import { Box } from '@mui/material'
import React from 'react'
import SendQuotes from '../components/SendQuotes'
import SendQuotesMobile from '../components/SendQuotesMobile'


function SendQuotesDetails() {
  return (
    <div>
     

      <Box sx={{display:{md:'block',xs:'none',sm:'block'}}}>

       <SendQuotes>        
       </SendQuotes>
      </Box>

      <Box sx={{display:{md:'none',xs:'block',sm:'none'}}}>

<SendQuotesMobile>
</SendQuotesMobile>

</Box>
    </div>
  )
}

export default SendQuotesDetails
