import React from 'react';
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import logo from './images/logo.png'
import { Button} from "@mui/material";
import Crop169Icon from "@mui/icons-material/Crop169";
import notification from "./images/mailheader.png";
import Messages from "./images/Messagesheader.png";
import Discussion from "./images/discussion room.png";
import Profile from "./images/Rectangle.png";
import { InputLabel } from '@mui/material';
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import UserNotificatinMenu from './UserNotificationMenu';
import Badge from '@mui/material/Badge';
function HeaderUserScreen(){


    const menus = [
        "Calculator",
        "Quotations",
        "Products",
        "About us",
        "Contact us"
      ];

      const [isOpenDrop, setIsOpenDrop] = useState(false);

      const handleMenuToggleDrop = () => {
        setIsOpenDrop(!isOpenDrop);
      };
    
      const handleMenuItemClickDrop = (value) => {
      
        setIsOpenDrop(false);
      }
      const [showNotificationMenu, setShowNotificationMenu] = useState(false);

      const handleClickNotificationImage = () => {
        
        setShowNotificationMenu(!showNotificationMenu);
      };

      return(
      
         <AppBar position="static" sx={{ bgcolor: "#0C276C", height: "72px", width: '100vw' }}>
         
         <Box sx={{display: "flex" ,width:'100%',flexDirection:'row',justifyContent:'center',alignItems:'center',height: "72px"}}> 
      
      <Box sx={{display: "flex" ,width:'10%',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <img src={logo} alt=""  style={{ 
                   display:  "flex" ,
                
                   height: 40,
                   width: '75%',
                  
                 }}
               />
 </Box>
               <Box sx={{  display: "flex" ,flexDirection:'row',gap:{md: '5%',sm:'0'},width:{md: '60%',sm:'55%'},justifyContent:'left'
               ,alignItems:'left',paddingLeft:{md: "3%",sm:'0'}, }}>
                 {menus.map((menu) => (
                   <Button
                     key={menu}
 
                     sx={{
                       my: 2, color: "white", display: "block",  textTransform: "capitalize", fontFamily: "poppins",
                       fontSize: "14px", lineHeight: "21px", fontWeight: "400",'&:hover': { color: "#939393",bgcolor:'#0C276C' },paddingRight:{md: "5%",sm:'.5%'},
                     }}
                   >
                     {menu}
                   </Button>
                 ))}  </Box>

                 
<Box sx={{display:'flex', flexDirection:'row',justifyContent:'center',width:'15%',gap:{md: '10%',sm:'3%'},alignItems:'center'}} >  
<Badge badgeContent="0" color="error"   >
<img src={Discussion} alt=""  />
    </Badge>      
    <Badge badgeContent="3" color="error"    >
    <img src={Messages} alt="" />
    </Badge>
    <Badge badgeContent="2" color="error"    >
    <img src={notification}  onClick={handleClickNotificationImage} alt=""  />
    </Badge>    
 

{showNotificationMenu && <UserNotificatinMenu />}
 </Box>
 <Box sx={{display:'flex', flexDirection:'row',justifyContent:'center',width:'15%',gap:{md:'7%',sm:'5%'},alignItems:'center',
 marginTop:{md:'0',sm:'2px'}}} > 
 <img src={Profile} alt=""  />
 <InputLabel style={{  fontFamily: "poppins", fontSize:{md:"16px",sm:"12px"} , fontWeight: "400",lineHeight:'24px',color:"#fff",
 }}>John Doe</InputLabel>

<div className="dropdown" >
     <div className="dropdown__toggle" onClick={handleMenuToggleDrop}>
     <ExpandMoreIcon style={{marginRight:'5%',marginTop:'5px',}}></ExpandMoreIcon>
     </div>
     {isOpenDrop && (
       <div className="dropdown__menu"  style={{ position: 'absolute', right: '10px ',fontFamily: "poppins",
        fontSize: "12px",  fontWeight: "400",lineHeight:'20px',width:'134px',height:{md:'56px',sm:'56px'},background:'#F8F8F8',borderRadius:'8px',
       boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',zIndex:1, minHeight: '20px',padding:'4px',color:'#2F2F2F'}}>
          <style>
           {`
             .dropdown__menu .MuiMenuItem-root:hover {
               background-color:#0C276C;
               color:white;
             }
             
           `}
         </style>
         <MenuItem  onClick={() => handleMenuItemClickDrop("myprofile")} value="option1" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
         fontFamily: "poppins", fontSize: "12px",fontWeight: "400",lineHeight:'20px'}}> <PersonIcon style={{width:'15px',height:'17px',
         marginRight:'10px'}}>
           </PersonIcon>My profile</MenuItem>
         <MenuItem onClick={() => handleMenuItemClickDrop("signout")} value="option2" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
         fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'20px'}}><LogoutIcon style={{width:'15px',height:'17px',
         marginRight:'10px'}}></LogoutIcon>Sign out</MenuItem>
        
       </div>
     )}
   </div>
   </Box>   

             
   </Box>   
        
         </AppBar>
         );
        }
        export default HeaderUserScreen;