
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import { Button, Grid, Typography } from "@mui/material";
import logo from './images/logo.png'
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import SignIn from './SignInSignUp'
import '../App.css';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Footer from './Footer';
import Header from './HeaderLanding';
import HeaderMobile from './HeaderLandingMobile';
import { Helmet } from 'react-helmet-async';

function AboutUs() {

  const [currentPage, setCurrentPage] = useState('About us');
  const menus = [
    { label: "Universal Calcualtor", link: "/" },
    { label: "Canada Mortgage ", link: "/canada-mortgage " },   
    { label: "About us", link: "/about-us" },
    { label: "Contact us", link: "/contact-us" }
  ];
  const [showSignIn, setShowSignIn] = useState(false);

  const handleClickSignIn = () => {
    setShowSignIn(!showSignIn);
  };
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  return (
    <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>

           <Helmet>
                <title>Explore Today's Mortgage Rates | Use Our Mortgage Payment Calculator</title>
                <meta name="description" content=" Mortgage rates and mortgage payment calculator - RATEWAVE's smart tools deliver real-time rate comparisons and precise payment breakdowns!" />
                <link rel="canonical" href="https://ratewaves.com/about-us" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Explore Today's Mortgage Rates | Use Our Mortgage Payment Calculator",
                        "description": " Mortgage rates and mortgage payment calculator - RATEWAVE's smart tools deliver real-time rate comparisons and precise payment breakdowns!",
                        "url": "https://ratewaves.com/about-us",
                        "publisher": {
                            "@type": "Organization",
                            "name": "Rate Waves",
                            "logo": {
                                "@type": "ImageObject",
                                "url": logo, // Dynamically uses the imported logo
                                "width": 250,
                                "height": 50,
                            },
                        },
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://ratewaves.com/?q={search_term_string}",
                            "query-input": "required name=search_term_string",
                        },
                    })}
                </script>
                
            </Helmet>
            <h1 style={{ position: 'absolute', left: '-9999px' }}>mortgage payment calculator</h1>



      <Box sx={{ height: '100% ', width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0, 
      padding: 0,display:{md: 'flex', sm: 'none',xs:'none' } }}>
<Header currentPage={currentPage}></Header>
        
        </Box>
        <Box sx={{ display:{md: 'none', sm: 'flex',xs:'flex' }, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
          <HeaderMobile currentPage={currentPage}></HeaderMobile>
      
      </Box>
        <Box sx={{
        width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)',xs:'calc(100%-10%)' },  display: 'flex', flexDirection: 'row',height:'100%',
        marginLeft: { md: '16%', sm: '5%' , xs:'5%'}, marginRight: { md: '16%', sm: '5%' , xs:'5%'}, marginTop: { md: '10px', sm: '10px',xs:'25px' },
      
      }}>
  <Box sx={{
            display: 'flex', flexDirection: 'column', width:'100%', height:{ md: '100%', sm: '100%' , xs:'100%' },background:"#F9F9F9 ",paddingTop:'25px ',
             alignItems: 'center'
          }}>
             
             <Typography variant="h2" sx={{
              fontFamily: 'poppins', fontSize: '16px',paddingLeft:'25px',paddingTop:{ md: '10px', sm: '10px' , xs:'0px'},
              lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
            }}>About us</Typography>
              <Box sx={{
            display: 'flex', flexDirection: 'column', width:'100%', height:{ md: '100%', sm: '100%' , xs:'100%' },background:"#F9F9F9 ",padding:'10px 25px 25px 25px',
             alignItems: 'center'
          }}>
            <Typography variant="h3" sx={{
              fontFamily: 'poppins', fontSize: '16px',paddingTop:'5px',
              lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Ratewaves is being proud while helping you to choose the right mortgage for you. Using our user-friendly interface,
             you can input essential details such as the property price, down payment, interest rate, and loan term to instantly discover
              your estimated monthly payments and total interest costs. Our calculator accounts for factors specific to the Canadian housing
               market, so you can trust the accuracy of the results.
           </Typography>
           <Typography variant="h4" sx={{
              fontFamily: 'poppins', fontSize: '16px',paddingTop:'5px',
              lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>
          But our commitment to your homeownership success doesn't end there. Our Canada Mortgage Calculator goes beyond the basics to
           help you explore different financial scenarios. Discover how making extra payments can save you money in the long run and analyze
            the impact of varying interest rates on your mortgage.
            </Typography>
            <Typography variant="h5" sx={{
              fontFamily: 'poppins', fontSize: '16px',paddingTop:'5px',
              lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>
           As your trusted partners, we are dedicated to supporting you throughout the process. Our team of experts is well-versed in the
            Canadian real estate landscape and mortgage market, ready to provide personalized guidance and answer any questions you may have
             along the way.
            </Typography>
            <Typography variant="h6" sx={{
              fontFamily: 'poppins', fontSize: '16px',paddingTop:'5px',
              lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>
        Whether you're a first-time homebuyer eager to find your perfect nest or a seasoned homeowner considering refinancing,
         our Canada Mortgage Calculator is here to empower you with knowledge. We believe that when you're equipped with the right information,
          you can confidently achieve your homeownership goals and build a solid foundation for your future.
            </Typography>
            </Box>
             </Box>




        </Box>
<Footer></Footer>
        </Grid>
  );

}

export default AboutUs;