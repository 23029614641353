import { AppBar, Avatar, Badge, Box, Button, IconButton, InputLabel, MenuItem, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import Crop169Icon from "@mui/icons-material/Crop169";
import Mail from './images/mail.png';
import Msg from './images/messagein.png';
import Person from './images/person.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import Robert from './images/Robert.png';
import '../App.css';
function Header2Mod() {
  const menus = [
    "Calculator",
    "Quotations",
    "Products",
    "About us",
    "Contact us"
  ];
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggleDropDown = () => {
    setIsOpen(!isOpen);

  };
  const handleMenuItemClick = (value) => {

    setIsOpen(false);
  }
  return (
    <Box >
      <Box sx={{ display: { lg: 'flex',sm:'flex', xs: 'none' } }}>
        <div className="header">
          {/* <div className='logopart'> */}
            <Box sx={{ width: '44px', height: '2.25rem', backgroundColor: 'white' }}></Box>
            <Box sx={{ width:{lg:'6.25rem',sm:'480px'}, display: 'flex', flexDirection: 'row', alignItems: 'center',gap: {lg:'68px',sm:'12px'},paddingLeft:'10px' }}>
              <Typography sx={{ fontWeight: 400, fontSize: { lg: 14, md: 12, sm: 12, xs: 12 }, color: '#FFFFFF', fontFamily: 'Poppins' }}>Calculator</Typography>
              <Typography sx={{ fontWeight: 600, fontSize: { lg: 14, md: 12, sm: 12, xs: 12 }, color: '#FFFFFF', fontFamily: 'Poppins' }}>Quotations</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: { lg: 14, md: 12, sm: 12, xs: 12 }, color: '#FFFFFF', fontFamily: 'Poppins' }}>Products</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: { lg: 14, md: 12, sm: 12, xs: 12 }, color: '#FFFFFF', fontFamily: 'Poppins' }}>About us</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: { lg: 14, md: 12, sm: 12, xs: 12 }, color: '#FFFFFF', fontFamily: 'Poppins' }}>Contact us</Typography>
            </Box>
          {/* </div> */}
          <Box sx={{width:'132px', display: 'flex', flexDirection: 'row', gap: {lg:'24px',sm:'10px'}, alignItems: 'center' }}>
            <Badge color="secondary" badgeContent={1}
              sx={{
                "& .MuiBadge-badge": {
                  color: "white", backgroundColor: "#CE3932"
                }
              }}>
              <Box sx={{ height: '28px', width: '28px', borderRadius: '20px', alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'white' }}>
                <img src={Person} />
              </Box>
            </Badge>
            <Badge color="secondary" badgeContent={3}
              sx={{
                "& .MuiBadge-badge": {
                  color: "white", backgroundColor: "#CE3932"
                }
              }}>
              <Box sx={{ height: '28px', width: '28px', borderRadius: '20px', alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'white' }}>
                <img src={Msg} />
              </Box>
            </Badge>
            <Badge color="secondary" badgeContent={2}
              sx={{
                "& .MuiBadge-badge": {
                  color: "white", backgroundColor: "#CE3932"
                }
              }}>
              <Box sx={{ height: '28px', width: '28px', borderRadius: '20px', alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'white' }}>
                <img src={Mail} />
              </Box>
            </Badge>
            </Box>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
            <Box sx={{ height: '28px', width: '28px', borderRadius: '20px', alignItems: 'center' }}>
              <img src={Robert} style={{width:'28px',height:'28px'}}/>
            </Box>
          
          <InputLabel style={{
            fontFamily: "poppins", fontSize: "16px", fontWeight: "400", lineHeight: '15px', color: "#ffff",
          }}>John Doe</InputLabel>
          {/* </div> */}
          {/* <Box className="dropdown"> */}
          <Box className="dropdown__toggle" onClick={handleMenuToggleDropDown}>
            <ExpandMoreIcon style={{ marginTop: '5px' }} />
          </Box>
          {isOpen && (
            <Box className="dropdown__menu" sx={{
              position: 'absolute', right: '10px', fontFamily: 'poppins', fontSize: '12px', fontWeight: '400',
              lineHeight: '20px', width: '134px', background: '#F8F8F8', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', zIndex: 1,
              color: '#2F2F2F', padding: '4px'
            }}>
              <style>
                {`
     .dropdown__menu .MuiMenuItem-root:hover {
       background-color: #1F6373;
       color: white;
     }
   `}
              </style>
              <MenuItem onClick={() => handleMenuItemClick("myprofile")} value="option1" style={{
                fontFamily: 'poppins', fontSize: '12px',
                fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
              }}>
                <PersonIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                My profile
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("signout")} value="option2" style={{
                fontFamily: 'poppins', fontSize: '12px',
                fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
              }}>
                <LogoutIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                Sign out
              </MenuItem>
            </Box>

          )}

        </div>
      </div>
      

    </Box>
         
  


    <Box sx={{ display: { lg: 'none',sm:'none', xs: 'flex' }, flexDirection: 'row', alignItems: 'center', width: '100vw',justifyContent:'space-between' }}>
      <div className="header">
        <AppBar position="fixed" sx={{ bgcolor: "#1F6373", height: "56" }}>


          <Toolbar disableGutters sx={{justifyContent: 'space-between',paddingRight:'10px'}}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '18px' }}>
              <Crop169Icon
                sx={{

                  height: '28px',
                  width: '44px',
                  backgroundColor: "#fff"
                }}
              />
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuToggleMenu}

              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: showMenu ? "block" : "none", position: "absolute", top: "100%", width: "100vw", zIndex: 1,
                bgcolor: "#1F6373", justifyContent: 'flex-start', borderRadius: ' 0px 0px 8px 8px', paddingBottom: '20px'
              }}
            >
              {menus.map((menu) => (
                <Button onClick={handleMenuToggleMenu}
                  key={menu}
                  sx={{
                    display: "block", height: '35px', color: "white", textTransform: "capitalize", fontFamily: "poppins",
                    fontSize: "14px", lineHeight: "21px", fontWeight: "400", textAlign: 'left', borderRadius: '0px',
                    '&:hover': { background: "#79A3A8", width: '100%' }
                  }}
                >
                  {menu}
                </Button>
              ))}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '39px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '14px', marginLeft: 'auto' }}>
                {/* <img src={Discussion} alt="" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
         <img src={Messages} alt="" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
         <img src={notification} alt="" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
         <img src={John} alt="" style={{width:'24px',height:'24px'}} />
         {/* <InputLabel style={{
           fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#fff",
         }}></InputLabel> */}
                {/* <div style={{display:'flex',flexDirection:'row',gap:'24px',alignItems:'center'}}> */}
                <Badge color="secondary" badgeContent={1} 
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "white", backgroundColor: "#CE3932",height: '10px',width: '8px',minWidth:'8px', borderRadius: '10px',fontSize:'8px'
                    }
                  }}>
                  <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'white' }}>
                    <img src={Person} />
                  </Box>
                </Badge>
                <Badge color="secondary" badgeContent={3}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "white", backgroundColor: "#CE3932",height: '10px',width: '8px',minWidth:'8px', borderRadius: '10px',fontSize:'8px'
                    }
                  }}>
                  <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'white' }}>
                    <img src={Msg} />
                  </Box>
                </Badge>
                <Badge color="secondary" badgeContent={2}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "white", backgroundColor: "#CE3932",height: '10px',width: '8px',minWidth:'8px', borderRadius: '10px',fontSize:'8px'
                    }
                  }}>
                  <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'white' }}>
                    <img src={Mail} />
                  </Box>
                </Badge>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                  <img src={Robert} style={{width:'20px',height:'20px'}}/>
                </Box>
                <InputLabel style={{
                  fontFamily: "poppins", fontSize: "10px", fontWeight: "400", lineHeight: '15px', color: "#ffff",
                }}>John Doe</InputLabel>
                {/* </div> */}
                {/* <Box className="dropdown"> */}
                <Box className="dropdown__toggle" onClick={handleMenuToggleDropDown}>
                  <ExpandMoreIcon sx={{ marginTop:'4px'}} />
                </Box>
                {isOpen && (
                  <Box className="dropdown__menu" sx={{
                    position: 'absolute', right: '10px', fontFamily: 'poppins', fontSize: '12px', fontWeight: '400',
                    lineHeight: '20px', width: '134px', background: '#F8F8F8', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', zIndex: 1,
                    color: '#2F2F2F', padding: '4px'
                  }}>
                    <style>
                      {`
     .dropdown__menu .MuiMenuItem-root:hover {
       background-color: #1F6373;
       color: white;
     }
   `}
                    </style>
                    <MenuItem onClick={() => handleMenuItemClick("myprofile")} value="option1" style={{
                      fontFamily: 'poppins', fontSize: '12px',
                      fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
                    }}>
                      <PersonIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                      My profile
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick("signout")} value="option2" style={{
                      fontFamily: 'poppins', fontSize: '12px',
                      fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
                    }}>
                      <LogoutIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                      Sign out
                    </MenuItem>
                  </Box>

                )}
              </Box>


            </Box>




          </Toolbar>


        </AppBar>
      </div>
    </Box>

 </Box >
  );
}
export default Header2Mod;