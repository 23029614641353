import { Box, Typography } from '@mui/material'
import React from 'react'
import HelpIcon from '@mui/icons-material/Help';
function Leftbox() {
  return (
    <Box sx={{ width:{lg:'178px',md:'178px',sm:'178px',xs:'60%' }, display: 'flex', flexDirection: 'column', gap: '16px',marginTop:{lg:0,sm:'26px'} }}>
            <Box sx={{ width: { lg: '168px', md: 168, sm: 158, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Initial payment</Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
                  <Box sx={{ width: { lg: '168px', md: 168, sm: 158, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Monthly payment </Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
                  <Box sx={{ width: { lg: '168px', md: 168, sm: 158, xs: 110 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Type</Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
                  <Box sx={{ width: { lg: '168px', md: 168, sm: 158, xs: 110 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Term</Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
                  <Box sx={{ width: { lg: '168px', md: 168, sm: 158, xs: 110 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Interest rate (%) </Typography>
                    <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Interest (%) </Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
                  <Box sx={{ width: { lg: '168px', md: 168, sm: 158, xs: 110 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Amortization period</Typography>
                    <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Amortization</Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
                  <Box sx={{ width: { lg: '168px', md: 168, sm: 158, xs: 110 }, height: {lg:'40px',md:'28px',sm:'40px',xs:'28px'}, alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                    <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment frequency</Typography>
                    <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment</Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
    </Box>
  )
}

export default Leftbox