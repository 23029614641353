import React from 'react';
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import { Grid } from "@mui/material";
import { InputLabel } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from "react";
import NotificationDetails from './AgentNotificationDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import HeaderAgentScreen from './HeaderAgentScreen';
function AgentNotification(){


    const menus = [
        "Calculator",
        "Quotations",
        "Products",
        "About us",
        "Contact us"
      ];
      const [isOpenDrop, setIsOpenDrop] = useState(false);

      const handleMenuToggleDrop = () => {
        setIsOpenDrop(!isOpenDrop);
      };
    
      const handleMenuItemClickDrop = (value) => {
      
        setIsOpenDrop(false);
      }

      const [isOpenSortBy, setIsOpenSortBy] = useState(false);

      const handleMenuToggleSortBy = () => {
        setIsOpenSortBy(!isOpenSortBy);
      };
    
      const handleMenuItemClickSortBy = (value) => {
      
        setIsOpenSortBy(false);
      }

      const code=6611;
      const [showNotificationMenu, setShowNotificationMenu] = useState(false);

      const handleClickNotificationImage = () => {
        
        setShowNotificationMenu(!showNotificationMenu);
      };
      
      return(
        <Grid container sx={{ width: '100%', height: '100%' }}>
         
         <Box>
          <HeaderAgentScreen></HeaderAgentScreen>
        

          </Box>

          <Box sx={{display:'flex',marginLeft:{md:'12%',sm:'5%'},marginRight:{md:'12%',sm:'5%'},flexDirection:'column',width: 'calc(100%)'}}>
        <InputLabel style={{  fontFamily: "poppins", fontSize: "20px", fontWeight: "500",lineHeight:'30px',color:"#313131",marginTop:'10px',
  }}>Notifications</InputLabel>
  
<AppBar position="static" sx={{ bgcolor: "#1F6373", height: "60px", borderRadius:'8px 8px 0px 0px',marginTop:'10px',paddingLeft:'20px',
justifyContent:'center', width: 'calc(100%)'
 }}>
   <Box sx={{display:'flex',flexDirection:'row',alignItems:'left',justifyContent:'left',alignContent:'center'}}>
   
 <ArrowBackIcon style={{width:'28px', height:'28px'}}/>
 
 <Box className="dropdownSortBy" sx={{marginLeft:{md:'88%',sm:'80%'}}}>
        <div className="dropdown__toggleSortBy" onClick={handleMenuToggleSortBy}>
        <Box sx={{display:'flex',flexDirection:'row',alignItems:'right',justifyContent:'right',alignContent:'right'}}>
        <InputLabel style={{  fontFamily: "poppins", fontSize: "14px", fontWeight: "400",lineHeight:'21px',color:"white",
  }}>Sort by</InputLabel> 
        <ExpandMoreIcon ></ExpandMoreIcon></Box>
        </div>
        {isOpenSortBy && (
          <Box className="dropdown__menuSortBy"  sx={{ position: 'absolute', right:{md:'14% ',sm:'10%'} ,fontFamily: "poppins",
           fontSize: "12px",  fontWeight: "400",lineHeight:'20px',width:'134px',height:'62px',background:'#F8F8F8',borderRadius:'8px',
          boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',zIndex:1, minHeight: '20px',padding:'4px',color:'#2F2F2F'}}>
             <style>
              {`
                .dropdown__menuSortBy .MuiMenuItem-root:hover {
                  background-color: #1F6373;
                  color:white;
                }
                
              `}
            </style>
            <MenuItem  onClick={() => handleMenuItemClickSortBy("By date")} value="option1" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
            fontFamily: "poppins", fontSize: "12px",fontWeight: "400",lineHeight:'20px'}}> By date</MenuItem>
            <MenuItem onClick={() => handleMenuItemClickSortBy("By time")} value="option2" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
            fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'20px'}}>By time</MenuItem>
           
          </Box>
        )}
      </Box>
      </Box>
     
</AppBar>   
</Box>

          
<Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center',
     width: 'calc(100%)',marginBottom:'30px', marginLeft:{md:'12%',sm:'5%'},marginRight:{md:'12%',sm:'5%'} }}>



      {[...Array(10)].map((_, index) => (
        <React.Fragment key={index} ><NotificationDetails code={code+index} ></NotificationDetails></React.Fragment>
      ))}
  
    </Box> 




          
          </Grid>
    );
}
export default AgentNotification;