import React, { useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box, MenuItem } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Button } from "@mui/material";


const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (value) => {
    handleSelection(value);
    setIsOpen(false);
  }
  const [buttonLabel, setButtonLabel] = useState("New");
  const [buttonBackground, setButtonBackground] = useState('#D5FBFF')

  const handleSelection = (value) => {
    if (value === "sent") {
      setButtonLabel("Sent");
      setButtonBackground('#76DE24');
    } else if (value === "reject") {
      setButtonLabel("Rejected");
      setButtonBackground('#EB5648');
    } else if (value === "negotiate") {
      setButtonLabel("Negotiated");
      setButtonBackground('#79A3A8');
    }
  }
  return (
    <Box sx={{display:"flex", direction:"coloumn", marginLeft:{md:'0',sm:"2%"}}} >
    <Box>
    <Button variant="contained"  sx={{
      color: '#313131' , textTransform: "none", padding: "10px 10px 10px 10px ",
      marginTop: "26px", background: buttonBackground ,boxShadow:'none',
      filter: 'blur(0.5px)', fontFamily: "Poppins", fontStyle: "normal",cursor:'default',
      lineHeight: "21px", fontWeight: "500", fontSize: {md:"14px",sm:"12px"}, width:{md:"94px",sm:"78px"},height:'28px',
       borderRadius: "24px", marginLeft:{md:'5%',sm:"5%"},'&:hover': { bgcolor: buttonBackground }
    }}  >
     {buttonLabel}
    </Button>
</Box>

<Box sx={{marginLeft:{md:'25%',sm:"10%"}}}>
     <InputLabel style={{  fontFamily: "poppins", fontSize: "10px", fontWeight: "400",lineHeight:'15px',color:"#212427",
     marginTop:'33px'
     }}>12/03/23 : 12 PM</InputLabel> 
  </Box>
    <Box className="dropdown" sx={{marginTop:{md:'27.5px',sm:"26px"}, marginLeft:{md:'50%',sm:"3%"}}}>
      <Box className="dropdown__toggle" onClick={handleMenuToggle}>
        <MoreVertOutlinedIcon style={{cursor:'pointer'}}/>
      </Box>
      {isOpen && (
        <Box className="dropdown__menu" handleSelection={handleSelection} sx={{ position: 'absolute', right: {md:'4.5% ',sm:'3% '},fontFamily: "poppins",
         fontSize: "12px",  fontWeight: "400",lineHeight:'18x',width:{md:'134px',sm:'114px'},height:'88px',background:'#F8F8F8',borderRadius:'8px',
        boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',zIndex:1, minHeight: '20px',padding:'4px',marginTop: {md:'-.5% ',sm:'.2% '}}}>
           <style>
            {`
              .dropdown__menu .MuiMenuItem-root:hover {
                background-color: #1F6373;
                color:white;
              }
             
            `}
          </style>
          <MenuItem  onClick={() => handleMenuItemClick("sent")} value="option1" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
          fontFamily: "poppins", fontSize: "12px",fontWeight: "400",lineHeight:'18px'}}>Send</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("reject")} value="option2" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
          fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'18px'}}>Reject</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("negotiate")} value="option3" style={{height:'24px',borderRadius:'4px', marginBottom: '0px',
          fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'18px'}}>Negotiate</MenuItem>
        </Box>
      )}
    </Box>
    </Box>
  );
};



export default DropdownMenu;
