import React from 'react';
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Button, Typography,Grid } from "@mui/material";
import Crop169Icon from "@mui/icons-material/Crop169";
import notification from "./images/Notification (4).png";
import Messages from "./images/Messages.png";
import Discussion from "./images/Discussion.png";
import Profile from "./images/image 22.png";
import { InputLabel } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { useState } from "react";
import DivComponents from './DivComponentMobile';
import Payment from './PaymentComponentMobile';
import Penalty from './PenaltyComponentMobile';
import Link from "@mui/material/Link";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import sendicon from './images/Vector (5).png';
import downicon from './images/material-symbols_download.png';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Tick from './images/Vector (6).png';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import GetAppIcon from '@mui/icons-material/GetApp';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AgentNotificatinMenu from './AgentNotificationMenu';
import HeaderAgentScreenMobile from './HeaderAgentScreenMobile';

function SendQuotes(){


    const menus = [
        "Calculator",
        "Quotations",
        "Products",
        "About us",
        "Contact us"
      ];



      const [dataList, setDataList] = useState([
        {
          id: 1,
          name: "Mortgage 1",
          initialPayment: 0,
          type: '',
          term: 2,
          rate: 0,
          period: 0,
          frequency: "monthly",       
          divBgColor:"white"
        },
        {
          id: 2,
          name: "Mortgage 2",
          initialPayment: 0,
          type: '',
          term: 2,
          rate: 0,
          period: 0,
          frequency: "monthly",
          divBgColor:"white"
        },
        
      ])
      
  const [currentSlideIndex, setCurrentSlideIndex] = useState(2);



  const linkstyles = {
    link1: {
     textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
      color: " #313131",zIndex:1, marginLeft: "15px",marginTop:'15px'
    },
    link2: {
      marginLeft: "15px", textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
      color: " #313131",marginTop:'15px',zIndex:1

    },
    activeLink: {
      textDecoration: 'underline',fontWeight:'500'
    },
  };
  const [activeLink, setActiveLink] = useState('Calculate Payment');

  const handleLinkClick = (link) => {
    setActiveLink(link);

  };
  const [calcContent, setcalcContent] = useState("Payment Content");

  const handlePaymentCalc = () => {
    setcalcContent("Payment Content");
    handleLinkClick('Calculate Payment');
  };

  const handlePenaltyCalc = () => {
    setcalcContent("Penalty Content");
    handleLinkClick('Calculate Penalty on cancellation');
  };
  const [ischeck, setIscheck] = useState(true);
  const [isCheckedCheckboxSendQuotes, setisCheckedCheckboxSendQuotes] = useState([]);

  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggleDropDown = () => {
    setIsOpen(!isOpen);
    ;
  };

  const handleMenuItemClick = (value) => {

    setIsOpen(false);
  }
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  
 

  const [selectedCheckboxList, setCheckboxSelectedList] = useState([]);

  // const handleCheckboxChangeSendQuote = (index) => {

    
  //   const temp = [...selectedCheckboxList];
  //   if (temp.includes(index)) {
  //     temp.splice(temp.indexOf(index), 1);
  //   }
  //   else {
  //     temp.push(index);
  //   }
  //   setIsAnyCheckboxChecked(temp);
  //   setisCheckedCheckboxSendQuotes(temp);
  //   setCheckboxSelectedList(temp);
  // };
  const handleCheckboxChangeSendQuote = (index) => {

    
    const temp = [...selectedCheckboxList];
    if (temp.includes(index)) {
      temp.splice(temp.indexOf(index), 1);
    }
    else {
      temp.push(index);
    }
    setIsAnyCheckboxChecked(temp);
    setisCheckedCheckboxSendQuotes(temp);
    setCheckboxSelectedList(temp);
  };
    
  const isdisableAddSlidebutton =(dataList[dataList.length ]?.name) === (dataList[currentSlideIndex]?.name)
 
  const [showPopup, setShowPopup] = useState(false);

  const handleCancel = () => {
    setShowPopup(false);
  };

  
  const handlePopUp = () => {
    setShowPopup(true);
  };

  const [isHoveredSendQuote, setIsHoveredSendQuote] = useState(false);

  const handleMouseEnterSendQuote = () => {
    setIsHoveredSendQuote(true);
  };

  const handleMouseLeaveSendQuote = () => {
    setIsHoveredSendQuote(false);
  };


  const [isHoveredSendToMail, setIsHoveredSendToMail] = useState(false);

  const handleMouseEnterSendToMail = () => {
    setIsHoveredSendToMail(true);
  };

  const handleMouseLeaveSendToMail = () => {
    setIsHoveredSendToMail(false);
  };
  const [isHoveredDownloadResult, setIsHoveredDownloadResult] = useState(false);

  const handleMouseEnterDownloadResult = () => {
    setIsHoveredDownloadResult(true);
  };

  const handleMouseLeaveDownloadResult = () => {
    setIsHoveredDownloadResult(false);
  };


  const [numberOfAddClick,setnumberOfAddClick] = useState(2);
 

  const handleAddSlide = () => {
   
   
    if (numberOfAddClick<4) {
     
      const newData = {
        
      
      id: dataList.length + 1, name:`Mortgage ${numberOfAddClick+1}`,
      initialPayment: 0, type: '', term: 2, rate: 0, period: 0, frequency: "monthly"
    }
   
   // isDeleteConfirm===true?setCurrentSlideIndex(currentSlideIndex+2):setCurrentSlideIndex(currentSlideIndex+1);
  
    setnumberOfAddClick(numberOfAddClick+1);

   // setIsDeleteConfirm(false);
    setDataList([...dataList, newData]);
    }
    setCurrentSlideIndex(currentSlideIndex+1);
   
  };
  
  
  
  const handlePrevSlide = () => {
      
      if(currentSlideIndex>2)
        setCurrentSlideIndex(currentSlideIndex-1)
    
  };

  const handleNextSlide = () => {
      if(currentSlideIndex<4)
        setCurrentSlideIndex(currentSlideIndex+1)
    
  }
 
   
  const [isHoveredAddMortgage, setIsHoveredAddMortgage] = useState(false);

  const handleMouseEnterAddMortgage = () => {
    setIsHoveredAddMortgage(true);
  };

  const handleMouseLeaveAddMortgage = () => {
    setIsHoveredAddMortgage(false);
  };

  const [isHoveredTheBidding, setIsHoveredTheBidding] = useState(false);

  const handleMouseEnterTheBidding = () => {
    setIsHoveredTheBidding(true);
  };

  const handleMouseLeaveTheBidding = () => {
    setIsHoveredTheBidding(false);
  };
  const [showNotificationMenu, setShowNotificationMenu] = useState(false);

  const handleClickNotificationImage = () => {
    
    setShowNotificationMenu(!showNotificationMenu);
  };
  
 
  
    return(
      <Grid container sx={{ width: '100%', height: '100%' }}>
        {/* <Box sx={{ flexGrow: 1,height: '726px', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400" }} > */}
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
          <HeaderAgentScreenMobile></HeaderAgentScreenMobile>
        {/* <AppBar position="static" sx={{ bgcolor: "#1F6373", height: "56" }}>


          <Toolbar disableGutters>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center', alignItems: 'center', paddingLeft: '18px' }}>
              <Crop169Icon
                sx={{

                  height: '28px',
                  width: '44px',
                  backgroundColor: "#fff"
                }}
              />
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuToggleMenu}

              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: showMenu ? "block" : "none", position: "absolute", top: "100%", width: "100vw", zIndex: 5,
                bgcolor: "#1F6373", justifyContent: 'flex-start', borderRadius: ' 0px 0px 8px 8px',paddingBottom:'20px'
              }}
            >
              {menus.map((menu) => (
                <Button  onClick={handleMenuToggleMenu}
                  key={menu}
                  sx={{
                    display: "block", height: '35px', color: "white", textTransform: "capitalize", fontFamily: "poppins",
                    fontSize: "14px", lineHeight: "21px", fontWeight: "400", textAlign: 'left', borderRadius: '0px',
                    '&:hover': { background: "#79A3A8", width: '100%' }
                  }}
                >
                  {menu}
                </Button>
              ))}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '12px', marginLeft: 'auto', 
            marginRight: '16px' }}>
              <img src={Discussion} alt="" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
              <img src={Messages} alt="" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
              <img src={notification}  onClick={handleClickNotificationImage} alt="" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
              {showNotificationMenu && <AgentNotificatinMenu />}
              <img src={Profile} alt="" style={{width:'24px',height:'24px'}} />
              <InputLabel style={{
                fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#fff",
              }}>Robert</InputLabel>
              <Box className="dropdown">
                <Box className="dropdown__toggle" onClick={handleMenuToggleDropDown}>
                  <ExpandMoreIcon style={{ marginTop: '5px' }} />
                </Box>
                {isOpen && (
                  <Box className="dropdown__menu" sx={{
                    position: 'absolute', right: '10px', fontFamily: 'poppins', fontSize: '12px', fontWeight: '400',
                    lineHeight: '20px', width: '134px', background: '#F8F8F8', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                     zIndex: 1,
                    color: '#2F2F2F', padding: '4px'
                  }}>
                    <style>
                      {`
          .dropdown__menu .MuiMenuItem-root:hover {
            background-color: #1F6373;
            color: white;
          }
        `}
                    </style>
                    <MenuItem onClick={() => handleMenuItemClick("myprofile")} value="option1" style={{
                      fontFamily: 'poppins', fontSize: '12px',
                      fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
                    }}>
                      <PersonIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                      My profile
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick("signout")} value="option2" style={{
                      fontFamily: 'poppins', fontSize: '12px',
                      fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
                    }}>
                      <LogoutIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                      Sign out
                    </MenuItem>
                  </Box>
                )}
              </Box>



            </Box>


          </Toolbar>


        </AppBar> */}
      </Box>
      <Box
        sx={{
          width:  'calc(100% - 30px)',   display: 'flex', flexDirection: "column", justifyContent: 'center',  alignItems:'left',margin:'16px ',
          marginTop:'70px'
        }}      >
          <InputLabel style={{  fontFamily: "poppins", fontSize: "14px", fontWeight: "500",lineHeight:'21px',color:"#313131",alignItems:'left'
  }}>Send quotes</InputLabel> 
   <Box
        sx={{
          width:  'calc(100% )',   display: 'flex', flexDirection: "row", justifyContent: 'center',  alignItems:'center',
          marginTop:'10px'
        }} >  
        <AppBar position="relative" sx={{
          bgcolor: "#1F6373", height: "54px", width: 'calc(100% )', borderRadius: '8px 8px 0px 0px',
          justifyContent: 'center',
        }}>
<ArrowBackIcon style={{width:'25px', height:'25px',paddingLeft:'10px'}}/>
        </AppBar>
        </Box>
      </Box> 
      <Typography sx={{ display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
              fontFamily: 'poppins', fontSize: '14px',marginLeft:'16px',
              lineHeight: '21px', fontWeight: '600', color: '#313131'
            }}>Input parameters</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left',width:'calc(100%)',margin:'16px'}}>
        
          <Box sx={{
            display: 'flex', flexDirection: 'column',width:'calc(32%)', marginTop:'55px',
            height: '308px',alignItems: 'left',  gap: '20px',
          }}>
           

            <Typography sx={{ 
     
              fontFamily: 'poppins', fontSize: '12px', alignItems: 'center',
              lineHeight: '18px', fontWeight: '400', color: '#313131',  justifyContent: 'space-between',display: 'flex',
            }}>Initial payment <HelpIcon sx={{
              fontSize: '10px', color: '#878787',
            
            }} /> </Typography>

            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px', display: 'flex',justifyContent: 'space-between',
              lineHeight: '18px', fontWeight: '400', color: '#313131'
            }}>Type <HelpIcon sx={{
              fontSize: '10px',  marginTop:'4px',
              color: '#878787'
            }} /> </Typography>

            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px', display: 'flex',justifyContent: 'space-between',
              lineHeight: '18px', fontWeight: '400', color: '#313131'
            }}>Term <HelpIcon sx={{
              fontSize: '10px',marginTop:'4px',
              color: '#878787'
            }} /> </Typography>

            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px', display: 'flex',justifyContent: 'space-between',
              lineHeight: '18px', fontWeight: '400', color: '#313131'
            }}>Interest (%) <HelpIcon sx={{
              fontSize: '10px',marginTop:'4px',
              color: '#878787'
            }} /> </Typography>

            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',display: 'flex',justifyContent: 'space-between',
              lineHeight: '18px', fontWeight: '400', color: '#313131',
            }}> Amortization <HelpIcon sx={{ fontSize: '10px',  color: '#878787',alignItems: 'flex-end',marginTop:'4px', }} /></Typography>

            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',display: 'flex',justifyContent: 'space-between',
              lineHeight: '18px', fontWeight: '400', color: '#313131',
            }}>Payment <HelpIcon sx={{
              fontSize: '10px',marginTop:'4px',
              color: '#878787'
            }} /></Typography>
            
            
            </Box> 
<Box sx={{
            display: 'flex', flexDirection: 'row',width:'calc(68% )', 
            height: '384px',alignItems: 'center', 
          }}>
 {dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex).map((data, index) => (
            <Box sx={{width:'calc(100% )',marginLeft:'3%'}}  key={index} className="slide">
               <Checkbox size='small'  checked={selectedCheckboxList.includes(data.name)}  onChange={() => handleCheckboxChangeSendQuote(data.name)}
          style={{
            accentColor:"green" ,  color: isCheckedCheckboxSendQuotes.includes(data.name)===true ? "#1F6373" : "#CFC9C9",
           position: "relative",    zIndex: 1, background: isCheckedCheckboxSendQuotes.includes(data.name) ? "#F9F9F9" : "white",
           borderRadius: '50%',width: '8px', height: '8px',marginTop:'-215px',alignItems:'right',padding:7,marginLeft:'83%' }} />
                <DivComponents dataList={dataList} 
                  index={index}  
                   mortgageName={data.name} 
                   selectedCheckboxList={selectedCheckboxList}
                   setCheckboxSelectedList={setCheckboxSelectedList}
                   isSelected={isCheckedCheckboxSendQuotes.includes(data.name)}
                   onChange={() => handleCheckboxChangeSendQuote(data.name)}
                />
            </Box>
          ))}


          </Box>

</Box>  
<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left',width:'calc(100%)',margin:'16px',marginTop:'-95px',}}>
        
        <Box sx={{
          display: 'flex', flexDirection: 'column',width:'calc(32%)', 
         alignItems: 'left',  
        }}>
         
<Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',display: 'flex',justifyContent: 'space-between',
              lineHeight: '18px', fontWeight: '400', color: '#313131',
            }}>Payment schedule <HelpIcon sx={{
              fontSize: '10px',marginTop:'12px',
              color: '#878787'
            }} /></Typography>
            </Box>
            <Box sx={{
            display: 'flex', flexDirection: 'row',width:'calc(68% )', marginTop:'-45px',
           alignItems: 'center', 
          }}>
 <Typography style={{
            marginLeft: "10%", fontFamily: "poppins", fontSize: "12px", fontWeight: "500", lineHeight: '18px',
            color: " #313131",width:'50%'
          }}> CAD (result)</Typography>
          <Typography style={{
            marginLeft: "10%", fontFamily: "poppins", fontSize: "12px", fontWeight: "500", lineHeight: '18px',
            color: " #313131",width:'50%'
          }}> CAD (result)</Typography>
   </Box>
   </Box>

<Box sx={{ display: 'flex', flexDirection: "row", width:'calc(100%)',marginRight:'16px',  alignItems: 'right',
        justifyContent: 'right',alignContent:'flex-end',marginTop:'-55px' }}>
<ChevronLeftIcon onClick={handlePrevSlide} disabled={currentSlideIndex ===  2} style={{
            background:   "#D2E0E3",marginRight:'4%',
            color:currentSlideIndex ===  2 ? "#CCCCCC" :  "#1F6373", boxShadow: "none ", borderRadius: "50%", fontSize: "26px"
          }} ></ChevronLeftIcon>



          <ChevronRightIcon onClick={isdisableAddSlidebutton ? null : handleNextSlide} style={{
            background: "#D2E0E3", 
            boxShadow: "none", color: isdisableAddSlidebutton ? "#CCCCCC" : "#1F6373",  borderRadius: "50%",
             fontSize: "26px"
          }} ></ChevronRightIcon>
       
</Box>
<Box sx={{ display: 'flex', flexDirection: "row", width:'calc(100%)',marginRight:'16px', marginLeft:'16px', alignItems: 'right',
        justifyContent: 'right',alignContent:'flex-end',marginTop:'-10px'}}>
<Button onClick={handleAddSlide} disabled={dataList.some(item => item.name === 'Mortgage 4') || (isAnyCheckboxChecked)} variant="contained" 
style={{
  color: "white", padding: "4px 16px ", 
  borderRadius: "12px",background: isHoveredAddMortgage && dataList.some(item => item.name === 'Mortgage 4') || isAnyCheckboxChecked
  ? '#939393' : isHoveredAddMortgage   ? '#074554' : '#1F6373', 
  border:(dataList.some(item => item.name === 'Mortgage 4') || isAnyCheckboxChecked ?"1px solid #939393" : "1px solid #1F6373") , fontFamily: "Roboto",
  textTransform: "none", fontStyle: "normal", fontWeight: "500", fontSize: "12px",
}} onMouseEnter={handleMouseEnterAddMortgage}  
onMouseLeave={handleMouseLeaveAddMortgage}
  startIcon={<AddCircleOutlineIcon style={{ color: "white", fontSize: "12px" }} />}>
  Add mortgage
</Button>
</Box>
<Box sx={{ display: 'flex', flexDirection: "row", width:'calc(100%)',}}>
          <Link href="#" underline='hover'
            style={activeLink === 'Calculate Payment' ? { ...linkstyles.link1, ...linkstyles.activeLink } : linkstyles.link1}
            onClick={handlePaymentCalc}>
            Calculate payment
          </Link>         
          
          <Link href="#" underline='hover' rel="noopener"
            style={activeLink === 'Calculate Penalty on cancellation' ? { ...linkstyles.link2, ...linkstyles.activeLink } : linkstyles.link2}
            onClick={handlePenaltyCalc}>
            Calculate penalty on cancellation
          </Link>
        </Box>



        {calcContent === "Payment Content" && 
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left',width:'calc(100%)',margin:'16px'}}>
        
        <Box sx={{
          display: 'flex', flexDirection: 'column',width:'calc(32%)', marginTop:'55px',
          height: '220px',alignItems: 'left',  gap: '22px',
        }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400", justifyContent: 'space-between',display: 'flex',
              color: " #313131"
            }}>Payment schedule <HelpIcon style={{ fontSize: "10px",  color: "#878787",marginTop:'12px' }} /> </Typography>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400", justifyContent: 'space-between',display: 'flex',
              color: " #313131"
            }}>Remaining principle<HelpIcon style={{ fontSize: "10px",  color: "#878787",marginTop:'12px' }} /></Typography>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400", justifyContent: 'space-between',display: 'flex',
              color: " #313131"
            }}> Interest paid <HelpIcon style={{ fontSize: "10px",  color: "#878787", marginTop: '4px' }} /></Typography>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400", justifyContent: 'space-between',display: 'flex',
              color: " #313131",marginTop: '8px' 
            }}> Principle paid<HelpIcon style={{ fontSize: "10px",  color: "#878787", marginTop: '3px'  }} /></Typography>

          </Box>

          <Box sx={{
            display: 'flex', flexDirection: 'row',width:'calc(68% )', 
            height: '220px',alignItems: 'center', 
          }}>
        {dataList.slice(Math.abs(2-currentSlideIndex),currentSlideIndex)

          .map((slide, index) => (
            <Box sx={{width:'calc(100% )',marginLeft:'3%'}} key={index} className="slide">
              <Payment dataList={dataList} index={index} mortgageName={slide.name}
               isSelected={isCheckedCheckboxSendQuotes.includes(slide.name)}
               onChange={() => handleCheckboxChangeSendQuote(slide.name)}
              />
            </Box>
          ))}

</Box>
      </Box>}

        {calcContent === "Penalty Content" && 
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left',width:'calc(100%)',margin:'16px',marginBottom:'-40px'}}>
        
        <Box sx={{
          display: 'flex', flexDirection: 'column',width:'calc(32%)', marginTop:'68px',
          height: '220px',alignItems: 'left',  gap: '22px',
        }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400", justifyContent: 'space-between',display: 'flex',
              color: " #313131"
            }}>Cancellation <HelpIcon style={{ fontSize: "10px",  color: "#878787",marginTop:'5px' }} /> </Typography>
             <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400", justifyContent: 'space-between',display: 'flex',
              color: " #313131",marginTop:'5px'
            }}>Remaining principle<HelpIcon style={{ fontSize: "10px",  color: "#878787",marginTop:'12px' }} /></Typography>
           <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400", justifyContent: 'space-between',display: 'flex',
              color: " #313131",marginTop:'-7px'
            }}>Calculated penalty<HelpIcon style={{ fontSize: "10px",  color: "#878787",marginTop:'12px' }} /></Typography>

          </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row',width:'calc(68% )', 
            height: '220px',alignItems: 'center', 
          }}>
        {dataList.slice(Math.abs(2-currentSlideIndex),currentSlideIndex)
          .map((slide, index) => (
            <Box sx={{width:'calc(100% )',marginLeft:'3%'}} key={index} className="slide">
              <Penalty dataList={dataList} index={index} mortgageName={slide.name}
               isSelected={isCheckedCheckboxSendQuotes.includes(slide.name)}
               onChange={() => handleCheckboxChangeSendQuote(slide.name)}
              />
            </Box>
          ))}
</Box>
      </Box>}  
    


      <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    Height: '80%',
    gap:'7px',
   
   width: 'calc(100% - 30px)',
  marginLeft:'15px',
  marginRight:'15px',
 boxShadow:' 0px -4px 4px rgba(217, 217, 217, 0.25)',
   backgroundColor:'white'
  }}
>
<Box
        sx={{
          width: '100%',   display: 'flex', flexDirection: "row", justifyContent: 'center',  alignItems:'center',
         
        }}

      >
        
        <Button variant="contained"  onMouseEnter={handleMouseEnterSendQuote} 
        onMouseLeave={handleMouseLeaveSendQuote} onClick={handlePopUp} disabled={!isAnyCheckboxChecked}   style={{
            color: "white", textTransform: "none",  padding: "5px 8px 5px 8px ",
            background: !isHoveredSendQuote && !isAnyCheckboxChecked  ? '#939393' : isHoveredSendQuote   ? '#074554' : '#1F6373',
            border:!isAnyCheckboxChecked ?"1px solid #939393" : "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal", 
            lineHeight: "21px", fontWeight: "500", fontSize: "14px", width: "100%", borderRadius: "4px",
          }} startIcon={<img src={sendicon} alt="" />}>
           Send quotes
          </Button>

      
      </Box> 
      <Box
        sx={{
          width: 'calc(100% )',   display: 'flex', flexDirection: "row", justifyContent: 'center',  alignItems:'center',gap:'2%' 
         
        }}

      >
        <Box
        sx={{   width:  'calc(48% )',   display: 'flex', flexDirection: "row", justifyContent: 'center',  alignItems:'center',     
        }}  >
        <Button variant="contained" onMouseEnter={handleMouseEnterSendToMail} 
        onMouseLeave={handleMouseLeaveSendToMail}   style={{
          color:isHoveredSendToMail?"white": "#1F6373",textTransform: "none",  padding: "5px 8px 5px 8px ",
            background:isHoveredSendToMail?"#1F6373 ":"#D2E0E3", border: "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal",
             lineHeight: "21px",fontWeight: "500", fontSize: "14px", width: "100%", borderRadius: "4px", marginBottom: '15px'
          }}
            startIcon={<MailOutlineIcon  style={{  color:isHoveredSendToMail?"white": "#1F6373", }}  />}>
            Send to email
          </Button></Box>
          <Box
        sx={{   width: 'calc(50% )',   display: 'flex', flexDirection: "row", justifyContent: 'center',  alignItems:'center',     
        }}  >
          <Button variant="contained" onMouseEnter={handleMouseEnterDownloadResult} 
        onMouseLeave={handleMouseLeaveDownloadResult}  style={{
          color:isHoveredDownloadResult?"white": "#1F6373", textTransform: "none", padding: "5px 8px 5px 8px ",
            background:isHoveredDownloadResult?"#1F6373 ":"#D2E0E3", border: "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal",
             lineHeight: "21px",minWidth:'164px',
            fontWeight: "500", fontSize: "14px", width: "100%", borderRadius: "4px", marginBottom: '15px'
          }}  startIcon={<GetAppIcon style={{  color:isHoveredDownloadResult?"white": "#1F6373", }} />}>
            Download result
          </Button>
          </Box> 
      
      </Box> 
   
      </Box> 
      {showPopup && (
  <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '1075px',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '30%',
               
                  width: '308px',
                  height: '136px',
                  backgroundColor: '#fff',                
                  border: '1px solid #CFC9C9',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                  boxSizing: 'border-box',
                  zIndex: '1'
                }}
              >
                <IconButton onClick={handleCancel} style={{ color: '#939393', position: 'absolute', width: '20px', height: '20px', left: '90%',
                 top: '10px' }} >
                  <HighlightOffIcon ></HighlightOffIcon>
                </IconButton>
                
                <img src={Tick} alt="" style={{ marginTop: "10px"}} />
              
                <Typography style={{ fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', 
                textAlign: "center",marginTop:'15px' }}>Your quote has been sent successfully</Typography>
              
              </Box>
            </Box>
            )}




          
          </Grid>
    );
}
export default SendQuotes;