import Box from '@mui/material/Box';
import { Button, Grid, Typography, Divider } from "@mui/material";
import { useState, useEffect, useRef } from 'react';
import '../App.css';
import Header from './NeholaHeader'
import HeaderMobile from './NeholaHeaderMobile';
import Footer from './NeholaFooter';
import AppBar from "@mui/material/AppBar";
import MenuItem from '@mui/material/MenuItem';
import PreviewIcon from '@mui/icons-material/Preview';
import logo from './images/logo.png'
import EmailIcon from '@mui/icons-material/Email';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CallIcon from '@mui/icons-material/Call';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NoImage from "./images/noimage.png"
import axios from "axios";
import { API_URL } from "./constants";
import { Link } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import BuilderSignUp from './BuilderSignUp';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function PersonalProjects() {
  const navigate = useNavigate();

  let constructionhomeid;
  const [isEnable, setIsEnable] = useState(false);
  const [currentPage, setCurrentPage] = useState('Nehola');
  const [projects, setProjects] = useState([]);
  const [builders, setBuilders] = useState([]);
  const [images, setImages] = useState([]);
  const [lastAddedBuilder, setLastAddedBuilder] = useState(null);
  const [lastAddedListingAgent, setLastAddedListingAgent] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTabValue, setSelectedTabValue] = useState('All');

  const token = localStorage.getItem('token')
  console.log("token",token)
  const login_id = localStorage.getItem('login_id')
  console.log("login_id",login_id); 
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update the active tab when a tab is clicked
  };
  // const builderName = localStorage.getItem('user_name');   
  // async function fetchData() {
  //   const builder = localStorage.getItem('builder_name'); 
  //   const listingagentid= localStorage.getItem('myprofilelisting_agent_id')
  //   //console.log("listingagentid",listingagentid)
  //  // console.log("builder",builder)
  //   try {
  //       const response = await axios.post(`${API_URL}/personal-project-details`, {          
  //         listingagentid: listingagentid,
  //         builder:builder,
  //     });
  //       setProjects(response.data);

  //       const index = response.data.findIndex((project) => project.sale_status === "sold out");

  //       if (index !== -1) {
  //         const newSoldOutFlags = [...isSoldOut];
  //         newSoldOutFlags[index] = true;
  //         setIsSoldOut(newSoldOutFlags);
  //       }

  //       const index1 = response.data.findIndex((project) => project.sale_status === "hide post");

  //       if (index1 !== -1) {
  //         const newSoldOutFlags = [...isHidePost];
  //         newSoldOutFlags[index1] = true;
  //         setisHidePost(newSoldOutFlags);
  //       }


  //   } 
  //   catch (error) {
  //       console.error('Error:', error.message);
  //   }
  // }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  async function fetchBuilderData() {
    // const builder = localStorage.getItem('builder_name'); 
   // const listingagentid = localStorage.getItem('myprofilelisting_agent_id')
    //console.log("listingagentid",listingagentid)
    // console.log("builder",builder)
    try {
      const response = await axios.post(`${API_URL}/personal-builder-details`, {
       // listingagentid: listingagentid,
        token: token,
      });
      setBuilders(response.data.projects);
    //  console.log("builderdata",response.data.projects.length)
    localStorage.setItem('myprofilelisting_agent_id', response.data.listing_agent_id);
      localStorage.setItem('user_name', response.data.username);
      if (response.data.projects.length > 0) {
        setIsEnable(true)
        setLastAddedBuilder(response.data.projects[response.data.projects.length - 1].builder_name);
        setLastAddedListingAgent(response.data.projects[response.data.projects.length - 1].listing_agent_id)
        handleSelectedbuilder(response.data.projects[response.data.projects.length - 1].builder_name, response.data.projects[response.data.projects.length - 1].listing_agent_id)
        
      }

    }
    catch (error) {
      console.error('Error:', error.message);
    }
  }

  useEffect(() => {
    fetchBuilderData(); // This will run only at page load
  }, []);
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const openRepostPopup = (index) => {
    setIsHidePopUp(true);
    setSelectedIndex(index)
  };
  const closeRepostPopup = (index) => {
    setIsHidePopUp(false);

  };
  const openPopup = (index) => {
    setShowPopup(true);
    setSelectedProject(index);
    setImages(projects[index].image_list);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedProject(null);
  };

  const [showSlider, setShowSlider] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleAddMoreImages = () => {

    setShowSlider(true);
    setShowPopup(false)
  };
  const closePopupAddMore = () => {

    setShowSlider(false);
    setShowPopup(true)
  };
  const handleRePostConfirm = async (index) => {
    // console.log("confirmid",projects[index].construction_home_id)
    const newHidePost = [...isHidePost];
    newHidePost[index] = false;
    setisHidePost(newHidePost);
    constructionhomeid = projects[index].construction_home_id
    const builder = projects[index].builder
    try {
      const response = await axios.post(`${API_URL}/update-repost`, {
        constructionhomeid: constructionhomeid,
      });

      // console.log("details",response.data)

      projects[index].sale_status = response.data[0].sale_status

    }
    catch (error) {
      console.error('Error:', error.message);
    }
    closeRepostPopup()


  };

  const showPreviousImage = () => {
    setCurrentImageIndex((prevIndex) => {
      if (prevIndex === 0) {
        return images.length - 1;
      } else {
        return prevIndex - 1;
      }
    });
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const articlesPerPage = 9;
  const totalPages = Math.ceil(projects.length / articlesPerPage);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const startIndex = (currentPageNumber - 1) * articlesPerPage;
  const endIndex = Math.min(startIndex + articlesPerPage, projects.length);

  const displayedprojects = projects.slice(startIndex, endIndex);

  const handlePageClick = (page) => {
    setCurrentPageNumber(page);
  };

  const [signInPopUp, setSignInPopup] = useState(false);
  const [signUpPopUp, setSignUpPopUp] = useState(false);
  const handleSignUpButton = () => {
    setSignInPopup(true);
  };
  const [showDropdown, setShowDropdown] = useState(new Array(displayedprojects.length).fill(false));
  const [isSoldOut, setIsSoldOut] = useState(new Array(displayedprojects.length).fill(false));
  const [isHidePost, setisHidePost] = useState(new Array(displayedprojects.length).fill(false));
  const [isHidePopUp, setIsHidePopUp] = useState(false);
  const [isEditEnable, setIsEditEnable] = useState(false);

  const handleEllipsisClick = (index) => {
    const newShowDropdown = [...showDropdown];
    newShowDropdown[index] = !newShowDropdown[index];
    setShowDropdown(newShowDropdown);
  };




  localStorage.setItem('builder_name', lastAddedBuilder)

  const handleEllipsisClickOption = async (value, index) => {
    //console.log("construction home id", projects[index].construction_home_id )
    if (value === "edit") {
      localStorage.setItem('construction_home_edit_id', projects[index].construction_home_id)
      setIsEditEnable(true);
      navigate('/newprojectform', { state: { isEditEnable: true } });

    } else if (value === "HidePost") {
      const newHidePost = [...isHidePost];
      newHidePost[index] = true;
      setisHidePost(newHidePost);

      constructionhomeid = projects[index].construction_home_id
      try {
        const response = await axios.post(`${API_URL}/update-hidepost`, {
          constructionhomeid: constructionhomeid,
        });

        //  projects[index].sale_status = 'hide post'
        // console.log("details",response.data)

      }
      catch (error) {
        console.error('Error:', error.message);
      }
    }
    // else if (value === "ViewPost")
    // {

    // }
    else if (value === "Sold Out") {
      const newSoldOut = [...isSoldOut];
      newSoldOut[index] = true;
      setIsSoldOut(newSoldOut);


      constructionhomeid = projects[index].construction_home_id
      try {
        const response = await axios.post(`${API_URL}/update-soldout`, {
          constructionhomeid: constructionhomeid,
        });

        // console.log("details",response.data)
        fetchBuilderData();
      }
      catch (error) {
        console.error('Error:', error.message);
      }

    }
    const newShowDropdown = [...showDropdown];
    newShowDropdown[index] = !newShowDropdown[index];
    setShowDropdown(newShowDropdown);
  };
  const handleAddNewProject = () => {
    localStorage.removeItem('construction_home_id');
    window.location.href = '/newprojectform'

  }

  const dropdownRef = useRef(null);

  // Function to handle global clicks outside of the dropdown
  const handleGlobalClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Close the dropdown
      setShowDropdown(new Array(displayedprojects.length).fill(false));
    }
  };

  // Add an event listener when the component mounts
  useEffect(() => {
    window.addEventListener('mousedown', handleGlobalClick);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('mousedown', handleGlobalClick);
    };
  }, []);

  const handlePreSale = async () => {
    setSelectedTabValue('Pre-sale');
    setisOpenTab(false)
    setProjects([]);
    setIsSoldOut([]);
    setisHidePost([]);
    const listingagent = lastAddedListingAgent
    const buildername = lastAddedBuilder
    //  console.log('buildername',buildername)
    //   console.log('listingagent',listingagent)
    try {
      const response = await axios.post(`${API_URL}/personal-project-Presale`, {
        listingagentid: listingagent,
        builder: buildername,
      });
      setProjects(response.data);

    }
    catch (error) {
      console.error('Error:', error.message);
    }
  }

  const handleOnSale = async () => {
    setSelectedTabValue('On-sale');
    setisOpenTab(false)
    setProjects([]);
    setIsSoldOut([]);
    setisHidePost([]);
    const listingagent = lastAddedListingAgent
    const buildername = lastAddedBuilder
    //  console.log('buildername',buildername)
    //   console.log('listingagent',listingagent)
    try {
      const response = await axios.post(`${API_URL}/personal-project-onsale`, {
        listingagentid: listingagent,
        builder: buildername,
      });
      setProjects(response.data);

    }
    catch (error) {
      console.error('Error:', error.message);
    }
  }



  const handleSoldOut = async () => {
    setSelectedTabValue('Sold out');
    setisOpenTab(false)
    setProjects([]);
    setIsSoldOut([]);
    setisHidePost([]);
    const listingagent = lastAddedListingAgent
    const buildername = lastAddedBuilder
    // console.log('buildername',buildername)
    // console.log('listingagent',listingagent)
    try {
      const response = await axios.post(`${API_URL}/personal-project-soldout`, {
        listingagentid: listingagent,
        builder: buildername,
      });
      setProjects(response.data);

      const soldOutIndices = response.data.reduce((acc, project, index) => {
        if (project.sale_status === "Sold Out") {
          acc.push(index);
        }
        return acc;
      }, []);

      if (soldOutIndices.length > 0) {
        const newSoldOutFlags = Array(response.data.length).fill(false); // Initialize with false
        soldOutIndices.forEach((index) => {
          newSoldOutFlags[index] = true;
        });
        setIsSoldOut(newSoldOutFlags);
      }



    }
    catch (error) {
      console.error('Error:', error.message);
    }
  }

  const handleHidePost = async () => {
    setSelectedTabValue('Hide post');
    setisOpenTab(false)
    setProjects([]);
    setIsSoldOut([]);
    setisHidePost([]);
    const listingagent = lastAddedListingAgent
    const buildername = lastAddedBuilder
    // console.log('buildername',buildername)
    // console.log('listingagent',listingagent)
    try {
      const response = await axios.post(`${API_URL}/personal-project-hidepost`, {
        listingagentid: listingagent,
        builder: buildername,
      });
      setProjects(response.data);

      const hidePostIndices = response.data.reduce((acc, project, index) => {
        if (project.sale_status === "hide post") {
          acc.push(index);
        }
        return acc;
      }, []);

      if (hidePostIndices.length > 0) {
        const newHidePostFlags = Array(response.data.length).fill(false); // Initialize with false
        hidePostIndices.forEach((index) => {
          newHidePostFlags[index] = true;
        });
        setisHidePost(newHidePostFlags);
      }

    }
    catch (error) {
      console.error('Error:', error.message);
    }
  }


  const handleSelectedbuilder = async (builderName, listingAgentId) => {
    setisOpenBuilder(false);
    setSelectedTabValue('All');
    setisOpenTab(false)
    setIsSoldOut([]);
    setisHidePost([]);
    setLastAddedBuilder(builderName)
    setLastAddedListingAgent(listingAgentId)
    const listingagent = listingAgentId
    const buildername = builderName
    try {
      const response = await axios.post(`${API_URL}/personal-project-details`, {
        listingagentid: listingagent,
        builder: buildername,
      });
      setProjects(response.data);
console.log("project details",response.data)
      const soldOutIndices = response.data.reduce((acc, project, index) => {
        if (project.sale_status === "Sold Out") {
          acc.push(index);
        }
        return acc;
      }, []);

      if (soldOutIndices.length > 0) {
        const newSoldOutFlags = Array(response.data.length).fill(false); // Initialize with false
        soldOutIndices.forEach((index) => {
          newSoldOutFlags[index] = true;
        });
        setIsSoldOut(newSoldOutFlags);
        setisOpenBuilder(false)
      }




      const hidePostIndices = response.data.reduce((acc, project, index) => {
        if (project.sale_status === "hide post") {
          acc.push(index);
        }
        return acc;
      }, []);

      if (hidePostIndices.length > 0) {
        const newHidePostFlags = Array(response.data.length).fill(false); // Initialize with false
        hidePostIndices.forEach((index) => {
          newHidePostFlags[index] = true;
        });
        setisHidePost(newHidePostFlags);
      }



    }
    catch (error) {
      console.error('Error:', error.message);
    }
    // You can add more code to handle the selected builder here.
  
  };
  const [showIcon, setShowIcon] = useState(Array(projects.length).fill(false));

  const handleContact = (index) => {
    const updatedShowContactInfo = [...showIcon];
    updatedShowContactInfo[index] = !updatedShowContactInfo[index];
    setShowIcon(updatedShowContactInfo);
  };
  const handleSelectedBuilderChange = (event) => {
    const selectedValue = event.target.value;
    setLastAddedBuilder(selectedValue);
   // setisOpenBuilder(!isOpenBuilder);
    // Perform actions based on the selected builder here if needed
  };
  const [isOpenBuilder, setisOpenBuilder] = useState(false);

  const handleBuilderDropDown = () => {
    setisOpenBuilder(!isOpenBuilder); 
    setSelectedTabValue('All');  

  };

  const [isOpenTab, setisOpenTab] = useState(false);

  const handleTabDropDown = () => {
    setisOpenTab(!isOpenTab);   

  };

  return (
    <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>
      <Box sx={{
        height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
        padding: 0, display: { md: 'flex', sm: 'none', xs: 'none' }
      }}>

        <Header currentPage={currentPage}></Header>
      </Box>
      <Box sx={{
        height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
        padding: 0, display: { md: 'none', sm: 'flex', xs: 'flex' }
      }}>
        <HeaderMobile ></HeaderMobile>
      </Box>
      <Box sx={{
        width: '100%', display: 'flex', flexDirection: { md: 'column', sm: 'column', xs: 'column' }, height: '100%',
        marginLeft: { md: '6%', sm: '5%', xs: '5%' }, marginRight: { md: '5%', sm: '5%', xs: '5%' },
        marginTop: { md: '30px', sm: '30px', xs: '25px' }, gap: { md: '0', sm: '20px', xs: '0px' },
        marginBottom:  { md: '10px', sm: '10px', xs: '5px' }, justifyContent: { xs: 'center', sm: 'left', md: 'left' },
        alignItems: { xs: 'center', sm: 'flex-start', md: 'flex-start' },
      }}> 
       <Box sx={{ width: '100%' , display: 'flex', flexDirection:{ md:  'row', sm:  'row', xs: 'column' },
        height: '100%',justifyContent:'space-between',position:'relative', }}>
  {isEnable &&          <Box sx={{ width:{ md: '40%', sm: '50%', xs: '100%' } , display: 'flex', flexDirection: 'row',
        height: '100%',justifyContent:'space-between',position:'relative', }}>


           <Box sx={{ width: { md: '50%', sm: '50%', xs: '50%' } , display: 'flex', flexDirection: 'row', display: 'flex',
          alignItems: 'flex-start',
        height: '100%', gap: { md:  '15px', sm:  '35px', xs:  '15px' } ,justifyContent:'space-between'}}  
         className="dropdown__toggle" onClick={handleBuilderDropDown}>
          <Button
    variant="text"
 
    sx={{
      width: { md:'234px', sm:'170px', xs: '155px' },
      height:{ md:'45px', sm:'40px', xs: '35px' } ,
      borderRadius: '40px',
    //  top:{ md:'115px', sm:'110px', xs: '80px' },
      background: '#EAE0E0',
      textTransform: 'none',
      color: '#3A3A3A',
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      position: 'absolute',
      fontFamily: 'poppins',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between', // Align items at start, middle, and end
      padding:{ md:'0 8px', sm:'0 8px', xs: '0 6px' } , // Adjust padding as needed
      '&:hover': {
        background: '#EAE0E0',
        textTransform: 'none',
        color: '#3A3A3A',
      },
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
    {lastAddedBuilder && lastAddedBuilder.length > 0 && (
        <>
      <Box
        sx={{
          width:{ md: '35px', sm: '30px', xs:  '25px' },
          height:{ md: '35px', sm: '30px', xs:  '25px' },
          borderRadius: '50%',
          background: '#2975AB',
          fontFamily: 'poppins',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          fontSize: { md: '24px', sm: '24px', xs:  '16px' },
          fontWeight: 'bold',
          marginRight: { md: '16px', sm: '12px', xs:  '5px' },
        }}
      >
        {lastAddedBuilder.charAt(0).toUpperCase()}
      </Box>
      <span>{lastAddedBuilder}</span>
      </>
      )}
    </Box>
    {isOpenBuilder ? (
      <ArrowDropUpIcon sx={{ fontSize: { md: '32px', sm: '28px', xs:  '22px' } }} />
    ) : (
      <ArrowDropDownIcon sx={{ fontSize: { md: '32px', sm: '28px', xs:  '22px' } }} />
    )}
  </Button> </Box>
  <Box sx={{ width: { md: '50%', sm: '50%', xs: '50%' } , display: 'flex', flexDirection: 'row', display: 'flex',
          alignItems: 'flex-start', height: '100%', gap: { md:  '35px', sm:  '35px', xs:  '15px' } ,
          justifyContent: { md:'space-between', sm: 'space-between', xs: 'flex-end' },}}  
           className="dropdown__toggle" onClick={handleTabDropDown}>
          <Button
    variant="text"
 
    sx={{
      width: { md:'234px', sm:'170px', xs: '155px' },
      height:{ md:'45px', sm:'40px', xs: '35px' } ,
      borderRadius: '40px',
      //top:'155px',
     background: '#EAE0E0',
      textTransform: 'none',
      color: '#3A3A3A',
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      position: 'absolute',
      fontFamily: 'poppins',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between', // Align items at start, middle, and end
      padding:{ md:'0 16px', sm:'0 16px', xs: '0 6px' } , // Adjust padding as needed
      '&:hover': {
        background: '#EAE0E0',
        textTransform: 'none',
        color: '#3A3A3A',
      },
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
  
        <>
      <Box
        sx={{
          width:{ md: '30px', sm: '30px', xs:  '25px' },
          height: { md: '30px', sm: '30px', xs:  '25px' },
       
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: { md: '16px', sm: '12px', xs:  '5px' },
        }}
      >
       
      </Box>
      <span>{selectedTabValue}</span>
      </>
      
    </Box>
    {isOpenBuilder ? (
      <ArrowDropUpIcon sx={{ fontSize: { md: '32px', sm: '28px', xs:  '22px' } }} />
    ) : (
      <ArrowDropDownIcon sx={{ fontSize: { md: '32px', sm: '28px', xs:  '22px' } }} />
    )}
  </Button>

         
         
           
          </Box>

          </Box>}


        
            <Box sx={{
          width: { md: '30%', sm: '10%', xs: '100%' }, display: { md: 'flex', sm: 'flex', xs: 'none' }, flexDirection: 'row',
          height: '100%', gap: '20px', marginLeft: { md: '0', sm: '1%', xs: '5%' },right:0,
        }}>
  </Box>
            <Box sx={{
          width: { md: '30%', sm: '40%', xs: '100%' }, display: 'flex', flexDirection: 'row', marginTop: { md: 0, sm: 0, xs: '65px' },
          height: '100%', gap: '20px', marginLeft: { md: '0', sm: '1%', xs: '0' },right:0, 
        }}>
          <Box sx={{ width: '50%' , display: 'flex', flexDirection: 'column',
        height: '100%',justifyContent: { md:'space-between', sm: 'space-between', xs: 'flex-start' },position:'relative',
       }}>
          {isEnable && <Button onClick={handleAddNewProject} variant="contained" sx={{
            color: '#313131',  height: '30px', width: { md: '158px', sm: '158px', xs: '158px' }, borderRadius: "12px",
           border: "1px solid #F8A81D",
            fontFamily: "Roboto", textTransform: "none", fontStyle: "normal", fontWeight: "500",
            marginLeft: { md: 'auto', sm:'auto', xs:'auto' },
            fontSize: { md: "14px", sm: "14px", xs: "12px" },  background: '#F8A81D', 
            '&:hover': { bgcolor: "#FF8C00", color: '#313131', border: "1px solid #FF8C00" }
          
          }}>
          <AddCircleOutlineIcon sx={{ color: '#313131',width: { md:'16px', sm:'16px', xs: "14px" },height: { md:'16px', sm:'16px', xs: "14px" },
          marginRight: { md:'8px', sm:'8px', xs: "4px" }}}></AddCircleOutlineIcon> New Post
          </Button>}
          </Box>
          <Box sx={{ width: '50%' , display: 'flex', flexDirection:'row',
        height: '100%',justifyContent: { md:'space-between', sm: 'space-between', xs: 'flex-end' },position:'relative'}}>
          <Button onClick={() => window.location.href = '/add-builder-details'} variant="contained" sx={{
            color: '#fff', height: '30px', width: { md: '158px', sm: '158px', xs: '158px' }, borderRadius: "12px",
            background: '#EB5648', border: "1px solid #EB5648",
            fontFamily: "Roboto", textTransform: "none", fontStyle: "normal", fontWeight: "500",
            marginLeft: { md: 'auto', sm:'auto', xs:'auto' },
            fontSize: { md: "14px", sm: "14px", xs: "12px" },
            '&:hover': { bgcolor: "#D9210E", color: '#fff', border: "1px solid #D9210E" }

          }}>
           Add builders
          </Button>
          </Box>
        </Box> 

        {isOpenBuilder && (
            <Box className="dropdown__menu" sx={{
              position: 'absolute', left: { md:'0px', sm:'5px', xs: '8px' }, top: { md:'60px', sm:'50px', xs: '40px' }, fontFamily: 'poppins', fontSize: '12px', fontWeight: '400',
              lineHeight: '20px', width: { md:'230px', sm:'170px', xs: '155px' }, background: '#F8F8F8', borderRadius: '8px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', zIndex: 1,
              color: '#2F2F2F', padding: '4px',
            }}>
              <style>
                {`
     .dropdown__menu .MuiMenuItem-root:hover {
      background-color: #504FA4 !important;
       color: white;
     }
   `}
              </style>
              {builders.map((builder) => (
                <MenuItem
                  key={builder.builder_name}
                  value={builder.builder_name}
                  onClick={() => handleSelectedbuilder(builder.builder_name, builder.listing_agent_id)}
                  sx={{
                    fontFamily: 'Poppins', fontSize: '14px',
                    fontWeight: '400', lineHeight: '20px', minHeight: '20px',
                    borderRadius: '4px', cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#504FA4',
                      color: 'white',
                    },
                  }}
                >
                  {builder.builder_name}
                </MenuItem>
              ))}
            </Box>)} 


             {isOpenTab && (
            <Box className="dropdown__menu" sx={{
              position: 'absolute', left: { md:'20%', sm:'25%', xs: '10px' },  top:{ md:'60px', sm:'50px', xs: '40px' }, fontFamily: 'poppins', fontSize: '12px', fontWeight: '400',
              lineHeight: '20px', width: { md:'234px', sm:'170px', xs: '155px' }, background: '#F8F8F8', borderRadius: '8px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', zIndex: 1,
              color: '#2F2F2F', padding: '4px',
            }}>
              <style>
                {`
     .dropdown__menu .MuiMenuItem-root:hover {
      background-color: #504FA4 !important;
       color: white;
     }
   `}
              </style>
              <MenuItem  onClick={() => handleSelectedbuilder(lastAddedBuilder, lastAddedListingAgent)}
               selected={selectedTabValue === 'All'}
               value ="All" style={{
                fontFamily: 'poppins', fontSize: '14px',
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
              All</MenuItem>
              <MenuItem onClick={handlePreSale}
               selected={selectedTabValue === 'Pre-sale'}
               value ="Pre-sale" style={{
                fontFamily: 'poppins', fontSize: '14px',
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
               Pre-sale</MenuItem>
               <MenuItem onClick={handleOnSale} value ="On-sale"  
                selected={selectedTabValue === 'On-sale'}
               style={{
                fontFamily: 'poppins', fontSize: '14px',
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
               On-sale</MenuItem>
               <MenuItem onClick={handleSoldOut} value ="Sold out"
                selected={selectedTabValue === 'Sold out'}
               style={{
                fontFamily: 'poppins', fontSize: '14px',
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
               Sold out</MenuItem>
               <MenuItem onClick={handleHidePost} value ="Hide post"
                selected={selectedTabValue === 'Hide post'}
               style={{
                fontFamily: 'poppins', fontSize: '14px',
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
               Hide post</MenuItem>
            </Box>)} 
          
          </Box>    
      
 
      </Box>
      <Box sx={{
        width:'100%', display: 'flex',
        flexDirection: 'row', height: { md: '50px', sm: '45px', xs: '20px' },borderBottom:'1px solid #F9F9F9 !important',
        marginLeft: { md: '6%', sm: '5%', xs: '5%' }, marginRight: { md: '5%', sm: '5%', xs: '5%' },
         marginTop: { md: '0px', sm: '10px', xs: '0px' },
        marginBottom: { md: '15px', sm: '20px', xs: '0' }, flexWrap: 'wrap',
      }}>
     
 </Box>
      <Box sx={{
        width: { md: 'calc(100%-10%)', sm: 'calc(100%-10%)', xs: 'calc(100%-10%)' }, display: 'flex',
        flexDirection: 'row', height: '100%',
        marginLeft: { md: '5%', sm: '5%', xs: '5%' }, marginRight: { md: '5%', sm: '5%', xs: '5%' }, marginTop: { md: '20px', sm: '20px', xs: '20px' },
        marginBottom: { md: '20px', sm: '20px', xs: '0' }, flexWrap: 'wrap',
      }}>
         <Box className= 'related-blog-grid'>
                    {displayedprojects.map((projects, index) => (
                        <Box key={index}   sx={{
                            width: { md: 'calc(100%-5%)', sm: 'calc(100%-5%)', xs: 'calc(100%-10%)' }, height: '100%',borderRadius:'8px',
                            padding: { md: '10px', sm: '5px', xs: '5px' }, display: { md: 'flex', sm: 'flex', xs: 'none' }, flexDirection: 'column', overflowX: 'hidden'
                           , gap: { md: '10px', sm: '10px', xs: '5px' },cursor: 'pointer', transition: 'background-color 0.3s',
                           '&:hover': {
                             backgroundColor: '#F9F9F9', 
                           },
                        }}  >

      
                            <Box  sx={{ width: { md: '100%', sm: '100%', xs: '100%' },  height: '100%',
                             gap:  { md: '8px', sm: '5px', xs: '8px' }, padding: '5px', display: 'flex', flexDirection: 'column',
                             }} >
                            <Box  onClick={() => openPopup(index)} sx={{ position: 'relative',width:{ md: '384px', sm: '100%', xs: '80%' },
                            height:{ md: '238px', sm: '238px', xs: '80%' },}}>
                            {projects.image_list.length > 0 && (<Box sx={{width:{ md: '384px', sm: '200px', xs: '325px' },
                            height:{ md: '238px', sm: '208px', xs: '238px' }}}>
                                    <img src={projects.image_list[0]} alt="First Image" 
                                    onClick={() => (isHidePost[index] ? openRepostPopup(index) : openPopup(index))} 
                                    style={{position: 'absolute', top: '0',
                                    left: '0', width: '100%',height: '100%', objectFit: 'cover',borderRadius:'8px'}}/></Box>
                                  )}
   { isSoldOut[index] && (
        <div
          style={{
            position: 'absolute',
            top: '50%', // Center vertically within the image
            left: '50%', // Center horizontally within the image
            transform: 'translate(-50%, -50%)', // Center both horizontally and vertically
            backgroundColor:'#CE3932', // Red background color for "Sold Out"
            color: '#fff',                
            fontSize: '32px',
            fontFamily: 'poppins',
            fontWeight: '600',
            height: '50px',
            width:'100%',
            whiteSpace: 'nowrap', 
            alignItems: 'center', // Center content vertically
            justifyContent: 'center', // Center content horizontally
           textAlign:'center'

          }}
        >
          Sold Out
        </div>
      )}  
       {isHidePost[index] && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#fff', // White overlay
            width: '100%',
            height: '100%',
            opacity: '0.9', // Adjust the overlay opacity as needed
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
        <FolderOffOutlinedIcon onClick={() => openRepostPopup(index)} sx={{width:'126px', height:'126px', opacity:'0.7'}}></FolderOffOutlinedIcon>
      </div> )}
    
  <Typography
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,     
      margin:'20px',
      backgroundColor: 'rgba(0, 0, 0, 0.57)', // Background color for the label
      color: '#fff', // Text color for the label
      padding: '6px 10px', borderRadius:'8px',
      fontSize: '12px',  fontFamily: 'poppins',fontWeight: '400',opacity: isHidePost[index] ?0.3 : 1
    }}
  >
   {projects.occupancy}
  </Typography>
  <Typography
    sx={{
      position: 'absolute',
      bottom: 0, 
      right: 0,    margin:'20px',
      backgroundColor: '#EB5648', 
      color: '#fff', // Text color for the label
      padding: '6px 10px', borderRadius:'8px',
      fontSize: '12px',  fontFamily: 'poppins',fontWeight: '400', opacity: isHidePost[index] ?0.3 : 1
    }}
  >
   {projects.sale_status}
  </Typography>   
</Box>
<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative',width:{ md: '384px', sm: '200px', xs: '325px' }  }}>
                                <Typography   onClick={() => openPopup(index)}  sx={{
                                    fontFamily: 'poppins', fontSize: '16px',
                                    lineHeight: '24px', fontWeight: '600', color: '#198CCD',
                                }} >{projects.project_name}</Typography>
                                <MoreVertOutlinedIcon 
                                 onClick={() => handleEllipsisClick(index)}></MoreVertOutlinedIcon>
                 <style>
                {`
     .dropdown__menu .MuiMenuItem-root:hover {
       background-color: #504FA4 !important;
       color: white;
     }
   `}
              </style>
              
 {showDropdown[index] && !isSoldOut[index] && (
 
         <Box  ref={dropdownRef}
         className="dropdown__menu"
         sx={{
           position: 'absolute',
           right: '0', // Adjust the position as needed
           top: '30px', // Adjust the position as needed
           fontFamily: 'poppins',
           fontSize: '12px',
           fontWeight: '400',
           lineHeight: '20px',
           width: '125px',
           background: '#F8F8F8',
           borderRadius: '8px',
           boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
           zIndex: 1,
           color: '#2F2F2F',
           padding: '4px',
         }}
       >
         <MenuItem
           onClick={() => handleEllipsisClickOption('edit',index)}
           value="option1"
           style={{
             fontFamily: 'poppins',
             fontSize: '14px',
             fontWeight: '400',
             lineHeight: '20px',
             minHeight: '10px',
             borderRadius: '4px',
           }}
         >
             <DriveFileRenameOutlineOutlinedIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
          Edit
         </MenuItem>
         {/* <MenuItem
           onClick={() => handleEllipsisClickOption('HidePost',index)}
           value="option1"
           style={{
             fontFamily: 'poppins',
             fontSize: '14px',
             fontWeight: '400',
             lineHeight: '20px',
             minHeight: '10px',
             borderRadius: '4px',
           }}
         >
             <FolderOffOutlinedIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
          Hide post
         </MenuItem> */}
         {!isHidePost[index] ? (
  <MenuItem
    onClick={() => handleEllipsisClickOption('HidePost', index)}
    value="option1"
    style={{
      fontFamily: 'poppins',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      minHeight: '10px',
      borderRadius: '4px',
    }}
  >
    <FolderOffOutlinedIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
    Hide post
  </MenuItem>
) : (
  <MenuItem
  onClick={() => openRepostPopup(index)}
    value="option1"
    style={{
      fontFamily: 'poppins',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      minHeight: '10px',
      borderRadius: '4px',
    }}
  >
    <PreviewIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
    View post
  </MenuItem>
)}

         <MenuItem
           onClick={() => handleEllipsisClickOption('Sold Out',index)}
           value="option2"
           style={{
             fontFamily: 'poppins',
             fontSize: '14px',
             fontWeight: '400',
             lineHeight: '20px',
             minHeight: '10px',
             borderRadius: '4px',
           }}
         >
             <AssignmentReturnedOutlinedIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
          sold out
         </MenuItem>
       </Box>
    
      )}
                                    {/* <Typography
                                      sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                      
                                        margin:'20px',
                                        backgroundColor: 'rgba(0, 0, 0, 0.57)', // Background color for the label
                                        color: '#fff', // Text color for the label
                                        padding: '6px 10px', borderRadius:'8px',
                                        fontSize: '12px',  fontFamily: 'poppins',fontWeight: '400', 
                                      }}
                                    >
                                    {projects.occupancy}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        position: 'absolute',
                                        bottom: 0, 
                                        right: 0,    margin:'20px',
                                        backgroundColor: '#EB5648', 
                                        color: '#fff', // Text color for the label
                                        padding: '6px 10px', borderRadius:'8px',
                                        fontSize: '12px',  fontFamily: 'poppins',fontWeight: '400', 
                                      }}
                                    >
                                    {projects.sale_status}
                                    </Typography> */}
                                  </Box>

                                {/* <Typography  onClick={() => openPopup(index)} sx={{
                                    fontFamily: 'poppins', fontSize: '16px',
                                    lineHeight: '24px', fontWeight: '600', color: '#198CCD',
                                }} >{projects.project_name}</Typography> */}
                                <Typography  onClick={() => openPopup(index)} sx={{
                                    fontFamily: 'poppins', fontSize: '16px',
                                    lineHeight: '18px', fontWeight: '400', color: '#313131',
                                }}>{projects.pricing}</Typography>                               
                                   
                                    <Typography  onClick={() => openPopup(index)} sx={{
                                        fontFamily: 'poppins', fontSize: '14px',
                                        lineHeight: '18px', fontWeight: '400', color: '#626262',    textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}> {projects.building_type} by {projects.developer}  </Typography>
                                     <Typography  onClick={() => openPopup(index)} sx={{
                                        fontFamily: 'poppins', fontSize: '14px',
                                        lineHeight: '18px', fontWeight: '400', color: '#626262',
                                    }}> {projects.address}</Typography>
                                     <Typography  onClick={() => openPopup(index)} sx={{
                                        fontFamily: 'poppins', fontSize: '14px',
                                        lineHeight: '18px', fontWeight: '400', color: '#626262',display: 'inline-block',
                                    }}><HotelOutlinedIcon  onClick={() => openPopup(index)} sx={{ verticalAlign: 'middle', color: '#626262'}} ></HotelOutlinedIcon> {projects.unit_size}</Typography>
                                     <Typography  onClick={() => openPopup(index)} sx={{
                                        fontFamily: 'poppins', fontSize: '14px',
                                        lineHeight: '18px', fontWeight: '400', color: '#626262',display: 'inline-block',
                                    }}><ViewQuiltOutlinedIcon  onClick={() => openPopup(index)} sx={{ verticalAlign: 'middle', color: '#626262'}} ></ViewQuiltOutlinedIcon> {projects.suite_size}</Typography>
                                                <Box sx={{ display: 'flex', flexDirection:{ md: 'row', sm: 'column', xs:  'row' },
                                                 gap:{ md: '30px', sm:'10px', xs:  '30px' } ,width:'100%',
                                                }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', width:'50%'}}>
                                     <Button size='small'  onClick={() => handleContact(index)} sx={{
        width:{ md:'120px', sm: '120px', xs: '80%' } , height: '32px', fontFamily: 'Poppins',
        fontStyle: 'normal', fontWeight: '400', fontSize:{ md:'14px', sm: '12px', xs:'14px' } , lineHeight: '21px', textAlign: 'center', color: '#000000',
        background: '#F8A81D', border: '1px solid #F8A81D', borderRadius: '4px',cursor:'pointer',textTransform:'none',
        '&:hover': { bgcolor: "#FF8C00", color: '#000000',border: "1px solid #FF8C00" },
      }}>Contact us</Button> </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', width:'50%'}}>
         {showIcon[index] && (
  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '30px' }}>
   

    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <a href={`tel:${projects.phone_number}`}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#3DDC84 ', // Change the background color as needed
            borderRadius: '12px',
            padding: '6px', // Adjust the padding as needed
          }}
        >
          <CallIcon sx={{ color: '#fff', fontSize: '20px' }} />
        </Box>
      </a>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <a href={`mailto:${projects.email_id}`}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#BB001B', // Change the background color as needed
            borderRadius: '12px',
            padding: '6px', // Adjust the padding as needed
          }}
        >
          <MailOutlineIcon sx={{ color: '#fff', fontSize: '20px' }} />
        </Box>
      </a>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
      <a href={`https://api.whatsapp.com/send?phone=${projects.whatsapp_number}`}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#25d366', // Change the background color as needed
            borderRadius: '12px',
            padding: '6px', // Adjust the padding as needed
          }}
        >
          <WhatsAppIcon sx={{ color: '#fff', fontSize: '20px' }} />
        </Box>
      </a>
    </Box>
  </Box>
)}

      </Box>
       </Box>
                            </Box>
                        </Box>

                    ))}
                </Box>
        <Box className='blog-grid'>
          {displayedprojects.map((projects, index) => (
            <Box key={index} sx={{
              width: { md: 'calc(100%-5%)', sm: 'calc(100%-10%)', xs: 'calc(100%-10%)' }, height: '100%', borderRadius: '8px',
              padding: { md: '10px', sm: '10px', xs: '5px' }, display: { md: 'none', sm: 'none', xs: 'flex' }, flexDirection: 'column', overflowX: 'hidden'
              , gap: { md: '10px', sm: '10px', xs: '5px' }, cursor: 'pointer', transition: 'background-color 0.3s',
              '&:hover': {
                backgroundColor: '#F9F9F9',
              },
            }}  >


              <Box sx={{ width: { md: '100%', sm: '80%', xs: '100%' },
               height: '100%', gap: '8px', padding: '5px', display: 'flex', flexDirection: 'column'}} >
                <Box sx={{ position: 'relative', width: '100%', height: '238px' }}>

                  {projects.image_list.length > 0 && (
                    <img src={projects.image_list[0]} alt="First Image" onClick={() => openPopup(index)} style={{ width: '100%', height: '238px', borderRadius: '8px' }} />
                  )}

                  <Typography onClick={() => openPopup(index)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,

                      margin: '20px',
                      backgroundColor: 'rgba(0, 0, 0, 0.57)', // Background color for the label
                      color: '#fff', // Text color for the label
                      padding: '6px 10px', borderRadius: '8px',
                      fontSize: '12px', fontFamily: 'poppins', fontWeight: '400',
                    }}
                  >
                    {projects.occupancy}
                  </Typography>
                  <Typography onClick={() => openPopup(index)}
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0, margin: '20px',
                      background: '#EB5648',
                      color: '#fff', // Text color for the label
                      padding: '6px 10px', borderRadius: '8px',
                      fontSize: '12px', fontFamily: 'poppins', fontWeight: '400',
                    }}
                  >
                    {projects.sale_status}
                  </Typography>
                  {isSoldOut[index] && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%', // Center vertically within the image
                        left: '50%', // Center horizontally within the image
                        transform: 'translate(-50%, -50%)', // Center both horizontally and vertically
                        backgroundColor: '#CE3932', // Red background color for "Sold Out"
                        color: '#fff',
                        fontSize: '32px',
                        fontFamily: 'poppins',
                        fontWeight: '600',
                        height: '50px',
                        width: '100%',
                        whiteSpace: 'nowrap',
                        alignItems: 'center', // Center content vertically
                        justifyContent: 'center', // Center content horizontally
                        textAlign: 'center'

                      }}
                    >
                      Sold Out
                    </div>
                  )}
                  {isHidePost[index] && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: '#fff', // White overlay
                        width: '100%',
                        height: '100%',
                        opacity: '0.9', // Adjust the overlay opacity as needed
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <FolderOffOutlinedIcon onClick={() => openRepostPopup(index)} sx={{ width: '126px', height: '126px', opacity: '0.7' }}></FolderOffOutlinedIcon>
                    </div>)}
                </Box>
                <Box sx={{
                  display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: { md: '10px', sm: '10px', xs: '5px' },
                  position: 'relative', width: { md: '384px', sm: '200px', xs: '325px' }
                }}>
                  <Typography onClick={() => openPopup(index)} sx={{
                    fontFamily: 'poppins', fontSize: '16px',
                    lineHeight: '24px', fontWeight: '600', color: '#198CCD',
                  }} >{projects.project_name}</Typography>
                  <MoreVertOutlinedIcon
                    onClick={() => handleEllipsisClick(index)}></MoreVertOutlinedIcon>
                  <style>
                    {`
     .dropdown__menu .MuiMenuItem-root:hover {
       background-color: #0C276C;
       color: white;
     }
   `}
                  </style>
                  {showDropdown[index] && !isSoldOut[index] && (
                    <Box
                      className="dropdown__menu"
                      sx={{
                        position: 'absolute',
                        right: '0', // Adjust the position as needed
                        top: '30px', // Adjust the position as needed
                        fontFamily: 'poppins',
                        fontSize: '12px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        width: '125px',
                        background: '#F8F8F8',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        zIndex: 1,
                        color: '#2F2F2F',
                        padding: '4px',
                      }}
                    >
                      <MenuItem
                        onClick={() => handleEllipsisClickOption('edit', index)}
                        value="option1"
                        style={{
                          fontFamily: 'poppins',
                          fontSize: '14px',
                          fontWeight: '400',
                          lineHeight: '20px',
                          minHeight: '10px',
                          borderRadius: '4px',
                        }}
                      >
                        <DriveFileRenameOutlineOutlinedIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                        Edit
                      </MenuItem>
                      {/* <MenuItem
           onClick={() => handleEllipsisClickOption('HidePost',index)}
           value="option1"
           style={{
             fontFamily: 'poppins',
             fontSize: '14px',
             fontWeight: '400',
             lineHeight: '20px',
             minHeight: '10px',
             borderRadius: '4px',
           }}
         >
             <FolderOffOutlinedIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
          Hide post
         </MenuItem> */}
                      {!isHidePost[index] ? (
                        <MenuItem
                          onClick={() => handleEllipsisClickOption('HidePost', index)}
                          value="option1"
                          style={{
                            fontFamily: 'poppins',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            minHeight: '10px',
                            borderRadius: '4px',
                          }}
                        >
                          <FolderOffOutlinedIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                          Hide post
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() => openRepostPopup(index)}
                          value="option1"
                          style={{
                            fontFamily: 'poppins',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            minHeight: '10px',
                            borderRadius: '4px',
                          }}
                        >
                          <PreviewIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                          View post
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => handleEllipsisClickOption('soldout', index)}
                        value="option2"
                        style={{
                          fontFamily: 'poppins',
                          fontSize: '14px',
                          fontWeight: '400',
                          lineHeight: '20px',
                          minHeight: '10px',
                          borderRadius: '4px',
                        }}
                      >
                        <AssignmentReturnedOutlinedIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                        sold out
                      </MenuItem>
                    </Box>

                  )}


                </Box>
                <Box sx={{ gap: '7px', flexDirection: 'column', display: 'flex' }}>
                  {/* <Typography sx={{
                                    fontFamily: 'poppins', fontSize: '16px',
                                    lineHeight: '24px', fontWeight: '600', color: '#198CCD',
                                }} >{projects.project_name}</Typography> */}
                  <Typography onClick={() => openPopup(index)} sx={{
                    fontFamily: 'poppins', fontSize: '16px',
                    lineHeight: '18px', fontWeight: '400', color: '#313131',
                  }}>{projects.pricing}</Typography>

                  <Typography onClick={() => openPopup(index)} sx={{
                    fontFamily: 'poppins', fontSize: '14px',
                    lineHeight: '18px', fontWeight: '400', color: '#626262',
                  }}> {projects.building_type} by {projects.developer}  </Typography>
                  <Typography onClick={() => openPopup(index)} sx={{
                    fontFamily: 'poppins', fontSize: '14px',
                    lineHeight: '18px', fontWeight: '400', color: '#626262',
                  }}> {projects.address}</Typography>
                  <Typography onClick={() => openPopup(index)} sx={{
                    fontFamily: 'poppins', fontSize: '14px',
                    lineHeight: '18px', fontWeight: '400', color: '#626262', display: 'inline-block',
                  }}><HotelOutlinedIcon onClick={() => openPopup(index)} sx={{ verticalAlign: 'middle', color: '#626262' }} ></HotelOutlinedIcon> {projects.unit_size}</Typography>
                  <Typography onClick={() => openPopup(index)} sx={{
                    fontFamily: 'poppins', fontSize: '14px',
                    lineHeight: '18px', fontWeight: '400', color: '#626262', display: 'inline-block',
                  }}><ViewQuiltOutlinedIcon onClick={() => openPopup(index)} sx={{ verticalAlign: 'middle', color: '#626262' }} ></ViewQuiltOutlinedIcon> {projects.suite_size}</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                      <Button size='small' onClick={() => handleContact(index)} sx={{
                        width: '120px', height: '32px', fontFamily: 'Poppins',
                        fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#000000',
                        background: '#F8A81D', border: '1px solid #F8A81D', borderRadius: '4px', cursor: 'pointer', textTransform: 'none', '&:hover': { bgcolor: "#FF8C00", color: '#000000', border: "1px solid #FF8C00" },
                      }} >Contact us</Button> </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                      {showIcon[index] && (
                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '10px' }}>


                          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                            <a href={`tel:${projects.phone_number}`}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  backgroundColor: '#3DDC84 ', // Change the background color as needed
                                  borderRadius: '12px',
                                  padding: '6px', // Adjust the padding as needed
                                }}
                              >
                                <CallIcon sx={{ color: '#fff', fontSize: '20px' }} />
                              </Box>
                            </a>
                          </Box>

                          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                            <a href={`mailto:${projects.email_id}`}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  backgroundColor: '#BB001B', // Change the background color as needed
                                  borderRadius: '12px',
                                  padding: '6px', // Adjust the padding as needed
                                }}
                              >
                                <MailOutlineIcon sx={{ color: '#fff', fontSize: '20px' }} />
                              </Box>
                            </a>
                          </Box>

                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                            <a href={`https://api.whatsapp.com/send?phone=${projects.whatsapp_number}`}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  backgroundColor: '#25d366', // Change the background color as needed
                                  borderRadius: '12px',
                                  padding: '6px', // Adjust the padding as needed
                                }}
                              >
                                <WhatsAppIcon sx={{ color: '#fff', fontSize: '20px' }} />
                              </Box>
                            </a>
                          </Box>
                        </Box>
                      )}

                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

          ))}
        </Box>
      </Box>


      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', width: '100%', gap: '10px', padding: '20px', paddingTop: '40px' }}>
        {/* Generate page number buttons */}
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index + 1}
            onClick={() => handlePageClick(index + 1)}
            variant={currentPageNumber === index + 1 ? "contained" : "outlined"}

            sx={{
              width: '28px', height: '28px', borderRadius: '4px', padding: 0, minWidth: 0,
              backgroundColor: currentPageNumber === index + 1 ? '#504FA4' : '#fff',
              color: currentPageNumber === index + 1 ? '#ffffff' : '#504FA4', border: '1px solid #504FA4 '
              , '&:hover': { bgcolor: "#504FA4", color: '#fff' }
            }}
          >
            {index + 1}
          </Button>
        ))}</Box>
      <Footer></Footer>
      {showPopup && selectedProject !== null && (

        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '2',

          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: { md: '1150px', sm: '90%', xs: '90%' },
              height: { md: '650px', sm: '80%', xs: '80%' },
              backgroundColor: '#fff',
              border: '1px solid #CFC9C9',
              borderRadius: '12px',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: { md: '50px', sm: '50px', xs: '20px' },
              overflowY: 'auto',
            }}
          >

            <IconButton onClick={closePopup} sx={{
              color: '#939393', position: 'fixed', width: '46px', height: '46px',
              left: { md: '85.5%', sm: '89%', xs: '80%' }, top: { md: '1%', sm: '7.5%', xs: '1%' }
            }} >
              <HighlightOffIcon sx={{ width: '36px', height: '36px', }} ></HighlightOffIcon>
            </IconButton>

            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: { md: '30px', sm: '30px', xs: '10px' }, height: { md: '100%', sm: '400px', xs: '200px' }, }}>
              <Box
                sx={{ width: '70%', height: { md: '434px', sm: '400px', xs: '200px' }, display: 'flex', flexDirection: 'row', maxWidth: '70%', maxHeight: { md: '434px', sm: '400px', xs: '300px' } }}>


                <img
                  src={projects[selectedProject].image_list[0]}
                  alt={`Image ${selectedProject}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    cursor: 'pointer',
                    borderRadius: '4px',
                  }}
                  onClick={handleAddMoreImages}
                />
              </Box>
              <Box
                sx={{ width: '30%', height: '100%', display: 'flex', flexDirection: 'column', gap: { md: '30px', sm: '30px', xs: '10px' }, position: 'relative', }}>

                <img
                  src={projects[selectedProject].image_list.length < 2 ? NoImage : projects[selectedProject].image_list[1]}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    maxHeight: '200px',
                    maxWidth: '300px',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    border: projects[selectedProject].image_list.length < 2 ? '1px solid #939393' : '0px solid #939393'
                  }}></img>
                <Box onClick={handleAddMoreImages}
                  sx={{
                    top: { md: '42%', sm: '54%', xs: '52%' },
                    position: 'absolute',
                    background: projects[selectedProject].image_list.length < 3 ? 'none' : 'rgba(0, 0, 0, 0.43)',
                    color: '#fff',
                    width: '100%',
                    height: { md: '36.5%', sm: '46%', xs: '48%' },
                    borderRadius: '4px',
                    fontSize: '14px',
                    cursor: 'pointer'
                  }}
                >
                  <Typography sx={{
                    fontFamily: 'poppins', fontSize: { md: '51px', sm: '51px', xs: '32px' },
                    lineHeight: '24px', fontWeight: '500', color: '#fff', textAlign: 'center', paddingTop: { md: '30%', sm: '45%', xs: '48%' }
                  }} >  {projects[selectedProject].image_list.length > 3 ? `${projects[selectedProject].image_list.length - 3}+` : ''}</Typography>

                </Box>
                <img
                  src={projects[selectedProject].image_list.length < 3 ? NoImage : projects[selectedProject].image_list[2]}

                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    maxHeight: '200px',
                    maxWidth: '300px',
                    borderRadius: '4px',
                    border: projects[selectedProject].image_list.length < 3 ? '1px solid #939393' : '0px solid #939393'
                  }}
                />

              </Box>
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'column', width: '100%', gap: '10px', height: '100%',
              marginTop: { md: '-65px', sm: '25px', xs: '25px' }
            }}>
              <Typography sx={{
                fontFamily: 'poppins', fontSize: '20px',
                lineHeight: '24px', fontWeight: '600', color: '#000',
              }} >{projects[selectedProject].project_name}</Typography>
              <Box sx={{ display: 'flex', flexDirection: { md: 'row', sm: 'row', xs: 'column' }, width: '100%', gap: { md: '30px', sm: '30px', xs: '15px' } }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                  <a href={`mailto:${projects[selectedProject].email_id}`} style={{ textDecoration: 'none' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <MailOutlineIcon sx={{ color: '#313131', fontSize: '20px' }}></MailOutlineIcon>
                      <Typography
                        sx={{
                          fontFamily: 'poppins',
                          fontSize: '16px',
                          lineHeight: '24px',
                          fontWeight: '500',
                          color: '#313131',
                          paddingLeft: '5px'
                        }}
                      >
                        {projects[selectedProject].email_id}
                      </Typography>
                    </div>
                  </a>
                </Box>


                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                  <a
                    href={`tel:${projects[selectedProject].phone_number}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <CallIcon sx={{ color: '#313131', fontSize: '20px' }}></CallIcon>
                      <Typography
                        sx={{
                          fontFamily: 'poppins',
                          fontSize: '16px',
                          lineHeight: '24px',
                          fontWeight: '500',
                          color: '#313131',
                          paddingLeft: '5px',
                          textDecoration: 'none', // Remove underline
                        }}
                      >
                        {projects[selectedProject].phone_number}
                      </Typography>
                    </div>
                  </a>
                </Box>


                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${projects[selectedProject].whatsapp_number}`}
                    style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                  >
                    <WhatsAppIcon sx={{ color: '#313131', fontSize: '20px' }}></WhatsAppIcon>
                    <Typography
                      sx={{
                        fontFamily: 'poppins',
                        fontSize: '16px',
                        lineHeight: '24px',
                        fontWeight: '500',
                        color: '#313131',
                        paddingLeft: '5px'
                      }}
                    >
                      {projects[selectedProject].whatsapp_number}
                    </Typography>
                  </a>
                </Box>



              </Box>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', color: '#313131', }} >
                <span style={{ fontWeight: '500' }}>Project name :</span> <span style={{ fontWeight: '400' }}> {projects[selectedProject].project_name}</span>
              </Typography>

              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Building type :<span style={{ fontWeight: '400' }}> {projects[selectedProject].building_type}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Builder :<span style={{ fontWeight: '400' }}> {projects[selectedProject].developer}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Address :<span style={{ fontWeight: '400' }}> {projects[selectedProject].address}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Nearest Intersection :<span style={{ fontWeight: '400' }}> {projects[selectedProject].nearest_intersection}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Pricing :<span style={{ fontWeight: '400' }}> {projects[selectedProject].pricing}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Occupancy :<span style={{ fontWeight: '400' }}> {projects[selectedProject].occupancy}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Sales status : <span style={{ fontWeight: '400' }}>{projects[selectedProject].sale_status}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Number of storeys :<span style={{ fontWeight: '400' }}>{projects[selectedProject].number_of_stories}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Number of units :<span style={{ fontWeight: '400' }}>{projects[selectedProject].number_of_unit}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Unit size :<span style={{ fontWeight: '400' }}> {projects[selectedProject].unit_size}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Suite Sizes :<span style={{ fontWeight: '400' }}> {projects[selectedProject].suite_size}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Maintenance Fees :<span style={{ fontWeight: '400' }}> {projects[selectedProject].maintenance_fee}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Deposit Structure :<span style={{ fontWeight: '400' }}> {projects[selectedProject].deposit_structure}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Incentives* :<span style={{ fontWeight: '400' }}> {projects[selectedProject].incentives}</span>
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131', }} >
                Suite Features :<span style={{ fontWeight: '400' }}> {projects[selectedProject].suite_features}</span>
              </Typography>
              <Typography sx={{
                fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '500', color: '#313131',
                paddingBottom: '50px !important'
              }} >
                Building Amenities :<span style={{ fontWeight: '400' }}> {projects[selectedProject].building_amenities}</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {showSlider && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '2',

          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: { md: '890px', sm: '90%', xs: '300px' },
              height: { md: '600px', sm: '80%', xs: '400px' },
              backgroundColor: '#fff',
              border: '1px solid #CFC9C9',
              borderRadius: '12px',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: { md: '50px', sm: '30px', xs: '20px' },
              alignItems: 'center',

            }}
          >
            <IconButton onClick={closePopupAddMore} sx={{
              color: '#939393', position: 'absolute', width: '20px', height: '20px',
              left: { md: '96%', sm: '94%', xs: '90%' }, top: '12px'
            }} >
              <HighlightOffIcon ></HighlightOffIcon>
            </IconButton>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',

              }}
            >
              <Box
                sx={{
                  backgroundColor: '#313131',
                  width: { md: '40px', sm: '30px', xs: '20px' },
                  height: '56px',
                  borderRadius: '8px 0px 0px 8px',
                  justifyContent: 'center', display: 'flex', alignItems: 'center'
                }}
              >
                <IconButton
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={showPreviousImage}
                >
                  <ChevronLeftIcon style={{ color: 'white', width: '50px', height: '50px', textAlign: 'center' }} />
                </IconButton>
              </Box>
              <Box sx={{
                width: { md: '700px', sm: '500px', xs: '350px' }, height: { md: '500px', sm: '150px', xs: '350px' }, 
                alignItems: 'center'
              }}>
                <img
                  src={images[currentImageIndex]}
                  alt={`Image ${currentImageIndex + 1}`}
                  style={{
                    width: '100%', // Default width
                    height: '100%', // Default height
                  }}


                />
              </Box>
              <Box
                sx={{
                  backgroundColor: '#313131',
                  width: { md: '40px', sm: '30px', xs: '20px' },
                  height: '56px',
                  borderRadius: '0px 8px 8px 0px',
                  justifyContent: 'center', display: 'flex', alignItems: 'center'
                }}
              >
                <IconButton
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={showNextImage}
                >
                  <ChevronRightIcon style={{ color: 'white', width: '50px', height: '50px', textAlign: 'center' }} />
                </IconButton>
              </Box>
            </div>
          </Box>
        </Box>
      )}
      {signUpPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup}
          signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} />
      )}
      {signInPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup}
          signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} />
      )}

      {isHidePopUp && (

        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '2'
          }}
        >
          <Box
            sx={{
              position: 'absolute',

              width: '378px',
              height: '202px',
              backgroundColor: '#fff',

              border: '1px solid #CFC9C9',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '3'
            }}
          >
            {/* <IconButton onClick={closeRepostPopup} style={{
              color: '#939393', position: 'absolute', width: '20px', height: '20px',
              left: '347px', top: '12px'
            }} >
              <HighlightOffIcon ></HighlightOffIcon>
            </IconButton> */}

            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
              textAlign: "center"
            }}>Are you sure you want to repost this post?</Typography>
            <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
              <button size='small' onClick={closeRepostPopup} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins', fontStyle: 'normal',
                fontWeight: '400', fontSize: '14px', lineHeight: '21px', marginRight: '24px', textAlign: 'center', color: '#F8A81D',
                background: '#FFF', border: '1px solid #F8A81D', borderRadius: '4px', cursor: 'pointer',
                '&:hover': { bgcolor: "#FFFF", color: '#F8A81D', border: "1px solid #FF8C00" },
              }} >Cancel</button>
              <button size='small' onClick={() => handleRePostConfirm(selectedIndex)} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#504FA4', border: '1px solid #504FA4', borderRadius: '4px', cursor: 'pointer',
                '&:hover': { bgcolor: "#3A3990", color: '#313131', border: "1px solid #3A3990" },
              }}  >Re post </button>
            </Box>
          </Box>
        </Box>
      )}
    </Grid>
  );

}

export default PersonalProjects;