
import Box from '@mui/material/Box';
import { Button, Grid, Typography, TextField } from "@mui/material";
import { useState, useEffect } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import Footer from './NeholaFooter';
import Header from './NeholaHeader';
import HeaderMobile from './NeholaHeaderMobile';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import axios from "axios";
import { API_URL } from "./constants";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import { useLocation } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Select, MenuItem, InputLabel } from '@mui/material';
function NewProjectForm() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [currentPage, setCurrentPage] = useState('New construction homes');
  const [editPage, setEditPage] = useState(false);
  const [editImagePage, setEditImagePage] = useState(false);
  const listingagentid= localStorage.getItem('myprofilelisting_agent_id')
  const login_id = localStorage.getItem('login_id')
  const construction_home_edit_id = localStorage.getItem('construction_home_edit_id');
  const [isProcessing, setIsProcessing] = useState(false);
  const [homeProjectName, setHomeProjectName] = useState('');
  const [homeBuildingType, setHomeBuildingType] = useState('');
  const [homeDeveloper, setHomeDeveloper] = useState('');
  const [homeAddress, setHomeAddress] = useState('');
  const [homeNearestIntersection, setHomeNearestIntersection] = useState('');
  const [homePricing, setHomePricing] = useState('');
  const [homeOccupancy, setHomeOccupancy] = useState('');
  const [homeSalesStatus, setHomeSalesStatus] = useState('');
  const [homeNumberofStorys, setHomeNumberofStorys] = useState('');
  const [homeNumberofUnits, setHomeNumberofUnits] = useState('');
  const [homeUnitSizes, setHomeUnitSizes] = useState('');
  const [homeSuiteSizes, setHomeSuiteSizes] = useState('');
  const [homeMaintenanceFees, setHomeMaintenanceFees] = useState('');
  const [homeDepositStructure, setHomeDepositStructure] = useState('');
  const [homeIncentives, setHomeIncentives] = useState('');
  const [homeSuiteFeatures, setHomeSuiteFeatures] = useState('');
  const [homeBuildingAmenities, setHomeBuildingAmenities] = useState('');
  const [constructionHomeDetails, setConstructionHome] = useState({

    projectName: homeProjectName,
    buildingType: homeBuildingType,
    developer: homeDeveloper,
    address: homeAddress,
    nearestIntersection: homeNearestIntersection,
    pricing: homePricing,
    occupancy: homeOccupancy,
    salesStatus: homeSalesStatus,
    numberofStorys: homeNumberofStorys,
    numberofUnits: homeNumberofUnits,
    unitSizes: homeUnitSizes,
    suiteSizes: homeSuiteSizes,
    maintenanceFees: homeMaintenanceFees,
    depositStructure: homeDepositStructure,
    incentives: homeIncentives,
    suiteFeatures: homeSuiteFeatures,
    buildingAmenities: homeBuildingAmenities,
  })
  const handlechangeProjectName = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      projectName: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.projectName; 
      return updatedErrors;
    });
    
  }
  const handlechangeBuildingType = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      buildingType: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.buildingType; 
      return updatedErrors;
    });   
  }
  const handlechangeDeveloper = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      developer: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.developer; 
      return updatedErrors;
    });     
  }
  const handlechangeAddress = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      address: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.address; 
      return updatedErrors;
    });   
  }
  const handlechangeNearestIntersection = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      nearestIntersection: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.nearestIntersection; 
      return updatedErrors;
    });    
  }
  const handlechangePricing = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      pricing: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.pricing; 
      return updatedErrors;
    });    
  }
  const handlechangeOccupancy = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      occupancy: event.target.value,
    }));  
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.occupancy; 
      return updatedErrors;
    });  
  }
  const handlechangeSalesStatus = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      salesStatus: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.salesStatus; 
      return updatedErrors;
    });    
  }
  const handlechangeNumberOfStorys = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      numberofStorys: event.target.value,
    }));  
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.numberofStorys; 
      return updatedErrors;
    });  
  }
  const handlechangeNumberOfUnits = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      numberofUnits: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.numberofUnits; 
      return updatedErrors;
    });   
  }
  const handlechangeUnitSize = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      unitSizes: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.unitSizes; 
      return updatedErrors;
    });   
  }
  const handlechangeSuiteSize = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      suiteSizes: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.suiteSizes; 
      return updatedErrors;
    });     
  }
  const handlechangeDepositStructure = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      depositStructure: event.target.value,
    }));  
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.depositStructure; 
      return updatedErrors;
    });  
  }
 const handlechangeIncentives = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      incentives: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.incentives; 
      return updatedErrors;
    });    
  }
  const handlechangeSuitFeatures = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      suiteFeatures: event.target.value,
    }));  
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.suiteFeatures; 
      return updatedErrors;
    });   
  }
  const handlechangeBuildingAmenities = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      buildingAmenities: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.buildingAmenities; 
      return updatedErrors;
    });    
  }
  const handlechangeMaintenanceFees = (event) => {
    setConstructionHome((prevState) => ({
      ...prevState,
      maintenanceFees: event.target.value,
    })); 
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.maintenanceFees; 
      return updatedErrors;
    });    
  }
  
  const [hoveredIndex, setHoveredIndex] = useState(null);
    const [fileNames, setFileNames] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFilesEdit, setSelectedFilesEdit] = useState([]);
const handleFileChange = (event) => {
  
    const files = event.target.files;
    if (files) {
      const fileDataArray = Array.from(files).map((file) => file);

      setSelectedFiles((prevSelectedFiles) => [
        ...prevSelectedFiles,
        ...fileDataArray,
      ]);
      setFileNames((prevFileNames) => [...prevFileNames, ...files]);
    }
    setImageErrors({});
  };

  const handleRemoveFile = async(index) => {
    setImageErrors({});
    const updatedSelectedFiles = [...selectedFiles];
    const updatedFileNames = [...fileNames];
    updatedSelectedFiles.splice(index, 1);
    updatedFileNames.splice(index, 1);
    setSelectedFiles(updatedSelectedFiles);
    setFileNames(updatedFileNames);
    
  };
    const handleUploadButtonClick = () => {
      setImageErrors({});
      setEditImagePage(true)
      // Create a synthetic file input element
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*';
      fileInput.style.display = 'none';
      fileInput.multiple = true; // Allow multiple file selection
    
      fileInput.addEventListener('change', (e) => {
        const newFiles = Array.from(e.target.files);
        const filteredNewFiles = newFiles.filter((newFile) => {
          return !selectedFiles.some((selectedFile) => selectedFile.name === newFile.name);
        });

        if (filteredNewFiles.length > 0) {
          setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...filteredNewFiles]);
          const names = filteredNewFiles.map((file) => file.name);
          setFileNames((prevFileNames) => [...prevFileNames, ...names]);
        }
      });
    
      // Trigger the click event on the synthetic file input
      fileInput.click();
    };
    
let editconstructionhomeid;
let uploaddateaudit
     let imageListaudit
     const [errors, setErrors] = useState({});
     const [imageErrors, setImageErrors] = useState({});
    const handlePost = async () => {
    
     // console.log('editpage',editPage)
     if(editPage){
      if (!constructionHomeDetails.projectName) {
        errors.projectName = 'Project name is required';
      }
if (!constructionHomeDetails.buildingType) {
  errors.buildingType = 'Building Type is required';
  }
 if (!constructionHomeDetails.developer) {
  errors.developer = 'Developer is required';
  }
   if (!constructionHomeDetails.address) {
    errors.address = 'Address is required';
  }
 if (!constructionHomeDetails.nearestIntersection) {
  errors.nearestIntersection = 'Nearest Intersection is required';
  }
 if (!constructionHomeDetails.pricing) {
  errors.pricing = 'Pricing is required';
  }
  if (!constructionHomeDetails.occupancy) {
    errors.occupancy = 'Occupancy is required';
  }
if (!constructionHomeDetails.salesStatus) {
errors.salesStatus = 'Sales status is required';
  }
  if (!constructionHomeDetails.numberofStorys) {
    errors.numberofStorys = 'Number of storys is required';
  }
  if (!constructionHomeDetails.numberofUnits) {
    errors.numberofUnits = 'Number of units is required';
  }
 if (!constructionHomeDetails.unitSizes) {
  errors.unitSizes = 'Unit size is required';
  }
 if (!constructionHomeDetails.suiteSizes) {
  errors.suiteSizes = 'Suit size is required';
  }
   if (!constructionHomeDetails.maintenanceFees) {
    errors.maintenanceFees = 'Maintenance Fees is required';
  }
 if (!constructionHomeDetails.depositStructure) {
    errors.depositStructure = 'Deposit structure is required';
  }
  if (!constructionHomeDetails.incentives) {
    errors.incentives = 'Incentives structure is required';
  }
 if (!constructionHomeDetails.suiteFeatures) {
  errors.suiteFeatures = 'Suite features is required';
  }
   if (!constructionHomeDetails.buildingAmenities) {
    errors.buildingAmenities = 'Building amenities is required';
  }  
  console.log("len",selectedFiles.length)
  if(selectedFiles.length === 0){
    setImageErrors({ selectedFiles: 'Please upload your images' }); ;
  }
  if (((Object.keys(errors).length === 0) && (selectedFiles.length !== 0))) {
    setImageErrors({}); 
      setIsProcessing(true);
      try {
        const response = await axios.post(`${API_URL}/select-project-details`, {          
          construction_home_edit_id: construction_home_edit_id,
      });
      editconstructionhomeid = response.data[0].construction_home_id
     const constructionhomeid = response.data[0].construction_home_id
       const projectName = response.data[0].project_name
       const buildingType = response.data[0].building_type
       const developer = response.data[0].developer
       const address = response.data[0].address
       const nearestIntersection = response.data[0].nearest_intersection
       const pricing = response.data[0].pricing
       const occupancy = response.data[0].occupancy
       const salesStatus = response.data[0].sale_status
       const numberofStorys = response.data[0].number_of_stories
       const numberofUnits = response.data[0].number_of_unit
       const unitSizes = response.data[0].unit_size
       const suiteSizes = response.data[0].suite_size
        const maintenanceFees = response.data[0].maintenance_fee
        const depositStructure = response.data[0].deposit_structure
        const incentives = response.data[0].incentives
        const suiteFeatures = response.data[0].suite_features
        const buildingAmenities = response.data[0].building_amenities
       const builderid = response.data[0].builder_id
    uploaddateaudit =  response.data[0].upload_date
    imageListaudit = response.data[0].image_list;
      const response1 = await axios.post(`${API_URL}/insert-newprojectform-audit`, {
        constructionhomeid:constructionhomeid,
        projectName: projectName,
        buildingType: buildingType,       
        developer: developer,      
        address: address,
        nearestIntersection: nearestIntersection,
        pricing: pricing,
        occupancy:occupancy, 
        salesStatus: salesStatus,
        numberofStorys: numberofStorys,
        numberofUnits: numberofUnits,       
        unitSizes: unitSizes,      
        suiteSizes: suiteSizes,
        maintenanceFees: maintenanceFees,
        depositStructure: depositStructure,
        incentives:incentives, 
        suiteFeatures: suiteFeatures,
        buildingAmenities: buildingAmenities,
        builderid: builderid,
        uploaddate: uploaddateaudit,
        login_id: login_id,
      });
    } 

    catch (error) {
      setIsProcessing(false);
        console.error('Error:', error.message);
       
    }
    try {
      setIsProcessing(true);
      const construction_home_id = localStorage.getItem('construction_home_id');
     
      for (const file of imageListaudit) {
        const imageUploadResponse = await axios.post(`${API_URL}/upload-images-audit`, {
          construction_home_id: editconstructionhomeid,
          uploaddateaudit: uploaddateaudit,
          login_id: login_id,
          images: file
        });
       // console.log('Image upload response:', imageUploadResponse.data);
      }
    
     
    } catch (error) {
      setIsProcessing(false);
      console.error(error);
    }
    const constructionhomeid = editconstructionhomeid
    const projectName = constructionHomeDetails.projectName;
    const buildingType = constructionHomeDetails.buildingType;
    const developer = constructionHomeDetails.developer;
    const address = constructionHomeDetails.address;
    const nearestIntersection = constructionHomeDetails.nearestIntersection;
    const pricing = constructionHomeDetails.pricing;
    const occupancy = constructionHomeDetails.occupancy;
    const salesStatus = constructionHomeDetails.salesStatus;
    const numberofStorys = constructionHomeDetails.numberofStorys;
    const numberofUnits = constructionHomeDetails.numberofUnits;
    const unitSizes = constructionHomeDetails.unitSizes;
    const suiteSizes = constructionHomeDetails.suiteSizes;
    const maintenanceFees = constructionHomeDetails.maintenanceFees;
    const depositStructure = constructionHomeDetails.depositStructure;
    const incentives = constructionHomeDetails.incentives;
    const suiteFeatures = constructionHomeDetails.suiteFeatures;
    const buildingAmenities = constructionHomeDetails.buildingAmenities;
    const builderid = localStorage.getItem('myprofilelisting_agent_id');  
   // const listingagentid= localStorage.getItem('myprofilelisting_agent_id')
    try {
      setIsProcessing(true);
      const response = await axios.post(`${API_URL}/insert-newprojectform-update`, {
        constructionhomeid: constructionhomeid,
        projectName: projectName,
        buildingType: buildingType,       
        developer: developer,      
        address: address,
        nearestIntersection: nearestIntersection,
        pricing: pricing,
        occupancy:occupancy, 
        salesStatus: salesStatus,
        numberofStorys: numberofStorys,
        numberofUnits: numberofUnits,       
        unitSizes: unitSizes,      
        suiteSizes: suiteSizes,
        maintenanceFees: maintenanceFees,
        depositStructure: depositStructure,
        incentives:incentives, 
        suiteFeatures: suiteFeatures,
        buildingAmenities: buildingAmenities,
        builderid: builderid,
      });
     // console.log('Insert response:', response.data);
  }

  catch (error) {
    setIsProcessing(false);
    console.error(error);
  }
  try {
    setIsProcessing(true);
    const construction_home_id = localStorage.getItem('construction_home_id');
    const formData = new FormData();
    formData.append('constructionhomeid', constructionhomeid);
   // console.log("updateImage",selectedFiles)
  
    selectedFiles.forEach((file) => {
      formData.append('images', file);
    });  
    console.log('Image upload formData:', formData);
    const imageUploadResponse = await axios.post(`${API_URL}/upload-images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  
   
  } catch (error) {
    setIsProcessing(false);
    console.error(error);
  }
  setIsProcessing(false);
     }}
      else{
        //const errors = {}; 

        if (!constructionHomeDetails.projectName) {
          errors.projectName = 'Project name is required';
        }
  if (!constructionHomeDetails.buildingType) {
    errors.buildingType = 'Building Type is required';
    }
   if (!constructionHomeDetails.developer) {
    errors.developer = 'Developer is required';
    }
     if (!constructionHomeDetails.address) {
      errors.address = 'Address is required';
    }
   if (!constructionHomeDetails.nearestIntersection) {
    errors.nearestIntersection = 'Nearest Intersection is required';
    }
   if (!constructionHomeDetails.pricing) {
    errors.pricing = 'Pricing is required';
    }
    if (!constructionHomeDetails.occupancy) {
      errors.occupancy = 'Occupancy is required';
    }
 if (!constructionHomeDetails.salesStatus) {
  errors.salesStatus = 'Sales status is required';
    }
    if (!constructionHomeDetails.numberofStorys) {
      errors.numberofStorys = 'Number of storys is required';
    }
    if (!constructionHomeDetails.numberofUnits) {
      errors.numberofUnits = 'Number of units is required';
    }
   if (!constructionHomeDetails.unitSizes) {
    errors.unitSizes = 'Unit size is required';
    }
   if (!constructionHomeDetails.suiteSizes) {
    errors.suiteSizes = 'Suit size is required';
    }
     if (!constructionHomeDetails.maintenanceFees) {
      errors.maintenanceFees = 'Maintenance Fees is required';
    }
   if (!constructionHomeDetails.depositStructure) {
      errors.depositStructure = 'Deposit structure is required';
    }
    if (!constructionHomeDetails.incentives) {
      errors.incentives = 'Incentives structure is required';
    }
   if (!constructionHomeDetails.suiteFeatures) {
    errors.suiteFeatures = 'Suite features is required';
    }
     if (!constructionHomeDetails.buildingAmenities) {
      errors.buildingAmenities = 'Building amenities is required';
    }  
    console.log("len",selectedFiles.length)
    if(selectedFiles.length === 0){
      setImageErrors({ selectedFiles: 'Please upload your images' }); ;
    }
    if (((Object.keys(errors).length === 0) && (selectedFiles.length !== 0))) {
      setImageErrors({}); 
      const projectName = constructionHomeDetails.projectName;
      const buildingType = constructionHomeDetails.buildingType;
      const developer = constructionHomeDetails.developer;
      const address = constructionHomeDetails.address;
      const nearestIntersection = constructionHomeDetails.nearestIntersection;
      const pricing = constructionHomeDetails.pricing;
      const occupancy = constructionHomeDetails.occupancy;
      const salesStatus = constructionHomeDetails.salesStatus;
      const numberofStorys = constructionHomeDetails.numberofStorys;
      const numberofUnits = constructionHomeDetails.numberofUnits;
      const unitSizes = constructionHomeDetails.unitSizes;
      const suiteSizes = constructionHomeDetails.suiteSizes;
      const maintenanceFees = constructionHomeDetails.maintenanceFees;
      const depositStructure = constructionHomeDetails.depositStructure;
      const incentives = constructionHomeDetails.incentives;
      const suiteFeatures = constructionHomeDetails.suiteFeatures;
      const buildingAmenities = constructionHomeDetails.buildingAmenities;
     // const builderid = localStorage.getItem('myprofilebuilder_id');   
     const builderid= localStorage.getItem('myprofilelisting_agent_id')  
      try {
        setIsProcessing(true);
        const response = await axios.post(`${API_URL}/insert-newprojectform`, {
          projectName: projectName,
          buildingType: buildingType,       
          developer: developer,      
          address: address,
          nearestIntersection: nearestIntersection,
          pricing: pricing,
          occupancy:occupancy, 
          salesStatus: salesStatus,
          numberofStorys: numberofStorys,
          numberofUnits: numberofUnits,       
          unitSizes: unitSizes,      
          suiteSizes: suiteSizes,
          maintenanceFees: maintenanceFees,
          depositStructure: depositStructure,
          incentives:incentives, 
          suiteFeatures: suiteFeatures,
          buildingAmenities: buildingAmenities,
          builderid: builderid,
          login_id: login_id,
        });
       // console.log('Insert response:', response.data);
        const lastrowId = response.data.construction_home_id
       // console.log('lastrowId1', lastrowId);
       localStorage.setItem('construction_home_id',lastrowId)
       if (response.data.message === 'New Construction Home inserted successfully') {
       // console.log('Insert response check',);  
        const response = await axios.post(`${API_URL}/NewProject-thankyou`, {        
          listingagentid: localStorage.getItem('myprofilelisting_agent_id') ,
         
      });
    }
    
    }

    catch (error) {
      setIsProcessing(false);
      console.error(error);
    }
    try {
      setIsProcessing(true);
      const construction_home_id = localStorage.getItem('construction_home_id');
      const formData = new FormData();    
      formData.append('constructionhomeid', construction_home_id);
      formData.append('login_id', login_id);
      selectedFiles.forEach((file) => {
        formData.append('images', file);
      });     
      const imageUploadResponse = await axios.post(`${API_URL}/upload-images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
   
     // console.log('Image upload response:', imageUploadResponse.data);
    } catch (error) {
      setIsProcessing(false);
      console.error(error);
    }
    setIsProcessing(false);  
      }}
      if (((Object.keys(errors).length === 0) && (selectedFiles.length !== 0))) {
  window.location.href = '/projects'
      }
    };
    const location = useLocation();
    const isEditEnable = location.state && location.state.isEditEnable;

    async function fetchData() {
      const developerValue = localStorage.getItem('builder_name')
      setConstructionHome((prevState) => ({
        ...prevState,
        developer: developerValue,
      }));
     // console.log("isEditEnable",isEditEnable)
      if(isEditEnable){
       setEditPage(true)
    try {
        const response = await axios.post(`${API_URL}/select-project-details`, {          
          construction_home_edit_id: construction_home_edit_id,
      });
      setConstructionHome({
        projectName: response.data[0].project_name,
        buildingType: response.data[0].building_type,
        developer: response.data[0].developer,
        address: response.data[0].address,
        nearestIntersection: response.data[0].nearest_intersection,
        pricing: response.data[0].pricing,
        occupancy: response.data[0].occupancy,
        salesStatus: response.data[0].sale_status,
        numberofStorys: response.data[0].number_of_stories,
        numberofUnits: response.data[0].number_of_unit,
        unitSizes: response.data[0].unit_size,
        suiteSizes: response.data[0].suite_size,
        maintenanceFees: response.data[0].maintenance_fee,
        depositStructure: response.data[0].deposit_structure,
        incentives: response.data[0].incentives,
        suiteFeatures: response.data[0].suite_features,
        buildingAmenities: response.data[0].building_amenities
      });
      const imageList = response.data[0].image_list;
      setFileNames((response.data[0].image_list).map((imageUrl) => {
        const parts = imageUrl.split('-');
        return parts[parts.length - 1]; // Get the last part, which is the file name
      }))

      // Set selectedFiles and fileNames state variables
      setSelectedFiles(response.data[0].image_list);
      setSelectedFilesEdit(response.data[0].image_list)
      setEditImagePage(true)
     // console.log("selectedFile",selectedFiles)
    //  console.log("fileNmaes",fileNames)
    } 
    catch (error) {
        console.error('Error:', error.message);
    }}
    localStorage.removeItem('construction_home_id');
  }

  useEffect(() => {
   // console.log("builder_id",builder_id)
    fetchData();
  }, []);

  const [salesStatusData, setSalesStatusData] = useState([]);
  async function fetchSalesStatusData() {

  try {
      const response = await axios.get(`${API_URL}/select-SalesStatus-details`, );
      setSalesStatusData(response.data);
  } 
  catch (error) {
      console.error('Error:', error.message);
  }
 
}

useEffect(() => {
 // console.log("builder_id",builder_id)
 fetchSalesStatusData();
}, []);

 //console.log("images",selectedFiles)

 const [isTermsAndCondition, setIsTermsAndCondition] = useState(false);
 const [isChecked, setIsChecked] = useState(false);

 const handleCheckboxChange = (event) => {
  // console.log('Checkbox changed:', event.target.checked);
   setIsChecked(event.target.checked);
 };
 
 const handleTermsClick = () => {
  setIsTermsAndCondition(true);
 
}
const closePopupTerm = () => {
  setIsTermsAndCondition(false)
 
  
};
    return (
        <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>
            <Box sx={{
                height: '100% ', width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'flex', sm: 'none', xs: 'none' }
            }}>
                <Header currentPage={currentPage}></Header>

            </Box>
            <Box sx={{ display: { md: 'none', sm: 'flex', xs: 'flex' }, 
            flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  width: { md: '100%', sm: '100%', xs: '100%' },}}>
                <HeaderMobile currentPage={currentPage}></HeaderMobile>

            </Box>
            
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'row', height: '100%',
                paddingLeft: { md: '5%', sm: '5%', xs: '5%' }, marginRight: { md: '5%', sm: '5%', xs: '5%' }, marginTop: { md: '2%', sm: '10px', xs: '25px' },
                

            }}>             

                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: '24px', lineHeight: '20px', fontWeight: '500', color: '#313131', marginRight: 'auto',
                    }}>Fill your project details</Typography>
                  
            </Box>
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                paddingLeft: { md: '10%', sm: '5%', xs: '5%' }, paddingRight: { md: '10%', sm: '5%', xs: '5%' }, marginTop: { md: '2%', sm: '10px', xs: '25px' },
                gap:{ md: '30px', sm: '20px', xs: '0px' },marginBottom: { md: '1%', sm: '10px', xs: '25px' },

            }}> 
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display:{ md:'flex', sm:'flex', xs:'none' }, flexDirection: 'row', height: '100%',
                paddingLeft: { md: '0%', sm: '0%', xs: '5%' }, marginRight: { md: '5%', sm: '5%', xs: '5%' }, marginTop: { md: '2%', sm: '10px', xs: '25px' },
                gap:{ md: '30px', sm: '20px', xs: '0px' },marginBottom: { md: '1%', sm: '10px', xs: '25px' },

            }}>             
         
         <Box sx={{
                width: {  md: '100%', sm: '100%', xs: '100%'}, display: 'flex', flexDirection: 'column', height: '100%',
              gap:{ md: '15px', sm: '10px', xs: '5px' }, 

            }}>  
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
              gap:{ md: '5px', sm: '5px', xs: '5px' }

            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
                        Enter your project name
              </Typography>           
             <TextField onChange={(event) => { handlechangeProjectName(event) }} value={constructionHomeDetails.projectName} placeholder="Pickering city Centre  condos" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
              <div style={{ color: 'red' }}>{errors.projectName}</div>      
             </Box>      
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Building Type Condominium /Studio
              </Typography>           
             <TextField onChange={(event) => { handlechangeBuildingType(event) }} value={constructionHomeDetails.buildingType} placeholder="Three Bedroom Suites & Towns" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
               <div style={{ color: 'red' }}>{errors.buildingType}</div>       
             </Box> 
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Occupancy
              </Typography>           
             <TextField onChange={(event) => { handlechangeOccupancy(event) }} value={constructionHomeDetails.occupancy}  placeholder="October 2026" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                 <div style={{ color: 'red' }}>{errors.occupancy}</div>     
             </Box> 
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Sales status
              </Typography>           
             {/* <TextField  onChange={(event) => { handlechangeSalesStatus(event) }} value={constructionHomeDetails.salesStatus}  placeholder="Now selling" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} /> */}
                        <Select
  value={constructionHomeDetails.salesStatus}
  onChange={(event) => handlechangeSalesStatus(event)}
  placeholder="Now selling"
  variant="outlined"
  sx={{
    height: '36px',
    color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500",
    borderRadius: '8px',
   
  }}
  inputProps={{
    style: {
      height: '44px',
      color: "#626262",
      fontFamily: "poppins",
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "500",
    },
  }}
>
{salesStatusData.map((item) => (
      <MenuItem  key={item.id} value={item.name}  sx={{
        backgroundColor: 'white !important',
        paddingLeft: '10px',
        marginRight: '10px',
        color: "#626262",
      fontFamily: "poppins",
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "500",
        '&:hover': {
          borderRadius: '8px',
          paddingLeft: '10px',
          marginRight: '10px',
          backgroundColor: '#0C276C !important',
          color: 'white',
        }}}>
        {item.name}
      </MenuItem>
    ))}
</Select>

                 <div style={{ color: 'red' }}>{errors.salesStatus}</div>     
             </Box> 
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Builder name
              </Typography>           
             <TextField 
              //onChange={(event) => { handlechangeDeveloper(event) }} 
              value={constructionHomeDetails.developer}  placeholder="Mattamy  Homes" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9',pointerEvents: 'none', }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                  <div style={{ color: 'red' }}>{errors.developer}</div>    
             </Box>  
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Address
              </Typography>           
             <TextField  onChange={(event) => { handlechangeAddress(event) }} value={constructionHomeDetails.address}  placeholder="935 the Queensway, Etobicoke" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                    <div style={{ color: 'red' }}>{errors.address}</div>  
             </Box> 
         

            </Box> 

            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
              gap:{ md: '15px', sm: '10px', xs: '5px' }, 

            }}>  
            
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Nearest intersection
              </Typography>           
             <TextField  onChange={(event) => { handlechangeNearestIntersection(event) }} value={constructionHomeDetails.nearestIntersection}  placeholder="The Queensway & Islington Ave" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                   <div style={{ color: 'red' }}>{errors.nearestIntersection}</div>   
             </Box> 
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Pricing
              </Typography>           
             <TextField  onChange={(event) => { handlechangePricing(event) }} value={constructionHomeDetails.pricing}  placeholder="$478,900" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                   <div style={{ color: 'red' }}>{errors.pricing}</div>   
             </Box> 
        
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Number of storeys
              </Typography>           
             <TextField  onChange={(event) => { handlechangeNumberOfStorys(event) }} value={constructionHomeDetails.numberofStorys} placeholder="45,40,50,55" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                   <div style={{ color: 'red' }}>{errors.numberofStorys}</div>   
             </Box>
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Number of units
              </Typography>           
             <TextField type="number"  onChange={(event) => { handlechangeNumberOfUnits(event) }} value={constructionHomeDetails.numberofUnits}  placeholder="2000" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins", type:"number" , min:1, max:10,
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.numberofUnits}</div> 
             </Box>
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Unit size
              </Typography>           
             <TextField  onChange={(event) => { handlechangeUnitSize(event) }} value={constructionHomeDetails.unitSizes}  placeholder="1 – 2 Bed plus Den" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.unitSizes}</div> 
             </Box>
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Suite size
              </Typography>           
             <TextField  onChange={(event) => { handlechangeSuiteSize(event) }} value={constructionHomeDetails.suiteSizes}  placeholder="359 sq ft – 1,212 sq ft" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.suiteSizes}</div> 
             </Box>

            </Box> 
            

            </Box>
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display:{ md:'none', sm:'none', xs:'flex' }, flexDirection: 'column', height: '100%',
                paddingLeft: { md: '5%', sm: '5%', xs: '5%' }, paddingRight: { md: '5%', sm: '5%', xs: '5%' }, marginTop: { md: '2%', sm: '10px', xs: '25px' },
                gap:{ md: '30px', sm: '20px', xs: '0px' },marginBottom: { md: '1%', sm: '10px', xs: '25px' },

            }}>             
         
        
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
              gap:{ md: '5px', sm: '5px', xs: '5px' }

            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
                        Enter your project name
              </Typography>           
             <TextField onChange={(event) => { handlechangeProjectName(event) }} value={constructionHomeDetails.projectName} placeholder="Pickering city Centre  condos" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.projectName}</div> 
             </Box>      
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Building Type Condominium /Studio
              </Typography>           
             <TextField onChange={(event) => { handlechangeBuildingType(event) }} value={constructionHomeDetails.buildingType} placeholder="Three Bedroom Suites & Towns" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.buildingType}</div> 
             </Box> 
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Occupancy
              </Typography>           
             <TextField onChange={(event) => { handlechangeOccupancy(event) }} value={constructionHomeDetails.occupancy}  placeholder="October 2026" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.occupancy}</div> 
             </Box> 
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Sales status
              </Typography>           
             <TextField  onChange={(event) => { handlechangeSalesStatus(event) }} value={constructionHomeDetails.salesStatus}  placeholder="Now selling" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.salesStatus}</div> 
             </Box> 
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Builder name
              </Typography>           
             <TextField  onChange={(event) => { handlechangeDeveloper(event) }} value={constructionHomeDetails.developer}  placeholder="Mattamy  Homes" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.developer}</div> 
             </Box>  
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Address
              </Typography>           
             <TextField  onChange={(event) => { handlechangeAddress(event) }} value={constructionHomeDetails.address}  placeholder="935 the Queensway, Etobicoke" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                   <div style={{ color: 'red' }}>{errors.address}</div>   
             </Box> 
         

            

          
            
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Nearest intersection
              </Typography>           
             <TextField  onChange={(event) => { handlechangeNearestIntersection(event) }} value={constructionHomeDetails.nearestIntersection}  placeholder="The Queensway & Islington Ave" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.nearestIntersection}</div> 
             </Box> 
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Pricing
              </Typography>           
             <TextField  onChange={(event) => { handlechangePricing(event) }} value={constructionHomeDetails.pricing}  placeholder="$478,900" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.pricing}</div> 
             </Box> 
        
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Number of storeys
              </Typography>           
             <TextField  onChange={(event) => { handlechangeNumberOfStorys(event) }} value={constructionHomeDetails.numberofStorys} placeholder="45,40,50,55" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.numberofStorys}</div> 
             </Box>
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Number of units
              </Typography>           
             <TextField type="number"  onChange={(event) => { handlechangeNumberOfUnits(event) }} value={constructionHomeDetails.numberofUnits}  placeholder="2000" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" }, type:"number" , min:1, max:10,
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.numberofUnits}</div> 
             </Box>
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Unit size
              </Typography>           
             <TextField  onChange={(event) => { handlechangeUnitSize(event) }} value={constructionHomeDetails.unitSizes}  placeholder="1 – 2 Bed plus Den" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.unitSizes}</div> 
             </Box>
             <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
                gap:{ md: '5px', sm: '5px', xs: '5px' }


            }}> 
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Suite size
              </Typography>           
             <TextField  onChange={(event) => { handlechangeSuiteSize(event) }} value={constructionHomeDetails.suiteSizes}  placeholder="359 sq ft – 1,212 sq ft" variant="outlined"       
            sx={{  width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.suiteSizes}</div> 
             </Box>

          
            

            </Box>     
          
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
              gap:{ md: '15px', sm: '10px', xs: '5px' }, paddingLeft: { md: '0%', sm: '0%', xs: '5%' }, paddingRight: { md: '0%', sm: '0%', xs: '5%' },

            }}>  
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
              gap:{ md: '5px', sm: '5px', xs: '5px' }

            }}>  
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Maintenance Fees
              </Typography>           
             <TextField  onChange={(event) => { handlechangeMaintenanceFees(event) }} value={constructionHomeDetails.maintenanceFees} multiline  rows={3}  placeholder="Estimated at $62.13/ sq ft for suites over 835 sq ft // $66.45/ sq ft for suites 835 sq ft or less (Individually metered for hydro and water)." variant="outlined"       
            style={{   width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '98px', color: " #626262",fontFamily: "poppins",overflow:'auto',
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                <div style={{ color: 'red' }}>{errors.maintenanceFees}</div>      
                  
            </Box>     
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
              gap:{ md: '5px', sm: '5px', xs: '5px' }

            }}>  
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Deposit structure
              </Typography>           
             <TextField onChange={(event) => { handlechangeDepositStructure(event) }} value={constructionHomeDetails.depositStructure}  multiline  rows={3}  placeholder="Deposit Structure: $10,000 on Signing // 5% Minus $10,000 in 30 Days // 2.5% in 120 Days // 2.5% in 240 Days // 5% on Occupancy3 Bedroom/3+Den Suites & Towns Deposit Structure: $10,000 on Signing // 5% Minus $10,000 in 30 Days // 2.5% in 180 Days // 2.5% in 360 Days // 5% on Occupancy." variant="outlined"       
            style={{   width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '98px', color: " #626262",fontFamily: "poppins",overflow:'auto',
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.depositStructure}</div> 
                  
            </Box>    
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
              gap:{ md: '5px', sm: '5px', xs: '5px' }

            }}>  
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Incentives
              </Typography>           
             <TextField onChange={(event) => { handlechangeIncentives(event) }} value={constructionHomeDetails.incentives}  multiline  rows={3}  placeholder="Platinum VIP Pricing & Floor Plans, First Access to the Best Availability, $0 Development Charge Cap, $0 Assignment (Excluding legal fees), Property Management & Leasing Services Available, Free Lawyer Review of Your Purchase Agreement, Free Mortgage Arrangements, One Year Free Maintenance (Offered as a credit on closing), $0 Option To Lease During Interim Occupancy (Excluding legal fees)." variant="outlined"       
            style={{   width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '98px', color: " #626262",fontFamily: "poppins",overflow:'auto',
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.incentives}</div> 
                  
            </Box> 
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
              gap:{ md: '5px', sm: '5px', xs: '5px' }

            }}>  
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Suite Features
              </Typography>           
             <TextField onChange={(event) => { handlechangeSuitFeatures(event) }} value={constructionHomeDetails.suiteFeatures}  multiline  rows={3}  placeholder="Laminate Flooring Throughout, Stone Kitchen Countertops, Stainless Steel Kitchen Appliances, Stacked Washer & Dryer and more." variant="outlined"       
            style={{   width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '98px', color: " #626262",fontFamily: "poppins",overflow:'auto',
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.suiteFeatures}</div> 
                  
            </Box>   
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
              gap:{ md: '5px', sm: '5px', xs: '5px' }

            }}>  
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Building Amenities
              </Typography>           
             <TextField onChange={(event) => { handlechangeBuildingAmenities(event) }} value={constructionHomeDetails.buildingAmenities}  multiline  rows={3}  placeholder="Integrated Smart Living throughout and bulk internet available, Lobby with concierge, pet spa, mail room, automated parcel lockers, Coworking space, Fitness Centre with yoga/stretch room, terrace with formal and informal sitting areas, Entertainment lounge, event space and media room, Entertainment space with kitchen, terrace with BBQs, dining al fresco, formal and informal sitting areas, fire features, pergolas and an outdoor sundeck/ fitness area." variant="outlined"       
            style={{   width: "100%",borderRadius:'8px',border: '1px solid #CFC9C9',backgroundColor: '#F9F9F9' }}
            InputProps={{sx: { height: '98px', color: " #626262",fontFamily: "poppins",overflow:'auto',
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                     <div style={{ color: 'red' }}>{errors.buildingAmenities}</div> 
                  
            </Box>   
            {/* <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', height: '100%',
              gap:{ md: '5px', sm: '5px', xs: '5px' }

            }}>  
              <Typography sx={{fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '500', color: '#313131', }}>
              Upload  images 
              </Typography>   
              <div style={{ position: 'relative' }}>  
         
              <div
          style={{
            width: '100%',
            borderRadius: '8px',
            border: '1px solid #CFC9C9',
            backgroundColor: '#F9F9F9',
            display: 'flex',         
            padding: '8px',
            height: '98px',
          }}
        >
         
          {selectedFiles.length > 0 ? (
selectedFiles.map((file, index) => (
  <div
    key={index}
    style={{
      display: 'flex',     
      marginBottom: '4px',
      position: 'relative',
    }}
  >
   
    <span
      style={{
        color: '#626262',
        fontFamily: 'poppins',
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: '500',
        display: 'inline-block',
        maxWidth: '80%',     
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
     {fileNames[index]}
    </span>
    <CloseIcon
      style={{
        cursor: 'pointer',
        marginRight: '4px',
        fontSize: '16px',
        color: 'red',
       // visibility: index === hoveredIndex ? 'visible' : 'hidden',
      }}
      onClick={() => handleRemoveFile(index)}
    />
    {index === hoveredIndex && (
      <img
        //src={URL.createObjectURL(file)}
        src={!editImagePage? selectedFiles[index]: URL.createObjectURL(file)}
        alt="Hovered Image"
        style={{
          position: 'absolute',
          top: 'calc(100% - 50px)',
          left: '0',
          zIndex: '1',
          maxWidth: '200px',
          maxHeight:'150px', // Adjust the image max width as needed
        }}
      />
    )}
  </div>
))
) : (
  <div style={{ color: '#626262', fontFamily: 'poppins', fontSize: '14px', lineHeight: '18px', fontWeight: '500',opacity: 0.6 }}>
   Browse and choose the files you want to  upload from your  computer.
  </div>
)}

                    
                    <Button  onClick={handleUploadButtonClick} variant="contained" sx={{
                        color: "#626262", height: '36px',  width: '158px', borderRadius: "8px", background: '#fff', border: "1px solid #626262",
                        position: 'absolute',bottom: '8px', right: '8px',  fontFamily: "Roboto", textTransform: "none",
                        fontStyle: "normal", fontWeight: "500", fontSize: "14px", '&:hover': { bgcolor: "#fff", color: '#626262' }
                       
                    }} startIcon={<AddPhotoAlternateOutlinedIcon style={{  color: "#626262", }} />}>
                 {selectedFiles.length > 0 ? 'Change Image' : 'Image Upload'}
          <input
            type="file"
            multiple
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(event) => handleFileChange(event)}
          />
                    </Button>   
                    </div> 
                    </div>  
            </Box>   */}
            <Box
  sx={{
    width: { md: '100%', sm: '100%', xs: '100%' },
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: { md: '5px', sm: '5px', xs: '5px' },
  }}
>
  <Typography
    sx={{
      fontFamily: 'poppins',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: '500',
      color: '#313131',
    }}
  >
    Upload images
  </Typography>
  <div style={{ position: 'relative' }}>
    <div
      style={{
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #CFC9C9',
        backgroundColor: '#F9F9F9',
        display: 'flex',
        flexDirection: 'row', // Display images in a row
        flexWrap: 'wrap', // Prevent images from wrapping to the next line
        padding: '8px',
       
       overflowY: 'auto', // Add a scrollbar if necessary
        height: '99px',
      }}
    >
      {selectedFiles.length > 0 ? (
        selectedFiles.map((file, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              marginBottom: '4px',
              position: 'relative',
            }}
          >
            <span
              style={{
                color: '#626262',
                fontFamily: 'poppins',
                fontSize: '14px',
                lineHeight: '18px',
                fontWeight: '500',
                display: 'inline-block',
                maxWidth: '80%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: 'pointer',
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              {fileNames[index]}
            </span>
            <CloseIcon
              style={{
                cursor: 'pointer',
                marginRight: '4px',
                fontSize: '16px',
                color: 'red',
              }}
              onClick={() => handleRemoveFile(index)}
            />
          {index === hoveredIndex && (
  <div
    style={{
      position: 'absolute',
      top: 'calc(100% - 50px)',
      left: '0',
      zIndex: '1',
      width: '100px', // Fixed width for the container
      height: '75px', // Fixed height for the container
      overflow: 'hidden', // Hide any overflowing content
      
    }}
    onMouseEnter={() => setHoveredIndex(index)}
    onMouseLeave={() => setHoveredIndex(null)}
  >
    <img
      src={
        editImagePage
          ? typeof selectedFiles[index] === 'string'
            ? selectedFiles[index]
            : selectedFiles[index] instanceof Blob ||
              selectedFiles[index] instanceof File
            ? URL.createObjectURL(selectedFiles[index])
            : null
          : selectedFiles[index] instanceof Blob ||
            selectedFiles[index] instanceof File
          ? URL.createObjectURL(selectedFiles[index])
          : null
      }
      alt="Hovered Image"
      style={{
        width: '100%', // Set the width to 100% to fit within the container
        height: '100%', // Set the height to 100% to fit within the container
      }}
    />
  </div>
)}
          </div>
        ))
      ) : (
        <div
          style={{
            color: '#626262',
            fontFamily: 'poppins',
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '500',
            opacity: 0.6,
          }}
        >
          Browse and choose the files you want to upload from your computer.
        </div>
      )}

      <Button
        onClick={handleUploadButtonClick}
        variant="contained"
        disabled={isProcessing }
        sx={{
          color: '#504FA4',
          height: '36px',
          width: '158px',
          borderRadius: '8px',
          background: '#fff',
          border: '1px solid #504FA4',
          position: 'absolute',
          bottom: '8px',
          right: '8px',
          fontFamily: 'Roboto',
          textTransform: 'none',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '14px',
          '&:hover': { bgcolor: "#8786CC", color: '#fff',border: "1px solid #504FA4",boxShadow:'none' }
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        startIcon={
          <AddPhotoAlternateOutlinedIcon  sx={{ color: isHovered ? '#fff' : '#504FA4' }} />
        }
      >
        {selectedFiles.length > 0 ? 'Change Image' : 'Image Upload'}
        <input
          type="file"
          multiple
          accept="image/*"
          style={{ display: 'none' }}
          onChange={(event) => handleFileChange(event)}
        />
      </Button>
    </div>
  </div>
  <div style={{ color: 'red' }}>{imageErrors.selectedFiles}</div>
</Box>
<Box sx={{ display:  'flex', flexDirection: 'row',alignItems: 'center',width:'100%' }}>
  <Checkbox
    checked={isChecked}
    onChange={(event) => handleCheckboxChange(event)}
    sx={{
      color: '#313131', // Change the checked color here
      background:'none' ,
      width:'2px',
      height:'2px',
      marginRight:'10px',
      '&.Mui-checked': {
        color: '#504FA4', // Change the checked color here as well
       background:'none' ,
       width:'2px',
       height:'2px'
      },
     
    }}
  />
  {/* <Typography sx={{ fontSize: '14px', color: '#313131', fontFamily: 'poppins' }}>
    I agree to the terms & condition
  </Typography> */}
   <>
   <Typography sx={{ fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: '#313131', fontFamily: 'poppins',paddingRight:'8px' }}>
    I agree to the{' '}
  </Typography>
            <Button onClick={handleTermsClick} variant="text"
             sx={{ background: 'none',cursor: 'pointer', textDecoration: 'none',textTransform:'none', color:'#504FA4',
              fontSize:  { md: '14px', sm: '14px',xs:'12px' },  fontFamily: 'poppins',padding:'0' }}>
              terms & condition
            </Button>
          </>
      
</Box>



            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'row', height: '100%',
              gap:{ md: '15px', sm: '15px', xs: '10px' }, justifyContent: 'flex-end',paddingTop:'10%'

            }}>  
                      
                      <Button onClick={() => window.location.href = '/personal-projects'}  disabled={isProcessing } variant="contained" sx={{
                        color: "#000", height: '36px',  width: '158px', borderRadius: "8px",
                         background: '#F8A81D', border: "1px solid #F8A81D",
                         fontFamily: "Roboto", textTransform: "none",
                        fontStyle: "normal", fontWeight: "500", fontSize: "14px", 
                        '&:hover': { bgcolor: "#FF8C00", color: '#000',border: "1px solid #FF8C00" },
                       
                    }} >
                 Cancel
                    </Button>   
                    <Button onClick={handlePost} disabled={!isChecked || isProcessing}  variant="contained" sx={{
                        color: "#fff", height: '36px',  width: '158px', borderRadius: "8px", background: '#504FA4',
                         border:!isChecked || isProcessing ? "1px solid #8786CC": "1px solid #504FA4", 
                          fontFamily: "Roboto", textTransform: "none",
                        fontStyle: "normal", fontWeight: "500", fontSize: "14px",
                        '&:hover': { bgcolor: "#3A3990", color: '#fff',border: "1px solid #3A3990" }
                       
                    }} >
              {editPage?'Save changes':  'Post'}
                    </Button>   
                  
            </Box>      
            </Box> 
           
            </Box> 

            { isTermsAndCondition &&   <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '900px', sm: '500px', xs: '320px' },
           height: { md:'730px', sm: '770px', xs: '700px' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
           borderRadius: '8px 0px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '36px 28px 36px 28px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflow:'auto',
          
         }}
       >
         <IconButton  onClick={closePopupTerm} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px',}}>
          <Typography sx={{ fontSize: '26px', color: '#313131', fontFamily: 'poppins',fontWeight:600 }}>
          Listing Agent Terms & Conditions
  </Typography> 
 
                       
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Eligibility</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>By using our platform to add property listings, you agree that you are a duly authorized real estate agent or property owner, and you have the legal authority to represent the property being listed.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Accurate Information</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You are responsible for providing accurate and up-to-date information about the properties you list on our platform, including but not limited to property details, price, availability, and images.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You agree not to engage in deceptive or fraudulent practices, such as misrepresenting property information.
                         </Typography>

                         </li>  
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Ownership and Authorization</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You confirm that you have the legal authority and consent of the property owner to list the property on RateWaves.com.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Compliance with Laws</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You agree to comply with all applicable laws and regulations, including fair housing and real estate disclosure requirements. You are responsible for ensuring that your listings do not violate any laws or regulations.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Listing Agent Conduct</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You will conduct yourself in a professional and ethical manner when using RateWaves.com. You agree not to engage in abusive, discriminatory, or harassing behavior towards other listing agent or our staff.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Removal of Listings</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You agree that we (Ratewaves.com) reserve the right to remove or modify any listings from RateWaves.com. 
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Copyright and Intellectual Property</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You confirm that you have the rights to use any images, text, or other materials you upload with your listings.
                        </Typography>

                        </li>     
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You agree not to infringe on the copyright or intellectual property rights of others.
                        </Typography>

                        </li>                     
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Changes to Terms & Conditions</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>We (Ratewaves.com) may update or change these terms and conditions at any time. It is your responsibility to review them periodically.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Termination</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>We(Ratewaves.com) reserve the right to suspend or terminate your account and access to our site.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Limitation of Liability</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves.com shall not be held liable for any direct or indirect damages, losses, or claims arising from technical issues, including but not limited to lost profits, data loss, or business interruption.
                        </Typography>

                        </li>      
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves.com makes every reasonable effort to protect the security and confidentiality of listing data. However, Ratewaves.com shall not be held liable for any direct or indirect damages, losses, or claims arising including but not limited to lost profits, data loss, or business interruption from any data breaches or unauthorized access to listing information, including personal data, financial information, or any other data stored on our platform. Data breaches can occur due to various factors, including external cyber attacks, vulnerabilities in third-party systems, or listing agent  negligence. While we implement robust security measures to safeguard your data, we cannot guarantee that breaches will not occur. Listing agents are encouraged to take additional steps to protect their data, such as using strong, unique passwords, enabling two-factor authentication, and regularly reviewing and updating their account settings. In the event of a data breach, Ratewaves.com will make every effort to mitigate the impact, investigate the breach, and notify affected listing agents in accordance with applicable data protection laws and regulations.
                        </Typography>

                        </li>   
                       <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Ratewaves.com makes every reasonable effort to protect the security and confidentiality of listing data. However, Ratewaves.com shall not be held liable for any direct or indirect damages, losses, or claims arising including but not limited to lost profits, data loss, or business interruption from any data breaches or unauthorized access to listing information, including personal data, financial information, or any other data stored on our platform. Data breaches can occur due to various factors, including external cyber attacks, vulnerabilities in third-party systems, or listing agent  negligence. While we implement robust security measures to safeguard your data, we cannot guarantee that breaches will not occur. Listing agents are encouraged to take additional steps to protect their data, such as using strong, unique passwords, enabling two-factor authentication, and regularly reviewing and updating their account settings. In the event of a data breach, Ratewaves.com will make every effort to mitigate the impact, investigate the breach, and notify affected listing agents in accordance with applicable data protection laws and regulations.
                    </Typography>

                    </li>   
                    <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Ratewaves.com shall not be held liable for any direct or indirect damages, losses, or claims resulting during Planned Maintenance and Downtime, including but not limited to financial loss, data loss, or business interruption.
                    </Typography>

                    

                        </li>   

                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>No Guarantee of Perfection</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>While we are committed to providing a high-quality service, Ratewaves.com does not guarantee that our technical systems and platforms will be entirely free from technical issues or disruptions.
                        </Typography>

                        </li>      
                         <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Factors beyond our control, such as internet infrastructure, third-party service providers, and force majeure events, may contribute to technical issues.
                    </Typography>                   

                        </li>      
                        <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Ratewaves.com disclaims any warranty, whether expressed or implied, regarding the accuracy, completeness, or suitability of the information and services provided.
                    </Typography>                   

                        </li>       
                        <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Users acknowledge that Ratewaves.com makes no warranties or representations regarding the error-free nature of our services.
                    </Typography>                   

                        </li>          
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Technical Issue Reporting</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Customers are encouraged to report any technical issues, disruptions, or outages they encounter when using our services. Reporting should be made through our designated support channels.
                        </Typography>

                        </li>    
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves.com will make every reasonable effort to promptly acknowledge, investigate, and resolve reported technical issues.
                        </Typography>

                        </li> 
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>For technical issue reporting, questions, or concerns, please contact support@ratewaves.com 
                        </Typography>                   
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Contact Information</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>By using our platform to add property listings, you agree that you are a duly authorized real estate agent or property owner, and you have the legal authority to represent the property being listed.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

          </Box> 
      </Box> </Box>     }
      <style>
        {`
          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 1px solid #504FA4 !important;
            box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
          }
        `}
      </style>
            <Footer></Footer>

        </Grid>
    );

}

export default NewProjectForm;