import React from "react";
import UserA from './images/Group.png';
import { Typography } from '@mui/material';
import { useState } from "react";
import Box from '@mui/material/Box';
import DropdownMenuNotification from "./UserNotificationDropDown.jsx";

function UserNotificationDetails(props)

{
    const {code } = props;
   const [isHovered, setIsHovered] = useState(false);
   const [isDeleted, setIsDeleted] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

    return(
        <div>
       {! isDeleted && ( <Box sx={{display:"flex", flexDirection:"row", backgroundColor: isHovered ? '#F9F9F9' : 'white',
       height: '140px',  border:'1px solid #DCDADA',alignContent: "center", borderRadius:'12px',marginTop:'10px',width: 'calc(100%)',
      }} 
        onMouseEnter={handleMouseEnter}
       onMouseLeave={handleMouseLeave}>
        <Box sx={{display:'flex',justifyContent:'center',alignContent:'center',paddingTop:{md:'15px' ,sm:'27px'}}}>
       <img src={UserA} alt="" style={{paddingLeft:'24px',width:'48px',height:'28px'}} />
       </Box>
       <Box sx={{display:"flex", flexDirection:"column",alignItems:'left',justifyContent:'center',alignContent:'center',gap:'5px', marginLeft:'20px'
       }}>
       <Box sx={{display:'flex',alignItems:'left',justifyContent:'left',flexDirection:"row",textAlign:'left' }}>
         <Typography sx={{  fontFamily: "poppins", fontSize: "16px", fontWeight: "600",lineHeight:'19px',color:"#313131",
       
         }}>Agent code {code}</Typography> 
         <Box sx={{display:'flex',alignItems:'left',justifyContent:'left',flexDirection:"row", }}>
           <Typography sx={{  fontFamily: "poppins", fontSize: "16px", fontWeight: "400",lineHeight:'19px',color:"#454545",
        marginLeft:'3px'
         }}> reacted to your quote</Typography> 
      </Box>
      </Box>
       <Box sx={{display:'inline',alignItems:'left',justifyContent:'left',flexDirection:"column", }}>
         <Typography  sx={{  fontFamily: "poppins", fontSize: "14px", fontWeight: "400",lineHeight:'21px',color:"#939393",width:'95%',
         maxHeight:'67px',textOverflow:'ellipsis !important',overflow:'hidden',   display: '-webkit-box', WebkitLineClamp:{md:'3',sm:'2'} ,
         WebkitBoxOrient: 'vertical',
        
         }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis eleifend odio. Aenean tempor euismod augue, non fringilla metus sagittis vitae. Proin pulvinar sed ante non consectetur. Pellentesque condimentum condimentum commodo. Maecenas eu nibh odio.</Typography> 
     
      </Box>
     <Box sx={{display:'flex',alignItems:'left',justifyContent:'left',flexDirection:"column", }}>
         <Typography sx={{  fontFamily: "poppins", fontSize: "14px", fontWeight: "400",lineHeight:'21px',color:"#939393",
        
         }}>10 mins ago</Typography> 
      </Box> 
      </Box>

   <Box >
      <DropdownMenuNotification isDeleted={isDeleted} setIsDeleted={setIsDeleted} /> 
      
   </Box>
    

      <Box>

   
      
    
    
          
        
</Box> 
       </Box>) }</div>
       
    );
}
export default UserNotificationDetails;