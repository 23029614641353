import React from 'react';
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Button, Typography,Grid } from "@mui/material";
import Crop169Icon from "@mui/icons-material/Crop169";
import notification from "./images/Notification (4).png";
import Messages from "./images/Messages.png";
import Discussion from "./images/Discussion.png";
import Profile from "./images/Rectangle.png";
import { InputLabel } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from "react";
import NotificationDetails from './UserNotificationDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import UserNotificatinMenu from './UserNotificationMenu';
import HeaderUserScreen from './HeaderUserScreen';

function UsersNotification(){


    const menus = [
        "Calculator",
        "Quotations",
        "Products",
        "About us",
        "Contact us"
      ];
      const [isOpenDrop, setIsOpenDrop] = useState(false);

      const handleMenuToggleDrop = () => {
        setIsOpenDrop(!isOpenDrop);
      };
    
      const handleMenuItemClickDrop = (value) => {
      
        setIsOpenDrop(false);
      }

      const [isOpenSortBy, setIsOpenSortBy] = useState(false);

      const handleMenuToggleSortBy = () => {
        setIsOpenSortBy(!isOpenSortBy);
      };
    
      const handleMenuItemClickSortBy = (value) => {
      
        setIsOpenSortBy(false);
      }

      const code=6611;
      
      const [showNotificationMenu, setShowNotificationMenu] = useState(false);

      const handleClickNotificationImage = () => {
        
        setShowNotificationMenu(!showNotificationMenu);
      };
      return(
        <Grid container sx={{ width: '100%', height: '100%' }}>
          {/* <Box sx={{ flexGrow: 1,height: '726px', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400" }} > */}
         <Box>
          <HeaderUserScreen></HeaderUserScreen>
         {/* <AppBar position="static" sx={{ bgcolor: "#1F6373", height: "72px", width: '100vw' }}>
         
         <Box sx={{display: "flex" ,width:'100%',flexDirection:'row',justifyContent:'center',alignItems:'center',height: "72px"}}> 
      
      <Box sx={{display: "flex" ,width:'10%',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
               <Crop169Icon
                 sx={{
                   display:  "flex" ,
                
                   height: 40,
                   width: '75%',
                   backgroundColor: "#fff"
                 }}
               />
 </Box>
               <Box sx={{  display: "flex" ,flexDirection:'row',gap:{md: '5%',sm:'0'},width:{md: '60%',sm:'56%'},justifyContent:'left'
               ,alignItems:'left',paddingLeft:{md: "3%",sm:'0'}, }}>
                 {menus.map((menu) => (
                   <Button
                     key={menu}
 
                     sx={{
                       my: 2, color: "white", display: "block",  textTransform: "capitalize", fontFamily: "poppins",
                       fontSize: "14px", lineHeight: "21px", fontWeight: "400",'&:hover': { color: "#939393" },paddingRight:{md: "5%",sm:'.5%'},
                     }}
                   >
                     {menu}
                   </Button>
                 ))}  </Box>

                 
     <Box sx={{display:'flex', flexDirection:'row',justifyContent:'center',width:'15%',gap:{md: '10%',sm:'3%'},alignItems:'center'}} >        
 <img src={Discussion} alt="img"  />
 <img src={Messages} alt="img" />
 <img src={notification}  onClick={handleClickNotificationImage} alt="img"  />
{showNotificationMenu && <UserNotificatinMenu />}
 </Box>
 <Box sx={{display:'flex', flexDirection:'row',justifyContent:'center',width:'15%',gap:{md:'7%',sm:'5%'},alignItems:'center',
 marginTop:{md:'0',sm:'2px'}}} > 
 <img src={Profile} alt="img"  />
 <InputLabel style={{  fontFamily: "poppins", fontSize:{md:"16px",sm:"12px"} , fontWeight: "400",lineHeight:'24px',color:"#fff",
 }}>John Doe</InputLabel>

<div className="dropdown" >
     <div className="dropdown__toggle" onClick={handleMenuToggleDrop}>
     <ExpandMoreIcon style={{marginRight:'5%',marginTop:'5px',}}></ExpandMoreIcon>
     </div>
     {isOpenDrop && (
       <div className="dropdown__menu"  style={{ position: 'absolute', right: '10px ',fontFamily: "poppins",
        fontSize: "12px",  fontWeight: "400",lineHeight:'20px',width:'134px',height:'56px',background:'#F8F8F8',borderRadius:'8px',
       boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',zIndex:1, minHeight: '20px',padding:'4px',color:'#2F2F2F'}}>
          <style>
           {`
             .dropdown__menu .MuiMenuItem-root:hover {
               background-color: #1F6373;
               color:white;
             }
             
           `}
         </style>
         <MenuItem  onClick={() => handleMenuItemClickDrop("myprofile")} value="option1" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
         fontFamily: "poppins", fontSize: "12px",fontWeight: "400",lineHeight:'20px'}}> <PersonIcon style={{width:'15px',height:'17px',
         marginRight:'10px'}}>
           </PersonIcon>My profile</MenuItem>
         <MenuItem onClick={() => handleMenuItemClickDrop("signout")} value="option2" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
         fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'20px'}}><LogoutIcon style={{width:'15px',height:'17px',
         marginRight:'10px'}}></LogoutIcon>Sign out</MenuItem>
        
       </div>
     )}
   </div>
   </Box>   

             
   </Box>   
        
         </AppBar> */}
          </Box>

          <Box sx={{display:'flex',marginLeft:{md:'12%',sm:'5%'},marginRight:{md:'12%',sm:'5%'},flexDirection:'column',width: 'calc(100%)'}}>
        <Typography sx={{  fontFamily: "poppins", fontSize: "20px", fontWeight: "500",lineHeight:'30px',color:"#313131",marginTop:'10px',
  }}>Notifications</Typography>
  
<AppBar position="static" sx={{ bgcolor: "#1F6373", height: "60px", borderRadius:'8px 8px 0px 0px',marginTop:'10px',paddingLeft:'20px',
justifyContent:'center', width: 'calc(100%)'
 }}>
   <Box sx={{display:'flex',flexDirection:'row',alignItems:'left',justifyContent:'left',alignContent:'center',width: 'calc(100%)',
 }}>
   
 <ArrowBackIcon style={{width:'28px', height:'28px',}}/>
 
 <Box className="dropdownSortBy" sx={{marginLeft:{md:'88%',sm:'83%'}}} >
        <div className="dropdown__toggleSortBy" onClick={handleMenuToggleSortBy}>
        <Box sx={{display:'flex',flexDirection:'row',alignItems:'right',justifyContent:'right',alignContent:'right'}}>
        <InputLabel style={{  fontFamily: "poppins", fontSize: "14px", fontWeight: "400",lineHeight:'21px',color:"white",
  }}>Sort by</InputLabel> 
        <ExpandMoreIcon ></ExpandMoreIcon></Box>
        </div>
        {isOpenSortBy && (
          <Box className="dropdown__menuSortBy"  sx={{ position: 'absolute', right:{md:'14% ',sm:'7%'} ,fontFamily: "poppins",
           fontSize: "12px",  fontWeight: "400",lineHeight:'20px',width:'134px',height:'56px',background:'#F8F8F8',borderRadius:'8px',
          boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',zIndex:1, minHeight: '20px',padding:'4px',color:'#2F2F2F'}}>
             <style>
              {`
                .dropdown__menuSortBy .MuiMenuItem-root:hover {
                  background-color: #1F6373;
                  color:white;
                }
                
              `}
            </style>
            <MenuItem  onClick={() => handleMenuItemClickSortBy("By date")} value="option1" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
            fontFamily: "poppins", fontSize: "12px",fontWeight: "400",lineHeight:'20px'}}> By date</MenuItem>
            <MenuItem onClick={() => handleMenuItemClickSortBy("By time")} value="option2" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
            fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'20px'}}>By time</MenuItem>
           
          </Box>
        )}
      </Box>
      </Box>
     
</AppBar>   
</Box>

          
          <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center',
     width: 'calc(100%)',marginBottom:'30px', marginLeft:{md:'12%',sm:'5%'},marginRight:{md:'12%',sm:'5%'} }}>



      {[...Array(10)].map((_, index) => (
        <React.Fragment key={index} ><NotificationDetails code={code+index} ></NotificationDetails></React.Fragment>
      ))}
  
    </Box> 




          
          </Grid>
    );
}
export default UsersNotification;