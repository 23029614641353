//import React, { useLayoutEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import AppBar from "@mui/material/AppBar";

import { Button, Grid, Typography } from "@mui/material";
import logo from './images/logo.png'


import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';




import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ImgFrame from './images/HomeBlue.png';
import dataicon from './images/icon-park-outline_database-forbid.svg';
//import downicon from './images/material-symbols_download.png';
import HelpIcon from '@mui/icons-material/Help';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Link from "@mui/material/Link";
import { useState } from "react";

import DivComponents from './DivComponent';
import Payment from './PaymentComponent';
import Penalty from './PenaltyComponent';
import CheckboxPopUp from './CheckboxPopUp';
import GetAppIcon from '@mui/icons-material/GetApp';

import SignIn from './SignInSignUp'







function AddMortgage() {

  const menus = [
    "Calculator",
    "Quotations",
    "Products",
    "About us",
    "Contact us"
  ];
  




  const linkstyles = {
    link1: {
    textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "16px", fontWeight: "400",
      color: " #313131", zIndex: 1
    },
    link2: {
      marginLeft: "5%", textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "16px", fontWeight: "400",
      color: " #313131"

    },
    activeLink: {
      textDecoration: 'underline', fontWeight: "500"
    },
  };
  const [activeLink, setActiveLink] = useState('Calculate Payment');

  const handleLinkClick = (link) => {
    setActiveLink(link);

  };






  const [calcContent, setcalcContent] = useState("Penalty Content");

  const handlePaymentCalc = () => {
    setcalcContent("Payment Content");
    handleLinkClick('Calculate Payment');
  };

  const handlePenaltyCalc = () => {
    setcalcContent("Penalty Content");
    handleLinkClick('Calculate Penalty on cancellation');
  };








  const [dataList, setDataList] = useState([
    {
      id: 1,
      name: "Option #1",
      initialPayment: 0,
      type: '',
      term: 2,
      rate: 0,
      period: 0,
      frequency: "monthly",
      isChecked: false
    },
    {
      id: 2,
      name: "Option #2",
      initialPayment: 0,
      type: '',
      term: 2,
      rate: 0,
      period: 0,
      frequency: "monthly",
      isChecked: false
    },
    {
      id: 3,
      name: "Option #3",
      initialPayment: 0,
      type: '',
      term: 2,
      rate: 0,
      period: 0,
      frequency: "monthly",
      isChecked: false
    },
    {
      id: 4,
      name: "Option #4",
      initialPayment: 0,
      type: '',
      term: 2,
      rate: 0,
      period: 0,
      frequency: "monthly",
      isChecked: false
    },
  ])

  
  const [numberOfAddClick, setnumberOfAddClick] = useState(4);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(4);

  const handleAddSlide = () => {

    setisDeleteButtonEnabled(true);
    if (numberOfAddClick < 8) {

      const newData = {


        id: dataList.length + 1, name: `Option # ${numberOfAddClick + 1}`,
        initialPayment: 0, type: '', term: 2, rate: 0, period: 0, frequency: "monthly"
      }

      // isDeleteConfirm===true?setCurrentSlideIndex(currentSlideIndex+2):setCurrentSlideIndex(currentSlideIndex+1);

      setnumberOfAddClick(numberOfAddClick + 1);

      // setIsDeleteConfirm(false);
      setDataList([...dataList, newData]);
    }
    setCurrentSlideIndex(currentSlideIndex + 1);
  };




  const handlePrevSlide = () => {

    if (currentSlideIndex > 4)
      setCurrentSlideIndex(currentSlideIndex - 1)

  };

  const handleNextSlide = () => {
    if (currentSlideIndex < 7)
      setCurrentSlideIndex(currentSlideIndex + 1)

  }
  const [isDeleteButtonEnabled, setisDeleteButtonEnabled] = useState(false);





  const isdisableAddSlidebutton = (dataList[dataList.length]?.name) === (dataList[currentSlideIndex]?.name)
  const [CheckboxEnabled, setCheckboxEnabled] = useState(false);

  const [thebidding, setthebidding] = useState("bidding disable");
  const handleAddBiddingCheckbox = () => {
    setCheckboxEnabled(true);
    setthebidding("bidding enable");
    setisDeleteButtonEnabled(false);

  };

  const [showPopupforCheckbox, setShowPopupforCheckbox] = useState(false);

  const handleNextClick = () => {

    setShowPopupforCheckbox(true);



  };
  const [selectedCheckboxList, setCheckboxSelectedList] = useState([]);

 // const [isCheckedCheckboxNext, setisCheckedCheckboxNext] = useState([]);
  var isNextButtonEnabled = selectedCheckboxList.length > 0;
  const handleNextCheckboxChange = (isChecked, index) => {

    // setisNextButtonEnabled(isChecked);    

    //setisCheckedCheckboxNext(isChecked);
    //[...isChecked]=[temp,setIsChecked];

    //  if (isChecked) {
    //   setisCheckedCheckboxNext((prevChecked) => [...prevChecked, index]);
    // } else {
    //   setisCheckedCheckboxNext((prevChecked) =>
    //     prevChecked.filter((item) => item !== index)
    //   );
    // }




  };
  const IsChevorButton = (CheckboxEnabled && dataList.length > 4) || isDeleteButtonEnabled;




  // const [isChecked, setIsChecked] = useState(false);

  const [isHoveredAddMortgage, setIsHoveredAddMortgage] = useState(false);

  const handleMouseEnterAddMortgage = () => {
    setIsHoveredAddMortgage(true);
  };

  const handleMouseLeaveAddMortgage = () => {
    setIsHoveredAddMortgage(false);
  };

  const [isHoveredTheBidding, setIsHoveredTheBidding] = useState(false);

  const handleMouseEnterTheBidding = () => {
    setIsHoveredTheBidding(true);
  };

  const handleMouseLeaveTheBidding = () => {
    setIsHoveredTheBidding(false);
  };
  const [isHoveredSendToMail, setIsHoveredSendToMail] = useState(false);

  const handleMouseEnterSendToMail = () => {
    setIsHoveredSendToMail(true);
  };

  const handleMouseLeaveSendToMail = () => {
    setIsHoveredSendToMail(false);
  };

  const [isHoveredDownloadResult, setIsHoveredDownloadResult] = useState(false);

  const handleMouseEnterDownloadResult = () => {
    setIsHoveredDownloadResult(true);
  };

  const handleMouseLeaveDownloadResult = () => {
    setIsHoveredDownloadResult(false);
  };
  const [isHoveredNext, setIsHoveredNext] = useState(false);

  const handleMouseEnterNext = () => {
    setIsHoveredNext(true);
  };

  const handleMouseLeaveNext = () => {
    setIsHoveredNext(false);
  };

  // const [size, setSize] = useState([0, 0]);

  // function ViewPort() {
  //   var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  //   var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  //   var viewsize = w + "," + h;
  //   alert("Your View Port Size is:" + viewsize);
  // }
  // useLayoutEffect(() => {
  //   function updateSize() {
  //     setSize([window.outerWidth, window.outerWidth]);
  //     var x = window.matchMedia("(max-width: 700px)")
  //     console.log("x", x)
  //   }
  //   window.addEventListener('resize', updateSize);
  //   updateSize();
  //   return () => window.removeEventListener('resize', updateSize);
  // }, []);

  // console.log("size", size);

  const [isActiveAskingPrice, setIsActiveAskingPrice] = useState(false);

  const handleInputClickAskingPrice = () => {
    setIsActiveAskingPrice(true);
  };

  const handleInputBlurAskingPrice = () => {
    setIsActiveAskingPrice(false);
  };
  const [isActiveLocation, setIsActiveLocation] = useState(false);

  const handleInputClickLocation = () => {
    setIsActiveLocation(true);
  };

  const handleInputBlurLocation = () => {
    setIsActiveLocation(false);
  };
  const [showSignIn, setShowSignIn] = useState(false);

  const handleClickSignIn = () => {
    setShowSignIn(!showSignIn);
  };
  const [showTermText, setShowTermText] = useState(false);
  const handleTermClick = () => {
    setShowTermText(!showTermText)
  };
  const [showAnnualInterestRateText, setShowAnnualInterestRateText] = useState(false);
  const handleAnnualInterestRateClick = () => {
    setShowAnnualInterestRateText(!showAnnualInterestRateText)
  };
  const [showAmortizationText, setShowAmortizationText] = useState(false);
  const handleAmortizationTextClick = () => {
    setShowAmortizationText(!showAmortizationText)
  };
  const [showPaymentSheduleText, setShowPaymentSheduleText] = useState(false);
  const handlePaymentSheduleTextClick = () => {
    setShowPaymentSheduleText(!showPaymentSheduleText)
  };

  return (
    <Grid container sx={{ width:  '100%', height: '100%',margin:0,padding:0 }}>
    <Box  sx={{ height:{md:'1000px',sm:'1000px'} , width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400",margin:0,padding:0 }}>

      <AppBar position="static" sx={{ bgcolor: "#0C276C", height: "72px", width: '100%' }}>
      <Box sx={{display: "flex" ,width:'100%',flexDirection:'row',justifyContent:'center',alignItems:'center',height: "72px"}}> 
       
          <Box sx={{display: "flex" ,width:{md:'10%',sm:'12%'},flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <img src={logo} alt="logo"  style={{              
               
               height: '85%',
               width: '90%',
              
             }} />
             
           
</Box>
            <Box sx={{display: "flex",width:'80%',flexDirection:'row',justifyContent:'left',alignItems:'left',paddingLeft:{md: "5%",sm:'3%'}, }}>
              {menus.map((menu) => (
                <Button
                  key={menu}

                  sx={{
                    color: "white",  paddingRight:{md: "7%",sm:'3%'}, textTransform: "capitalize", fontFamily: "poppins",
                    fontSize: "14px", lineHeight: "21px", fontWeight: "400", '&:hover': { color: "#939393",bgcolor:'#0C276C' }
                  }}
                >
                  {menu}
                </Button>
              ))}</Box>
  <Box sx={{display: "flex" ,width:{md:'10%',sm:'17%'},justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
              <Button variant="contained" onClick={handleClickSignIn}
                sx={{
                  bgcolor: "#EB5648", color: "#fff", borderRadius: "16px", 
                  padding: " 4px, 10px, 4px, 10px", textTransform: "capitalize", fontFamily: "poppins", fontSize: "14px",
                  lineHeight: "21px", fontWeight: "400", width: "90%", height: "36px", '&:hover': { bgcolor: "#fff",color:'#0C276C' }
                }} >Sign in</Button></Box>
            
            {showSignIn && <SignIn />}
          
      
        </Box>
      </AppBar> 


     
        <Box sx={{ width:'calc(100%-30%)', alignItems:'center' , justifyContent:'center', display:'flex', flexDirection:'row',marginLeft:{md:'12%',sm:'5%'},
        marginRight:{md:'18%',sm:'5%'},marginTop:{md:'2.5%',sm:'5%'}}}>
         
          <Box sx={{ width:{md:'15%',sm:'25%'}, alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row',}}>
            <img src={ImgFrame} alt="imgframe" />
            </Box>
            <Box sx={{ width:{md:'75%',sm:'100%'}, alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row', marginRight:{md:'10%',sm:'0%'}}}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "12px", lineHeight: "24px",
              fontWeight: 400, width: '100%'

            }}>
              Whether you are applying for a new mortgage or thinking about switching lenders, This comparison calculator can help you explore
              and pick the mortgage option that’s right for you. Use this Mortgage comparison tool to compare mortgage platfrom top lenders and
              find the one that best suits your needs.
            </Typography>
          </Box>
         
        </Box>

        <Box  sx={{ width:'calc(100%-30%)', alignItems:'center' , justifyContent:'center', display:'flex', flexDirection:'row',marginLeft:{md:'12%',sm:'5%'},
        marginRight:{md:'25%',sm:'6%'},marginTop:{md:'1%',sm:'3%'},borderTop:'2px solid #E2E2E2'}}>
        </Box>

        <Box  sx={{ width:'calc(100%-30%)', alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row'
        ,marginLeft:{md:'12%',sm:'5%'},        marginRight:{md:'22.5%',sm:'6%'},        marginTop:{md:'2%',sm:'3%'}}} >
          <Box sx={{ width:{md:'calc(25%)',sm:'37.5%'}, alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row',
        
          }} >
            <Typography style={{
              fontFamily: "poppins", fontSize: "16px", lineHeight: "24px", fontWeight: "500", color: "#313131", display: 'flex',
              alignItems: 'center'
            }}>Let us start<ArrowForwardIcon sx={{ marginLeft: "5px", color: "#313131", fontSize: "16px", lineHeight: "24px"}} />
            </Typography>

          </Box>
          <Box sx={{width:{md:'94%',sm:'100%'},alignItems:'center' , justifyContent:'center', display:'flex', flexDirection:'row',gap:{md:'4%',sm:'4%'},
          marginRight:{md:'5%',sm:'0%'}
          }} >

          <Box sx={{width:{md:'48%',sm:'65%'},alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row'}} >

            <Paper
              component="form" sx={{
                height: "40px", border: `1px solid ${isActiveAskingPrice ? '#0C276C' : '#CFC9C9'}`,
                borderRadius: "4px", boxShadow: isActiveAskingPrice ? '2px 0px 4px rgba(0, 0, 0, 0.25)' : 'none', display: 'flex', 
                alignItems: 'center',width:'100%',
              }}  >

              <InputBase onClick={handleInputClickAskingPrice}
                onBlur={handleInputBlurAskingPrice}
                sx={{ ml: 1, flex: 1, fontFamily: "Roboto", fontSize: "12px", lineHeight: "14px", fontWeight: "400",
                
              }}
                placeholder="Asking Price"
                inputProps={{ 'aria-label': 'Asking Price' ,sx: { color:'#626262'} }} 
              />
            </Paper>

          </Box>
          <Box sx={{width:{md:'48%',sm:'65%'},alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row'}}   >
            <Paper
              component="form"
              sx={{
                display: 'flex', alignItems: 'center', height: '40px', borderRadius: '8px',width:'100%',
                border: `1px solid ${isActiveLocation ? '#0C276C' : '#CFC9C9'}`, boxShadow: isActiveLocation ? '2px 0px 4px rgba(0, 0, 0, 0.25)' : 'none',
              }}
            >
              <InputBase onClick={handleInputClickLocation}
                onBlur={handleInputBlurLocation}
                sx={{
                  ml: 1, flex: 1, fontFamily: 'Roboto', fontSize: '12px', lineHeight: '14px', fontWeight: '400', 
                }}
                placeholder="Location" inputProps={{ 'aria-label': 'Location',style: { color:'#626262'}, }}
              />
              <IconButton type="button" sx={{ color: '#626262',width:'15px',height:'15px',paddingRight:'5%' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>

          </Box>
          </Box>
        </Box>






        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',width:'calc(100%-30%)' ,marginLeft:{md:'12%',sm:'5%'},marginRight:{md:'14%',sm:'5%'}
        ,marginTop:{md:'3%',sm:'5%'},gap:{md:'2%',sm:'0'}
       }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width:{md:'17.5%',sm:'27%'}  ,
            height: '334px', alignItems: 'center', gap: '20px', 
          }}>
           < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'2px',sm:'2px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '16px', 
              lineHeight: '24px', fontWeight: '600', color: '#313131'
            }}>Input parameters</Typography></Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'7px',sm:'0px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Initial payment</Typography> <HelpIcon sx={{
              fontSize: '12px', color: '#878787'
             
            }} /> </Box>
{/* <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'2px',sm:'2px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Type</Typography> <HelpIcon sx={{
              fontSize: '12px', 
              color: '#878787'
            }} /> </Box> */}
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'0px',sm:'3px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Term</Typography> <HelpIcon onClick={handleTermClick} sx={{
              fontSize: '12px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showTermText && (  <Box
            sx={{
              position: 'absolute',
              top:{md:'60.3%',sm:'37.8%'} ,
              left: {md:'23%',sm:'27%'},
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>The number of years will be engaged with proposed option</Typography></Box>)}
             </Box>
          
            
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'2px',sm:'3px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px', 
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Interest rate (%)</Typography> <HelpIcon onClick={handleAnnualInterestRateClick} sx={{
              fontSize: '12px',cursor:'pointer',
              color: '#878787'
            }} /> 
             {showAnnualInterestRateText && (  <Box
            sx={{
              position: 'absolute',
              top:{md:'66%',sm:'41.7%'} ,
              left: {md:'23%',sm:'27%'},
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Annual interest rate for this option</Typography></Box>)}
            </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'1px',sm:'3px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}> Amortization period</Typography><HelpIcon onClick={handleAmortizationTextClick} sx={{ fontSize: '12px', color: '#878787',
            cursor:'pointer' }} />
             {showAmortizationText && (  <Box
            sx={{
              position: 'absolute',
              top: {md:'72%',sm:'45.5%'} ,
              left:  {md:'23%',sm:'27%'},
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>The number of years & months over which you will repay this loan</Typography></Box>)}
            
            </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'0px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131',marginRight: 'auto',
            }}> Payment frequency</Typography><HelpIcon onClick={handlePaymentSheduleTextClick} sx={{
              fontSize: '12px', cursor:'pointer',
              color: '#878787'
            }} />
            {showPaymentSheduleText && (  <Box
            sx={{
              position: 'absolute',
              top: {md:'77%',sm:'49%'},
              left:  {md:'23%',sm:'27%'},
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Monthly, weekly, biweekly(Every two weeks),semi monthly(24x per year)</Typography></Box>)}
            </Box>
             
          </Box>
         
<Box sx={{width:{md:'calc(75%)',sm:'calc(78%)'}, display: 'flex', flexDirection: 'row', alignItems: 'center',marginLeft:{md:'0',sm:'1.5%'} 
           ,justifyContent:'center',gap:{md:'1.5%',sm:'1.5%'} 
          }}>
          {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex).map((data, index) => (
            <Box key={index} className="slide"  sx={{width:'calc(75%)'}} >
              <DivComponents dataList={dataList} setDataList={setDataList}
                index={index} CheckboxEnabled={CheckboxEnabled} setCurrentSlideIndex={setCurrentSlideIndex}
                currentSlideIndex={currentSlideIndex} mortgageName={data.name}
                isDeleteButtonEnabled={isDeleteButtonEnabled} selectedCheckboxList={selectedCheckboxList}
                setCheckboxSelectedList={setCheckboxSelectedList}
              />
            </Box>
          ))}
</Box>


          {!CheckboxEnabled && <Box sx={{ display:{md:'flex',sm:'none'} , flexDirection: 'row', justifyContent: 'center' ,width:{md:'13.5%',sm:'0'}}}>
            <Button onClick={handleAddSlide} disabled={dataList.some(item => item.name === 'Option #7')} variant="contained" style={{
              color: "white", height: '30px',
              marginTop: "50px", borderRadius: "12px", background: isHoveredAddMortgage && dataList.some(item => item.name === 'Option #7')
                ? '#939393' : isHoveredAddMortgage ? '#074554' : '#0C276C',
              border: (dataList.some(item => item.name === 'Option #7') ? "1px solid #939393" : "1px solid #0C276C"), fontFamily: "Roboto",
              textTransform: "none", fontStyle: "normal", fontWeight: "500", fontSize: "12px",
            }} onMouseEnter={handleMouseEnterAddMortgage}
              onMouseLeave={handleMouseLeaveAddMortgage}
              startIcon={<AddCircleOutlineIcon style={{ color: "white", fontSize: "12px" }} />}>
              Add mortgage
            </Button>
          </Box>}
          {CheckboxEnabled && <Box sx={{ display:{md:'flex',sm:'none'} , flexDirection: 'row', justifyContent: 'center' ,width:{md:'13.5%',sm:'0'}}}>
            
          </Box>}
        </Box>  



        <Box sx={{ display: 'flex', flexDirection: "row", marginTop:{md:'-1.5%',sm:'-5%'}, alignItems: 'center',width:'calc(100%-30%)' 
        
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'11.8%',sm:'23%'},
             alignItems: 'center',marginLeft:{md:'12%',sm:'5%'},
          }}>
           <Typography style={{
           fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
            color: " #313131",marginRight: 'auto',
          }}> Scheduled Payment</Typography><HelpIcon style={{ fontSize: "12px", color: "#878787" }} /></Box>
           <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'13.5%',sm:'17%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131",
          }}> Result</Typography></Box>
           <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'13%',sm:'17%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131"
          }}> Result</Typography></Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'12%',sm:'17%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131",
          }}> Result</Typography></Box>
            <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'13.5%',sm:'15%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131"
          }}>Result</Typography></Box>

        </Box>

        {!CheckboxEnabled && <Box sx={{ display:{md:'none',sm:'flex'} , flexDirection: 'row', justifyContent: 'right' ,width:{md:'0',sm:'94%'}}}>
            <Button onClick={handleAddSlide} disabled={dataList.some(item => item.name === 'Option #7')} variant="contained" style={{
                marginTop: "25px",color: "white", height: '30px',
              borderRadius: "12px", background: isHoveredAddMortgage && dataList.some(item => item.name === 'Option #7')
                ? '#939393' : isHoveredAddMortgage ? '#074554' : '#0C276C',
              border: (dataList.some(item => item.name === 'Option #7') ? "1px solid #939393" : "1px solid #0C276C"), fontFamily: "Roboto",
              textTransform: "none", fontStyle: "normal", fontWeight: "500", fontSize: "12px",
            }} onMouseEnter={handleMouseEnterAddMortgage}
              onMouseLeave={handleMouseLeaveAddMortgage}
              startIcon={<AddCircleOutlineIcon style={{ color: "white", fontSize: "12px" }} />}>
              Add mortgage
            </Button>
            
          </Box>}
         
          <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '25px',width:'calc(100%-30%)',marginRight:{md:'28%',sm:'6%'},
        marginLeft:{md:'12%',sm:'5%'} ,justifyContent:'right',marginBottom:{md:'10px',sm:'30px'}
       }}>

          {/* <Link href="#" underline='hover'
            style={activeLink === 'Calculate Payment' ? { ...linkstyles.link1, ...linkstyles.activeLink } : linkstyles.link1}
            onClick={handlePaymentCalc}>
            Calculate payment
          </Link>


          <Link href="#" underline='hover' rel="noopener"
            style={activeLink === 'Calculate Penalty on cancellation' ? { ...linkstyles.link2, ...linkstyles.activeLink } : linkstyles.link2}
            onClick={handlePenaltyCalc}>
            Calculate penalty on cancellation
          </Link> */}


{IsChevorButton && <ChevronLeftIcon onClick={handlePrevSlide} disabled={currentSlideIndex === 4} sx={{
            background: "#CFDAF4", marginLeft:{md:'29.5%',sm:'12%'} , cursor: currentSlideIndex === 4?'default':'pointer',
            color: currentSlideIndex === 4 ? "#CCCCCC" : "#0C276C", boxShadow: "2px 0px 4px 0px #00000040 ", borderRadius: "50%",
            width: "36px", height: '36px'
          }} ></ChevronLeftIcon>}



          {IsChevorButton && <ChevronRightIcon onClick={isdisableAddSlidebutton ? null : handleNextSlide} sx={{
            background: "#CFDAF4", marginLeft:{md: "20px",sm:'15px'} , cursor: isdisableAddSlidebutton?'default':'pointer',
            boxShadow: "2px 0px 4px 0px #00000040", color: isdisableAddSlidebutton ? "#CCCCCC" : "#0C276C", borderRadius: "50%",
            width: "36px", height: '36px'
          }} ></ChevronRightIcon>}


        </Box>

        {/* {calcContent === "Payment Content" && <Box className="carousel" sx={{
          display: 'flex', flexDirection: "row", alignItems: "center",  width:'calc(100%-30%)',marginTop:'-1.5%',
          marginRight:{md:'14%',sm:'5%'},          marginLeft:{md:'12%',sm:'5%'}
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width:{md:'17.7%',sm:'27%'}  ,
            height: '334px', alignItems: 'center', gap: '6%', 
          }}>
           < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'43.5%',sm:'49.5%'}
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}>Payment schedule</Typography> <HelpIcon style={{ fontSize: "12px",color: "#878787" }} /> </Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}>Remaining principle</Typography><HelpIcon style={{ fontSize: "12px",  color: "#878787" }} /></Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',marginTop:'-2.3px'
            }}> Interest paid</Typography> <HelpIcon style={{ fontSize: "12px",color: "#878787" }} /></Box>
                < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:0,sm:'-1px'}
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}> Principle paid</Typography><HelpIcon style={{ fontSize: "12px", color: "#878787" }} /></Box>

          </Box>
          <Box sx={{width:{md:'calc(75%)',sm:'calc(78%)'}, display: 'flex', flexDirection: 'row', alignItems: 'center',marginLeft:{md:'1.7%',sm:'1.5%'} 
           ,justifyContent:'center',gap:{md:'1.5%',sm:'1.5%'} ,marginTop:'-7%'
          }}>

          {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex)

            .map((slide, index) => (
              <Box key={index} className="slide"  sx={{width:'calc(75%)'}} >
                <Payment dataList={dataList} index={index} mortgageName={slide.name} onChange={() => handleNextCheckboxChange(slide.name)}
                  isCheckedCheckboxNext={selectedCheckboxList.includes(slide.name)}

                />
              </Box>
            ))}
</Box>
<Box sx={{ display:{md:'flex',sm:'none'} , flexDirection: 'row', justifyContent: 'center' ,width:{md:'15.5%',sm:'0'}}}> </Box>
        </Box>} */}

        {calcContent === "Penalty Content" && <Box className="carousel" sx={{
          display: 'flex', flexDirection: "row", alignItems: "center",  width:'calc(100%-30%)',
          marginRight:{md:'14%',sm:'5%'},       marginLeft:{md:'12%',sm:'5%'},marginTop:{md:'-2.5%',sm:'-6%'}
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width:{md:'17.5%',sm:'27%'}  ,
            height: '334px', alignItems: 'center', gap: '5%', 
          }}>
           < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'43.5%',sm:'49.5%'}
          }}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131", marginTop: '12px',marginRight: 'auto',
            }}>Cancellation </Typography> <HelpIcon sx={{ fontSize: "12px", color: "#878787",marginTop: '10px'}} /> </Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',marginTop: '-2px',
            }}>Remaining principle</Typography><HelpIcon sx={{ fontSize: "12px", color: "#878787" }} /></Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',marginTop: '-2px',
            }}> Calculated penalty</Typography> <HelpIcon sx={{ fontSize: "12px", color: "#878787" }} /></Box>

          </Box>
          <Box sx={{width:{md:'calc(75%)',sm:'calc(78%)'}, display: 'flex', flexDirection: 'row', alignItems: 'center',marginLeft:{md:'1.9%',sm:'1.5%'} 
           ,justifyContent:'center',gap:{md:'1.5%',sm:'1.5%'} ,marginTop:'-7%'
          }}>
          {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex)
            .map((slide, index) => (
              <Box key={index} className="slide" sx={{width:'calc(75%)'}}>
                <Penalty dataList={dataList} index={index} mortgageName={slide.name} onChange={() => handleNextCheckboxChange(slide.name)}
                  isCheckedCheckboxNext={selectedCheckboxList.includes(slide.name)} />
              </Box>
            ))}
</Box>
<Box sx={{ display:{md:'flex',sm:'none'} , flexDirection: 'row', justifyContent: 'center' ,width:{md:'15.7%',sm:'0'}}}> </Box>
        </Box>}

        <Box
  sx={{
    display: 'flex', flexDirection: "row", 
    paddingTop:{md:'3.5%',sm:'-5%'} , 
    alignItems: 'center',
 justifyContent: 'center' ,
    position: 'fixed',
    left: 0,
    bottom: 0,
   width: 'calc(100% )', 
   height:'10%'  ,
   gap:{md:'2%',sm:'1%'},
 boxShadow:' 0px -4px 4px rgba(217, 217, 217, 0.25)',
   backgroundColor:'white',
   zIndex:'3'
  }}
>
        
        {thebidding === "bidding disable" && <Box sx={{display: 'flex', flexDirection: "row", marginTop:{md:'-3.5%',sm:'-5%'} , alignItems: 'center',width:'calc(100%)' 
        ,marginLeft:{md:'12%',sm:'5%'},marginRight:{md:'25%',sm:'5%'},justifyContent: 'center' , gap:{md:'1%',sm:'1%'},
        }}>


          <Button variant="contained" onMouseEnter={handleMouseEnterTheBidding}
            onMouseLeave={handleMouseLeaveTheBidding} onClick={handleAddBiddingCheckbox} style={{
              color: "white", textTransform: "none",
              background: isHoveredTheBidding ? "#074554" : "#0C276C", border: "1px solid #0C276C", fontFamily: "Poppins",
              fontStyle: "normal", lineHeight: "21px", height: '36px',
              fontWeight: "500", fontSize: "14px", width:"35%", borderRadius: "4px",
            }} startIcon={<img src={dataicon} alt="dataicon" />} >
            Start the bidding
          </Button>
          <Button variant="contained" onMouseEnter={handleMouseEnterSendToMail}
            onMouseLeave={handleMouseLeaveSendToMail} style={{
              color: isHoveredSendToMail ? "white" : "#0C276C", textTransform: "none", marginLeft: "14px",
              background: isHoveredSendToMail ? "#0C276C " : "#CFDAF4", border: "1px solid #0C276C", fontFamily: "Poppins", fontStyle: "normal", lineHeight: "21px",
              fontWeight: "500", fontSize: "14px", width:{md:"170px",sm:'190px'} , borderRadius: "4px", height: '36px'
            }}
            startIcon={<MailOutlineIcon style={{ color: isHoveredSendToMail ? "white" : "#0C276C", }} />}>
            Send to email
          </Button>
          <Button variant="contained" onMouseEnter={handleMouseEnterDownloadResult}
            onMouseLeave={handleMouseLeaveDownloadResult} style={{
              color: isHoveredDownloadResult ? "white" : "#0C276C", textTransform: "none", marginLeft: "14px", height: '36px',
              background: isHoveredDownloadResult ? "#0C276C " : "#CFDAF4", border: "1px solid #0C276C", fontFamily: "Poppins",
              fontStyle: "normal", lineHeight: "21px", fontWeight: "500", fontSize: "14px", width: "204px", borderRadius: "4px",
            }} startIcon={<GetAppIcon style={{ color: isHoveredDownloadResult ? "white" : "#0C276C", }} />}>
            Download result
          </Button>

        </Box>}
        {thebidding === "bidding enable" && <Box sx={{display: 'flex', flexDirection: "row",  marginTop:{md:'-3.5%',sm:'-5%'}, alignItems: 'center',
        width:'calc(100%-30%)',justifyContent:'center' 
        ,marginLeft:{md:'12%',sm:'5%'},marginRight:{md:'15%',sm:'5%'}}}>



          <Button variant="contained" onClick={handleNextClick} disabled={!isNextButtonEnabled} style={{
            color: "white", textTransform: "none",
            background: isHoveredNext && isNextButtonEnabled
              ? '#074554' : !isNextButtonEnabled ? '#939393' : '#0C276C', border: !isNextButtonEnabled ? "1px solid #939393" : "1px solid #0C276C",
            fontFamily: "Poppins", fontStyle: "normal",
            lineHeight: "21px", fontWeight: "500", fontSize: "14px", width: "348px", borderRadius: "4px",height:'36px',
          }} onMouseEnter={handleMouseEnterNext}
            onMouseLeave={handleMouseLeaveNext} >
            Next<ArrowForwardIcon style={{ marginLeft: "5px", color: "white", fontSize: "16px" }} />
          </Button>
         



        </Box>}

     </Box>
     {showPopupforCheckbox && (<CheckboxPopUp ></CheckboxPopUp>)}
      </Box>
      </Grid>
  );

}

export default AddMortgage;
