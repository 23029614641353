
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import { Button, Grid, Typography, Card, CardContent, CardMedia, Stack, Divider, Breadcrumbs, InputBase, } from "@mui/material";
import logo from './images/logo.png'
import Toolbar from "@mui/material/Toolbar";
import { useState, useEffect } from "react";
import SignIn from './SignInSignUp'
import '../App.css';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Footer from './Footer';
import Header from './HeaderLanding';
import HeaderMobile from './HeaderLandingMobile';
import bloghdr from '../components/images/bloghdr.png'
import blogimg from '../components/images/blog.png'
import { useParams, useNavigate } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import axios from 'axios';
import { API_URL, FRONT_URL } from './constants';
import BlogComponent from './BlogComponent';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PrimaryButton from './customButton';
import share from './images/share.svg'
import twitter from './images/twitter.svg'
import linkedin from './images/linkedin.svg'
import facebook from './images/facebook.svg'
import { Helmet } from 'react-helmet-async';

const generateSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-/g, '')
    .replace(/-$/g, '');
};

function BlogDetail() {
  
 

  function generateUrlFromTitle(title) {
    return title.toLowerCase().replace(/\s+/g, '-');
  }

  const { blogTitle } = useParams();
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [blog, setBlog] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [isShare, setIsShare] = useState(false);

  const [blogs, setBlogs] = useState([]);

    async function fetchData() {
    
      try {
          const response = await axios.get(`${API_URL}/blog_data`);                   
          console.log("details",response.data)
          setBlogs(response.data)
      } 
      catch (error) {
          console.error('Error:', error.message);
      }
    }
  
    useEffect(() => {
      fetchData();
    }, []);
    

    useEffect(() => {
      // Find the blog based on the blogTitle from the URL
      const selectedBlog = blogs.find(
        (b) => b.title.toLowerCase().replace(/\s+/g, '-') === blogTitle
      );
      setBlog(selectedBlog);       
    }, [blogTitle, blogs]);
  
    if (!blog) {
      return <div>Loading...</div>;
    }
  


 // Sort blogs to ensure the correct order
 const sortedBlogs = [...blogs].sort((a, b) => a.blogId - b.blogId);
    
 // Get the current blog index
 const currentBlogIndex = sortedBlogs.findIndex(
   (b) => b.title.toLowerCase().replace(/\s+/g, '-') === blogTitle
 );

 // Determine the next and previous blogs
 const nextBlog = sortedBlogs[currentBlogIndex + 1] || null;
 const prevBlog = sortedBlogs[currentBlogIndex - 1] || null;

 // Handlers for navigating to next/previous blogs
 const handleNext = () => {
   if (nextBlog) {
     navigate(`/blog/${nextBlog.url}`);
   }
 };

 const handlePrevious = () => {
   if (prevBlog) {
     navigate(`/blog/${prevBlog.url}`);
   }
 };


 const handleCopyButtonClick = async () => {
   if (navigator.clipboard && navigator.clipboard.writeText) {
       navigator.clipboard.writeText(`${selectedUrl}`)
           .then(() => {
               console.log('Link copied to clipboard');
               setIsShare(false);
           })
           .catch((error) => {
               console.error('Failed to copy:', error);
               fallbackCopyTextToClipboard(`${selectedUrl}`);
           });

   } else {
       setIsShare(false);
       console.warn('Clipboard API not supported, unable to copy text');
       fallbackCopyTextToClipboard(`${selectedUrl}`);
   }
};

const fallbackCopyTextToClipboard = (text) => {
   const textArea = document.createElement('textarea');
   textArea.value = text;

   // Avoid scrolling to the bottom of the page
   textArea.style.position = 'fixed';
   textArea.style.top = '0';
   textArea.style.left = '0';
   textArea.style.width = '2em';
   textArea.style.height = '2em';
   textArea.style.padding = '0';
   textArea.style.border = 'none';
   textArea.style.outline = 'none';
   textArea.style.boxShadow = 'none';
   textArea.style.background = 'transparent';

   document.body.appendChild(textArea);
   textArea.select();

   try {
       const successful = document.execCommand('copy');
       console.log('Fallback: Copying text command was ' + (successful ? 'successful' : 'unsuccessful'));
   } catch (err) {
       console.error('Fallback: Oops, unable to copy', err);
   }

   document.body.removeChild(textArea);
};

const handleShareButtonClick = (url, text, e) => {
   e.stopPropagation();
   setIsShare(true); 
   setSelectedUrl(`${FRONT_URL}/blog/${url}`)
   setSelectedText(text)      
};  


const handleCloseSharePopUp = () => {
   setIsShare(false);
};

  
  return (
    <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    
    <Helmet>
                <title>Mortgage Calculator Ontario | Best Mortgage Rates | RateWave</title>
                <meta name="description" content="Mortgage Calculator Ontario and current mortgage rates Ontario-wide at your fingertips. RateWave's tools help you make smarter mortgage decisions. Try it free." />
                <link rel="canonical" href="https://ratewaves.com/blog" /> 
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Mortgage Calculator Ontario | Best Mortgage Rates | RateWave",
                        "description": "Mortgage Calculator Ontario and current mortgage rates Ontario-wide at your fingertips. RateWave's tools help you make smarter mortgage decisions. Try it free.",
                        "url": "https://ratewaves.com/blog",
                        "author": {
                            "@type": "Organization",
                            "name": "Rate Waves"
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "Rate Waves",
                            "logo": {
                                "@type": "ImageObject",
                                "url": logo
                            }
                        },
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://ratewaves.com/blog"
                        }
                    })}
                </script>           
            </Helmet>
            <h1 style={{ position: 'absolute', left: '-9999px' }}>Mortgage calculator onatario</h1>
    
      {/* Header for larger screens */}
      <Box sx={{ height: '100%', width: '100vw', fontFamily: "Poppins", fontSize: "14px", fontWeight: "400", margin: 0, padding: 0, display: { md: 'flex', sm: 'none', xs: 'none' } }}>
        <Header currentPage={'Blog'} />
      </Box>

      {/* Header for mobile screens */}
      <Box sx={{ display: { md: 'none', sm: 'flex', xs: 'flex' }, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
        <HeaderMobile currentPage={'Blog'} />
      </Box>

      <Box sx={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'left', 
                          width:'100%',height:'10%', paddingTop:'2%', paddingLeft:'2%'}}>
              <Breadcrumbs  aria-label="breadcrumb" >  
                          <WestIcon  
                            onClick={() => window.location.href = '/blog'}  
                            sx={{ 
                              color: '#313131', cursor:'pointer', fontSize: '24px', 
                              transition: 'all 0.3s ease', '&:hover': {     fontSize: '24px',   filter: 'brightness(0.98) drop-shadow(0 0 1px #313131)'  } 
                            }} 
                          />
              </Breadcrumbs>
      </Box>

      {/* Main Content Box */}
      <Box sx={{ display: 'flex', flexDirection: {md:'row', xs:'column'}, width: '100%', height:{md:'95%', xs:'90%'}, maxWidth:{md:'90%', xs:'92%'}, paddingTop:{md:'2.5%', xs:'5%'}, 
        paddingBottom:{md:'2.5%', xs:'5%'}, gap: '36px'
      }}>
          <Box sx={{display:'flex', flexDirection:'column', width:{md:'68%', xs:'100%'}  }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
          <Typography sx={{ fontFamily: 'Poppins', fontSize:{md:'36px', xs:'16px'} , fontWeight: 600, lineHeight: {md:'56px', xs:'24px'}, color: '#212427',
             textAlign: 'left'}}>
            {blog.title}
          </Typography>

          {/* Blog Card - Image and Content */}
          <Card sx={{ display: 'flex', flexDirection: 'row', boxShadow: 'none', gap: '24px', paddingTop: '24px' }}>
            {/* Left Section: Image Box */}
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center',
              width:{md:'100%', xs:'100%'}, height:{md:'540px', xs:'100%'} , position: 'relative', padding: '0 !important',
            }}
            >
              <CardMedia component="img" image={blogimg} alt="Blog cover image"
                sx={{width: '100%', height: '96%', objectFit: 'cover', borderRadius: 1,
                }}
              />
              {/* Share button over the image */}
      <IconButton
        onClick={(e) => handleShareButtonClick(generateUrlFromTitle(blog.title), blog.title, e)}
        sx={{
          position: 'absolute',
          bottom: {md:'30px', xs:'16px'}, 
          right: '10px', 
        
        }}
      >
        <img src={share} alt="Share Icon" style={{ width: '40px', height: '40px' }}/>
      </IconButton>
            </Box>

          </Card>

          {/* Overflow Content (Bottom Section) */}
          <Box  sx={{ width: '100%', paddingBottom: '16px',}} >
            {/* If content exceeds the image height, display here */}
            <Typography  sx={{fontFamily: 'Poppins', fontSize:{md:'16px', xs:'12px'}, fontWeight: 400,
                lineHeight: {md:'36px', xs:'24px'}, color: '#313131',}}
               dangerouslySetInnerHTML={{ __html: blog.blog_content }} 
            />
          </Box>
        
         
        </Box>
        </Box>
        <Box sx={{display:'flex', flexDirection:'column', width:{md:'32%', xs:'100%'},  }}>
               <BlogComponent></BlogComponent>
            </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
            width: '100%', gap: '12px', height:{md:'95%', xs:'90%'}, maxWidth:{md:'90%', xs:'92%'}, paddingTop:{md:'2.5%', xs:'5%'}, 
            paddingBottom:{md:'2.5%', xs:'5%'}, justifyContent: 'flex-start',
          }} >
            <Typography sx={{fontFamily: 'Poppins', fontSize: {md:'24px', xs:'16px'},
              fontWeight: 600, lineHeight: {md:'36px', xs:'30px'}, color: '#313131',
            }}          >
              More articles
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: {md:'row', xs:'column'}, alignItems: 'center',padding:'12px',
            width: '100% ',height:'fit-content', gap: '12px', background: '#f9f9f9', justifyContent: 'flex-start',
             }}>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',
            width:{md: '70%', xs:'100%'}, gap: '12px',  justifyContent: 'flex-start', height:'100%',}}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start',
               width:{md: '20%', xs:'40%'}, gap: '12px', justifyContent: 'flex-start',}}>
                <img src={blogimg} alt="blog"
                style={{ width: '100%', height: '100%',  }}/>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', 
              width:{md: '80%', xs:'60%'}, gap: '12px', height:'100%',  justifyContent: 'center', paddingRight:'10%' }}>
                 <Typography  onClick={() => { window.location.href = `/blog/${blog.blogId}`; }} 
                  sx={{fontFamily:'Poppins', fontSize:{md:'24px', xs:'14px'}, fontWeight:500, lineHeight:{md:'36px', xs:'19px'}, color:'#212427',cursor:'pointer',
                  '&:hover':{color:'#198CCD'}}}>
                      {blog.title}
                  </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems:{md:'flex-end', xs:'center'} ,
            width: {md: '30%', xs:'50%'}, gap: '12px',  justifyContent: 'space-between', paddingLeft: {md: '10%', xs:'0%'} ,}}>
               <Button onClick={handlePrevious} disabled={!prevBlog} variant="text" sx={{
                fontFamily: 'Poppins', fontSize: '16px', fontWeight: 400, lineHeight: '36px',
                color: '#198CCD', textTransform: 'none', padding: 0,backgroundColor:'none', 
                '&:hover':{color:'#198CCD', fontWeight:500,fontSize: '16px',  backgroundColor:'none', background:'none'}
            }}><WestIcon sx={{
              fontFamily: 'Poppins', fontSize: '16px', fontWeight: 400, lineHeight: '36px',
              color:!prevBlog? '#939393' : '#198CCD', textTransform: 'none', padding: 0,backgroundColor:'none', 
              '&:hover':{color:'#198CCD', fontWeight:500,fontSize: '16px',  backgroundColor:'none', background:'none'}
          }}></WestIcon>
            Previous
          </Button>
          <Button onClick={handleNext} disabled={!nextBlog} variant="text" sx={{
                fontFamily: 'Poppins', fontSize: '16px', fontWeight: 400, lineHeight: '36px',
                color: '#198CCD', textTransform: 'none', padding: 0,backgroundColor:'none', 
                '&:hover':{color:'#198CCD', fontWeight:500,fontSize: '16px',  backgroundColor:'none', background:'none'}
            }}>
            Next<EastIcon sx={{
                fontFamily: 'Poppins', fontSize: '16px', fontWeight: 400, lineHeight: '36px',
                color: !nextBlog? '#939393' :  '#198CCD', textTransform: 'none', padding: 0,backgroundColor:'none', 
                '&:hover':{color:'#198CCD', fontWeight:500,fontSize: '16px',  backgroundColor:'none', background:'none'}
            }}></EastIcon>
          </Button>
            </Box>


            </Box>
          </Box>
      <Footer />

      {isShare && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '200',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    
                    <Box
                        sx={{width: { md: '35%', sm: '35%', xs: '85%' },height: 'fit-content',
                            backgroundColor:'white', border: '1px solid',
                            borderColor:'white', borderRadius: '4px ',boxShadow: 'none',
                            boxSizing: 'border-box',zIndex: '3', alignItems: 'center', 
                        }}>
                         <IconButton onClick={handleCloseSharePopUp} sx={{
                                    width: '100%', height: '36px', justifyContent:'flex-end', alignItems:'flex-end'}} >
                                        <HighlightOffIcon sx={{ width: '24px', height: '24px', color: '#939393', '&:hover': {
                                         color: '#939393', backgroundColor: 'none',
                                        background:'none'},}} ></HighlightOffIcon>
                                    </IconButton>
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: 'calc(100% )', gap: '16px',
                            justifyContent: 'center', alignItems: 'center', height: '100%',paddingLeft:'24px',
                            paddingRight:'24px', paddingBottom:'24px'
                        }}>
                                
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
                                    justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'stretch',
                                }}>
                                    <Typography sx={{ textAlign: 'left', fontWeight:500, fontSize:{md:'20px', xs:'16px'}, lineHeight: {md:'28px', xs:'20px'}}}>
                                        Share
                                    </Typography>
                                    <Box
                                    sx={{
                                        display:  'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                        gap: '12px',
                                        justifyContent: 'flex-start',
                                    }}
                                    >
                                   
                                    <img
                                        src={facebook}
                                        alt="facebook"
                                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                        onClick={() =>
                                            window.open(
                                                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                                    selectedUrl + `?title=${encodeURIComponent(selectedText)}`
                                                )}`,
                                                "_blank"
                                            )
                                        }
                                        
                                    />
                                     <img
                                        src={twitter}
                                        alt="twitter"
                                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                        onClick={() => window.open(`https://twitter.com/intent/tweet?url=${selectedUrl}&text=${selectedText}`, "_blank")}
                                    />
                                    <img
                                        src={linkedin}
                                        alt="linkedin"
                                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                        onClick={() =>
                                            window.open(
                                                `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(selectedUrl)}&title=${encodeURIComponent(selectedText)}&summary=&source=`,
                                                "_blank"
                                            )
                                        }
                                    />
                                   
                             
                           




                                    
                                    
                                    </Box> 

                                    <Typography sx={{ textAlign: 'left', paddingTop: '8px', fontWeight:500, fontSize:{md:'20px', xs:'16px'}, lineHeight: {md:'28px', xs:'20px'} }}>
                                    Share link
                                    </Typography>

                                    <Box sx={{
                                        width: 'calc(100%-32px)', height: '48px', border: '1px solid', padding: '10px 16px 10px 16px',
                                        borderColor: '#c9c9c9', borderRadius: '4px', display: 'flex', flexDirection: 'row',background:'#f9f9f9'
                                    }}>
                                        <InputBase
                                         value={selectedUrl}
                                            style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent', }}
                                            inputProps={{
                                                style: {
                                                    fontFamily: 'poppins', fontSize: {md:'16px', xs:'12px'},
                                                    '::placeholder': {
                                                        fontFamily: 'poppins', fontSize: {md:'16px', xs:'12px'},
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{
                                        width: 'calc(100%)', height: '100%', paddingTop: '8px',
                                        display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                    }}
                                    >
                                         <Button variant="contained"  sx={{
                            width: '100%', height: '36px', color: 'white',borderRadius:'8px',border:'1px solid #3A3990',
                            backgroundColor: '#504FA4', textTransform:'none', 
                            '&:hover': { bgcolor:'#CFDAF4', color: '#3A3990',border:'1px solid 65C898',boxShadow:'none' }
                        }} onClick={handleCopyButtonClick}><ContentCopyIcon sx={{ fontSize: { xs: '12px', md: '18px' } }} />Copy link</Button>
                                    </Box>
                                </Box>

                </Box>
                </Box>
                </Box>
                </Box>}
    </Grid>


  );

}

export default BlogDetail;