import React from 'react';
import Button from '@mui/material/Button';

function PrimaryButton(props)
{
    const {sx,...rest}=props;
    return(
        <Button {...rest}
        sx={{width:'100%',height:'36px',borderRadius:'8px',
              textTransform:'none',backgroundColor:'#1F6373',color:'white',fontSize:'16px',alignItems:'center',fontFamily:'Poppins',
              fontWeight:500,
              '&:hover': {
                backgroundColor:'#D2E0E3',
                color: '#1F6373',
                border: '1px solid #1F6373',
              },
              
              '&:active': {
              
                backgroundColor: '#1F6373',
                color:'white',
              },
              ...sx}}
        />
    );
}

export default PrimaryButton;
