
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import { Button, Grid, Typography } from "@mui/material";
import logo from './images/logo.png'
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import SignIn from './SignInSignUp'
import '../App.css';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import mailid from './images/contact_mail.png';
import TextField from '@mui/material/TextField';
import Footer from './Footer';
import Header from './HeaderLanding';
import HeaderMobile from './HeaderLandingMobile';
import { API_URL } from "./constants";
function ContactUs() {

    const [currentPage, setCurrentPage] = useState('Contact us');
    const menus = [
    { label: "Universal Calculator", link: "/" },
    { label: "Canada Mortgage ", link: "/canada-mortgage " },  
    { label: "About us", link: "/about-us" },
    { label: "Contact us", link: "/contact-us" }
  ];
    const [showSignIn, setShowSignIn] = useState(false);

    const handleClickSignIn = () => {
        setShowSignIn(!showSignIn);
    };
    const [showMenu, setShowMenu] = useState(false);

    const handleMenuToggle = () => {
        setShowMenu(!showMenu);
    };

    const [formData, setFormData] = useState({
        name: '',
        mail: '',       
        message: '',
    });
    const handleChangeName = (event) => {
        setFormData({ ...formData, name: event.target.value });
      };
    
    
      const handleChangeEmail = (event) => {
        setFormData({ ...formData, mail: event.target.value });
      };
    
      
      const handleChangeMessage = (event) => {
        setFormData({ ...formData, message: event.target.value });
      };
      
    const handleSubmit = () => {
      
        const name = formData.name;
        const mail = formData.mail;
        const message = formData.message;     
      
        const emailContent = `Name: ${name}\nEmail: ${mail}\nMessage: ${message}`;      
      
        fetch(`${API_URL}/contactUsMail`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
           name,mail,message
          }),
        })
          .then((response) => {
            // Handle the response, show success or error messages, etc.
            console.log('Email sent successfully');
          })
          .catch((error) => {
            console.error('Error sending email:', error);
          });
          setFormData({
            name: '',
            mail: '',
            message: '',
          });  
      };


    return (
        <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>
            <Box sx={{
                height: '100% ', width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'flex', sm: 'none', xs: 'none' }
            }}>
        <Header currentPage={currentPage}></Header>
                {/* <AppBar position="static" sx={{ bgcolor: "#0C276C", height: "72px", width: '100%' }}>
                    <Box sx={{ display: "flex", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: "72px" }}>
                        <Box sx={{ display: "flex", width: { md: '10%', sm: '12%' }, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={logo} alt="" style={{ height: '85%', width: '90%', }} />
                        </Box>
                        <Box sx={{ display: "flex", width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left',
                         paddingLeft: { md: "5%", sm: '0%' }, }}>
                            {menus.map((menu) => (
                                <Button
                                    key={menu.label}
                                    component={Link}
                                    to={menu.link}
                                    sx={{
                                        color: menu.label === currentPage ? '#939393' : 'white', paddingRight: { md: "7%", sm: '3%' }, textTransform: "capitalize", fontFamily: "poppins",
                                        fontSize: "14px", lineHeight: "21px", fontWeight: "400", '&:hover': { color: "#939393", bgcolor: '#0C276C' }
                                    }}>
                                    {menu.label}
                                </Button>
                            ))}
                        </Box>
                         <Box sx={{ display: "flex", width: { md: '10%', sm: '17%' }, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <Button variant="contained" onClick={handleClickSignIn}
                                sx={{
                                    bgcolor: "#EB5648", color: "#fff", borderRadius: "16px", padding: " 4px, 10px, 4px, 10px", textTransform: "capitalize",
                                    fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", width: "90%", height: "36px",
                                    '&:hover': { bgcolor: "#fff", color: '#0C276C' }
                                }} >
                                Sign in
                            </Button>
                        </Box> 

                        {showSignIn && <SignIn />}

                    </Box>
                </AppBar> */}
            </Box>
            <Box sx={{ display: { md: 'none', sm: 'flex', xs: 'flex' }, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
                <HeaderMobile currentPage={currentPage}></HeaderMobile>
                {/*  <AppBar position="static" sx={{ bgcolor: "#0C276C", height: "56" }}>


                    <Toolbar disableGutters>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center', alignItems: 'center', paddingLeft: '18px',
                            width: '30%', marginLeft: '2.5%'
                        }}>
                            <img src={logo} alt="" style={{

                                height: '100%',
                                width: '90%',

                            }}
                            />
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleMenuToggle}

                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                display: showMenu ? "block" : "none", position: "absolute", top: "100%", width: "100vw", zIndex: 5,
                                bgcolor: "#0C276C", justifyContent: 'flex-start', borderRadius: ' 0px 0px 8px 8px', paddingBottom: '20px',
                                
                            }}
                        >
                            {menus.map((menu) => (
                                <Button onClick={handleMenuToggle}
                                    key={menu.label}
                                    component={Link}
                                    to={menu.link}
                                    sx={{
                                        display: "block", height: '40px', color: "white", textTransform: "capitalize", fontFamily: "poppins",
                                        fontSize: "14px", lineHeight: "21px", fontWeight: "400", textAlign: 'left', borderRadius: '0px',
                                        '&:hover': { background: "#CFDAF4", width: '100%', color: '#0C276C' }
                                    }}
                                >
                                    {menu.label}
                                </Button>
                            ))}
                        </Box>
                       <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '12px', marginLeft: 'auto',
                         marginRight: '18px' }}>
                            <Button
                                variant="contained" onClick={handleClickSignIn}
                                sx={{
                                    bgcolor: "#EB5648", color: "#fff", borderRadius: "16px", gap: "10px",
                                    alignContent: "center", padding: "4px 10px", textTransform: "capitalize", fontFamily: "poppins", fontSize: "14px",
                                    lineHeight: "21px", fontWeight: "400", width: "88px", height: "28px", '&:hover': { bgcolor: "#fff", color: '#0C276C' },
                                     ml: "auto"
                                }}
                            >
                                Sign in
                            </Button>

                        </Box> 
                        {showSignIn && <SignIn />}





                    </Toolbar>


                </AppBar>*/}
            </Box>
            <Box sx={{
                width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)', xs: 'calc(100%-10%)' }, display: 'flex', 
                flexDirection: { md: 'row', sm: 'row', xs: 'column' }, height: '100%',
                paddingLeft: { md: '16%', sm: '5%', xs: '5%' }, paddingRight: { md: '16%', sm: '5%', xs: '5%' },
                 paddingTop: { md: '5%', sm: '5%', xs: '5%' },
               
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: { md: 'column', sm: 'column', xs: 'column' }, width: { md: '100%', sm: '100%', xs:'100%'} , 
                    height: '100%',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection:  { md: 'column', sm: 'column', xs: 'column' } , width:{ md: '100%', sm: '100%', xs:'calc(100%-10%)'} ,

                        height:'100% ',  padding:{ md:'20px', sm: '20px', xs: '15px' } ,
                        alignItems: 'center',justifyContent:'center'
                    }}>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>Contact us</Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingTop:'10px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>We are appreciating reaching out to us. At Ratewaves, we are passionate about helping you achieve your
                         goals and dreams. Whether you have questions, need guidance, or are ready to embark on your homeownership
                          journey, our team is here to support you every step of the way.

                        </Typography>
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', marginTop:'10%',
                            justifyContent: 'left',  alignItems: 'left',
                        }}>    <Box sx={{
                            display: 'flex', flexDirection: 'row', width: '100%',
                            justifyContent: 'center',  alignItems: 'center',gap:'5px'
                        }}>
                            <img src={mailid} alt="" style={{textAlign:'center',height:'36px' , width:'36px', alignItems:'center' }} />
                            contact@ratewaves.com
                            </Box>
                           {/*  <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%',
                            justifyContent: 'left',  alignItems: 'center',
                        }}>
                            <Typography sx={{
                                fontFamily: 'poppins', fontSize: '16px', paddingTop: '5px',
                                lineHeight: '28px', fontWeight: '400', color: '#EB5648',textAlign:'left'
                            }}>

                                contact@ratewaves.com
                            </Typography></Box> */}
                        </Box>
                    </Box>
                </Box>

                {/* <Box sx={{
                    display: 'flex', flexDirection:{ md: 'column', sm: 'column', xs: 'column' }, width: { md: '40%', sm: '40%', xs: '100%' },
                     height:{ md: '650px', sm: '500px', xs: '550px' }, border :{md:' 1px solid #DCDADA ', sm:'1px solid #DCDADA ', xs: '1px solid #DCDADA ' },                     
                    alignItems: 'left',  padding:{ md:'40px', sm: '20px', xs: '25px' } ,
                    justifyContent:'center'
                }}>
                   <style>
        {`
          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 1px solid #0C276C !important;
            box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
          }
        `}
      </style>
                   
                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: '12px', paddingTop: '5px', textAlign: 'left',
                        lineHeight: '18px', fontWeight: '400', color: " #313131",
                    }}>
                       Please complete and submit the form below to help us answer  your  email as completely and quickly  as possible.
                    </Typography>
                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: '12px', paddingTop: '15px', textAlign: 'left',
                        lineHeight: '18px', fontWeight: '400', color: " #939393",
                    }}>
                       Name
                    </Typography>
                    <TextField value={formData.name}  onChange={handleChangeName} required
                        InputProps={{
                            sx: {
                                color: " #626262", borderRadius: '8px', width: '100%', border: '1px solid #CFC9C9'
                                , height: '36px',marginTop:'5px'
                            },
                            '& label': {
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center'
                            }
                        }}
                    />
                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: '12px', paddingTop: '20px', textAlign: 'left',
                        lineHeight: '18px', fontWeight: '400', color: " #939393",
                    }}>
                       Email
                    </Typography>
                    <TextField  type="email" value={formData.mail} onChange={handleChangeEmail} required
                        InputProps={{
                            sx: {
                                color: " #626262", borderRadius: '8px', width: '100%', border: '1px solid #CFC9C9'
                                , height: '36px',marginTop:'5px'
                            },
                            '& label': {
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center'
                            }
                        }} />

                   
                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: '12px', paddingTop: '20px', textAlign: 'left',
                        lineHeight: '18px', fontWeight: '400', color: " #939393",
                    }}>
                        Message
                    </Typography>
                    <TextField
                       multiline rows={4} value={formData.message}  onChange={handleChangeMessage} required
                        InputProps={{
                            sx: {
                                color: " #626262", borderRadius: '8px', width: '100%', border: '1px solid #CFC9C9'
                                ,marginTop:'5px'
                            },
                            '& label': {
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center'
                            }
                        }}
                    />
                    <Box sx={{
                        display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'right', justifyContent: 'right',
                    }}>
                        <Button variant="contained"  onClick={handleSubmit}
                            sx={{
                                width: '124px', height: '36px', marginTop: '35px', padding: ' 4px, 10px, 4px, 10px', background: '#EB5648', color: 'white'
                                , borderRadius: '8px', textTransform: 'none', fontFamily: 'poppins', fontSize: '14px',                            
                                lineHeight: '24px', fontWeight: '400',
                                 '&:hover': { bgcolor: "#fff", color: '#EB5648', border: '1px solid #EB5648 ' },
                            }}>
                            Submit
                        </Button>

                    </Box>












                </Box> */}

            </Box>
<Footer></Footer>
        </Grid>
    );

}

export default ContactUs;