import { Box, Button, Grid, OutlinedInput, Typography } from "@mui/material";
import axios from "axios";
import { useState, useEffect, useRef  } from "react";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';
import Checkbox from '@mui/material/Checkbox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { API_URL } from "./constants";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Select, MenuItem, InputLabel } from '@mui/material';

function BuilderSignUp(props) {
    const{signUpPopUp, setSignUpPopUp,signInPopUp, setSignInPopup, editProfile, setEditProfile, changePasswordPopUp,
       setChangePasswordPopUp, resetPopUp, setResetPopUp, resetConfirmPopUp, setResetConfirmPopUp, emailForPasswordChange, 
       setEmailForPasswordChange,thankYouPopUp, setThankYouPopUp, isTermsAndCondition, setIsTermsAndCondition, verificationPopUp, setVerificationPopUp} = props;
   let Uname;
   //let phoneverify
   //console.log('param', changePasswordPopUp);
   const [selectedBuilder, setSelectedBuilder] = useState('My Profile'); 
   const [builders, setBuilders] = useState([]);
   const [isBuilder, setIsBuilder] = useState(true);
   const [otpError, setOtpError] = useState('')

  // const [isTermsAndCondition, setIsTermsAndCondition] = useState(false);
    const [builderName, setBuilderName] = useState(false);
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [userpassword, setUserPassword] = useState('');
    const [businessName, setbusinessName] = useState('');
    const [userphone, setUserphone] = useState('');
    const [userWhatsApp, setUserWhatsApp] = useState('');
    const [businessbuilderName, setbusinessbuilderName] = useState('');
    const [showErrorbusinessName, setshowErrorbusinessName] = useState(false);
    const [showErroremailId, setShowErroremailId] = useState(false);
    const [showErrorphoneNumber, setShowErrorphoneNumber] = useState(false);
    const [showErrorwhatsAppNumber, setShowErrorwhatsAppNumber] = useState(false);
    const [showErrorbuilderName, setShowErrorbuilderName] = useState(false);
    const [showErrorPassword, setShowErrorPassword] = useState(false);
    const [showErrorlocation, setShowErrorlocation] = useState(false);
    const [showErroremailExist, setShowErroremailExist] = useState(false);
    const [signUpDetails, setSignUpDetails] = useState({
        Name: businessName,
        builderName: businessbuilderName,
        password: userpassword,
        Phonenumber:userphone,
        whatsAppNumber:userWhatsApp,
        emailId: email,
        location: location
    })
    const handleInputEmailChange = (event) => {
        setSignUpDetails({
          ...signUpDetails,
          emailId: event.target.value,
        });
        setShowErroremailId(false)
        setShowErroremailExist(false)
      };
      const handleInputLocationChange = (event) => {
        setSignUpDetails({
          ...signUpDetails,
          location: event.target.value,
        });
        setShowErrorlocation(false)
      };
      const handleInputPhonenumberChange = (event) => {
        setSignUpDetails({
          ...signUpDetails,
          Phonenumber: event.target.value,
        });
        setShowErrorphoneNumber(false)
      };
      const handleInputWhatsAppNumberChange = (event) => {
        setSignUpDetails({
          ...signUpDetails,
          whatsAppNumber: event.target.value,
        });
        setShowErrorwhatsAppNumber(false)
      };
      const handleInputPasswordChange = (event) => {
        setSignUpDetails({
          ...signUpDetails,
          password: event.target.value,
        });
        setShowErrorPassword(false)
      };
      
      // const handleBuilderNameChange = (event) => {
      //   setSignUpDetails({
      //     ...signUpDetails,
      //     builderName: event.target.value,
      //   });
      //   setShowErrorbuilderName(false)
      // };
      const handleChangebusinessUserName = (event) => {
        setSignUpDetails((prevState) => ({
          ...prevState,
          Name: event.target.value,
        }));
      setshowErrorbusinessName(false)
      }

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };
      let id;
      let submissionInProgress = false;
    const handleSignUpButtonClick = async () => {
        if (signUpDetails.Name === '') {
            setshowErrorbusinessName(true);
          } 
          
          else if(signUpDetails.password === '') {
            setShowErrorPassword(true);
          } 
          else if(signUpDetails.Phonenumber === '') {
            setShowErrorphoneNumber(true);
          } 
          else if(signUpDetails.whatsAppNumber === '') {
            setShowErrorwhatsAppNumber(true);
          } 
          else if(signUpDetails.emailId === '') {
            setShowErroremailId(true);
          }  
          else if(signUpDetails.location === '') {
            setShowErrorlocation(true);
          } 
          else if (!submissionInProgress) {       
            submissionInProgress = true;
     const username = signUpDetails.Name;  
     const password = signUpDetails.password;
     const phone = signUpDetails.Phonenumber;
     const whatsapp = signUpDetails.whatsAppNumber;
     const email = signUpDetails.emailId;
     const location = signUpDetails.location;

     try {
      const emailExistsResponse = await axios.post(`${API_URL}/check-email-exists`,
      {
        email:signUpDetails.emailId,
      });
      const { emailExists } = emailExistsResponse.data;
      console.log('Email already exists in the database',emailExistsResponse.data);
      if (emailExists) {
        console.log('Email already exists in the database');
        setShowErroremailExist(true)
      } else { 
        setShowErroremailExist(false)
        const response = await axios.post(`${API_URL}/insert-listing-agent-profile`, {
            username: username,             
            password: password,      
            phone: phone,
            email: email,
            location: location,
            whatsapp:whatsapp,
        });
      //  console.log('Insert response:', response.data);
        const lastrowId = response.data.listing_agent_id
        id = response.data.listing_agent_id
       // console.log('lastrowId1', lastrowId);
       localStorage.setItem('listing_agent_id',lastrowId) 
       setThankYouPopUp(true) ;     
       setSignUpPopUp(false)
       submissionInProgress = false;
      if (response.data.message === 'listing agent profile details inserted successfully') {
     //   console.log('Insert response check',);  
        const response = await axios.post(`${API_URL}/signup-thankyou`, {        
          email: signUpDetails.emailId,
          listingagentid: id,
      });
     // console.log('Insert response:', response.data);
       
       
        }
    }}

    catch (error) {
      console.error(error);
      submissionInProgress = false;
    }}
         

    };
    

    const handleTermsClick = () => {
      setIsTermsAndCondition(true)
      setSignUpPopUp(false)
  }
    const closePopup = () => {
        setSignUpPopUp(false)
    }
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
     // console.log('Checkbox changed:', event.target.checked);
      setIsChecked(event.target.checked);
    };
    

    const [signInEmail, setsignInEmail] = useState('');
    const [storedOTP, setStoredOTP] = useState('');
 
    const [signInPassword, setsignInPassword] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [showErrorsignInEmailId, setShowErrorsignInEmailId] = useState(false);
   const [showErrorSignInPassword, setShowErrorSignInPassword] = useState(false);
   const [isCheckedSignIn, setisCheckedSignIn] = useState(false);
   const [istwostepverification, setIstwostepverification] = useState(false);
   const [otpDigits, setOtpDigits] = useState(['', '', '', '', '', '']);
   const inputRefs = useRef([
    { ref: useRef(null), value: '' },
    { ref: useRef(null), value: '' },
    { ref: useRef(null), value: '' },
    { ref: useRef(null), value: '' },
    { ref: useRef(null), value: '' },
    { ref: useRef(null), value: '' },
  ]);

    const [signInDetails, setsignInDetails] = useState({
        signInEmailId: signInEmail,
        password: signInPassword,
        
    })
     let mailtocheck;
    const handleInputsignInEmailChange = (event) => {
        setsignInDetails({
          ...signInDetails,
          signInEmailId: event.target.value,
        });
        setShowErrorsignInEmailId(false)       
        mailtocheck = event.target.value

        const rememberMeData = JSON.parse(localStorage.getItem('rememberMeData')) || [];
        const foundData = rememberMeData.find((data) => data.email ===  event.target.value);
      //  console.log("foundData",rememberMeData)
        if (foundData) {
            // If data is found, set the password field with the found data
            setsignInDetails({
                signInEmailId: event.target.value,
                password: foundData.password,
            });
            setisCheckedSignIn(true);
        }
        
      };
   
    
      const handleInputPasswordChangeSignIn = (event) => {
        setsignInDetails({
          ...signInDetails,
          password: event.target.value,
        });
        setShowErrorSignInPassword(false)
      };
      
      
      const handleCheckboxChangetwostep =  (event) => {
        // console.log('Checkbox changed:', event.target.checked);
         setIstwostepverification(event.target.checked);
         setOtpDigits(['', '', '', '', '', '']);
         inputRefs.current.forEach((inputField) => {
          inputField.value = ''; // Clearing the value of each input field
        });
        
       };

       const handleSendOTP = async () => {
        setOtpDigits(['', '', '', '', '', '']); 
        inputRefs.current.forEach((inputField) => {
          inputField.value = ''; // Clearing the value of each input field
        });
        setOtpSent(true)
        setOtpError('')
       
          try {
            const findmobResponse = await axios.post(`${API_URL}/twostepmail`,
            {
              email:signInDetails.signInEmailId,
            });
            console.log("findmobResponse",findmobResponse.data); 
          //  phoneverify = findmobResponse.data.phone;

            // const expirationTime = new Date(); // Get the current time
            // expirationTime.setMinutes(expirationTime.getMinutes() + 1); // Set expiration after 5 minutes
      
            // // Store the OTP and its expiration time
            // const storedOTPvalidity = {
            //   otp: OTP,
            //   expiration: expirationTime.getTime(), // Store expiration time as milliseconds since epoch
            // };
      
            // setStoredOTP(storedOTPvalidity); 
           
          } catch (error) {
            console.error('Error:', error);
            setOtpSent(false)
          }
      

       };

       const handleArrowKeyPress = (index, event) => {
        if (event.key === 'ArrowLeft' && index > 0) {
          inputRefs.current[index - 1].ref.current.focus();
        } else if (event.key === 'ArrowRight' && index < inputRefs.current.length - 1) {
          inputRefs.current[index + 1].ref.current.focus();
        }
      };
       
       const handleOtpDigitChange = (index, event) => {
        const inputField = inputRefs.current[index];
        const { value } = event.target;
    
        inputField.value = value;
        const updatedOtpDigits = [...otpDigits];
        updatedOtpDigits[index] = event.target.value;
        setOtpDigits(updatedOtpDigits);
        setOtpError('')
    
        if (value && index < inputRefs.current.length - 1) {
          inputRefs.current[index + 1].ref.current.focus();
        }
      };

      


      const handleOTPButtonClick = async () => {
        const token = localStorage.getItem('token')
        const concatenatedOTP = otpDigits.join('');
        console.log("concatenatedOTP",concatenatedOTP)
        try {
          const response = await fetch('https://api.ipify.org');
          const ipAddress = await response.text();
          console.log("ip",ipAddress)
          const verifycoderesponse = await axios.post(`${API_URL}/verifycode`,
          {
            enteredOTP: concatenatedOTP,          
            token: token,
            ipAddress: ipAddress,
           
          });
          console.log("verifycodeResponse",verifycoderesponse.data); 
          if (verifycoderesponse.data.message === 'OTP matched. Login details updated.') {
            const login_id = verifycoderesponse.data.login_id;
            console.log("login_id",login_id); 
            localStorage.setItem('login_id',login_id) 
            window.location.href = '/personal-projects';
          }
          if (verifycoderesponse.data.otpMismatch) {
            setOtpError('Incorrect Code entered. Please try again.')
          } 
          if (verifycoderesponse.data.timeExpired) {
            setOtpError('Code expired. Please try again.')
          } 
          console.log("verifycodeResponse",verifycoderesponse.data); 
        }catch (error) {
          console.error('Error:', error);
          
        }
       console.log("otperror",otpError)
      
      }
      
      const handleCheckboxChangeSignIn = (event) => {
       // console.log('Checkbox changed:', event.target.checked);
        setisCheckedSignIn(event.target.checked);
      };
    const [showPasswordSignIn, setshowPasswordSignIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const togglePasswordVisibilitySignIn = () => {
        setshowPasswordSignIn(!showPasswordSignIn);
      };
  
      let myprofilelisting_agent_id


const handleSignInButtonClick = async () => {
   // console.log("selectedRole", selectedRole);
   
   if (signInDetails.signInEmailId === '') {
    setShowErrorsignInEmailId(true);
  } else if (signInDetails.password === '') {
    setShowErrorSignInPassword(true);
  } else {
    const email = signInDetails.signInEmailId;
    const password = signInDetails.password;
    const isCheckSignIn = isCheckedSignIn;     
    try {
      if (selectedRole === 'builder') {
        const response = await axios.post(`${API_URL}/signin-builder`, {
          email: email,
          password: password,
          rememberMe: isCheckSignIn,
        });
  
        if (response.data.login_status === 'PENDING') {
          setErrorMessage('Please check your email and click the verification link before signing in.');
        } else if (response.data.error) {
          setErrorMessage(response.data.error);
        } else if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          setVerificationPopUp(true)
setOtpError('')
          setOtpDigits(['', '', '', '', '', '']); 
          inputRefs.current.forEach((inputField) => {
            inputField.value = ''; // Clearing the value of each input field
          });
          setOtpSent(true)  
        
            try {
              const findmobResponse = await axios.post(`${API_URL}/twostepmail`,
              {
                email:signInDetails.signInEmailId,
               
              });
              console.log("findmobResponse",findmobResponse.data); 
             // phoneverify = findmobResponse.data.phone;
              // const expirationTime = new Date(); // Get the current time
              // expirationTime.setMinutes(expirationTime.getMinutes() + 1); // Set expiration after 5 minutes
        
              // // Store the OTP and its expiration time
              // const storedOTPvalidity = {
              //   otp: OTP,
              //   expiration: expirationTime.getTime(), // Store expiration time as milliseconds since epoch
              // };
        
              // setStoredOTP(storedOTPvalidity); 
             
            } catch (error) {
              console.error('Error:', error);
              setOtpSent(false)
            }
          


         // setSignInPopup(false)
       // window.location.href = '/personal-projects';
          setBuilderName(true);
          localStorage.setItem('builder', true);
        } else {
          setErrorMessage('Please check your email and password, as they may be incorrect.');
        }
      }
  
      if (selectedRole === 'admin') {
        const response = await axios.post(`${API_URL}/signin-admin`, {
          email: email,
          password: password,
        });
  
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else if (response.data.token) {
console.log("admintoken",response.data)
          localStorage.setItem('admin', response.data.userData.name);
          window.location.href = '/admin-check';
        } else {
          setErrorMessage('Please check your email and password, as they may be incorrect.');
        }
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Please check your email and password, as they may be incorrect.');
    }
  }
//  console.log("checked", isCheckedSignIn);
  if (isCheckedSignIn) {
    const email = signInDetails.signInEmailId;
    const password = signInDetails.password;
    const rememberMeData = JSON.parse(localStorage.getItem('rememberMeData')) || [];
    const newRememberMe = { email, password };
    rememberMeData.push(newRememberMe);
    localStorage.setItem('rememberMeData', JSON.stringify(rememberMeData));
  }
};

    const closeSignInPopup = () => {
        setSignInPopup(false)
    }

    const closeVerificationPopup = () => {
      setVerificationPopUp(false)
  }
    
    
    const handleCreateAccountButtonClick = () => {
        setSignUpPopUp(true)
        setSignInPopup(false)
    }
    const handleMovetoSignInButtonClick = () => {
        setSignUpPopUp(false)
        setSignInPopup(true);
    }
    async function fetchData() {
      const builderid = localStorage.getItem('listing_agent_id');  
     // console.log("editProfile",editProfile)
   if(editProfile===true)
    {

      try {
        const response = await axios.post(`${API_URL}/personal-details`, {          
          builderid: builderid,
      });
      setSignUpDetails({
        Name: response.data[0].user_name,
       // builderName: response.data[0].builder_name,
        password: response.data[0].password,
        Phonenumber: response.data[0].phone_number,
        emailId: response.data[0].email_id,
        location: response.data[0].location,
      }); 
   //  updateId=response.data[0].listing_agent_id
       // console.log("details",response.data)
       // setImages(response.data.image_list);       
      
     
     // console.log("images",response.data.image_list)
    } 
    catch (error) {
        console.error('Error:', error.message);
    }
    }
  }
  
    useEffect(() => {
      fetchData();
    }, []);


    const handleForgotPassword = () => {
    setSignInPopup(false)
     setResetPopUp(true)
   
      
  };
  const handleReturntosignin = () => {
     setSignInPopup(true)
     setResetPopUp(false)
  setResetConfirmPopUp(false)
     
 };

  
    const [resetEmail, setResetEmail] = useState('');
  
    

  
    const [showErrorResetEmail, setshowErrorResetEmail] = useState(false);
 

    const [restDetail, setResetDetail] = useState({
        restEmail: resetEmail,
       
        
    })
    const handleResetLinkEmai = (event) => {
        setResetDetail({
          ...restDetail,
          restEmail: event.target.value,
        });
        setshowErrorResetEmail(false)
      };
      let changePasswordEmail 
   
      const handleResetLinkClick = async () => {
        if(setResetDetail.restEmail === '') {
          setshowErrorResetEmail(true);
        } 
        else{
          const email = restDetail.restEmail
         // changePasswordEmail = restDetail.restEmail
          try {
           
            const response = await axios.post(`${API_URL}/sendmail`, {
              email: email,                 
               
            });
          //  console.log('Insert response:', response.data);
           
        
        }
    
        catch (error) {
          console.error(error);
        }
     

        }
        setResetConfirmPopUp(true)
        setResetPopUp(false)
      };   
    

   
    const closeResetPopUp = () => {
        setResetPopUp(false)
        setResetConfirmPopUp(false)
    };

    const [oldPassword, setOldPassword] = useState('');    
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
   

  
    const [showErrorNewPassword, setShowErrorNewPassword] = useState(false); 
    const [showErrorOldPassword, setShowErrorOldPassword] = useState(false);
    const [showErrorConfirmPassword, setShowErrorConfirmPassword] = useState(false);

    const [passwordChangeDetail, setPasswordChangeDetail] = useState({
       oldPassword: oldPassword,
       newPassword: newPassword,
       confirmPassword: confirmPassword
        
    })

    const [showOldPassword, setshowOldPassword] = useState(false);

    const togglePasswordVisibilityOldPassword = () => {
        setshowOldPassword(!showOldPassword);
      };
    const handleOldPassword = (event) => {
        setPasswordChangeDetail({
          ...passwordChangeDetail,
          oldPassword: event.target.value,
        });
        setShowErrorOldPassword(false)
      };
      
      const [showNewPassword, setshowNewPassword] = useState(false);

      const togglePasswordVisibilityNewPassword = () => {
        setshowNewPassword(!showNewPassword);
        };
      const handleNewPassword = (event) => {
          setPasswordChangeDetail({
            ...passwordChangeDetail,
            newPassword: event.target.value,
          });
          setShowErrorNewPassword(false)
        };
        
        const [showConfirmPassword, setshowConfirmPassword] = useState(false);

        const togglePasswordVisibilityConfirmPassword = () => {
            setshowConfirmPassword(!showConfirmPassword);
          };
        const handleConfirmPassword = (event) => {
            setPasswordChangeDetail({
              ...passwordChangeDetail,
              confirmPassword: event.target.value,
            });
            setShowErrorConfirmPassword(false)
          };

      const handlePasswordChangeClick = async () => {
       
       if(passwordChangeDetail.newPassword === '') {
          setShowErrorNewPassword(true);
        }
        else if(passwordChangeDetail.confirmPassword === '') {
          setShowErrorConfirmPassword(true);
        }
        else if(passwordChangeDetail.newPassword !== passwordChangeDetail.confirmPassword){
          setShowErrorOldPassword(true)
        }
        else{
        //  oldPassword= passwordChangeDetail.oldPassword
        const newPassword= passwordChangeDetail.newPassword
       //   confirmPassword= passwordChangeDetail.confirmPassword
          
          const email =  emailForPasswordChange 
         // console.log("changePasswordEmail",changePasswordEmail)
      //    console.log("email",email)
          try {
           
          const response = await axios.post(`${API_URL}/forgotpassword`, {
          email: email,
          newPassword: newPassword,
                     
               
            });
         //   console.log('Insert response:', response.data);
           
        
        }
    
        catch (error) {
          console.error(error);
        }
     

        } 
        setSignInPopup(true)
        setChangePasswordPopUp(false)
      };   
    

   
    const closeChangePasswordPopUp = () => {
        setChangePasswordPopUp(false)
        
    };
    const [selectedRole, setSelectedRole] = useState('builder');

    const handleRoleChange = (event) => {
      setSelectedRole(event.target.value);
     // console.log(event.target.value)
      // if (event.target.value === 'builder') {
      //   setIsBuilder(true)
      // }
      // if (event.target.value === 'admin') {
      //   setIsBuilder(false)
      // }
    };
    const handleCloseThankYou = () => {
      setThankYouPopUp(false)
      
  };
  const closePopupTerm = () => {
    setIsTermsAndCondition(false)
    setSignUpPopUp(true)
    
};

const handleBuilderChange = (event) => {
  if (event.target.value === 'My Profile') {
    setSelectedBuilder('My Profile');
  } else {
    setSelectedBuilder(event.target.value);
    localStorage.setItem('builder_name', event.target.value);
  }
  
  fetchBuilderData();
}; 
async function fetchBuilderData() {
  const email = signInDetails.signInEmailId;
  const password = signInDetails.password;
  if (selectedRole === 'builder') {
   
  try{
    const response = await axios.post(`${API_URL}/select-builder`, {
      email: email,
      password: password,    
    });
    if(response.data.length > 0){
      
    setBuilders(response.data);}
   // console.log("Builders state:", response.data);  
  

  } 
  catch (error) {
      console.error('Error:', error.message);
  }
}
}

useEffect(() => {
 // console.log("Builders state:", builders);
  fetchBuilderData();
    
}, [signInDetails.signInEmailId, signInDetails.password, selectedRole, selectedBuilder]);  
    return (
        <Grid container sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
            padding: '30px'
        }}>
           { signUpPopUp &&   <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '400px', sm: '450px', xs: '320px' },
           height: { md:'730px', sm: '90%', xs: '90%' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
          borderRadius: '8px 8px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '36px 28px 36px 28px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflowY:'auto',
          
         }}
       >
         <IconButton  onClick={closePopup} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>
            <div style={{ display: 'flex', flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center'}}>
                <Box sx={{height:'40px',width:'40px',borderRadius:'40px',border:'1px solid #504FA4',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                    <PersonIcon sx={{color:'#504FA4',height:'40px',width:'40px'}}/>
                </Box>
                <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins'}}>Sign Up</Typography>
             </div>
                <Box sx={{ display: 'flex', flexDirection: 'column',  gap: '16px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%',gap:'5px' }}>
          <Typography sx={{
            fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
          }}>
            Business username<span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField variant="outlined"  value={signUpDetails.Name}
            onChange={(event) => { handleChangebusinessUserName(event) }}
            error={showErrorbusinessName}
            helperText={showErrorbusinessName ? 'Please enter your business user name' : ''}
            sx={{ width: "100%",  fontSize: '14px', color: '#212427', fontFamily: 'Poppins', lineHeight: "18px", fontWeight: "400", }}
            InputProps={{
              sx: {
                height: '36px',  fontSize: '14px', color: " #313131",fontFamily: 'Poppins', lineHeight: "18px", fontWeight: "400",
                borderRadius:'8px',background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
              },

            }} />
        </Box>
                  
                    {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500 }}>
                            Builders name<span style={{ color: 'red' }}>*</span></Typography>
                        <TextField
                            value={signUpDetails.builderName}
                            onChange={handleBuilderNameChange}
                            type="text"
                            name="user_name"                           
                            error={showErrorbuilderName}
                            helperText={showErrorbuilderName ? 'Please enter your builders name' : ''}
                            InputProps={{
                                sx: {
                                    height: '36px', color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px', background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
                                  },
                            }}
                        />
                    </Box> */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>
                            Create password<span style={{ color: 'red' }}>*</span> </Typography>
                        <TextField
                            value={signUpDetails.password}
                            onChange={handleInputPasswordChange}
                            type={showPassword ? 'text' : 'password'}
                            name="user_password"                          
                            error={showErrorPassword}
                            helperText={showErrorPassword ? 'Please enter your password' : ''}                      
                            InputProps={{
                                sx: {
                                    height: '36px',  color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
                                  },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            color="primary"
                                        >
                                            {showPassword ? <VisibilityOutlinedIcon sx={{color:'#313131'}} /> : <VisibilityOffOutlinedIcon sx={{color:'#313131'}} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>
                            Business phone number<span style={{ color: 'red' }}>*</span> </Typography>
                        <TextField
                            value={signUpDetails.Phonenumber}
                            onChange={handleInputPhonenumberChange}
                            type="number"
                            name="user_phone"                           
                            InputProps={{
                                sx: {
                                    height: '36px',color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
                                  },
                            }}
                            error={showErrorphoneNumber}
                            helperText={showErrorphoneNumber ? 'Phonenumber is mandatory' : ''}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>
                        Business whatsapp number<span style={{ color: 'red' }}>*</span> </Typography>
                        <TextField
                            value={signUpDetails.whatsAppNumber}
                            onChange={handleInputWhatsAppNumberChange}
                            type="number"
                            name="user_phone"                           
                            InputProps={{
                                sx: {
                                    height: '36px',color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
                                  },
                            }}
                            error={showErrorwhatsAppNumber}
                            helperText={showErrorwhatsAppNumber ? 'WhatsApp number is mandatory' : ''}
                        />
                    </Box>
       <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>
                            Business email id<span style={{ color: 'red' }}>*</span> </Typography>
                        <TextField
                            value={signUpDetails.emailId}
                            onChange={handleInputEmailChange}
                            type="text"
                            name="user_email"                           
                            error={showErroremailId}
                            helperText={showErroremailId ? 'Please enter your email' : ''}
                            InputProps={{
                                sx: {
                                    height: '36px',color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9', padding:'10px, 14px, 10px, 14px'
                                  },
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>
                            Location<span style={{ color: 'red' }}>*</span> </Typography>
                        <TextField
                            value={signUpDetails.location}
                            onChange={handleInputLocationChange}
                            type="text"
                            name="user_email"                           
                            error={showErrorlocation}
                            helperText={showErrorlocation ? 'Please enter your location' : ''}
                            InputProps={{
                                sx: {
                                    height: '36px',color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9', padding:'10px, 14px, 10px, 14px'
                                  },
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row',alignItems: 'center' }}>
  <Checkbox
    checked={isChecked}
    onChange={(event) => handleCheckboxChange(event)}
    sx={{
      color: '#313131', // Change the checked color here
      background:'none' ,
      width:'2px',
      height:'2px',
      marginRight:'10px',
      '&.Mui-checked': {
        color: '#3A3990', // Change the checked color here as well
       background:'none' ,
       width:'2px',
       height:'2px'
      },
     
    }}
  />
  {/* <Typography sx={{ fontSize: '14px', color: '#313131', fontFamily: 'poppins' }}>
    I agree to the terms & condition
  </Typography> */}
    <>
   <Typography sx={{ fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: '#313131', fontFamily: 'poppins',paddingRight:'8px' }}>
    I agree to the{' '}
  </Typography>
            <Button onClick={handleTermsClick} variant="text"
             sx={{ background: 'none',cursor: 'pointer', textDecoration: 'none',textTransform:'none', color:'#504FA4',
              fontSize:  { md: '14px', sm: '14px',xs:'12px' },  fontFamily: 'poppins',padding:'0' }}>
              terms & condition
            </Button>
          </>
      
</Box>


                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px',}}>
                        <Button variant="contained"  disabled={!isChecked}  size="large" sx={{
                            width: '100%', height: '36px', color: 'white',borderRadius:'8px',
                            backgroundColor: '#3A3990', textTransform:'none', 
                            '&:hover': { bgcolor: "#3A3990", color: '#fff',border: "1px solid #3A3990" }
                        }}
                            onClick={handleSignUpButtonClick}> {editProfile=== true ? 'Save changes' : 'Sign up'}</Button>
  { showErroremailExist && (
        <Typography sx={{ color: 'red', marginTop: '8px', textAlign: 'center', fontSize: { md: "14px", sm: "14px", xs: "12px" }, fontFamily: 'poppins'  }}>
          Sorry, this email is already in use.
        </Typography>
      )}
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography sx={{ fontSize: '14px', color: '#313131', fontFamily: 'poppins' ,}}>Already  have an account? </Typography>
                            <Button
        sx={{height:'20px',
        textTransform:'none',
          fontSize: '14px',
          color: '#2D96D1', // Change text color to match your design
          fontFamily: 'poppins',
          paddingLeft: '10px',
          textDecoration: 'none', // Remove default underline
          border: 'none', // Remove border
          background: 'none', 
          textTransform:'none'// Remove background
        }}
        onClick={handleMovetoSignInButtonClick}
      >
        Sign in
      </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            </Box>
            </Box>}

            { isTermsAndCondition &&   <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '400px', sm: '450px', xs: '320px' },
           height: { md:'730px', sm: '90%', xs: '90%' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
          borderRadius: '8px 8px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '36px 28px 36px 28px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflow:'auto',
          
         }}
       >
         <IconButton  onClick={closePopupTerm} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
 <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px',}}>
          <Typography sx={{ fontSize: '18px', color: '#313131', fontFamily: 'poppins',fontWeight:600 }}>
          Listing Agent Terms & Conditions
  </Typography> 
 
                       
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Eligibility</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>By using our platform to add property listings, you agree that you are a duly authorized real estate agent or property owner, and you have the legal authority to represent the property being listed.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Accurate Information</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You are responsible for providing accurate and up-to-date information about the properties you list on our platform, including but not limited to property details, price, availability, and images.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You agree not to engage in deceptive or fraudulent practices, such as misrepresenting property information.
                         </Typography>

                         </li>  
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Ownership and Authorization</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You confirm that you have the legal authority and consent of the property owner to list the property on RateWaves.com.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Compliance with Laws</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You agree to comply with all applicable laws and regulations, including fair housing and real estate disclosure requirements. You are responsible for ensuring that your listings do not violate any laws or regulations.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Listing Agent Conduct</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You will conduct yourself in a professional and ethical manner when using RateWaves.com. You agree not to engage in abusive, discriminatory, or harassing behavior towards other listing agent or our staff.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Removal of Listings</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You agree that we (Ratewaves.com) reserve the right to remove or modify any listings from RateWaves.com. 
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Copyright and Intellectual Property</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You confirm that you have the rights to use any images, text, or other materials you upload with your listings.
                        </Typography>

                        </li>     
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You agree not to infringe on the copyright or intellectual property rights of others.
                        </Typography>

                        </li>                     
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Changes to Terms & Conditions</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>We (Ratewaves.com) may update or change these terms and conditions at any time. It is your responsibility to review them periodically.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Termination</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>We(Ratewaves.com) reserve the right to suspend or terminate your account and access to our site.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Limitation of Liability</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves.com shall not be held liable for any direct or indirect damages, losses, or claims arising from technical issues, including but not limited to lost profits, data loss, or business interruption.
                        </Typography>

                        </li>      
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves.com makes every reasonable effort to protect the security and confidentiality of listing data. However, Ratewaves.com shall not be held liable for any direct or indirect damages, losses, or claims arising including but not limited to lost profits, data loss, or business interruption from any data breaches or unauthorized access to listing information, including personal data, financial information, or any other data stored on our platform. Data breaches can occur due to various factors, including external cyber attacks, vulnerabilities in third-party systems, or listing agent  negligence. While we implement robust security measures to safeguard your data, we cannot guarantee that breaches will not occur. Listing agents are encouraged to take additional steps to protect their data, such as using strong, unique passwords, enabling two-factor authentication, and regularly reviewing and updating their account settings. In the event of a data breach, Ratewaves.com will make every effort to mitigate the impact, investigate the breach, and notify affected listing agents in accordance with applicable data protection laws and regulations.
                        </Typography>

                        </li>   
                       <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Ratewaves.com makes every reasonable effort to protect the security and confidentiality of listing data. However, Ratewaves.com shall not be held liable for any direct or indirect damages, losses, or claims arising including but not limited to lost profits, data loss, or business interruption from any data breaches or unauthorized access to listing information, including personal data, financial information, or any other data stored on our platform. Data breaches can occur due to various factors, including external cyber attacks, vulnerabilities in third-party systems, or listing agent  negligence. While we implement robust security measures to safeguard your data, we cannot guarantee that breaches will not occur. Listing agents are encouraged to take additional steps to protect their data, such as using strong, unique passwords, enabling two-factor authentication, and regularly reviewing and updating their account settings. In the event of a data breach, Ratewaves.com will make every effort to mitigate the impact, investigate the breach, and notify affected listing agents in accordance with applicable data protection laws and regulations.
                    </Typography>

                    </li>   
                    <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Ratewaves.com shall not be held liable for any direct or indirect damages, losses, or claims resulting during Planned Maintenance and Downtime, including but not limited to financial loss, data loss, or business interruption.
                    </Typography>

                    

                        </li>   

                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>No Guarantee of Perfection</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>While we are committed to providing a high-quality service, Ratewaves.com does not guarantee that our technical systems and platforms will be entirely free from technical issues or disruptions.
                        </Typography>

                        </li>      
                         <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Factors beyond our control, such as internet infrastructure, third-party service providers, and force majeure events, may contribute to technical issues.
                    </Typography>                   

                        </li>      
                        <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Ratewaves.com disclaims any warranty, whether expressed or implied, regarding the accuracy, completeness, or suitability of the information and services provided.
                    </Typography>                   

                        </li>       
                        <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Users acknowledge that Ratewaves.com makes no warranties or representations regarding the error-free nature of our services.
                    </Typography>                   

                        </li>          
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Technical Issue Reporting</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Customers are encouraged to report any technical issues, disruptions, or outages they encounter when using our services. Reporting should be made through our designated support channels.
                        </Typography>

                        </li>    
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves.com will make every reasonable effort to promptly acknowledge, investigate, and resolve reported technical issues.
                        </Typography>

                        </li> 
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>For technical issue reporting, questions, or concerns, please contact support@ratewaves.com 
                        </Typography>                   
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Contact Information</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>By using our platform to add property listings, you agree that you are a duly authorized real estate agent or property owner, and you have the legal authority to represent the property being listed.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

          </Box> 
      </Box> </Box>     }

            {signInPopUp &&  <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '400px', sm: '450px', xs: '95%' },
           height: { md:'630px', sm: '90%', xs: '95%' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
          borderRadius: '8px 8px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '25px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflow:'auto',
          
         }}
       >
         <IconButton  onClick={closeSignInPopup} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>
            <div style={{ display: 'flex', flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center'}}>
                <Box sx={{height:'40px',width:'40px',borderRadius:'40px',border:'1px solid #504FA4',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                    <PersonIcon sx={{color:'#504FA4',height:'40px',width:'40px'}}/>
                </Box>
                <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins'}}>Sign in</Typography>
             </div>
                <Box sx={{ display: 'flex', flexDirection: 'column',  gap: '16px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>Email</Typography>
                        <TextField
                            value={signInDetails.signInEmailId}
                            onChange={handleInputsignInEmailChange}
                            type="text"
                            name="user_signInEmail"                           
                            error={showErrorsignInEmailId}
                            helperText={showErrorsignInEmailId ? 'Please enter your signInEmail' : ''}
                            InputProps={{
                                sx: {
                                    height: '36px',color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9', padding:'10px, 14px, 10px, 14px'
                                  },
                            }}
                        />
                    </Box>
                
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>Password </Typography>
                        <TextField
                            value={signInDetails.password}
                            onChange={handleInputPasswordChangeSignIn}
                            type={showPasswordSignIn ? 'text' : 'password'}
                            name="user_password"                          
                            error={showErrorSignInPassword}
                            helperText={showErrorSignInPassword ? 'Please enter your password' : ''}                      
                            InputProps={{
                                sx: {
                                    height: '36px',  color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
                                  },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibilitySignIn}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            color="primary"
                                        >
                                            {showPasswordSignIn ? <VisibilityOutlinedIcon sx={{color:'#313131'}} /> : <VisibilityOffOutlinedIcon sx={{color:'#313131'}} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    {errorMessage && (
        <div style={{ color: 'red', fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
        fontWeight: "400"}}>{errorMessage}</div>
      )}
                    <Box sx={{ display: 'flex', flexDirection: 'row',alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row',alignItems: 'center',width:{ md:'100%', sm:'100%', xs:'95%'} }}>
  <Checkbox
    checked={isCheckedSignIn}
    onChange={(event) => handleCheckboxChangeSignIn(event)}   
    size="small"
    sx={{
      color: '#313131', // Change the checked color here
      background:'none' ,
      width:'2px',
      height:'2px',
      '&.Mui-checked': {
        color: '#3A3990', // Change the checked color here as well
       background:'none' ,
       width:'2px',
       height:'2px'
      },
     
    }}
  />
  <Typography sx={{ fontSize: { md: '14px', sm: '14px', xs:  '12px'}, color: '#313131', fontFamily: 'poppins',
  paddingLeft:{ md: '10px', sm: '10px', xs:  '5px'} }}>
  Remember me
  </Typography>
  </Box>
  
  <Box sx={{ display: 'flex', flexDirection: 'row',alignItems: 'right',width:'100%',justifyContent:'right'}}>
  <Button variant="text" sx={{fontSize: { md:'14px', sm:'14px', xs:'12px'}, color: '#313131', fontFamily: 'poppins', 
  textTransform:'none',background:'none',fontWeight:400,
  '&:hover': {background:'none', }
                            }}onClick={handleForgotPassword}>Forgot password ?</Button>
 </Box>              
</Box>
{/* <Box sx={{ display: 'flex', flexDirection: 'row',alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row',alignItems: 'center',width:{ md:'100%', sm:'100%', xs:'95%'} }}>
  <Checkbox
    checked={istwostepverification}
    onChange={(event) => handleCheckboxChangetwostep(event)}   
    size="small"
    sx={{
      color: '#313131', // Change the checked color here
      background:'none' ,
      width:'2px',
      height:'2px',
      '&.Mui-checked': {
        color: '#3A3990', // Change the checked color here as well
       background:'none' ,
       width:'2px',
       height:'2px'
      },
     
    }}
  />
   <Typography sx={{ fontSize: { md: '14px', sm: '14px', xs:  '12px'}, color: '#313131', fontFamily: 'poppins',
  paddingLeft:{ md: '10px', sm: '10px', xs:  '5px'} }}>
  2-Step Verification
  </Typography> </Box>
  {istwostepverification && ( <Box sx={{ display: 'flex', flexDirection: 'row',alignItems: 'right',width:'60%',justifyContent:'right'}}>
  <Button variant="text" sx={{fontSize: { md:'14px', sm:'14px', xs:'12px'}, color: '#313131', fontFamily: 'poppins', 
  textTransform:'none',background:'none',fontWeight:400,textDecoration:'underline', textUnderlineOffset:'5px',
  '&:hover': {background:'none',textDecoration:'underline', textUnderlineOffset:'5px', }
                            }}onClick={handleSendOTP}>{otpSent ? 'Resend Code' : 'Send Code'}</Button>
 </Box>   )}           
</Box>
{ otpSent && (
        <Box sx={{ display: 'flex', flexDirection: 'column',  }}>
        <Typography sx={{ fontSize: { md: '14px', sm: '14px', xs:  '12px'}, color: '#313131', fontFamily: 'poppins',
   }}>Code send to your registered mail</Typography>
  </Box>

)}
  { otpSent && (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px',marginBottom:'20px' }}>
        <Typography sx={{ fontSize: { md: '14px', sm: '14px', xs:  '12px'}, color: '#313131', fontFamily: 'poppins',
  }}>Please enter the verification code:</Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          {otpDigits.map((digit, index) => (
            <TextField
              key={index}
              value={otpDigits[index]}
              onChange={(event) => handleOtpDigitChange(index, event.target.value)}
              type="text"
              variant="outlined"
              inputProps={{
                maxLength: 1, // Allow only one character input
                style: {
                  width: '15px',
                  height: '15px',
                  textAlign: 'center',
                },
              }}
            />
          ))}
        </Box>
      </Box>
      )}
   { otpError && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px' }}>
        <Typography sx={{ fontSize: { md: '14px', sm: '14px', xs:  '12px'}, color: 'red', fontFamily: 'poppins',
  paddingLeft:{ md: '10px', sm: '10px', xs:  '5px'} }}>Incorrect Code entered. Please try again.</Typography>
  </Box>
  )} */}
<Box sx={{ display: 'flex', flexDirection: 'row',alignItems: 'left',width:'100%',justifyContent:'left'}}>

  <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group" sx={{ fontSize: '14px', color: '#313131 !important', fontFamily: 'poppins',paddingBottom:'10px', fontWeight:500 }}>Sign in as a builder or admin </FormLabel>
      <RadioGroup sx={{ display: 'flex', flexDirection: 'row',alignItems: 'left',width:'100%',justifyContent:'left'}}  size="small" 
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={selectedRole}
        onChange={handleRoleChange}
        
      >
        <FormControlLabel  value="builder" control={<Radio size="small"  style={{ color: '#3A3990' }} />}
         label={<span style={{fontSize: '14px', color: '#313131', fontFamily: 'poppins'  }}>Listing agent</span>} />
        <FormControlLabel  value="admin" control={<Radio size="small"  style={{ color: '#3A3990' }} />} 
          label={<span style={{fontSize: '14px', color: '#313131', fontFamily: 'poppins'  }}>Admin</span>}  />
      </RadioGroup>
    </FormControl>
  </Box>

 {/* {isBuilder && <Box  sx={{ display: 'flex', flexDirection: 'column',alignItems: 'left',width:'100%',justifyContent:'left',gap:'15px'}} >
  <Typography sx={{ fontSize: { md: '14px', sm: '14px', xs:  '12px'}, color: '#313131', fontFamily: 'poppins', fontWeight:500}}>
  Choose your builder
  </Typography>
  <Select
  sx={{
    height: '36px',
    color: "#313131",
    fontFamily: "Poppins",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "400",
    borderRadius: '8px',
    padding: '10px 14px', // Note the corrected padding value
  }}
  value={selectedBuilder}
  onChange={handleBuilderChange}
>
 <MenuItem
    sx={{
      backgroundColor: 'white !important',
      marginLeft: '10px',
      marginRight: '10px',
      fontFamily: "Poppins",
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      '&:hover': {
        borderRadius: '8px',
        marginLeft: '10px',
        marginRight: '10px',
        backgroundColor: '#0C276C !important',
        color: 'white',
      }}
    }
    value="My Profile" // Set the value for "My Profile"
  >
    My Profile
  </MenuItem> 
  {builders.map((builder, index) => (
    <MenuItem
    key={index} // Use listing_agent_id as the key
      sx={{
        backgroundColor: 'white !important',
        marginLeft: '10px',
        marginRight: '10px',
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "400",
        '&:hover': {
          borderRadius: '8px',
          marginLeft: '10px',
          marginRight: '10px',
          backgroundColor: '#0C276C !important',
          color: 'white',
        }}
      }
      value={builder.builder_name} // Set the builder_name as the value
    
    >
      {builder.builder_name}
    </MenuItem>
  ))}
</Select>

       
  </Box>} */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', paddingTop: '10px' }}>
                        <Button variant="contained" size="large" sx={{
                            width: '100%', height: '36px', color: 'white',borderRadius:'8px',
                            backgroundColor: '#3A3990', textTransform:'none',
                            '&:hover': { bgcolor: "#3A3990", color: '#fff',border: "1px solid #3A3990" }
                        }}
                            onClick={handleSignInButtonClick}>Sign in</Button>

                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',paddingTop:'10px' }}>
                            <Typography sx={{ fontSize: '14px', color: '#313131', fontFamily: 'poppins' }}>If you don’t have already an account</Typography>
                            <Button variant="contained" size="large" sx={{
                            width: '100%', height: '36px', color: '#3A3990',borderRadius:'8px',border:'1px solid #3A3990 ',
                            backgroundColor:"#fff" , textTransform:'none', '&:hover': { bgcolor:'#CFDAF4', color: '#3A3990',border:'1px solid #3A3990',boxShadow:'none' }
                        }}
                            onClick={handleCreateAccountButtonClick}>Create an account</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            </Box>
            </Box>}

            {verificationPopUp &&  <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '400px', sm: '450px', xs: '95%' },
           height: { md:'630px', sm: '90%', xs: '95%' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
          borderRadius: '8px 8px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '25px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflow:'auto',
          
         }}
       >
         <IconButton  onClick={closeVerificationPopup} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>
            <div style={{ display: 'flex', flexDirection:'column',alignItems:'left',justifyContent:'left',alignContent:'left',paddingTop:'20px'}}>
              
                <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins'}}>Verification</Typography>
             </div>
                <Box sx={{ display: 'flex', flexDirection: 'column',  gap: '16px' }}>
                
     
<Box sx={{ display: 'flex', flexDirection: 'column',alignItems: 'center',width: '100%',gap:'20px' }}>
<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', }}>
        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs:  '12px'}, color: '#626262', fontFamily: 'poppins',
   }}>We sent you a 6-digit code to verify your mobile number.</Typography>
  </Box> 
  <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '10px',marginBottom:'20px' ,width: '100%',}}>
        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs:  '12px'}, color: '#313131', fontFamily: 'poppins',
  }}>Enter your OTP here</Typography>
  </Box>   
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px',width: '100%',flexDirection: 'row', }}>
        {inputRefs.current.map((inputField, index) => (
        <TextField
          key={index}
          value={inputField.value}
          onChange={(event) => handleOtpDigitChange(index, event)}
          type="text"
          variant="standard"
          inputRef={inputField.ref}
          inputProps={{
            maxLength: 1,
            style: {
              width: '15px',
              height: '15px',
              textAlign: 'center',
              borderBottom: '1px solid #313131',
              width: '48px',
            },
          }}
          onKeyDown={(event) => {
            if (event.key === 'Backspace' && index > 0) {
              inputRefs.current[index - 1].ref.current.focus();
            }
            else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
              handleArrowKeyPress(index, event);
            }
          }}
        />
      ))}
        </Box>
               
 <Box sx={{ display: 'flex', flexDirection: 'row',alignItems: 'right',width:'100%',justifyContent:'right'}}>
  <Button variant="text" sx={{fontSize: { md:'14px', sm:'14px', xs:'12px'}, color: '#313131', fontFamily: 'poppins', 
  textTransform:'none',background:'none',fontWeight:400,textDecoration:'underline', textUnderlineOffset:'5px',
  '&:hover': {background:'none',textDecoration:'underline', textUnderlineOffset:'5px', }
                            }} onClick={handleSendOTP}> Resend Code</Button>
 </Box>        
</Box>





     
    
   { otpError !=='' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px' }}>
        <Typography sx={{ fontSize: { md: '14px', sm: '14px', xs:  '12px'}, color: 'red', fontFamily: 'poppins',
  paddingLeft:{ md: '10px', sm: '10px', xs:  '5px'} }}>{otpError}</Typography>
  </Box>
  )}


 
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px',position: 'absolute',
    bottom: '50px',  width:  { md: '350px', sm: '400px', xs: '90%' }, }}>
                        <Button disabled = { otpError !==''} variant="contained" size="large" sx={{
                            width: '100%', height: '36px', color: 'white',borderRadius:'8px',
                            backgroundColor: '#3A3990', textTransform:'none',
                            '&:hover': { bgcolor: "#3A3990", color: '#fff',border: "1px solid #3A3990" }
                        }}
                            onClick={handleOTPButtonClick}>Submit</Button>

                      
                       
                    </Box>
                </Box>
            </Box>
            </Box>
            </Box>}

            { thankYouPopUp &&  <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '400px', sm: '400px', xs: '300px' },
           height: { md:'300px', sm: '320px', xs: '300px' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
          borderRadius: '8px 8px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '25px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflow:'auto',
          
         }}
       >
         {/* <IconButton  onClick={closeResetPopUp} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton> */}
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>
          
            
            <div style={{ display: 'flex', flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center',paddingTop:'15px'}}>
            <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins',textAlign:'center',
            paddingTop:'25px'}}>Thank you!</Typography>
                {/* <Typography sx={{fontSize:'14px',fontWeight:400,color:'black',fontFamily:'Poppins',textAlign:'center',paddingTop:'35px'}}>
               
                Thanks for creating a profile with Ratewaves. Your request is under review by the admin team,  access will be enabled once they approve your request. Eventually, you will be notified once access is ready.  </Typography> */}
             <Typography sx={{fontSize:'14px',fontWeight:400,color:'black',fontFamily:'Poppins',textAlign:'center',paddingTop:'35px'}}>
               
             Thanks for creating a profile with Ratewaves. Please check your email for a verification </Typography>
            
             </div>
                <Box sx={{ display: 'flex', flexDirection: 'column',  gap: '16px', paddingTop:'20px' }}>
            
                
                  

      
                  
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', paddingTop: '10px' }}>
                        

                       
                           
                    <Button variant="contained" size="large" sx={{
                            width: '100%', height: '36px', color: 'white',borderRadius:'8px',
                            backgroundColor: '#3A3990', textTransform:'none',
                            '&:hover': { bgcolor: "#3A3990", color: '#fff',border: "1px solid #3A3990" }
                        }}
                        onClick={handleCloseThankYou} >Close</Button>
                      
                    </Box>
                </Box>
            </Box>
            </Box>
            </Box>}

            { resetPopUp &&  <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '400px', sm: '400px', xs: '300px' },
           height: { md:'500px', sm: '470px', xs: '450px' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
          borderRadius: '8px 8px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '25px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflow:'auto',
          
         }}
       >
         <IconButton  onClick={closeResetPopUp} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>
          
            <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins',textAlign:'center'}}>Reset password</Typography>
            <div style={{ display: 'flex', flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center',paddingTop:'15px'}}>
                <Box sx={{height:'40px',width:'40px',borderRadius:'40px',border:'1px solid #504FA4',alignItems:'center',
                alignContent:'center',justifyContent:'center',}}>
                   
                    <PersonIcon sx={{color:'#504FA4',height:'40px',width:'40px'}}/>
                </Box>
                <Typography sx={{fontSize:'14px',fontWeight:400,color:'black',fontFamily:'Poppins',textAlign:'center',paddingTop:'25px'}}>Enter registered email below. We will email you instructions to reset your password. </Typography>
             </div>
                <Box sx={{ display: 'flex', flexDirection: 'column',  gap: '16px', paddingTop:'20px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>Email</Typography>
                        <TextField
                            value={restDetail.restEmail}
                            onChange={handleResetLinkEmai}
                            type="text"
                            name="user_resetEmail"                           
                            error={showErrorResetEmail}
                            helperText={showErrorResetEmail ? 'Please enter your resetEmail' : ''}
                            InputProps={{
                                sx: {
                                    height: '36px',color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9', padding:'10px, 14px, 10px, 14px'
                                  },
                            }}
                        />
                    </Box>
                
                  

      
                  
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', paddingTop: '10px' }}>
                        <Button variant="contained" size="large" sx={{
                            width: '100%', height: '36px', color: 'white',borderRadius:'8px',
                            backgroundColor: '#3A3990', textTransform:'none', 
                            '&:hover': { bgcolor: "#3A3990", color: '#fff',border: "1px solid #3A3990" }
                        }}
                            onClick={handleResetLinkClick}>Send reset link</Button>

                       
                           
                            <Button variant="contained" size="large" sx={{
                            width: '100%', height: '36px', color: '#3A3990',borderRadius:'8px',border:'1px solid #3A3990',
                            backgroundColor: '#FFF', textTransform:'none', 
                            '&:hover': { bgcolor:'#CFDAF4', color: '#3A3990',border:'1px solid 65C898',boxShadow:'none' }
                        }}
                        onClick={handleReturntosignin} >Return to sign in</Button>
                      
                    </Box>
                </Box>
            </Box>
            </Box>
            </Box>}

            { resetConfirmPopUp &&  <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '400px', sm: '400px', xs: '300px' },
           height: { md:'350px', sm: '320px', xs: '300px' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
          borderRadius: '8px 8px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '25px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflow:'auto',
          
         }}
       >
         <IconButton  onClick={closeResetPopUp} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>
          
            
            <div style={{ display: 'flex', flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center',paddingTop:'15px'}}>
                {/* <Box sx={{height:'40px',width:'40px',borderRadius:'40px',border:'1px solid #504FA4',alignItems:'center',
                alignContent:'center',justifyContent:'center',}}> */}
                   
                    <CheckCircleIcon sx={{color:'#0BEA14',height:'40px',width:'40px'}}/>
                {/* </Box> */}
                <Typography sx={{fontSize:'14px',fontWeight:400,color:'black',fontFamily:'Poppins',textAlign:'center',paddingTop:'35px'}}>
                Account reset link has been sent to your registered email. please click on the link to reset the password </Typography>
             </div>
                <Box sx={{ display: 'flex', flexDirection: 'column',  gap: '16px', paddingTop:'20px' }}>
            
                
                  

      
                  
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', paddingTop: '10px' }}>
                        

                       
                           
                            <Button variant="contained" size="large" sx={{
                            width: '100%', height: '36px', color: '#fff',borderRadius:'8px',border:'1px solid #3A3990',
                            backgroundColor: '#3A3990', textTransform:'none', 
                             '&:hover': { bgcolor: "#3A3990", color: '#fff',border: "1px solid #3A3990",boxShadow:'none' }
                        }}
                        onClick={handleReturntosignin} >Return to sign in</Button>
                      
                    </Box>
                </Box>
            </Box>
            </Box>
            </Box>}

            { changePasswordPopUp &&  <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '400px', sm: '400px', xs: '300px' },
           height: { md:'400px', sm: '400px', xs: '360px' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
          borderRadius: '8px 8px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '25px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflow:'auto',
          
         }}
       >
         <IconButton  onClick={closeChangePasswordPopUp} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>
          
            <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins',textAlign:'center',
            paddingTop:'25px'}}>Create new password</Typography>
           
                <Box sx={{ display: 'flex', flexDirection: 'column',  gap: '16px', paddingTop:'20px' }}>
                {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>Old password </Typography>
                        <TextField
                            value={passwordChangeDetail.oldPassword}
                            onChange={handleOldPassword}
                            type={showOldPassword ? 'text' : 'password'}
                            name="user_password"                          
                            error={showErrorOldPassword}
                            helperText={showErrorOldPassword ? 'Please enter your old password' : ''}                      
                            InputProps={{
                                sx: {
                                    height: '36px',  color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
                                  },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibilityOldPassword}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            color="primary"
                                        >
                                            {showOldPassword ? <VisibilityOutlinedIcon sx={{color:'#313131'}} /> : <VisibilityOffOutlinedIcon sx={{color:'#313131'}} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box> */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>
                        Create new Password </Typography>
                        <TextField
                            value={passwordChangeDetail.newPassword}
                            onChange={handleNewPassword}
                            type={showNewPassword ? 'text' : 'password'}
                            name="user_password"                          
                            error={showErrorNewPassword}
                            helperText={showErrorNewPassword ? 'Please enter your new password' : ''}                    
                            InputProps={{
                                sx: {
                                    height: '36px',  color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
                                  },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibilityNewPassword}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            color="primary"
                                        >
                                            {showNewPassword ? <VisibilityOutlinedIcon sx={{color:'#313131'}} /> : <VisibilityOffOutlinedIcon sx={{color:'#313131'}} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>Confirm password </Typography>
                        <TextField
                            value={passwordChangeDetail.confirmPassword}
                            onChange={handleConfirmPassword}
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="user_password"                          
                            error={showErrorConfirmPassword}
                            helperText={showErrorConfirmPassword ? 'Please enter your old password' : ''}                  
                            InputProps={{
                                sx: {
                                    height: '36px',  color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
                                  },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibilityConfirmPassword}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            color="primary"
                                        >
                                            {showConfirmPassword ? <VisibilityOutlinedIcon sx={{color:'#313131'}} /> : <VisibilityOffOutlinedIcon sx={{color:'#313131'}} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
{ showErrorOldPassword &&  <div style={{ color: 'red', fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
        fontWeight: "400"}}>New password & Confirm Password do not match.</div> }
      
                  
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', paddingTop: '10px' }}>
                        <Button variant="contained" size="large" sx={{
                            width: '100%', height: '36px', color: 'white',borderRadius:'8px',
                            backgroundColor: '#3A3990', textTransform:'none', 
                            '&:hover': { bgcolor: "#3A3990", color: '#fff',border: "1px solid #3A3990" }
                        }}
                            onClick={handlePasswordChangeClick}>Update password</Button>

                       
                          
                      
                    </Box>
                </Box>
            </Box>
            </Box>
            </Box>}
            <style>
        {`
          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 1px solid #3A3990 !important;
            box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
          }
        `}
      </style>
        </Grid>
    )
}
export default BuilderSignUp;