//import React, { useLayoutEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AppBar from "@mui/material/AppBar";
import { Button, Grid, Typography } from "@mui/material";
import logo from './images/logo.png'
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ImgFrame from './images/HomeBlue.png';
import dataicon from './images/icon-park-outline_database-forbid.svg';
import HelpIcon from '@mui/icons-material/Help';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
// import Link from "@mui/material/Link";
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import DivComponents from './DivComponent';
import Payment from './PaymentComponent';
import Penalty from './PenaltyComponent';
import CheckboxPopUp from './CheckboxPopUp';
import GetAppIcon from '@mui/icons-material/GetApp';
import SignIn from './SignInSignUp'
import axios from 'axios';
import '../App.css';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PenaltyComponent from './PenaltyComponent';
import PaymentComponent from './PaymentComponent';
import { Link } from 'react-router-dom';

const dbLink = process.env.REACT_APP_API_URL;

function MortgageAgent() {
  // const menus = [
  //   "Calculator",
  //   "Quotations",
  //   "Products",
  //   "About us",
  //   "Contact us"
  // ];
  const [currentPage, setCurrentPage] = useState('Universal Calcualtor');
  const menus = [
    { label: "Universal Calcualtor", link: "/" },
    { label: "Canada Mortgage", link: "/canada-mortgage " },
    { label: "About us", link: "/about-us" },
    { label: "Contact us", link: "/contact-us" }
  ];

  const linkstyles = {
    link1: {
      textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "16px", fontWeight: "400",
      color: " #313131", zIndex: 1
    },
    link2: {
      marginLeft: "5%", textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "16px", fontWeight: "400",
      color: " #313131"

    },
    activeLink: {
      textDecoration: 'underline', fontWeight: "500"
    },
  };
  const [activeLink, setActiveLink] = useState('Calculate Payment');

  const handleLinkClick = (link) => {
    setActiveLink(link);

  };
  const [calcContent, setcalcContent] = useState("Payment Content");
  const handlePaymentCalc = () => {
    setcalcContent("Payment Content");
    handleLinkClick('Calculate Payment');
  };
  const handlePenaltyCalc = () => {
    setcalcContent("Penalty Content");
    handleLinkClick('Calculate Penalty on cancellation');
  };





  const [isSelected, setIsSelected] = useState(false);
  const [askingPriceValue, setAskingPriceValue] = useState(10000);
  const [initialPaymentValue, setInitialPaymentValue] = useState(0);
  const [mortgageamountValue, setMortgageamountValue] = useState(10000);
  const [termValue, setTermValue] = useState(5);
  const [rateValue, setRateValue] = useState(3);
  const [periodValue, setPeriodValue] = useState(25);
  const [frequencyValue, setFrequencyValue] = useState('M');
  const [insurance, setInsurance] = useState(0);
  const [sheduledPayment, setSheduledPayment] = useState(47.32);
  const [resultTotal, setResultTotal] = useState({ TotalAmountPaid: 1431.60, TotalInterestPaid: 1407.60, TotalPrinciplePaid: 2839.20 });

  const [dataList, setDataList] = useState([
    {
      id: 0,
      name: "Option #1",
      askingprice: askingPriceValue,
      initialPayment: initialPaymentValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      isSelected: false,
      SheduledResult: sheduledPayment,
      lowerResults: resultTotal
    },
    {
      id: 1,
      name: "Option #2",
      askingprice: askingPriceValue,
      initialPayment: initialPaymentValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      isSelected: false,
      SheduledResult: sheduledPayment,
      lowerResults: resultTotal
    },
    {
      id: 2,
      name: "Option #3",
      askingprice: askingPriceValue,
      initialPayment: initialPaymentValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      isSelected: false,
      SheduledResult: sheduledPayment,
      lowerResults: resultTotal
    },
    {
      id: 3,
      name: "Option #4",
      askingprice: askingPriceValue,
      initialPayment: initialPaymentValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      isSelected: false,
      SheduledResult: sheduledPayment,
      lowerResults: resultTotal
    },
  ])


  const [numberOfAddClick, setnumberOfAddClick] = useState(4);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(4);

  const handleAddSlide = async () => {
    console.log("datalength", dataList.length)
    setisDeleteButtonEnabled(true);
    if (dataList.length !== 7) {
      const mrval = askingPriceValue - initialPaymentValue;
      const monthlyPayment = await getSheduledPayment(parseInt(askingPriceValue), (rateValue / 100), periodValue, frequencyValue, mrval);
      const data = await getSecondResult(parseInt(askingPriceValue), (rateValue / 100), periodValue, frequencyValue, monthlyPayment, termValue, mrval, monthlyPayment)
      const newData = {
        id: dataList.length, name: `Option #${numberOfAddClick + 1}`, askingprice: askingPriceValue, isSelected: false,
        initialPayment: initialPaymentValue, mortgageamount: mrval, term: termValue, rate: rateValue, period: periodValue,
        frequency: frequencyValue, SheduledResult:monthlyPayment.toFixed(2), lowerResults: {
          TotalAmountPaid: data.TotalPrinciple,
          TotalInterestPaid: data.TotalInterest,
          TotalPrinciplePaid: data.TotalPayment
        }
      }

      // isDeleteConfirm===true?setCurrentSlideIndex(currentSlideIndex+2):setCurrentSlideIndex(currentSlideIndex+1);

      setnumberOfAddClick(numberOfAddClick + 1);

      // setIsDeleteConfirm(false);
      setDataList([...dataList, newData]);
    }
    setCurrentSlideIndex(currentSlideIndex + 1);
  };




  const handlePrevSlide = () => {

    if (currentSlideIndex > 4)
      setCurrentSlideIndex(currentSlideIndex - 1)

  };

  const handleNextSlide = () => {
    if (currentSlideIndex < 7)
      setCurrentSlideIndex(currentSlideIndex + 1)

  }
  const [isDeleteButtonEnabled, setisDeleteButtonEnabled] = useState(false);
  const isdisableAddSlidebutton = (dataList[dataList.length]?.name) === (dataList[currentSlideIndex]?.name)
  const [CheckboxEnabled, setCheckboxEnabled] = useState(false);
  const [thebidding, setthebidding] = useState("bidding disable");
  const handleAddBiddingCheckbox = () => {
    setCheckboxEnabled(true);
    setthebidding("bidding enable");
    setisDeleteButtonEnabled(false);
  };
  const [showPopupforCheckbox, setShowPopupforCheckbox] = useState(false);
  const [showPopupforSummary, setShowPopupforSummary] = useState(false);
  const [showResultSummary, setShowResultSummary] = useState([]);
  const handleNextClick = () => {
    setShowPopupforCheckbox(true);
  };
  const [selectedCheckboxList, setCheckboxSelectedList] = useState([]);

  // const [isCheckedCheckboxNext, setisCheckedCheckboxNext] = useState([]);
  var isNextButtonEnabled = selectedCheckboxList.length > 0;
  const handleNextCheckboxChange = (isChecked, index) => {

    // setisNextButtonEnabled(isChecked);    

    //setisCheckedCheckboxNext(isChecked);
    //[...isChecked]=[temp,setIsChecked];

    //  if (isChecked) {
    //   setisCheckedCheckboxNext((prevChecked) => [...prevChecked, index]);
    // } else {
    //   setisCheckedCheckboxNext((prevChecked) =>
    //     prevChecked.filter((item) => item !== index)
    //   );
    // }




  };
  const IsChevorButton = (CheckboxEnabled && dataList.length > 4) || isDeleteButtonEnabled;





  const [isHoveredAddMortgage, setIsHoveredAddMortgage] = useState(false);

  const handleMouseEnterAddMortgage = () => {
    setIsHoveredAddMortgage(true);
  };

  const handleMouseLeaveAddMortgage = () => {
    setIsHoveredAddMortgage(false);
  };

  const [isHoveredTheBidding, setIsHoveredTheBidding] = useState(false);

  const handleMouseEnterTheBidding = () => {
    setIsHoveredTheBidding(true);
  };

  const handleMouseLeaveTheBidding = () => {
    setIsHoveredTheBidding(false);
  };
  const [isHoveredSendToMail, setIsHoveredSendToMail] = useState(false);

  const handleMouseEnterSendToMail = () => {
    setIsHoveredSendToMail(true);
  };

  const handleMouseLeaveSendToMail = () => {
    setIsHoveredSendToMail(false);
  };

  const [isHoveredDownloadResult, setIsHoveredDownloadResult] = useState(false);

  const handleMouseEnterDownloadResult = () => {
    setIsHoveredDownloadResult(true);
  };

  const handleMouseLeaveDownloadResult = () => {
    setIsHoveredDownloadResult(false);
  };
  const [isHoveredNext, setIsHoveredNext] = useState(false);

  const handleMouseEnterNext = () => {
    setIsHoveredNext(true);
  };

  const handleMouseLeaveNext = () => {
    setIsHoveredNext(false);
  };

  //     const handleSummary =async () => {
  //     const AskingPriceValue=dataList.askingprice;
  //     const rate = (dataList.rate)/100;

  //    const Amortization = dataList.period;
  //    const Frequency = dataList.frequency;
  //    const Term = dataList.term;
  //    const MortgageAmnt =  dataList.askingprice - dataList.initialPayment;
  //    const monthlyPayment= dataList.SheduledResult;
  //    const SheduledPayment= dataList.SheduledResult;
  //     const response2 = await axios.post('http://localhost:5000/calculate2', { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt,SheduledPayment });
  //     console.log("second result", response2);
  //     setDataList(prevDataList => {
  //       const newDataList = [...prevDataList];
  //       newDataList.lowerResults = {
  //         TotalAmountPaid: response2.data.TotalPrinciple,
  //       TotalInterestPaid: response2.data.TotalInterest,
  //       TotalPrinciplePaid: response2.data.TotalPayment,

  //       };
  //       return newDataList;
  //     });
  // setShowResultSummary(response2.data.scheduleData)
  //     setShowPopupforSummary(true);
  //   };

  const handleSummary = async (mname) => {
    const index = dataList.findIndex(x => x.name === mname);
    const askingValue = dataList[index].askingprice;
    const initialValue = dataList[index].initialPayment;
    const rate = (dataList[index].rate) / 100;
    const amortValue = dataList[index].period;
    const freqValue = dataList[index].frequency;
    const termsValue = dataList[index].term;
    const shedule = dataList[index].sheduledPayment;
    const mrval = dataList[index].askingprice - dataList[index].initialPayment;
    const monthlyPayment = await getSheduledPayment(askingValue, rate, amortValue, freqValue, mrval);
    const data = await getSecondResult(askingValue, rate, amortValue, freqValue, parseFloat(monthlyPayment), termsValue, mrval, parseFloat(shedule))
    setShowResultSummary(data.scheduleData);

    setShowPopupforSummary(true);
  };


  const handleCancel = () => {
    setShowPopupforSummary(false);


  };
  // const [size, setSize] = useState([0, 0]);

  // function ViewPort() {
  //   var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  //   var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  //   var viewsize = w + "," + h;
  //   alert("Your View Port Size is:" + viewsize);
  // }
  // useLayoutEffect(() => {
  //   function updateSize() {
  //     setSize([window.outerWidth, window.outerWidth]);
  //     var x = window.matchMedia("(max-width: 700px)")
  //     console.log("x", x)
  //   }
  //   window.addEventListener('resize', updateSize);
  //   updateSize();
  //   return () => window.removeEventListener('resize', updateSize);
  // }, []);

  // console.log("size", size);

  const [isActiveAskingPrice, setIsActiveAskingPrice] = useState(false);

  const handleInputClickAskingPrice = () => {
    setIsActiveAskingPrice(true);
  };

  const handleInputBlurAskingPrice = () => {
    setIsActiveAskingPrice(false);
  };
  const [isActiveLocation, setIsActiveLocation] = useState(false);

  const handleInputClickLocation = () => {
    setIsActiveLocation(true);
  };

  const handleInputBlurLocation = () => {
    setIsActiveLocation(false);
  };
  const [showSignIn, setShowSignIn] = useState(false);

  const handleClickSignIn = () => {
    setShowSignIn(!showSignIn);
  };
  const [showTermText, setShowTermText] = useState(false);
  const handleTermClick = () => {
    setShowTermText(!showTermText)
    setTimeout(() => {
      setShowTermText(false);
    }, 5000);
    setShowAnnualInterestRateText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showAnnualInterestRateText, setShowAnnualInterestRateText] = useState(false);
  const handleAnnualInterestRateClick = () => {
    setShowAnnualInterestRateText(!showAnnualInterestRateText);
    setTimeout(() => {
      setShowAnnualInterestRateText(false);
    }, 5000); 
   
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showAmortizationText, setShowAmortizationText] = useState(false);
  const handleAmortizationTextClick = () => {
    setShowAmortizationText(!showAmortizationText);
    setTimeout(() => {
      setShowAmortizationText(false);
    }, 5000); 
    setShowAnnualInterestRateText(false);
    setShowTermText(false);   
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showPaymentfreequencyText, setShowPaymentfreequencyText] = useState(false);
  const handlePaymentfreequencyTextClick = () => {
    setShowPaymentfreequencyText(!showPaymentfreequencyText);
    setTimeout(() => {
      setShowPaymentfreequencyText(false);
    }, 8000); 
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);   
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showPaymentSheduleText1, setShowPaymentSheduleText1] = useState(false);
  const handlePaymentSheduleTextClick1 = () => {
    setShowPaymentSheduleText1(!showPaymentSheduleText1);
    setTimeout(() => {
      setShowPaymentSheduleText1(false);
    }, 5000); 
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);  
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showPaymentSheduleText2, setShowPaymentSheduleText2] = useState(false);
  const handlePaymentSheduleTextClick2 = () => {
    setShowPaymentSheduleText2(!showPaymentSheduleText2);
    setTimeout(() => {
      setShowPaymentSheduleText2(false);
    }, 5000); 
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);   
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showAskingPriceText, setShowAskingPriceText] = useState(false);
  const handleAskingPriceTextClick = () => {
    setShowAskingPriceText(!showAskingPriceText);
    setTimeout(() => {
      setShowAskingPriceText(false);
    }, 5000); 
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);   
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showInitialpaymentText, setShowInitialpaymentText] = useState(false);
  const handleInitialPaymentTextClick = () => {
    setShowInitialpaymentText(!showInitialpaymentText);
    setTimeout(() => {
      setShowInitialpaymentText(false);
    }, 5000); 
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false); 
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showTotalPrinciplePaidText, setShowTotalPrinciplePaidText] = useState(false);
  const handleTotalPrinciplePaidTextClick = () => {
    setShowTotalPrinciplePaidText(!showTotalPrinciplePaidText);
    setTimeout(() => {
      setShowTotalPrinciplePaidText(false);
    }, 5000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showTotalInterestPaidText, setShowTotalInterestPaidText] = useState(false);
  const handleTotalInterestPaidTextClick = () => {
    setShowTotalInterestPaidText(!showTotalInterestPaidText);
    setTimeout(() => {
      setShowTotalInterestPaidText(false);
    }, 6000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showTotalAmountPaidText, setShowTotalAmountPaidText] = useState(false);
  const handleTotalAmountPaidTextClick = () => {
    setShowTotalAmountPaidText(!showTotalAmountPaidText);
    setTimeout(() => {
      setShowTotalAmountPaidText(false);
    }, 8000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showMortgageAmountText, setShowMortgageAmountText] = useState(false);
  const handleMortgageAmountTextClick = () => {
    setShowMortgageAmountText(!showMortgageAmountText)
    setTimeout(() => {
      setShowMortgageAmountText(false);
    }, 3000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
  };

  const [askingPriceVariableParent, setAskingPriceVariableParent] = useState(false);
  const handleChangeAskingPriceValue = (event) => {
    const newValue = event.target.value;
    const nval = new Intl.NumberFormat().format(event.target.value);
    setAskingPriceValue(newValue);
    setAskingPriceVariableParent(true);
    var tempdataList = [...dataList]
    tempdataList = tempdataList.map(e => ({ ...e, askingprice: parseInt(newValue) }));
    setDataList(tempdataList);

  };
  ////////////////////////backend/////////////////////////
  //   const [resultAsking, setResultAsking] = useState([0, 0, 0, 0, 0, 0, 0]);
  //   const [result, setResult] = useState([47.32, 47.32, 47.32, 47.32, 47.32, 47.32, 47.32]);
  //   const [result2, setResult2] = useState([{ Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
  //   { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
  //   { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
  //   { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }]);
  //   const [askingprice, setAskingPrice] = useState([10000, 10000, 10000, 10000, 10000, 10000, 10000]);
  //   const [interestRate, setInterestRate] = useState([0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03]);
  //   const [amortznPeriod, setAmortznPeriod] = useState([25, 25, 25, 25, 25, 25, 25]);
  //   const [FreqParam, setFreqParam] = useState(['M', 'M', 'M', 'M', 'M', 'M']);
  //   const [TermVal, setTermVal] = useState([5, 5, 5, 5, 5, 5, 5]);
  //   const [InitialpaymentVal, setInitialpaymentVal] = useState([0, 0, 0, 0, 0, 0, 0]);
  //   const [mortgageamountVal, setmortgageamountVal] = useState([10000, 10000, 10000, 10000, 10000, 10000, 10000]);
  // useEffect(() => {    
  //   console.log('result from parent:', result);
  // }, [result]);

  const [mortgageAmountTextValue, setMortgageAmountTextValue] = useState([10000, 10000, 10000, 10000, 10000, 10000, 10000]);

  const [mortAmountValue, setMortAmountValue] = useState([10000, 10000, 10000, 10000, 10000, 10000, 10000]);

  const getSheduledPayment = async (AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt) => {
    const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });


    const monthlyPayment = response.data.MonthlyPayment;
    return monthlyPayment;
  }

  const getSecondResult = async (AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment) => {
    // const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

    const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment });

    return response2.data;
  }
  const handleChangeAskingprice = async (value, index) => {
    const AskingPriceValue = value;

    const rate = (dataList[index].rate) / 100;

    const Amortization = dataList[index].period;
    const Frequency = dataList[index].frequency;
    const Term = dataList[index].term;
    const MortgageAmnt = value - dataList[index].initialPayment;
    var tempdataList = [...dataList]
    tempdataList[index].mortgageamount = value - dataList[index].initialPayment;
    setDataList(tempdataList);
    console.log("asking price text value!!!!", new Intl.NumberFormat().format(value));
    try {


      const monthlyPayment = await getSheduledPayment(AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt)

      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
      });
      // Update the result array with the new values
      //   console.log("<<<>>>>>>",dataList)
      //       setResult((prevResult) => {
      //         const newArray = [...prevResult]; // Create a copy of the existing array
      //         newArray[index] = response.data.MonthlyPayment; // Modify the value at the specified index
      //         return newArray; // Set the updated array

      //       });


      const SheduledPayment = dataList[index].SheduledResult;

      const data = await getSecondResult(AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment)
      //  console.log("second result",response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: data.TotalPrinciple,
          TotalInterestPaid: data.TotalInterest,
          TotalPrinciplePaid: data.TotalPayment,

        };
        return newDataList;
      });

      // console.log("result2",dataList)
      //     const updatedResult2 = [...result2];
      //     updatedResult2[index].Totalinterest =response2.data.TotalInterest;
      //     updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
      //     updatedResult2[index].Totalpayment =response2.data.TotalPayment;
      //     setResult2(updatedResult2);

    } catch (error) {
      // Handle any error
    }
  };



  const handleChangeInterest = async (event, index) => {
    const value = event.target.value;
    let rate = (value) / 100;
    var tempdataList = [...dataList]
    tempdataList[index].rate = event.target.value;
    setDataList(tempdataList);
    // setInterestRate[index]=rate;
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const Frequency = dataList[index].frequency;
    const Initialpayment = dataList[index].initialPayment;
    const MortgageAmnt = dataList[index].mortgageamount;

    const Term = dataList[index].term;
    // console.log("interestRate,initialPayment,amortznPeriod",interestRate,askingprice,amortznPeriod,FreqParam);
    try {
      const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
      });
      //   const newArray = result.slice(); // Create a shallow copy of the array
      //   newArray[index] = monthlyPayment; // Modify the value at the specified index
      //   setResult(newArray);

      const SheduledPayment = dataList[index].SheduledResult;
      const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPrinciple,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPayment,

        };
        return newDataList;
      });
      setShowResultSummary(response2.data.scheduleData)

      //   const updatedResult2 = [...result2];
      //   updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      //   updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      //   updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      //   setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangePeriod = async (event, index) => {

    const Amortization = parseInt(event.target.value);
    var tempdataList = [...dataList]
    tempdataList[index].period = parseInt(event.target.value);
    setDataList(tempdataList);


    // setAmortznPeriod[index] = amortznPeriod;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate) / 100;
    const Frequency = dataList[index].frequency;
    const Term = dataList[index].term;
    const Initialpayment = dataList[index].initialPayment;
    const MortgageAmnt = dataList[index].mortgageamount;
    //  console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
      });
      //   const newArray = result.slice(); // Create a shallow copy of the array
      //   newArray[index] = monthlyPayment; // Modify the value at the specified index
      //   setResult(newArray);
      const SheduledPayment = dataList[index].SheduledResult;
      const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment });
      console.log("second result", response2);

      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPrinciple,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPayment,

        };
        return newDataList;
      });
      //   const updatedResult2 = [...result2];
      //   updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      //   updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      //   updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      //   setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangeFreequency = async (event, index) => {

    const Frequency = event.target.value;
    // setFreqParam[index]=FreqParam;
    var tempdataList = [...dataList]
    tempdataList[index].frequency = event.target.value;
    setDataList(tempdataList);


    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate) / 100;
    const Term = dataList[index].term;
    const Initialpayment = dataList[index].initialPayment;
    const MortgageAmnt = dataList[index].mortgageamount;
    //  console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
      });
      // const newArray = result.slice(); // Create a shallow copy of the array
      // newArray[index] = monthlyPayment; // Modify the value at the specified index
      // setResult(newArray);

      const SheduledPayment = dataList[index].SheduledResult;
      const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment });
      console.log("second result", response2);
      // const updatedResult2 = [...result2];

      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPrinciple,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPayment,

        };
        return newDataList;
      });
      // updatedResult2[index].Totalinterest =response2.data.TotalInterest;
      // updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
      // updatedResult2[index].Totalpayment =response2.data.TotalPayment;
      // setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangeTerm = async (event, index) => {

    const Term = parseInt(event.target.value);

    var tempdataList = [...dataList]
    tempdataList[index].term = parseInt(event.target.value);
    setDataList(tempdataList);

    // setTermVal[index]=parseInt(event.target.value);
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate) / 100;
    const Frequency = dataList[index].frequency;
    const Initialpayment = dataList[index].initialPayment;
    const MortgageAmnt = dataList[index].mortgageamount;
    // console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
      });
      // const newArray = result.slice(); // Create a shallow copy of the array
      // newArray[index] = monthlyPayment; // Modify the value at the specified index
      // setResult(newArray);

      const SheduledPayment = dataList[index].SheduledResult;
      const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment });
      console.log("second result", response2);
      // const updatedResult2 = [...result2];
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPrinciple,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPayment,

        };
        return newDataList;
      });
      // updatedResult2[index].Totalinterest =response2.data.TotalInterest;
      // updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
      // updatedResult2[index].Totalpayment =response2.data.TotalPayment;
      // setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangeInitialPayment = async (event, index) => {

    //console.log("handleChangeInitialPayment ",event.target.value,index,askingprice)

    const Initialpayment = parseInt(event.target.value);

    var tempdataList = [...dataList]
    tempdataList[index].initialPayment = parseInt(event.target.value);
    setDataList(tempdataList);

    // console.log("init......",Initialpayment)
    // setInitialpaymentVal[index]=Initialpayment;
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate) / 100;
    const Frequency = dataList[index].frequency;
    const Term = dataList[index].term;
    const MortgageAmnt = (dataList[index].askingprice) - (event.target.value);

    //  console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
      });
      // const newArray = result.slice(); // Create a shallow copy of the array
      // newArray[index] = monthlyPayment; // Modify the value at the specified index
      // setResult(newArray);
      const SheduledPayment = dataList[index].SheduledResult;
      const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment });
      console.log("second result", response2);
      // const updatedResult2 = [...result2];
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPrinciple,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPayment,

        };
        return newDataList;
      });
      // updatedResult2[index].Totalinterest =response2.data.TotalInterest;
      // updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
      // updatedResult2[index].Totalpayment =response2.data.TotalPayment;
      // setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangeMortgageAmount = async (index) => {
    const MortgageAmnt = parseInt(mortgageAmountTextValue);

    //setmortgageamountVal[index]=MortgageAmnt;

    const Initialpayment = dataList[index].initialPayment;
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate) / 100;
    const Frequency = dataList[index].frequency;
    const Term = dataList[index].term;

    //console.log("interestRate,initialPayment,amortznPeriod",interestRate,askingprice,amortznPeriod);
    try {
      const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment;
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
      });
      setMortAmountValue[index] = response.data.MonthlyPayment;
      // console.log("ghghghgh:index",monthlyPayment,index)
      // const newArray = result.slice(); // Create a shallow copy of the array
      //  newArray[index] = monthlyPayment; 
      //  // Modify the value at the specified index
      //  setResult(newArray);
      const SheduledPayment = dataList[index].SheduledResult;
      const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment });
      console.log("second result", response2);

      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPrinciple,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPayment,

        };
        return newDataList;
      });
      // const updatedResult2 = [...result2];
      // updatedResult2[index].Totalinterest =response2.data.TotalInterest;
      // updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
      // updatedResult2[index].Totalpayment =response2.data.TotalPayment;
      // setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };

  useEffect(() => {

    console.log('result has changed:', showResultSummary)
    console.log('datalist has changed:', dataList);
  }, [dataList, showResultSummary]);

  //console.log("result from parent",result)

  // useEffect(() => {
  //   myfunction(askingprice)
  // }, [askingprice]);

  // useEffect(() => { 
  // myTermFunction(TermVal)
  // }, [TermVal]);

  // useEffect(() => {
  // myFunctionFreq(FreqParam)
  // }, [FreqParam]);


  // const myfunction = (arrayp)=>{
  // console.log("array:",arrayp)

  // }
  // const myTermFunction = (arrayTerm)=>{
  //   console.log("array:",arrayTerm)

  //   }

  //   const myFunctionFreq = (arrayFreq)=>{
  //     console.log("array:",arrayFreq)

  //     }



  const handleMouseEnterPaymentButton = (index) => {
    var tempdataList = [...dataList]
    tempdataList[index].isSelected = true;
    setDataList(tempdataList);
  };

  const handleMouseLeavePaymentButton = (index) => {
    var tempdataList = [...dataList]
    tempdataList[index].isSelected = false;
    setDataList(tempdataList);
  };

  const triangleStyle = {
    position: 'absolute',
    top: '-10.5px',
    width: '10px',
    height: '20px',
    borderTop: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderBottom: '20px solid #0C276C',
    transform: 'rotate(135deg)',
    
  };



  return (
    <Grid container sx={{ width: '100%',height:'100%', margin: 0, }}>
      <Box sx={{ width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0, padding: 0 ,
     
    }}>

        <AppBar position="static" sx={{ bgcolor: "#0C276C", height: "72px", width: '100%' }}>
          <Box sx={{ display: "flex", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: "72px" }}>
            <Box sx={{ display: "flex", width: { md: '10%', sm: '12%' }, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <img src={logo} alt="" style={{ height: '85%', width: '90%', }} />
            </Box>
            <Box sx={{ display: "flex", width: '80%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left', paddingLeft: { md: "5%", sm: '3%' }, }}>
              {menus.map((menu) => (
                <Button
                  key={menu.label}
                  component={Link}
                  to={menu.link}
                  sx={{
                    color: menu.label === currentPage ? '#939393' : 'white', paddingRight: { md: "7%", sm: '3%' }, textTransform: "capitalize", fontFamily: "poppins",
                    fontSize: "14px", lineHeight: "21px", fontWeight: "400", '&:hover': { color: "#939393", bgcolor: '#0C276C' }
                  }}>
                  {menu.label}
                </Button>
              ))}
            </Box>
            <Box sx={{ display: "flex", width: { md: '10%', sm: '17%' }, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
              {/* <Button variant="contained" onClick={handleClickSignIn}
                sx={{
                  bgcolor: "#EB5648", color: "#fff", borderRadius: "16px", padding: " 4px, 10px, 4px, 10px", textTransform: "capitalize",
                  fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", width: "90%", height: "36px",
                  '&:hover': { bgcolor: "#fff", color: '#0C276C' }
                }} >
                Sign in
              </Button> */}
            </Box>

            {showSignIn && <SignIn />}

          </Box>
        </AppBar>

        <Box sx={{
          width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)' }, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row',
          marginLeft: { md: '16%', sm: '5%' }, marginRight: { md: '16%', sm: '5%' }, marginTop: { md: '1.5%', sm: '3%' }, gap: '2%'
        }}>

          <Box sx={{ width: { md: '15%', sm: '25%' }, alignItems: 'left', justifyContent: 'left', display: 'flex', flexDirection: 'row', }}>
            <img src={ImgFrame} alt="" style={{ width: '100%', height: '100%' }} />
          </Box>

          <Box sx={{ width: { md: '85%', sm: '75%' }, alignItems: 'left', justifyContent: 'left', display: 'flex', flexDirection: 'column', }}>
          <Typography sx={{ fontFamily: "poppins", fontSize: "16px", lineHeight: "24px", fontWeight: 500, width: '100%' }}>
          Welcome to the Universal Loan/Mortgage Calculator! 
            </Typography>
            <Typography sx={{ fontFamily: "poppins", fontSize: "12px", lineHeight: "24px", fontWeight: 400, width: '100%',paddingTop:'10px' }}>
            No matter what type of loan you're considering—be it a mortgage/personal loan, auto loan, student loan, 
            or any other installment loan—our versatile calculator has got you covered. With just a few simple inputs,
             such as loan amount, interest rate, loan term, and any additional fees, you can quickly estimate your monthly payments,
              total interest costs, and even compare various loan terms side by side.
            </Typography>
           
          </Box>

        </Box>

        {/* <Box sx={{
          width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)' }, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row'
          , marginLeft: { md: '16%', sm: '5%' }, marginRight: { md: '16%', sm: '5%' }, marginTop: { md: '1.5%', sm: '3%' }, borderTop: '2px solid #E2E2E2'
        }}>
        </Box>

        <Box sx={{
          width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)' }, alignItems: 'left', justifyContent: 'left', display: 'flex', flexDirection: 'row'
          , marginLeft: { md: '16%', sm: '5%' }, marginRight: { md: '16%', sm: '6%' }, marginTop: { md: '2%', sm: '3%' }, gap: { md: '2%', sm: '1.5%' },
        }} >

          <Box sx={{
            width: { md: 'calc(20%)', sm: '20%' }, alignItems: 'left', justifyContent: 'left', display: 'flex', flexDirection: 'row', 
            minWidth: { md: '190px', sm: '160px' }
          }} >
            <Typography style={{
              fontFamily: "poppins", fontSize: "16px", lineHeight: "24px", fontWeight: "500", color: "#313131",
              display: 'flex', alignItems: 'center'
            }}>
              Purchase price<ArrowForwardIcon sx={{ marginLeft: "5px", color: "#313131", fontSize: "16px", lineHeight: "24px" }} />
            </Typography>
          </Box>

          <Box sx={{
            width: { md: '100%', sm: '100%' },minWidth: { md: 'calc(80% / 2)', sm: 'calc(75% / 2)' } ,alignItems: 'left', justifyContent: 'left', display: 'flex', flexDirection: 'row',
         
          }} >

            <Box sx={{ width: { md: '47.5%', sm: '50%' }, alignItems: 'left', justifyContent: 'left', display: 'flex', flexDirection: 'row' }} >

              <TextField className='askingtxt' value={askingPriceValue} variant="outlined"
                onChange={(event) => { handleChangeAskingPriceValue(event, dataList.index) }}
                sx={{ width: "100%", fontFamily: "poppins", fontSize: "12px", lineHeight: "18px", fontWeight: "400", }}
                InputProps={{
                  sx: { height: '38px', color: " #626262" },
                  type: 'number', min: 0, max: 9,
                  '& label': { display: 'flex', alignItems: 'left', justifyContent: 'left' }
                }}

              />
            </Box>
          </Box>
        </Box> */}

        
          <Box sx={{
          width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)' }, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row'
          , marginLeft: { md: '16%', sm: '5%' }, marginRight: { md: '16%', sm: '5%' }, marginTop: { md: '1%', sm: '3%' }, borderTop: '2px solid #E2E2E2'
        }}>
        </Box>

        <Box sx={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)' },
          marginLeft: { md: '16%', sm: '5%' }, marginRight: { md: '16%', sm: '5%' }, marginTop: { md: '2%', sm: '3%' },
          gap: { md: '2%', sm: '0' }
        }} >

          <Box sx={{
            display: 'flex', flexDirection: 'column', width: "20%", minWidth: { md: '190px', sm: '160px' },
            alignItems: 'left', gap: { md: '5%', sm: '25px' },
          }} >
            <Typography style={{
              fontFamily: "poppins", fontSize: "16px", lineHeight: "24px", fontWeight: "500", color: "#313131",
              display: 'flex', alignItems: 'center'
            }}>
              Purchase price<ArrowForwardIcon sx={{ marginLeft: "5px", color: "#313131", fontSize: "16px", lineHeight: "24px" }} />
            </Typography>
          </Box>

          <Box sx={{
            minWidth: { md: 'calc(80%)', sm: 'calc(76.5%)' }, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: { md: '0', sm: '1.5%' }
            , justifyContent: 'center', gap: { md: '4.2%', sm: '1.8%' },
          }} >

            <Box sx={{ width: { md: '48%', sm: '100%' }, alignItems: 'left', justifyContent: 'left', display: 'flex', flexDirection: 'row' }} >

              <TextField className='askingtxt' value={askingPriceValue} variant="outlined"
                onChange={(event) => { handleChangeAskingPriceValue(event, dataList.index) }}
                sx={{ width: "100%", fontFamily: "poppins", fontSize: "12px", lineHeight: "18px", fontWeight: "400", }}
                InputProps={{
                  sx: { height: '38px', color: " #626262" },
                  type: 'number', min: 0, max: 9,
                  '& label': { display: 'flex', alignItems: 'left', justifyContent: 'left' }
                }}

              /> </Box>
            <Box sx={{ width: { md: '48%', sm: '100%' }, alignItems: 'left', justifyContent: 'left', display: 'flex', flexDirection: 'row' }} >
            

            </Box>
          </Box>
        </Box>

        <Box sx={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)' },
          marginLeft: { md: '16%', sm: '5%' }, marginRight: { md: '16%', sm: '5%' }, marginTop: { md: '45px', sm: '35px' },
          gap: { md: '2%', sm: '0' }
        }}>

          <Box sx={{
            display: 'flex', flexDirection: 'column', width: "20%", minWidth: { md: '190px', sm: '160px' },
            alignItems: 'center', gap: { md: '5%', sm: '25px' }, height: "400px",
          }}>

            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginTop: { md: '-49px', sm: '0px' }, }}>

              <Typography sx={{
                fontFamily: 'poppins', fontSize: '16px', lineHeight: '24px', fontWeight: '600', color: '#313131', marginRight: 'auto',
                marginTop: { md: '20px', sm: '-30px' }
              }}>
                Input parameters
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginTop: { md: '0px', sm: '-10px' }, }}>
              <Typography sx={{
                fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>
                Mortgage options
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginTop: { md: '3px', sm: '1px' },
            position: 'relative',
        }}>
              <Typography sx={{
                fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>
                Purchase price
              </Typography>
              <HelpIcon onClick={handleAskingPriceTextClick}
                sx={{ fontSize: '12px', cursor: 'pointer', color: '#878787' }} />
              {showAskingPriceText && (
                <Box sx={{
                  position: 'absolute', top: 30, right:-170, zIndex: 9999,borderRadius:'8px',
                  background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
                }}                >
                  <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ',border:'1px solid #0C276C', } }}></Box>
                  <Typography sx={{
                    fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',

                  }}>
                    This shows the total amount to be paid to seller/builder</Typography></Box>)}
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginTop: { md: '7px', sm: 'px' },
            }}>
              <Typography sx={{
                fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>
                Initial payment
              </Typography>
              <HelpIcon onClick={handleInitialPaymentTextClick}
                sx={{ fontSize: '12px', cursor: 'pointer', color: '#878787' }} />
              {showInitialpaymentText && (
                <Box sx={{
                  position: 'absolute', top: { md: '66%', sm: '43.2%' }, left: { md: '26.6%', sm: '21%' }, width: '200px',borderRadius:'8px',
                  background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', border:'1px solid #0C276C',zIndex: 9999,
                }} >
                  <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                  <Typography sx={{
                    fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                  }}>
                    Portion of  purchase price arranged from own fund , not from mortgage loan</Typography></Box>)}
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginTop: { md: '7.5px', sm: '2px' },
            }}>
              <Typography sx={{
                fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>
                Mortgage amount
              </Typography>
              <HelpIcon onClick={handleMortgageAmountTextClick}
                sx={{ fontSize: '12px', cursor: 'pointer', color: '#878787' }} />
              {showMortgageAmountText && (
                <Box sx={{
                  position: 'absolute', top: { md: '72.5%', sm: '47.3%' }, left: { md: '26.6%', sm: '21%' },borderRadius:'8px',zIndex: 9999,
                  background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
                }} >
                  <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                  <Typography sx={{
                    fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                    padding: '5px'
                  }}>Required Mortgage Amount</Typography></Box>)}
            </Box>
            {/* <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginTop: { md: '4.5px', sm: '2px' },
            }}>
              <Typography sx={{
                fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>Term</Typography> <HelpIcon onClick={handleTermClick}
                sx={{ fontSize: '12px', cursor: 'pointer', color: '#878787' }} />
              {showTermText && (
                <Box sx={{
                  position: 'absolute', top: { md: '78.6%', sm: '51.5%' }, left: { md: '26.6%', sm: '21%' },borderRadius:'8px',zIndex: 9999,
                  background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
                }}>
                  <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                  <Typography sx={{
                    fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                  }}>
                    The number of years will be engaged with proposed mortgage</Typography>
                </Box>)}
            </Box> */}
   <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginTop: { md: '6px', sm: '3px' },
            }}>
              <Typography sx={{
                fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}> Amortization period
              </Typography>
              <HelpIcon onClick={handleAmortizationTextClick}
                sx={{ fontSize: '12px', color: '#878787', cursor: 'pointer' }} />
              {showAmortizationText && (
                <Box sx={{
                  position: 'absolute', top: { md: '91.7%', sm: '60%' }, left: { md: '26.6%', sm: '21%' },borderRadius:'8px',zIndex: 9999,
                  background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
                }} >
                  <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                  <Typography sx={{
                    fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                  }}>The number of years & months over which you will repay this loan
                  </Typography>
                </Box>)}

            </Box>

            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginTop: { md: '8px', sm: '1px' },
            }}>
              <Typography sx={{
                fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>
                Interest rate (%)
              </Typography>
              <HelpIcon onClick={handleAnnualInterestRateClick}
                sx={{ fontSize: '12px', cursor: 'pointer', color: '#878787' }} />
              {showAnnualInterestRateText && (
                <Box sx={{
                  position: 'absolute', top: { md: '85.5%', sm: '55.6%' }, left: { md: '26.6%', sm: '21%' },borderRadius:'8px',zIndex: 9999,
                  background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
                }} >
                  <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                  <Typography sx={{
                    fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                  }}>
                    Annual interest rate for this mortgage
                  </Typography>
                </Box>)}
            </Box>
         
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginTop: { md: '7px', sm: '1px' },
            }}>
              <Typography sx={{
                fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}> Payment frequency
              </Typography>
              <HelpIcon onClick={handlePaymentfreequencyTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showPaymentfreequencyText && (
                <Box sx={{
                  position: 'absolute', top: { md: '98.5%', sm: '64%' }, left: { md: '26.6%', sm: '21%' },borderRadius:'8px',zIndex: 9999,
                  background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '250px', border:'1px solid #0C276C',
                }}>
                  <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                  <Typography sx={{
                    fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                  }}>Monthly, weekly, biweekly(Every two weeks), semi monthly(24 x per year), Accelerated BiWeekly(Reducing Amortization Period
                    by making extra payment, half  of monthly payment on every two weeks.), Accelerated Weekly(Another accelerated option, 
                    one fourth of monthly payment on every weeks.)
                  </Typography>
                </Box>)}
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginTop: { md: '4.5px', sm: '2px' },
            }}>
              <Typography sx={{
                fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>Term</Typography> <HelpIcon onClick={handleTermClick}
                sx={{ fontSize: '12px', cursor: 'pointer', color: '#878787' }} />
              {showTermText && (
                <Box sx={{
                  position: 'absolute', top: { md: '78.6%', sm: '51.5%' }, left: { md: '26.6%', sm: '21%' },borderRadius:'8px',zIndex: 9999,
                  background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
                }}>
                  <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                  <Typography sx={{
                    fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                  }}>
                    The number of years will be engaged with proposed mortgage</Typography>
                </Box>)}
            </Box>
          </Box>

          <Box sx={{
            minWidth: { md: 'calc(80%)', sm: 'calc(75%)' }, display: 'flex', flexDirection: 'row', alignItems: 'center',
             marginLeft: { md: '0', sm: '1.5%' }
            , justifyContent: 'center', gap: { md: '4.2%', sm: '1.5%' },
          }}>
            {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex).map((data, index) => (
              <Box key={index} className="slide" sx={{ width: 'calc(75%)' }}  >
                <DivComponents dataList={dataList} setDataList={setDataList}
                  index={index} parentIndex={index} CheckboxEnabled={CheckboxEnabled} setCurrentSlideIndex={setCurrentSlideIndex}
                  currentSlideIndex={currentSlideIndex} mortgageName={data.name}
                  isDeleteButtonEnabled={isDeleteButtonEnabled} selectedCheckboxList={selectedCheckboxList}
                  setCheckboxSelectedList={setCheckboxSelectedList} askingPriceValue={askingPriceValue}
                  isSelected={isSelected} setIsSelected={setIsSelected}

                  handleChangeTerm={handleChangeTerm} handleChangeInterest={handleChangeInterest} handleChangePeriod={handleChangePeriod}
                  handleChangeMortgageAmount={handleChangeMortgageAmount} handleChangeAskingprice={handleChangeAskingprice}
                  handleChangeFreequency={handleChangeFreequency} handleChangeInitialPayment={handleChangeInitialPayment}
                  mortgageAmountTextValue={mortgageAmountTextValue} setMortgageAmountTextValue={setMortgageAmountTextValue}
                  askingPriceVariableParent={askingPriceVariableParent} setAskingPriceVariableParent={setAskingPriceVariableParent}

                />
              </Box>
            ))}
          </Box>

        </Box>

        <Box sx={{
          display: 'flex', flexDirection: "row", marginTop: { md: '2%', sm: '3.5%' }, alignItems: 'center', width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)' },
          marginLeft: { md: '16%', sm: '5%' }, marginRight: { md: '16%', sm: '5%' }

        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'row', width: { md: '20%', sm: '20%' }, minWidth: { md: '190px', sm: '160px' },
            alignItems: 'center'
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131", marginRight: 'auto',
            }}> Scheduled Payment</Typography><HelpIcon onClick={handlePaymentSheduleTextClick1} sx={{
              fontSize: '12px', cursor: 'pointer',
              color: '#878787'
            }} />
            {showPaymentSheduleText1 && (<Box
              sx={{
                position: 'absolute', top: { md: '111%', sm: '71%' }, left: { md: '26.6%', sm: '21%' },borderRadius:'8px',zIndex: 9999,
                background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
              }}>
              <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
              <Typography sx={{
                fontFamily: 'poppins', fontSize: '12px',
                lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
              }}>Required payment  on each scheduled payment date</Typography></Box>)}
          </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', minWidth: { md: 'calc(80%)', sm: 'calc(76.8%)' },
            alignItems: 'center', gap: { md: '4.5%', sm: '1.5%' }, marginLeft: { md: '2%', sm: '1.5%' }
          }}>
            {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex).map((value, index) => (
              <Box key={index} sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'center', border: dataList[index].isSelected ? "1px solid #0C276C" : "1px solid #CFC9C9",
                alignItems: 'center', width: '75%', height: '35px', borderRadius: '8px', background: dataList[index].isSelected ? "#F9F9F9" : "white",
              }} onMouseEnter={() => handleMouseEnterPaymentButton(index)}
                onMouseLeave={() => handleMouseLeavePaymentButton(index)}>
                <Typography style={{
                  fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
                  color: " #313131",
                }}>{value.SheduledResult}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
     
        {!CheckboxEnabled && <Box sx={{
          display: "flex", flexDirection: 'row', justifyContent: 'right', alignItems: 'right',
          width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)' }, marginRight: { md: '16%', sm: '5%' }, marginTop: { md: "33px", sm: "25px" },
          marginLeft: { md: '16%', sm: '5%' }
        }}>
          <Button onClick={handleAddSlide} disabled={dataList.length === 7} variant="contained" style={{
            color: "white", height: '30px', width: '17%', minWidth: '150px',
            borderRadius: "12px", background: isHoveredAddMortgage && dataList.length === 7
              ? '#939393' : isHoveredAddMortgage ? '#074554' : '#0C276C',
            border: dataList.length === 7 ? "1px solid #939393" : "1px solid #0C276C", fontFamily: "Roboto",
            textTransform: "none", fontStyle: "normal", fontWeight: "500", fontSize: "12px",
            '&:hover': { bgcolor: "#0B1E51", color: '#fff', border:'1px solid #0B1E51' }
          }} onMouseEnter={handleMouseEnterAddMortgage}
            onMouseLeave={handleMouseLeaveAddMortgage}
            startIcon={<AddCircleOutlineIcon style={{ color: "white", fontSize: "12px" }} />}>
            Add more options
            </Button>
            
          </Box>}
        <Box sx={{
          display: 'flex', flexDirection: "row", marginTop: '20px', width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)' }, marginRight: { md: '16%', sm: '6%' },
          marginLeft: { md: '16%', sm: '5%' }, justifyContent: 'right', marginBottom: { md: '45px', sm: '15px' }
        }}>




          {IsChevorButton && <ChevronLeftIcon onClick={handlePrevSlide} disabled={currentSlideIndex === 4} sx={{
            background: "#CFDAF4", marginLeft: { md: '35%', sm: '12%' }, cursor: currentSlideIndex === 4 ? 'default' : 'pointer',
            color: currentSlideIndex === 4 ? "#CCCCCC" : "#0C276C", boxShadow: "2px 0px 4px 0px #00000040 ", borderRadius: "50%",
            width: "36px", height: '36px'
          }} ></ChevronLeftIcon>}



          {IsChevorButton && <ChevronRightIcon onClick={isdisableAddSlidebutton ? null : handleNextSlide} sx={{
            background: "#CFDAF4", marginLeft: { md: "20px", sm: '15px' }, cursor: isdisableAddSlidebutton ? 'default' : 'pointer',
            boxShadow: "2px 0px 4px 0px #00000040", color: isdisableAddSlidebutton ? "#CCCCCC" : "#0C276C", borderRadius: "50%",
            width: "36px", height: '36px'
          }} ></ChevronRightIcon>}

        </Box>

        <Box className="carousel" sx={{
          display: 'flex', flexDirection: "row", alignItems: "center", width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)' }
          , marginTop: { md: '-1.5%', sm: '0%' }, marginRight: { md: '16%', sm: '5%' }, marginLeft: { md: '16%', sm: '5%' }
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width: { md: '26%', sm: '25%' },
            alignItems: 'center', gap: '11%', height: "265px", minWidth: { md: '190px', sm: '160px' }
          }}>
            < Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center', marginTop: { md: '10%', sm: '12%' }
            }}>
              <Typography style={{
                fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
                color: " #313131", marginRight: 'auto',
              }}>Mortgage options</Typography></Box>
            < Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center', marginTop: { md: '-5px', sm: '-2%' }
            }}>
              <Typography style={{
                fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
                color: " #313131", marginRight: 'auto',
              }}>Scheduled payment</Typography> <HelpIcon onClick={handlePaymentSheduleTextClick2} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showPaymentSheduleText2 && (<Box
                sx={{
                  position: 'absolute', top: { md: '138%', sm: '86.7%' }, left: { md: '26.6%', sm: '21%' },borderRadius:'8px',zIndex: 9999,
                  background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>Required payment  on each scheduled payment date</Typography></Box>)}
            </Box>
            < Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center', marginTop: { md: '-1px', sm: '-1px' }
            }}>
              <Typography style={{
                fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
                color: " #313131", marginRight: 'auto',
              }}> Total principal paid</Typography><HelpIcon onClick={handleTotalPrinciplePaidTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showTotalPrinciplePaidText && (<Box
                sx={{
                  position: 'absolute', top: { md: '144.5%', sm: '90.9%' }, left: { md: '26.6%', sm: '21%' },borderRadius:'8px',zIndex: 9999,
                  background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>The total amount of principal payment made during the Term and Amortization period respectively</Typography></Box>)}</Box>



            < Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center'
            }}>
              <Typography style={{
                fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
                color: " #313131", marginRight: 'auto', marginTop: '-2px'
              }}>Total interest paid </Typography> <HelpIcon onClick={handleTotalInterestPaidTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showTotalInterestPaidText && (<Box
                sx={{
                  position: 'absolute', top: { md: '151.2%', sm: '95%' }, left: { md: '26.6%', sm: '21%' },borderRadius:'8px',zIndex: 9999,
                  background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',
                }}>Total of all interest paid during the Term  period respectively assuming that the conditions of your loan
                </Typography></Box>)}</Box>

            < Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center', marginTop: { md: '0px', sm: '-1px' }
            }}>
              <Typography style={{
                fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
                color: " #313131", marginRight: 'auto',
              }}>Total amount paid</Typography><HelpIcon onClick={handleTotalAmountPaidTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showTotalAmountPaidText && (<Box
                sx={{
                  position: 'absolute', top: { md: '157.7%', sm: '98.9%' }, left: { md: '26.6%', sm: '21%' },borderRadius:'8px',zIndex: 9999,
                  background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width:{md:'350px', sm:'400px'} , border:'1px solid #0C276C',
                }}  >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',
                }}>Total of all payments made during the Term and Amortization period respectively, assuming that the conditions of your loan
                  (e.g. interest rate, amortization period, term, etc.) will not change during these periods.</Typography></Box>)}</Box>
          </Box>
          <Box sx={{
            minWidth: { md: 'calc(80%)', sm: '75%' }, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: { md: '2%', sm: '1.5%' }
            , justifyContent: 'center', gap: { md: '4.2%', sm: '1.5%' },
          }}>
            {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex).map((data, index) => (
              <Box key={index} className="slide" sx={{ width: 'calc(75%)' }} >
                <PaymentComponent dataList={dataList} index={index} mortgageName={data.name} onChange={() => handleNextCheckboxChange(data.name)}
                  isCheckedCheckboxNext={selectedCheckboxList.includes(data.name)} isSelected={isSelected} setDataList={setDataList} />
              </Box>
            ))}
          </Box>

        </Box>







         
      <Box sx={{
          display: 'flex', flexDirection: "row", alignItems: 'center', width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)' },
          marginLeft: { md: '16%', sm: '5%' }, marginRight: { md: '16%', sm: '5%' },height:{ md: '10%', sm: '8%' },

        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'row', width: { md: '20%', sm: '20%' }, minWidth: { md: '190px', sm: '160px' },
            alignItems: 'center'
          }}>           
           
          </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', minWidth: { md: 'calc(79.8%)', sm: 'calc(76.7%)' },
            alignItems: 'center', gap: { md: '4.5%', sm: '1.5%' }, marginLeft: { md: '2%', sm: '1.5%' }
          }}>
          {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex).map((data, index) => (
 <Box key={index} className="slide" sx={{ width: 'calc(75%)' }}>
            <Button id={index} variant="contained" onClick={function () { handleSummary(data.name); }}
              sx={{
                color: "white", textTransform: "none", height: { md: '36px', sm: '36px' },padding:{ md: '0px', sm: '5px' },
                background: "#EB5648", border: "1px solid #EB5648", fontFamily: "Poppins",
                fontStyle: "normal", lineHeight: "18px", fontWeight: "400", fontSize: "12px", width: "100%", borderRadius: "8px", 
                '&:hover': { bgcolor: "#D9210E", color: '#fff', border:'1px solid #D9210E' }
              }}
              onMouseEnter={() => handleMouseEnterPaymentButton(index)}
              onMouseLeave={() => handleMouseLeavePaymentButton(index)}
            >

              Payment Schedule
            </Button>
            </Box>
          ))}

        </Box>
        {showPopupforSummary && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: { md: '1150px', sm: '1250px' },
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '2'
            }}
          >
            <Box
              sx={{
                position: 'absolute',

                width: '80%',
                height: '80%',
                overflowY: 'auto',
                backgroundColor: '#fff',
                padding: { md: "75px", sm: "50px" },
                border: '1px solid #CFC9C9',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                boxSizing: 'border-box',
                zIndex: '3'
              }}
            >
              <IconButton onClick={handleCancel} sx={{
                color: '#939393', position: 'absolute', width: '20px', height: '20px',
                left: { md: '97%', sm: '95%' }, top: '14px'
              }} >
                <HighlightOffIcon ></HighlightOffIcon>
              </IconButton>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: { md: 650, sm: 800 } }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#0C276C', }}>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#ffffff', textAlign: 'center' }}>Period</TableCell>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#ffffff', textAlign: 'center' }}>Principal Payment</TableCell>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#ffffff', textAlign: 'center' }}>Interest Payment</TableCell>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#ffffff', textAlign: 'center' }}>Total Payment</TableCell>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#ffffff', textAlign: 'center' }}>Ending Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {

                      showResultSummary.map((row, index) => (
                        <TableRow
                          key={index}

                          sx={{
                            '&:nth-of-type(odd)': {
                              backgroundColor: '#F3F3F3',
                            },
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },

                          }}
                        >

                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center', padding: { md: '15px', sm: '10px' }
                          }}>{row.frequency} {row.month}</TableCell>
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center', padding: { md: '15px', sm: '10px' }
                          }}>{row.principlePayment}</TableCell>
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center', padding: { md: '15px', sm: '10px' }
                          }}>{row.interestPayment}</TableCell>
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center', padding: { md: '15px', sm: '10px' }
                          }}>{row.totalPayment}</TableCell>
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center', padding: { md: '15px', sm: '10px' }
                          }}>{row.EndingBalance}</TableCell>
                        </TableRow>
                      ))}

                  </TableBody>
                </Table>
              </TableContainer></Box></Box>)} </Box>
        {showPopupforCheckbox && (<CheckboxPopUp ></CheckboxPopUp>)}
      </Box>
    </Grid>
  );

}

export default MortgageAgent;