import React from "react";
import UserA from './images/Frame 5705.png';
import { Typography } from '@mui/material';

import msg from "./images/Vector (10).png";

import star from "./images/Vector (11).png";

import { useState,useEffect } from "react";

import hand from "./images/Group 3427.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import excl from './images/Vectorpop.png';
import RateAndComment from './RateAndCommentMobile'
function ListDetailsMobile(props) {

const {setNumberOfAddClick,numberOfAddClick}=props;
  
   const [isHovered, setIsHovered] = useState(false);
   const [showPopup, setShowPopup] = useState(false);
   const [isMoreDetails, setIsMoreDetails] = useState(false);
   const handleCancel = () => {
      setShowPopup(false);
      handleSelection('null');

    };
   
    const handleDoneClick = () => {
      setShowPopup(false);
      
      console.log(`Clicked no ${numberOfAddClick}`);
      handleMenuItemClick("Accept");
      
    };
    useEffect(() => {
      console.log(`Clicked no ${numberOfAddClick}`);
    }, [numberOfAddClick]);
  
  
   const handleMouseEnter = () => {
      setIsHovered(true);
   };

   const handleMouseLeave = () => {
      setIsHovered(false);
   };
   const handleMoreDetails = () => {
      setIsMoreDetails(true);
   };
   const handleLessDetails = () => {
      setIsMoreDetails(false);
   };
   
   const [buttonLabelAccept, setButtonLabelAccept] = useState("Accept");
   const [buttonLabelReject, setButtonLabelReject] = useState("Reject");
   const [buttonLabelNegotiate, setButtonLabelNegotiate] = useState("Negotiate");
   const [buttonBgAccept, setButtonBgAccept] = useState("white");
   const [buttonBgReject, setButtonBgReject] = useState("white");
   const [buttonBgNegotiate, setButtonBgNegotiate] = useState("white"); 
   const [buttonColorAccept, setButtonColorAccept] = useState("#212427");
   const [buttonColorReject, setButtonColorReject] = useState("#212427");
   const [buttonColorNegotiate, setButtonColorNegotiate] = useState("#212427");
   const [buttoniconAccept, setButtoniconAccept] = useState('');
   const [buttoniconReject, setButtoniconReject] = useState('');
   const [buttoniconNegotiate, setButtoniconNegotiate] = useState('');

   

  const handleSelection = (value) => {
    if (value === "Accept") {
      setNumberOfAddClick(numberOfAddClick+1);
      setButtonLabelAccept("Accepted");
      setButtonLabelReject("Reject");
      setButtonLabelNegotiate("Negotiate");
      setButtonBgAccept('#76DE24');
      setButtonColorAccept('white');
      setButtonBgReject('white');
     setButtonColorReject('#212427');
     setButtonBgNegotiate('white');
     setButtonColorNegotiate('#212427');
     setButtoniconAccept(<DoneIcon style={{color:'white',fontSize:'12px',marginLeft:'2px'}}></DoneIcon>);
     setButtoniconReject('');
     setButtoniconNegotiate('');
    
    } else if (value === "reject") {
     setButtonLabelReject("Rejected");
     setButtonLabelAccept("Accept");
     setButtonLabelNegotiate("Negotiate");
     setButtonBgReject('#EB5648');
     setButtonColorReject('white');
     setButtonBgAccept('white');
     setButtonColorAccept('#212427');
     setButtonBgNegotiate('white');
     setButtonColorNegotiate('#212427');
     setButtoniconReject(<CloseIcon style={{color:'white',fontSize:'12px',marginLeft:'2px'}}/>)
     setButtoniconNegotiate('');
     setButtoniconAccept('')
    } else if (value === "negotiate") {
      setButtonLabelNegotiate("Negotiated");
      setButtonLabelReject("Reject");
      setButtonLabelAccept("Accept");
      setButtonBgNegotiate('#1F6373');
      setButtonColorNegotiate('white');
      setButtonColorAccept('#212427');
      setButtonBgAccept('white');
      setButtonBgReject('white');
      setButtonColorReject('#212427');
      setButtoniconNegotiate(<SwapHorizIcon style={{color:'white',fontSize:'12px',marginLeft:'2px'}}/>);
      setButtoniconAccept('')
      setButtoniconReject('');
    }
  }
  
  const handleMenuItemClick = (value) => {
   setNumberOfAddClick(numberOfAddClick);
   handleSelection(value);
   
 }
 const handleAcceptClick = () => {
   setShowPopup(true);   
   
 }

 const [showRate, setShowRate] = useState(false);

 const handleButtonClick = () => {
   setShowRate(true);
 };
 
   return (
      <Box>
         {!isMoreDetails && (<Box sx={{
            display: "flex", flexDirection: "coloumn", backgroundColor: '#F8F8F8', height: '190px', marginBottom: '10px',
            alignContent: "center", width: 'calc(100% )',border:isHovered? "1px solid #1F6373":"none", boxShadow:isHovered?'0px 4px 4px rgba(0, 0, 0, 0.25)':'none'
            ,borderRadius:isHovered?'8px':'none'
         }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Box sx={{ display: 'flex', paddingTop: '16px', paddingLeft: '20px', paddingRight: '20px', flexDirection: 'column', width: '100% ',
             gap: '18px' }}>
 
               
               <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100% ', gap: '7%',}}>
                 
                 
                 
                  <Box  sx={{
                     width: 'calc((100% )/3)', height: '80px', border: '1px solid #D2D2D2', borderRadius: '12px', justifyContent: 'center',
                      display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2px',
                  }}>
                     <img src={UserA} alt="" style={{ width: '20px', height: '20px' }}  />
                     <Typography sx={{ fontFamily: "poppins", fontSize: "10px", fontWeight: "400", lineHeight: '15px', color: "#212427", }}>
                     Agent code
                     </Typography>
                     <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "500", lineHeight: '18px', color: "#212427", }}>
                        6601
                     </Typography>
                  </Box>
               
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100% ',gap:'10px'}}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100% ',gap:'5px'}}>

                  <Box sx={{
                     width: 'calc((100% )/1.5)', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'left',
                     gap: '2px',
                  }}>
                     <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                     Monthly payment
                     </Typography>
                     <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", }}>
                        $ 25,000
                     </Typography>
                  </Box>
                  <Box sx={{ width: 'calc((100% )/2)', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'left',
                   gap: '2px', }}>
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  Interest (%)
                     </Typography>
                     <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", }}>
                     4.5%
                     </Typography>
                  </Box></Box>
                  <Box sx={{
                  width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center',

               }}      >

                  <Button onClick={handleMoreDetails} style={{
                     fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", height: '24px',
                     textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px', width: 'calc(100% )'
                  }}>
                     More details<ArrowDropDownIcon style={{}} />
                  </Button>


               </Box>

                  </Box>


               </Box>


              
               {/* <Box
        sx={{
          width: '100%',   display: 'flex', flexDirection: "row", justifyContent: 'center',  alignItems:'center',
         
        }}

      >
        
      

      
      </Box>  */}

               <Box sx={{
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'calc(100% )', gap: '5%'

               }}>
                  <Box sx={{
                     display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                     width: 'calc((100% )/3)'
                  }}>
                     
                     <Button  onClick={() => handleAcceptClick()}   disabled={numberOfAddClick === 4} sx={{
                        fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color:(numberOfAddClick === 4)? 'white !important':  buttonColorAccept, width: '100%', height: '28px',
                        textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px',backgroundColor:(numberOfAddClick === 4)? '#939393': buttonBgAccept ,
                     }}>
                      {buttonLabelAccept}{buttoniconAccept}
                     </Button>
                     
                  </Box>
                  <Box sx={{
                     display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                     width: 'calc((100% )/3)'
                  }}>
                     <Button onClick={() => handleMenuItemClick("reject")} sx={{
                        fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: buttonColorReject, width: '100%', height: '28px',
                        textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px',backgroundColor:buttonBgReject, '&:hover': { bgcolor: "#EB5648",color:'white' }
                     }}>
                         {buttonLabelReject}{buttoniconReject}
                     </Button>
                  </Box>
                  <Box sx={{
                     display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                     width: 'calc((100% )/3)'
                  }}>
                     <Button onClick={() => handleMenuItemClick("negotiate")} sx={{
                        fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: buttonColorNegotiate, width: '100%', height: '28px',
                        textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px',backgroundColor:buttonBgNegotiate, '&:hover': { bgcolor: "#1F6373",color:'white' }
                     }}>
                        {buttonLabelNegotiate}{buttoniconNegotiate}
                     </Button>
                  </Box>
               </Box>
               <Box
                  sx={{
                     width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center',

                  }}

               >

                  <Typography style={{
                     fontFamily: "poppins", fontSize: "10px", fontWeight: "400", lineHeight: '15px', color: "#626262",
                     textTransform: 'none', justifyContent: 'center', alignContent: 'center'
                  }}>
                     Received on 12th Feb 23 : 12 PM
                  </Typography>
                  <Typography style={{
                     fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427",
                     textTransform: 'none', justifyContent: 'center', alignContent: 'center',marginLeft:'15px'
                  }}>
                   Mortgage 1
                  </Typography>


               </Box>

               <Box>
               </Box>
            </Box>


         </Box>)}
         {isMoreDetails && (<Box sx={{
            display: "flex", flexDirection: "column", backgroundColor: '#F8F8F8', height: '450px', marginBottom: '10px',
            alignContent: "center", width: 'calc(100% -30px)',
           gap: '18px',border:isHovered? "1px solid #1F6373":"none", boxShadow:isHovered?'0px 4px 4px rgba(0, 0, 0, 0.25)':'none'
           ,borderRadius:isHovered?'8px':'none'
         }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
           

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', 
            marginTop: '5px' }}>
            <Box sx={{  width: 'calc(100% )', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', 
            gap: '10%',marginTop:'10px'

            }}      >
                <Box onClick={handleButtonClick}  sx={{
                     width: '62px', height: '80px', border: '1px solid #1F6373', borderRadius: '12px', justifyContent: 'center', display: 'flex',
                     flexDirection: 'column', alignItems: 'center', gap: '2px', alignContent: 'center',boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',
                     marginLeft: showRate ? '38px' : '0',
                  }}>
                     <img src={UserA} alt="" style={{ width: '20px', height: '20px' }}  />
                     <Typography sx={{ fontFamily: "poppins", fontSize: "10px", fontWeight: "400", lineHeight: '15px', color: "#212427", }}>
                     Agent code
                     </Typography>
                     <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "500", lineHeight: '18px', color: "#212427", }}>
                        6601
                     </Typography>
                     <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: 'calc(100% -30px)',gap:'4px'}}>
                     <img src={msg} alt="" style={{ width: '12px', height: '10px' }} />
                     <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "500", lineHeight: '18px', color: "#1F6373",
                    marginTop:'-4px' }}>
                     4.5
                     </Typography>
                     <img src={star} alt="" style={{ width: '12px', height: '10px' }} />
                  </Box>
                  </Box>
                   {showRate && <RateAndComment />}
            </Box>
            </Box>





            
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
         marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '60%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  Monthly payment (CAD)
                  </Typography></Box>
               <Box
                  sx={{
                     width: '40%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                     25,000
                  </Typography>
               </Box>
            </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
         marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '60%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  Interest (%)
                  </Typography></Box>
               <Box
                  sx={{
                     width: '40%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  4.5%
                  </Typography>
               </Box>
            </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
         marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '60%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  Type
                  </Typography></Box>
               <Box
                  sx={{
                     width: '40%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  Variable
                  </Typography>
               </Box>
            </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
       marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '60%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  Amortization
                  </Typography></Box>
               <Box
                  sx={{
                     width: '40%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  25 years
                  </Typography>
               </Box>
            </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
         marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '60%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  Penalty
                  </Typography></Box>
               <Box
                  sx={{
                     width: '40%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  1500
                  </Typography>
               </Box>
            </Box>
            </Box>
           
           
            
           

            

           






            <Box sx={{
                width: 'calc(100% -30px)', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center',
                marginLeft:'20px',marginRight:'20px'
            }}      >

               <Button onClick={handleLessDetails} style={{
                  fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", height: '24px',
                  textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px', width: 'calc(100% )', 
                 
   
               }}>
                  Less details<ArrowDropUpIcon style={{}} />
               </Button>


            </Box>


            <Box sx={{
               display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'calc(100% -30px)', gap: '5%',
               marginLeft:'20px',marginRight:'20px'

            }}>
               <Box sx={{
                  display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                  width: 'calc((100% )/3)'
               }}>
                  <Button onClick={() => handleMenuItemClick("Accept")} disabled={numberOfAddClick===4} sx={{
                     fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: buttonColorAccept, width: '100%', height: '28px',
                     textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px',backgroundColor:buttonBgAccept,'&:hover': { bgcolor: "#76DE24",color:'white' }
                  }}>
                    {buttonLabelAccept}{buttoniconAccept}
                  </Button>
               </Box>
               <Box sx={{
                  display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                  width: 'calc((100% )/3)'
               }}>
                  <Button onClick={() => handleMenuItemClick("reject")} sx={{
                     fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: buttonColorReject, width: '100%', height: '28px',
                     textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px',backgroundColor:buttonBgReject,'&:hover': { bgcolor: "#EB5648",color:'white' }
                  }}>
                     {buttonLabelReject}{buttoniconReject}
                  </Button>
               </Box>
               <Box sx={{
                  display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                  width: 'calc((100% )/3)'
               }}>
                  <Button onClick={() => handleMenuItemClick("negotiate")} sx={{
                     fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: buttonColorNegotiate, width: '100%', height: '28px',
                     textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px',backgroundColor:buttonBgNegotiate,'&:hover': { bgcolor: "#1F6373",color:'white' }
                  }}>
                     {buttonLabelNegotiate}{buttoniconNegotiate}
                  </Button>
               </Box>
            </Box>
            <Box
                  sx={{
                     width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center',

                  }}

               >

                  <Typography style={{
                     fontFamily: "poppins", fontSize: "10px", fontWeight: "400", lineHeight: '15px', color: "#626262",
                     textTransform: 'none', justifyContent: 'center', alignContent: 'center'
                  }}>
                     Received on 12th Feb 23 : 12 PM
                  </Typography>
                  <Typography style={{
                     fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427",
                     textTransform: 'none', justifyContent: 'center', alignContent: 'center',marginLeft:'15px'
                  }}>
                   Mortgage 1
                  </Typography>


               </Box>

            <Box>
            </Box>


         </Box>)}

         {showPopup && (

<Box
  sx={{
    position: 'fixed',
    top: '0',
    left: 0,
    width: '100%',
    height: '2600px',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2'
  }}
>
  <Box
    sx={{
      position: 'absolute',            
      width: '318px',
      top:'10%',
      height: '222px',
      backgroundColor: '#fff',

      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3'
    }}
  >
    <IconButton onClick={handleCancel} style={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left: '273px', top: '10px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
    <img src={excl} alt="" style={{width:'24px',height:'24px'}} />
    <Typography style={{
      fontFamily: "Inter", fontSize: "12px", lineHeight: "15px", fontWeight: "400", color: '#212427', width: "290px",
      textAlign: "center", marginTop: '15px'
    }}>Please note: Only 4 quotations can be accepted. 
    Choose wisely. Thank you</Typography>
    <Typography style={{
      fontFamily: "Inter", fontSize: "12px", lineHeight: "15px", fontWeight: "400", color: '#212427', width: "290px",
      textAlign: "center", marginTop: '15px'
    }}>Are you sure you want to accept this quotations?</Typography>
    <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '25px' }}>
      <button size='small' onClick={handleCancel} style={{
        width: '77px', height: '32px', fontFamily: 'Poppins', fontStyle: 'normal',
        fontWeight: '400', fontSize: '14px', lineHeight: '21px', marginRight: '24px', textAlign: 'center', color: '#313131',
        background: '#F8F8F8', border: '1px solid #CFC9C9', borderRadius: '4px',cursor:'pointer'
      }} >Cancel</button>
      <button size='small' onClick= {handleDoneClick}  style={{
        width: '77px', height: '32px', fontFamily: 'Poppins',
        fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
        background: '#1F6373', border: '1px solid #1F6373', borderRadius: '4px',cursor:'pointer'
      }} onMouseEnter={(e) => {
        e.currentTarget.style.background = '#074554';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.background = '#1F6373';
      }} >Done </button>
    </Box>
  </Box>
</Box>
)}


      </Box>

   );
}
export default ListDetailsMobile;