import React, { useState } from 'react'

//import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, IconButton, InputAdornment, OutlinedInput, Stack, Tab, Tabs, Typography } from '@mui/material'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Mortgage from './images/mortgage.png';
import PersonIcon from '@mui/icons-material/Person';
import Edit from './images/edit.png';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpIcon from '@mui/icons-material/Help';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Header1Mod from './header1-modified';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
function MortgageMob() {
  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);
  const [i, setI] = useState([1,2,3,4]);
  const [j, setJ] = useState(5);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [currentSlideIndex, setCurrentSlideIndex] = useState(2);
  const newHandleChange = (event, newValue) => {
    setValue1(newValue);
  };
  const handlePrevSlide = () => {
      
    if(currentSlideIndex>2)
      setCurrentSlideIndex(currentSlideIndex-1)
  
};

const handleNextSlide = () => {
    if(currentSlideIndex<4)
      setCurrentSlideIndex(currentSlideIndex+1)
  
}

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ paddingTop: '0px' }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
<Box >
  
<div style={{height:'60%', display: 'flex', flexDirection: 'column' }}>

  <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: '14px', fontFamily: 'Poppins', color: '#313131', fontWeight: 500, marginTop: '8px' }}>Input parameters</Typography>
 
  {/* <div className='oldpart'> */}
  <Box sx={{ display: 'flex', flexDirection: 'row',gap:'4px' }}>
      <Box style={{width: { lg: '158px', md: '158px', sm: '158px', xs: '104px' }, height: '324px', display: 'flex', flexDirection: 'column',
                paddingTop: '18px',  paddingBottom: '18px',
                 gap: '16px', marginTop: '12px', 
                position: 'relative'}}>
        {/* <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: '14px', fontFamily: 'Poppins', color: '#313131', fontWeight: 600 }}>Input parameters</Typography> */}
        <Box sx={{width:'30%',height:'248px',display:'flex',flexDirection:'column',gap:'16px',marginTop:'30px'}}>
        <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '26px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Initial payment</Typography>
          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
        </Box>
        <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Type</Typography>
          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
        </Box>
        <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Term</Typography>
          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
        </Box>
        <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Interest rate (%) </Typography>
          <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Interest (%) </Typography>
          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
        </Box>
        <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Amortization period</Typography>
          <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Amortization</Typography>
          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
        </Box>
        <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment frequency</Typography>
          <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment</Typography>
          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
        </Box>
        </Box>
        <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row', marginTop: '8px',marginBottom:'10px' }}>
          <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment schedule</Typography>
          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
        </Box>
      </Box>
      <div style={{ width: '70%', display: 'flex', gap: '4px' }}>
        {i.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex).map((item, index) => {
            return (
             <Box key={index} sx={{
                width: { lg: '158px', md: '158px', sm: '158px', xs: '104px' }, height: '308px', display: 'flex', flexDirection: 'column',
                paddingTop: '10px', paddingLeft: '12px', paddingRight: '12px', paddingBottom: '10px', backgroundColor: 'white',
                border: '1px solid #CFC9C9', gap: '8px', marginTop: '12px', borderRadius: '8px',
                position: 'relative',
              }}>
                <Box sx={{width:'30%',display:'flex',flexDirection:'column',gap:'16px'}}>
               <Typography sx={{ textAlign: 'center', fontSize: '12px', fontWeight: 400, color: '#626262',fontFamily: 'Poppins' }}>Mortgage{item}</Typography>
               
               <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>0</Typography>
                </Box>
                <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Variable</Typography>
                </Box>
                <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>2 Months</Typography>
                </Box>
                <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>0</Typography>
                </Box>
                <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>0</Typography>
                </Box> 
                <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Monthly</Typography>
                </Box>
                <Typography sx={{ fontSize: { lg: '14px', md: '14px', sm: '14px', xs: '12px' }, fontWeight: { lg: 500, xs: 500 }, color: '#313131', fontFamily: 'Poppins',marginTop:'12px',marginBottom:'4px',marginLeft:'12px'}}>CAD({0})</Typography>
                </Box>
              </Box>
             );
          })}
        </div>
        
      </Box>
      
      
 </div>
 <Box sx={{ display: 'flex', flexDirection: "row", width:'90%',  alignItems: 'right',
         justifyContent: 'right',alignContent:'flex-end',marginRight:'16px',marginTop:'8px' }}> 
<ChevronLeftIcon onClick={handlePrevSlide} disabled={currentSlideIndex ===  2} style={{
            background:   "#D2E0E3",marginRight:'4%',width:'24px',height:'24px',
            color:currentSlideIndex ===  2 ? "#CCCCCC" :  "#1F6373", boxShadow: "none ", borderRadius: "50%", fontSize: "26px"
          }} ></ChevronLeftIcon>



          <ChevronRightIcon onClick={ handleNextSlide} style={{
            background: "#D2E0E3", width:'24px',height:'24px',
            boxShadow: "none", color: "#1F6373",  borderRadius: "50%",
             fontSize: "26px"
          }} ></ChevronRightIcon>
       
</Box>
 
    <Tabs value={value1} onChange={newHandleChange} aria-label="basic tabs example"
          TabIndicatorProps={{ style: { background: '#313131' } }}
          sx={{
            " .MuiTabs-flexContainer": {height:'10px',
              minHeight: '28px', alignItems: 'center',paddingLeft:'10px',gap:'18px',
              "& .Mui-selected": { color: '#313131', borderColor: '#313131', fontWeight: 500 }, "& .MuiTabs-indicator": { backgroundColor: '1px solid #313131' },
            },
            "& .MuiTab-root": { paddingLeft: '0px',minHeight:'28px' },
          }}
        >
          <Tab label="Calculate payment" sx={{ textTransform: 'none',paddingBottom:0, fontFamily: 'Poppins',color:'#313131',fontWeight: 400 ,marginTop:'16px', fontSize: { lg: 16, xs: 12 } ,paddingLeft:0,paddingRight:0}} {...a11yProps(0)} />
          <Tab label="Calculate penalty on cancellation" sx={{ textTransform: 'none',paddingBottom:0,color:'#313131', fontWeight: 400, fontFamily: 'Poppins',marginTop:'16px', fontSize: { lg: 16, xs: 12 } ,paddingLeft:0,paddingRight:0}} {...a11yProps(1)} />
        </Tabs>
   <TabPanel value={value1} index={0} sx={{
      "& .MuiTabPanel-root": { padding: '0px' },
      "& .MuiBox-root": { padding: '0px' },
    }}>
      <div style={{ display: 'flex', flexDirection: 'row',gap:'4px' }}>
        <Box 
        sx={{ width:'35%',display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '50px'}}
        >
          <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '36px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment schedule</Typography>
            <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
          </Box>
          <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '36px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Remaining principle</Typography>
            <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
          </Box>
          <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Interest paid</Typography>
            <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
          </Box>
          <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Principle paid</Typography>
            <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
          </Box>
        </Box>
          {/* <div style={{width:'70%'}} className='subpart'> */}
          <div style={{width:'70%',display:'flex',flexDirection:'row',gap:'4px'}}>
              {i.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex).map((item, index) => {
                return (
                  <Box sx={{
                    width: {lg:'158px',md:'158px',sm:'158px',xs:'104px'}, height: '214px', display: 'flex', flexDirection: 'column',
                    paddingTop: '8px', paddingLeft: '12px', paddingRight: '12px', paddingBottom: '8px', backgroundColor: 'white',
                    border: '1px solid #CFC9C9', gap: '4px', borderRadius: '8px',marginTop:'8px'
                }}>
                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',alignContent:'center',justifyContent:'center'}}>
                    <Typography sx={{ width:'104px',textAlign: 'left', fontSize: '12px', fontWeight: 400, color: '#626262',fontFamily: 'Poppins' }}>Mortgage{item}</Typography>
                  </Box>
                  <Box sx={{width:'178px',display:'flex',flexDirection:'column',gap:'12px'}}>
                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '36px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Typography sx={{fontSize: '12px', color: '#313131',textAlign: 'left', lineHeight: '28px', fontFamily: 'Poppins'}}>0</Typography>
                    </Box>
                    <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '36px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Typography sx={{fontSize: '12px', color: '#313131',textAlign: 'left', lineHeight: '28px', fontFamily: 'Poppins'}}>0</Typography>
                    </Box>
                    <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Typography sx={{fontSize: '12px', color: '#313131',textAlign: 'left', lineHeight: '28px', fontFamily: 'Poppins'}}>0</Typography>
                    </Box>
                    <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Typography sx={{fontSize: '12px', color: '#313131', lineHeight: '28px',textAlign: 'left', fontFamily: 'Poppins'}}>0</Typography>
                    </Box>
                    </Box>
                    </Box>
                );
              })}
            </div>
        </div>
    </TabPanel>
    <TabPanel value={value1} index={1}
      x={{
        "& .MuiTabPanel-root": { paddingLeft: '0px' },
        "& .MuiBox-root": { padding: '0px' },
      }}>
      <div style={{ display: 'flex', flexDirection: 'row',gap:'4px' }}>
        <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', marginTop: '64px', gap: '16px' }}>
          <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Cancellation</Typography>
            <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
          </Box>
          <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Penalty</Typography>
            <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
          </Box>
          <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Calculated penalty</Typography>
            <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
          </Box>
        </Box>
             <div style={{width:'70%',display:'flex',flexDirection:'row',gap:'4px'}}>
              {i.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex).map((item, index) => {
                return (
                  <Box sx={{
                      width: {lg:'158px',md:'158px',sm:'158px',xs:'104px'}, display: 'flex', flexDirection: 'column',
                      paddingTop: '10px', paddingLeft: '12px', paddingRight: '12px', paddingBottom: '10px', backgroundColor: 'white',
                      border: '1px solid #CFC9C9', gap: '12px', borderRadius: '8px',marginTop:'12px'
                  }}>
                 <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',alignContent:'center',justifyContent:'center'}}>
                    <Typography sx={{width:'104px', textAlign: 'center', fontSize: '12px', fontWeight: 400, color: '#626262',"& .MuiTypography-root":{ fontFamily: 'Poppins'} }}>Mortgage {item}</Typography>
                    </Box>
                    <Box sx={{width:'178px',display:'flex',flexDirection:'column',gap:'16px'}}>
                    <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Typography sx={{fontSize: '12px', color: '#313131',textAlign: 'left', fontFamily: 'Poppins'}}>0</Typography>
                    </Box>
                    <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Typography sx={{fontSize: '12px', color: '#313131',textAlign: 'left',  fontFamily: 'Poppins'}}>0</Typography>
                    </Box>
                    <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Typography sx={{fontSize: '12px', color: '#313131',textAlign: 'left',   fontFamily: 'Poppins'}}>0</Typography>
                    </Box>
                    </Box>
                  </Box>
                );
              })}
            </div>
          </div>
       </TabPanel>
  </Box>
  );
}
export default MortgageMob;