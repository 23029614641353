import Box from '@mui/material/Box';
import { Button, Grid, Typography } from "@mui/material";
import { useState,  useEffect  } from "react";
import '../App.css';
import Header from './NeholaHeader'
import HeaderMobile from './NeholaHeaderMobile';
import Footer from './NeholaFooter';
import EmailIcon from '@mui/icons-material/Email';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CallIcon from '@mui/icons-material/Call';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NoImage from "./images/noimage.png"
import axios from "axios";
import { API_URL } from "./constants";
import { Link } from 'react-router-dom';

import BuilderSignUp from './BuilderSignUp';
function Projects() {
    const [currentPage, setCurrentPage] = useState('Nehola');
    const [projects, setProjects] = useState([]);
    const [images, setImages] = useState([]);
   
    async function fetchData() {
    
      try {
          const response = await axios.get(`${API_URL}/project-details`); 
          setProjects(response.data);         
       console.log("details",response.data)
         // setImages(response.data.image_list);       
        
       
       // console.log("images",response.data.image_list)
      } 
      catch (error) {
          console.error('Error:', error.message);
      }
    }
  
    useEffect(() => {
      fetchData();
    }, []);
    const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
    const [selectedProject, setSelectedProject] = useState(null);
    const openPopup = (index) => {
        setShowPopup(true);
        setSelectedProject(index);
        setImages(projects[index].image_list);
      };
    
      const closePopup = () => {
        setShowPopup(false);
        setSelectedProject(null);
      };

      const [showSlider, setShowSlider] = useState(false);
      const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleAddMoreImages = () => {
    
    setShowSlider(true);
    setShowPopup(false)
  };
  const closePopupAddMore = () => {
    
    setShowSlider(false);
    setShowPopup(true)
  };
  

  const showPreviousImage = () => {
    setCurrentImageIndex((prevIndex) => {
      if (prevIndex === 0) {
        return images.length - 1;
      } else {
        return prevIndex - 1;
      }
    });
  };
 
  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
    const articlesPerPage = 9;
    const totalPages = Math.ceil(projects.length / articlesPerPage);

    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const startIndex = (currentPageNumber - 1) * articlesPerPage;
    const endIndex = Math.min(startIndex + articlesPerPage, projects.length);

    const displayedprojects = projects.slice(startIndex, endIndex);

    const handlePageClick = (page) => {
        setCurrentPageNumber(page);
    };
    
    const [signInPopUp, setSignInPopup] = useState(false);
    const [signUpPopUp, setSignUpPopUp] = useState(false);
    const handleSignUpButton = () => {
      setSignInPopup(true);
  };

  const [showIcon, setShowIcon] = useState(Array(projects.length).fill(false));

  const handleContact = (index) => {
    const updatedShowContactInfo = [...showIcon];
    updatedShowContactInfo[index] = !updatedShowContactInfo[index];
    setShowIcon(updatedShowContactInfo);
  };

    return (
        <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 ,}}>
            <Box sx={{
                height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'flex', sm: 'none', xs: 'none' }
            }}>
                <Header currentPage={currentPage}></Header>
            </Box>
            <Box sx={{
                height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'none', sm: 'flex', xs: 'flex' }
            }}>
                <HeaderMobile ></HeaderMobile>
            </Box>
            <Box sx={{  width:'100%' , display: 'flex', flexDirection: 'column', height: '100%',}}>
            <Box sx={{
                width:{ md: 'calc(100%-10%)', sm: 'calc(100%-10%)', xs: 'calc(100%-10%)' }, display: 'flex', flexDirection: 'row', height: '100%',
                marginLeft: { md: '5%', sm: '2.5%', xs: '5%' }, marginRight: { md: '5%', sm: '2.5%', xs: '5%' }, marginTop: { md: '50px', sm: '50px', xs: '25px' },
                marginBottom: { md: '0', sm: '2.5%', xs: '5%' }, justifyContent: { xs: 'center', sm: 'space-between', md: 'space-between' }, 
                alignItems: { xs: 'center', sm: 'center', md: 'flex-start' }, paddingRight:'40px', paddingLeft: '15px', 
            }}>
                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: { md: '38px', sm: '32px', xs: '16px' },
                       lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight:'auto'
                    }}>
                        New construction homes 
                    </Typography>
                   

                    {/* <Button  onClick={handleSignUpButton} variant="contained" sx={{
                        color: "white", height: '30px',  width:{ md:'158px', sm:'158px', xs: '168px' }, borderRadius: "12px", background: '#0C276C', border: "1px solid #0C276C",
                        fontFamily: "Roboto", textTransform: "none", fontStyle: "normal", fontWeight: "500", fontSize:{ md: "14px", sm: "14px", xs: "12px" } , '&:hover': { bgcolor: "white", color: '#0C276C' }
                       
                    }}>
                     Builder SignIn
                    </Button> */}
               
            </Box>
            <Box sx={{
                width: { md: 'calc(100%-10%)', sm: 'calc(100%-10%)', xs: 'calc(100%-10%)' }, display: 'flex', flexDirection: 'row', height: '100%',
                paddingLeft: { md: '5%', sm: '2.5%', xs: '5%' }, paddingRight: { md: '5%', sm: '2.5%', xs: '5%' }, marginTop: { md: '20px', sm: '20px', xs: '0px' },
                marginBottom: { md: '20px', sm: '20px', xs: '0' },flexWrap: 'wrap',}}>
                <Box className= 'related-blog-grid'>
                    {displayedprojects.map((projects, index) => (
                        <Box key={index}   sx={{
                            width:{ md: '100%', sm: '100%', xs: '100%' }, height: '100%',borderRadius:'8px',
                            padding: { md: '10px', sm: '5px', xs: '5px' }, display: { md: 'flex', sm: 'flex', xs: 'none' },
                             flexDirection: 'column', overflowX: 'hidden'
                           , gap: { md: '10px', sm: '10px', xs: '5px' },cursor: 'pointer', transition: 'background-color 0.3s',
                           '&:hover': {
                             backgroundColor: '#F9F9F9', 
                           },  }}  >

      
                            <Box  sx={{ width: { md: '100%', sm: '100%', xs: '100%' },  height: '100%', 
                             gap:  { md: '8px', sm: '5px', xs: '8px' }, padding: '5px', display: 'flex', flexDirection: 'column',
                             }} >
                            <Box  onClick={() => openPopup(index)} sx={{ position: 'relative',width:{ md: '100%', sm: '100%', xs: '80%' },
                            height:{ md: '238px', sm: '238px', xs: '80%' },}}>
                           
                            {projects.image_list.length > 0 && (<Box sx={{width:{ md: '100%', sm: '100%', xs: '80%' },
                            height:{ md: '238px', sm: '238px', xs: '80%' }}}>
                                    <img src={projects.image_list[0]} alt="First Image" style={{position: 'absolute', top: '0',
                                    left: '0', width: '100%',height: '100%', objectFit: 'cover',borderRadius:'8px'}}/></Box>
                                  )}
                                      
                                    <Typography
                                      sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                      
                                        margin:'20px',
                                        backgroundColor: 'rgba(0, 0, 0, 0.57)', // Background color for the label
                                        color: '#fff', // Text color for the label
                                        padding: '6px 10px', borderRadius:'8px',
                                        fontSize: '12px',  fontFamily: 'poppins',fontWeight: '400', 
                                      }}
                                    >
                                    {projects.occupancy}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        position: 'absolute',
                                        bottom: 0, 
                                        right: 0,    margin:'20px',
                                        backgroundColor: '#EB5648', 
                                        color: '#fff', // Text color for the label
                                        padding: '6px 10px', borderRadius:'8px',
                                        fontSize: '12px',  fontFamily: 'poppins',fontWeight: '400', 
                                      }}
                                    >
                                    {projects.sale_status}
                                    </Typography>
                                  </Box>

                                <Typography  onClick={() => openPopup(index)} sx={{
                                    fontFamily: 'poppins', fontSize: '16px',
                                    lineHeight: '24px', fontWeight: '600', color: '#198CCD',
                                }} >{projects.project_name}</Typography>
                                <Typography  onClick={() => openPopup(index)} sx={{
                                    fontFamily: 'poppins', fontSize: '16px',
                                    lineHeight: '18px', fontWeight: '400', color: '#313131',
                                }}>{projects.pricing}</Typography>                               
                                   
                                    <Typography  onClick={() => openPopup(index)} sx={{
                                        fontFamily: 'poppins', fontSize: '14px',
                                        lineHeight: '18px', fontWeight: '400', color: '#626262',
                                    }}> {projects.building_type} by {projects.developer}  </Typography>
                                     <Typography  onClick={() => openPopup(index)} sx={{
                                        fontFamily: 'poppins', fontSize: '14px',
                                        lineHeight: '18px', fontWeight: '400', color: '#626262',
                                    }}> {projects.address}</Typography>
                                     <Typography  onClick={() => openPopup(index)} sx={{
                                        fontFamily: 'poppins', fontSize: '14px',
                                        lineHeight: '18px', fontWeight: '400', color: '#626262',display: 'inline-block',
                                    }}><HotelOutlinedIcon  onClick={() => openPopup(index)} sx={{ verticalAlign: 'middle', color: '#626262'}} ></HotelOutlinedIcon> {projects.unit_size}</Typography>
                                     <Typography  onClick={() => openPopup(index)} sx={{
                                        fontFamily: 'poppins', fontSize: '14px',
                                        lineHeight: '18px', fontWeight: '400', color: '#626262',display: 'inline-block',
                                    }}><ViewQuiltOutlinedIcon  onClick={() => openPopup(index)} sx={{ verticalAlign: 'middle', color: '#626262'}} ></ViewQuiltOutlinedIcon> {projects.suite_size}</Typography>
                                                <Box sx={{ display: 'flex', flexDirection:{ md: 'row', sm: 'column', xs:  'row' },
                                                 gap:{ md: '30px', sm:'10px', xs:  '30px' } ,width:'100%',
                                                }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', width:'50%'}}>
                                     <Button size='small'  onClick={() => handleContact(index)} sx={{
        width:{ md:'120px', sm: '120px', xs: '80%' } , height: '32px', fontFamily: 'Poppins',
        fontStyle: 'normal', fontWeight: '400', fontSize:{ md:'14px', sm: '12px', xs:'14px' } , lineHeight: '21px', textAlign: 'center', color: '#000000',
        background: '#F8A81D', border: '1px solid #F8A81D', borderRadius: '4px',cursor:'pointer',textTransform:'none',
        '&:hover': { bgcolor: "#FF8C00", color: '#000000',border: "1px solid #FF8C00" },
      }}>Contact us</Button> </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', width:'50%'}}>
         {showIcon[index] && (
  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '30px' }}>
   

    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <a href={`tel:${projects.phone_number}`}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#3DDC84 ', // Change the background color as needed
            borderRadius: '12px',
            padding: '6px', // Adjust the padding as needed
          }}
        >
          <CallIcon sx={{ color: '#fff', fontSize: '20px' }} />
        </Box>
      </a>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <a href={`mailto:${projects.email_id}`}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#BB001B', // Change the background color as needed
            borderRadius: '12px',
            padding: '6px', // Adjust the padding as needed
          }}
        >
          <MailOutlineIcon sx={{ color: '#fff', fontSize: '20px' }} />
        </Box>
      </a>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
      <a href={`https://api.whatsapp.com/send?phone=${projects.whatsapp_number}`}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#25d366', // Change the background color as needed
            borderRadius: '12px',
            padding: '6px', // Adjust the padding as needed
          }}
        >
          <WhatsAppIcon sx={{ color: '#fff', fontSize: '20px' }} />
        </Box>
      </a>
    </Box>
  </Box>
)}

      </Box>
       </Box>
                            </Box>
                        </Box>

                    ))}
                </Box>
                <Box className= 'blog-grid'>
                    {displayedprojects.map((projects, index) => (
                        <Box key={index}   sx={{
                            width: { md: 'calc(100%-5%)', sm: 'calc(100%-10%)', xs: 'calc(100%-10%)' }, height: '100%',borderRadius:'8px',
                            padding: { md: '10px', sm: '10px', xs: '5px' }, display: { md: 'none', sm: 'none', xs: 'flex' }, flexDirection: 'column', overflowX: 'hidden'
                           , gap: { md: '10px', sm: '10px', xs: '5px' },cursor: 'pointer', transition: 'background-color 0.3s',
                           '&:hover': {
                             backgroundColor: '#F9F9F9', 
                           },
                        }}  >

      
                            <Box sx={{ width: { md: '100%', sm: '100%', xs: '100%' }, height: '100%', gap: '8px', padding: '5px', display: 'flex', flexDirection: 'column', }} >
                            <Box  onClick={() => openPopup(index)} sx={{ position: 'relative',width:'100%',height:'238px'}}>
                           
                            {projects.image_list.length > 0 && (
  <img src={projects.image_list[1]} alt="First Image" style={{width:'100%',height:'238px',borderRadius:'8px'}}/>
)}
     
  <Typography  onClick={() => openPopup(index)}
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
     
      margin:'20px',
      backgroundColor: 'rgba(0, 0, 0, 0.57)', // Background color for the label
      color: '#fff', // Text color for the label
      padding: '6px 10px', borderRadius:'8px',
      fontSize: '12px',  fontFamily: 'poppins',fontWeight: '400', 
    }}
  >
   {projects.occupancy}
  </Typography>
  <Typography  onClick={() => openPopup(index)}
  sx={{
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: '20px',
    backgroundColor: '#EB5648', // Background color
    color: '#fff', // Text color for the label
    padding: '6px 10px',
    borderRadius: '8px',
    fontSize: '12px',
    fontFamily: 'poppins',
    fontWeight: '400',
  }}
>
  {projects.sale_status}
</Typography>

</Box>

                                <Typography  onClick={() => openPopup(index)} sx={{
                                    fontFamily: 'poppins', fontSize: '16px',
                                    lineHeight: '24px', fontWeight: '600', color: '#198CCD',
                                }} >{projects.project_name}</Typography>
                                <Typography sx={{
                                    fontFamily: 'poppins', fontSize: '16px',
                                    lineHeight: '18px', fontWeight: '400', color: '#313131',
                                }}>{projects.pricing}</Typography>                               
                                   
                                    <Typography  onClick={() => openPopup(index)} sx={{
                                        fontFamily: 'poppins', fontSize: '14px',
                                        lineHeight: '18px', fontWeight: '400', color: '#626262',
                                    }}> {projects.building_type} by {projects.developer}  </Typography>
                                     <Typography  onClick={() => openPopup(index)} sx={{
                                        fontFamily: 'poppins', fontSize: '14px',
                                        lineHeight: '18px', fontWeight: '400', color: '#626262',
                                    }}> {projects.address}</Typography>
                                     <Typography sx={{
                                        fontFamily: 'poppins', fontSize: '14px',
                                        lineHeight: '18px', fontWeight: '400', color: '#626262',display: 'inline-block',
                                    }}><HotelOutlinedIcon  onClick={() => openPopup(index)} sx={{ verticalAlign: 'middle', color: '#626262'}} ></HotelOutlinedIcon> {projects.unit_size}</Typography>
                                     <Typography  onClick={() => openPopup(index)} sx={{
                                        fontFamily: 'poppins', fontSize: '14px',
                                        lineHeight: '18px', fontWeight: '400', color: '#626262',display: 'inline-block',
                                    }}><ViewQuiltOutlinedIcon  onClick={() => openPopup(index)} sx={{ verticalAlign: 'middle', color: '#626262'}} ></ViewQuiltOutlinedIcon> {projects.suite_size}</Typography>
                                              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px',width:'100%' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', width:'50%'}}>
                                     <Button size='small'  onClick={() => handleContact(index)} sx={{
        width: '120px', height: '32px', fontFamily: 'Poppins',
        fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#000000',
        background: '#F8A81D', border: '1px solid #F8A81D', borderRadius: '4px',cursor:'pointer',textTransform:'none'
      }} onMouseEnter={(e) => {
        e.currentTarget.style.background = '#FF8C00';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.background = '#FF8C00';
      }} >Contact us</Button> </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', width:'50%'}}>
         {showIcon[index] && (
  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '10px' }}>
   

    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <a href={`tel:${projects.phone_number}`}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#3DDC84 ', // Change the background color as needed
            borderRadius: '12px',
            padding: '6px', // Adjust the padding as needed
          }}
        >
          <CallIcon sx={{ color: '#fff', fontSize: '20px' }} />
        </Box>
      </a>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <a href={`mailto:${projects.email_id}`}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#BB001B', // Change the background color as needed
            borderRadius: '12px',
            padding: '6px', // Adjust the padding as needed
          }}
        >
          <MailOutlineIcon sx={{ color: '#fff', fontSize: '20px' }} />
        </Box>
      </a>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
      <a href={`https://api.whatsapp.com/send?phone=${projects.whatsapp_number}`}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#25d366', // Change the background color as needed
            borderRadius: '12px',
            padding: '6px', // Adjust the padding as needed
          }}
        >
          <WhatsAppIcon sx={{ color: '#fff', fontSize: '20px' }} />
        </Box>
      </a>
    </Box>
  </Box>
)}

      </Box>
       </Box>
                            </Box>
                        </Box>

                    ))}
                </Box>
            </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', 
            width: '100%', gap: '10px', padding: '20px', paddingTop: '40px' }}>
                {/* Generate page number buttons */}
                {Array.from({ length: totalPages }, (_, index) => (
                    <Button
                        key={index + 1}
                        onClick={() => handlePageClick(index + 1)}
                        variant={currentPageNumber === index + 1 ? "contained" : "outlined"}

                        sx={{
                            width: '28px', height: '28px', borderRadius: '4px', padding: 0, minWidth: 0,
                             backgroundColor: currentPageNumber === index + 1 ? '#504FA4' : '#fff',
                            color: currentPageNumber === index + 1 ? '#ffffff' : '#504FA4', border: '1px solid #504FA4 '
                            , '&:hover': { bgcolor: "#504FA4", color: '#fff',border: '1px solid #504FA4 ' }
                        }}
                    >
                        {index + 1}
                    </Button>
                ))}</Box>
            <Footer></Footer>
            {showPopup && selectedProject !== null && (
             
<Box
  sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2',
   
  }}
>
  <Box
    sx={{
      position: 'absolute',    
      width: { md: '1150px', sm: '680px', xs: '320px' },
      height:{ md: '650px', sm: '700px', xs: '700px' },  
      backgroundColor: '#fff',
      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3',  
      padding: { md:'50px', sm:'50px', xs:'30px'}, 
      overflowY: 'auto', 
    }}
  >
     
     <IconButton  onClick={closePopup} sx={{
      color: '#939393', position: 'fixed', width: '46px', height: '46px',
      left:  { md: '85.5%', sm:'90%', xs: '80%'}, top:{ md:  '1%', sm:'12%', xs: '1.7%'}
    }} >
      <HighlightOffIcon sx={{ width: '36px', height: '36px',}} ></HighlightOffIcon>
    </IconButton>

    <Box sx={{ display: 'flex',flexDirection:'row',width:'100%',gap:{  md: '30px', sm: '30px', xs: '10px' }, height:{  md: '100%', sm: '400px', xs: '200px' } ,}}> 
    <Box
        sx={{ width:'70%', height:{ md: '434px', sm: '400px', xs: '200px' } ,display: 'flex',flexDirection:'row',maxWidth:'70%', maxHeight:{  md: '434px', sm: '400px', xs: '300px' } }}>  
        
      <img
            src={projects[selectedProject].image_list[0]}
            alt={`Image ${selectedProject}`}
            style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                cursor:'pointer',
                borderRadius: '4px',
              }}
              onClick={handleAddMoreImages}
          />
    </Box>
      <Box
        sx={{ width:'30%', height: '100%',display: 'flex',flexDirection:'column',gap:{  md: '30px', sm: '30px', xs: '10px' },
        position: 'relative', }}>
        
        <img
    src={projects[selectedProject].image_list.length < 2 ? NoImage : projects[selectedProject].image_list[1]}
    style={{
        width:'100%',
        height:  '100%',
        objectFit:'cover',
        maxHeight: '200px',
        maxWidth: '300px',
        cursor: 'pointer',
        borderRadius: '4px',      
       border:projects[selectedProject].image_list.length < 2 ?'1px solid #939393':'0px solid #939393'
    }}
    onClick={handleAddMoreImages}
/>

        <Box onClick={handleAddMoreImages}
              sx={{
                top: { md: '42%', sm: '54%', xs: '52%' },
                position: 'absolute',                   
                background: projects[selectedProject].image_list.length < 3 ?'none': 'rgba(0, 0, 0, 0.43)',
                color: '#fff',   
                width: '100%',
                height:{ md: '36.5%', sm:'46%', xs: '48%' } ,           
                borderRadius: '4px',
                fontSize: '14px',
              cursor:'pointer'
              }}
            >
                 <Typography sx={{
                                    fontFamily: 'poppins', fontSize: { md: '51px', sm: '51px', xs:  '32px' } ,
                                    lineHeight: '24px', fontWeight: '500', color: '#fff',textAlign:'center',paddingTop:{ md:  '30%', sm:'45%', xs: '48%' }
                                }} >   {projects[selectedProject].image_list.length > 3 ? `${projects[selectedProject].image_list.length - 3}+` : ''} </Typography>
           
            </Box>
        <img
          src={projects[selectedProject].image_list.length < 3 ? NoImage : projects[selectedProject].image_list[2]}
        
          style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                maxHeight:'200px',
                maxWidth:'300px',
                borderRadius: '4px',
                border:projects[selectedProject].image_list.length < 3 ?'1px solid #939393':'0px solid #939393'
              }}
          />
       
       </Box>
       </Box> 
       <Box sx={{ display: 'flex',flexDirection:'column',width:'100%',gap:'10px', height: '100%',
      marginTop:{ md:'-65px', sm: '25px', xs:'25px'}}}>
     
       <Typography sx={{
                                    fontFamily: 'poppins', fontSize: '20px',
                                    lineHeight: '24px', fontWeight: '600', color: '#000',
                                }} >{projects[selectedProject].project_name}</Typography>
                            <Box sx={{ display: 'flex',flexDirection:'row',width:'100%',gap:'30px' }}>
                                 <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
  <a href={`mailto:${projects[selectedProject].email_id}`} style={{ textDecoration: 'none' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <MailOutlineIcon sx={{ color: '#313131', fontSize: '20px' }}></MailOutlineIcon>
      <Typography
        sx={{
          fontFamily: 'poppins',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '500',
          color: '#313131',
          paddingLeft:'5px'
        }}
      >
        {projects[selectedProject].email_id}
      </Typography>
    </div>
  </a>
</Box>


<Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
  <a
    href={`tel:${projects[selectedProject].phone_number}`}
    style={{ textDecoration: 'none' }}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CallIcon sx={{ color: '#313131', fontSize: '20px' }}></CallIcon>
      <Typography
        sx={{
          fontFamily: 'poppins',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '500',
          color: '#313131',
          paddingLeft:'5px',
          textDecoration: 'none', // Remove underline
        }}
      >
        {projects[selectedProject].phone_number}
      </Typography>
    </div>
  </a>
</Box>


        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
  <a
    href={`https://api.whatsapp.com/send?phone=${projects[selectedProject].whatsapp_number}`}
    style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
  >
    <WhatsAppIcon sx={{ color: '#313131', fontSize: '20px' }}></WhatsAppIcon>
    <Typography
      sx={{
        fontFamily: 'poppins',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '500',
        color: '#313131',
        paddingLeft:'5px'
      }}
    >
      {projects[selectedProject].whatsapp_number}
    </Typography>
  </a>
</Box>



      </Box>

        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px',color: '#313131', }} >
        <span style={{ fontWeight: '500' }}>Project name :</span> <span style={{ fontWeight: '400' }}> {projects[selectedProject].project_name}</span>
        </Typography>
        
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Building type :<span style={{ fontWeight: '400' }}> {projects[selectedProject].building_type}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Builder :<span style={{ fontWeight: '400' }}> {projects[selectedProject].developer}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Address :<span style={{ fontWeight: '400' }}> {projects[selectedProject].address}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Nearest Intersection :<span style={{ fontWeight: '400' }}> {projects[selectedProject].nearest_intersection}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Pricing :<span style={{ fontWeight: '400' }}> {projects[selectedProject].pricing}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Occupancy :<span style={{ fontWeight: '400' }}> {projects[selectedProject].occupancy}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Sales status : <span style={{ fontWeight: '400' }}>{projects[selectedProject].sale_status}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Number of storeys :<span style={{ fontWeight: '400' }}>{projects[selectedProject].number_of_stories}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Number of units :<span style={{ fontWeight: '400' }}>{projects[selectedProject].number_of_unit}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Unit size :<span style={{ fontWeight: '400' }}> {projects[selectedProject].unit_size}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Suite Sizes :<span style={{ fontWeight: '400' }}> {projects[selectedProject].suite_size}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Maintenance Fees :<span style={{ fontWeight: '400' }}> {projects[selectedProject].maintenance_fee}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Deposit Structure :<span style={{ fontWeight: '400' }}> {projects[selectedProject].deposit_structure}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Incentives* :<span style={{ fontWeight: '400' }}> {projects[selectedProject].incentives}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131', }} >
        Suite Features :<span style={{ fontWeight: '400' }}> {projects[selectedProject].suite_features}</span>
        </Typography>
        <Typography sx={{fontFamily: 'poppins', fontSize: '16px',lineHeight: '24px', fontWeight: '500',color: '#313131',
        paddingBottom:'50px !important' }} >
        Building Amenities :<span style={{ fontWeight: '400' }}> {projects[selectedProject].building_amenities}</span>
        </Typography>       
  </Box>
  </Box>
</Box>
)}
   {showSlider && (
       <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '890px', sm: '650px', xs: '300px' },
           height: { md:'600px', sm: '400px', xs: '400px' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
           borderRadius: '12px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '50px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
          
         }}
       >
            <IconButton  onClick={closePopupAddMore} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '96%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
    <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
               
              }}
            >
              <Box
                sx={{
                    backgroundColor: '#313131',
                    width:{ md: '40px', sm: '30px', xs:  '20px'},
                    height:'56px',
                    borderRadius: '8px 0px 0px 8px',
                  justifyContent:'center',display:'flex',alignItems: 'center'
                }}
              >
                <IconButton
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={showPreviousImage}
                >
                  <ChevronLeftIcon style={{  color: 'white', width:'50px', height:'50px',textAlign:'center'  }} />
                </IconButton>
              </Box>
              <Box  sx={{
                 width: { md: '700px', sm: '500px', xs: '350px' }, height: { md: '500px', sm: '500px', xs: '350px' },alignItems:'center'
                }}>
              <img
                src={images[currentImageIndex]}
                alt={`Image ${currentImageIndex + 1}`}
                style={{
                  width: '100%', // Default width
                  height: '100%', // Default height
                }}
               
                         
              />
              </Box>
              <Box
                sx={{
                  backgroundColor: '#313131',
                  width:{ md: '40px', sm: '30px', xs:  '20px'},
                  height:'56px',
                  borderRadius: '0px 8px 8px 0px',
                justifyContent:'center',display:'flex',alignItems: 'center'
                }}
              >
                <IconButton
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={showNextImage}
                >
                  <ChevronRightIcon style={{ color: 'white', width:'50px', height:'50px',textAlign:'center' }} />
                </IconButton>
              </Box>
            </div>
            </Box>
        </Box>
      )}
       {signUpPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp}  />
      )}
       {signInPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} />
      )}
        </Grid>
    );

}

export default Projects;