import { Box, Button, Checkbox, FormControlLabel, Grid, OutlinedInput, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';


function ResetPassword() {
   
    const [oldPassword, setOldPassword] = useState('');    
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [changePasswordPopUp, setChangePasswordPopUp] = useState(false);

  
    const [showErrorNewPassword, setShowErrorNewPassword] = useState(false); 
    const [showErrorOldPassword, setShowErrorOldPassword] = useState(false);
    const [showErrorConfirmPassword, setShowErrorConfirmPassword] = useState(false);

    const [passwordChangeDetail, setPasswordChangeDetail] = useState({
       oldPassword: oldPassword,
       newPassword: newPassword,
       confirmPassword: confirmPassword
        
    })

    const [showOldPassword, setshowOldPassword] = useState(false);

    const togglePasswordVisibilityOldPassword = () => {
        setshowOldPassword(!showOldPassword);
      };
    const handleOldPassword = (event) => {
        setPasswordChangeDetail({
          ...passwordChangeDetail,
          oldPassword: event.target.value,
        });
        setShowErrorOldPassword(false)
      };
      
      const [showNewPassword, setshowNewPassword] = useState(false);

      const togglePasswordVisibilityNewPassword = () => {
        setshowNewPassword(!showNewPassword);
        };
      const handleNewPassword = (event) => {
          setPasswordChangeDetail({
            ...passwordChangeDetail,
            newPassword: event.target.value,
          });
          setShowErrorNewPassword(false)
        };
        
        const [showConfirmPassword, setshowConfirmPassword] = useState(false);

        const togglePasswordVisibilityConfirmPassword = () => {
            setshowConfirmPassword(!showConfirmPassword);
          };
        const handleConfirmPassword = (event) => {
            setPasswordChangeDetail({
              ...passwordChangeDetail,
              confirmPassword: event.target.value,
            });
            setShowErrorConfirmPassword(false)
          };

      const handlePasswordChangeClick = async () => {
        
      };   
    

   
    const closeChangePasswordPopUp = () => {
        setChangePasswordPopUp(false)
        
    };

  
    return (
        <Grid container sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
            padding: '30px'
        }}>
             {  <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '400px', sm: '400px', xs: '300px' },
           height: { md:'500px', sm: '500px', xs: '700px' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
           borderRadius: '8px 0px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '25px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflow:'auto',
          
         }}
       >
         <IconButton  onClick={closeChangePasswordPopUp} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>
          
            <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins',textAlign:'center',paddingTop:'25px'}}>Change password</Typography>
           
                <Box sx={{ display: 'flex', flexDirection: 'column',  gap: '16px', paddingTop:'20px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>Old password </Typography>
                        <TextField
                            value={passwordChangeDetail.oldPassword}
                            onChange={handleOldPassword}
                            type={showOldPassword ? 'text' : 'password'}
                            name="user_password"                          
                            error={showErrorOldPassword}
                            helperText={showErrorOldPassword ? 'Please enter your old password' : ''}                      
                            InputProps={{
                                sx: {
                                    height: '36px',  color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
                                  },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibilityOldPassword}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            color="primary"
                                        >
                                            {showOldPassword ? <VisibilityOutlinedIcon sx={{color:'#313131'}} /> : <VisibilityOffOutlinedIcon sx={{color:'#313131'}} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>New password </Typography>
                        <TextField
                            value={passwordChangeDetail.newPassword}
                            onChange={handleNewPassword}
                            type={showNewPassword ? 'text' : 'password'}
                            name="user_password"                          
                            error={showErrorNewPassword}
                            helperText={showErrorNewPassword ? 'Please enter your new password' : ''}                    
                            InputProps={{
                                sx: {
                                    height: '36px',  color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
                                  },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibilityNewPassword}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            color="primary"
                                        >
                                            {showNewPassword ? <VisibilityOutlinedIcon sx={{color:'#313131'}} /> : <VisibilityOffOutlinedIcon sx={{color:'#313131'}} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>Confirm password </Typography>
                        <TextField
                            value={passwordChangeDetail.confirmPassword}
                            onChange={handleConfirmPassword}
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="user_password"                          
                            error={showErrorConfirmPassword}
                            helperText={showErrorConfirmPassword ? 'Please enter your old password' : ''}                  
                            InputProps={{
                                sx: {
                                    height: '36px',  color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                     fontWeight: "400", borderRadius: '8px',background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
                                  },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibilityConfirmPassword}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            color="primary"
                                        >
                                            {showConfirmPassword ? <VisibilityOutlinedIcon sx={{color:'#313131'}} /> : <VisibilityOffOutlinedIcon sx={{color:'#313131'}} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

      
                  
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', paddingTop: '10px' }}>
                        <Button variant="contained" size="large" sx={{
                            width: '100%', height: '36px', color: 'white',borderRadius:'8px',
                            backgroundColor: '#0C276C', textTransform: 'capitalize', '&:hover': { bgcolor: "white", color: '#0C276C',border:'1px solid #0C276C ' }
                        }}
                            onClick={handlePasswordChangeClick}>Update password</Button>

                       
                          
                      
                    </Box>
                </Box>
            </Box>
            </Box>
            </Box>}
            <style>
        {`
          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 1px solid #0C276C !important;
            box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
          }
        `}
      </style>
        </Grid>
    )
}
export default ResetPassword;