import { Box, Button, Grid, OutlinedInput, Typography } from "@mui/material";
import axios from "axios";
import  React,  { useState, useEffect } from "react";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';
import Checkbox from '@mui/material/Checkbox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { API_URL } from "./constants";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Header from './NeholaHeader'
import HeaderMobile from './NeholaHeaderMobile';
import Footer from './NeholaFooter';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Select, MenuItem, InputLabel } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

function EditSignUp(props) {
  const login_id = localStorage.getItem('login_id')
  const [isEditing, setIsEditing] = useState(false);
  const [isMyProfile, setIsMyProfile] = useState(true);
    const [isTermsAndCondition, setIsTermsAndCondition] = useState(false);  
    let Uname;
    let builderid;
    const [expandedRow, setExpandedRow] = useState(null);

    const handleRowClick = (index) => {
      if (index === expandedRow) {
        setExpandedRow(null);
      } else {
        setExpandedRow(index);
      }
    };

    useEffect(() => {
      // Parse the URL to get query parameters
      const urlParams = new URLSearchParams(window.location.search);
      const isMyProfileParam = urlParams.get('isMyProfile');
  
      if (isMyProfileParam === 'false') {
        setIsMyProfile(false);
        setActiveTab(1)
      }
    }, []);
  
const [selectedBuilderid, setSelectedBuilderid] = useState(''); 
const [buildersNames, setBuildersNames] = useState([]);
const [builderName, setBuilderName] = useState(false);
const [email, setEmail] = useState('');
const [location, setLocation] = useState('');
const [userpassword, setUserPassword] = useState('');
const [businessName, setbusinessName] = useState('');
const [userphone, setUserphone] = useState('');
const [userWhatsApp, setUserWhatsApp] = useState('');
const [businessbuilderName, setbusinessbuilderName] = useState('');
const [showErrorbusinessName, setshowErrorbusinessName] = useState(false);
const [showErroremailId, setShowErroremailId] = useState(false);
const [showErrorphoneNumber, setShowErrorphoneNumber] = useState(false);
const [showErrorbuilderName, setShowErrorbuilderName] = useState(false);
const [showErrorPassword, setShowErrorPassword] = useState(false);
const [showErrorWhatsAppNumber, setShowErrorWhatsAppNumber] = useState(false);
const [showErrorlocation, setShowErrorlocation] = useState(false);
const [signUpDetails, setSignUpDetails] = useState({
    Name: businessName,
    builderName: businessbuilderName,
    password: userpassword,
    Phonenumber:userphone,
    whatsAppNumber:userWhatsApp,
    emailId: email,
    location: location
})
const handleInputEmailChange = (event) => {
    setSignUpDetails({
      ...signUpDetails,
      emailId: event.target.value,
    });
    setShowErroremailId(false)
  };
  const handleInputLocationChange = (event) => {
    setSignUpDetails({
      ...signUpDetails,
      location: event.target.value,
    });
    setShowErrorlocation(false)
  };
  const handleInputPhonenumberChange = (event) => {
    setSignUpDetails({
      ...signUpDetails,
      Phonenumber: event.target.value,
    });
    setShowErrorphoneNumber(false)
  };

  const handleInputPasswordChange = (event) => {
    setSignUpDetails({
      ...signUpDetails,
      password: event.target.value,
    });
    setShowErrorPassword(false)
  };
  const handleInputWhatsAppNumberChange = (event) => {
    setSignUpDetails({
      ...signUpDetails,
      whatsAppNumber: event.target.value,
    });
    setShowErrorWhatsAppNumber(false)
  };
  const handleChangebusinessUserName = (event) => {
    setSignUpDetails((prevState) => ({
      ...prevState,
      Name: event.target.value,
    }));
  setshowErrorbusinessName(false)
  }

const [showPassword, setShowPassword] = useState(false);

const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
//   async function fetchBuilderData() {

//     const listingagentid = localStorage.getItem('myprofilelisting_agent_id');

//    console.log("listingagentid",listingagentid)

//     try {
   
//         const listingagentid = localStorage.getItem('myprofilelisting_agent_id');
//           const response = await axios.post(`${API_URL}/all-builder-details`, {          
//             listingagentid: listingagentid,
//         });
//         console.log('show builder:', response.data);
//         if(response.data.length > 0){
//           setBuilders(response.data)
//         }
       
    
//   } 
//   catch (error) {
//       console.error('Error:', error.message);
//   }
 
// }

//   useEffect(() => {
   
//     fetchBuilderData();
//   }, []);

  async function fetchData() {

    const listingagentid = localStorage.getItem('myprofilelisting_agent_id');

   //console.log("listingagentid",listingagentid)

    try {
      const response = await axios.post(`${API_URL}/personal-details`, {          
        listingagentid: listingagentid,
    });
    setSignUpDetails({
      Name: response.data[0].user_name,
    //  builderName: response.data[0].builder_name,
      password: response.data[0].password,
      Phonenumber: response.data[0].phone_number,
      whatsAppNumber:response.data[0].whatsapp_number,
      emailId: response.data[0].email_id,
      location: response.data[0].location,
    }); 
 //  updateId=response.data[0].listing_agent_id
     // console.log("details",response.data)
      setBuilders(response.data)
   
   // console.log("images",response.data.image_list)
  } 
  catch (error) {
      console.error('Error:', error.message);
  }
 
}

  useEffect(() => {
   
    fetchData();
  }, []);

  // const handleBuilderChange = (event) => {   
  //   setBuilderDetails({
  //     ...builderDetails,
  //     builderName: event.target.value,
  //   });  
  // }; 
  const handleBuilderChange = (event, index, fieldName) => {
    const updatedBuilderDetails = [...builderDetails]; // Create a copy of the array
    updatedBuilderDetails[index][fieldName] = event.target.value; // Update the specific builder detail
    setBuilderDetails(updatedBuilderDetails); // Update the state
    const selectbuilder = buildersNames.find((builder) => builder.builder_name === event.target.value);
    setSelectedBuilderid(selectbuilder.builder_id)
    //console.log("bid",builderid)
  };

const handleSignUpButtonClick = async () => {
  setIsEditing(false)
    if (signUpDetails.Name === '') {
        setshowErrorbusinessName(true);
      } 
      // else if(signUpDetails.builderName === '') {
      //   setShowErrorbuilderName(true);
      // } 
      else if(signUpDetails.password === '') {
        setShowErrorPassword(true);
      } 
      else if(signUpDetails.Phonenumber === '') {
        setShowErrorphoneNumber(true);
      } 
      else if(signUpDetails.whatsAppNumber === '') {
        setShowErrorWhatsAppNumber(true);
      } 
      else if(signUpDetails.emailId === '') {
        setShowErroremailId(true);
      }  
      else if(signUpDetails.location === '') {
        setShowErrorlocation(true);
      } 
      else{ 
       
        const listingagentid = localStorage.getItem('myprofilelisting_agent_id');
          try {
            const response = await axios.post(`${API_URL}/personal-details`, {          
              listingagentid: listingagentid,
          });
         
            const username= response.data[0].user_name
         //   const builderName =  response.data[0].builder_name
            const password =  response.data[0].password
            const phone= response.data[0].phone_number
            const whatsapp = response.data[0].whatsapp_number
            const email= response.data[0].email_id
            const location= response.data[0].location             
            const updateId = response.data[0].listing_agent_id
            const registerdate = response.data[0].register_date           
            const loginstatus =  response.data[0].login_status
            const login_id1 =  response.data[0].login_id
            Uname = response.data[0].user_name ? response.data[0].user_name.split(' ')[0] : '';
           // console.log("details",response.data)
            const response1 = await axios.post(`${API_URL}/insert-listing-agent-profile-audit`, {
              listingagentid: updateId,
              username: username,                
              password: password,      
              phone: phone,
              email: email,
              location: location,
              registerdate:registerdate, 
              loginstatus:loginstatus,
              whatsapp: whatsapp,  
              login_id: login_id1,
          });

         // console.log('Insert response:', response.data); 
          const username1 = signUpDetails.Name;     
          const password1 = signUpDetails.password;
          const phone1 = signUpDetails.Phonenumber;
          const email1 = signUpDetails.emailId;
          const location1 = signUpDetails.location;
          const whatsapp1 = signUpDetails.whatsAppNumber;

          const response2 = await axios.post(`${API_URL}/update-listing-agent-profile`, {
            listingagentid: updateId,
            username: username1,             
            password: password1,      
            phone: phone1,
            email: email1,
            location: location1,         
            whatsapp: whatsapp1,
            login_id: login_id,
         
        });    
          
        //console.log('Insert response:', response2.data); 
         // console.log("images",response.data.image_list)
        } 
        catch (error) {
            console.error('Error:', error.message);
        }
        
        window.location.href = '/edit-profile'
        

      }

};
const [builderDetails, setBuilderDetails] = useState([
    {
      builderName: '',
      builderLocation: '',
      sponsorName: '',
      sponsorEmail: '',
      sponsorPhone: '',
    },
  ]);

  const [errorMessages, setErrorMessages] = useState([{}]);

  const handleInputChange = (event, index, field) => {
    const updatedBuilderDetails = [...builderDetails];
    updatedBuilderDetails[index] = {
      ...updatedBuilderDetails[index],
      [field]: event.target.value,
    };

    // Clear the error message for the current field
    const updatedErrorMessages = [...errorMessages];
    updatedErrorMessages[index] = {
      ...updatedErrorMessages[index],
      [field]: '',
    };

    setBuilderDetails(updatedBuilderDetails);
    setErrorMessages(updatedErrorMessages);
  };

  const validateInput = (index) => {
    const builder = builderDetails[index];
    const errors = {};

    if (!builder.builderName) {
      errors.builderName = 'Builders name is required';
    }

   else if (!builder.builderLocation) {
      errors.builderLocation = 'Builder location is required';
    }

    else if (!builder.sponsorName) {
      errors.sponsorName = 'Sponsor name is required';
    }

    else if (!builder.sponsorEmail) {
      errors.sponsorEmail = 'Sponsor email is required';
    }

    else if (!builder.sponsorPhone) {
      errors.sponsorPhone = 'Sponsor phone number is required';
    }

    const updatedErrorMessages = [...errorMessages];
    updatedErrorMessages[index] = errors;
    setErrorMessages(updatedErrorMessages);
  };
  const [builders, setBuilders] = useState([]);
  const handleAddBuilderButtonClick = async () => {
    const lastIndex = builderDetails.length - 1;
    validateInput(lastIndex);

    const lastBuilderErrors = errorMessages[lastIndex];

    if (
      lastBuilderErrors.builderName ||
      lastBuilderErrors.builderLocation ||
      lastBuilderErrors.sponsorName ||
      lastBuilderErrors.sponsorEmail ||
      lastBuilderErrors.sponsorPhone
    ) {
     
    } else {
        const allBuilderDetails = builderDetails.map(builder => ({
           
            listingagentid: localStorage.getItem('myprofilelisting_agent_id'),
            buildername: builder.builderName,
            builderlocation: builder.builderLocation,
            sponsorname: builder.sponsorName,
            sponsoremail: builder.sponsorEmail,
            sponsorphone: builder.sponsorPhone,
          }));
        
          try {
            // Send a single request to insert all builder details
            const response = await axios.post(`${API_URL}/insert-builders`, {
              allBuilderDetails,
              builderid : selectedBuilderid,
            });
           // console.log('Insert response builder:', response.data);
           
          } catch (error) {
            console.error('Error:', error.message);
          }
        }
       setIsAddBuilder(false)
       fetchData()
        };

  const handleAddMoreClick = () => {
    const lastIndex = builderDetails.length - 1;
    const lastBuilderErrors = errorMessages[lastIndex];

    if (
      lastBuilderErrors.builderName ||
      lastBuilderErrors.builderLocation ||
      lastBuilderErrors.sponsorName ||
      lastBuilderErrors.sponsorEmail ||
      lastBuilderErrors.sponsorPhone
    ) {
      
      return;
    }

    setBuilderDetails([
      ...builderDetails,
      {
        builderName: '',
        builderLocation: '',
        sponsorName: '',
        sponsorEmail: '',
        sponsorPhone: '',
      },
    ]);
    setErrorMessages([...errorMessages, {}]);
  };
const [isAddBuilder, setIsAddBuilder] = useState(false);

const handleAddBuilderPopUpClick = () => {
  setIsAddBuilder(true)
  setBuilderDetails([
    {
      builderName: '',
      builderLocation: '',
      sponsorName: '',
      sponsorEmail: '',
      sponsorPhone: '',
    },
  ]);
    
   
   }
   const closePopupbuilder = () => {
    setIsAddBuilder(false)
}
const handleTermsClick = () => {
 setIsTermsAndCondition(true)

}

const closePopupTerm = () => {
    setIsTermsAndCondition(false)
}
const [isChecked, setIsChecked] = useState(false);

const handleCheckboxChange = (event) => {
 // console.log('Checkbox changed:', event.target.checked);
  setIsChecked(event.target.checked);
};
const handleSignoutClick = () => {
 
  localStorage.removeItem('listing_agent_id'); 
  localStorage.removeItem('myprofilelisting_agent_id'); 
  localStorage.removeItem('builder'); 
  localStorage.removeItem('builder_id');
  localStorage.removeItem('user_name');
    window.location.href = '/';
  
  };
 
  async function fetchBuilderName() {
    
    try {
        const response = await axios.get(`${API_URL}/builder-names`); 
        setBuildersNames(response.data);         
       // console.log("BuilderName",response.data)
      
    } 
    catch (error) {
        console.error('Error:', error.message);
    }
  }

  useEffect(() => {
    fetchBuilderName();
  }, []);
  const [activeTab, setActiveTab] = useState(0);
    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue); // Update the active tab when a tab is clicked
    };
    const handleEditClick = () => {
      setIsEditing(true);
    };

    const handleMyProfile = () => {
      setIsMyProfile(true);
    };

    const handleListOfBuilders = () => {
      setIsMyProfile(false);
    };

 return (
    <Grid
    container
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
     
    }}
  >
       <Box sx={{
              height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
              padding: 0, display: { md: 'flex', sm: 'none', xs: 'none' }
          }}>
              <Header ></Header>
          </Box>
          <Box sx={{
              height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
              padding: 0, display: { md: 'none', sm: 'flex', xs: 'flex' }
          }}>
              <HeaderMobile ></HeaderMobile>
          </Box>
         
                <Box
     sx={{
        display: 'flex', flexDirection: 'row', width:'100%',justifyContent:'center'   }}>
   <Box
     sx={{
        display: 'flex', flexDirection: 'column',   width: '100%',  height:'100%',  justifyContent:'center' ,
      
       background: '#F9F9F9',
       borderRadius: '8px 8px 8px 8px',
     margin:'50px',
       padding: { md: '50px', sm: '30px', xs: '20px' },
       alignItems: 'center',
      
     }}
   >


<Box sx={{
                width: '100%', display: 'flex', flexDirection: 'row', 
                paddingLeft: { md: '6%', sm: '5%', xs: '0%' }, paddingRight: { md: '5%', sm: '5%', xs: '4%' }, 
                justifyContent: { xs: 'center', sm: 'left', md: 'left' }, 
                alignItems: { xs: 'center', sm: 'flex-start', md: 'flex-start' } 
            }}>
               
              
               <Box sx={{width:{ md: '85%', sm:  '85%', xs: "83%" } , display: 'flex', flexDirection: 'row',
                gap:{ md: '35px', sm:  '35px', xs: "0px" }}}>
               <Tabs
    value={activeTab}
    onChange={handleTabChange}
   
    textColor="#313131"
    indicatorColor="#313131"
  >
    <Tab
    onClick= {handleMyProfile}
      sx={{
        fontFamily: "poppins",
       fontSize: { md: '16px', sm: '14px',xs:'12px' },
        fontWeight: activeTab === 0 ? "600" : "400",
        color: '#313131', // Set the color for all tab labels to orange
        textTransform: 'none',
        borderBottom: activeTab === 0 ? '1px solid #313131' : 'none',
        paddingLeft: isMyProfile ? "0px":{ md:'0px', sm:"95px", xs: "0px" }
      }}
      label="My profile"
    />
    <Tab
    onClick= {handleListOfBuilders}
      sx={{
        fontFamily: "poppins",
       fontSize: { md: '16px', sm: '14px',xs:'12px' },
        fontWeight: activeTab === 1 ? "600" : "400",
        color: '#313131', // Set the color for all tab labels to orange
        textTransform: 'none',
        borderBottom: activeTab === 1 ? '1px solid #313131' : 'none',
        
      }}
      label="List of builders"
    /> 
    </Tabs>
                    </Box>
                    <Box sx={{width:{ md:'25%', sm:  '25%', xs: "15%" } , display: 'flex', flexDirection: 'row', height: '100%',gap:'20px' }}>
                    { isMyProfile && <Button  onClick={handleEditClick}  variant="contained" sx={{
                        color: "white", height: '30px',  width:{ md:'158px', sm:'158px', xs: '158px' }, borderRadius: "12px",
                         background: '#504FA4', border: "1px solid #504FA4",
                        fontFamily: "Roboto", textTransform: "none", fontStyle: "normal", fontWeight: "500", marginLeft: 'auto',
                         fontSize:{ md: "14px", sm: "14px", xs: "12px" } ,
                         '&:hover': { bgcolor: "#3A3990", color: '#fff',border: "1px solid #3A3990" }
                       
                    }}>
                    Edit
                    </Button> }
                    </Box>
                   
            </Box>









    
 { isMyProfile &&  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px',width:{ md: '520px', sm: '450px', xs: '100%' },paddingTop:'20px'}}>
         
        <div style={{ display: 'flex', flexDirection:'row',alignItems:'left',justifyContent:'left',
        alignContent:'left',gap:'10px'}}>
           
            <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins'}}>{signUpDetails.Name}</Typography>
         </div>
            <Box sx={{ display: 'flex', flexDirection: 'column',  gap: '16px',paddingTop:'20px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%',gap:'5px' }}>
      <Typography sx={{
        fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
      }}>
        Business username<span style={{ color: 'red' }}>*</span>
      </Typography>
      <TextField variant="outlined"  value={signUpDetails.Name}
        onChange={(event) => { handleChangebusinessUserName(event) }}
        error={showErrorbusinessName}
        helperText={showErrorbusinessName ? 'Please enter your business user name' : ''}
        sx={{ width: "100%",  fontSize: '14px', color: '#212427', fontFamily: 'Poppins', lineHeight: "18px", fontWeight: "400", }}
        InputProps={{
          sx: {
            height: '36px',  fontSize: '14px', color: " #313131",fontFamily: 'Poppins', lineHeight: "18px", fontWeight: "400",
            borderRadius:'8px',background:'#fff',padding:'10px, 14px, 10px, 14px',pointerEvents: isEditing ? 'auto': 'none'
          },

        }} />
    </Box>
              
                {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500 }}>
                        Builders name<span style={{ color: 'red' }}>*</span></Typography>
                    <TextField
                        value={signUpDetails.builderName}
                        onChange={handleBuilderNameChange}
                        type="text"
                        name="user_name"                           
                        error={showErrorbuilderName}
                        helperText={showErrorbuilderName ? 'Please enter your builders name' : ''}
                        InputProps={{
                            sx: {
                                height: '36px', color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                 fontWeight: "400", borderRadius: '8px', background:'#F9F9F9',padding:'10px, 14px, 10px, 14px'
                              },
                        }}
                    />
                </Box> */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>
                        Create password<span style={{ color: 'red' }}>*</span> </Typography>
                    <TextField
                        value={signUpDetails.password}
                        onChange={handleInputPasswordChange}
                        type={showPassword ? 'text' : 'password'}
                        name="user_password"                          
                        error={showErrorPassword}
                        helperText={showErrorPassword ? 'Please enter your password' : ''}                      
                        InputProps={{
                            sx: {
                                height: '36px',  color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                 fontWeight: "400", borderRadius: '8px',background:'#fff',padding:'10px, 14px, 10px, 14px',pointerEvents: isEditing ? 'auto': 'none'
                              },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={togglePasswordVisibility}
                                        edge="end"
                                        aria-label="toggle password visibility"
                                        color="primary"
                                    >
                                        {showPassword ? <VisibilityOutlinedIcon sx={{color:'#313131'}} /> : <VisibilityOffOutlinedIcon sx={{color:'#313131'}} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>
                        Business phone number<span style={{ color: 'red' }}>*</span> </Typography>
                    <TextField
                        value={signUpDetails.Phonenumber}
                     //   onChange={handleInputPhonenumberChange}
                        type="text"
                        name="user_phone"                           
                        InputProps={{
                            sx: {
                                height: '36px',color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                 fontWeight: "400", borderRadius: '8px',background:'#fff',padding:'10px, 14px, 10px, 14px',
                                 pointerEvents: 'none',
                              },
                        }}
                        error={showErrorphoneNumber}
                        helperText={showErrorphoneNumber ? 'Phonenumber is mandatory' : ''}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>
                    Business whatsapp number<span style={{ color: 'red' }}>*</span> </Typography>
                    <TextField
                        value={signUpDetails.whatsAppNumber}
                     //   onChange={handleInputPhonenumberChange}
                        type="text"
                        name="user_phone"                           
                        InputProps={{
                            sx: {
                                height: '36px',color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                 fontWeight: "400", borderRadius: '8px',background:'#fff',padding:'10px, 14px, 10px, 14px',
                                 pointerEvents: 'none',
                              },
                        }}
                        error={showErrorWhatsAppNumber}
                        helperText={showErrorWhatsAppNumber ? 'WhataApp Number is mandatory' : ''}
                    />
                </Box>
   <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>
                        Business email id<span style={{ color: 'red' }}>*</span> </Typography>
                    <TextField
                        value={signUpDetails.emailId}
                      //  onChange={handleInputEmailChange}
                        type="text"
                        name="user_email"                           
                        error={showErroremailId}
                        helperText={showErroremailId ? 'Please enter your email' : ''}
                        InputProps={{
                            sx: {
                                height: '36px',color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                 fontWeight: "400", borderRadius: '8px',background:'#fff', padding:'10px, 14px, 10px, 14px',
                                 pointerEvents: 'none',
                              },
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500  }}>
                        Location<span style={{ color: 'red' }}>*</span> </Typography>
                    <TextField
                        value={signUpDetails.location}
                        onChange={handleInputLocationChange}
                        type="text"
                        name="user_email"                           
                        error={showErrorlocation}
                        helperText={showErrorlocation ? 'Please enter your location' : ''}
                        InputProps={{
                            sx: {
                                height: '36px',color: " #313131", fontFamily: "Poppins", fontSize: "12px", lineHeight: "18px",
                                 fontWeight: "400", borderRadius: '8px',background:'#fff', padding:'10px, 14px, 10px, 14px',pointerEvents: isEditing ? 'auto': 'none'
                              },
                        }}
                    />
                </Box>
                <Box sx={{ display:  'flex', flexDirection: 'row',alignItems: 'center',width:'100%' }}>
  <Checkbox
    checked={isChecked}
    onChange={(event) => handleCheckboxChange(event)}
    sx={{
      color: '#313131', // Change the checked color here
      background:'none' ,
      width:'2px',
      height:'2px',
      marginRight:'10px',
      '&.Mui-checked': {
        color: '#504FA4', // Change the checked color here as well
       background:'none' ,
       width:'2px',
       height:'2px'
      },
     
    }}
  />
  {/* <Typography sx={{ fontSize: '14px', color: '#313131', fontFamily: 'poppins' }}>
    I agree to the terms & condition
  </Typography> */}
   <>
   <Typography sx={{ fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: '#313131', fontFamily: 'poppins',paddingRight:'8px' }}>
    I agree to the{' '}
  </Typography>
            <Button onClick={handleTermsClick} variant="text"
             sx={{ background: 'none',cursor: 'pointer', textDecoration: 'none',textTransform:'none', color:'#504FA4',
              fontSize:  { md: '14px', sm: '14px',xs:'12px' },  fontFamily: 'poppins',padding:'0' }}>
              terms & condition
            </Button>
          </>
      
</Box>

                <Box sx={{display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'flex-end', gap: '15px',width:'100%',alignContent:'right',}}>
                    <Button variant="contained"  disabled={!isChecked}  size="large" sx={{
                        width: '160px', height: '36px', color: 'white',borderRadius:'8px',
                        backgroundColor: '#F9B339', textTransform:'none',alignContent:'right',border:isChecked? '1px solid #F9B339': "1px solid #F9B339",
                        '&:hover': { bgcolor: "#FF8C00", color: '#fff',border: "1px solid #FF8C00" },
                    }}
                        onClick={handleSignUpButtonClick}> Save changes</Button>

            
                </Box>
            </Box>



            {/* <TableContainer sx={{ marginTop: '30px' }} component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{ backgroundColor: 'White' }}>
            <TableCell  sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Builder Name</TableCell>
            <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {builders.map((row, index) => (
            <React.Fragment key={row.listing_agent_id}>
              <TableRow sx={{ height:'3px !important' }}>
  <TableCell sx={{ backgroundColor: '#F3F3F3', height: '3px !important' }} colSpan={3}>
    
  </TableCell>
</TableRow>


              <TableRow  sx={{ borderBottom: '1px solid #DCDADA' }}
                key={row.listing_agent_id}              
              >
                <TableCell 
                  sx={{
                    fontFamily: 'poppins',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: '400',
                    color: '#313131',
                    textAlign: 'center',
                   
                    //padding: { md: '15px', sm: '10px' },
                  }}
                >
                  {row.builder_name}
                </TableCell>
                <TableCell 
                  sx={{
                    fontFamily: 'poppins',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: '400',
                    color: '#313131',
                    textAlign: 'center',
                   // padding: { md: '15px', sm: '10px' },
                  }}
                >
                  {row.profile_status}
                </TableCell>
                </TableRow>
                <TableRow  >
                 
                <TableCell sx={{ textAlign: 'center', justifyContent:'center',
                    alignContent:'center'}} colSpan={3}>
                  <Button variant="outlined" sx={{
                    textTransform: 'none',
                    border: '1px solid #626262',
                    color: '#626262',
                    background: '#fff',
                    borderRadius: '8px',
                    width: '120px',
                    
                  }}
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleRowClick(index)}
                    endIcon={
                      expandedRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                    }
                  >
                    {expandedRow === index ? 'Less details' : 'More details'}
                  </Button>
                </TableCell>
              </TableRow>
              
              {expandedRow === index && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Box sx={{
                      display: 'flex', width: '100%', flexDirection: 'column', border: '1px solid #DCDADA', marginBottom: '20px',
                      gap: '10px', padding: '20px'
                    }}>
                      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                        <Typography sx={{
                          fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131',
                        }}>
                          Builder Details
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                          <Typography sx={{
                            fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                          }}>
                            Builder name : {row.builder_name}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                          <Typography sx={{
                            fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                          }}>
                            Builder location : {row.builder_location}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                          <Typography sx={{
                            fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                          }}>
                            Sponsor name : {row.sponsor_name}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                          <Typography sx={{
                            fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                          }}>
                            Sponsor email id : {row.sponsor_email}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                          <Typography sx={{
                            fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                          }}>
                            Sponsor phone number : {row.sponsor_phone_number}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width:'100%',paddingTop:'30px'}}>
        <Button variant="contained" sx={{
           justifyContent:'center',width:'100%',
            height: '36px',
            color: 'white',
            borderRadius: '8px',
            background: '#EB5648', border: "1px solid #EB5648",
            textTransform: 'none',
            '&:hover': { bgcolor: "#D9210E", color: '#fff',border: "1px solid #D9210E", },
          }}
            onClick={handleAddBuilderPopUpClick}>Add builder</Button>
        </Box> */}


</Box>}
{ !isMyProfile &&  <Box sx={{ display:{ md: 'flex', sm: 'none', xs: 'none' } , flexDirection: 'column', gap: '15px',width:'1210px',paddingTop:'20px'}}>
       
       <TableContainer sx={{ marginTop: '30px' }} component={Paper}>
  <Table aria-label="collapsible table">
    <TableHead>
      <TableRow sx={{ backgroundColor: 'White' }}>
        <TableCell  sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Builder name</TableCell>
        <TableCell  sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Builders location</TableCell>
        <TableCell  sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Sponsor name</TableCell>
        <TableCell  sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Sponsor email</TableCell>
        <TableCell  sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Sponsor phone number</TableCell>
        <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Status</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {builders.map((row, index) => (
        <React.Fragment key={row.listing_agent_id}>
          <TableRow sx={{ height:'3px !important' }}>
<TableCell sx={{ backgroundColor: '#F3F3F3', height: '3px !important' }} colSpan={7}>

</TableCell>
</TableRow>
          <TableRow  sx={{ borderBottom: '1px solid #DCDADA' }}
            key={row.listing_agent_id}              
          >
            <TableCell 
              sx={{
                fontFamily: 'poppins',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '400',
                color: '#313131',
                textAlign: 'center',
               
                //padding: { md: '15px', sm: '10px' },
              }}
            >
              {row.builder_name}
            </TableCell>
            <TableCell 
              sx={{
                fontFamily: 'poppins',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '400',
                color: '#313131',
                textAlign: 'center',
               
                //padding: { md: '15px', sm: '10px' },
              }}
            >
             {row.builder_location}
            </TableCell>
            <TableCell 
              sx={{
                fontFamily: 'poppins',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '400',
                color: '#313131',
                textAlign: 'center',
               
                //padding: { md: '15px', sm: '10px' },
              }}
            >
               {row.sponsor_name}
            </TableCell>
            <TableCell 
              sx={{
                fontFamily: 'poppins',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '400',
                color: '#313131',
                textAlign: 'center',
               
                //padding: { md: '15px', sm: '10px' },
              }}
            >
             {row.sponsor_email}
            </TableCell>
            <TableCell 
              sx={{
                fontFamily: 'poppins',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '400',
                color: '#313131',
                textAlign: 'center',
               
                //padding: { md: '15px', sm: '10px' },
              }}
            >
             {row.sponsor_phone_number}
            </TableCell>
            <TableCell 
              sx={{
                fontFamily: 'poppins',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '400',
                color: '#313131',
                textAlign: 'center',
               // padding: { md: '15px', sm: '10px' },
              }}
            >
              {row.profile_status}
            </TableCell>
            </TableRow>
            <TableRow  >
             
            {/* <TableCell sx={{ textAlign: 'center', justifyContent:'center',
                alignContent:'center'}} colSpan={3}>
              <Button variant="outlined" sx={{
                textTransform: 'none',
                border: '1px solid #626262',
                color: '#626262',
                background: '#fff',
                borderRadius: '8px',
                width: '120px',
                
              }}
                aria-label="expand row"
                size="small"
                onClick={() => handleRowClick(index)}
                endIcon={
                  expandedRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                }
              >
                {expandedRow === index ? 'Less details' : 'More details'}
              </Button>
            </TableCell> */}
          </TableRow>
          
          {expandedRow === index && (
            <TableRow>
              <TableCell colSpan={3}>
                <Box sx={{
                  display: 'flex', width: '100%', flexDirection: 'column', border: '1px solid #DCDADA', marginBottom: '20px',
                  gap: '10px', padding: '20px'
                }}>
                  <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                    <Typography sx={{
                      fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131',
                    }}>
                      Builder Details
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                      <Typography sx={{
                        fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                      }}>
                        Builder name : {row.builder_name}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                      <Typography sx={{
                        fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                      }}>
                        Builder location : {row.builder_location}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                      <Typography sx={{
                        fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                      }}>
                        Sponsor name : {row.sponsor_name}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                      <Typography sx={{
                        fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                      }}>
                        Sponsor email id : {row.sponsor_email}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                      <Typography sx={{
                        fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                      }}>
                        Sponsor phone number : {row.sponsor_phone_number}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
            
          )}
        </React.Fragment>
      ))}
    </TableBody>
  </Table>
</TableContainer>
</Box>}


{ !isMyProfile &&  <Box sx={{ display:{ md: 'none', sm: 'flex', xs: 'flex' } , flexDirection: 'column', gap: '15px',
paddingTop:'20px',width:'320px'}}>
  <TableContainer sx={{ marginTop: '30px' }} component={Paper}>
  <Table aria-label="collapsible table">
    <TableHead>
      <TableRow sx={{ backgroundColor: 'White' }}>
        <TableCell  sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Builder Name</TableCell>
        <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Status</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {builders.map((row, index) => (
        <React.Fragment key={row.listing_agent_id}>
          <TableRow sx={{ height:'3px !important' }}>
<TableCell sx={{ backgroundColor: '#F3F3F3', height: '3px !important' }} colSpan={3}>

</TableCell>
</TableRow>


          <TableRow  sx={{ borderBottom: '1px solid #DCDADA' }}
            key={row.listing_agent_id}              
          >
            <TableCell 
              sx={{
                fontFamily: 'poppins',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '400',
                color: '#313131',
                textAlign: 'center',
               
                //padding: { md: '15px', sm: '10px' },
              }}
            >
              {row.builder_name}
            </TableCell>
            <TableCell 
              sx={{
                fontFamily: 'poppins',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '400',
                color: '#313131',
                textAlign: 'center',
               // padding: { md: '15px', sm: '10px' },
              }}
            >
              {row.profile_status}
            </TableCell>
            </TableRow>
            <TableRow  >
             
            <TableCell sx={{ textAlign: 'center', justifyContent:'center',
                alignContent:'center'}} colSpan={3}>
              <Button variant="outlined" sx={{
                textTransform: 'none',
                border: '1px solid #626262',
                color: '#626262',
                background: '#fff',
                borderRadius: '8px',
                width: '120px',
                
              }}
                aria-label="expand row"
                size="small"
                onClick={() => handleRowClick(index)}
                endIcon={
                  expandedRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                }
              >
                {expandedRow === index ? 'Less details' : 'More details'}
              </Button>
            </TableCell>
          </TableRow>
          
          {expandedRow === index && (
            <TableRow>
              <TableCell colSpan={3}>
                <Box sx={{
                  display: 'flex', width: '100%', flexDirection: 'column', border: '1px solid #DCDADA', marginBottom: '20px',
                  gap: '10px', padding: '20px'
                }}>
                  <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                    <Typography sx={{
                      fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131',
                    }}>
                      Builder Details
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                      <Typography sx={{
                        fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                      }}>
                        Builder name : {row.builder_name}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                      <Typography sx={{
                        fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                      }}>
                        Builder location : {row.builder_location}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                      <Typography sx={{
                        fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                      }}>
                        Sponsor name : {row.sponsor_name}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                      <Typography sx={{
                        fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                      }}>
                        Sponsor email id : {row.sponsor_email}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                      <Typography sx={{
                        fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500
                      }}>
                        Sponsor phone number : {row.sponsor_phone_number}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
            
          )}
        </React.Fragment>
      ))}
    </TableBody>
  </Table>
</TableContainer>
</Box>
}
        </Box></Box>

      


        {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width:'10%',paddingTop:'30px',paddingRight:'20px'}}>
        <Button variant="contained" sx={{
           justifyContent:'center',width:'100%',
            height: '36px',
            color: 'white',
            borderRadius: '8px',
            background: '#EB5648', border: "1px solid #EB5648",
            textTransform: 'none',
            '&:hover': { bgcolor: "#D9210E", color: '#fff',border: "1px solid #D9210E", },
          }}
            onClick={handleSignoutClick}>Sign out</Button>
        </Box> */}
       
        { isTermsAndCondition &&   <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '400px', sm: '450px', xs: '320px' },
           height: { md:'730px', sm: '770px', xs: '700px' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
           borderRadius: '8px 0px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '36px 28px 36px 28px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflow:'auto',
          
         }}
       >
         <IconButton  onClick={closePopupTerm} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
 <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px',}}>
          <Typography sx={{ fontSize: '18px', color: '#313131', fontFamily: 'poppins',fontWeight:600 }}>
          Listing Agent Terms & Conditions
  </Typography> 
 
                       
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Eligibility</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>By using our platform to add property listings, you agree that you are a duly authorized real estate agent or property owner, and you have the legal authority to represent the property being listed.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Accurate Information</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You are responsible for providing accurate and up-to-date information about the properties you list on our platform, including but not limited to property details, price, availability, and images.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You agree not to engage in deceptive or fraudulent practices, such as misrepresenting property information.
                         </Typography>

                         </li>  
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Ownership and Authorization</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You confirm that you have the legal authority and consent of the property owner to list the property on RateWaves.com.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Compliance with Laws</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You agree to comply with all applicable laws and regulations, including fair housing and real estate disclosure requirements. You are responsible for ensuring that your listings do not violate any laws or regulations.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Listing Agent Conduct</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You will conduct yourself in a professional and ethical manner when using RateWaves.com. You agree not to engage in abusive, discriminatory, or harassing behavior towards other listing agent or our staff.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Removal of Listings</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You agree that we (Ratewaves.com) reserve the right to remove or modify any listings from RateWaves.com. 
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Copyright and Intellectual Property</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You confirm that you have the rights to use any images, text, or other materials you upload with your listings.
                        </Typography>

                        </li>     
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>You agree not to infringe on the copyright or intellectual property rights of others.
                        </Typography>

                        </li>                     
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Changes to Terms & Conditions</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>We (Ratewaves.com) may update or change these terms and conditions at any time. It is your responsibility to review them periodically.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Termination</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>We(Ratewaves.com) reserve the right to suspend or terminate your account and access to our site.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Limitation of Liability</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves.com shall not be held liable for any direct or indirect damages, losses, or claims arising from technical issues, including but not limited to lost profits, data loss, or business interruption.
                        </Typography>

                        </li>      
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves.com makes every reasonable effort to protect the security and confidentiality of listing data. However, Ratewaves.com shall not be held liable for any direct or indirect damages, losses, or claims arising including but not limited to lost profits, data loss, or business interruption from any data breaches or unauthorized access to listing information, including personal data, financial information, or any other data stored on our platform. Data breaches can occur due to various factors, including external cyber attacks, vulnerabilities in third-party systems, or listing agent  negligence. While we implement robust security measures to safeguard your data, we cannot guarantee that breaches will not occur. Listing agents are encouraged to take additional steps to protect their data, such as using strong, unique passwords, enabling two-factor authentication, and regularly reviewing and updating their account settings. In the event of a data breach, Ratewaves.com will make every effort to mitigate the impact, investigate the breach, and notify affected listing agents in accordance with applicable data protection laws and regulations.
                        </Typography>

                        </li>   
                       <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Ratewaves.com makes every reasonable effort to protect the security and confidentiality of listing data. However, Ratewaves.com shall not be held liable for any direct or indirect damages, losses, or claims arising including but not limited to lost profits, data loss, or business interruption from any data breaches or unauthorized access to listing information, including personal data, financial information, or any other data stored on our platform. Data breaches can occur due to various factors, including external cyber attacks, vulnerabilities in third-party systems, or listing agent  negligence. While we implement robust security measures to safeguard your data, we cannot guarantee that breaches will not occur. Listing agents are encouraged to take additional steps to protect their data, such as using strong, unique passwords, enabling two-factor authentication, and regularly reviewing and updating their account settings. In the event of a data breach, Ratewaves.com will make every effort to mitigate the impact, investigate the breach, and notify affected listing agents in accordance with applicable data protection laws and regulations.
                    </Typography>

                    </li>   
                    <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Ratewaves.com shall not be held liable for any direct or indirect damages, losses, or claims resulting during Planned Maintenance and Downtime, including but not limited to financial loss, data loss, or business interruption.
                    </Typography>

                    

                        </li>   

                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>No Guarantee of Perfection</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>While we are committed to providing a high-quality service, Ratewaves.com does not guarantee that our technical systems and platforms will be entirely free from technical issues or disruptions.
                        </Typography>

                        </li>      
                         <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Factors beyond our control, such as internet infrastructure, third-party service providers, and force majeure events, may contribute to technical issues.
                    </Typography>                   

                        </li>      
                        <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Ratewaves.com disclaims any warranty, whether expressed or implied, regarding the accuracy, completeness, or suitability of the information and services provided.
                    </Typography>                   

                        </li>       
                        <li>
                        <Typography sx={{
                        fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                        lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                    }}>Users acknowledge that Ratewaves.com makes no warranties or representations regarding the error-free nature of our services.
                    </Typography>                   

                        </li>          
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Technical Issue Reporting</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Customers are encouraged to report any technical issues, disruptions, or outages they encounter when using our services. Reporting should be made through our designated support channels.
                        </Typography>

                        </li>    
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves.com will make every reasonable effort to promptly acknowledge, investigate, and resolve reported technical issues.
                        </Typography>

                        </li> 
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>For technical issue reporting, questions, or concerns, please contact support@ratewaves.com 
                        </Typography>                   
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Contact Information</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '14px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>By using our platform to add property listings, you agree that you are a duly authorized real estate agent or property owner, and you have the legal authority to represent the property being listed.
                        </Typography>

                        </li>                       
                        </ul>  
                        </ul> 

          </Box> 
      </Box> </Box>     }
      { isAddBuilder &&   <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '450px', sm: '450px', xs: '320px' },
           height: { md:'650px', sm: '770px', xs: '700px' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
           borderRadius: '8px 0px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '50px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflow:'auto',
          
         }}
       >
         <IconButton  onClick={closePopupbuilder} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px'}}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', alignContent: 'left' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 600, color: 'black', fontFamily: 'Poppins' }}>
              Add your builders details
            </Typography>
          </div>
          {builderDetails.map((builder, index) => (
            <Box key={index} sx={{ marginBottom: '20px' }}>
              
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingTop: '10px',
             }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500 }}>
                    Builders name<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Select
  sx={{
    height: '36px',
    color: "#313131",
    fontFamily: "Poppins",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "400",
    borderRadius: '8px',
    padding: '10px 14px', // Note the corrected padding value
  }}
  value={builder.builderName}
  onChange={(event) => handleBuilderChange(event, index, 'builderName')}
  error={!!errorMessages[index].builderName}
>
  {buildersNames.map((buildername, index) => (
    <MenuItem
    key={index} // Use listing_agent_id as the key
      sx={{
        backgroundColor: 'white !important',
        marginLeft: '10px',
        marginRight: '10px',
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "400",
        '&:hover': {
          borderRadius: '8px',
          marginLeft: '10px',
          marginRight: '10px',
          backgroundColor: '#0C276C !important',
          color: 'white',
        }}
      }
      value={buildername.builder_name} // Set the builder_name as the value
    
    >
      {buildername.builder_name}
    </MenuItem>
  ))}
</Select>
                  {/* <TextField
                    value={builder.builderName}
                    onChange={(event) => handleInputChange(event, index, 'builderName')}
                    type="text"
                    name="user_name"
                    error={!!errorMessages[index].builderName}
                    InputProps={{
                      sx: {
                        height: '36px',
                        color: "#313131",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        borderRadius: '8px',                       
                        padding: '10px 14px',
                        borderColor: errorMessages[index].builderName ? 'red' : '',
                      },
                    }}
                  /> */}
                  <Typography variant="body2" color="error">
                    {errorMessages[index].builderName}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '5px' }}>
                  <Typography sx={{
                    fontSize: '14px',
                    color: '#212427',
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                  }}>
                    Builder location<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    value={builder.builderLocation}
                    onChange={(event) => handleInputChange(event, index, 'builderLocation')}
                    error={!!errorMessages[index].builderLocation}
                    sx={{
                      width: "100%",
                      fontSize: '14px',
                      color: '#212427',
                      fontFamily: 'Poppins',
                      lineHeight: "18px",
                      fontWeight: "400",
                    }}
                    InputProps={{
                      sx: {
                        height: '36px',
                        fontSize: '14px',
                        color: "#313131",
                        fontFamily: 'Poppins',
                        lineHeight: "18px",
                        fontWeight: "400",
                        borderRadius: '8px',
                      //  background: '#F9F9F9',
                        padding: '10px 14px',
                        borderColor: errorMessages[index].builderLocation ? 'red' : '',
                      },
                    }}
                  />
                  <Typography variant="body2" color="error">
                    {errorMessages[index].builderLocation}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500 }}>
                    Sponsor name<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    value={builder.sponsorName}
                    onChange={(event) => handleInputChange(event, index, 'sponsorName')}
                    type="text"
                    name="user_sponsorName"
                    error={!!errorMessages[index].sponsorName}
                    InputProps={{
                      sx: {
                        height: '36px',
                        color: "#313131",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        borderRadius: '8px',
                      //  background: '#F9F9F9',
                        padding: '10px 14px',
                        borderColor: errorMessages[index].sponsorName ? 'red' : '',
                      },
                    }}
                  />
                  <Typography variant="body2" color="error">
                    {errorMessages[index].sponsorName}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500 }}>
                    Sponsor email id<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    value={builder.sponsorEmail}
                    onChange={(event) => handleInputChange(event, index, 'sponsorEmail')}
                    type="text"
                    name="user_phone"
                    error={!!errorMessages[index].sponsorEmail}
                    InputProps={{
                      sx: {
                        height: '36px',
                        color: "#313131",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        borderRadius: '8px',
                      //  background: '#F9F9F9',
                        padding: '10px 14px',
                        borderColor: errorMessages[index].sponsorEmail ? 'red' : '',
                      },
                    }}
                  />
                  <Typography variant="body2" color="error">
                    {errorMessages[index].sponsorEmail}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500 }}>
                    Sponsor phone number<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    value={builder.sponsorPhone}
                    onChange={(event) => handleInputChange(event, index, 'sponsorPhone')}
                    type="text"
                    name="user_email"
                    error={!!errorMessages[index].sponsorPhone}
                    InputProps={{
                      sx: {
                        height: '36px',
                        color: "#313131",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        borderRadius: '8px',
                      //  background: '#F9F9F9',
                        padding: '10px 14px',
                        borderColor: errorMessages[index].sponsorPhone ? 'red' : '',
                      },
                    }}
                  />
                  <Typography variant="body2" color="error">
                    {errorMessages[index].sponsorPhone}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px'}}>
        <Button variant="contained" size="large" sx={{
           justifyContent:'center',
            height: '36px',
            color: 'white',
            borderRadius: '8px',
            backgroundColor: '#0C276C',
            textTransform: 'none',
            '&:hover': { bgcolor: "#0B1E51", color: '#fff', border: '1px solid #0B1E51' },
          }}
            onClick={handleAddBuilderButtonClick}>Add builder</Button>
        </Box>
        
      </Box> </Box>     }
      <Footer></Footer>
      <style>
        {`
          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 1px solid #504FA4 !important;
            box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
          }
        `}
      </style>
        </Grid>
    )
}
export default EditSignUp;