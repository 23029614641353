import { Box, Button, CheckBox, FormControlLabel, FormLabel, IconButton, InputAdornment, Modal, OutlinedInput, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Signup from './SignUp';
import Signin from './SignIn';
import PrimaryButton from './customButton';
import SecondaryButton from './Secondarybutton';
import CustomOutlined from './customOutlined';
function ChangepwdMob() {
    const [openModal, setOpenmodal]=useState(true);
    const handleOpenmodal = () => setOpenmodal(true);
    const handleClosemodal = () => setOpenmodal(false);
    const [showModal,setShowModal] = useState(false);
    const [values, setValues] = useState({
      currentPassword: '',
      newPassword1: '',
      newPassword2: '',
    });
    
    function showModalHandler(){
      setShowModal(!showModal);
    }
    const [showPasswordValues,setShowPasswordValues]=useState({
      currentPassword: false,
      newPassword1: false,
      newPassword2: false,
    })
    const [error, setError] = useState();
    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
    const handleClickShowPassword = (prop)=>() => {
      setShowPasswordValues({
        ...showPasswordValues,
        [prop]: !showPasswordValues[prop],
      });
    };
    const checkPasswordSame = () => {
      if (values.newPassword2 !== values.newPassword1) {
        setError("Your password entered is not similar. Please try again.")
      }else{
        setError(null)
      }
    }
  
    function onSave(){
      checkPasswordSame()
    }
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  return (
    <Box
  sx={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '800px',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1'
  }}>
    {/* // <div style={{overflowY:'auto'}} >
    //     <Modal */}
    {/* //     open = {openModal}
    //     aria-labelledby="modal-modal-title"
    //     aria-describedby="modal-modal-description"
    //   > */}
     <Box
          sx={{
            position: 'absolute',
            width: {lg:'400px',md:'400px',sm:'400px',xs:'90%'},
            height:'510px',
        //     left:'483px',
        //    top:'100px',
            bgcolor: '#FFFF',
            paddingBottom:'70px',
            borderRadius:'8px',
            BoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            alignItems:'center',
            justifyContent:'center',
            }}
        >
          <div style={{ display: 'flex', flexDirection:'row-reverse',paddingTop:'12px',paddingRight:'12px' }}>
            <IconButton sx={{ padding: '0px' }} onClick={handleClosemodal} >
              < CancelOutlinedIcon sx={{ color: '#606060', width: '24px', height: '24px' }}/>
               </IconButton>
            </div>
             <div style={{ display: 'flex', flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center',marginTop:'34px'}}>
                <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins'}}>Change password</Typography>
             </div>
             <div style={{ display: 'flex', flexDirection:'column',paddingLeft:'32px',marginTop:'46px',
            paddingRight:'32px'
             }}>
              <Typography sx={{textAlign:'left',fontSize:'14px',color:'#212427',paddingBottom:'4px',fontFamily:'Poppins',fontWeight:500}}>Old password</Typography>
              <CustomOutlined      
                        id="outlined-adornment-password1"
                        type={showPasswordValues.currentPassword ? 'text' : 'password'}
                        value={values.currentPassword}
                        onChange={handleChange('currentPassword')}
                        endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword("currentPassword")}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        > 
                         {values.currentPassword===''?null:showPasswordValues.currentPassword ?   <Visibility />:<VisibilityOff />}
                       </IconButton>
                       </InputAdornment>
            }/>
              
              <Typography sx={{textAlign:'left',fontSize:'14px',color:'#212427',paddingBottom:'4px',paddingTop:'16px',fontFamily:'Poppins',fontWeight:500}}>New password</Typography>
              <CustomOutlined
                        id="outlined-adornment-password"
                        type={showPasswordValues.newPassword1 ? 'text' : 'password'}
                        value={values.newPassword1}
                        onChange={handleChange('newPassword1')}
                        endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword("newPassword1")}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        > 
                         {values.newPassword1===''?null:showPasswordValues.newPassword1 ? <Visibility/>:<VisibilityOff />}
                       </IconButton>
                       </InputAdornment>
            }/>
          <Typography sx={{textAlign:'left',fontSize:'14px',color:'#212427',paddingBottom:'4px',paddingTop:'16px',fontFamily:'Poppins',fontWeight:500}}>Confirm password</Typography>
              <CustomOutlined
                        id="outlined-adornment-password"
                        type={showPasswordValues.newPassword2 ? 'text' : 'password'}
                        value={values.newPassword2}
                        onChange={handleChange('newPassword2')}
                        endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword("newPassword2")}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        > 
                         {values.newPassword2===''?null:showPasswordValues.newPassword2 ? <Visibility/>:<VisibilityOff />}
                       </IconButton>
                       </InputAdornment>
            }
            />
            {
          error && <Typography variant='body' sx={{mt:'4px',fontSize:'12px',fontWeight:'400px',color:'red'}}>{error}</Typography>
        }
              <PrimaryButton onClick={onSave} sx={{marginTop:'48px'}}>Update password</PrimaryButton>
              {/* <SecondaryButton onClick={showModalHandler}>Return to sign in</SecondaryButton> */}
            </div>
            {/* {showModal ? <Signin />: null } */}
        </Box>
        {/* </Modal> */}
    </Box>
  )
}
export default ChangepwdMob