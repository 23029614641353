
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import axios from 'axios';
import '../App.css';
import { Link } from 'react-router-dom';
import homekey from'./images/key.png';


function FooterAboutUs() {


    const handleMoreArticles = () => {
       

    };


    return (
        <Grid container sx={{ width: '100%', height: '100%', margin: 0,gap:'30px', }}>
              <Box  sx={{display:'flex',flexDirection:'column', justifyContent: 'flex-start', alignItems: 'flex-start',
                    width:'100%' ,gap:'5px'}}>
                    <Typography sx={{ fontFamily: 'poppins', fontSize: '20px',lineHeight: '30px', fontWeight: '500', color: '#000000',}}>
                    Ratewaves   
                    </Typography>
                    <Typography sx={{ fontFamily: 'poppins', fontSize: '16px',lineHeight: '32px', fontWeight: '400', color: '#212427',}}>
                    Buying a home in Canada is an exciting and significant step towards creating a stable and prosperous future. 
                    At ratewaves , we understand the importance of making well-informed decisions, and our  Mortgage Calculator is
                     here to guide you on your journey.
                     </Typography>
                     <Typography sx={{ fontFamily: 'poppins', fontSize: '16px',lineHeight: '32px', fontWeight: '400', color: '#212427',}}>
                    As a proud Canadian company, we've tailored this calculator to meet the unique needs of homebuyers across the country. 
                    Whether you're in the bustling city of Toronto, the scenic landscapes of Vancouver,
                    or anywhere in between, our calculator provides accurate and localized results for a range of Canadian mortgage options. 
                    </Typography>
              </Box>
              <Box  sx={{display:'flex',flexDirection:'column',gap:'20px', justifyContent: 'flex-start', alignItems: 'flex-start',
                    width:'100%' }}>
                    <Typography sx={{ fontFamily: 'poppins', fontSize: '20px',lineHeight: '30px', fontWeight: '500', color: '#000000',}}>
                    Articles
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <Button  variant="contained" onClick={function () { handleMoreArticles(); }}
                            sx={{color: "white", textTransform: "none", height: { md: '36px', sm: '36px' },padding:{ md: '5px', sm: '5px' },
                            background: "#EB5648", border: "1px solid #EB5648", fontFamily: "Poppins",fontStyle: "normal", lineHeight: "18px",
                            fontWeight: "400", fontSize: "12px", width: "226px", borderRadius: "22px", 
                            '&:hover': { bgcolor: "#fff", color: '#EB5648' } }} >
                            Find more articles
                            </Button>
                    </Box>
                    <Box  sx={{display:'flex',flexDirection:'column', justifyContent: 'flex-start', alignItems: 'flex-start',
                    width:'100%' ,gap:'5px'}}>
                       <Typography sx={{ fontFamily: 'poppins', fontSize: '20px',lineHeight: '30px', fontWeight: '500', color: '#000000',}}>
                        About us
                        </Typography>  
                        <Typography sx={{ fontFamily: 'poppins', fontSize: '16px',lineHeight: '32px', fontWeight: '400', color: '#212427',}}>
                        Ratewaves is proud while helping you to choose the right mortgage for you. Using our user-friendly interface,
                         you can input essential details such as the property price, down payment, interest rate, and loan term to
                        instantly discover your estimated monthly payments and total interest costs. Our calculator accounts for
                        factors specific to the Canadian housing market, so you can trust the accuracy of the results.
                        </Typography>
                        <Typography sx={{ fontFamily: 'poppins', fontSize: '16px',lineHeight: '32px', fontWeight: '400', color: '#212427',}}>
                        But our commitment to your homeownership success doesn't end there. Our Canada Mortgage Calculator goes
                         beyond the basics to help you explore different financial scenarios. Discover how making extra payments
                          can save you money in the long run and analyze the impact of varying interest rates on your mortgage.
                       </Typography>
                       <img src={homekey} alt="" style={{textAlign:'center',height:'475px' , width:'100%', alignItems:'center',paddingTop:'20px',
                        paddingBottom:'20px' }} />
                       <Typography sx={{ fontFamily: 'poppins', fontSize: '16px',lineHeight: '32px', fontWeight: '400', color: '#212427',}}>
                       As your trusted partners, we are dedicated to supporting you throughout the process. Our team of experts is
                        well-versed in the Canadian real estate landscape and mortgage market, ready to provide personalized guidance
                         and answer any questions you may have along the way.
                       </Typography>
                       <Typography sx={{ fontFamily: 'poppins', fontSize: '16px',lineHeight: '32px', fontWeight: '400', color: '#212427',}}>                       
                        Whether you're a first-time homebuyer eager to find your perfect nest or a seasoned homeowner considering
                         refinancing, our Canada Mortgage Calculator is here to empower you with knowledge. We believe that when
                        you're equipped with the right information, you can confidently achieve your homeownership goals and
                        build a solid foundation for your future.As your trusted partners, we are dedicated to supporting you
                        throughout the process. Our team of experts is well-versed in the Canadian real estate landscape and
                        mortgage market, ready to provide personalized guidance and answer any questions you may have along the way.
                       </Typography>
                       <Typography sx={{ fontFamily: 'poppins', fontSize: '16px',lineHeight: '32px', fontWeight: '400', color: '#212427',}}>
                       Whether you're a first-time homebuyer eager to find your perfect nest or a seasoned homeowner considering
                        refinancing, our Canada Mortgage Calculator is here to empower you with knowledge. We believe that when
                         you're equipped with the right information, you can confidently achieve your homeownership goals and build
                       a solid foundation for your future.
                       </Typography>
                   </Box>
              </Box>
        </Grid>
        );

    }
    

    export default FooterAboutUs;