import { Box, Checkbox, FormControlLabel, IconButton, InputAdornment,
   Typography } from '@mui/material'
import React, { useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Signup from './SignUp';
import PrimaryButton from './customButton';
import SecondaryButton from './Secondarybutton';
import CustomOutlined from './customOutlined';
function SigninMob() {
    const [openModal, setOpenmodal]=useState(true);
    
    // const handleOpenmodal = () => setOpenmodal(true);

    const handleClosemodal = () => setOpenmodal(false);
    const [showModal,setShowModal] = useState(false);
    const [values, setValues] = useState({
      currentPassword: '',
      newPassword1: '',
      newPassword2: '',
    });
    function showModalHandler(){
      setOpenmodal(false)  
     setShowModal(!showModal);
     
    }
    const [showPasswordValues,setShowPasswordValues]=useState({
      currentPassword: false,
      newPassword1: false,
      newPassword2: false,
    })
    // const [error, setError] = useState();
    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
    const handleClickShowPassword = (prop)=>() => {
      setShowPasswordValues({
        ...showPasswordValues,
        [prop]: !showPasswordValues[prop],
      });
    };
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  return (
    <Box>
    {openModal  && ( <Box
  sx={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: {md:'1070px',sm:'1125px',xs:'1280px'},
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '3'
  }}>
    
         <Box
          sx={{
            position: 'absolute',
            width: {lg:'400px',md:'400px',sm:'400px',xs:'90%'},
            height:'524px',
            bgcolor: '#FFFF',
            paddingBottom:'36px',
            borderRadius:'8px',
            BoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            alignItems:'center',
            justifyContent:'center'
            }}>
          <div style={{ display: 'flex', flexDirection:'row-reverse',paddingTop:'12px',paddingRight:'12px' }}>
            <IconButton sx={{ padding: '0px' }} onClick={handleClosemodal} >
              < CancelOutlinedIcon sx={{ color: '#606060', width: '24px', height: '24px' }} />
            </IconButton>
            </div>
             <div style={{ display: 'flex', flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center'}}>
                <Box sx={{height:'40px',width:'40px',borderRadius:'40px',border:'1px solid #1F6373',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                    <PersonIcon sx={{color:'#1F6373',height:'40px',width:'40px'}}/>
                </Box>
                <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins'}}>Sign in</Typography>
             </div>
             <div style={{ display: 'flex', flexDirection:'column',paddingLeft:'28px',
            paddingRight:'28px',
             paddingTop:'48px'}}>
              <Typography sx={{textAlign:'left',fontSize:'14px',color:'#212427',paddingBottom:'6px',fontFamily:'Poppins',fontWeight:500}}>Email or Username</Typography>
              <CustomOutlined type="text" placeholder='olivia@untitledui.com'/> 
                <Typography sx={{textAlign:'left',fontSize:'14px',color:'#212427',paddingBottom:'6px',paddingTop:'16px',fontFamily:'Poppins',fontWeight:500}}>Password</Typography>
              <CustomOutlined
                        id="outlined-adornment-password"
                        type={showPasswordValues.currentPassword ? 'text' : 'password'}
                        value={values.currentPassword}
                        onChange={handleChange('currentPassword')}
                  endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword("currentPassword")}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        /> 
                         {values.currentPassword===''?null:showPasswordValues.currentPassword ?   <Visibility />:<VisibilityOff />}
                       </InputAdornment>
                }/>
                    <div className="check">
            <FormControlLabel control={<Checkbox sx={{color:'#1F6373', '&.Mui-checked': {
      color:'#1F6373'},}}/>} label="Remember me" sx={{fontSize:'0.75rem',color:'#626262',fontFamily:'Poppins',
            "& .MuiFormControlLabel-label":{fontFamily:'Poppins',fontSize:'12px'},
          }} />
            <Typography mt={1.5} sx={{fontSize:'12px',color:'#626262',fontFamily:'Poppins'}}><a href='#' style={{color:'#626262',fontSize:'0.75rem'}}>
              Forgot password?</a></Typography>
          </div> 
              <PrimaryButton sx={{marginTop:'48px'}}>Sign in</PrimaryButton>
              <Typography sx={{fontSize:{lg:'14px',md:'14px',xs:'12px'},color:'#626262',paddingTop:'16px',fontFamily:'Poppins',fontWeight:400}}>
                If you don’t have already an account</Typography>
              <SecondaryButton onClick={showModalHandler}>Create an account</SecondaryButton>
            </div>
           
        </Box>
        {/* // </Modal> */}
    </Box>)}
    {showModal ? <Signup onClose={handleClosemodal}/>: null }
    </Box>
  )
}
export default SigninMob;