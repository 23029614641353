import { Box, Button, CheckBox, FormControlLabel, FormLabel, IconButton, InputAdornment, InputBase, Modal, NativeSelect, OutlinedInput, Radio, RadioGroup, styled, Typography } from '@mui/material'
import React, { useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HelpIcon from '@mui/icons-material/Help';
import Leftbox from './leftbox';
import PrimaryButton from './customButton';
function NegotiatemodalMod() {
    const [openModal, setOpenmodal]=useState(true);
    const handleOpenmodal = () => setOpenmodal(true);
    const handleClosemodal = () => setOpenmodal(false);
    const [showModal,setShowModal] = useState(false);
    const [value, setValue] = useState(0);
    function showModalHandler() {
        setShowModal(!showModal);
      }
      const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      const [labelName,setLabelName] = useState(["Mortgage 1","Received","Requirement"]);
      const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(),
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          color: '#626262',
          position: 'relative',
          backgroundColor: theme.palette.background.paper,
          border: '2px solid #DADADA',
          fontSize: 12,
          fontFamily: 'Poppins',
          paddingLeft: '14px',
          paddingRight: '14px',
          paddingTop: '1px',
          paddingBottom: '2px',
          height: '28px',
          width: '134px',
          alignItems: 'center',
          BoxSizing: 'border-Box',
          '&:focus': {
            borderRadius: 4,
            },
        },
        "& .MuiNativeSelect-select": { paddingRight: '8px !important' },
      }));
  return (
    <div>
         <Modal
        open = {openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
     <Box
          sx={{
            position: 'absolute',
            width: '790px',
            height:'540px',
            left:'288px',
           top:'25px',
            bgcolor: '#FFFF',
            paddingLeft:'42px',
            paddingBottom:'24px',
            borderRadius:'12px',
            }}>
          <div style={{ display: 'flex', flexDirection:'row-reverse',paddingTop:'12px',paddingRight:'12px' }}>
            <IconButton sx={{ padding: '0px' }} onClick={handleClosemodal} >
              < CancelOutlinedIcon sx={{ color: '#606060', width: '24px', height: '24px' }} />
            </IconButton>
            </div>
             <div style={{ display: 'flex', flexDirection:'column'}}>
                <Typography sx={{fontSize:'20px',fontWeight:500,color:'#454545',fontFamily:'Poppins'}}>Request a revised quote</Typography>
             </div>
             <div style={{ display: 'flex', flexDirection: 'row',gap:'22px',height:'390px',paddingRight:'42px' }}>
              <div style={{ width: '178px', height: '365px', display: 'flex', flexDirection: 'column', marginTop: '72px', gap: '16px'}}>
                <Leftbox />
              </div>
                {labelName.map(item =>{
                  return(
                <div style={{
                        width: '158px', display: 'flex', flexDirection: 'column',
                        paddingTop: '18px', paddingLeft: '12px', paddingRight: '12px', paddingBottom: '20px', backgroundColor: 'white',
                        border: '1px solid #CFC9C9', gap: '16px', marginTop: '16px', borderRadius: '8px'
                      }}>
                        <Typography sx={{ textAlign: 'center', fontSize: '14px', fontWeight: 400, color: '#626262' }}>{item}</Typography>
                        <OutlinedInput type="text" placeholder='0'
                          sx={{width: '100%', borderRadius: '4px', fontSize: '12px', color: '#313131',
                            height: '28px', textAlign: 'center', border: '1px solid #DADADA',
                            "& .MuiOutlinedInput-input": { textAlign: 'left' },
                          }}/>
                         <OutlinedInput type="text" placeholder='0'
                           sx={{width: '100%', borderRadius: '4px', fontSize: '12px', color: '#313131',
                            height: '28px', textAlign: 'center', border: '1px solid #DADADA',
                            "& .MuiOutlinedInput-input": { textAlign: 'left' },
                          }} />
                        <NativeSelect
                          id="demo-customized-select-native"
                          defaultValue={10}
                          onChange={handleChange}
                          input={<BootstrapInput />}
                          sx={{
                            "& .MuiNativeSelect-nativeInput": { paddingRight: '0px' },
                            paddingRight: '0px'
                          }}
                        >
                          <option value={10} >Variable</option>
                          <option value={20}>Fixed</option>
                          <option value={30}>Open</option>
                        </NativeSelect>
                        <NativeSelect
                          id="demo-customized-select-native"
                          defaultValue={10}
                          onChange={handleChange}
                          input={<BootstrapInput />}
                          sx={{
                            "& .MuiNativeSelect-nativeInput": { paddingRight: '0px' },
                            paddingRight: '0px'
                          }}
                        >
                          <option value={10} sx={{ color: '#626262' }}>1 Month</option>
                          <option value={20}>2 Months</option>
                          <option value={30}>3 Months</option>
                          <option value={20}>4 Months</option>
                          <option value={30}>5 Months</option>
                          <option value={20}>6 Months</option>
                          <option value={30}>7 Months</option>
                          <option value={20}>8 Months</option>
                          <option value={30}>9 Months</option>
                          <option value={30}>10 Months</option>
                          <option value={20}>11 Months</option>
                          <option value={30}>12 Months</option>
                        </NativeSelect>
                        <OutlinedInput type="text" placeholder='0'
                          sx={{
                            width: '100%', borderRadius: '4px', fontSize: '12px',
                            height: '28px', textAlign: 'center', border: '1px solid #DADADA',
                            "& .MuiOutlinedInput-input": { textAlign: 'left' },
                          }}/>
                        <OutlinedInput type="text" placeholder='0'
                          sx={{width: '100%', borderRadius: '4px', fontSize: '12px',
                            height: '28px', textAlign: 'center', border: '1px solid #DADADA',
                            "& .MuiOutlinedInput-input": { textAlign: 'left' },
                          }} />
                        <NativeSelect
                          id="demo-customized-select-native"
                          defaultValue={10}
                          onChange={handleChange}
                          input={<BootstrapInput />}
                          sx={{
                            "& .MuiNativeSelect-nativeInput": { paddingRight: '0px' },
                            paddingRight: '0px'
                          }}
                        >
                          <option value={10}>Monthly</option>
                          <option value={20}>Bi-weekly</option>
                        </NativeSelect>
                     </div>
                  );})}
                </div>
                <div align="right" style={{paddingRight:'42px',marginTop:'24px'}}>
              <PrimaryButton sx={{width:'244px'}}>Request</PrimaryButton>
              </div>
        </Box>
        </Modal>
    </div>
  )
}
export default NegotiatemodalMod;