import Box from '@mui/material/Box';
import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import '../App.css';
import Header from './HeaderLanding'
import Footer from './Footer';
import Bloger1 from './images/Bloger1.png'
import Image01 from './images/image01.png'
import Image02 from './images/image02.png'
import Image03 from './images/image03.png'
import Image04 from './images/image04.png'
import Image1 from './images/image1.png'
import Image2 from './images/image2.png'
import Image3 from './images/image3.png'
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useLocation } from 'react-router-dom';

function StepsToGet() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const blogTitle = searchParams.get('blogTitle');
  const blogImage = searchParams.get('blogImage');
  

  const [title, setTitle] = useState(blogTitle);
      const [image, setImage] = useState(blogImage);
      useEffect(() => {
        // Update state only if query parameters change
        setTitle(blogTitle);
        setImage(blogImage);
    }, [blogTitle, blogImage]);
    const handleReadMoreClick = (title, image) => {
      setTitle(title);
      setImage(image);
      window.scrollTo(0, 0); // Scroll to the top of the page
    };
    
  
    const [currentPage, setCurrentPage] = useState('');
    const blogs = [
        {
            image: Image01,
            heading: 'Mortgage rates match highest level in 20 years',
            content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
            publishedDate: 'April 8,2023',
          },          
        {
            image: Image02,
            heading: 'What is the role of a mortgage agent?',
            content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
            publishedDate: 'April 8,2023',
          },
          {
            image: Image03,
            heading: 'Steps to get the best interest rate on your mortgage',
            content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
            publishedDate: 'April 8,2023',
          },
          {
            image: Image04,
            heading: 'How a mortgage broker can help you',
            content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
            publishedDate: 'April 8,2023',
          },
        {
          image: Image1,
          heading: 'How much is a down payment on a house?',
          content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
          publishedDate: 'April 8,2023',
        },
        {
            image: Image2,
            heading: 'How to get the best mortgage rate',
            content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
            publishedDate: 'April 8,2023',
          },
          {
            image: Image3,
            heading: 'How to get the best mortgage rate',
            content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
            publishedDate: 'April 8,2023',
          },
      ];

      const [visibleIndex, setVisibleIndex] = useState(0);

      const blogsPerPage = 3;
      const totalPages = Math.ceil(blogs.length / blogsPerPage);
    
      const showNextPage = () => {
        if (visibleIndex < totalPages - 1) {
          setVisibleIndex(visibleIndex + 1);
        }
      };
    
      const showPreviousPage = () => {
        if (visibleIndex > 0) {
          setVisibleIndex(visibleIndex - 1);
        }
      };
    
      const startIndex = visibleIndex * blogsPerPage;
      const visibleBlogs = blogs.slice(startIndex, startIndex + blogsPerPage);
      

    return (
        <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>
            <Box sx={{
                height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'flex', sm: 'flex', xs: 'none' }
            }}>
                <Header currentPage={currentPage}></Header>
            </Box>
            <Box sx={{
        width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)',xs:'calc(100%-10%)' },  display: 'flex', flexDirection: 'row',height:'100%',
        paddingLeft: { md: '16%', sm: '5%' , xs:'5%'}, paddingRight: { md: '16%', sm: '5%' , xs:'5%'}, paddingTop: { md: '20px', sm: '20px',xs:'10px' },
        paddingBottom: { md: '0', sm: '0' , xs:'5%'},
      }}>
 
                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: { md: '26px', sm: '26px', xs: '20px' },
                        lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto'
                    }}>
                       Articles
                    </Typography>
                    </Box>
                    <Box  sx={{
       width: '100%',  display: 'flex', flexDirection: 'column',height:'100%',
        paddingLeft: { md: '16%', sm: '5%' , xs:'5%'}, paddingRight: { md: '16%', sm: '5%' , xs:'5%'}, paddingTop: { md: '20px', sm: '20px',xs:'25px' },
        paddingBottom: { md: '0', sm: '0' , xs:'5%'},gap:'50px'
      }}>
  <Typography sx={{
                        fontFamily: 'poppins', fontSize: { md: '36px', sm: '36px', xs: '32px' },
                        lineHeight: '24px', fontWeight: '700', color: '#313131', width:'100%'
                    }}>
                     {blogTitle}
                    </Typography>
                    <Box  sx={{ width: '30%',  display: 'flex', flexDirection: 'column',height:'100%',gap:'5px',}}>
                    <Box  sx={{ width: '100%',  display: 'flex', flexDirection: 'row',height:'100%',gap:'5px',}}>
                    <Box  sx={{ width: '36px',  display: 'flex', flexDirection: 'row',height:'100%',}}>
                    <img src={Bloger1} alt=''  />
                    </Box>
                    <Box  sx={{ width: '80%',  display: 'flex', flexDirection: 'column',height:'100%'}}>
                    <Typography sx={{ fontFamily: 'poppins', fontSize:'16px',
                        lineHeight: '24px', fontWeight: '600', color: '#313131', width:'100%'
                    }}>
                     Anuja Chauhan
                    </Typography>
                    <Typography sx={{ fontFamily: 'poppins', fontSize:'16px',
                        lineHeight: '24px', fontWeight: '400', color: '#626262', width:'100%'
                    }}>
                   Author and advertiser
                    </Typography>
                   
                    </Box>
      </Box>
      <Typography sx={{ fontFamily: 'poppins', fontSize:'16px',
                        lineHeight: '24px', fontWeight: '400', color: '#626262', width:'100%'
                    }}>
                 Published on August 15,2023
                    </Typography>
      </Box>
      <Box  sx={{ width: '100%',  display: 'flex', flexDirection: 'column',height:'100%',gap:'50px',}}>  
      <Box  sx={{ width: '100%',  display: 'flex', flexDirection: 'row',height:'100%',gap:'50px',}}> 
      <Typography sx={{ fontFamily: 'poppins', fontSize:'16px',
                        lineHeight: '32px', fontWeight: '400', color: '#313131', width:'60%'
                    }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

                    </Typography>
                    <img src={blogImage} alt='' style={{width:'40%'}} />
              </Box>     
              <Box  sx={{ width: '100%',  display: 'flex', flexDirection: 'row',height:'100%',gap:'50px',}}> 
              <img src={Image03} alt='' style={{width:'40%'}} />
      <Typography sx={{ fontFamily: 'poppins', fontSize:'16px',
                        lineHeight: '32px', fontWeight: '400', color: '#313131', width:'60%'
                    }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

                    </Typography>
                   
              </Box>  
              <Typography sx={{ fontFamily: 'poppins', fontSize:'16px',
                        lineHeight: '32px', fontWeight: '400', color: '#313131', width:'100%'
                    }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

                    </Typography>    
      </Box>
      <Typography sx={{
                        fontFamily: 'poppins', fontSize: '24px',
                        lineHeight: '24px', fontWeight: '500', color: '#313131', marginRight: 'auto'
                    }}>
                       Related articles 
                    </Typography>
      <div className="related-blog-grid"  >
      {visibleBlogs.map((blog, index) => (
                            <Box key={index} sx={{width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)',xs:'calc(100%-10%)' },height:'100%',
                            padding: { md: '20px', sm: '10px' , xs:'5px'}, display: 'flex' , flexDirection: 'column',overflowX:'hidden'
                            ,gap:{ md: '20px', sm: '10px' , xs:'5px'},}}  >
                                    
                                    <Box sx={{width:'100%',height:'100%',display: 'flex', flexDirection: 'column',}} >
                                    <img src={blog.image} alt={blog.heading}   />
                                    </Box>
                                    <Box sx={{width:{md:'100%' , sm:'100%', xs:'100%' },height:'100%',gap:'10px',padding:'5px',display: 'flex', flexDirection: 'column',}} >
                                    <Typography sx={{ fontFamily: 'poppins', fontSize:  '20px' ,
                                                    lineHeight: '24px', fontWeight: '600', color: '#313131',}} >{blog.heading}</Typography>
                                    <Typography sx={{ fontFamily: 'poppins', fontSize:  '16px' ,
                                                    lineHeight: '24px', fontWeight: '400', color: '#626262',}}>{blog.content}</Typography>
                                            <Box sx={{display: 'flex', flexDirection: 'row', width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)',xs:'calc(100%-10%)' },justifyContent: 'space-between', alignItems: 'center',  }} >
                                            <Link style={{ fontFamily: 'poppins', fontSize:  '16px' ,lineHeight: '24px', fontWeight: '400', color: '#4867AA',
                                                    textTransform:'none',  textDecoration: 'none', }}   to={{
                                                      pathname: '/steps-to-get',
                                                      search: `?blogTitle=${encodeURIComponent(blog.heading)}&blogImage=${encodeURIComponent(blog.image)}`,
                                                  }}onClick={() => handleReadMoreClick(blog.heading, blog.image)}>
                                            Read More
                                            </Link>
                                            <Typography sx={{fontFamily: 'poppins', fontSize:  '16px' ,
                                                            lineHeight: '24px', fontWeight: '400', color: '#626262',  }} className="published-date">Published: {blog.publishedDate}</Typography></Box>
                                            </Box>
                                     </Box>
                                     
                        ))}
                         </div> 
                         <Box sx={{ display: 'flex', flexDirection: "row", width: '100%', justifyContent: 'right', gap: '20px' }}>
                    <ChevronLeftIcon onClick={showPreviousPage} disabled={visibleIndex === 0} sx={{
                        background: "#CFDAF4", cursor: visibleIndex === 0 ? 'default' : 'pointer',
                        color: visibleIndex === 0 ? "#CCCCCC" : "#0C276C", boxShadow: "2px 0px 4px 0px #00000040 ", borderRadius: "50%",
                        width: "32px", height: '32px'
                    }} ></ChevronLeftIcon>
                    <ChevronRightIcon onClick={showNextPage} disabled={visibleIndex === totalPages - 1} sx={{
                        background: "#CFDAF4", cursor: visibleIndex === totalPages - 1 ? 'default' : 'pointer',
                        boxShadow: "2px 0px 4px 0px #00000040", color: visibleIndex === totalPages - 1 ? "#CCCCCC" : "#0C276C", borderRadius: "50%",
                        width: "32px", height: '32px'
                    }} ></ChevronRightIcon>
                </Box>
                        
      </Box>
            <Footer></Footer>
        </Grid>
    );

}

export default StepsToGet;