import React, { useState, useEffect } from 'react';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Button, Typography, InputLabel, Grid } from "@mui/material";
import Crop169Icon from "@mui/icons-material/Crop169";
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ImgFrame from './images/HomeBlue.png';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import HelpIcon from '@mui/icons-material/Help';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dataicon from './images/icon-park-outline_database-forbid.svg';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Link1 from "@mui/material/Link";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DivComponents from './DivComponentMobile';
import Payment from './PaymentComponentMobile';
import Penalty from './PenaltyComponentMobile';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import GetAppIcon from '@mui/icons-material/GetApp';
import CheckboxPopUp from './CheckboxPopUpMobile';
import SignIn from './SignInSignUp'
import logo from './images/logo.png'
import TextField from '@mui/material/TextField';
import axios from 'axios';
import '../App.css';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';

const dbLink = process.env.REACT_APP_API_URL;

function AddMortgageMobile() {
  const [currentPage, setCurrentPage] = useState('Universal Calcualtor');
  const menus = [
    { label: "Universal Calcualtor", link: "/" },
    { label: "Canada Mortgage", link: "/canada-mortgage " },
    { label: "About us", link: "/about-us" },
    { label: "Contact us", link: "/contact-us" }
  ];

  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };





  const [isSelected, setIsSelected] = useState(false);
  const [askingPriceValue, setAskingPriceValue] = useState(10000);
  const [initialPaymentValue, setInitialPaymentValue] = useState(0);
  const [mortgageamountValue, setMortgageamountValue] = useState(10000);
  const [termValue, setTermValue] = useState(5);
  const [rateValue, setRateValue] = useState(3);
  const [periodValue, setPeriodValue] = useState(25);
  const [frequencyValue, setFrequencyValue] = useState('M');  
  const [insurance,setInsurance]=useState(0);
  const [sheduledPayment, setSheduledPayment] = useState(47.32);
  const [resultTotal, setResultTotal] = useState({ TotalAmountPaid:1431.60 ,TotalInterestPaid :1407.60 , TotalPrinciplePaid: 2839.20 });

  const [dataList, setDataList] = useState([
    {
      id: 0,
      name: "Option #1",
      askingprice: askingPriceValue,
      initialPayment: initialPaymentValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      isSelected: false,
      SheduledResult:sheduledPayment,
      lowerResults:resultTotal
    },
    {
      id: 1,
      name: "Option #2",
      askingprice: askingPriceValue,
      initialPayment: initialPaymentValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      isSelected: false,
      SheduledResult:sheduledPayment,
      lowerResults:resultTotal
    },
   
  ])

  
  const [numberOfAddClick, setnumberOfAddClick] = useState(2);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(2);

  const handleAddSlide = async() => {
console.log("datalength",dataList.length )
    setisDeleteButtonEnabled(true);
    if (dataList.length !== 7) {
      const mrval=askingPriceValue-initialPaymentValue;
      const monthlyPayment =await getSheduledPayment(parseInt(askingPriceValue), (rateValue/100), periodValue, frequencyValue, mrval);
      const data = await getSecondResult(parseInt(askingPriceValue),(rateValue/100),periodValue,frequencyValue,monthlyPayment,termValue,mrval,monthlyPayment)
      const newData = {
        id: dataList.length , name: `Option #${numberOfAddClick + 1}`, askingprice: askingPriceValue, isSelected: false,
        initialPayment: initialPaymentValue, mortgageamount:mrval, term: termValue, rate: rateValue, period: periodValue,
         frequency: frequencyValue,SheduledResult:monthlyPayment.toFixed(2),lowerResults:{ TotalAmountPaid: data.TotalPrinciple,
         TotalInterestPaid:data.TotalInterest,
         TotalPrinciplePaid: data.TotalPayment}
      }

      // isDeleteConfirm===true?setCurrentSlideIndex(currentSlideIndex+2):setCurrentSlideIndex(currentSlideIndex+1);

      setnumberOfAddClick(numberOfAddClick + 1);

      // setIsDeleteConfirm(false);
      setDataList([...dataList, newData]);
    }
    setCurrentSlideIndex(currentSlideIndex + 1);
  };




  const handlePrevSlide = () => {

    if (currentSlideIndex > 2)
      setCurrentSlideIndex(currentSlideIndex - 1)

  };

  const handleNextSlide = () => {
    if (currentSlideIndex < (dataList.length))
      setCurrentSlideIndex(currentSlideIndex + 1)

  }
  const [isDeleteButtonEnabled, setisDeleteButtonEnabled] = useState(false);
  const isdisableAddSlidebutton = (dataList[dataList.length]?.name) === (dataList[currentSlideIndex]?.name)
  const [CheckboxEnabled, setCheckboxEnabled] = useState(false);
  const [thebidding, setthebidding] = useState("bidding disable");
  const handleAddBiddingCheckbox = () => {
    setCheckboxEnabled(true);
    setthebidding("bidding enable");
    setisDeleteButtonEnabled(false);
  };
  const [showPopupforCheckbox, setShowPopupforCheckbox] = useState(false);
  const [showPopupforSummary, setShowPopupforSummary] = useState(false);
  const [showResultSummary, setShowResultSummary] = useState([]);
  const handleNextClick = () => {
    setShowPopupforCheckbox(true);
  };
  const [selectedCheckboxList, setCheckboxSelectedList] = useState([]);

 // const [isCheckedCheckboxNext, setisCheckedCheckboxNext] = useState([]);
  var isNextButtonEnabled = selectedCheckboxList.length > 0;
  const handleNextCheckboxChange = (isChecked, index) => {

    // setisNextButtonEnabled(isChecked);    

    //setisCheckedCheckboxNext(isChecked);
    //[...isChecked]=[temp,setIsChecked];

    //  if (isChecked) {
    //   setisCheckedCheckboxNext((prevChecked) => [...prevChecked, index]);
    // } else {
    //   setisCheckedCheckboxNext((prevChecked) =>
    //     prevChecked.filter((item) => item !== index)
    //   );
    // }




  };
  const IsChevorButton = (CheckboxEnabled && dataList.length > 2) || isDeleteButtonEnabled;




  // const [isChecked, setIsChecked] = useState(false);

  const [isHoveredAddMortgage, setIsHoveredAddMortgage] = useState(false);

  const handleMouseEnterAddMortgage = () => {
    setIsHoveredAddMortgage(true);
  };

  const handleMouseLeaveAddMortgage = () => {
    setIsHoveredAddMortgage(false);
  };

  const [isHoveredTheBidding, setIsHoveredTheBidding] = useState(false);

  const handleMouseEnterTheBidding = () => {
    setIsHoveredTheBidding(true);
  };

  const handleMouseLeaveTheBidding = () => {
    setIsHoveredTheBidding(false);
  };
  const [isHoveredSendToMail, setIsHoveredSendToMail] = useState(false);

  const handleMouseEnterSendToMail = () => {
    setIsHoveredSendToMail(true);
  };

  const handleMouseLeaveSendToMail = () => {
    setIsHoveredSendToMail(false);
  };

  const [isHoveredDownloadResult, setIsHoveredDownloadResult] = useState(false);

  const handleMouseEnterDownloadResult = () => {
    setIsHoveredDownloadResult(true);
  };

  const handleMouseLeaveDownloadResult = () => {
    setIsHoveredDownloadResult(false);
  };
  const [isHoveredNext, setIsHoveredNext] = useState(false);

  const handleMouseEnterNext = () => {
    setIsHoveredNext(true);
  };

  const handleMouseLeaveNext = () => {
    setIsHoveredNext(false);
  };
  


const handleSummary = async (mname) => {
  const index=dataList.findIndex(x => x.name ===mname);
  const askingValue = dataList[index].askingprice; 
  const initialValue =dataList[index].initialPayment;
  const rate =(dataList[index].rate)/100;
  const amortValue = dataList[index].period;
  const freqValue =dataList[index].frequency;
  const termsValue =dataList[index].term;
  const shedule = dataList[index].sheduledPayment;
  const mrval = dataList[index].askingprice - dataList[index].initialPayment;
  const monthlyPayment = await getSheduledPayment(askingValue, rate , amortValue, freqValue, mrval);
  const data = await getSecondResult(askingValue, rate , amortValue, freqValue, parseFloat(monthlyPayment), termsValue, mrval, parseFloat(shedule))
  setShowResultSummary(data.scheduleData);
  
  setShowPopupforSummary(true);
};
  const handleCancel = () => {
    setShowPopupforSummary(false);
   
    
  };

  const handleMouseEnterPaymentButton = (index) => {
    var tempdataList = [...dataList]
    tempdataList[index].isSelected = true;
    setDataList(tempdataList);
  };

  const handleMouseLeavePaymentButton = (index) => {
    var tempdataList = [...dataList]
    tempdataList[index].isSelected = false;
    setDataList(tempdataList);
  };
  // const [size, setSize] = useState([0, 0]);

  // function ViewPort() {
  //   var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  //   var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  //   var viewsize = w + "," + h;
  //   alert("Your View Port Size is:" + viewsize);
  // }
  // useLayoutEffect(() => {
  //   function updateSize() {
  //     setSize([window.outerWidth, window.outerWidth]);
  //     var x = window.matchMedia("(max-width: 700px)")
  //     console.log("x", x)
  //   }
  //   window.addEventListener('resize', updateSize);
  //   updateSize();
  //   return () => window.removeEventListener('resize', updateSize);
  // }, []);

  // console.log("size", size);

  const [isActiveAskingPrice, setIsActiveAskingPrice] = useState(false);

  const handleInputClickAskingPrice = () => {
    setIsActiveAskingPrice(true);
  };

  const handleInputBlurAskingPrice = () => {
    setIsActiveAskingPrice(false);
  };
  const [isActiveLocation, setIsActiveLocation] = useState(false);

  const handleInputClickLocation = () => {
    setIsActiveLocation(true);
  };

  const handleInputBlurLocation = () => {
    setIsActiveLocation(false);
  };
  const [showSignIn, setShowSignIn] = useState(false);

  const handleClickSignIn = () => {
    setShowSignIn(!showSignIn);
  };
  const [showTermText, setShowTermText] = useState(false);
  const handleTermClick = () => {
    setShowTermText(!showTermText)
    setTimeout(() => {
      setShowTermText(false);
    }, 5000);
    setShowAnnualInterestRateText(false);   
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showAnnualInterestRateText, setShowAnnualInterestRateText] = useState(false);
  const handleAnnualInterestRateClick = () => {
    setShowAnnualInterestRateText(!showAnnualInterestRateText);
    setTimeout(() => {
      setShowAnnualInterestRateText(false);
    }, 5000);    
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showAmortizationText, setShowAmortizationText] = useState(false);
  const handleAmortizationTextClick = () => {
    setShowAmortizationText(!showAmortizationText);
    setTimeout(() => {
      setShowAmortizationText(false);
    }, 5000); 
  };

  const [showPaymentfreequencyText, setShowPaymentfreequencyText] = useState(false);
  const handlePaymentfreequencyTextClick = () => {
    setShowPaymentfreequencyText(!showPaymentfreequencyText);
    setTimeout(() => {
      setShowPaymentfreequencyText(false);
    }, 8000); 
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showPaymentSheduleText1, setShowPaymentSheduleText1] = useState(false);
  const handlePaymentSheduleTextClick1 = () => {
    setShowPaymentSheduleText1(!showPaymentSheduleText1);
    setTimeout(() => {
      setShowPaymentSheduleText1(false);
    }, 5000); 
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showPaymentSheduleText2, setShowPaymentSheduleText2] = useState(false);
  const handlePaymentSheduleTextClick2 = () => {
    setShowPaymentSheduleText2(!showPaymentSheduleText2);
    setTimeout(() => {
      setShowPaymentSheduleText2(false);
    }, 5000); 
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showAskingPriceText, setShowAskingPriceText] = useState(false);
  const handleAskingPriceTextClick = () => {
    setShowAskingPriceText(!showAskingPriceText);
    setTimeout(() => {
      setShowAskingPriceText(false);
    }, 5000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false); 
  };

  const [showInitialpaymentText, setShowInitialpaymentText] = useState(false);
  const handleInitialPaymentTextClick = () => {
    setShowInitialpaymentText(!showInitialpaymentText);
    setTimeout(() => {
      setShowInitialpaymentText(false);
    }, 5000); 
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showTotalPrinciplePaidText, setShowTotalPrinciplePaidText] = useState(false);
  const handleTotalPrinciplePaidTextClick = () => {
    setShowTotalPrinciplePaidText(!showTotalPrinciplePaidText);
    setTimeout(() => {
      setShowTotalPrinciplePaidText(false);
    }, 5000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showTotalInterestPaidText, setShowTotalInterestPaidText] = useState(false);
  const handleTotalInterestPaidTextClick = () => {
    setShowTotalInterestPaidText(!showTotalInterestPaidText);
    setTimeout(() => {
      setShowTotalInterestPaidText(false);
    }, 6000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showTotalAmountPaidText, setShowTotalAmountPaidText] = useState(false);
  const handleTotalAmountPaidTextClick = () => {
    setShowTotalAmountPaidText(!showTotalAmountPaidText);
    setTimeout(() => {
      setShowTotalAmountPaidText(false);
    }, 8000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
  };

  const [showMortgageAmountText, setShowMortgageAmountText] = useState(false);
  const handleMortgageAmountTextClick = () => {
    setShowMortgageAmountText(!showMortgageAmountText)
    setTimeout(() => {
      setShowMortgageAmountText(false);
    }, 3000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowInitialpaymentText(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
  };
  const [askingPriceVariableParent, setAskingPriceVariableParent] = useState(false);
  const handleChangeAskingPriceValue = (event) => {
    const newValue = event.target.value;
    setAskingPriceValue(newValue);
    setAskingPriceVariableParent(true);
    var tempdataList=[...dataList]
   tempdataList= tempdataList.map(e=>({...e,askingprice:parseInt(newValue)}));   
   setDataList(tempdataList);

  };
  ////////////////////////backend/////////////////////////
//   const [resultAsking, setResultAsking] = useState([0, 0, 0, 0, 0, 0, 0]);
//   const [result, setResult] = useState([47.32, 47.32, 47.32, 47.32, 47.32, 47.32, 47.32]);
//   const [result2, setResult2] = useState([{ Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
//   { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
//   { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
//   { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }]);
//   const [askingprice, setAskingPrice] = useState([10000, 10000, 10000, 10000, 10000, 10000, 10000]);
//   const [interestRate, setInterestRate] = useState([0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03]);
//   const [amortznPeriod, setAmortznPeriod] = useState([25, 25, 25, 25, 25, 25, 25]);
//   const [FreqParam, setFreqParam] = useState(['M', 'M', 'M', 'M', 'M', 'M']);
//   const [TermVal, setTermVal] = useState([5, 5, 5, 5, 5, 5, 5]);
//   const [InitialpaymentVal, setInitialpaymentVal] = useState([0, 0, 0, 0, 0, 0, 0]);
//   const [mortgageamountVal, setmortgageamountVal] = useState([10000, 10000, 10000, 10000, 10000, 10000, 10000]);
  // useEffect(() => {    
  //   console.log('result from parent:', result);
  // }, [result]);

const [mortgageAmountTextValue, setMortgageAmountTextValue] = useState([10000,10000,10000,10000,10000,10000,10000]);

const [mortAmountValue, setMortAmountValue] = useState([10000,10000,10000,10000,10000,10000,10000]);

const getSheduledPayment=async(AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt)=>{
  const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

 
    const monthlyPayment = response.data.MonthlyPayment;
    return monthlyPayment;
}

const getSecondResult=async(AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt,SheduledPayment)=>{
 // const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

  const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt,SheduledPayment });
    
    return response2.data;
}
const handleChangeAskingprice =async (value,index) => {
   const AskingPriceValue = value;
 
    const rate = (dataList[index].rate)/100;

    const Amortization = dataList[index].period;
    const Frequency = dataList[index].frequency;
    const Term = dataList[index].term;
    const MortgageAmnt =  value - dataList[index].initialPayment;
    var tempdataList=[...dataList]
    tempdataList[index].mortgageamount= value - dataList[index].initialPayment;
    setDataList(tempdataList);
    console.log("asking price text value!!!!",dataList);
    try {
     
 
    const monthlyPayment =await getSheduledPayment(AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt)
    
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
      return newDataList;
    });
    // Update the result array with the new values
//   console.log("<<<>>>>>>",dataList)
//       setResult((prevResult) => {
//         const newArray = [...prevResult]; // Create a copy of the existing array
//         newArray[index] = response.data.MonthlyPayment; // Modify the value at the specified index
//         return newArray; // Set the updated array
       
//       });
     
   
   const SheduledPayment= dataList[index].SheduledResult;

    const data = await getSecondResult(AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt,SheduledPayment)
  //  console.log("second result",response2);
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].lowerResults = {
        TotalAmountPaid: data.TotalPrinciple,
        TotalInterestPaid:data.TotalInterest,
        TotalPrinciplePaid: data.TotalPayment,
        
      };
      return newDataList;
    });

// console.log("result2",dataList)
//     const updatedResult2 = [...result2];
//     updatedResult2[index].Totalinterest =response2.data.TotalInterest;
//     updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
//     updatedResult2[index].Totalpayment =response2.data.TotalPayment;
//     setResult2(updatedResult2);

  } catch (error) {
    // Handle any error
  }
};

 
 
  const handleChangeInterest = async (event, index) => {
    const value = event.target.value;
    let rate = (value) / 100;
    var tempdataList=[...dataList]
    tempdataList[index].rate=event.target.value  ;
    setDataList(tempdataList);
    // setInterestRate[index]=rate;
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const Frequency = dataList[index].frequency;
    const Initialpayment = dataList[index].initialPayment;
    const MortgageAmnt = dataList[index].mortgageamount;

  const Term = dataList[index].term;
 // console.log("interestRate,initialPayment,amortznPeriod",interestRate,askingprice,amortznPeriod,FreqParam);
  try {
    const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue,rate,Amortization,Frequency,MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
      });
    //   const newArray = result.slice(); // Create a shallow copy of the array
    //   newArray[index] = monthlyPayment; // Modify the value at the specified index
    //   setResult(newArray);

      const SheduledPayment= dataList[index].SheduledResult;
      const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt,SheduledPayment });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPrinciple,
        TotalInterestPaid: response2.data.TotalInterest,
        TotalPrinciplePaid: response2.data.TotalPayment,
          
        };
        return newDataList;
      });
setShowResultSummary(response2.data.scheduleData)

    //   const updatedResult2 = [...result2];
    //   updatedResult2[index].Totalinterest = response2.data.TotalInterest;
    //   updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
    //   updatedResult2[index].Totalpayment = response2.data.TotalPayment;
    //   setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangePeriod = async (event, index) => {

    const Amortization = parseInt(event.target.value);
    var tempdataList=[...dataList]
    tempdataList[index].period=parseInt(event.target.value)  ;
    setDataList(tempdataList);


   // setAmortznPeriod[index] = amortznPeriod;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate)/100;
    const Frequency = dataList[index].frequency;
    const Term = dataList[index].term;
    const Initialpayment = dataList[index].initialPayment;
    const MortgageAmnt = dataList[index].mortgageamount;
  //  console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
      });
    //   const newArray = result.slice(); // Create a shallow copy of the array
    //   newArray[index] = monthlyPayment; // Modify the value at the specified index
    //   setResult(newArray);
      const SheduledPayment= dataList[index].SheduledResult;
      const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt,SheduledPayment });
      console.log("second result", response2);

      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPrinciple,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPayment,
         
        };
        return newDataList;
      });
    //   const updatedResult2 = [...result2];
    //   updatedResult2[index].Totalinterest = response2.data.TotalInterest;
    //   updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
    //   updatedResult2[index].Totalpayment = response2.data.TotalPayment;
    //   setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangeFreequency = async (event, index) => {

    const Frequency = event.target.value;
    // setFreqParam[index]=FreqParam;
    var tempdataList=[...dataList]
    tempdataList[index].frequency=event.target.value  ;
    setDataList(tempdataList);


    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate)/100;
    const Term = dataList[index].term;
    const Initialpayment = dataList[index].initialPayment;
    const MortgageAmnt = dataList[index].mortgageamount;
  //  console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

    // Handle the response
    const monthlyPayment = response.data.MonthlyPayment
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
      return newDataList;
    });
    // const newArray = result.slice(); // Create a shallow copy of the array
    // newArray[index] = monthlyPayment; // Modify the value at the specified index
    // setResult(newArray);

    const SheduledPayment= dataList[index].SheduledResult;
    const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt,SheduledPayment });
    console.log("second result",response2);
   // const updatedResult2 = [...result2];

    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].lowerResults = {
        TotalAmountPaid: response2.data.TotalPrinciple,
        TotalInterestPaid: response2.data.TotalInterest,
        TotalPrinciplePaid: response2.data.TotalPayment,
       
      };
      return newDataList;
    });
    // updatedResult2[index].Totalinterest =response2.data.TotalInterest;
    // updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
    // updatedResult2[index].Totalpayment =response2.data.TotalPayment;
    // setResult2(updatedResult2);
    // Perform further actions with the response data
  } catch (error) {
    // Handle any error
  }
};
const handleChangeTerm = async(event,index) => {

  const Term = parseInt(event.target.value);

    var tempdataList=[...dataList]
    tempdataList[index].term=parseInt(event.target.value)  ;
    setDataList(tempdataList);

    // setTermVal[index]=parseInt(event.target.value);
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate)/100;
    const Frequency = dataList[index].frequency;
    const Initialpayment = dataList[index].initialPayment;
    const MortgageAmnt = dataList[index].mortgageamount;
   // console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

    // Handle the response
    const monthlyPayment = response.data.MonthlyPayment
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
      return newDataList;
    });
    // const newArray = result.slice(); // Create a shallow copy of the array
    // newArray[index] = monthlyPayment; // Modify the value at the specified index
    // setResult(newArray);
    
    const SheduledPayment= dataList[index].SheduledResult;
    const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt,SheduledPayment });
    console.log("second result",response2);
   // const updatedResult2 = [...result2];
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].lowerResults = {
        TotalAmountPaid: response2.data.TotalPrinciple,
        TotalInterestPaid: response2.data.TotalInterest,
        TotalPrinciplePaid: response2.data.TotalPayment,
       
      };
      return newDataList;
    });
    // updatedResult2[index].Totalinterest =response2.data.TotalInterest;
    // updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
    // updatedResult2[index].Totalpayment =response2.data.TotalPayment;
    // setResult2(updatedResult2);
    // Perform further actions with the response data
  } catch (error) {
    // Handle any error
  }
};
const handleChangeInitialPayment = async(event,index) => {
 
  //console.log("handleChangeInitialPayment ",event.target.value,index,askingprice)

  const Initialpayment = parseInt(event.target.value);

    var tempdataList=[...dataList]
    tempdataList[index].initialPayment=parseInt(event.target.value)  ;
    setDataList(tempdataList);

    // console.log("init......",Initialpayment)
    // setInitialpaymentVal[index]=Initialpayment;
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate)/100;
    const Frequency = dataList[index].frequency;
    const Term = dataList[index].term;
    const MortgageAmnt = (dataList[index].askingprice) - (event.target.value);
    
  //  console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

    // Handle the response
    const monthlyPayment = response.data.MonthlyPayment
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
      return newDataList;
    });
    // const newArray = result.slice(); // Create a shallow copy of the array
    // newArray[index] = monthlyPayment; // Modify the value at the specified index
    // setResult(newArray);
    const SheduledPayment= dataList[index].SheduledResult;
    const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt,SheduledPayment });
    console.log("second result",response2);
   // const updatedResult2 = [...result2];
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].lowerResults = {
        TotalAmountPaid: response2.data.TotalPrinciple,
        TotalInterestPaid: response2.data.TotalInterest,
        TotalPrinciplePaid: response2.data.TotalPayment,
       
      };
      return newDataList;
    });
    // updatedResult2[index].Totalinterest =response2.data.TotalInterest;
    // updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
    // updatedResult2[index].Totalpayment =response2.data.TotalPayment;
    // setResult2(updatedResult2);
    // Perform further actions with the response data
  } catch (error) {
    // Handle any error
  }
};
const handleChangeMortgageAmount = async(index) => {
  const MortgageAmnt = parseInt(mortgageAmountTextValue); 

  //setmortgageamountVal[index]=MortgageAmnt;
 
  const Initialpayment=dataList[index].initialPayment;
  const Amortization = dataList[index].period;
  const AskingPriceValue = dataList[index].askingprice;
  const rate = (dataList[index].rate)/100;
  const Frequency = dataList[index].frequency;
  const Term = dataList[index].term;

  //console.log("interestRate,initialPayment,amortznPeriod",interestRate,askingprice,amortznPeriod);
  try {
    const response = await axios.post(' http://3.145.140.63:5000/calculate', { AskingPriceValue,rate,Amortization,Frequency,MortgageAmnt});

    // Handle the response
    const monthlyPayment= response.data.MonthlyPayment;
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
      return newDataList;
    });
    setMortAmountValue[index] = response.data.MonthlyPayment;
    // console.log("ghghghgh:index",monthlyPayment,index)
    // const newArray = result.slice(); // Create a shallow copy of the array
    //  newArray[index] = monthlyPayment; 
    //  // Modify the value at the specified index
    //  setResult(newArray);
     const SheduledPayment= dataList[index].SheduledResult;
    const response2 = await axios.post(' http://3.145.140.63:5000/calculate2', { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt,SheduledPayment });
    console.log("second result",response2);

    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].lowerResults = {
        TotalAmountPaid: response2.data.TotalPrinciple,
        TotalInterestPaid: response2.data.TotalInterest,
        TotalPrinciplePaid: response2.data.TotalPayment,
       
      };
      return newDataList;
    });
    // const updatedResult2 = [...result2];
    // updatedResult2[index].Totalinterest =response2.data.TotalInterest;
    // updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
    // updatedResult2[index].Totalpayment =response2.data.TotalPayment;
    // setResult2(updatedResult2);
    // Perform further actions with the response data
  } catch (error) {
    // Handle any error
  }
};

useEffect(() => {

  console.log('result has changed:', showResultSummary)
  console.log('datalist has changed:', dataList); 
}, [dataList,showResultSummary]);
  
//console.log("result from parent",result)

// useEffect(() => {
//   myfunction(askingprice)
// }, [askingprice]);

// useEffect(() => { 
// myTermFunction(TermVal)
// }, [TermVal]);

// useEffect(() => {
// myFunctionFreq(FreqParam)
// }, [FreqParam]);


// const myfunction = (arrayp)=>{
// console.log("array:",arrayp)

// }
// const myTermFunction = (arrayTerm)=>{
//   console.log("array:",arrayTerm)
  
//   }

//   const myFunctionFreq = (arrayFreq)=>{
//     console.log("array:",arrayFreq)
    
//     }
  

const triangleStyle = {
  position: 'absolute',
  top: '-10.5px',
  width: '10px',
  height: '20px',
  borderTop: '20px solid transparent',
  borderRight: '20px solid transparent',
  borderBottom: '20px solid #0C276C',
  transform: 'rotate(135deg)',
  
};



 
  return (
    <Grid container sx={{ width: '100%', height: '100%' }}>
      {/* <Box sx={{ flexGrow: 1,height: '726px', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400" }} > */}
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
        <AppBar position="static" sx={{ bgcolor: "#0C276C", height: "56" }}>


          <Toolbar disableGutters>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center', alignItems: 'center', paddingLeft: '18px',
              width:'30%' ,marginLeft:'2.5%'         }}>
            <img src={logo} alt=""  style={{ 

                  height: '100%',
                  width: '90%',
               
                }}
              />
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuToggle}

              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: showMenu ? "block" : "none", position: "absolute", top: "100%", width: "100vw", zIndex: 5,
                bgcolor: "#0C276C", justifyContent: 'flex-start', borderRadius: ' 0px 0px 8px 8px', paddingBottom: '20px'
              }}
            >
              {menus.map((menu) => (
                <Button onClick={handleMenuToggle}
                key={menu.label}
                component={Link}
                to={menu.link}
                  sx={{
                    display: "block", height: '40px', color: "white", textTransform: "capitalize", fontFamily: "poppins",
                    fontSize: "14px", lineHeight: "21px", fontWeight: "400", textAlign: 'left', borderRadius: '0px',
                    '&:hover': { background: "#CFDAF4", width: '100%',color:'#0C276C' }
                  }}
                >
                  {menu.label}
                </Button>
              ))}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '12px', marginLeft: 'auto', marginRight: '18px' }}>
              {/* <Button
                variant="contained" onClick={handleClickSignIn}
                sx={{
                  bgcolor: "#EB5648", color: "#fff", borderRadius: "16px", gap: "10px",
                  alignContent: "center", padding: "4px 10px", textTransform: "capitalize", fontFamily: "poppins", fontSize: "14px",
                  lineHeight: "21px", fontWeight: "400", width: "88px", height: "28px", '&:hover': { bgcolor: "#fff",color:'#0C276C' }, ml: "auto"
                }}
              >
                Sign in
              </Button> */}
            
            </Box>
            {showSignIn && <SignIn />}





          </Toolbar>


        </AppBar>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left', width: 'calc(100%)', marginLeft: '16px',marginRight:'16px', gap: '7%',
    marginTop:'5%' }} >
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 'calc(30%)', }} >
          <img src={ImgFrame} alt="" />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', ajustifyContent: 'center', alignItems: 'center', width: 'calc(70%)' }} >
        <Typography sx={{ fontFamily: "poppins", fontSize: "14px", lineHeight: "18px", fontWeight: 500, width: '100%' }}>
        Welcome to the Universal Loan/Mortgage Calculator! 
            </Typography>
            <Typography sx={{ fontFamily: "poppins", fontSize: "10px", lineHeight: "18px", fontWeight: 400, width: '100%',marginTop:'8px' }}>
            No matter what type of loan you're considering—be it a mortgage/personal loan, auto loan, student loan, or any other installment
             loan—our versatile calculator has got you covered. With just a few simple inputs, such as loan amount, interest rate, loan term, 
             and any additional fees, you can quickly estimate your monthly payments, total interest costs, and even compare various loan terms
              side by side.
            </Typography>
        </Box>
      </Box>

      <Box sx={{
        display: 'flex', flexDirection: 'row', alignItems: 'left', width: 'calc(100%)', marginLeft: '16px', marginRight: '16px',
        borderBottom: '2px solid #F9F9F9'
      }} >

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'left', width: 'calc(100%)', marginBottom: '10px',
      marginTop:'10px' }} >
          <Typography style={{
            fontFamily: "poppins", fontSize: "12px", lineHeight: "18px", fontWeight: "500",
            color: "#313131", display: 'flex', justifyContent: 'left', alignItems: 'left',
          }}> Purchase price<ArrowForwardIcon sx={{ marginLeft: "5px", color: "#313131", fontSize: "16px", lineHeight: "24px", }} /></Typography>

        </Box>

      </Box>
      <Box sx={{
        display: 'flex', flexDirection: 'row', alignItems: 'left', width: 'calc(100%)', marginLeft: '16px', marginRight: '16px'
        , marginTop: '10px'
      }} >
        <TextField className='askingtxt' value={askingPriceValue}   onChange={(event) =>{  handleChangeAskingPriceValue(event,dataList.index)}} variant="outlined" 
                               sx={{ width: "100%", fontFamily: "poppins",  fontSize: "12px", lineHeight: "18px", fontWeight: "400",
                              
                              }}
                               InputProps={{
                                          sx: { height: '38px', color: " #626262" },                                          
                                          type: 'number', min:1, max:10,
                                          '& label': { display: 'flex', alignItems: 'left',justifyContent: 'left'}
                   
                                        }}
                              
                   /> 

      </Box>
     
      <Box sx={{
            display: 'flex', flexDirection: 'row' ,width:'calc(100%-32px)' ,marginLeft:"16px",marginRight:"16px",
             alignItems: 'center',marginTop:'15px'
          }}>
             {/* <Typography sx={{
              fontFamily: 'poppins', fontSize: '16px', 
              lineHeight: '24px', fontWeight: '600', color: '#313131'
             }}>Input parameters</Typography> */}
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '600', color: '#313131', marginRight: 'auto',
            }}>Input parameters</Typography> </Box>


<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',width:'calc(100%-32px)' ,marginLeft:"16px",marginRight:"16px"
        , marginTop:"20px" ,
       }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', minWidth:'110px' ,maxWidth:"100px" ,width:'30%',
            alignItems: 'center', gap:'2%' ,height: "300px", 
          }}>
              <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'-2px',
          }}>
           <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Mortgage options</Typography></Box>
            <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'12px',
          }}>
             
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Purchase price</Typography> <HelpIcon onClick={handleAskingPriceTextClick}  sx={{
              fontSize: '10px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showAskingPriceText && (  <Box
            sx={{
              position: 'absolute', top:'54.5%', left: '23.5%',borderRadius:'8px',zIndex: 9999,
                background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
               <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>This shows the total amount to be paid to seller/builder</Typography></Box>)}
             </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'12px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Initial payment</Typography>  <HelpIcon onClick={handleInitialPaymentTextClick}   sx={{
              fontSize: '10px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showInitialpaymentText && (  <Box
            sx={{
              position: 'absolute', top:'58.3%', left: '23.5%',borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
                 <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Portion of  purchase price arranged from own fund , not from mortgage loan</Typography></Box>)}
             </Box>
            
           
             <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'11px',
          }}>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}> Mortgage </Typography>  <HelpIcon onClick={handleMortgageAmountTextClick}  sx={{
              fontSize: '10px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showMortgageAmountText && (  <Box
            sx={{
              position: 'absolute', top:'62.5%', left: '23.5%',borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >  
           <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Required Mortgage Amount</Typography></Box>)}
             </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'13px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Term</Typography> <HelpIcon onClick={handleTermClick}  sx={{
              fontSize: '10px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showTermText && (  <Box
            sx={{
              position: 'absolute', top:'66.3%', left: '23.5%',borderRadius:'8px',zIndex: 9999,
                background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
                 <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>The number of years will be engaged with proposed mortgage</Typography></Box>)}
             </Box>
          
            
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'13px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px', 
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Interest </Typography> <HelpIcon onClick={handleAnnualInterestRateClick}  sx={{
              fontSize: '10px',cursor:'pointer',
              color: '#878787'
            }} /> 
             {showAnnualInterestRateText && (  <Box
            sx={{
              position: 'absolute', top:'70%', left: '23.5%',borderRadius:'8px',zIndex: 9999,
                background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
                 <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Annual interest rate for this mortgage</Typography></Box>)}
            </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:"11px",
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}> Amortization</Typography><HelpIcon onClick={handleAmortizationTextClick}  sx={{
               fontSize: '10px', color: '#878787',            cursor:'pointer' }} />
             {showAmortizationText && (  <Box
            sx={{
              position: 'absolute', top:'74%', left: '23.5%',borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          > 
               <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
          <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>The number of years & months over which you will repay this loan</Typography></Box>)}
            
            </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:"4px",
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131',marginRight: 'auto',
            }}> Payment frequency</Typography><HelpIcon onClick={handlePaymentfreequencyTextClick}  sx={{
              fontSize: '10px', cursor:'pointer',
              color: '#878787'
            }} />
            {showPaymentfreequencyText && (  <Box
            sx={{
              position: 'absolute', top:'78.3%', left: '23.5%',borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          > 
             <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
          <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Monthly, weekly, biweekly(Every two weeks), semi monthly(24 x per year), Accelerated BiWeekly(Reducing Amortization Period
              by making extra payment, half  of monthly payment on every two weeks.), Accelerated Weekly(Another accelerated option, one fourth of monthly payment on weeks.)</Typography></Box>)}
            </Box>
             
          </Box>
         
<Box sx={{width:'100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft:'3px' 
           ,justifyContent:'center',gap:'4.5%' ,marginTop:'-20px'
          }}>
         {dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex).map((data, index) => (
            <Box key={index} className="slide"  sx={{width:'100%'}} >
              <DivComponents dataList={dataList} setDataList={setDataList}
                index={index} parentIndex={index} CheckboxEnabled={CheckboxEnabled} setCurrentSlideIndex={setCurrentSlideIndex}
                currentSlideIndex={currentSlideIndex} mortgageName={data.name}
                isDeleteButtonEnabled={isDeleteButtonEnabled} selectedCheckboxList={selectedCheckboxList}
                setCheckboxSelectedList={setCheckboxSelectedList} askingPriceValue={askingPriceValue} 
                
          
                 handleChangeTerm={handleChangeTerm} handleChangeInterest={handleChangeInterest} handleChangePeriod={handleChangePeriod}
                 handleChangeMortgageAmount={handleChangeMortgageAmount} handleChangeAskingprice={handleChangeAskingprice}
                 handleChangeFreequency={handleChangeFreequency}  handleChangeInitialPayment={handleChangeInitialPayment}
                mortgageAmountTextValue={mortgageAmountTextValue} setMortgageAmountTextValue={setMortgageAmountTextValue}
                askingPriceVariableParent={askingPriceVariableParent}  setAskingPriceVariableParent={setAskingPriceVariableParent} 
              />
            </Box>
          ))}
</Box>


       
          
        </Box>  



        <Box sx={{ display: 'flex', flexDirection: 'row', width: 'calc(100%)', margin: '16px', marginTop: '5px', }}>

        <Box sx={{
            display: 'flex', flexDirection: 'row', width:'30%',
             alignItems: 'center',minWidth:'110px',maxWidth:'100px'
          }}>
           <Typography style={{
           fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
            color: " #313131",marginRight: 'auto',
          }}> Scheduled Payment</Typography><HelpIcon onClick={handlePaymentSheduleTextClick1}  sx={{
            fontSize: '10px', cursor:'pointer',
            color: '#878787'
          }} />
          {showPaymentSheduleText1 && (  <Box
          sx={{
            position: 'absolute', top:'85%', left: '23.5%',borderRadius:'8px',zIndex: 9999,
            background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
          }}        >
             <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
           <Typography sx={{
            fontFamily: 'poppins', fontSize: '12px',
            lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',
          }}>Required payment  on each scheduled payment date</Typography></Box>)}
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center' ,gap:"5%",
           justifyContent:'center',width:'100%',marginLeft:'3px'
          }}>
          {dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex).map((value, index) => (
  <Box key={index} sx={{
    display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', border:dataList[index].isSelected  ?"1px solid #0C276C" : "1px solid #CFC9C9", 
    alignItems: 'center',borderRadius:'4px',height:"28px"
  }}>
    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
      color: " #313131",width:'100%',textAlign:'center'
    }}>{value.SheduledResult}</Typography>
  </Box>
))}
</Box>
</Box>

{!CheckboxEnabled && <Box sx={{
        display: 'flex', flexDirection: "row", width: '100%',  alignItems: 'right',
        justifyContent: 'right', alignContent: 'flex-end',
      }}>
       <Button onClick={handleAddSlide} disabled={dataList.length === 7} variant="contained" style={{
  color: "white", height: '30px',
  marginTop:IsChevorButton ? "40px": "40px", borderRadius: "12px", background: isHoveredAddMortgage && dataList.length === 7
    ? '#939393' : isHoveredAddMortgage ? '#074554' : '#0C276C',
  border: dataList.length === 7 ? "1px solid #939393" : "1px solid #0C276C", fontFamily: "Roboto",
  textTransform: "none", fontStyle: "normal", fontWeight: "500", fontSize: "12px",
}} onMouseEnter={handleMouseEnterAddMortgage}
  onMouseLeave={handleMouseLeaveAddMortgage}
  startIcon={<AddCircleOutlineIcon style={{ color: "white", fontSize: "12px" }} />}>
Add more options
</Button>
      </Box>}
      {IsChevorButton && (<Box sx={{
        display: 'flex', flexDirection: "row", width: '100%',  alignItems: 'right',
        justifyContent: 'right', alignContent: 'flex-end', 
      }}>
       <ChevronLeftIcon onClick={handlePrevSlide} disabled={currentSlideIndex === 2} style={{
          background:  "#CFDAF4", marginRight: '4%', zIndex: 1,
          color: currentSlideIndex === 2 ? "#CCCCCC" :  "#0C276C", boxShadow: "none ", borderRadius: "50%",
          fontSize: "26px"
        }} ></ChevronLeftIcon>

       <ChevronRightIcon onClick={isdisableAddSlidebutton ? null : handleNextSlide} style={{
          background: "#CFDAF4", zIndex: 1,
          boxShadow: "none", color: isdisableAddSlidebutton ? "#CCCCCC" :  "#0C276C", borderRadius: "50%",
          fontSize: "26px"
        }} ></ChevronRightIcon>

      </Box>)}

         <Box className="carousel" sx={{
          display: 'flex', flexDirection: "row", alignItems: "center",  width:'calc(100%-32px)',marginTop:IsChevorButton ?'-25px':"-40px",
          marginRight:"16px",marginLeft:"16px", height: '240px',
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width:'17.7%'  , height: '240px',
           minWidth:"110px", alignItems: 'center', gap: '6%',
          }}>
              < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'60px'
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}>Mortgage options</Typography>   </Box>
           < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'-8px'
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}>Scheduled Payment</Typography> <HelpIcon onClick={handlePaymentSheduleTextClick2} sx={{
              fontSize: '10px', cursor:'pointer',
              color: '#878787'
            }} />
            {showPaymentSheduleText2 && (  <Box
            sx={{
              position: 'absolute', top:'102%', left: '23.5%',borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          > 
             <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
          <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Required payment  on each scheduled payment date</Typography></Box>)}
             </Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:"-2px"
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}> Total principal</Typography><HelpIcon onClick={handleTotalPrinciplePaidTextClick} sx={{
              fontSize: '10px', cursor:'pointer',
              color: '#878787'
            }} />
            {showTotalPrinciplePaidText && (  <Box
            sx={{
              position: 'absolute', top:'105.8%', left: '23.5%',borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
                 <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>The total amount of principal payment made during the Term and Amortization period respectively</Typography></Box>)}</Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'3px'
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
              color: " #313131",marginRight: 'auto'
            }}>Total interest </Typography> <HelpIcon onClick={handleTotalInterestPaidTextClick}  sx={{
              fontSize: '10px', cursor:'pointer',
              color: '#878787'
            }} />
            {showTotalInterestPaidText && (  <Box
            sx={{
              position: 'absolute', top:'110.3%', left: '23.5%',borderRadius:'8px',zIndex: 9999,
                background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
                 <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Total of all interest paid during the Term  period respectively assuming that the conditions of your loan
            </Typography></Box>)}</Box>
            < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'3px'
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}>Total amount</Typography><HelpIcon onClick={handleTotalAmountPaidTextClick}  sx={{
              fontSize: '10px', cursor:'pointer',
              color: '#878787'
            }} />
            {showTotalAmountPaidText && (  <Box
            sx={{
              position: 'absolute', top:'114.5%', left: '23.5%',borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          > 
               <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
          <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Total of all payments made during the Term and Amortization period respectively, assuming that the conditions of your loan
             (e.g. interest rate, amortization period, term, etc.) will not change during these periods.</Typography></Box>)}</Box>
              

          </Box>
          <Box sx={{width:'calc(100%)', display: 'flex', flexDirection: 'row', alignItems: 'center',marginLeft:'3px' 
           ,justifyContent:'center',gap:'4.5%' 
          }}>

{dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex)

.map((slide, index) => (
  <Box key={index} className="slide" sx={{width:'calc(100%)'}}  >
    <Payment dataList={dataList} setDataList={setDataList} index={index} mortgageName={slide.name} onChange={() => handleNextCheckboxChange(slide.name)}
      isCheckedCheckboxNext={selectedCheckboxList.includes(slide.name)} 

    />
  </Box>
))}

       
</Box>

        </Box>

      {/* {calcContent === "Penalty Content" &&
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left', width: 'calc(100%)', margin: '16px', marginBottom: '-40px' }}>

          <Box sx={{
            display: 'flex', flexDirection: 'column', width: 'calc(32%)', marginTop: '68px',
            height: '220px', alignItems: 'left', gap: '22px',
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400", justifyContent: 'space-between', display: 'flex',
              color: " #313131"
            }}>Cancellation <HelpIcon style={{ fontSize: "10px", color: "#878787", marginTop: '5px' }} /> </Typography>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400", justifyContent: 'space-between', display: 'flex',
              color: " #313131", marginTop: '5px'
            }}>Remaining principle<HelpIcon style={{ fontSize: "10px", color: "#878787", marginTop: '12px' }} /></Typography>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400", justifyContent: 'space-between', display: 'flex',
              color: " #313131", marginTop: '-7px'
            }}>Calculated penalty<HelpIcon style={{ fontSize: "10px", color: "#878787", marginTop: '12px' }} /></Typography>

          </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', width: 'calc(68% )',
            height: '220px', alignItems: 'center',
          }}>
            {dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex)
              .map((slide, index) => (
                <Box sx={{ width: 'calc(100% )', marginLeft: '3%' }} key={index} className="slide">
                  <Penalty dataList={dataList} index={index} mortgageName={slide.name} onChange={() => handleNextCheckboxChange(slide.name)}
                    isCheckedCheckboxNext={selectedCheckboxList.includes(slide.name)} />
                </Box>
              ))}
          </Box>
        </Box>} */}



      {/* {thebidding === "bidding disable" && <Box
        sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', Height: '80%', gap: '7px',
          width: 'calc(100% - 30px)', marginLeft: '16px', marginRight: '16px', boxShadow: ' 0px -4px 4px rgba(217, 217, 217, 0.25)',
          backgroundColor: 'white'
        }}
      >
        <Box
          sx={{
            width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center',

          }}

        >

          <Button variant="contained" onMouseEnter={handleMouseEnterTheBidding}
            onMouseLeave={handleMouseLeaveTheBidding} onClick={handleAddBiddingCheckbox} style={{
              color: "white", textTransform: "none",
              background: isHoveredTheBidding ? "#074554" : "#0C276C", border: "1px solid #0C276C", fontFamily: "Poppins",
              fontStyle: "normal", lineHeight: "21px", height: '36px',
              fontWeight: "500", fontSize: "14px", width: "100%", borderRadius: "4px",
            }} startIcon={<img src={dataicon} alt="" />} >
            Start the bidding
          </Button>


        </Box>
        <Box
          sx={{
            width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', gap: '10px',

          }}

        >
          <Box
            sx={{
              width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center',
            }}  >
            <Button variant="contained" onMouseEnter={handleMouseEnterSendToMail}
              onMouseLeave={handleMouseLeaveSendToMail} style={{
                color: isHoveredSendToMail ? "white" : "#0C276C", textTransform: "none", padding: "5px 8px 5px 8px ",
                background: isHoveredSendToMail ? "#0C276C " : "#CFDAF4", border: "1px solid #0C276C", fontFamily: "Poppins", fontStyle: "normal",
                lineHeight: "21px", minWidth: '142px', fontWeight: "500", fontSize: "14px", width: "100%", borderRadius: "4px", marginBottom: '15px'
              }}
              startIcon={<MailOutlineIcon style={{ color: isHoveredSendToMail ? "white" : "#0C276C", }} />}>
              Send to email
            </Button></Box>
          <Box
            sx={{
              width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center',
            }}  >
            <Button variant="contained" onMouseEnter={handleMouseEnterDownloadResult}
              onMouseLeave={handleMouseLeaveDownloadResult} style={{
                color: isHoveredDownloadResult ? "white" : "#0C276C", textTransform: "none", padding: "5px 8px 5px 8px ",
                background: isHoveredDownloadResult ? "#0C276C " : "#CFDAF4", border: "1px solid #0C276C", fontFamily: "Poppins", fontStyle: "normal",
                lineHeight: "21px", minWidth: '174px',
                fontWeight: "500", fontSize: "14px", width: "100%", borderRadius: "4px", marginBottom: '15px'
              }} startIcon={<GetAppIcon style={{ color: isHoveredDownloadResult ? "white" : "#0C276C", }} />}>
              Download result
            </Button>
          </Box>

        </Box>

      </Box>}
      {thebidding === "bidding enable" && <Box sx={{
        display: 'flex', flexDirection: "row", marginLeft: "16px", marginBottom: '25px',marginRight:'16px',width: 'calc(100% - 30px)',
        justifyContent: 'center'
      }}>



        <Button variant="contained" onClick={handleNextClick} disabled={!isNextButtonEnabled} style={{
          color: "white", textTransform: "none",
          background: isHoveredNext && isNextButtonEnabled
            ? '#074554' : !isNextButtonEnabled ? '"#CFDAF4"' : '#0C276C', border: !isNextButtonEnabled ? "1px solid #CFDAF4" : "1px solid #0C276C",
          fontFamily: "Poppins", fontStyle: "normal",
          lineHeight: "21px", fontWeight: "500", fontSize: "14px", width: "348px", borderRadius: "4px", height: '36px',
        }} onMouseEnter={handleMouseEnterNext}
          onMouseLeave={handleMouseLeaveNext} >
          Next<ArrowForwardIcon style={{ marginLeft: "5px", color: "white", fontSize: "16px" }} />
        </Button>
        {showPopupforCheckbox && (<CheckboxPopUp ></CheckboxPopUp>)}



      </Box>} */}

<Box sx={{ display: 'flex', flexDirection: 'row', width: 'calc(100%)', margin: '16px', marginTop: '25px', }}>

<Box sx={{
    display: 'flex', flexDirection: 'row', width:'30%',
     alignItems: 'center',minWidth:'110px',maxWidth:'100px'
  }}></Box>
  <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center' ,gap:"5%",
           justifyContent:'center',width:'100%'
          }}>
 {dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex)

.map((slide, index) => (
          <Button id={index} variant="contained"  onClick={function () { handleSummary(slide.name); }}
            style={{
              color: !isHoveredDownloadResult ? "white" : "#EB5648", textTransform: "none", height: '36px',padding:'5px',
              background: isHoveredDownloadResult ? "white " : "#EB5648", border: "1px solid #EB5648", fontFamily: "Poppins",
              fontStyle: "normal", lineHeight: "18px", fontWeight: "500", fontSize: "12px", width: "50%", borderRadius: "4px",
            }}
            onMouseEnter={() => handleMouseEnterPaymentButton(index)}
            onMouseLeave={() => handleMouseLeavePaymentButton(index)}
            >
            Payment Schedule
          </Button>
           ))}
</Box>
        </Box>
        {showPopupforSummary && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '1200px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '2'
            }}
          >
            <Box
              sx={{
                position: 'absolute',

                width: '80%',
                height: '80%',
                overflowY: 'auto',
                backgroundColor: '#fff',
                padding: "25px",
                border: '1px solid #CFC9C9',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                boxSizing: 'border-box',                zIndex: '3'
              }}
            >
              <IconButton onClick={handleCancel} style={{
                color: '#939393', position: 'absolute', width: '20px', height: '20px',
                left: '90%', top: '8px'
              }} >
                <HighlightOffIcon ></HighlightOffIcon>
              </IconButton>
              
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, marginTop:'10px' }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#0C276C', }}>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '500', color: '#ffffff', textAlign: 'center' }}>Period</TableCell>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '500', color: '#ffffff', textAlign: 'center' }}>Principal Payment</TableCell>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '500', color: '#ffffff', textAlign: 'center' }}>Interest Payment</TableCell>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '500', color: '#ffffff', textAlign: 'center' }}>Total Payment</TableCell>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '500', color: '#ffffff', textAlign: 'center' }}>Ending Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {

                      showResultSummary.map((row, index) => (
                        <TableRow
                          key={index}
                         
                          sx={{
                            '&:nth-of-type(odd)': {
                              backgroundColor: '#F3F3F3',
                            },
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                      
                          }}
                        >
                         
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center',   padding:'5px !important'
                          }}>{row.frequency} {row.month}</TableCell>
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center',  padding:'5px !important'
                          }}>{row.principlePayment}</TableCell>
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center',  padding:'5px !important'
                          }}>{row.interestPayment}</TableCell>
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center',  padding:'5px !important'
                          }}>{row.totalPayment}</TableCell>
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center',  padding:'5px !important'
                          }}>{row.EndingBalance}</TableCell>
                        </TableRow>
                      ))}

                  </TableBody>
                </Table>
              </TableContainer></Box></Box>)}
        {showPopupforCheckbox && (<CheckboxPopUp ></CheckboxPopUp>)}
       

    </Grid>
  );
}

export default AddMortgageMobile;
