import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AddMortgageCalculatePayment from './components/AddMortgageCalculatePayment';
import MortgageAgent from './components/MortgageAgent';
import AddMortgageCalculatePenalty from './components/AddMortgageCalculatePenalty';
import AgentLanding from './components/AgentsLanding';
import SendQuotes from './components/SendQuotes';
import CheckboxPopUp from './components/CheckboxPopUp';
import AddMortgageMobile from './components/MortgageAgentMobile';
import MortgageDetails from "./pages/MortgageDetails";
import MortgageUserDetails from "./pages/MortgageUserDetails";
import AgentLandingMobile from './components/AgentLandingMobile'
import UserDetailsMobile from './components/AgentLandingUserDetails';
import AgentLandingDetails from './pages/AgentLandingDetails';
import SendQuotesMobile from './components/SendQuotesMobile';
import SendQuotesDetails from "./pages/SendQuotesDetails";
import UsersNotification from './components/UsersNotification';
import UsersNotificationMobile from './components/UserNotificationMobile';
import ListViewOfRecievedQtnMobile from './components/ListViewOfRecievedQtnMobile';
import UsersNotifications from "./pages/UserNotifications";
import AgentsNotifications from "./pages/AgentsNotifications";
import RateAndComment from './components/RateAndCommentMobile';
import AddMortgageTab from './components/AddMortGageTab';
import MortgageUser from './components/MortgageUser';
import MortgageUserMobile from './components/MortgageUserMobile';
import './App.css';
import PenaltyCalculation from './components/penaltycalculation';
import Modaldelete from './components/modaldelete';
import Receivedqtn1 from './components/receivedqtn-modified';
import Negotiatemodal from './components/negotiatemodal-modified';
import Discussion from './components/discussionmob';
import Agentprofile1 from './components/agentprofilemob';
import Userprofile from './components/userprofilemob';
import HeaderMod from './components/header-modified';
import Header1Mod from './components/header1-modified';
import FooterMod from './components/footer-modified';
import ResetMob from './components/resetmob';
import ResetlinkMob from './components/resetlinkmob';
import EnterotpMod from './components/enterotp-modified';
import QuotesMod from './components/quotes-modified';
import ModalsendtoemailMod from './components/modalsendtomail-modified';
import GetquotesMod from './components/getquotes-modified';
import NegotiatemodalMod from './components/negotiatemodal-modified';
//import UserprofileMod from './components/userprofile-modified';
import RateandcommentMob from './components/rateandcommentmob';
import SigninMob from './components/SignIn';
import SignupMob from './components/SignUp';
import PersonalchatuserMob from './components/personalchatusermob';
import MyprofileMob from './components/myprofilemob';
import ChangepwdMob from './components/changepwdmob';
import CreatepwdMob from './components/createpwdmob';
import PersonalchatagentMob from './components/personalchatagentmob';
import DiscussionroomMob from './components/discussionroommob';
import UserprofileMob from './components/userprofilemob';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import UniversalCalculator from './pages/UniversalCalculatorDetails';
import Footer from './components/Footer';
import FooterAboutUs from './components/FooterAboutUs';
import Disclaimer from './components/Disclaimer';
import PrivacyPolicy from './components/PrivacyPolicy';
import Articles from './components/Articles';
import StepsToGet from './components/StepsToGetBlog';
import TermsOfUse from './components/TermsOfUse';
import Projects from './components/Projects';
import NewProjectForm from './components/NewProjectForm';
import BuilderSignUp from './components/BuilderSignUp';
import PersonalProjects from './components/PersonalProjects';
import ResetPassword from './components/ResetPassword';
import AdminPage from './components/AdminPage';
import AddBuilderDetails from './components/AddBuilderDetails';
import EditSignUp from './components/EditSignUp';
import NeeholaHeader from './components/NeholaHeader'
import UniversalCalculatorNew from './components/UniversolCalculatorNew';
import CanadaMortgage from './components/CanadaMortgage';
import Blogs from './components/Blogs';
import BlogDetail from './components/BlogDetail';
import { HelmetProvider } from 'react-helmet-async';

function App() {

  const dbLink='https://backend.ratewaves.com';
  
  return (

   
    <div style={{ height: '100%' }}>
      <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<UniversalCalculatorNew />} />
          <Route path='/addmortgagepayment' element={<AddMortgageCalculatePayment />} />
          {/* <Route path='/universal' element={<UniversalCalculator />} /> */}
          <Route path='/blog' element={<Blogs />} />          
          <Route path="/blog/:blogTitle" element={<BlogDetail />} />
          <Route path='/footer' element={<Footer/>} />
          {/* <Route path='/articles' element={<Articles/>} />
          <Route path='/nehola-header' element={<NeeholaHeader/>} /> */}
          <Route path='/projects' element={<Projects/>} />
          {/* <Route path='/admin-check' element={<AdminPage/>} />
          <Route path='/edit-profile' element={<EditSignUp/>} />
          <Route path='/add-builder-details' element={<AddBuilderDetails/>} /> */}
          <Route path='/personal-projects' element={<PersonalProjects/>} />
          <Route path='/newprojectform' element={<NewProjectForm/>} />
          <Route path='/builder-signup' element={<BuilderSignUp/>} />
          <Route path='/reset-password' element={<ResetPassword/>} />
          {/* <Route path='/steps-to-get' element={<StepsToGet/>} /> */}
          <Route path='/disclaimer' element={<Disclaimer/>} />
          <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
          <Route path='/terms-of-use' element={<TermsOfUse/>} />
          {/* <Route path='/footer-aboutus' element={<FooterAboutUs/>} />
          <Route path='/canada-mortgage-old' element={<MortgageUserDetails />} /> */}
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/contact-us' element={<ContactUs />} />
          {/* <Route path='/mortgageuserDesk' element={<MortgageUser />} />
          <Route path='/mortgageusermobile' element={<MortgageUserMobile />} />
          <Route path='/mortgageagent' element={<MortgageAgent />} /> 
          <Route path='/addmortgagepenalty' element={<AddMortgageCalculatePenalty />} /> */}
          <Route path='/agentlanding' element={<AgentLanding />} />
          {/* <Route path='/userdetails' element={<UserDetailsMobile />} />
          <Route path='/sendquotes' element={<SendQuotes />} />
          <Route path='/check' element={<CheckboxPopUp />} /> */}
          <Route path='/addmortgagemobile' element={<AddMortgageMobile />} />
          <Route path='/universal-calcualtor' element={<MortgageDetails />} />
          <Route path='/agentlandingmobile' element={<AgentLandingMobile />} />
          <Route path='/agentlandingdetails' element={<AgentLandingDetails />} />
          {/* <Route path='/sendquotesmobile' element={<SendQuotesMobile />} />
          <Route path='/sendquotesdetails' element={<SendQuotesDetails />} />
          <Route path='/usersnotification' element={<UsersNotification />} />
          <Route path='/usersnotificationmobile' element={<UsersNotificationMobile />} />
          <Route path='/listviewofrecievedqtnmobile' element={<ListViewOfRecievedQtnMobile />} />
          <Route path='/usersnotifications' element={<UsersNotifications />} />
          <Route path='/agentsnotifications' element={<AgentsNotifications />} />
          <Route path='/rateandcomment' element={<RateAndComment />} />
          <Route path='/addmortgagetab' element={<AddMortgageTab />} />
          <Route path='/penaltycalculation' element={<PenaltyCalculation />} />
          <Route path='/modalsendtoemail' element={<ModalsendtoemailMod />} />
          <Route path='/reset' element={<ResetMob />} />
          <Route path='/createpwd' element={<CreatepwdMob />} />
          <Route path='/changepwd' element={<ChangepwdMob />} />
          <Route path='/resetlink' element={<ResetlinkMob />} />
          <Route path='/quotes' element={<QuotesMod />} />
          <Route path='/enterotp' element={<EnterotpMod />} />
          <Route path='/getquotes' element={<GetquotesMod />} />
          <Route path='/modaldelete' element={<Modaldelete />} />
          <Route path='/receivedqtn' element={<Receivedqtn1 />} />
          <Route path='/negotiatemodal' element={<NegotiatemodalMod />} />
          <Route path='/personalchatuser' element={<PersonalchatuserMob />} />
          <Route path='/personalchatagent' element={<PersonalchatagentMob />} />
          <Route path='/myprofile' element={<MyprofileMob />} />
          <Route path='/discussionroom' element={<DiscussionroomMob />} />
          <Route path='/agentprofile' element={<Agentprofile1 />} />
          <Route path='/userprofile' element={<UserprofileMob />} />
          <Route path='/discussion' element={<Discussion />} />
          <Route path='/rateandComment' element={<RateandcommentMob />} />
          <Route path='/header' element={<HeaderMod />} />
          <Route path='/header1' element={<Header1Mod />} /> */}
          <Route path='/signup' element={<SignupMob />} />
          <Route path='/signin' element={<SigninMob />} />
          {/* <Route path='/footermod' element={<FooterMod />} /> */}
          <Route path='/universal-calculator' element={<UniversalCalculatorNew />} />
          <Route path='/canada-mortgage' element={<CanadaMortgage />} />
        </Routes>
      </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}
export default App;
