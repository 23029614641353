import React, { useState } from 'react'
import Header1 from './header1-modified'
import { Box, Button, IconButton, InputAdornment, OutlinedInput, Stack, Tab, Tabs, Typography } from '@mui/material'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Mortgage from './images/mortgage.png';
import PersonIcon from '@mui/icons-material/Person';
import Edit from './images/edit.png';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpIcon from '@mui/icons-material/Help';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Header2 from './header2-modified';
function AgentprofileMob() {
    
  return (
    <div style={{ overflow: 'hidden' }}>
    <Header2 />
    <Box sx={{ height: '848px', width: '1366px', paddingLeft: '52px', paddingRight: '52px', paddingTop: '10px', paddingBottom: '52px' }}>
      <Typography sx={{ fontWeight: 500, fontSize: '20px', color: '#494949', fontFamily: 'Poppins' }}>Agent profile</Typography>
      <Box sx={{ width: '1262px', height: '744px', backgroundColor: '#F8F8F8', borderRadius: '8px', marginTop: '14px', paddingLeft: '30px', paddingTop: '36px',alignItems:'center',justifyContent:'space-between' }}>
      <Box sx={{ paddingRight: '182px', display: 'flex', flexDirection: 'row-reverse',alignItems:'center',justifyContent:'space-between' }}>
        
        
          <Button startIcon={<img src={Edit} />} sx={{
            width: '76px', height: '26px', border: '1px solid #1F6373', textTransform: 'none', color: '#1F6373', backgroundColor: 'white', borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#D2E0E3',
              color: '#1F6373',
              border: '1px solid #1F6373',
            },

            '&:active': {

              backgroundColor: '#1F6373',
              color: 'white',
            },
          }}>Edit</Button>
        </Box>
        
            <Box sx={{ width: '1262px', height: '542px',alignItems:'center',justifyContent:'center',alignContent:'center', display: 'flex', flexDirection: 'row', marginTop: '60px' }}>
        
                <Box sx={{ width: '382px', backgroundColor: 'white', height: '542px', borderRadius: '8px', paddingTop: '38px', paddingLeft: '16px', paddingRight: '20px', paddingBottom: '22px', alignContent: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    <Box sx={{ height: '40px', width: '40px', borderRadius: '40px', border: '1px solid #1F6373', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                      <PersonIcon sx={{ color: '#1F6373', height: '40px', width: '40px' }} />
                    </Box>
                    <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#212427', fontFamily: 'Poppins', marginTop: '8px' }}>Robert</Typography>
                    <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#212427', fontFamily: 'Poppins', marginTop: '10px' }}>User ID : 1167593</Typography>
                  </div>
                  <Typography sx={{ textAlign: 'left', fontSize: '14px', marginTop: '48px', color: '#939393', paddingBottom: '4px', fontFamily: 'Poppins', fontWeight: 400 }}>Email id</Typography>
                 
                  <Typography sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '14px', marginTop: '8px', color: '#212427', fontWeight: 400, paddingBottom: '4px', fontFamily: 'Poppins' }}>
                    johndoe@gmail.com
                  </Typography>
                  <Typography sx={{ textAlign: 'left', fontSize: '14px', marginTop: '24px', color: '#939393', paddingBottom: '4px', fontFamily: 'Poppins' }}>Mobile number</Typography>
                 
                  <Typography sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '14px', marginTop: '8px', color: '#212427', fontWeight: 400, paddingBottom: '4px', fontFamily: 'Poppins' }}>
                    9493952345
                  </Typography>

                  <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#939393', paddingBottom: '4px', paddingTop: '24px', fontFamily: 'Poppins' }}>Password</Typography>
                  

                    
                  <Typography sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '14px', marginTop: '8px', color: '#212427', fontWeight: 400, paddingBottom: '4px', fontFamily: 'Poppins' }}>
                    ******
                  </Typography>

                  <Typography sx={{ fontSize: '12px', marginTop: '4px', color: '#626262', fontFamily: 'Poppins' }}><span><a href='#' style={{ color: '#626262', fontSize: '12px' }}>Change password?</a></span></Typography>

                </Box>
                </Box>
                
                


              </Box>
    </Box>
    
</div>

  )
}

export default AgentprofileMob;