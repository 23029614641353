import React from 'react';

import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Box, InputLabel, Stack, Typography } from '@mui/material';
import { useState , useEffect  } from 'react';
import DeleteIcon from './images/Component 4.png';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import '../App.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
function Divcomponenet(props) {

  const {  initialPaymentValue,setInitialPaymentValue,dataList, setDataList, mortgageName, CheckboxEnabled, isDeleteButtonEnabled,askingPriceValue,handleChangeTerm,handleChangeInitialPayment,
    currentSlideIndex, setCurrentSlideIndex, setCheckboxSelectedList, selectedCheckboxList, isSelected,handleChangeFreequency,setTermVal,TermVal,setIsSelected,
    handleChangeInterest,handleChangePeriod,handleChangeAskingprice, result,setResult, FreqParam,setFreqParam,amortznPeriod,setAmortznPeriod,setAskingPrice, askingprice,
    handleChangeMortgageAmount,mortgageAmountTextValue,setMortgageAmountTextValue,askingPriceVariableParent,setAskingPriceVariableParent,setFinalPrice,
    downBorderAmount, setdownBorderAmount,setTooltipTitle,tooltipTitle } = props;
const index=dataList.findIndex(x => x.name ===mortgageName);
    const [selectedOptionVariable, setSelectedOptionVariable] = useState(); //AmortizationPeriod Used this
    const [selectedOptionMonths, setSelectedOptionMonths] = useState( );
    const [selectedOptionMonthly, setSelectedOptionMonthly] = useState();
    //const [amortizationMonthly, setAmortizationMonthly] = useState([0]); 
  //   const initialAmortizationMonthly = Array(dataList.length).fill(0);
  // const [amortizationMonthly, setAmortizationMonthly] = useState(initialAmortizationMonthly);
  // const initialTermMonthly = Array(dataList.length).fill(0);
  // const [termMonthly, setTermMonthly] = useState(initialTermMonthly);
  // const initialRateMonthly = Array(dataList.length).fill(0);
  // const [rateMonthly, setRateMonthly] = useState(initialRateMonthly);
  const [tooltipTitlesMonth, setTooltipTitlesMonth] = useState(Array(dataList.length).fill(''));
  const [tooltipTitlestermMonth, setTooltipTitlestermMonth] = useState(Array(dataList.length).fill(''));
  const initialshowErrorBorder = Array(dataList.length).fill(false);
  const [showErrorBorder, setShowErrorBorder] = useState(initialshowErrorBorder);
  const initialshowErrorBorderterm = Array(dataList.length).fill(false);
  const [showErrorBorderterm, setShowErrorBorderterm] = useState(initialshowErrorBorderterm);
    const [showPopuptermamort, setShowPopuptermamort] = useState(false);

  const IsDeleteButton = dataList.length > 4 && isDeleteButtonEnabled;


  const handleCheckboxClickAddMortgage = (mortgageName) => {

    const temp = [...selectedCheckboxList];
    if (temp.includes(mortgageName)) {
      temp.splice(temp.indexOf(mortgageName), 1);
    }
    else {
      temp.push(mortgageName);
    }
    setCheckboxSelectedList(temp);

  };


  const [showPopup, setShowPopup] = useState(false);


  const handleDeleteClick = (mortgageName) => {
    setShowPopup(true);
  };

  const handleDeleteConfirm = (name) => {
    const temp = dataList.filter(dataList => dataList.name !== mortgageName);
    setDataList(temp);
    setShowPopup(false);
    dataList.length = (dataList.length - 1);
    

    if (currentSlideIndex === 4) {
      setCurrentSlideIndex(currentSlideIndex - 0);
    } else {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
    
   
  };
  console.log(currentSlideIndex)

  const handleCancel = () => {
    setShowPopup(false);
  };

  const handleCancelTerm = () => {
    setShowPopuptermamort(false);
   
    var tempdataList=[...dataList];
     tempdataList[index].period=35;
     setDataList(tempdataList);
  };
 
  const handleOptionChangeAmortization = (event,index) => {  
      const AmortYear = parseInt(event.target.value)
      const AmortMonth = dataList[index].amortizationMonth
     var tempdataList=[...dataList];
    tempdataList[index].period= AmortYear ;
    setDataList(tempdataList);
    var tempdataList=[...dataList];
    tempdataList[index].totalAmortization = AmortYear + (AmortMonth/12)  ;
    setDataList(tempdataList); 
    setSelectedOptionVariable(event.target.value);   
    if (event.target.value !== '' && (dataList[index].totalAmortization < dataList[index].totalTerm )) {
     // setShowPopuptermamort(true);
     const newTooltipTitles = [...tooltipTitlesMonth];     
      newTooltipTitles[index] = 'The amortization period must be equal to or greater than the term';    
      setTooltipTitlesMonth(newTooltipTitles);    
      setShowErrorBorder((prevShowErrorBorder) => {
        const newShowErrorBorder = [...prevShowErrorBorder];
        newShowErrorBorder[index] = true;
        return newShowErrorBorder;
      });
    } 
   else if (event.target.value > 35 || event.target.value <= 0) {
      const newTooltipTitles = [...tooltipTitlesMonth];     
      newTooltipTitles[index] = 'Please enter a value between 1 and 35 ';    
      setTooltipTitlesMonth(newTooltipTitles);    
      setShowErrorBorder((prevShowErrorBorder) => {
        const newShowErrorBorder = [...prevShowErrorBorder];
        newShowErrorBorder[index] = true;
        return newShowErrorBorder;
      });
    } else {
      const newTooltipTitles = [...tooltipTitlesMonth];
      newTooltipTitles[index] = '';
      setTooltipTitlesMonth(newTooltipTitles);
      setShowErrorBorder((prevShowErrorBorder) => {
        const newShowErrorBorder = [...prevShowErrorBorder];
        newShowErrorBorder[index] = false;
        return newShowErrorBorder;
      });
    }
    handleChangePeriod((AmortYear + (AmortMonth/12)),index)
  }
  
  const handleOptionChangeAmortizationMonth = (event,index) => {
    var tempdataList=[...dataList];
   tempdataList[index].amortizationMonth=parseInt(event.target.value)  ;
   setDataList(tempdataList);  
   const AmortYear = dataList[index].period
   const AmortMonth = event.target.value
   var tempdataList=[...dataList];
   tempdataList[index].totalAmortization=AmortYear + (AmortMonth/12)  ;
   setDataList(tempdataList); 
   if (event.target.value !== '' && (dataList[index].totalAmortization < dataList[index].totalTerm) ) {
    // setShowPopuptermamort(true);
    const newTooltipTitles = [...tooltipTitlesMonth];     
     newTooltipTitles[index] = 'The amortization period must be equal to or greater than the term';    
     setTooltipTitlesMonth(newTooltipTitles);    
     setShowErrorBorder((prevShowErrorBorder) => {
       const newShowErrorBorder = [...prevShowErrorBorder];
       newShowErrorBorder[index] = true;
       return newShowErrorBorder;
     });
   } 
   else {
    const newTooltipTitles = [...tooltipTitlesMonth];
    newTooltipTitles[index] = '';
    setTooltipTitlesMonth(newTooltipTitles);
    setShowErrorBorder((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = false;
      return newShowErrorBorder;
    });
    const newTooltipTitles1 = [...tooltipTitlesMonth];
    newTooltipTitles1[index] = '';
    setTooltipTitlestermMonth(newTooltipTitles1);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = false;
      return newShowErrorBorder; });
  }
   handleChangePeriod((AmortYear + (AmortMonth/12)),index)
 };

 const handleOptionChangeTermMonth = (event,index) => {
  
  var tempdataList=[...dataList];
  tempdataList[index].termMonth=parseInt(event.target.value)  ;
  setDataList(tempdataList);  
  const TermYear = dataList[index].term
  const TermMonth = event.target.value
  var tempdataList=[...dataList]
    tempdataList[index].totalTerm=TermYear + (TermMonth/12)  ;
    setDataList(tempdataList);
  if (dataList[index].totalAmortization < dataList[index].totalTerm) {
    // Show tooltip for amortization condition
    const newTooltipTitles = [...tooltipTitlesMonth];
    newTooltipTitles[index] = 'The amortization period must be equal to or greater than the term';
    setTooltipTitlestermMonth(newTooltipTitles);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = true;
      return newShowErrorBorder;
    });
  }
  else {
   
    const newTooltipTitles = [...tooltipTitlesMonth];
    newTooltipTitles[index] = '';
    setTooltipTitlestermMonth(newTooltipTitles);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = false;
      return newShowErrorBorder;
    });}
  handleChangeTerm((TermYear + (TermMonth/12)),index)
 
};


  const handleOptionChangePaymentFreequency = (event,index) => { 
    var tempdataList=[...dataList]
    tempdataList[index].frequency=(event.target.value)  ;
    setDataList(tempdataList);
    setSelectedOptionMonthly(event.target.value);
    
    //setFreqParam(event.target.value);
   
    
  };
  const handleOptionChangeTerm = (event,index) => {
    const TermYear = parseInt(event.target.value)
      const TermMonth = dataList[index].termMonth
    var tempdataList=[...dataList]
    tempdataList[index].term=parseInt(event.target.value)  ;
    setDataList(tempdataList);
    var tempdataList=[...dataList]
    tempdataList[index].totalTerm=TermYear + (TermMonth/12)  ;
    setDataList(tempdataList);
   // setSelectedOptionMonths(event.target.value);
   // setTermVal(event.target.value);
   //console.log("totalAmortization",dataList[index].totalAmortization)
  // console.log("totalterm",(TermYear + (TermMonth/12)))
   if (event.target.value !== '' && (dataList[index].totalAmortization < dataList[index].totalTerm)) {
    // Show tooltip for amortization condition
    const newTooltipTitles = [...tooltipTitlesMonth];
    newTooltipTitles[index] = 'The amortization period must be equal to or greater than the term';
    setTooltipTitlestermMonth(newTooltipTitles);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = true;
      return newShowErrorBorder;
    });
  } else if (event.target.value > 35 || event.target.value <= 0) {
    // Show tooltip for value range condition
    const newTooltipTitles = [...tooltipTitlesMonth];
    newTooltipTitles[index] = 'Please enter a value between 1 and 35';
    setTooltipTitlestermMonth(newTooltipTitles);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = true;
      return newShowErrorBorder;
    });
  } else {
    // Clear tooltips and errors if conditions are met
    const newTooltipTitles = [...tooltipTitlesMonth];
    newTooltipTitles[index] = '';
    setTooltipTitlestermMonth(newTooltipTitles);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = false;
      return newShowErrorBorder;
    });
  }
    handleChangeTerm((TermYear + (TermMonth/12)),index)
   
   
  };


const [initialpaymentTextValue, setInitialPaymentTextValue] = useState([]); 
 const [askingPriceTextValue, setAskingPriceTextValue] = useState([]);
 const [askingPriceObjValue, setAskingPriceObjValue] = useState({});
 const [mortValValue, setMortValValue] = useState(10000);
 

 useEffect(() => {
  //setAskingPriceTextValue(askingPriceValue);
  var tempdataList=[...dataList]
  tempdataList[index].askingprice=askingPriceValue  ;
  tempdataList[index].mortgageamount=parseInt(tempdataList[index].askingprice-dataList[index].initialPayment);
  setDataList(tempdataList);

 
}, [askingPriceValue]);
  


  const handleChangeAskingPriceText =(event,index) => { 
  
    var tempdataList=[...dataList]
    tempdataList[index].askingprice=parseInt(event.target.value)  ;    
    setDataList(tempdataList);

    handleChangeInterest(dataList[index].rate, index)
   }

   const handleChangeInterstRate =(event,index) => { 
  
    var tempdataList=[...dataList]
    tempdataList[index].rate= event.target.value ;    
    setDataList(tempdataList);
    // var tempdataList=[...dataList];
    // tempdataList[index].rateMonth=(event.target.value / 12).toFixed(4) ;
    // setDataList(tempdataList);  
    handleChangeInterest(dataList[index].rate, index)
   }
   const handleChangeInterstRateMonth =(event,index) => { 
   var tempdataList=[...dataList];
   tempdataList[index].rateMonth=event.target.value ;
   setDataList(tempdataList);  
   
    const annual = event.target.value * 12
    var tempdataList=[...dataList]
    tempdataList[index].rate=parseFloat(annual)  ;    
    setDataList(tempdataList);
    handleChangeInterest(dataList[index].rate, index)
   } 
  
   
   const handleChangeInitialPaymentText =(event,index) => {  
    setdownBorderAmount(false)
  

  const newValue = parseInt(event.target.value);
  const tempdataList = [...dataList];

  if (newValue >= tempdataList[index].askingprice) {
    tempdataList[index].initialPayment=event.target.value  ;  
    tempdataList[index].downBorderAmount = true;
    setTooltipTitle("Please enter a value less than the Purchase price");
  } else {
    tempdataList[index].downBorderAmount = false; // Reset the flag
    tempdataList[index].initialPayment=parseInt(event.target.value)  ;   
    tempdataList[index].mortgageamount=parseInt(tempdataList[index].askingprice)-parseInt(tempdataList[index].initialPayment);
    setDataList(tempdataList);
  }
    
  

  }

  useEffect(() => {
    handleChangeAskingprice(dataList[index].askingprice, index)
  }, [dataList[index].askingprice]
  );
  
 

  const handleMouseEnter = (index) => {
    var tempdataList=[...dataList]
    tempdataList[index].isSelected=true  ;    
    setDataList(tempdataList);
  };

  const handleMouseLeave = (index) => {
    var tempdataList=[...dataList]
    tempdataList[index].isSelected=false  ;    
    setDataList(tempdataList); 
  };

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
 // const [selectedOptionVariable, setSelectedOptionVariable] = useState(null);
  const [show10to15, setShow10to15] = useState(false);
  const handleSubMenuSelect = (value) => {
    setSelectedOptionVariable(value);
    setShow10to15(false);
  };
  const handle10to15MenuClick = () => {   
    setShow10to15(true);
  }; 

console.log("amortization month",tooltipTitlesMonth)

const renderDisplayValue = (value) => {
  switch (value) {
    case 'M':
      return 'Monthly';
    case 'S':
      return 'Semi-monthly';
    case 'W':
      return 'Weekly';
    case 'B':
      return 'Bi-weekly';
    case 'A':
      return 'Acc* weekly';
    case 'Z':
      return 'Acc* Bi-weekly';
    default:
      return value;
  }
};


const [isFocused, setIsFocused] = useState(false);
const [isFocusedTerm, setIsFocusedTerm] = useState(false);
const handleFocus = () => {
  // Set isFocused to true when the TextField is focused
  setIsFocused(true);
};

const handleBlur = () => {
  // Set isFocused to false when the TextField loses focus
  setIsFocused(false);
};
const handleFocusTerm = () => {
  // Set isFocused to true when the TextField is focused
  setIsFocusedTerm(true);
};

const handleBlurTerm = () => {
  // Set isFocused to false when the TextField loses focus
  setIsFocusedTerm(false);
};


  return (


    <Box key={mortgageName} sx={{
      display: 'flex', flexDirection: "column", height:{ md: "365px", sm: "365px",xs:'460px' } ,  alignItems: "center", 
      borderRadius: "8px", gap: '5%',padding:'10%',
       border:dataList[index].isSelected || selectedCheckboxList.includes(mortgageName) ?"1px solid #0C276C !important" : "1px solid #CFC9C9", 
      width:'98.5%',background: dataList[index].isSelected || selectedCheckboxList.includes(mortgageName) ? "#F9F9F9" : "white", 
      justifyContent: 'center', ':hover': {
        border: '1px solid #0C276C !important'
      },  }}
      onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
    >

      {IsDeleteButton &&<Box sx={{ display: 'flex', flexDirection: "row",position: "relative",
     width:'100%',height:'0px',justifyContent:'right',alignItems:'right',
    right:0 ,marginTop:'-25px',marginRight:'-60px' }}> 
      <IconButton onClick={() => handleDeleteClick(mortgageName)} aria-label="delete" size="small">
        <img src={DeleteIcon} alt="delete" sx={{ }} />
      </IconButton></Box>}


      {CheckboxEnabled && <Box sx={{ display: 'flex', flexDirection: "row",position: "relative", marginTop:{md:"-23%",sm:'-30%'} , 
      marginLeft:{md:"99%",sm:'94%'}  }}> 
      <Checkbox checked={selectedCheckboxList.includes(mortgageName)} onChange={() => handleCheckboxClickAddMortgage(mortgageName)}
       style={{
        accentColor: "#0C276C !important", color: selectedCheckboxList.includes(mortgageName) === true ? "#0C276C !important" : "#CFC9C9",
        zIndex: 1, background: selectedCheckboxList ? "#F9F9F9" : "white",
        borderRadius: '0', width: '19px', height: '19px'
      }} />
     </Box>}




      <Typography sx={{
         textAlign: "center", padding: { md: "0px 10px 0px 10px", sm:"0px 0px 0px 0px",xs:"0px 0px 0px 0px" } ,
         fontFamily: "poppins", fontSize:  { md: '14px', sm: '14px',xs:'12px' }, lineHeight: "21px",marginTop:'-7px',
         fontWeight: "600", color: '#0C276C' ,
        // color: "#626262", fontWeight: "400", 
      }}>{mortgageName}
      </Typography>
     
      
      <TextField key={index}  value={isNaN(dataList[index].askingprice) ? '' : String(dataList[index].askingprice)}  
      onChange={(event) =>{ handleChangeAskingprice(event.target.value, index);  handleChangeAskingPriceText(event,index); }} variant="outlined" 
      sx={{
        width: " 100%", fontFamily: "poppins",
         fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",padding:'0 !important',
         '& input': {
         padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
        },
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262" ,fontFamily: "poppins",padding:'0 !important',
          fontSize: "12px !important", lineHeight: "18px", fontWeight: "400", }, type:"number" , min:1, max:10,
          '& label': {
            display: 'flex', alignItems: 'center',padding:'0 !important', justifyContent: 'center'
          }
        }} /> 
  
    
    {dataList[index].downBorderAmount ? (
    
      <Tooltip
       classes={{ tooltip: "custom-tooltip" }}
       title={tooltipTitle} 
        placement="top-start"
        arrow
        disableHoverListener = {!dataList[index].downBorderAmount}
      >
      <TextField  placeholder="0" variant="outlined" value={isNaN(dataList[index].initialPayment) ? '' : String(dataList[index].initialPayment)}  
      onChange={(event) =>{ handleChangeInitialPayment(event, index);handleChangeInitialPaymentText(event, index)}}
        sx={{
        width: " 100%", fontFamily: "poppins",
         fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", '& input': {
         padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
        },
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262",fontFamily: "poppins",border: dataList[index].downBorderAmount ? '1px solid red !important' : '',
           fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",  },type:"number", min:1, max:10,
          '& label': {
            display: 'flex', alignItems: 'center',
            justifyContent: 'center'
          }
        }} />
        </Tooltip>
        ) : ( <TextField  placeholder="0" variant="outlined" value={isNaN(dataList[index].initialPayment) ? '' : String(dataList[index].initialPayment)}  
        onChange={(event) =>{ handleChangeInitialPayment(event, index);handleChangeInitialPaymentText(event, index)}}
          sx={{
          width: " 100%", fontFamily: "poppins",
           fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", '& input': {
           padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
          },
        }}
          InputProps={{
            sx: { height: '28px', color: " #626262",fontFamily: "poppins",border: dataList[index].downBorderAmount ? '1px solid red !important' : '',
             fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",  },type:"number", min:1, max:10,
            '& label': {
              display: 'flex', alignItems: 'center',
              justifyContent: 'center'
            }
          }} />)
        }
<Typography     
      sx={{
        width: " 100%", fontFamily: "poppins",   fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px",
         fontWeight: "400",border:'1px solid #CFC9C9 ',
        height: '28px', color: " #626262",borderRadius:'4px', display: 'flex', alignItems: 'center', 
         justifyContent: 'left', padding:{ md: '12px', sm: '6px',xs:'8px' }
      }} >
        {isNaN(dataList[index].mortgageamount) ? '' : String(dataList[index].mortgageamount)}
        </Typography>
        

      
        <Box sx={{ display: 'flex',gap:'5px',maxWidth:' 100% !important',flexDirection:{ md: 'row', sm: 'row',xs:'column' }  }}>  
 
       <Tooltip
       classes={{ tooltip: "custom-tooltip" }}
        title={tooltipTitlesMonth[index]}
        placement="top-start"
        arrow
        disableHoverListener = {!dataList[index].period}
      >
       <TextField   placeholder={isFocused ? '' : '25 Y'} variant="outlined" 
       value={isNaN(dataList[index].period) ? '' : String(dataList[index].period)}
       
       sx={{
        width:{ md :'calc(50% - 3px)', sm: 'calc(50% - 3px)',xs:'100%' }, fontFamily: "poppins",  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",
        border: showErrorBorder[index] ? '1px solid red' : '',borderRadius:'4px' ,'& input': {
         padding:{ md: '12px', sm: '6px',xs:'8px' }// Override padding for the input element
        },
        '& ::placeholder': {
          color: '#626262' ,opacity:1
        }
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262" ,fontFamily: "poppins",
          fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", },type:"number",'& label': {
            display: 'flex', alignItems: 'center', justifyContent: 'center',
             border : showErrorBorder[index] ? '1px solid red !important' : '',  padding:{ md: '12px !important', sm: '6px !important',xs:'2px !important' }
          }
        }}onChange={(event) =>{ handleOptionChangeAmortization(event,index);
         
         }} onFocus={handleFocus}
         onBlur={handleBlur}   /> 
          </Tooltip>
      <Select value={isNaN(dataList[index].amortizationMonth) ? '' : String(dataList[index].amortizationMonth)} onChange={(event) =>{ handleOptionChangeAmortizationMonth(event,index); }}  
        sx={{
          width:{ md  :'calc(50% + 1px)', sm:  'calc(50% + 1px)',xs:'100%' },height: '28px', fontFamily: 'poppins', 
           fontSize: { md: '12px', sm: '12px',xs:'10px' },
           lineHeight: '18px', fontWeight: '400', 
          color: '#626262', '& .MuiSelect-select': {
           padding:{ md: '12px', sm: '6px',xs:'8px' }// Set padding to 0 for the select input
          }, // Override padding for the input element
          '& .MuiSelect-icon': {
            right: 0,// Set padding to 0 for the select input
          }, 
        }}
       
        displayEmpty       
        MenuProps={{          
          PaperProps: {
            style: {
              onMouseLeave: () => setSelectedOptionVariable(''),
               background: " #F8F8F8", borderRadius: '8px',marginTop:'5px', maxHeight: '250px',
               overflowY: 'auto',
              
            },
            
          },
        }}
      >
        {/* <MenuItem value={0} style={{ display: 'none',  }}>  0 M </MenuItem> */}
        <MenuItem value={0} sx={{
          backgroundColor: selectedOptionVariable === 0 ? '#0C276C !important ' : 'transparent',
          color: selectedOptionVariable === 0 ? '#ffffff' : '#626262', fontFamily: 'poppins', 
           fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px',
          fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(0)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          0 M  </MenuItem> 
        <MenuItem value={1} sx={{
          backgroundColor: selectedOptionVariable === 1 ? '#0C276C !important ' : 'transparent',
          color: selectedOptionVariable === 1 ? '#ffffff' : '#626262', fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px',
          fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(1)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          1 M  </MenuItem>
        <MenuItem value={2} sx={{
          backgroundColor: selectedOptionVariable === 2 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 2 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(2)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          2 M </MenuItem>
        <MenuItem value={3} sx={{
          backgroundColor: selectedOptionVariable === 3 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 3 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(3)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         3 M  </MenuItem>
         <MenuItem value={4} sx={{
          backgroundColor: selectedOptionVariable === 4 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 4 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(4)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          4 M </MenuItem>
        <MenuItem value={5} sx={{
          backgroundColor: selectedOptionVariable === 5 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 5 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(5)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         5 M  </MenuItem>
         <MenuItem value={6} sx={{
          backgroundColor: selectedOptionVariable === 6 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 6 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(6)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          6 M </MenuItem>
        <MenuItem value={7} sx={{
          backgroundColor: selectedOptionVariable === 7 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 7 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(7)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         7 M  </MenuItem>
         <MenuItem value={8} sx={{
          backgroundColor: selectedOptionVariable === 8 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 8 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(8)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          8 M </MenuItem>
        <MenuItem value={9} sx={{
          backgroundColor: selectedOptionVariable === 9 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 9 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(9)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         9 M  </MenuItem>
         <MenuItem value={10} sx={{
          backgroundColor: selectedOptionVariable === 10 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 10 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(10)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          10 M </MenuItem>
        <MenuItem value={11} sx={{
          backgroundColor: selectedOptionVariable === 11 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 11 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(11)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         11 M  </MenuItem>
        
       
      </Select>
     </Box>
      

      
      
   
       
     <TextField   variant="outlined" value={isNaN(dataList[index].rate) ? '' : String(dataList[index].rate)}
      sx={{
       width: ' 100%', fontFamily: "poppins",  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px",
        fontWeight: "400", '& input': {
        padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
       },
     }}
       InputProps={{
         sx: { height: '28px', color: " #626262" ,fontFamily: "poppins",
         fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", },type:"number",'& label': {
           display: 'flex', alignItems: 'center', justifyContent: 'center'
         }
       }}onChange={(event) => handleChangeInterstRate(event, index)} />   

{/* <TextField  variant="outlined" value={dataList[index].rateMonth}
      sx={{
       width:' 100%', fontFamily: "poppins",  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px",
        fontWeight: "400", '& input': {
        padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
       },
     }}
       InputProps={{
         sx: { height: '28px', color: " #626262" ,fontFamily: "poppins",
         fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", },type:"number",'& label': {
           display: 'flex', alignItems: 'center', justifyContent: 'center'
         }
       }}onChange={(event) => handleChangeInterstRateMonth(event, index)} />    */}
      
      
     
      <Select value={dataList[index].frequency} onChange={(event) =>{ handleOptionChangePaymentFreequency(event, index);
      handleChangeFreequency(event,index)}}
      renderValue={(selected) => renderDisplayValue(selected)}
       sx={{
          width: ' 100%', height: '28px', fontFamily: 'poppins', fontSize: { md: '12px', sm: '12px',xs:'10px' }, 
          lineHeight: '18px', fontWeight: '400', color: '#626262', '& .MuiSelect-select': {
           padding:{ md: '12px', sm: '6px',xs:'8px' }// Set padding to 0 for the select input
          }, // Override padding for the input element
          '& .MuiSelect-icon': {
            right: 0,// Set padding to 0 for the select input
          }, 
        }}
        displayEmpty MenuProps={{
          PaperProps: {
            style: {
              onMouseLeave: () => setSelectedOptionMonthly(''), background: " #F8F8F8 ", borderRadius: '8px',marginTop:'5px',
            },
          },
        }}
      >
         <MenuItem  sx={{ display: 'none' }}>Monthly </MenuItem>   
        <MenuItem value={"M"}  sx={{
          backgroundColor: selectedOptionMonthly === "M" ? '#0C276C !important' : 'transparent', color: selectedOptionMonthly ==="M" ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionMonthly('M')}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Monthly</MenuItem>
        <MenuItem value={"S"} sx={{
          backgroundColor: selectedOptionMonthly === "S" ? '#0C276C !important' : 'transparent', color: selectedOptionMonthly === "S" ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionMonthly("S")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Semi-monthly</MenuItem>
           <MenuItem value={"W"} sx={{
          backgroundColor: selectedOptionMonthly === "W" ? '#0C276C !important' : 'transparent', color: selectedOptionMonthly === "W" ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionMonthly("W")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Weekly</MenuItem>
           <MenuItem value={"B"} sx={{
          backgroundColor: selectedOptionMonthly === "B" ? '#0C276C !important' : 'transparent', color: selectedOptionMonthly === "B" ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionMonthly("B")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Bi-weekly</MenuItem>
           <MenuItem value={"A"} sx={{
          backgroundColor: selectedOptionMonthly === "A" ? '#0C276C !important' : 'transparent', color: selectedOptionMonthly === "A" ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionMonthly("A")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Accelerated weekly</MenuItem>
           <MenuItem value={"Z"} sx={{
          backgroundColor: selectedOptionMonthly === "Z" ? '#0C276C !important' : 'transparent', color: selectedOptionMonthly === "Z" ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionMonthly("Z")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Accelerated Bi-weekly</MenuItem>

      </Select>
     
      <Box sx={{display:'flex', flexDirection:{ md: 'row', sm: 'row',xs:'column' },width:' 100%',gap:'5px'}}>
      <Tooltip
       classes={{ tooltip: "custom-tooltip" }}
        title={tooltipTitlestermMonth[index]}
        placement="top-start"
        arrow
        disableHoverListener = {!dataList[index].term}
      >
      <TextField  placeholder={isFocusedTerm ? '' : '5 Y'} variant="outlined" value={isNaN(dataList[index].term) ? '' : String(dataList[index].term)}
       sx={{
        width:{ md:" 100%", sm:" 100%",xs:'100%' } , fontFamily: "poppins",  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",
        border: showErrorBorderterm[index] ? '1px solid red' : '',borderRadius:'4px',height: '28px', '& input': {
         padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
        },
        '& ::placeholder': {
          color: '#626262' ,opacity:1
        }
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262" ,fontFamily: "poppins",
          fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", },type:"number",'& label': {
            display: 'flex', alignItems: 'center', justifyContent: 'center', 
            border: showErrorBorderterm[index] ? '1px solid red' : '',borderRadius:'4px'
           
          }
        }}onChange={(event) =>{ handleOptionChangeTerm(event,index);}}
        onFocus={handleFocusTerm}
        onBlur={handleBlurTerm} />   
        </Tooltip>
      
        <Select value={isNaN(dataList[index].termMonth) ? '' : String(dataList[index].termMonth)} onChange={(event) =>{ handleOptionChangeTermMonth(event, index); }}  
        sx={{
          width: { md:'calc(50% + 1px)', sm:'calc(50% + 1px)',xs:'100%' },height: '28px', fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' },
           lineHeight: '18px', fontWeight: '400', 
          color: '#626262', '& .MuiSelect-select': {
           padding:{ md: '12px', sm: '6px',xs:'8px' }// Set padding to 0 for the select input
          }, // Override padding for the input element
          '& .MuiSelect-icon': {
            right: 0,// Set padding to 0 for the select input
          }, 
        
        }}
        displayEmpty       
        MenuProps={{          
          PaperProps: {
            style: {
              onMouseLeave: () => setSelectedOptionVariable(''),
               background: " #F8F8F8", borderRadius: '8px',marginTop:'5px', maxHeight: '250px',
               overflowY: 'auto',
              
            },
            
          },
        }}
      >
        {/* <MenuItem value={0} style={{ display: 'none',  }}>  0 M </MenuItem> */}
       <MenuItem value={0} sx={{
          backgroundColor: selectedOptionVariable === 0 ? '#0C276C !important ' : 'transparent',
          color: selectedOptionVariable === 0 ? '#ffffff' : '#626262', fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px',
          fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,minHeight:{ md: '28px', sm: '28px',xs:'18px' }        }}
          onMouseEnter={() => setSelectedOptionVariable(0)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          0 M  </MenuItem> 
        <MenuItem value={1} sx={{
          backgroundColor: selectedOptionVariable === 1 ? '#0C276C !important ' : 'transparent',
          color: selectedOptionVariable === 1 ? '#ffffff' : '#626262', fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px',
          fontWeight: '400', borderRadius: '4px', marginLeft: '5px',    marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,minHeight:{ md: '28px', sm: '28px',xs:'18px' } 
        }}
          onMouseEnter={() => setSelectedOptionVariable(1)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          1 M  </MenuItem>
        <MenuItem value={2} sx={{
          backgroundColor: selectedOptionVariable === 2 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 2 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400',
           borderRadius: '4px', marginLeft: '5px',    marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,minHeight:{ md: '28px', sm: '28px',xs:'18px' } 
        }}
          onMouseEnter={() => setSelectedOptionVariable(2)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          2 M </MenuItem>
        <MenuItem value={3} sx={{
          backgroundColor: selectedOptionVariable === 3 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 3 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',    marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,minHeight:{ md: '28px', sm: '28px',xs:'18px' } 
        }}
          onMouseEnter={() => setSelectedOptionVariable(3)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         3 M  </MenuItem>
         <MenuItem value={4} sx={{
          backgroundColor: selectedOptionVariable === 4 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 4 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',    marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,minHeight:{ md: '28px', sm: '28px',xs:'18px' } 
        }}
          onMouseEnter={() => setSelectedOptionVariable(4)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          4 M </MenuItem>
        <MenuItem value={5} sx={{
          backgroundColor: selectedOptionVariable === 5 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 5 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(5)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         5 M  </MenuItem>
         <MenuItem value={6} sx={{
          backgroundColor: selectedOptionVariable === 6 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 6 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(6)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          6 M </MenuItem>
        <MenuItem value={7} sx={{
          backgroundColor: selectedOptionVariable === 7 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 7 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(7)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         7 M  </MenuItem>
         <MenuItem value={8} sx={{
          backgroundColor: selectedOptionVariable === 8 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 8 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(8)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          8 M </MenuItem>
        <MenuItem value={9} sx={{
          backgroundColor: selectedOptionVariable === 9 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 9 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(9)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         9 M  </MenuItem>
         <MenuItem value={10} sx={{
          backgroundColor: selectedOptionVariable === 10 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 10 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(10)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          10 M </MenuItem>
        <MenuItem value={11} sx={{
          backgroundColor: selectedOptionVariable === 11 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 11 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } , 
        }}
          onMouseEnter={() => setSelectedOptionVariable(11)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         11 M  </MenuItem>
        
       
      </Select>
      </Box>

      {showPopup && (

        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '2'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
            
              width: '378px',
              height: '202px',
              backgroundColor: '#fff',

              border: '1px solid #CFC9C9',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '3'
            }}
          >
            <IconButton onClick={handleCancel} style={{
              color: '#939393', position: 'absolute', width: '20px', height: '20px',
              left: '347px', top: '12px'
            }} >
              <HighlightOffIcon ></HighlightOffIcon>
            </IconButton>

            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
              textAlign: "center"
            }}>Are you sure you want to delete this Mortgage option?</Typography>
            <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
              <button size='small' onClick={handleCancel} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins', fontStyle: 'normal',
                fontWeight: '400', fontSize: '14px', lineHeight: '21px', marginRight: '24px', textAlign: 'center', color: '#313131',
                background: '#F8F8F8', border: '1px solid #CFC9C9', borderRadius: '4px',cursor:'pointer'
              }} >Cancel</button>
              <button size='small' onClick={handleDeleteConfirm} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C !important', border: '1px solid #0C276C !important', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C !important';
              }} >Delete </button>
            </Box>
          </Box>
        </Box>
      )}



{showPopuptermamort && (

<Box
  sx={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '1070px',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2'
  }}
>
  <Box
    sx={{
      position: 'absolute',
    
      width: '378px',
      height: '202px',
      backgroundColor: '#fff',

      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3'
    }}
  >
    <IconButton onClick={handleCancelTerm} style={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left: '347px', top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>

    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
      textAlign: "center"
    }}> The amortization period must be equal to or greater than the term</Typography>
   <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
   <button size='small' onClick={handleCancelTerm} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C !important', border: '1px solid #0C276C !important', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C !important';
              }} >Cancel </button>
            
            </Box>
   
  </Box>
</Box>
)}
    </Box>


  );

}

export default Divcomponenet;