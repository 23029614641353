import { Box, Button, CheckBox, FormControlLabel, FormLabel, IconButton, InputAdornment, Modal, OutlinedInput, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Signup from './SignUp';
import Signin from './SignIn';
import CustomOutlined from './customOutlined';
import PrimaryButton from './customButton';
import SecondaryButton from './Secondarybutton';
function ResetMob(props) {
   const {showResetMob,setShowResetMob,openModalSignIn,setOpenmodalSignIn}=props;
   console.log(props)
    const [openModal, setOpenmodal]=useState(true);
    const handleOpenmodal = () => setOpenmodal(true);
    const handleClosemodal = () =>  setShowResetMob(false);
    const [showModal,setShowModal] = useState(false);
    function showModalHandler(){
      setShowModal(!showModal);
     }
    const [error, setError] = useState();
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const ReturnToSignIn = () => {
      setOpenmodalSignIn(true);
      setShowResetMob(false);
    };


  return (
    <Box>
     
     {showResetMob &&( 
    <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: {md:'1070px',sm:'1125px',xs:'1280px'},
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: '3'
    }}>
      
           <Box
            sx={{
              position: 'absolute',
              width: {lg:'400px',md:'400px',sm:'400px',xs:'90%'},
              height:'482px',
              bgcolor: '#FFFF',
              paddingBottom:'36px',
              borderRadius:'8px',
              BoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              alignItems:'center',
              justifyContent:'center'
              }}>
          <div style={{ display: 'flex', flexDirection:'row-reverse',paddingTop:'12px',paddingRight:'12px' }}>
            <IconButton sx={{ padding: '0px' }} onClick={handleClosemodal} >
              < CancelOutlinedIcon sx={{ color: '#606060', width: '24px', height: '24px' }} />
            </IconButton>
            </div>
            <Box sx={{paddingLeft:'24px',paddingRight:'24px'}}>
             <div style={{ display: 'flex', flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center',
             marginTop:'13px'}}>
             <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins',marginBottom:'48px'}}>Reset password</Typography>
                <Box sx={{height:'40px',width:'40px',borderRadius:'40px',border:'1px solid #1F6373',alignItems:'center',alignContent:'center',
                justifyContent:'center'}}>
                    <PersonIcon sx={{color:'#1F6373',height:'40px',width:'40px'}}/>
                </Box>
                <Typography sx={{fontSize:'14px',fontWeight:400,color:'#454545',fontFamily:'Poppins',textAlign:'center',marginTop:'8px'}}>
                  Enter registered email below. We will email you instructions to reset your password.</Typography>
             </div>
             <div style={{ display: 'flex', flexDirection:'column',paddingTop:'16px'}}>
              <Typography sx={{textAlign:'left',fontSize:'14px',color:'#939393',paddingBottom:'6px',fontFamily:'Poppins',fontWeight:500}}>
                Email</Typography>
              <CustomOutlined type="text" placeholder='olivia@untitledui.com'/> 
               <div style={{marginTop:'48px',marginBottom:'16px'}}>
              <PrimaryButton >Send reset link</PrimaryButton>
              </div>
             <SecondaryButton onClick={ReturnToSignIn}>Return to sign in</SecondaryButton>
            </div>
           
        </Box>
        </Box>
        {/* </Modal>
    </div> */}
    </Box>
     )}
  </Box>
  )
}
export default ResetMob;