import { Box } from '@mui/material'
import React from 'react'
import AgentsLanding from '../components/AgentsLanding'
import AgentLandingMobile from '../components/AgentLandingMobile'


function AgentsLandingDetails () {
  return (
    <div>
     

      <Box sx={{display:{md:'block',xs:'none',sm:'block'}}}>

       <AgentsLanding>        
       </AgentsLanding>
      </Box>

      <Box sx={{display:{md:'none',xs:'block',sm:'none'}}}>

<AgentLandingMobile>
</AgentLandingMobile>

</Box>
    </div>
  )
}

export default AgentsLandingDetails
