import { InputLabel, Box,Typography } from '@mui/material';
//import { useState } from 'react';

function PenaltyComponentMobile(props) {

    const {mortgageName,isSelected,isCheckedCheckboxNext } = props;

    return (
        <Box key={mortgageName} style={{
            display: 'flex', flexDirection: "column", border: "1px solid #CFC9C9",  width:  'calc(91% )',
            height: "176px",  alignItems: "left", borderRadius: "8px", gap: '20px', justifyContent: 'left', 
            background: isSelected || isCheckedCheckboxNext ? "#F9F9F9" : "white",marginTop: '15px',padding:'4px'
          }}>
            
            <Typography style={{
        color: "#626262", textAlign: "center",  fontFamily: "poppins", fontSize: "12px", lineHeight: "18px",
        fontWeight: "400",marginTop:'10px' 
      }}>{mortgageName}
      </Typography>
           <InputLabel style={{
                color: "#626262",fontFamily: "poppins", fontSize: "12px", lineHeight: "18px",
                fontWeight: "400",marginTop:'-8px',paddingLeft:'12px'
            }}>0</InputLabel>
            <InputLabel style={{
                color: "#626262",  fontFamily: "poppins", fontSize: "12px", lineHeight: "18px",
                fontWeight: "400",paddingLeft:'12px',marginTop:'15px'
            }}>0</InputLabel>
            <InputLabel style={{
                color: "#626262", fontFamily: "poppins", fontSize: "12px", lineHeight: "18px",
                fontWeight: "400",paddingLeft:'12px',marginTop:'11px'
            }}>2 months</InputLabel> 
           </Box>
    );
}
export default PenaltyComponentMobile;