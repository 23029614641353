import { Box, Button, Grid, OutlinedInput, Typography } from "@mui/material";
import axios from "axios";
import '../App.css';
import  React,  { useState, useEffect } from "react";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';
import Checkbox from '@mui/material/Checkbox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { API_URL } from "./constants";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Header from './NeholaHeader'
import HeaderMobile from './NeholaHeaderMobile';
import Footer from './NeholaFooter';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Select, MenuItem, InputLabel } from '@mui/material';
function AddBuilderDetails( ) {
    const [isTermsAndCondition, setIsTermsAndCondition] = useState(false);  
    let Uname;
    let builderid;
    const [expandedRow, setExpandedRow] = useState(null);

    const login_id = localStorage.getItem('login_id')
const [selectedBuilderid, setSelectedBuilderid] = useState(''); 
const [buildersNames, setBuildersNames] = useState([]);
const [buildersName, setBuildersName] = useState('');
const [sponsorsEmail, setsponsorsEmail] = useState('');
const [location, setLocation] = useState('');
//const [userpassword, setUserPassword] = useState('');
const [sponsorsName, setSponsorsName] = useState('');
const [sponsorsPhone, setSponsorsPhone] = useState('');
const [businessbuilderName, setbusinessbuilderName] = useState('');


  const [builderDetails, setBuilderDetails] = useState({
    builderName: '',
    builderLocation: '',
    sponsorName: '',
    sponsorEmail: '',
    sponsorPhone: '',
  });

  const [showErrorsponsorName, setshowErrorsponsorName] = useState('');
  const [showErrorsponsorEmailId, setShowErrorsponsorEmailId] = useState('');
  const [showErrorphoneNumber, setShowErrorphoneNumber] = useState('');
  const [showErrorbuilderName, setShowErrorbuilderName] = useState('');
  const [showErrorlocation, setShowErrorlocation] = useState('');
  const [thankYouPopUp, setThankYouPopUp] = useState(false);

  const handleBuilderNameChange = (value, builderId) => {
   
    console.log("handleBuilderNameChange",value)
    console.log("handleBuilderIdChange",builderId)
    setBuilderDetails({
      ...builderDetails,
      builderName: value,
    });
    setSelectedBuilderid(builderId)
    setShowErrorbuilderName('');
  };

  const handleBuilderLocationChange = (event) => {
    setBuilderDetails({
      ...builderDetails,
      builderLocation: event.target.value,
    });
    setShowErrorlocation('');
  };

  const handleSponsorNameChange = (event) => {
    setBuilderDetails({
      ...builderDetails,
      sponsorName: event.target.value,
    });
    setshowErrorsponsorName('');
  };

  const handleSponsorEmailChange = (event) => {
    setBuilderDetails({
      ...builderDetails,
      sponsorEmail: event.target.value,
    });
    setShowErrorsponsorEmailId('');
  };

  const handleSponsorPhoneChange = (event) => {
    setBuilderDetails({
      ...builderDetails,
      sponsorPhone: event.target.value,
    });
    setShowErrorphoneNumber('');
  };

  const handleAddBuilderButtonClick = async () => {
    if (builderDetails.builderName === '') {
      setShowErrorbuilderName('Builders name is required');
    }

    if (builderDetails.builderLocation === '') {
      setShowErrorlocation('Builder location is required');
    }


    if (builderDetails.sponsorName === '') {
     setshowErrorsponsorName('Sponsor name is required');
    }

   if (builderDetails.sponsorEmail  === '') {
     setShowErrorsponsorEmailId('Sponsor sponsorEmail is required');
    }

     if (builderDetails.sponsorPhone  === '') {
      setShowErrorphoneNumber( 'Sponsor phone number is required');
    }
    if (
      builderDetails.builderName !== '' &&
      builderDetails.builderLocation !== '' &&
      builderDetails.sponsorName !== '' &&
      builderDetails.sponsorEmail !== '' &&
      builderDetails.sponsorPhone !== '' 
    )
     {
      
      const allBuilderDetails = {
        listingagentid: localStorage.getItem('myprofilelisting_agent_id'),
        buildername: builderDetails.builderName,
        builderlocation: builderDetails.builderLocation,
        sponsorname: builderDetails.sponsorName,
        sponsorEmail: builderDetails.sponsorEmail,
        sponsorphone: builderDetails.sponsorPhone,
      };
       console.log("allBuilderDetails",allBuilderDetails) 
          try {
            // Send a single request to insert all builder details
            const response = await axios.post(`${API_URL}/insert-builders`, {
              allBuilderDetails,
              builderid : selectedBuilderid,
              login_id : login_id,
            });
            console.log('Insert response builder:', response.data);
            setThankYouPopUp(true)
          } catch (error) {
            console.error('Error:', error.message);
          }
        }
       setIsAddBuilder(false)
      
        };


      
        const handleCloseThankYou = () => {
          setThankYouPopUp(false)
          window.location.href = '/edit-profile?isMyProfile=false';
      
      };

  // const handleAddMoreClick = () => {
  //   const lastIndex = builderDetails.length - 1;
  //   const lastBuilderErrors = errorMessages[lastIndex];

  //   if (
  //     lastBuilderErrors.builderName ||
  //     lastBuilderErrors.builderLocation ||
  //     lastBuilderErrors.sponsorName ||
  //     lastBuilderErrors.sponsorEmail ||
  //     lastBuilderErrors.sponsorPhone
  //   ) {
      
  //     return;
  //   }

  //   setBuilderDetails([
  //     ...builderDetails,
  //     {
  //       builderName: '',
  //       builderLocation: '',
  //       sponsorName: '',
  //       sponsorEmail: '',
  //       sponsorPhone: '',
  //     },
  //   ]);
  //   setErrorMessages([...errorMessages, {}]);
  // };
const [isAddBuilder, setIsAddBuilder] = useState(false);

// const handleAddBuilderPopUpClick = () => {
//   setIsAddBuilder(true)
//   setBuilderDetails([
//     {
//       builderName: '',
//       builderLocation: '',
//       sponsorName: '',
//       sponsorEmail: '',
//       sponsorPhone: '',
//     },
//   ]);
    
   
//    }
   const closePopupbuilder = () => {
    setIsAddBuilder(false)
}


 
  async function fetchBuilderName() {
    
    try {
        const response = await axios.get(`${API_URL}/builder-names`); 
        setBuildersNames(response.data);         
       console.log("BuilderName",response.data)
      
    } 
    catch (error) {
        console.error('Error:', error.message);
    }
  }

  useEffect(() => {
    fetchBuilderName();
  }, []);

  return (
    <Grid
      container
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
       
      }}
    >
         <Box sx={{
                height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'flex', sm: 'none', xs: 'none' }
            }}>
                <Header ></Header>
            </Box>
            <Box sx={{
                height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'none', sm: 'flex', xs: 'flex' }
            }}>
                <HeaderMobile ></HeaderMobile>
            </Box>
           
    <Box sx={{  width: '100%',alignItems: 'center', justifyContent: 'center',
                display: 'flex', flexDirection: 'column', paddingLeft: { md: '0', sm: '0' , xs:'3%'}, 
                paddingRight: { md: '0', sm: '0', xs:'3%' }, gap: { md: '30px', sm: '30px',xs:'20px' } ,   }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', alignContent: 'left' ,
            height: '100% ', width: '100%',paddingLeft: { md: '3%', sm: '3%' , xs:'3%'},}}>
            <Typography sx={{ fontSize: '18px', fontWeight: 600, color: 'black', fontFamily: 'Poppins',paddingTop:'30px' }}>
              Add your builders details
            </Typography>
          </Box>
         
            <Box sx={{ marginBottom: '20px',width:{ md: '520px', sm: '520px', xs: '100%'},justifyContent:'center',alignItems:'center',
             display: 'flex', flexDirection: 'column',paddingLeft: { md: '0', sm: '0' , xs:'3%'}, 
             paddingRight: { md: '0', sm: '0', xs:'3%' },
             }}>
              
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingTop: '10px',
              width:{ md: '520px', sm: '520px', xs: '100%'},paddingLeft: { md: '0', sm: '0' , xs:'3%'}, 
              paddingRight: { md: '0', sm: '0', xs:'3%' },
             }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500 }}>
                    Builders name<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Select
  sx={{
    height: '36px',
    color: "#313131",
    fontFamily: "Poppins",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "400",
    borderRadius: '8px',
    padding: '10px 14px', // Note the corrected padding value
  }}
  value={builderDetails.builderName}
  onChange={(event) => handleBuilderNameChange(event.target.value, buildersNames.find(builder => builder.builder_name === event.target.value)?.builder_id)}
  error={showErrorbuilderName}
>
  {buildersNames.map((buildername, index) => (
    <MenuItem
    
    key={buildername.builder_id} // Use listing_agent_id as the key
      sx={{
        backgroundColor: 'white !important',
        marginLeft: '10px',
        marginRight: '10px',
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "400",
        '&:hover': {
          borderRadius: '8px',
          marginLeft: '10px',
          marginRight: '10px',
          backgroundColor: '#504FA4 !important',
          color: 'white',
        }}
      }
      value={buildername.builder_name} // Set the builder_name as the value
    
    >
      {buildername.builder_name}
    </MenuItem>
  ))}
</Select>
                  {/* <TextField
                    value={builder.builderName}
                    onChange={(event) => handleInputChange(event, index, 'builderName')}
                    type="text"
                    name="user_name"
                    error={!!errorMessages[index].builderName}
                    InputProps={{
                      sx: {
                        height: '36px',
                        color: "#313131",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        borderRadius: '8px',                       
                        padding: '10px 14px',
                        borderColor: errorMessages[index].builderName ===' ' ? 'red' : '',
                      },
                    }}
                  /> */}
                  <Typography variant="body2" color="error">
                    {showErrorbuilderName}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '5px' }}>
                  <Typography sx={{
                    fontSize: '14px',
                    color: '#212427',
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                  }}>
                    Builder location<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    value={builderDetails.builderLocation}
                    onChange={(event) => handleBuilderLocationChange(event)}
                    error={showErrorlocation}
                    sx={{
                      width: "100%",
                      fontSize: '14px',
                      color: '#212427',
                      fontFamily: 'Poppins',
                      lineHeight: "18px",
                      fontWeight: "400",
                    }}
                    InputProps={{
                      sx: {
                        height: '36px',
                        fontSize: '14px',
                        color: "#313131",
                        fontFamily: 'Poppins',
                        lineHeight: "18px",
                        fontWeight: "400",
                        borderRadius: '8px',
                      //  background: '#F9F9F9',
                        padding: '10px 14px',
                        borderColor: showErrorlocation ===' ' ? 'red' : '',
                      },
                    }}
                  />
                  <Typography variant="body2" color="error">
                    {showErrorlocation}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500 }}>
                    Sponsor name<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    value={builderDetails.sponsorName}
                    onChange={(event) => handleSponsorNameChange(event)}
                    type="text"
                    name="user_sponsorName"
                    error={showErrorsponsorName}
                    InputProps={{
                      sx: {
                        height: '36px',
                        color: "#313131",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        borderRadius: '8px',
                      //  background: '#F9F9F9',
                        padding: '10px 14px',
                        borderColor: showErrorsponsorName ===' ' ? 'red' : '',
                      },
                    }}
                  />
                  <Typography variant="body2" color="error">
                    {showErrorsponsorName}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500 }}>
                    Sponsor email id<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    value={builderDetails.sponsorEmail}
                    onChange={(event) => handleSponsorEmailChange(event)}
                    type="text"
                    name="user_phone"
                    error={showErrorsponsorEmailId}
                    InputProps={{
                      sx: {
                        height: '36px',
                        color: "#313131",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        borderRadius: '8px',
                      //  background: '#F9F9F9',
                        padding: '10px 14px',
                        borderColor: showErrorsponsorEmailId ===' ' ? 'red' : '',
                      },
                    }}
                  />
                  <Typography variant="body2" color="error">
                    {showErrorsponsorEmailId}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Typography sx={{ fontSize: '14px', color: '#212427', fontFamily: 'Poppins', fontWeight: 500 }}>
                    Sponsor phone number<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    value={builderDetails.sponsorPhone}
                    onChange={(event) => handleSponsorPhoneChange(event)}
                    type="number"
                    name="user_sponsorEmail"
                    error={showErrorphoneNumber}
                    InputProps={{
                      sx: {
                        height: '36px',
                        color: "#313131",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        borderRadius: '8px',
                      //  background: '#F9F9F9',
                        padding: '10px 14px', type: 'number', min: 0, max: 9,
                        borderColor: showErrorphoneNumber ===' ' ? 'red' : '',
                      },
                    }}
                  />
                  <Typography variant="body2" color="error">
                    {showErrorphoneNumber}
                  </Typography>
                </Box>
              </Box>
            </Box>
         
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom:'50px',paddingLeft: { md: '0', sm: '0' , xs:'8%'}, 
              paddingRight: { md: '0', sm: '0', xs:'8%' },width:{ md: '520px', sm: '520px', xs: '100%'},}}>
        <Button variant="contained" size="large" sx={{
           justifyContent:'center',
            height: '36px',
           width:{ md: '520px', sm: '520px', xs: '100%'},
            color: 'white',
            borderRadius: '8px',
            backgroundColor: '#504FA4',
            textTransform: 'none',
            '&:hover': { bgcolor: "#3A3990", color: '#fff',border: "1px solid #3A3990", }
          }}
            onClick={handleAddBuilderButtonClick}>Add builder</Button>
        </Box>
        { thankYouPopUp &&  <Box
       sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',
        
       }}
     >
       <Box
         sx={{
           position: 'absolute',    
           width:  { md: '400px', sm: '400px', xs: '300px' },
           height: { md:'370px', sm: '350px', xs: '350px' },  
           backgroundColor: '#fff',
           border: '1px solid #CFC9C9',
           borderRadius: '8px 0px 8px 8px',
           boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
           boxSizing: 'border-box',
           zIndex: '3',  
           padding:{ md: '25px', sm: '30px', xs:  '20px'}, 
           alignItems: 'center', 
           overflow:'auto',
          
         }}
       >
         {/* <IconButton  onClick={closeResetPopUp} sx={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left:{ md:  '93%', sm:  '94%', xs:  '90%'}, top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton> */}
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>
          
            
            <div style={{ display: 'flex', flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center',paddingTop:'15px'}}>
            <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins',textAlign:'center',
            paddingTop:'25px'}}>Thank you!</Typography>
                {/* <Typography sx={{fontSize:'14px',fontWeight:400,color:'black',fontFamily:'Poppins',textAlign:'center',paddingTop:'35px'}}>
               
                Thanks for creating a profile with Ratewaves. Your request is under review by the admin team,  access will be enabled once they approve your request. Eventually, you will be notified once access is ready.  </Typography> */}
             <Typography sx={{fontSize:'14px',fontWeight:400,color:'black',fontFamily:'Poppins',textAlign:'center',paddingTop:'35px'}}>
               
             Thank you for adding the builder details to Ratewaves. Our team is reviewing your request. You'll be notified once your access is ready to add your projects. </Typography>
            
             </div>
                <Box sx={{ display: 'flex', flexDirection: 'column',  gap: '16px', paddingTop:'20px' }}>
            
                
                  

      
                  
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', paddingTop: '10px' }}>
                        

                       
                           
                    <Button variant="contained" size="large" sx={{
                            width: '100%', height: '36px', color: 'white',borderRadius:'8px',
                            backgroundColor: '#504FA4', textTransform:'none',
                            '&:hover': { bgcolor: "#3A3990", color: '#fff',border: "1px solid #3A3990", }
                        }}
                        onClick={handleCloseThankYou} >Close</Button>
                      
                    </Box>
                </Box>
            </Box>
            </Box>
            </Box>} 
     
      <Footer></Footer>
      <style>
        {`
          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 1px solid #504FA4 !important;
            box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
          }
        `}
      </style>
          
    </Grid>
  );
}

export default AddBuilderDetails;










