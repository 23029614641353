import { Box, InputLabel, Typography } from '@mui/material';

import TextField from '@mui/material/TextField';

function PaymentComponentMobile(props) {

    const { mortgageName, isSelected, isCheckedCheckboxNext,dataList, setDataList} = props;
    const index=dataList.findIndex(x => x.name ===mortgageName);
    const handleMouseEnter = (index) => {
      var tempdataList=[...dataList]
      tempdataList[index].isSelected=true  ;    
      setDataList(tempdataList);
    };
  
    const handleMouseLeave = (index) => {
      var tempdataList=[...dataList]
      tempdataList[index].isSelected=false  ;    
      setDataList(tempdataList); 
    };
    return (
        <Box key={mortgageName} style={{
            display: 'flex', flexDirection: "column", border: dataList[index].isSelected?"1px solid #0C276C" : "1px solid #CFC9C9",  width:  'calc(98% )',
            height: "200px",  alignItems: "left", borderRadius: "8px", gap: '14px', justifyContent: 'left', 
            background:  dataList[index].isSelected|| isCheckedCheckboxNext ? "#F9F9F9" : "white",padding:'0px 7px 0px 7px'
          }}>

        
<Typography style={{
        color: "#626262", textAlign: "center",  fontFamily: "poppins", fontSize: "12px", lineHeight: "18px",
        fontWeight: "400",marginTop:'15px' 
      }}>{mortgageName}
      </Typography>
      <TextField  variant="outlined" value={isNaN(dataList[index].SheduledResult) ? '' : String(dataList[index].SheduledResult)}  
    
    style={{
      width: "100%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    }}
      InputProps={{
        sx: { height: '22px', color: " #626262",":read-only":true, fontFamily: "poppins",
        fontSize: "12px", lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />
    <style>
      {`
        .Mui-focused .MuiOutlinedInput-notchedOutline {
          border: 1px solid #0C276C !important;
          box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
        }
      `}
    </style>

    
<TextField  variant="outlined" value={isNaN(dataList[index].lowerResults.TotalAmountPaid) ? '' : String(dataList[index].lowerResults.TotalAmountPaid)}  
    
    style={{
      width: "100%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    }}
      InputProps={{
        sx: { height: '22px', color: " #626262",":read-only":true,fontFamily: "poppins",
        fontSize: "12px", lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />

<TextField  variant="outlined" value={isNaN(dataList[index].lowerResults.TotalInterestPaid) ? '' : String(dataList[index].lowerResults.TotalInterestPaid)}  
    
    style={{
      width: "100%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    }}
      InputProps={{
        sx: { height: '22px', color: " #626262",":read-only":true,fontFamily: "poppins",
        fontSize: "12px", lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />
      <TextField  variant="outlined" value={isNaN(dataList[index].lowerResults.TotalPrinciplePaid) ? '' : String(dataList[index].lowerResults.TotalPrinciplePaid)}  
    
    style={{
      width: "100%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    }}
      InputProps={{
        sx: { height: '22px', color: " #626262",":read-only":true,fontFamily: "poppins",
        fontSize: "12px", lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />
           {/* <InputLabel style={{
                color: "#626262",fontFamily: "poppins", fontSize: "12px", lineHeight: "18px",
                fontWeight: "400",marginTop:'8px',paddingLeft:'12px'
            }}>{dataList[index].SheduledResult}</InputLabel>
            <InputLabel style={{
                color: "#626262",  fontFamily: "poppins", fontSize: "12px", lineHeight: "18px",
                fontWeight: "400",paddingLeft:'12px',marginTop:'15px'
            }}>{dataList[index].lowerResults.TotalAmountPaid}</InputLabel>
            <InputLabel style={{
                color: "#626262", fontFamily: "poppins", fontSize: "12px", lineHeight: "18px",
                fontWeight: "400",paddingLeft:'12px',marginTop:'11px'
            }}>{dataList[index].lowerResults.TotalInterestPaid}</InputLabel>
            <InputLabel style={{
                color: "#626262",  fontFamily: "poppins", fontSize: "12px", lineHeight: "18px",
                fontWeight: "400",paddingLeft:'12px',marginTop:'7px'
            }}>{dataList[index].lowerResults.TotalPrinciplePaid}</InputLabel>  */}
        </Box>


    );
}
export default PaymentComponentMobile;