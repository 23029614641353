
import React, { useState } from 'react';
import { Box, colors, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material';

import closeicon from './images/closeicon.svg'
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import searchicon from './images/searchicon.svg'
import blog1 from './images/blog.png'
import { useRef } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { API_URL } from './constants';

const BlogComponent = (props) => {

    function generateUrlFromTitle(title) {
        return title.toLowerCase().replace(/\s+/g, '-');
      }
    const {selectedCategory} = props

    const isDesktop = useMediaQuery('(min-width:768px)');
    const [searchValue, setSearchValue] = useState('')    
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detect xs screen size

    // Set postsPerPage dynamically based on screen size
    const postsPerPage = isSmallScreen ? 1 : 3;
   
    const navigate = useNavigate();

    const boxRef = useRef(null);


    const [blogs, setBlogs] = useState([]);

    async function fetchData() {
    
      try {
          const response = await axios.get(`${API_URL}/blog_data`);                   
          console.log("details",response.data)
          setBlogs(response.data)
      } 
      catch (error) {
          console.error('Error:', error.message);
      }
    }
  
    useEffect(() => {
      fetchData();
    }, []);

  
      const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        const filtered = blogs.filter(blog => 
          blog.title.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredBlogs(filtered);
        if (boxRef.current) {
          if (value) {
            boxRef.current.style.display = 'block'; 
          } else {
            boxRef.current.style.display = 'none';  
          }
        }
      };

      const handleClearSearch = () => {
        setSearchValue('');
        if (boxRef.current) {
            boxRef.current.style.display = 'none';  
          }
      };

      const handleSelectBlog = (blog) => {
        setSearchValue(blog.title); 
        if (boxRef.current) {
            boxRef.current.style.display = 'none'; 
          }
      };

      const handleClickOutside = (event) => {
        if (boxRef.current && !boxRef.current.contains(event.target)) {
          setSearchValue(''); // Clear search value or close the dropdown
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

      const handleBoxClick = (link) => {
            navigate(link);
        };

        const sortedBlogs = blogs.sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate));
        const currentPosts = sortedBlogs.slice(startIndex, startIndex + postsPerPage);

        const handleNext = () => {
            // Move to the next post (increment by 1)
            setStartIndex((prevIndex) => Math.min(prevIndex + 1, sortedBlogs.length - 1));
        };
        
        const handlePrev = () => {
            // Move to the previous post (decrement by 1)
            setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        };
    
        

    return (
        <Grid container sx={{
            width: '100%', height: '100%', overflow: 'hidden',
            justifyContent: 'center', alignItems: 'center'
        }}>
            
                    

            <Box sx={{
                height: '100%', display: 'flex', width: '100%', 
                paddingTop: {sm:'2%',md:'2%',xs:'5%'}, paddingBottom: '3%', flexDirection: 'column'
            }}>
                <Box sx={{
                height: '100%', display: 'flex', width: '100%',flexDirection: 'column'
                }}>

                 <Box sx={{
                height: 'fit-content', display: 'flex', width: '100%',flexDirection: 'column', 
                }}>   
                <TextField
                variant="outlined"
                sx={{ width: '100%' }} // Ensure matching width
                value={searchValue}
                name="search"
                placeholder="Search blogs"
                onChange={handleSearchChange}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    {searchValue ? (
                        <IconButton onClick={handleClearSearch}>
                        <img src={closeicon} alt="Close" style={{ width: '16px', height: '16px' }} />
                        </IconButton>
                    ) : (
                        <img src={searchicon} alt="Search" style={{ width: '16px', height: '16px' }} />
                    )}
                    </InputAdornment>
                ),
                }}/>

                {searchValue && (
                <Box
                    ref={boxRef}
                    sx={{
                    width: '23.7%',
                    position: 'absolute',
                    marginTop: '56px', // Adjust based on header height
                    borderRadius: '4px',
                    paddingBottom: '8px',
                    maxHeight: {md:'170px', xs:'130px'},
                    overflow: 'auto',
                    boxShadow: ' 0px 4px 4px 0px #00000040',
                    display: 'flex',justifyContent:'center',alignItems:'center',
                    flexDirection: 'column',
                    border: '0px solid',
                    borderColor: 'grey',
                    background: 'white',
                    paddingTop: '8px',
                    zIndex: 9,
                    gap: '4px',
                    '&::-webkit-scrollbar': {
                        width: '11px',
                        height: '8px',
                        borderRadius: '16px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'grey',
                        borderRadius: '16px',
                        border: '2px solid white',
                    },
                    '&::-webkit-scrollbar-track': {
                        borderRadius: '16px',
                        backgroundColor: 'white',
                    },
                    }}
                >
                    {filteredBlogs.length === 0 ? (
                    <Box
                        sx={{
                            width: 'calc(100% - 16px)',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '8px',
                            padding: '8px',                          
                            justifyContent: 'center',
                            color: '#313131',fontFamily:'poppins', 
                        }}
                    >
                         <Typography sx={{ minWidth: 'fit-content', color: 'inherit' }}>
                         No results found for your search.
                        </Typography>
                       
                    </Box>
                    ) : (
                    filteredBlogs.map((blog, index) => (
                        <Box
                        key={blog.title}
                        sx={{
                            width: 'calc(100% - 16px)',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '8px',
                            padding: '8px',
                            cursor: 'pointer',
                            justifyContent: 'center',
                            color: '#313131',fontFamily:'poppins', 
                            '&:hover': { backgroundColor: '#0C276C', color: 'white' },
                        }}
                        onClick={() => { window.location.href = `/blog/${generateUrlFromTitle(blog.title)}`; }}
                        >
                        <Typography sx={{ minWidth: 'fit-content', color: 'inherit' ,}}>
                            {blog.title}
                        </Typography>
                        </Box>
                    ))
                    )}
                </Box>
                )}
                </Box>               

                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', 
                     }}>
            <Typography sx={{ fontSize:{md: '32px', xs:'24px'}, fontWeight: 500 , fontFamily:'poppins', 
             paddingTop:{md: '16px', xs:'36px'}}}>Recent Posts</Typography>
            
          
            <Box
  sx={{
    display: 'flex',
    gap: { md: '32px', xs: '16px' },
    width: '100%',
    justifyContent: 'center',
   
  }}
>
  {Array.from({ length: postsPerPage }).map((_, index) => {
    const post = currentPosts[index]; // Get the post at the current index
    return post ? (
      <Box  onClick={() => { window.location.href = `/blog/${generateUrlFromTitle(post.title)}`; }} 
        key={`${post.title}-${index}`} // Unique key for each post box
        sx={{
          width: '100%', // Each box will take full width
          flexDirection: 'column',
          border: '1px solid #CFC9C9',
          padding: '16px',
          display: 'flex',borderRadius:'4px',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '16px', // Added some gap between content
          '&:hover': { background:'#F9F9F9' ,border: '1px solid #CFC9C9',},
        }}
      >
        <Typography
          sx={{
            fontSize: '16px', fontFamily:'poppins',          
            fontWeight: 400,
            lineHeight: '26px',
            cursor: 'pointer',
            
          }}
          onClick={() => window.location.href = `/blog/${post.blogId}`} // On click, navigate to the post
        >
          {post.title}
        </Typography>
        
      </Box>
    ) : null; // Return null if there’s no post
  })}
</Box>

   




</Box>
                
                </Box>
                
                </Box>
           
        </Grid>

    );
};

export default BlogComponent;
