import * as React from "react";
import { styled } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const OpenPickerIcon = styled(ArrowDropDownIcon)({});

export default function BasicDatePicker() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker 
      PopperProps={{
        "& .MuiPaper-root": {
          backgroundColor: "yellow"
        },
        "& .MuiCalendarPicker-root": {
          backgroundColor: "orenge"
        },
        "& .MuiPickersDay-dayWithMargin": {
          color: "rgb(229,228,226)",
          backgroundColor: "red"
        },
        "& .MuiTabs-root": { backgroundColor: "green" }
      
      }}
        slots={{
          openPickerIcon: OpenPickerIcon
          
        }}  
       
        format="DD/MM/YYYY"
      />
    </LocalizationProvider>
  );
}