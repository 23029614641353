import { Box } from '@mui/material'
import React from 'react'
import DeskAndTab from '../components/UniversalCalculator'
import Mobile from '../components/UniversolCalculatorMobile'


function MortgageDetails() {
  return (
    <div>
      <Box sx={{display:{md:'none',xs:'block',sm:'none'}}}>

        <Mobile>
        </Mobile>

      </Box>

      <Box sx={{display:{md:'block',xs:'none',sm:'block'}}}>

       <DeskAndTab>        
       </DeskAndTab>
      </Box>
    </div>
  )
}

export default MortgageDetails
