import { Box, FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CustomOutlined from './customOutlined';
import PrimaryButton from './customButton';
import {Link} from 'react-router-dom';
function SignupMob() {
    const [openModalup, setOpenmodalup]=useState(true);
    // const handleOpenmodal1 = () => setOpenmodalup(true);
    const handleClosemodal1 = () => setOpenmodalup(false);
    const [values, setValues] = useState({
        currentPassword: '',
        newPassword1: '',
        newPassword2: '',
        });
    const [showPasswordValues,setShowPasswordValues]=useState({
      currentPassword: false,
      newPassword1: false,
      newPassword2: false,
    })
    // const [error, setError] = useState();
    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
    const handleClickShowPassword = (prop)=>() => {
      setShowPasswordValues({
        ...showPasswordValues,
        [prop]: !showPasswordValues[prop],
      });
    };
      const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const  handleSignInLinkClick = () => setOpenmodalup(false);
   
  return (
    <Box>
      {openModalup &&(
    
    <Box
  sx={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height:{md:'1070px',sm:'1125px',xs:'1280px'},
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '3'
  }}>
    
        <Box
          sx={{
            position: 'absolute',
            width: {lg:'392px',md:'392px',sm:'400px',xs:'95%'},
            height:'672px',
            // left:'487px',
            bgcolor: '#FFFF',
            paddingBottom:'36px',
            borderRadius:'8px',
            BoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            alignItems:'center',
            justifyContent:'center',
            alignContent:'center',
            overflowY: 'auto'
           }}>
          <div style={{ display: 'flex', flexDirection:'row-reverse',paddingTop:'12px',paddingRight:'12px' }}>
            <IconButton sx={{ padding: '0px' }} onClick={handleClosemodal1} >
              < CancelOutlinedIcon sx={{ color: '#606060', width: '24px', height: '24px' }} />
            </IconButton>
            </div>
             <div style={{ display: 'flex', flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center'}}>
                <Box sx={{height:'40px',width:'40px',borderRadius:'40px',border:'1px solid #1F6373',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                    <PersonIcon sx={{color:'#1F6373',height:'40px',width:'40px'}}/>
                </Box>
                <Typography sx={{fontSize:'18px',fontWeight:600,color:'black',fontFamily:'Poppins'}}>Sign up</Typography>
             </div>
             <div style={{ display: 'flex', flexDirection:'column',paddingLeft:'28px',paddingRight:'28px',paddingTop:'48px'}}>
              <Typography sx={{textAlign:'left',fontSize:'14px',color:'#212427',paddingBottom:'4px',fontFamily:'Poppins'}}>Username</Typography>
              <CustomOutlined type="text" sx={{border:'1px solid #D2D2D2'}}/> 
                <Typography sx={{textAlign:'left',fontSize:'14px',color:'#212427',paddingBottom:'4px',paddingTop:'16px',fontFamily:'Poppins'}}>Registered email id</Typography>
              <CustomOutlined type="email" sx={{border:'1px solid #D2D2D2'}}/> 
                <Typography sx={{textAlign:'left',fontSize:'14px',color:'#212427',paddingBottom:'4px',paddingTop:'16px',fontFamily:'Poppins'}}>Mobile number</Typography>
                <CustomOutlined type="email" sx={{border:'1px solid #D2D2D2'}}/> 
              <Typography sx={{textAlign:'left',fontSize:'14px',color:'#212427',paddingBottom:'4px',paddingTop:'16px',fontFamily:'Poppins'}}>Create password</Typography>
              <CustomOutlined sx={{border:'1px solid #D2D2D2'}}
                      id="outlined-adornment-password"
                    type = {showPasswordValues.currentPassword ? 'text' : 'password'}
                    value={values.currentPassword}
                    onChange={handleChange('currentPassword')}
                            endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword("currentPassword")}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"/> 
                         {values.currentPassword===''?null:showPasswordValues.currentPassword ?   <Visibility />:<VisibilityOff />}
                       </InputAdornment>
            }/> 
              <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:{lg:'18px',md:'18px',xs:'4px'},paddingLeft:'10px',marginTop:'16px',marginBottom:'48px'}}>
              <Typography sx={{textAlign:'left',fontSize:'16px',color:'#626262',fontFamily:'Poppins'}}>Are you an agent?</Typography>
              <RadioGroup
                 row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group">
        <FormControlLabel value="No" control={<Radio  size = 'small' />} label="No" sx={{color:'#626262',fontSize:'16px',fontFamily:'Poppins',"& .MuiFormControlLabel-label":{fontFamily:'Poppins',fontSize:'16px'}}} />
        <FormControlLabel value="Yes" control={<Radio size = 'small' sx={{color:'#626262',"& .Mui-checked":{color:'#626262',},}}/>} label="Yes" sx={{color:'#626262',fontSize:'16px',fontFamily:'Poppins',"& .MuiFormControlLabel-label":{fontFamily:'Poppins',fontSize:'16px'}}}/>
        </RadioGroup>
        </Box>
             <PrimaryButton>Sign up</PrimaryButton>
              <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:{lg:'20px',md:'20px',xs:'4%'},'marginTop':'16px'}}>
              <Typography sx={{fontSize:'1rem',color:'#626262',fontFamily:'Poppins'}}>Already have an account ?</Typography>
              {/* <a href='#' style={{fontSize:'1rem',color:'#198CCD',fontFamily:'Poppins',fontWeight:500}}>Sign in</a> */}
              <Link  style={{textDecoration:'none'}} onClick={handleSignInLinkClick}>
                <Typography sx={{fontSize:'1rem',color:'#198CCD',fontFamily:'Poppins',fontWeight:500,}}>Sign in</Typography>
                </Link>
              </Box>
            </div>
        </Box>
        {/* </Modal> */}
        </Box>
      )}
        </Box>
  )
}
export default SignupMob;