import React, { useState } from 'react';
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Button,Grid, Typography  } from "@mui/material";
import Crop169Icon from "@mui/icons-material/Crop169";
import notification from "./images/Notification (4).png";
import Messages from "./images/Messages.png";
import Discussion from "./images/Discussion.png";
import Profile from "./images/image 22.png";
import { InputLabel } from '@mui/material';
import home from "./images/Frame 5337.png";
import card from "./images/ic_round-car-crash.png";
import car from "./images/Frame 5412.png";
import tds from "./images/Frame 5411.png";
import gds from "./images/Frame 5410.png";
import calender from "./images/Frame 5343.png";
import timer from "./images/Vector (3).png";
import bank  from "./images/Vector (2).png";
import hand from "./images/Group 3427.png";
import UserDtls from './AgentLandingUserDetails';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import sendicon from './images/Vector (5).png';
import downicon from './images/material-symbols_download.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import GetAppIcon from '@mui/icons-material/GetApp';
import AgentNotificatinMenu from './AgentNotificationMenu';
function AgentLanding() {

  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (value) => {
  
    setIsOpen(false);
  }

    const menus = [
        "Calculator",
        "Quotations",
        "Products",
        "About us",
        "Contact us"
      ];
      const divToRender = <UserDtls></UserDtls>;
      const [isHoveredSendQuote, setIsHoveredSendQuote] = useState(false);

      const handleMouseEnterSendQuote = () => {
        setIsHoveredSendQuote(true);
      };
    
      const handleMouseLeaveSendQuote = () => {
        setIsHoveredSendQuote(false);
      };
    
    
      const [isHoveredSendToMail, setIsHoveredSendToMail] = useState(false);
    
      const handleMouseEnterSendToMail = () => {
        setIsHoveredSendToMail(true);
      };
    
      const handleMouseLeaveSendToMail = () => {
        setIsHoveredSendToMail(false);
      };
      const [isHoveredDownloadResult, setIsHoveredDownloadResult] = useState(false);
    
      const handleMouseEnterDownloadResult = () => {
        setIsHoveredDownloadResult(true);
      };
    
      const handleMouseLeaveDownloadResult = () => {
        setIsHoveredDownloadResult(false);
      };
      const [showNotificationMenu, setShowNotificationMenu] = useState(false);

      const handleClickNotificationImage = () => {
        
        setShowNotificationMenu(!showNotificationMenu);
      };
    return(
      <Grid container sx={{ width: '100%', height: '100%' }}>
         <Box  sx={{ height:{md:'1024px',sm:'1024px'} , width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400" }}> 
       <Box sx={{display:'flex', justifyContent:'center',alignItems:'center'}} > 
          <AppBar position="static" sx={{ bgcolor: "#1F6373", height: "72px", width: '100vw' }}>
         
          <Box sx={{display: "flex" ,width:'100%',flexDirection:'row',justifyContent:'center',alignItems:'center',height: "72px"}}> 
       
       <Box sx={{display: "flex" ,width:'10%',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <Crop169Icon
                  sx={{
                    display:  "flex" ,
                 
                    height: 40,
                    width: '75%',
                    backgroundColor: "#fff"
                  }}
                />
  </Box>
                <Box sx={{  display: "flex" ,flexDirection:'row',gap:{md: '5%',sm:'0'},width:{md: '60%',sm:'56%'},justifyContent:'left'
                ,alignItems:'left',paddingLeft:{md: "3%",sm:'0'}, }}>
                  {menus.map((menu) => (
                    <Button
                      key={menu}
  
                      sx={{
                        my: 2, color: "white", display: "block",  textTransform: "capitalize", fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "21px", fontWeight: "400",'&:hover': { color: "#939393" },paddingRight:{md: "5%",sm:'.5%'},
                      }}
                    >
                      {menu}
                    </Button>
                  ))}  </Box>

                  
      <Box sx={{display:'flex', flexDirection:'row',justifyContent:'center',width:'15%',gap:{md: '10%',sm:'5%'},alignItems:'center'}} >        
  <img src={Discussion} alt=""  />
  <img src={Messages} alt="" />
  <img src={notification}  onClick={handleClickNotificationImage} alt=""  />
 {showNotificationMenu && <AgentNotificatinMenu />}
  </Box>
  <Box sx={{display:'flex', flexDirection:'row',justifyContent:'center',width:'15%',gap:{md:'7%',sm:'5%'},alignItems:'center',
  marginTop:{md:'0',sm:'2px'}}} > 
  <img src={Profile} alt=""  />
  <InputLabel style={{  fontFamily: "poppins", fontSize:{md:"16px",sm:"14px"} , fontWeight: "400",lineHeight:'24px',color:"#fff",
  }}>Robert</InputLabel>

<div className="dropdown" >
      <div className="dropdown__toggle" onClick={handleMenuToggle}>
      <ExpandMoreIcon style={{marginRight:'5%',marginTop:'5px',}}></ExpandMoreIcon>
      </div>
      {isOpen && (
        <div className="dropdown__menu"  style={{ position: 'absolute', right: '10px ',fontFamily: "poppins",
         fontSize: "12px",  fontWeight: "400",lineHeight:'20px',width:'134px',height:'56px',background:'#F8F8F8',borderRadius:'8px',
        boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',zIndex:1, minHeight: '20px',padding:'4px',color:'#2F2F2F'}}>
           <style>
            {`
              .dropdown__menu .MuiMenuItem-root:hover {
                background-color: #1F6373;
                color:white;
              }
              
            `}
          </style>
          <MenuItem  onClick={() => handleMenuItemClick("myprofile")} value="option1" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
          fontFamily: "poppins", fontSize: "12px",fontWeight: "400",lineHeight:'20px'}}> <PersonIcon style={{width:'15px',height:'17px',
          marginRight:'10px'}}>
            </PersonIcon>My profile</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("signout")} value="option2" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
          fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'20px'}}><LogoutIcon style={{width:'15px',height:'17px',
          marginRight:'10px'}}></LogoutIcon>Sign out</MenuItem>
         
        </div>
      )}
    </div>
    </Box>   

              
    </Box>   
         
          </AppBar>
        </Box>
<Box sx={{marginLeft:{md:'2.5%',sm:'2.5%'},width: 'calc(100%-10%)',marginRight:{md:'2.5%',sm:'2.5%'},background:'#ECECEC',
alignItems: 'center',justifyContent:'center'}}>
<AppBar position="static" sx={{ bgcolor: "#1F6373", height: "86px", width: '100%',marginTop:'22px',alignItems: 'left',
justifyContent:'center' }}>
  <Box sx={{display: 'flex', flexDirection: 'row',width:'100%',alignItems: 'left',justifyContent:'left',gap:'5%'}}>
  <Box sx={{display: 'flex', flexDirection: 'row',width:'5%',alignItems: 'center',justifyContent:'center',marginLeft:{md:'2.5%',sm:'4%'}}}>
  <Box sx={{display: 'flex', flexDirection: 'column',alignItems: 'center' }}>
   <Typography sx={{  fontFamily: "poppins", fontSize:{ md: "16px", sm: "14px" }, fontWeight: "500",lineHeight:'24px',color:"#fff",marginTop:'20%'
   }}>Enquiries </Typography>
  </Box>
  </Box>
  <Box sx={{display: 'flex', flexDirection: 'column',width:'18%',marginRight:{ md:'1%', sm: "0%" }}}>  
  <Box sx={{display: 'flex', flexDirection: 'row',width:'100%',alignItems: 'center',justifyContent:'center'}} >
   
    <Typography sx={{  fontFamily: "poppins", fontSize:{ md: "14px", sm: "12px" }, fontWeight: "500",lineHeight:'21px',color:"#fff",
   }}>Income , Expenses</Typography> 
   
  </Box>
   <Box sx={{display: 'flex', flexDirection: 'row' , border: "1px solid #79A3A8",width:{ md:'91%', sm: "95%" },height:'40px',gap:{ md:'27%', sm: "15%" },
   borderRadius: "8px",paddingLeft:'7%'}}>
<img src={home} alt="" style={{ marginTop: "8px",marginBottom:'8px',}} />
<img src={card} alt="" style={{ marginTop: "8px",marginBottom:'8px',}} />
<img src={car} alt="" style={{ marginTop: "8px",marginBottom:'8px' }} />
</Box>
</Box>

 <Box sx={{display: 'flex', flexDirection: 'column',marginLeft:'-2%',width:{ md:'8.5%', sm: "12%" }}}>  
 <Box sx={{display: 'flex', flexDirection: 'row',width:'100%',alignItems: 'center',justifyContent:'center'}} >

   <Typography sx={{  fontFamily: "poppins", fontSize:{ md: "14px", sm: "12px" }, fontWeight: "500",lineHeight:'21px',color:"#fff"      }}>
    Eligibility ratios</Typography></Box>
<Box sx={{display: 'flex', flexDirection: 'row' , border: "1px solid #79A3A8",width:'92%',height:'40px',paddingLeft:'8%',gap:{ md:'45%', sm: "35%" },
borderRadius: "8px"}}>
    <img src={tds} alt="" style={{ marginTop: "8px",marginBottom:'8px',}} />
<img src={gds} alt="" style={{ marginTop: "8px",marginBottom:'8px',}} />
</Box>
</Box>
<Box sx={{display: 'flex', flexDirection: 'column',marginLeft:{ md:'0%', sm: "-2.5%" },width:{ md:'25%', sm: "26%" }}}> 
<Box sx={{display: 'flex', flexDirection: 'row',width:'100%',alignItems: 'center',justifyContent:'center',}} >
<Typography sx={{  fontFamily: "poppins", fontSize: { md: "14px", sm: "12px" }, fontWeight: "500",lineHeight:'21px',color:"#fff",
marginLeft:{ md: "0", sm: '-10%' }
   }}>Mortgage requirements</Typography></Box>
<Box sx={{display: 'flex', flexDirection: 'row' , border: "1px solid #79A3A8",width:'88%',height:'40px',gap:{ md:'22%', sm: "15%" },
borderRadius: "8px",marginLeft:'0px',paddingLeft:{ md:'5%', sm: "3%" }}}>
<img src={calender} alt="" style={{ marginTop: "8px",marginBottom:'8px',}} />
<img src={timer} alt="" style={{ marginTop: "8px",marginBottom:'8px'}} />
<img src={bank} alt="" style={{ marginTop: "8px",marginBottom:'8px'}} />
<img src={hand} alt="" style={{ marginTop: "8px",marginBottom:'8px'}} />
</Box>
</Box>
<Box sx={{display: 'flex', flexDirection: 'row' ,alignItems: 'center',justifyContent:'center',width:'10%',marginLeft:'-5%'}} >
<Typography sx={{  fontFamily: "poppins", fontSize:{ md: "16px", sm: "14px" }, fontWeight: "500",lineHeight:'24px',color:"#fff",marginTop:'20px',
}}>Status </Typography>

 
</Box> 
<Box sx={{display: 'flex', flexDirection: 'row' ,alignItems: 'center',justifyContent:'center',width:{ md:'10%', sm: "15%" },marginLeft:'-5%'}} >
<Typography sx={{  fontFamily: "poppins", fontSize: { md: "16px", sm: "14px" }, fontWeight: "500",lineHeight:'24px',color:"#fff",marginTop:'20px',
}}>Date & Time </Typography>
</Box>
</Box>
</AppBar>

<Box>
      {[...Array(10)].map((_, index) => (
        <React.Fragment key={index}>{divToRender}</React.Fragment>
      ))}
    </Box>
    

</Box>
<Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    Height: '80%',  
    position: 'fixed',
    left: 0,
    bottom: 0,
   
    width:{md:'calc(100%)',sm:'calc(100%)'} ,
 
 boxShadow:' 0px -4px 4px rgba(217, 217, 217, 0.25)',
   backgroundColor:'white'
  }}
>
<Box sx={{display: 'flex', flexDirection: "row", marginTop:{md:'2.5%',sm:'3%'} , alignItems: 'center',width: 'calc(100%)' 
        ,marginLeft:{md:'15%',sm:'5%'},marginRight:{md:'15%',sm:'5%'},gap:{md:'2%',sm:'1%'},justifyContent: 'center' ,
        }}>
  
<Button variant="contained"  onMouseEnter={handleMouseEnterSendQuote} 
        onMouseLeave={handleMouseLeaveSendQuote}  style={{
            color: "white", textTransform: "none", padding: "10px 16px 10px 16px ",
            background:  isHoveredSendQuote   ? '#074554' : '#1F6373', border: "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal", lineHeight: "21px",
            fontWeight: "500", fontSize: "14px", width: "348px", borderRadius: "4px", marginBottom: '25px'
          }} startIcon={<img src={sendicon} alt="" />}>
           Send quotes
          </Button>
          <Button variant="contained" onMouseEnter={handleMouseEnterSendToMail} 
        onMouseLeave={handleMouseLeaveSendToMail}  style={{
          color:isHoveredSendToMail?"white": "#1F6373",textTransform: "none", padding: "10px 16px 10px 16px ", marginLeft: "14px",
            background:isHoveredSendToMail?"#1F6373 ":"#D2E0E3", border: "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal", lineHeight: "21px",
            fontWeight: "500", fontSize: "14px", width: "170px", borderRadius: "4px", marginBottom: '25px'
          }}
            startIcon={<MailOutlineIcon  style={{  color:isHoveredSendToMail?"white": "#1F6373", }}  />}>
            Send to email
          </Button>
          <Button variant="contained" onMouseEnter={handleMouseEnterDownloadResult} 
        onMouseLeave={handleMouseLeaveDownloadResult}  style={{
          color:isHoveredDownloadResult?"white": "#1F6373", textTransform: "none", padding: "10px 16px 10px 16px ", marginLeft: "14px",
            background:isHoveredDownloadResult?"#1F6373 ":"#D2E0E3", border: "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal", lineHeight: "21px",
            fontWeight: "500", fontSize: "14px", width: "204px", borderRadius: "4px", marginBottom: '25px'
          }}  startIcon={<GetAppIcon style={{  color:isHoveredDownloadResult?"white": "#1F6373", }} />}>
            Download result
          </Button>
</Box>

</Box>
</Box>     
          </Grid>
  
    );
    
}
export default AgentLanding;