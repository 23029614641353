import Box from '@mui/material/Box';
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import logo from './images/NeholaHeader.png'
import { Link } from 'react-router-dom';
import { Button, Grid, Typography } from "@mui/material";
import BuilderSignUp from './BuilderSignUp';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import Toolbar from "@mui/material/Toolbar";
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MenuIcon from '@mui/icons-material/Menu';
import { API_URL } from "./constants";
import axios from "axios";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function HeaderMobile(props) {
  const login_id = localStorage.getItem('login_id') 
  let mailbuilderId;
    const menus = [
        { label: "Universal Calculator", link: "/" },
        { label: "Canada Mortgage", link: "/canada-mortgage" }, 
        { label: "Nehola", link: "/projects" },      
        { label: "About us", link: "/about-us" },
        { label: "Contact us", link: "/contact-us" }
    ];
    const [showMenu, setShowMenu] = useState(false);

    const handleMenuToggle = () => {
      setShowMenu(!showMenu);
    };
    const [isTermsAndCondition, setIsTermsAndCondition] = useState(false);
    const [thankYouPopUp, setThankYouPopUp] = useState(false);
    const [resetPopUp, setResetPopUp] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    const [resetConfirmPopUp, setResetConfirmPopUp] = useState(false);
    const [isSignOutPopUp, setIsSignOutPopUp] = useState(false);
    const [signInPopUp, setSignInPopup] = useState(false);
    const [signUpPopUp, setSignUpPopUp] = useState(false);
    const [verificationPopUp, setVerificationPopUp] = useState(false);
    
    const handleSignUpButton = () => {
      setSignInPopup(true);
  };
  const [emailForPasswordChange, setEmailForPasswordChange] = useState('');
  const [changePasswordPopUp, setChangePasswordPopUp] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    //console.log( queryParams.get('listingagentid'))
    // Access the query parameter and convert it to a boolean
    if (queryParams.get('changePassword') === 'true') {
      setChangePasswordPopUp(true);
    }
    if (queryParams.get('signIn') === 'true') {    
      fetchData() 
      setSignInPopup(true);
    }
    const emailParam = queryParams.get('email');
    if (emailParam) {
      setEmailForPasswordChange(emailParam);
    }
  }, []); // Use an empty dependency array to ensure this effect runs only once

  async function fetchData() { 
    const queryParams = new URLSearchParams(window.location.search); 
    const listingagentid = queryParams.get('listingagentid');
    localStorage.setItem('listing_agent_id', listingagentid);
    const myprofilelisting_agent_id = localStorage.getItem('listing_agent_id');

try {
  const response = await axios.post(`${API_URL}/update-loginstatus`, {
    listingagentid: listingagentid // Send it as a plain integer, not in an object
  });
} catch (error) {
  console.error('Error:', error.message);
}

      }
      
        useEffect(() => {
          fetchData();
        }, [signInPopUp]);

const [isOpen, setIsOpen] = useState(false);

const handleMenuToggleDropDown = () => {
  setIsOpen(!isOpen);

};
const handleMenuItemClick = (value) => {
  
    if (value === "myprofile") {
      window.location.href = '/edit-profile';
        setEditProfile(true)        
      }  
      // else if (value === "Addbuilders") {
      //   window.location.href = '/add-builder-details';
      // }     
      else if (value === "myprojects") {
        window.location.href = '/personal-projects';
      } 
      else if (value === "signout") {
        setIsSignOutPopUp(true);
      }
  setIsOpen(false);
}
const handleSignoutConfirm = async() => {
  try {
    const response = await axios.post(`${API_URL}/update-signout`, {          
      login_id: login_id,
  });
} 
catch (error) {
    console.error('Error:', error.message);
}
  localStorage.removeItem('listing_agent_id'); 
  localStorage.removeItem('myprofilelisting_agent_id'); 
  localStorage.removeItem('builder'); 
  localStorage.removeItem('builder_id');
  localStorage.removeItem('user_name');
  localStorage.removeItem('token');
  window.location.href = '/projects';

};

const closeSignOutPopup = () => {
  setIsSignOutPopUp(false);

};
const userName = localStorage.getItem('user_name');
//console.log("buildername",userName)
  // Split the user's name by spaces and take the first word
  const firstName = userName ? userName.split(' ')[0] : '';
return (


<Grid container sx={{width: '100%', height: '100%' }}>
<AppBar position="static" sx={{ background: "#504FA4", height: "56" ,paddingLeft:'2.5%', width: '100%', paddingRight:'2.5%' }}>
     <Toolbar disableGutters>
       <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center', alignItems: 'center', paddingLeft: '18px',
            width:'30%'         }}>
           <img src={logo} alt=""  style={{ height: '100%',width: '90%',}}/>
           <IconButton   edge="start" color="inherit" aria-label="menu" onClick={handleMenuToggle}>
               <MenuIcon />
           </IconButton>
       </Box>
       <Box sx={{display: showMenu ? "block" : "none", position: "absolute", top: "100%", width: "100vw", zIndex: 5,
           background: "#504FA4", justifyContent: 'flex-start', borderRadius: ' 0px 0px 8px 8px', paddingBottom: '20px'}}>
           {menus.map((menu) => (
           <Button onClick={handleMenuToggle}
              key={menu.label}
              component={Link}
              to={menu.link}
              sx={{display: "block", height: '40px', color: "white", textTransform: "capitalize", fontFamily: "poppins",
                 fontSize: "14px", lineHeight: "21px", fontWeight: "400", textAlign: 'left', borderRadius: '0px',
                 '&:hover': { background: "#D2D2D2", width: '100%',color:'#504FA4' }}}>
             {menu.label}
           </Button>  ))}
       </Box>
       <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '12px', marginLeft: 'auto' }}>
       {! localStorage.getItem('builder') &&        <Button
           variant="contained"  onClick={handleSignUpButton}
           sx={{
             bgcolor: "#F8A81D", color: "#313131", borderRadius: "16px", gap: "10px",
             alignContent: "center", padding: "4px 10px", textTransform: "capitalize",
              fontFamily: "poppins", fontSize: "14px", border: "1px solid #F8A81D",
             lineHeight: "21px", fontWeight: "400", width: "200px", height: "28px", 
             '&:hover': { bgcolor: "#FF8C00", color: '#313131',border: "1px solid #FF8C00" }, ml: "auto"
           }}
         >
           Listing agent sign in
         </Button> }
         { localStorage.getItem('builder') &&  <Box className="dropdown__toggle" onClick={handleMenuToggleDropDown}>
<Button  variant="text" sx={{
                        color: "#fff", height: '30px', width:{ md:'158px', sm:'158px' },  borderRadius: "12px", background: 'none', border: "none",
                        fontFamily: "Roboto", textTransform: "none", fontStyle: "normal", fontWeight: "600", fontSize:{ md: "16px", sm: "16px", xs: "12px" } , '&:hover': { bgcolor: "none", color: '#fff',border: "none", }                       
                    }}> 
                    <AccountCircleOutlinedIcon sx={{paddingRight:'8px', fontSize:'32px'}} />
                    {firstName} <ExpandMoreIcon sx={{paddingLeft:'8px', fontSize:'32px'}}/>               
                  </Button>  

          </Box>}
          {isOpen && (
            <Box className="dropdown__menu" sx={{
              position: 'absolute', right: '25px',top:'60px', fontFamily: 'poppins', fontSize: '12px', fontWeight: '400',
              lineHeight: '20px', width: '150px', background: '#F8F8F8', borderRadius: '8px', 
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', zIndex: 5,
              color: '#2F2F2F', padding: '4px'
            }}>
              <style>
                {`
    .dropdown__menu .MuiMenuItem-root:hover {
      background-color: #504FA4 !important;
      color: white;
    }
     }
   `}
    </style>
             
              <MenuItem onClick={() => handleMenuItemClick("myprofile")} value="option1" style={{
                fontFamily: 'poppins', fontSize: '14px',
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px',
              }}>
                <PersonIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                My profile
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("myprojects")} value="option1" style={{
                fontFamily: 'poppins', fontSize: '14px',
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
                <DriveFolderUploadOutlinedIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                My  projects
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("signout")} value="option2" style={{
                fontFamily: 'poppins', fontSize: '14px',
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
                <LogoutIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                Sign out
              </MenuItem>
            </Box>

          )}
       
       </Box>
     
     </Toolbar>
</AppBar>
{signUpPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
        changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
      resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
      resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
      emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange}
      isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
      setVerificationPopUp={setVerificationPopUp}/>
      )}
       {signInPopUp && (
       <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
       signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
       changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
     resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
     resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
     emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange} 
     isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
     setVerificationPopUp={setVerificationPopUp}/>
      )}
 {changePasswordPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
        changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
      resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
      resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
      emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange} 
      isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
      setVerificationPopUp={setVerificationPopUp}/>
      )}
       {resetPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
        changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
      resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
      resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
      emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange} 
      isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
      setVerificationPopUp={setVerificationPopUp}/>
      )}

{resetConfirmPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
        changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
      resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
      resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
      emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange}
      isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
      setVerificationPopUp={setVerificationPopUp}/>
      )}

{thankYouPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
        changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
      resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
      resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
      emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange} 
      isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
      setVerificationPopUp={setVerificationPopUp}/>
      )}

{isTermsAndCondition && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
        changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
      resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
      resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
      emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange} 
      isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
      setVerificationPopUp={setVerificationPopUp}/>
      )}

{isSignOutPopUp && (

<Box
  sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2'
  }}
>
  <Box
    sx={{
      position: 'absolute',
    
      width: '378px',
      height: '202px',
      backgroundColor: '#fff',

      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3'
    }}
  >
    {/* <IconButton style={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left: '347px', top: '12px'
    }} >
      <HighlightOffIcon onClick={closeSignOutPopup}  ></HighlightOffIcon>
    </IconButton> */}

    <Typography style={{
      fontFamily: "poppins", fontSize: "16px", lineHeight: "21px", fontWeight: "600", color: '#313131', width: "290px",
      textAlign: "center"
    }}>Sign out your account</Typography>
    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
      textAlign: "center",paddingTop:'20px'
    }}>Are you sure you would like to sign out
    your account?</Typography>
    <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
    <button size='small' onClick={closeSignOutPopup} style={{
        width: '77px', height: '32px', fontFamily: 'Poppins', fontStyle: 'normal',
        fontWeight: '400', fontSize: '14px', lineHeight: '21px', marginRight: '24px', textAlign: 'center', color: '#F8A81D',
        background: '#Fff', border: '1px solid #F8A81D', borderRadius: '4px',cursor:'pointer',
        '&:hover': { bgcolor: "#FFf", color: '#F8A81D',border: "1px solid #FF8C00" },
      }} >Cancel</button>
       <button size='small'   onClick={ handleSignoutConfirm} style={{
        width: '77px', height: '32px', fontFamily: 'Poppins',
        fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
        background: '#504FA4', border: '1px solid #504FA4', borderRadius: '4px',cursor:'pointer', 
        '&:hover': { bgcolor: "#3A3990", color: '#313131',border: "1px solid #3A3990" },
      }}  >Sign out </button>
    </Box>
  </Box>
</Box>
)}
</Grid>
 );

}

export default HeaderMobile;
