
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import { Button, Grid, Typography , Card, CardContent, CardMedia, Stack, Divider, InputBase,} from "@mui/material";
import logo from './images/logo.png'
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useState } from "react";
import SignIn from './SignInSignUp'
import '../App.css';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Footer from './Footer';
import Header from './HeaderLanding';
import HeaderMobile from './HeaderLandingMobile';
import bloghdr from '../components/images/bloghdr.png'
import blog1 from './images/blog.png'
import twitter from './images/twitter.svg'
import linkedin from './images/linkedin.svg'
import facebook from './images/facebook.svg'
import { Helmet } from 'react-helmet-async';
import { API_URL, FRONT_URL } from './constants';
import axios from 'axios';
import BlogComponent from './BlogComponent';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PrimaryButton from './customButton';
import share from './images/share.svg'

function generateUrlFromTitle(title) {
  return title.toLowerCase().replace(/\s+/g, '-');
}

  
  function Blogs() {
    const [blogs, setBlogs] = useState([]);

    async function fetchData() {
    
      try {
          const response = await axios.get(`${API_URL}/blog_data`);                   
          console.log("details",response.data)
          setBlogs(response.data)
      } 
      catch (error) {
          console.error('Error:', error.message);
      }
    }
  
    useEffect(() => {
      fetchData();
    }, []);
    
      const articlesPerPage = 5;
      const totalPages = Math.ceil(blogs.length / articlesPerPage);
  
      const [currentPageNumber, setCurrentPageNumber] = useState(1);
  
      const startIndex = (currentPageNumber - 1) * articlesPerPage;
      const endIndex = Math.min(startIndex + articlesPerPage, blogs.length);
  
      const displayedprojects = blogs.slice(startIndex, endIndex);
  
      const handlePageClick = (page) => {
          setCurrentPageNumber(page);
      };

      const [selectedUrl, setSelectedUrl] = useState("");
      const [selectedText, setSelectedText] = useState("");
      const [isShare, setIsShare] = useState(false);

      const handleCopyButtonClick = async () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(`${selectedUrl}`)
                .then(() => {
                    console.log('Link copied to clipboard');
                    setIsShare(false);
                })
                .catch((error) => {
                    console.error('Failed to copy:', error);
                    fallbackCopyTextToClipboard(`${selectedUrl}`);
                });

        } else {
            setIsShare(false);
            console.warn('Clipboard API not supported, unable to copy text');
            fallbackCopyTextToClipboard(`${selectedUrl}`);
        }
     };

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;

        // Avoid scrolling to the bottom of the page
        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';

        document.body.appendChild(textArea);
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            console.log('Fallback: Copying text command was ' + (successful ? 'successful' : 'unsuccessful'));
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    };

    const handleShareButtonClick = (url, text, e) => {
        e.stopPropagation();
        setIsShare(true); 
        setSelectedUrl(`${FRONT_URL}/blog/${url}`)
        setSelectedText(text)      
    };  

    
    const handleCloseSharePopUp = () => {
        setIsShare(false);
    };
      

  
  return (
    <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0, display:'flex', justifyContent:'center', 
    alignItems:'center',flexDirection:'column', }}>


    <Helmet>
                <title>Mortgage Calculator Ontario | Best Mortgage Rates | RateWave</title>
                <meta name="description" content="Mortgage Calculator Ontario and current mortgage rates Ontario-wide at your fingertips. RateWave's tools help you make smarter mortgage decisions. Try it free." />
                <link rel="canonical" href="https://ratewaves.com/blog" /> 
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Mortgage Calculator Ontario | Best Mortgage Rates | RateWave",
                        "description": "Mortgage Calculator Ontario and current mortgage rates Ontario-wide at your fingertips. RateWave's tools help you make smarter mortgage decisions. Try it free.",
                        "url": "https://ratewaves.com/blog",
                        "author": {
                            "@type": "Organization",
                            "name": "Rate Waves"
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "Rate Waves",
                            "logo": {
                                "@type": "ImageObject",
                                "url": logo
                            }
                        },
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://ratewaves.com/blog"
                        }
                    })}
                </script>           
            </Helmet>
            <h1 style={{ position: 'absolute', left: '-9999px' }}>Mortgage calculator onatario</h1>


        <Box sx={{ height: '100% ', width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0, 
        padding: 0,display:{md: 'flex', sm: 'none',xs:'none' } }}>
           <Header currentPage={'Blog'}></Header>       
        </Box>
        <Box sx={{ display:{md: 'none', sm: 'flex',xs:'flex' }, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
             <HeaderMobile currentPage={'Blog'}></HeaderMobile>
        </Box>
       <Box sx={{display:'flex', flexDirection:'column', width:'100%', height:{md:'95%', xs:'90%'}, maxWidth:{md:'90%', xs:'92%'}, paddingTop:{md:'2.5%', xs:'5%'}, 
        paddingBottom:{md:'2.5%', xs:'5%'}, gap:'36px'}}> 
    <Typography   
      sx={{fontFamily:'Poppins', fontSize:{md:'32px', xs:'16px'}, fontWeight:500, lineHeight:{md:'36px', xs:'24px'}, color:'#313131',textAlign:'center'
       }}>
         Blog  
        </Typography>      
         </Box>
         <Box sx={{display:'flex', flexDirection:{md:'row', xs:'column'}, width:'100%', height:{md:'95%', xs:'90%'}, maxWidth:{md:'90%', xs:'92%'}, paddingTop:{md:'2.5%', xs:'5%'}, 
        paddingBottom:{md:'2.5%', xs:'5%'}, gap:'32px'}}> 
         <Box sx={{display:'flex', flexDirection:'column', width:{md:'68%', xs:'100%'},  }}> 
        
      {displayedprojects.map((blog, index) => (
      <Box key={index} sx={{display:'flex', flexDirection:'column', width:'100%', height:{md:'420px', xs:'100%'}, gap:'16px'}}>
   
      <Typography  onClick={() => { window.location.href = `/blog/${generateUrlFromTitle(blog.title)}`; }} 
      sx={{fontFamily:'Poppins', fontSize:{md:'24px', xs:'16px'}, fontWeight:500, lineHeight:{md:'36px', xs:'24px'}, color:'#212427',cursor:'pointer',
       '&:hover':{color:'#198CCD'}}}>
            {blog.title}
        </Typography>
     <Card sx={{ display: 'flex',  boxShadow: 'none', flexDirection:{xs:'column', md:'row'}, gap:'16px'}}>
    
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: {md:'30%', xs:'100%'} ,
       gap:'16px', position:'relative'}}>
       
        <CardMedia
        component="img"
        image={blog1} // Replace with your dynamic image
        alt="Blog cover image"
        sx={{
          width: '100%',
          height: 'auto',
          borderRadius: 1,
          cursor: 'pointer',
        }}
        onClick={() => { window.location.href = `/blog/${generateUrlFromTitle(blog.title)}`; }}// Navigate to the blog page when the image is clicked
      />

      {/* Share button over the image */}
      <IconButton
        onClick={(e) => handleShareButtonClick(generateUrlFromTitle(blog.title), blog.title, e)}
        sx={{
          position: 'absolute',
          bottom: {md:'40px', xs:'16px'},  
          right: '10px', 
        
        }}
      >
        <img src={share} alt="Share Icon" style={{ width: '36px', height: '36px' }}/>
      </IconButton>
       <Typography sx={{fontFamily:'Poppins', fontSize:'16px', fontWeight:500, lineHeight:'22px', color:'#626262', 
          textAlign:'right', display: {md:'flex', xs:'none'},width:'100%', alignItems:'flex-start', justifyContent:'flex-start' }}>          
           Published On {blog.publish_date}
        </Typography>

      </Box>
      <CardContent sx={{ width:{md:'70%', xs:'100%'}, paddingLeft: '16px' ,padding:0}}>
        <Typography 
                sx={{
                   fontFamily:'Poppins', fontSize:{md:'16px', xs:'12px'}, fontWeight:400, lineHeight:{md:'24px !important', xs:'24px'},
                    color:'#000000',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    WebkitLineClamp: 6,  // Limits the content to 4 lines
                }}
                dangerouslySetInnerHTML={{ __html: blog.blog_content }} 
                />

       
        <Link to={ `/blog/${generateUrlFromTitle(blog.title)}`} sx={{ textDecoration: 'none', }}>
            <Button variant="text" sx={{
                fontFamily: 'Poppins', fontSize:{md: '20px', xs:'12px'}, fontWeight: 600, lineHeight: '36px',
                color: '#EB5648', textTransform: 'none', padding: 0,backgroundColor:'none', 
                '&:hover':{color:'#F5220E', fontWeight:600, backgroundColor:'none', background:'none'}
            }}>
              Read more
            </Button>
          </Link>


       


      </CardContent>
            </Card> 

            {index < displayedprojects.length - 1 && <Divider sx={{ mt: 2,  }} />}

            </Box>))}
            </Box>
            <Box sx={{display:'flex', flexDirection:'column', width:{md:'32%', xs:'100%'},  }}>
               <BlogComponent></BlogComponent>
            </Box>
 
            </Box>


       <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', width: '100%', gap: '10px', padding: '20px', paddingTop: '40px' }}>
                {Array.from({ length: totalPages }, (_, index) => (
                    <Button
                        key={index + 1}
                        onClick={() => handlePageClick(index + 1)}
                        variant={currentPageNumber === index + 1 ? "contained" : "outlined"}
                        sx={{
                            width: '28px', height: '28px', borderRadius: '4px', padding: 0, minWidth: 0,
                            backgroundColor: currentPageNumber === index + 1 ? '#504FA4' : '#fff',
                            color: currentPageNumber === index + 1 ? '#ffffff' : '#504FA4', border: '1px solid #504FA4',
                            '&:hover': { bgcolor: "#504FA4", color: '#fff', border: '1px solid #504FA4' }
                        }}
                    >
                        {index + 1}
                    </Button>
                ))}
            </Box>

            <Footer></Footer>

            {isShare && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '200',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    
                    <Box
                        sx={{width: { md: '35%', sm: '35%', xs: '85%' },height: 'fit-content',
                            backgroundColor:'white', border: '1px solid',
                            borderColor:'white', borderRadius: '4px ',boxShadow: 'none',
                            boxSizing: 'border-box',zIndex: '3', alignItems: 'center', 
                        }}>
                         <IconButton onClick={handleCloseSharePopUp} sx={{
                                    width: '100%', height: '36px', justifyContent:'flex-end', alignItems:'flex-end'}} >
                                        <HighlightOffIcon sx={{ width: '24px', height: '24px', color: '#939393', '&:hover': {
                                         color: '#939393', backgroundColor: 'none',
                                        background:'none'},}} ></HighlightOffIcon>
                                    </IconButton>
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: 'calc(100% )', gap: '16px',
                            justifyContent: 'center', alignItems: 'center', height: '100%',paddingLeft:'24px',
                            paddingRight:'24px', paddingBottom:'24px'
                        }}>
                                
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
                                    justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'stretch',
                                }}>
                                    <Typography sx={{ textAlign: 'left', fontWeight:500, fontSize:{md:'20px', xs:'16px'}, lineHeight: {md:'28px', xs:'20px'}}}>
                                        Share
                                    </Typography>
                                    <Box
                                    sx={{
                                        display:  'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                        gap: '12px',
                                        justifyContent: 'flex-start',
                                    }}
                                    >
                                   
                                    <img
                                        src={facebook}
                                        alt="facebook"
                                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                        onClick={() =>
                                            window.open(
                                                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                                    selectedUrl + `?title=${encodeURIComponent(selectedText)}`
                                                )}`,
                                                "_blank"
                                            )
                                        }
                                        
                                    />
                                     <img
                                        src={twitter}
                                        alt="twitter"
                                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                        onClick={() => window.open(`https://twitter.com/intent/tweet?url=${selectedUrl}&text=${selectedText}`, "_blank")}
                                    />
                                    <img
                                        src={linkedin}
                                        alt="linkedin"
                                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                        onClick={() =>
                                            window.open(
                                                `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(selectedUrl)}&title=${encodeURIComponent(selectedText)}&summary=&source=`,
                                                "_blank"
                                            )
                                        }
                                    />
                                   
                             
                           




                                    
                                    
                                    </Box> 

                                    <Typography sx={{ textAlign: 'left', paddingTop: '8px', fontWeight:500, fontSize:{md:'20px', xs:'16px'}, lineHeight: {md:'28px', xs:'20px'} }}>
                                    Share link
                                    </Typography>

                                    <Box sx={{
                                        width: 'calc(100%-32px)', height: '48px', border: '1px solid', padding: '10px 16px 10px 16px',
                                        borderColor: '#c9c9c9', borderRadius: '4px', display: 'flex', flexDirection: 'row',background:'#f9f9f9'
                                    }}>
                                        <InputBase
                                         value={selectedUrl}
                                            style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent', }}
                                            inputProps={{
                                                style: {
                                                    fontFamily: 'poppins', fontSize: {md:'16px', xs:'12px'},
                                                    '::placeholder': {
                                                        fontFamily: 'poppins', fontSize: {md:'16px', xs:'12px'},
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{
                                        width: 'calc(100%)', height: '100%', paddingTop: '8px',
                                        display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                    }}
                                    >
                                         <Button variant="contained"  sx={{
                            width: '100%', height: '36px', color: 'white',borderRadius:'8px',border:'1px solid #3A3990',
                            backgroundColor: '#504FA4', textTransform:'none', 
                            '&:hover': { bgcolor:'#CFDAF4', color: '#3A3990',border:'1px solid 65C898',boxShadow:'none' }
                        }} onClick={handleCopyButtonClick}><ContentCopyIcon sx={{ fontSize: { xs: '12px', md: '18px' } }} />Copy link</Button>
                                    </Box>
                                </Box>

                </Box>
                </Box>
                </Box>
                </Box>}

        </Grid>
  );

}

export default Blogs;