import React from "react";
import UserA from './images/Frame 5705.png';
import { Typography } from '@mui/material';

import home from "./images/Frame 5337.png";

import gds from "./images/Frame 5410.png";

import { useState } from "react";

import hand from "./images/Group 3427.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import { Button } from "@mui/material";

function UserDetailsMobile() {
   const [isHovered, setIsHovered] = useState(false);
const [isDateRemaining, setIsDateRemaining] = useState(true);
   const [isMoreDetails, setIsMoreDetails] = useState(false);

   const handleMouseEnter = () => {
      setIsHovered(true);
   };

   const handleMouseLeave = () => {
      setIsHovered(false);
   };
   const handleMoreDetails = () => {
      setIsMoreDetails(true);
   };
   const handleLessDetails = () => {
      setIsMoreDetails(false);
   };
   const [buttonLabel, setButtonLabel] = useState("New");
  const [buttonBackground, setButtonBackground] = useState('#D5FBFF')

  const handleSelection = (value) => {
    if (value === "sent") {
      setButtonLabel("Sent");
      setButtonBackground('#76DE24');
      setIsDateRemaining(false);
    } else if (value === "reject") {
      setButtonLabel("Rejected");
      setButtonBackground('#EB5648');
       setIsDateRemaining(false);
    } else if (value === "negotiate") {
      setButtonLabel("Negotiated");
      setButtonBackground('#79A3A8');
       setIsDateRemaining(false);
    }
  }
  const handleMenuItemClick = (value) => {
   handleSelection(value);
   
 }
   return (
      <Box>
         {!isMoreDetails && (<Box sx={{
            display: "flex", flexDirection: "coloumn", backgroundColor: '#F8F8F8', height: '230px', marginBottom: '10px',
            alignContent: "center", width: 'calc(100% )'
         }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Box sx={{ display: 'flex', paddingTop: '6px', paddingLeft: '20px', paddingRight: '20px', flexDirection: 'column', width: '100% ',
             gap: '18px' }}>
 <Box
                  sx={{
                     width: '100%', display: 'flex', flexDirection: "column", justifyContent: 'flex-end', alignItems: 'flex-end',gap:'2px',
                     alignContent:'right',
                  }}  >
                  {isDateRemaining && <Typography sx={{ fontFamily: "poppins", fontSize: "8px", fontWeight: "400", lineHeight: '12px',
                   color: "#CE3932", }}>
                       4 Days remaining..
                     </Typography> }  
                  <Button variant="contained"  style={{
      color: '#313131' , textTransform: "none",   backgroundColor: buttonBackground ,boxShadow:'none',
      filter: 'blur(0.5px)', fontFamily: "Poppins", fontStyle: "normal",cursor:'default',  lineHeight: "18px", fontWeight: "500", 
      fontSize: "12px", width: "80px", height:'25px', borderRadius: "24px",
    }}  >
     {buttonLabel}
    </Button> 
               </Box>
               
               <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100% ', gap: '7%',marginTop:'-20px' }}>
                 
                 
                 
                  <Box sx={{
                     width: 'calc((100% )/4)', height: '62px', border: '1px solid #D2D2D2', borderRadius: '12px', justifyContent: 'center',
                      display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2px',
                  }}>
                     <img src={UserA} alt="" style={{ width: '20px', height: '20px' }} />
                     <Typography sx={{ fontFamily: "poppins", fontSize: "10px", fontWeight: "400", lineHeight: '15px', color: "#212427", }}>
                        User id
                     </Typography>
                     <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "500", lineHeight: '18px', color: "#212427", }}>
                        6601
                     </Typography>
                  </Box>
                  <Box sx={{
                     width: 'calc((100% )/4)', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                     gap: '2px',
                  }}>
                     <img src={home} alt="" style={{ width: '29px', height: '25px' }}></img>
                     <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", }}>
                        $ 25,000
                     </Typography>
                  </Box>
                  <Box sx={{ width: 'calc((100% )/4)', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '2px', }}>
                     <img src={gds} alt="" style={{ width: '24px', height: '20px' }}></img>
                     <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", }}>
                        $ 25,000
                     </Typography>
                  </Box>
                  <Box sx={{ width: 'calc((100% )/4)', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '2px', }}>
                     <img src={hand} alt="" style={{ width: '20px', height: '14px' }}></img>
                     <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", }}>
                        $ 25,000
                     </Typography>
                  </Box>
               </Box>


               <Box sx={{
                  width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center',

               }}      >

                  <Button onClick={handleMoreDetails} style={{
                     fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", height: '24px',
                     textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px', width: 'calc(100% )'
                  }}>
                     More details<ArrowDropDownIcon style={{}} />
                  </Button>


               </Box>
               {/* <Box
        sx={{
          width: '100%',   display: 'flex', flexDirection: "row", justifyContent: 'center',  alignItems:'center',
         
        }}

      >
        
      

      
      </Box>  */}

               <Box sx={{
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'calc(100% )', gap: '5%'

               }}>
                  <Box sx={{
                     display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                     width: 'calc((100% )/3)'
                  }}>
                     <Button onClick={() => handleMenuItemClick("sent")} style={{
                        fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", width: '100%', height: '24px',
                        textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px'
                     }}>
                        Send
                     </Button>
                  </Box>
                  <Box sx={{
                     display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                     width: 'calc((100% )/3)'
                  }}>
                     <Button onClick={() => handleMenuItemClick("reject")} style={{
                        fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", width: '100%', height: '24px',
                        textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px'
                     }}>
                        Reject
                     </Button>
                  </Box>
                  <Box sx={{
                     display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                     width: 'calc((100% )/3)'
                  }}>
                     <Button onClick={() => handleMenuItemClick("negotiate")} style={{
                        fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", width: '100%', height: '24px',
                        textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px'
                     }}>
                        Negotiate
                     </Button>
                  </Box>
               </Box>
               <Box
                  sx={{
                     width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center',

                  }}

               >

                  <Typography style={{
                     fontFamily: "poppins", fontSize: "10px", fontWeight: "400", lineHeight: '15px', color: "#626262",
                     textTransform: 'none', justifyContent: 'center', alignContent: 'center'
                  }}>
                     Received on 12th Feb 23 : 12 PM
                  </Typography>


               </Box>

               <Box>
               </Box>
            </Box>


         </Box>)}
         {isMoreDetails && (<Box sx={{
            display: "flex", flexDirection: "column", backgroundColor: '#F8F8F8', height: '570px', marginBottom: '10px',
            alignContent: "center", width: 'calc(100% -30px)',
           gap: '18px'
         }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
           

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', 
            marginTop: '5px' }}>
            <Box sx={{  width: 'calc(100% -30px)', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', 
            gap: '10%',marginTop:'10px'

            }}      >
                <Box sx={{
                     width: '62px', height: '62px', border: '1px solid #D2D2D2', borderRadius: '12px', justifyContent: 'center', display: 'flex',
                     flexDirection: 'column', alignItems: 'center', gap: '2px', alignContent: 'center',marginLeft:'30%'
                  }}>
                     <img src={UserA} alt="" style={{ width: '20px', height: '20px' }} />
                     <Typography sx={{ fontFamily: "poppins", fontSize: "10px", fontWeight: "400", lineHeight: '15px', color: "#212427", }}>
                        User id
                     </Typography>
                     <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "500", lineHeight: '18px', color: "#212427", }}>
                        6601
                     </Typography>
                  </Box>
               <Box
                  sx={{
                     width: '30%', display: 'flex', flexDirection: "column", justifyContent: 'flex-end', alignItems: 'flex-end',gap:'2px',
                     alignContent:'right'
                  }}  >
                  {isDateRemaining && <Typography sx={{ fontFamily: "poppins", fontSize: "8px", fontWeight: "400", lineHeight: '12px',
                   color: "#CE3932", }}>
                       4 Days remaining..
                     </Typography> }  
                  <Button variant="contained"  style={{
      color: '#313131' , textTransform: "none",   backgroundColor: buttonBackground ,boxShadow:'none',
      filter: 'blur(0.5px)', fontFamily: "Poppins", fontStyle: "normal",cursor:'default',  lineHeight: "18px", fontWeight: "500", 
      fontSize: "12px", width: "80px", height:'25px', borderRadius: "24px",
    }}  >
     {buttonLabel}
    </Button> 
               </Box>
            </Box>
            </Box>





            
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
         marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                     House hold income
                  </Typography></Box>
               <Box
                  sx={{
                     width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                     25,000
                  </Typography>
               </Box>
            </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
         marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  credit lone payment
                  </Typography></Box>
               <Box
                  sx={{
                     width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  1500
                  </Typography>
               </Box>
            </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
         marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  car lone payment
                  </Typography></Box>
               <Box
                  sx={{
                     width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  25000
                  </Typography>
               </Box>
            </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
       marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  tds
                  </Typography></Box>
               <Box
                  sx={{
                     width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  0.75
                  </Typography>
               </Box>
            </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
         marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  gds
                  </Typography></Box>
               <Box
                  sx={{
                     width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  1.05
                  </Typography>
               </Box>
            </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
         marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  Amortization
                  </Typography></Box>
               <Box
                  sx={{
                     width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  25 years
                  </Typography>
               </Box>
            </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
         marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  Term
                  </Typography></Box>
               <Box
                  sx={{
                     width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  2 Months
                  </Typography>
               </Box>
            </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
          marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  Lone amount
                  </Typography></Box>
               <Box
                  sx={{
                     width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  25 k
                  </Typography>
               </Box>
            </Box>
            </Box>
           

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'calc(100% -30px)', gap: '10px', marginTop: '5px',
        marginLeft:'15%' }}>
            <Box sx={{   width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'left', gap: '30px'

            }}      >
               <Box  sx={{  width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  Total amount
                  </Typography></Box>
               <Box
                  sx={{
                     width: '50%', display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left',
                  }}  >
                  <Typography sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#626262", }}>
                  25k
                  </Typography>
               </Box>
            </Box>
            </Box>
           


           






            <Box sx={{
                width: 'calc(100% -30px)', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center',
                marginLeft:'20px',marginRight:'20px'
            }}      >

               <Button onClick={handleLessDetails} style={{
                  fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", height: '24px',
                  textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px', width: 'calc(100% )', 
                 
   
               }}>
                  Less details<ArrowDropUpIcon style={{}} />
               </Button>


            </Box>


            <Box sx={{
               display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'calc(100% -30px)', gap: '5%',
               marginLeft:'20px',marginRight:'20px'

            }}>
               <Box sx={{
                  display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                  width: 'calc((100% )/3)'
               }}>
                  <Button onClick={() => handleMenuItemClick("sent")} style={{
                     fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", width: '100%', height: '24px',
                     textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px'
                  }}>
                     Send
                  </Button>
               </Box>
               <Box sx={{
                  display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                  width: 'calc((100% )/3)'
               }}>
                  <Button onClick={() => handleMenuItemClick("reject")} style={{
                     fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", width: '100%', height: '24px',
                     textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px'
                  }}>
                     Reject
                  </Button>
               </Box>
               <Box sx={{
                  display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
                  width: 'calc((100% )/3)'
               }}>
                  <Button onClick={() => handleMenuItemClick("negotiate")} style={{
                     fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#212427", width: '100%', height: '24px',
                     textTransform: 'none', border: '1px solid #D2D2D2', borderRadius: '4px'
                  }}>
                     Negotiate
                  </Button>
               </Box>
            </Box>
            <Box
               sx={{
                  width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center',

               }}

            >

               <Typography style={{
                  fontFamily: "poppins", fontSize: "10px", fontWeight: "400", lineHeight: '15px', color: "#626262",
                  textTransform: 'none', justifyContent: 'center', alignContent: 'center'
               }}>
                  Received on 12th Feb 23 : 12 PM
               </Typography>


            </Box>

            <Box>
            </Box>


         </Box>)}
      </Box>

   );
}
export default UserDetailsMobile;