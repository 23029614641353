import { Box, InputLabel, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

function PaymentComponentUserMobile(props) {

    const { dataList,setDataList,mortgageName, isSelected, isCheckedCheckboxNext,result2,result} = props;
    const index=dataList.findIndex(x => x.name ===mortgageName);

const handleMouseEnter = (index) => {
    var tempdataList=[...dataList]
    tempdataList[index].isSelected=true  ;    
    setDataList(tempdataList);
  };

  const handleMouseLeave = (index) => {
    var tempdataList=[...dataList]
    tempdataList[index].isSelected=false  ;    
    setDataList(tempdataList); 
  };


    return (


        <Box sx={{
            display: 'flex', flexDirection: "column", border:dataList[index].isSelected ||isCheckedCheckboxNext? "1px solid #0C276C":"1px solid #CFC9C9", 
             width: "100%", height: "240px",            alignItems: "center", borderRadius: "8px", gap: '8%', justifyContent: 'center', 
            background: dataList[index].isSelected ||isCheckedCheckboxNext? "#F9F9F9" : "white" 
        }}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={() => handleMouseLeave(index)}
        >
            <Typography sx={{
                color: "#313131",   fontFamily: "poppins", fontSize: "14px",marginTop:'-10px',
                fontWeight: "400", lineHeight: "21px",textAlign:'center', padding: "0px 10px 0px 10px",
            }}>{mortgageName}</Typography>
            <TextField  variant="outlined" value={isNaN(dataList[index].SheduledResult) ? '' : String(dataList[index].SheduledResult)}  
    
    style={{
      width: "90%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    }}
      InputProps={{
        sx: { height: '28px', color: " #626262",":read-only":true, fontFamily: "poppins",
        fontSize: "12px", lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />
    <style>
      {`
        .Mui-focused .MuiOutlinedInput-notchedOutline {
          border: 1px solid #0C276C !important;
          box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
        }
      `}
    </style>

    
<TextField  variant="outlined"  value={isNaN(dataList[index].lowerResults.TotalPrinciplePaid) ? '' : String(dataList[index].lowerResults.TotalPrinciplePaid)}
    
    style={{
      width: "90%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    }}
      InputProps={{
        sx: { height: '28px', color: " #626262",":read-only":true,fontFamily: "poppins",
        fontSize: "12px", lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />

<TextField  variant="outlined" value={isNaN(dataList[index].lowerResults.TotalInterestPaid) ? '' : String(dataList[index].lowerResults.TotalInterestPaid)}  
    
    style={{
      width: "90%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    }}
      InputProps={{
        sx: { height: '28px', color: " #626262",":read-only":true,fontFamily: "poppins",
        fontSize: "12px", lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />
      <TextField  variant="outlined" value={isNaN(dataList[index].lowerResults.TotalAmountPaid) ? '' : String(dataList[index].lowerResults.TotalAmountPaid)}    
    
    style={{
      width: "90%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    }}
      InputProps={{
        sx: { height: '28px', color: " #626262",":read-only":true,fontFamily: "poppins",
        fontSize: "12px", lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />
        </Box>


    );
}
export default PaymentComponentUserMobile;