import React from 'react';
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { Button, Typography, Grid } from "@mui/material";
import Crop169Icon from "@mui/icons-material/Crop169";
import notification from "./images/Notification (4).png";
import Messages from "./images/Messages.png";
import Discussion from "./images/Discussion.png";
import Profile from "./images/Rectangle.png";
import { InputLabel } from '@mui/material';

import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import sendicon from './images/Vector (5).png';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import UserDtls from './ListViewDetails';
import AcceptDtls from './AcceptedMenu';
import RejectDtls from './RejectedMenu';
import NegotiateDtls from './NegotiatedMenu';
import GetAppIcon from '@mui/icons-material/GetApp';
import UserNotificatinMenu from './UserNotificationMenu';
import HeaderUserScreenMobile from './HeaderUserScreenMobile';
function ListViewOfRecievedQtnMobile() {
  const menus = [
    "Calculator",
    "Quotations",
    "Products",
    "About us",
    "Contact us"
  ];
  const appbarmenus = [
    "All Quotations",
    "Accepted",
    "Rejected",
    "Negotiated",
    
  ];
  const [numberOfAddClick, setNumberOfAddClick] = useState(0);
  const handleMenuClick = (menu) => {
    // Perform actions when a menu item is clicked, e.g., navigate to a specific route
    console.log(`Clicked on ${menu}`);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [allQuotations, setAllQuotations] = useState(true);
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [negotiated, setNegotiated] = useState(false);
  


  const handleMenuToggleAllQuotations = () => {
    setAllQuotations(true);
    setAccepted(false);
  };
  const handleMenuToggleAccepted = () => {
    setAccepted(true);
    setAllQuotations(false);
    setRejected(false);
    setNegotiated(false);
  };
  const handleMenuToggleRejected = () => {
    setRejected(true);
    setAllQuotations(false);
    setAccepted(false);
    setNegotiated(false);

  };
  const handleMenuToggleNegotiated = () => {
    setNegotiated(true);
    setAllQuotations(false);
    setAccepted(false);
    setRejected(false);
  };

  const handleMenuToggleDropDown = () => {
    setIsOpen(!isOpen);
    ;
  };

  const handleMenuItemClick = (value) => {

    setIsOpen(false);
  }
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggleMenu = () => {
    setShowMenu(!showMenu);
  };
  
 

  const [isHoveredSendQuote, setIsHoveredSendQuote] = useState(false);

  const handleMouseEnterSendQuote = () => {
    setIsHoveredSendQuote(true);
  };

  const handleMouseLeaveSendQuote = () => {
    setIsHoveredSendQuote(false);
  };


  const [isHoveredSendToMail, setIsHoveredSendToMail] = useState(false);

  const handleMouseEnterSendToMail = () => {
    setIsHoveredSendToMail(true);
  };

  const handleMouseLeaveSendToMail = () => {
    setIsHoveredSendToMail(false);
  };
  const [isHoveredDownloadResult, setIsHoveredDownloadResult] = useState(false);

  const handleMouseEnterDownloadResult = () => {
    setIsHoveredDownloadResult(true);
  };

  const handleMouseLeaveDownloadResult = () => {
    setIsHoveredDownloadResult(false);
  };
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);

  const handleMenuToggleSortBy = () => {
    setIsOpenSortBy(!isOpenSortBy);
  };

  const handleMenuItemClickSortBy = (value) => {
  
    setIsOpenSortBy(false);
  }
const AgentCode=6611;
const [showNotificationMenu, setShowNotificationMenu] = useState(false);
const handleClickNotificationImage = () => {
        
  setShowNotificationMenu(!showNotificationMenu);
};


  return (

    <Grid container sx={{ width: '100%', height: '100%'}}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'calc(100% - 24px)' }}>
            <HeaderUserScreenMobile></HeaderUserScreenMobile>
        {/* <AppBar position="fixed" sx={{ bgcolor: "#1F6373", height: "56" }}>


          <Toolbar disableGutters>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center', alignItems: 'center', paddingLeft: '18px' }}>
              <Crop169Icon
                sx={{

                  height: '28px',
                  width: '44px',
                  backgroundColor: "#fff"
                }}
              />
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuToggleMenu}

              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: showMenu ? "block" : "none", position: "absolute", top: "100%", width: 'calc(100% - 24px)', zIndex: 1,
                bgcolor: "#1F6373", justifyContent: 'flex-start', borderRadius: ' 0px 0px 8px 8px',paddingBottom:'20px'
              }}
            >
             {menus.map((menu) => (
                <Button onClick={handleMenuToggleMenu}
                  key={menu}
                  sx={{
                    display: "block", height: '35px', color: "white", textTransform: "capitalize", fontFamily: "poppins",
                    fontSize: "14px", lineHeight: "21px", fontWeight: "400", textAlign: 'left', borderRadius: '0px',
                    '&:hover': { background: "#79A3A8", width: '100%' }
                  }}
                >
                  {menu}
                </Button>
              ))} 
 


            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '12px', marginLeft: 'auto', 
            }}>
              <img src={Discussion} alt="" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
              <img src={Messages} alt="" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
            
              <img src={notification} alt="" onClick={handleClickNotificationImage}  style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
              {showNotificationMenu && <UserNotificatinMenu />}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center', gap: '8px', marginLeft: '7%', 
            marginRight: '3%' }}>
              <img src={Profile} alt="" style={{width:'24px',height:'24px'}} />
              <InputLabel style={{
                fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#fff",
              }}>John Doe</InputLabel>
              <Box className="dropdown">
                <Box className="dropdown__toggle" onClick={handleMenuToggleDropDown}>
                  <ExpandMoreIcon style={{ marginTop: '5px',marginLeft:'-5px' }} />
                </Box>
                {isOpen&& (
                  <Box className="dropdown__menu" sx={{
                    position: 'absolute', right: '5%', fontFamily: 'poppins', fontSize: '12px', fontWeight: '400',
                    lineHeight: '20px', width: '134px', background: '#F8F8F8', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                     zIndex: 1, color: '#2F2F2F', padding: '4px'
                  }}>
                    <style>
                      {`
          .dropdown__menu .MuiMenuItem-root:hover {
            background-color: #1F6373;
            color: white;
          }
        `}
                    </style>
                    <MenuItem onClick={() => handleMenuItemClick("myprofile")} value="option1" style={{
                      fontFamily: 'poppins', fontSize: '12px',
                      fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
                    }}>
                      <PersonIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                      My profile
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick("signout")} value="option2" style={{
                      fontFamily: 'poppins', fontSize: '12px',
                      fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
                    }}>
                      <LogoutIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                      Sign out
                    </MenuItem>
                  </Box>
                )}
              </Box>



            </Box>


          </Toolbar>


        </AppBar> */}
    
      </Box>
       <Box
        sx={{
          width:'calc(100% - 24px)',   display: 'flex', flexDirection: "column", justifyContent: 'center',  alignItems:'left',margin:'14px ',
          marginTop:'18%'
        }}     >


<Typography sx={{ fontFamily: "poppins", fontSize: "14px", fontWeight: "500", lineHeight: '21px', color: "#313131",}}>
Selected quotations 
</Typography>
  
<AppBar position="relative" sx={{ bgcolor: "#1F6373", height: "44px", borderRadius:'8px 8px 0px 0px',marginTop:'10px',paddingLeft:'12px',
justifyContent:'center',width:'100%'
 }}>
   <Toolbar disableGutters>
           
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
              {/* {appbarmenus.map((menu) => (
                <Button
                  key={menu}                 
                  onClick={() => handleMenuClick(menu)}
                  sx={{
                   color: "white", display: "block", paddingRight: "4%", textTransform: "none", fontFamily: "poppins",
                    fontSize: "10px", lineHeight: "18px", fontWeight: "600", '&:hover': { textDecoration: 'underline', textUnderlineOffset:'5px'  }
                  }}
                >
                  {menu}
                </Button>
              ))} */}
              <Button onClick={handleMenuToggleAllQuotations}
                 
                 sx={{
                  color: "white", display: "block", paddingRight: "4%", textTransform: "none", fontFamily: "poppins",
                   fontSize: "10px", lineHeight: "18px", fontWeight: "600", '&:hover': { textDecoration: 'underline', textUnderlineOffset:'5px'  },
                   textDecoration:(allQuotations===false)?'none':'underline',textUnderlineOffset:(allQuotations===false)?'none':'5px'
                 }}
               >
               All Quotations 
               </Button>
               <Button onClick={handleMenuToggleAccepted}
                
                sx={{
                  color: "white", display: "block", paddingRight: "4%", textTransform: "none", fontFamily: "poppins",
                   fontSize: "10px", lineHeight: "18px", fontWeight: "600", '&:hover': { textDecoration: 'underline', textUnderlineOffset:'5px'  }
                 }}
               >
             Accepted
               </Button>
               <Button onClick={handleMenuToggleRejected}
                
                sx={{
                  color: "white", display: "block", paddingRight: "4%", textTransform: "none", fontFamily: "poppins",
                   fontSize: "10px", lineHeight: "18px", fontWeight: "600", '&:hover': { textDecoration: 'underline', textUnderlineOffset:'5px'  }
                 }}
               >
             Rejected
               </Button>
               <Button onClick={handleMenuToggleNegotiated}
                
                sx={{
                  color: "white", display: "block", paddingRight: "4%", textTransform: "none", fontFamily: "poppins",
                   fontSize: "10px", lineHeight: "18px", fontWeight: "600", '&:hover': { textDecoration: 'underline', textUnderlineOffset:'5px'  }
                 }}
               >
             Negotiated
               </Button>

             
            </Box>
          </Toolbar>     

        </AppBar>
      </Box> 
       
   <Box className="dropdownSortBy"sx={{ width: 'calc(100% )'}}  >
          <Box className="dropdown__toggleSortBy" onClick={handleMenuToggleSortBy} >
          <Box sx={{display:'flex',flexDirection:'row',alignItems:'right',justifyContent:'right',paddingRight:'5%'
        }}>
          <Typography sx={{  fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'18px',color:"#1F6373"
    }}>Sort by</Typography> 
          <ExpandMoreIcon style={{ color:"#1F6373",fontSize: "18px",}} ></ExpandMoreIcon></Box>
          </Box>
          {isOpenSortBy && (
            <Box className="dropdown__menuSortBy"  sx={{ position: 'absolute', right: '6% ',fontFamily: "poppins",
             fontSize: "12px",  fontWeight: "400",lineHeight:'20px',width:'134px',height:'65px',background:'#F8F8F8',borderRadius:'8px',
            boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',zIndex:1, minHeight: '6px',padding:'4px',color:'#2F2F2F'}}>
               <style>
                {`
                  .dropdown__menuSortBy .MuiMenuItem-root:hover {
                    background-color: #1F6373;
                    color:white;
                  }
                  
                `}
              </style>
              <MenuItem  onClick={() => handleMenuItemClickSortBy("By date")} value="option1" style={{minHeight:'6px',borderRadius:'4px',
              fontFamily: "poppins", fontSize: "12px",fontWeight: "400",lineHeight:'20px'}}> By date</MenuItem>
              <MenuItem onClick={() => handleMenuItemClickSortBy("By time")} value="option2" style={{minHeight:'6px',borderRadius:'4px',
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'20px'}}>By time</MenuItem>
             
            </Box>
          )}
        </Box>
      




      <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', marginLeft: '15px', marginRight: '15px',
     width: 'calc(100% - 30px)',marginBottom:'70px',marginTop:'10px' }}>


 { allQuotations && (<Box>
      {[...Array(10)].map((_, index) => (
        <React.Fragment key={index} ><UserDtls numberOfAddClick={numberOfAddClick}  setNumberOfAddClick={setNumberOfAddClick}></UserDtls>  
        </React.Fragment>
      ))}
    </Box>)}
    { accepted && (<Box>
      {[...Array(10)].map((_, index) => (
        <React.Fragment key={index} ><AcceptDtls numberOfAddClick={numberOfAddClick}  setNumberOfAddClick={setNumberOfAddClick} ></AcceptDtls>  
        </React.Fragment>
      ))}
    </Box>)}
    { rejected && (<Box>
      {[...Array(10)].map((_, index) => (
        <React.Fragment key={index} ><RejectDtls numberOfAddClick={numberOfAddClick}  setNumberOfAddClick={setNumberOfAddClick}></RejectDtls>  
        </React.Fragment>
      ))}
    </Box>)}
    { negotiated && (<Box>
      {[...Array(10)].map((_, index) => (
        <React.Fragment key={index} ><NegotiateDtls numberOfAddClick={numberOfAddClick}  setNumberOfAddClick={setNumberOfAddClick} ></NegotiateDtls>  
        </React.Fragment>
      ))}
    </Box>)}
    </Box> 

      
    </Grid>

  );
}
export default ListViewOfRecievedQtnMobile;