import React, { useState } from 'react'
import Header1 from './header1-modified'
//import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, IconButton, InputAdornment, OutlinedInput, Stack, Tab, Tabs, Typography } from '@mui/material'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Mortgage from './images/mortgage.png';
import PersonIcon from '@mui/icons-material/Person';
import Edit from './images/edit.png';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpIcon from '@mui/icons-material/Help';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Header1Mod from './header1-modified';
import MortgageMob from './mortgagemob';
function MyprofileMob() {
  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);
  const [i, setI] = useState([1, 2, 3, 4]);
  const [j, setJ] = useState(5);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const newHandleChange = (event, newValue) => {
    setValue1(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ paddingTop: '0px' }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <div style={{ overflow: 'hidden' }}>
      <Header1Mod />
      <Box sx={{ height: {lg:'848px',xs:'908px'}, overflowY: 'auto', width: {lg:'1366px',sm:'100%',xs:'100%'}, paddingLeft: {lg:'52px',sm:'30px',xs:'12px'}, paddingRight: {lg:'52px',sm:'30px',xs:'12px'}, paddingTop: {lg:'10px',md:'10px',sm:'10px',xs:'4px'}, paddingBottom: '52px' }}>
        <Typography sx={{ fontWeight: 500, fontSize: {lg:'20px',md:'20px',sm:'20px',xs:'14px'}, color: '#494949', fontFamily: 'Poppins' }}>User Profile</Typography>
        <Box sx={{ width: {lg:'1262px',sm:'100%',xs:'100%'}, height: {lg:'744px',sm:'784px',xs:'908px'}, backgroundColor: '#F8F8F8', borderRadius: '8px', marginTop: {lg:'14px',xs:'4px'}, paddingLeft: {lg:'30px',md:'30px',sm:'30px',xs:'8px'},paddingBottom:{xs:'15px'}, paddingTop: {lg:'36px',xs:'8px'},paddingRight:{lg:'30px',md:'30px',sm:'30px',xs:'12px'},alignItems:'center',justifyContent:'space-between' }}>
        <Box sx={{ paddingRight:{lg:'182px',md:'182px',sm:'0px'}, display: {lg:'flex',md:'flex',sm:'flex',xs:'none'}, flexDirection: 'row',alignItems:'center',justifyContent:'space-between' }}>
          <Tabs
            value={value}
            onChange={handleChange}
           TabIndicatorProps={{ style: { background: 'black', fontFamily: 'Poppins' } }}
            sx={{
              minHeight: 0, padding: '0px', " .MuiTabs-flexContainer": {
                minHeight: 0, height: '44px', alignItems: 'center',gap:'24px',
                "& .Mui-selected": { color: '#313131', fontWeight: 500, borderColor: 'black', fontFamily: 'Poppins' }, "& .MuiTabs-indicator": { backgroundColor: 'black',width:'50%' },
               },
            }}
          >
            <Tab icon={<AccountCircleOutlinedIcon sx={{ color: 'inherit', width: '22px', height: '22px' }} />} iconPosition="start" label={<Stack direction="row" sx={{ gap: '10px', display: 'flex', alignItems: 'center' }}>My profile</Stack>} sx={{ textTransform: 'none', fontSize: '16px', fontWeight: 400, color: '#313131', fontFamily: 'Poppins',paddingLeft:0,paddingRight:0 }} />
            <Tab icon={<img src={Mortgage} width='22px' height='18px' />} iconPosition="start" label={<Stack direction="row" sx={{ gap: '10px', display: 'flex', alignItems: 'center' }}>Mortgage preferences </Stack>} sx={{ textTransform: 'none', fontSize: '16px', fontWeight: 400, color: '#313131', fontFamily: 'Poppins',paddingLeft:0,paddingRight:0 }} />
            </Tabs>
         <Button startIcon={<img src={Edit} />} sx={{
              width: '76px', height: '26px', border: '1px solid #1F6373', textTransform: 'none', color: '#1F6373', backgroundColor: 'white', borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#D2E0E3',
                color: '#1F6373',
                border: '1px solid #1F6373',
              },
              '&:active': {
                backgroundColor: '#1F6373',
                color: 'white',
              },
            }}>Edit</Button>
          </Box>
          <Box sx={{ display: {lg:'none',md:'none',sm:'none',xs:'flex'}, flexDirection: 'row',alignItems:'center'}}>
          <Tabs
            value={value}
            onChange={handleChange}
           TabIndicatorProps={{ style: { background: 'black', fontFamily: 'Poppins' } }}
            sx={{
              minHeight: 0, padding: '0px', " .MuiTabs-flexContainer": {
                minHeight: 0, height: '44px', alignItems: 'center',gap:'24px',
                "& .Mui-selected": { color: '#313131', fontWeight: 500, borderColor: 'black', fontFamily: 'Poppins' }, "& .MuiTabs-indicator": { backgroundColor: 'black',width:'50%' },
               },
            }}
          >
            <Tab icon={<AccountCircleOutlinedIcon sx={{ color: 'inherit', width: '22px', height: '22px' }} />} iconPosition="start" label={<Stack direction="row" sx={{ gap: '10px', display: 'flex', alignItems: 'center' }}>My profile</Stack>} sx={{ textTransform: 'none', fontSize: '12px', fontWeight: 400, color: '#313131', fontFamily: 'Poppins',paddingLeft:0,paddingRight:0 }} />
            <Tab icon={<img src={Mortgage} width='22px' height='18px' />} iconPosition="start" label={<Stack direction="row" sx={{ gap: '10px', display: 'flex', alignItems: 'center' }}>Mortgage preferences </Stack>} sx={{width:'40%', textTransform: 'none',textAlign:'left', fontSize: '12px', fontWeight: 400, color: '#313131', fontFamily: 'Poppins',paddingLeft:0,paddingRight:0 }} />
            </Tabs>
            </Box>
            <Box sx={{ display: {lg:'none',md:'none',sm:'none',xs:'flex'}, flexDirection: 'row-reverse',alignItems:'center',marginTop:'8px'}}>
             <Button startIcon={<img src={Edit} />} sx={{
              width: '76px', height: '26px', border: '1px solid #1F6373', textTransform: 'none', color: '#1F6373', backgroundColor: 'white', borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#D2E0E3',
                color: '#1F6373',
                border: '1px solid #1F6373',
              },
              '&:active': {
                backgroundColor: '#1F6373',
                color: 'white',
              },
            }}>Edit</Button>
            </Box>
          <TabPanel value={value} index={0} sx={{
            "& .MuiTabPanel-root": { paddingLeft: '0px' },
            "& .MuiBox-root": { padding: '0px' },
          }}>
            <Box sx={{ width: {lg:'764px',sm:'100%',xs:'100%'}, height: {lg:'542px',xs:'100%'}, marginLeft: {lg:'220px',xs:0}, display: {lg:'flex',xs:'flex'}, flexDirection:{lg:'row',sm:'row',xs:'column'}, marginTop:{lg:'34px',sm:'34px',xs:'8px'} }}>
              <Box sx={{ width: {lg:'410px',xs:'100%'}, paddingRight: {lg:'28px',sm:'28px',xs:0}, borderRight:{lg:'1px solid #CFC9C9',sm:'1px solid #CFC9C9',xs:'none'} }}>
                <Box sx={{ width:{lg:'382px',xs:'100%'}, backgroundColor: 'white', height: {lg:'542px',xs:'100%'}, borderRadius: '8px', paddingTop: '38px', paddingLeft: '16px', paddingRight:{lg:'20px',xs:'0px'}, paddingBottom: '22px', alignContent: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    <Box sx={{ height: '40px', width: '40px', borderRadius: '40px', border: '1px solid #1F6373', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                      <PersonIcon sx={{ color: '#1F6373', height: '40px', width: '40px' }} />
                    </Box>
                    <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#212427', fontFamily: 'Poppins', marginTop: '8px' }}>Robert</Typography>
                    <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#212427', fontFamily: 'Poppins', marginTop: '10px' }}>User ID : 1167593</Typography>
                  </div>
                  <Typography sx={{ textAlign: 'left', fontSize: '14px', marginTop: '48px', color: '#939393', paddingBottom: '4px', fontFamily: 'Poppins', fontWeight: 400 }}>Email id</Typography>
                  <Typography sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '14px', marginTop: '8px', color: '#212427', fontWeight: 400, paddingBottom: '4px', fontFamily: 'Poppins' }}>johndoe@gmail.com</Typography>
                  <Typography sx={{ textAlign: 'left', fontSize: '14px', marginTop: '24px', color: '#939393', paddingBottom: '4px', fontFamily: 'Poppins' }}>Mobile number</Typography>
                  <Typography sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '14px', marginTop: '8px', color: '#212427', fontWeight: 400, paddingBottom: '4px', fontFamily: 'Poppins' }}>9493952345</Typography>
                  <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#939393', paddingBottom: '4px', paddingTop: '24px', fontFamily: 'Poppins' }}>Password</Typography>
                  <Typography sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '14px', marginTop: '8px', color: '#212427', fontWeight: 400, paddingBottom: '4px', fontFamily: 'Poppins' }}>******</Typography>
                 <Typography sx={{ fontSize: '12px', marginTop: '4px', color: '#626262', fontFamily: 'Poppins' }}><span><a href='#' style={{ color: '#626262', fontSize: '12px' }}>Change password?</a></span></Typography>
                </Box>
             </Box>
              <Box sx={{ width:  {lg:'410px',xs:'100%'}, paddingLeft: {lg:'28px',sm:'28px',xs:0} }}>
                <Box sx={{ width: {lg:'382px',xs:'100%'}, backgroundColor: 'white', height:  {lg:'542px',xs:'100%'},marginTop:{lg:0,md:0,sm:0,xs:'14px'}, borderRadius: '8px', paddingLeft: '18px', paddingRight: '20px', paddingTop: {lg:'82px',xs:'10px'}, paddingBottom: {lg:'82px',xs:'10px'} }}>
                  <Typography sx={{ textAlign: 'left', fontSize: '14px', marginTop: '0px', color: '#939393', paddingBottom: '4px', fontFamily: 'Poppins' }}>Annual house hold Income</Typography>
                 <Typography sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '14px', marginTop: '8px', color: '#212427', fontWeight: 400, paddingBottom: '4px', fontFamily: 'Poppins' }}>0</Typography>
                  <Typography sx={{ textAlign: 'left', fontSize: '14px', marginTop: '24px', color: '#939393', paddingBottom: '4px', fontFamily: 'Poppins' }}>Line of credit payment</Typography>
                  <Typography sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '14px', marginTop: '8px', color: '#212427', fontWeight: 400, paddingBottom: '4px', fontFamily: 'Poppins' }}>0</Typography>
                  <Typography sx={{ textAlign: 'left', fontSize: '14px', marginTop: '24px', color: '#939393', paddingBottom: '4px', fontFamily: 'Poppins' }}>Car loan payment</Typography>
                  <Typography sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '14px', marginTop: '8px', color: '#212427', fontWeight: 400, paddingBottom: '4px', fontFamily: 'Poppins' }}>0</Typography>
                  <Typography sx={{ textAlign: 'left', fontSize: '14px', marginTop: '24px', color: '#939393', paddingBottom: '4px', fontFamily: 'Poppins' }}>Other loans</Typography>
                 
                 <Typography sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '14px', marginTop: '8px', color: '#212427', fontWeight: 400, paddingBottom: '4px', fontFamily: 'Poppins' }}>0</Typography>                  
                  <Typography sx={{ textAlign: 'left', fontSize: '14px', marginTop: '24px', color: '#939393', paddingBottom: '4px', fontFamily: 'Poppins' }}>Other mortgages</Typography>
                  <Typography sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '14px', marginTop: '8px', color: '#212427', fontWeight: 400, paddingBottom: '4px', fontFamily: 'Poppins' }}>0</Typography>
                </Box>
                </Box>
              </Box>
           </TabPanel>
          <TabPanel value={value} index={1}
            x={{
              "& .MuiTabPanel-root": { paddingLeft: '0px', paddingTop: '0px' },
              "& .MuiBox-root": { padding: '0px' },
            }}>
              <Box sx={{display: {lg:'none',md:'none',sm:'none',xs:'flex'}}}>
                <MortgageMob />
              </Box>
            <Box sx={{width:'100%',display: {lg:'block',md:'block',sm:'block',xs:'none'}, paddingLeft: {lg:'158px',sm:'0px'},paddingRight:{lg:'188px',sm:'0px'} }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'none', xs: 'block' }, fontSize: '14px', fontFamily: 'Poppins', color: '#313131', fontWeight: 600, marginTop: '15px' }}>Input parameters</Typography>
                <div className='oldpart'>
                    <div style={{width: { lg: '158px', md: '158px', sm: '120px', xs: '104px' }, height: '324px', display: 'flex', flexDirection: 'column',
                              paddingTop: '18px', paddingLeft: '12px', paddingRight: '12px', paddingBottom: '18px',
                               gap: '16px', marginTop: '12px', 
                              position: 'relative'}}>
                      <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'block', xs: 'none' }, fontSize: {lg:'16px',sm:'12px'}, fontFamily: 'Poppins', color: '#313131', fontWeight: 600 }}>Input parameters</Typography>
                      <Box sx={{width:{lg:'178px',sm:'120px'},height:'248px',display:'flex',flexDirection:'column',gap:'16px'}}>
                      <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Initial payment</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                      <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Type</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                      <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Term</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                      <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Interest rate (%) </Typography>
                        <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Interest (%) </Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                      <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Amortization period</Typography>
                        <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Amortization</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                      <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                        <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment frequency</Typography>
                        <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                      </Box>
                      <Box sx={{ width: { lg: '168px', md: 168, sm: 110, xs: 110 }, height: '28px', alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row', marginTop: '12px',marginBottom:'10px' }}>
                        <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment schedule</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                    </div>
                    <div style={{ width: '692px', display: 'flex', gap: '20px' }}>
                      {i.map((item, index) => {
                          return (
                           <Box key={index} sx={{
                              width: { lg: '158px', md: '158px', sm: '120px', xs: '104px' }, height: '324px', display: 'flex', flexDirection: 'column',
                              paddingTop: '15px', paddingLeft: '12px', paddingRight: '12px', paddingBottom: '20px', backgroundColor: 'white',
                              border: '1px solid #CFC9C9', gap: '16px', marginTop: '12px', borderRadius: '8px',
                              position: 'relative',
                            }}>
                             <Typography sx={{ textAlign: 'center', fontSize: '14px', fontWeight: 400, color: '#626262',fontFamily: 'Poppins' }}>Mortgage {item}</Typography>
                             <Box sx={{width:'178px',display:'flex',flexDirection:'column',gap:'16px'}}>
                             <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>0</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Variable</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>2 Months</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>0</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>0</Typography>
                              </Box> 
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Monthly</Typography>
                              </Box>
                              <Typography sx={{ fontSize: { lg: '14px', md: '14px', sm: '14px', xs: '12px' }, fontWeight: { lg: 500, xs: 500 }, color: '#313131', fontFamily: 'Poppins', marginTop: '12px',marginBottom:'4px', marginLeft:{lg:'30px',sm:'16px'}}}>CAD({0})</Typography>
                              </Box>
                            </Box>
                           );
                        })}
                      </div>
                    </div>
               </div>
                  <Tabs value={value1} onChange={newHandleChange} aria-label="basic tabs example"
                        TabIndicatorProps={{ style: { background: '#313131' } }}
                        sx={{
                          " .MuiTabs-flexContainer": {
                            minHeight: '24px', alignItems: 'center',marginTop:'8px',paddingLeft:'10px',gap:'18px',
                            "& .Mui-selected": { color: '#313131', borderColor: '#313131', fontWeight: 500 }, "& .MuiTabs-indicator": { backgroundColor: '1px solid #313131' },
                          },
                          ".MuiTab-root": { paddingLeft: '0px' },
                        }}
                      >
                        <Tab label="Calculate payment" sx={{ textTransform: 'none',paddingBottom:0, fontFamily: 'Poppins',color:'#313131',fontWeight: 400 ,marginTop:'16px', fontSize: { lg: 16, xs: 12 } ,paddingLeft:0,paddingRight:0}} {...a11yProps(0)} />
                        <Tab label="Calculate penalty on cancellation" sx={{ textTransform: 'none',paddingBottom:0,color:'#313131', fontWeight: 400, fontFamily: 'Poppins',marginTop:'16px', fontSize: { lg: 16, xs: 12 } ,paddingLeft:0,paddingRight:0}} {...a11yProps(1)} />
                      </Tabs>
                 <TabPanel value={value1} index={0} sx={{
                    "& .MuiTabPanel-root": { padding: '0px' },
                    "& .MuiBox-root": { padding: '0px' },
                  }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div 
                      style={{ display: 'flex', flexDirection: 'column',paddingLeft:'12px', gap: '16px', marginTop: '64px', marginRight: '20px' }}
                      >
                        <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment schedule</Typography>
                          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                        </Box>
                        <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Remaining principle</Typography>
                          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                        </Box>
                        <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Interest paid</Typography>
                          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                        </Box>
                        <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Principle paid</Typography>
                          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                        </Box>
                      </div>
                        <div className='subpart'>
                            {i.map((item, index) => {
                              return (
                                <Box sx={{
                                  width: {lg:'158px',md:'158px',sm:'120px',xs:'104px'}, height: '218px', display: 'flex', flexDirection: 'column',
                                  paddingTop: '20px', paddingLeft: '12px', paddingRight: '12px', paddingBottom: '20px', backgroundColor: 'white',
                                  border: '1px solid #CFC9C9', gap: '12px', borderRadius: '8px',marginTop:'12px'
                              }}>
                                <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',alignContent:'center',justifyContent:'center'}}>
                                  <Typography sx={{ textAlign: 'center', fontSize: '14px', fontWeight: 400, color: '#626262' }}>Mortgage {item}</Typography>
                                </Box>
                                <Box sx={{width:'178px',display:'flex',flexDirection:'column',gap:'16px'}}>
                                <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                  <Typography sx={{fontSize: '12px', color: '#313131',textAlign: 'left', lineHeight: '28px', fontFamily: 'Poppins'}}>0</Typography>
                                  </Box>
                                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                  <Typography sx={{fontSize: '12px', color: '#313131',textAlign: 'left', lineHeight: '28px', fontFamily: 'Poppins'}}>0</Typography>
                                  </Box>
                                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                  <Typography sx={{fontSize: '12px', color: '#313131',textAlign: 'left', lineHeight: '28px', fontFamily: 'Poppins'}}>0</Typography>
                                  </Box>
                                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                  <Typography sx={{fontSize: '12px', color: '#313131', lineHeight: '28px',textAlign: 'left', fontFamily: 'Poppins'}}>0</Typography>
                                  </Box>
                                  </Box>
                                  </Box>
                              );
                            })}
                          </div>
                      </div>
                  </TabPanel>
                  <TabPanel value={value1} index={1}
                    x={{
                      "& .MuiTabPanel-root": { paddingLeft: '0px' },
                      "& .MuiBox-root": { padding: '0px' },
                    }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{  display: 'flex', flexDirection: 'column', marginTop: '64px', gap: '16px', marginRight: '16px',paddingLeft:'12px' }}>
                        <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Cancellation</Typography>
                          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                        </Box>
                        <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Penalty</Typography>
                          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                        </Box>
                        <Box sx={{ width: { lg: '168px', md: 162, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Calculated penalty</Typography>
                          <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                        </Box>
                      </div>
                           <div style={{display:'flex',flexDirection:'row',gap:'20px',marginLeft:'6px'}}>
                            {i.map((item, index) => {
                              return (
                                <Box sx={{
                                    width: {lg:'158px',md:'158px',sm:'120px',xs:'104px'}, display: 'flex', flexDirection: 'column',
                                    paddingTop: '20px', paddingLeft: '12px', paddingRight: '12px', paddingBottom: '20px', backgroundColor: 'white',
                                    border: '1px solid #CFC9C9', gap: '8px', borderRadius: '8px',marginTop:'12px'
                                }}>
                               <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',alignContent:'center',justifyContent:'center'}}>
                                  <Typography sx={{ textAlign: 'center', fontSize: '14px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Mortgage {item}</Typography>
                                  </Box>
                                  <Box sx={{width:'178px',display:'flex',flexDirection:'column',gap:'16px'}}>
                                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                  <Typography sx={{fontSize: '12px', color: '#313131',textAlign: 'left', fontFamily: 'Poppins'}}>0</Typography>
                                  </Box>
                                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                  <Typography sx={{fontSize: '12px', color: '#313131',textAlign: 'left',  fontFamily: 'Poppins'}}>0</Typography>
                                  </Box>
                                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                  <Typography sx={{fontSize: '12px', color: '#313131',textAlign: 'left',   fontFamily: 'Poppins'}}>0</Typography>
                                  </Box>
                                  </Box>
                                </Box>
                              );
                            })}
                          </div>
                        </div>
                     </TabPanel>
                </Box>
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
            <Typography sx={{ paddingLeft: '50px', fontSize: '16px', color: '#000000', fontWeight: 500, fontFamily: 'Poppins' }}>Privacy policy</Typography>
          </TabPanel> */}
        </Box>
      </Box>
    </div>
  )
}
export default MyprofileMob;