import * as React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tick from './images/tick.png';
import { Button, IconButton } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SecondaryButton from './Secondarybutton';
export default function ResetlinkMob() {
const [openModal, setOpenmodal]=React.useState(true);
    const handleOpenmodal = () => setOpenmodal(true);
    const handleClosemodal = () => setOpenmodal(false);
    const [showModal,setShowModal] = React.useState(false);
  return (
    <Box>
    {openModal && (
  
  <Box
sx={{
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '800px',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '1'
}}>


    {/* // <div style={{overflowY:'auto'}} >
    //     <Modal
    //     open = {openModal} */}
    {/* //     aria-labelledby="modal-modal-title"
    //     aria-describedby="modal-modal-description"
    //   > */}
      <Box
          sx={{
            position: 'absolute',
            width: {lg:'516px',md:'400px',sm:'394px',xs:'90%'},
            height:'286px',
        //     left:'489px',
        //    top:'100px',
            bgcolor: '#FFFF',
            paddingBottom:'36px',
            borderRadius:'8px',
            BoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            alignItems:'center',
           }}>
            <div style={{ display: 'flex', flexDirection:'row-reverse',paddingTop:'12px',paddingRight:'12px' }}>
            <IconButton sx={{ padding: '0px' }} onClick={handleClosemodal} >
              < CancelOutlinedIcon sx={{ color: '#606060', width: '24px', height: '24px' }} />
            </IconButton>
            </div>
            <Box sx={{alignItems:'center',justifyContent:'center',display:'flex',flexDirection:'column',paddingTop:'4px',paddingLeft:{lg:'86px',xs:'24px'},paddingRight:{lg:'86px',xs:'24px'}}}>
            <div style={{alignItems:'center',justifyContent:'center',display:'flex',paddingLeft:{lg:'86px',xs:'24px'},paddingRight:{lg:'86px',xs:'24px'}}}>
          <Box sx={{height:44, width:44,alignItems:'center',justifyContent:'center', display:'flex'}}>
              <img src={Tick}  />
           </Box> 
           </div>
          <Typography sx={{textAlign:'center',fontSize:{lg:'14px',md:'14px',sm:'14px',xs:'12px'},fontWeight:'500px',color:'#1A1A1A',fontFamily:'Poppins',marginTop:'34px'}}>
            <span>Account reset link has been sent to your registered email. please click on the link to reset the password</span></Typography>
            <SecondaryButton sx={{marginTop:'48px',marginBottom:'16px'}}>Return to sign in</SecondaryButton>
            </Box>
          </Box>
        {/* </Modal>
    </div> */}
    </Box>
    )}
    </Box>

 );
}