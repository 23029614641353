import React from 'react';
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Button, Typography,Grid } from "@mui/material";
import Crop169Icon from "@mui/icons-material/Crop169";
import notification from "./images/Notification (4).png";
import Messages from "./images/Messages.png";
import Discussion from "./images/Discussion.png";
import Profile from "./images/image 22.png";
import { InputLabel } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { useState } from "react";
import DivComponents from './DivComponent';
import Payment from './PaymentComponent';
import Penalty from './PenaltyComponent';
import Link from "@mui/material/Link";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import sendicon from './images/Vector (5).png';
import downicon from './images/material-symbols_download.png';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Tick from './images/Vector (6).png';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowBackIcon from './images/Vector (9).png';
import AgentNotificatinMenu from './AgentNotificationMenu';
import HeaderAgentScreen from './HeaderAgentScreen';

function SendQuotes(){


    const menus = [
        "Calculator",
        "Quotations",
        "Products",
        "About us",
        "Contact us"
      ];



      const [dataList, setDataList] = useState([
        {
          id: 1,
          name: "Mortgage 1",
          initialPayment: 0,
          type: '',
          term: 2,
          rate: 0,
          period: 0,
          frequency: "monthly",       
          divBgColor:"white"
        },
        {
          id: 2,
          name: "Mortgage 2",
          initialPayment: 0,
          type: '',
          term: 2,
          rate: 0,
          period: 0,
          frequency: "monthly",
          divBgColor:"white"
        },
        {
          id: 3,
          name: "Mortgage 3",
          initialPayment: 0,
          type: '',
          term: 2,
          rate: 0,
          period: 0,
          frequency: "monthly",
      
          divBgColor:"white"
        },
        {
          id: 4,
          name: "Mortgage 4",
          initialPayment: 0,
          type: '',
          term: 2,
          rate: 0,
          period: 0,
          frequency: "monthly",        
          divBgColor:"white"
        },
      ])
      
  const [currentSlideIndex, setCurrentSlideIndex] = useState(4);



  const linkstyles = {
    link1: {
     textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "16px", fontWeight: "400",
      color: " #313131",zIndex:1
    },
    link2: {
      marginLeft: "35px", textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "16px", fontWeight: "400",
      color: " #313131"

    },
    activeLink: {
      textDecoration: 'underline',fontWeight:'500'
    },
  };
  const [activeLink, setActiveLink] = useState('Calculate Payment');

  const handleLinkClick = (link) => {
    setActiveLink(link);

  };
  const [calcContent, setcalcContent] = useState("Payment Content");

  const handlePaymentCalc = () => {
    setcalcContent("Payment Content");
    handleLinkClick('Calculate Payment');
  };

  const handlePenaltyCalc = () => {
    setcalcContent("Penalty Content");
    handleLinkClick('Calculate Penalty on cancellation');
  };

  const [isCheckedCheckboxSendQuotes, setisCheckedCheckboxSendQuotes] = useState([]);

  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (value) => {
  
    setIsOpen(false);
  }
 

  const [selectedCheckboxList, setCheckboxSelectedList] = useState([]);

  const handleCheckboxChangeSendQuote = (index) => {
    const temp = [...selectedCheckboxList];
    if (temp.includes(index)) {
      temp.splice(temp.indexOf(index), 1);
    }
    else {
      temp.push(index);
    }
    setIsAnyCheckboxChecked(temp);
    setisCheckedCheckboxSendQuotes(temp);
    setCheckboxSelectedList(temp);
  };
    
 
 
  const [showPopup, setShowPopup] = useState(false);

  const handleCancel = () => {
    setShowPopup(false);
  };

  
  const handlePopUp = () => {
    setShowPopup(true);
  };

  const [isHoveredSendQuote, setIsHoveredSendQuote] = useState(false);

  const handleMouseEnterSendQuote = () => {
    setIsHoveredSendQuote(true);
  };

  const handleMouseLeaveSendQuote = () => {
    setIsHoveredSendQuote(false);
  };


  const [isHoveredSendToMail, setIsHoveredSendToMail] = useState(false);

  const handleMouseEnterSendToMail = () => {
    setIsHoveredSendToMail(true);
  };

  const handleMouseLeaveSendToMail = () => {
    setIsHoveredSendToMail(false);
  };
  const [isHoveredDownloadResult, setIsHoveredDownloadResult] = useState(false);

  const handleMouseEnterDownloadResult = () => {
    setIsHoveredDownloadResult(true);
  };

  const handleMouseLeaveDownloadResult = () => {
    setIsHoveredDownloadResult(false);
  };
  const [showNotificationMenu, setShowNotificationMenu] = useState(false);

      const handleClickNotificationImage = () => {
        
        setShowNotificationMenu(!showNotificationMenu);
      };
    return(
      <Grid container sx={{ width: '100%', height: '100%' }}>
       <Box  sx={{ height:{md:'1024px',sm:'1024px'} , width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400" }}> 
       <Box sx={{display:'flex', justifyContent:'center',alignItems:'center'}} > 
       <HeaderAgentScreen></HeaderAgentScreen>

          {/* <AppBar position="static" sx={{ bgcolor: "#1F6373", height: "72px", width: '100vw' }}>
         
          <Box sx={{display: "flex" ,width:'100%',flexDirection:'row',justifyContent:'center',alignItems:'center',height: "72px"}}> 
       
       <Box sx={{display: "flex" ,width:'10%',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <Crop169Icon
                  sx={{
                    display:  "flex" ,
                 
                    height: 40,
                    width: '75%',
                    backgroundColor: "#fff"
                  }}
                />
  </Box>
                <Box sx={{  display: "flex" ,flexDirection:'row',gap:{md: '5%',sm:'0'},width:{md: '60%',sm:'56%'},justifyContent:'left'
                ,alignItems:'left',paddingLeft:{md: "3%",sm:'0'}, }}>
                  {menus.map((menu) => (
                    <Button
                      key={menu}
  
                      sx={{
                        my: 2, color: "white", display: "block",  textTransform: "capitalize", fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "21px", fontWeight: "400",'&:hover': { color: "#939393" },paddingRight:{md: "5%",sm:'.5%'},
                      }}
                    >
                      {menu}
                    </Button>
                  ))}  </Box>

                  
      <Box sx={{display:'flex', flexDirection:'row',justifyContent:'center',width:'15%',gap:{md: '10%',sm:'5%'},alignItems:'center'}} >        
  <img src={Discussion} alt=""  />
  <img src={Messages} alt="" />
  <img src={notification}  onClick={handleClickNotificationImage} alt=""  />
 {showNotificationMenu && <AgentNotificatinMenu />}
  </Box>
  <Box sx={{display:'flex', flexDirection:'row',justifyContent:'center',width:'15%',gap:{md:'7%',sm:'5%'},alignItems:'center',
  marginTop:{md:'0',sm:'2px'}}} > 
  <img src={Profile} alt=""  />
  <InputLabel style={{  fontFamily: "poppins", fontSize:{md:"16px",sm:"14px"} , fontWeight: "400",lineHeight:'24px',color:"#fff",
  }}>Robert</InputLabel>

<div className="dropdown" >
      <div className="dropdown__toggle" onClick={handleMenuToggle}>
      <ExpandMoreIcon style={{marginRight:'5%',marginTop:'5px',}}></ExpandMoreIcon>
      </div>
      {isOpen && (
        <div className="dropdown__menu"  style={{ position: 'absolute', right: '10px ',fontFamily: "poppins",
         fontSize: "12px",  fontWeight: "400",lineHeight:'20px',width:'134px',height:'56px',background:'#F8F8F8',borderRadius:'8px',
        boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',zIndex:1, minHeight: '20px',padding:'4px',color:'#2F2F2F'}}>
           <style>
            {`
              .dropdown__menu .MuiMenuItem-root:hover {
                background-color: #1F6373;
                color:white;
              }
              
            `}
          </style>
          <MenuItem  onClick={() => handleMenuItemClick("myprofile")} value="option1" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
          fontFamily: "poppins", fontSize: "12px",fontWeight: "400",lineHeight:'20px'}}> <PersonIcon style={{width:'15px',height:'17px',
          marginRight:'10px'}}>
            </PersonIcon>My profile</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("signout")} value="option2" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
          fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'20px'}}><LogoutIcon style={{width:'15px',height:'17px',
          marginRight:'10px'}}></LogoutIcon>Sign out</MenuItem>
         
        </div>
      )}
    </div>
    </Box>   

              
    </Box>   
         
          </AppBar> */}
        </Box>
        <Box sx={{marginLeft:{md:'17%',sm:'5%'},width: 'calc(100%-34%)',marginRight:{md:'17%',sm:'5%'},}}>
        <InputLabel style={{  fontFamily: "poppins", fontSize: "20px", fontWeight: "500",lineHeight:'30px',color:"#313131",marginTop:'10px',
  }}>Send quotes</InputLabel>
  <Box sx={{display:'flex', flexDirection:'row',justifyContent:'left',width: '100%'}}>
<AppBar position="static" sx={{ bgcolor: "#1F6373", height: "60px", width: '99%',borderRadius:'8px 8px 0px 0px',marginTop:'10px',
paddingLeft:'20px',
justifyContent:'center'
 }}>
 <InputLabel style={{  fontFamily: "poppins", fontSize: "16px", fontWeight: "600",lineHeight:'24px',color:"#fff",
  }}>  User Id : 225231</InputLabel>
</AppBar>   
</Box>
<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',width:'calc(100%)' ,marginRight:{md:'14%',sm:'5%'}
        ,marginTop:{md:'8%',sm:'5%'},gap:{md:'2%',sm:'0'}
       }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width:{md:'20%',sm:'27%'}  ,
            height: '334px', alignItems: 'center', gap: '20px', 
          }}>
           < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'12px',sm:'12px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '16px', 
              lineHeight: '24px', fontWeight: '600', color: '#313131'
            }}>Input parameters</Typography></Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Initial payment</Typography> <HelpIcon sx={{
              fontSize: '12px', color: '#878787'
             
            }} /> </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'2px',sm:'2px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Type</Typography> <HelpIcon sx={{
              fontSize: '12px', 
              color: '#878787'
            }} /> </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'3px',sm:'3px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Term</Typography> <HelpIcon sx={{
              fontSize: '12px',
              color: '#878787'
            }} /> </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'5px',sm:'3px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px', 
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Interest rate (%)</Typography> <HelpIcon sx={{
              fontSize: '12px',
              color: '#878787'
            }} /> </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',marginTop:'3px',
             alignItems: 'center'
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}> Amortization period</Typography><HelpIcon sx={{ fontSize: '12px', color: '#878787' }} /></Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'3px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131',marginRight: 'auto',
            }}> Payment frequency</Typography><HelpIcon sx={{
              fontSize: '12px', 
              color: '#878787'
            }} /></Box>
          </Box>
<Box sx={{width:{md:'calc(75%)',sm:'calc(78%)'}, display: 'flex', flexDirection: 'row', alignItems: 'center',marginLeft:{md:'5%',sm:'1.5%'} 
           ,justifyContent:'center',gap:{md:'2%',sm:'1.5%'} 
          }}>
          {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex).map((data, index) => (
            <Box  key={index} className="slide" sx={{width:'calc(75%)'}} >
               <Checkbox  checked={selectedCheckboxList.includes(data.name)}  onChange={() => handleCheckboxChangeSendQuote(data.name)}
          style={{
            accentColor:"green" ,  color: isCheckedCheckboxSendQuotes.includes(data.name)===true ? "#1F6373" : "#CFC9C9",
           position: "relative", marginLeft:'85%',   zIndex: 1, background: isCheckedCheckboxSendQuotes.includes(data.name) ? "#F9F9F9" : "white",
           borderRadius: '0',width: '19px', height: '19px',marginTop:'-60px' }} />
                <DivComponents dataList={dataList} 
                  index={index}  
                   mortgageName={data.name} 
                   selectedCheckboxList={selectedCheckboxList}
                   setCheckboxSelectedList={setCheckboxSelectedList}
                   isSelected={isCheckedCheckboxSendQuotes.includes(data.name)}
                   onChange={() => handleCheckboxChangeSendQuote(data.name)}
                />
            </Box>
          ))}


       


</Box>
        </Box>
        <Box style={{ display: 'flex', flexDirection: "row", marginTop: '.5%', alignItems: 'center',width:'calc(100%-30%)' 
        ,marginLeft:{md:'12%',sm:'5%'},marginRight:{md:'14%',sm:'5%'},
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'19.5%',sm:'25%'},
             alignItems: 'center',
          }}>
           <Typography style={{
           fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
            color: " #313131",marginRight: 'auto',
          }}> Payment schedule</Typography><HelpIcon style={{ fontSize: "12px", color: "#878787" }} /></Box>
           <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'29%',sm:'17%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131",
          }}> CAD (result)</Typography></Box>
           <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'10%',sm:'17%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131"
          }}> CAD (result)</Typography></Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'27%',sm:'18%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131",
          }}> CAD (result)</Typography></Box>
            <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'11%',sm:'18%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131"
          }}> CAD (result)</Typography></Box>

        </Box>

        <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>

<Link href="#" underline='hover'
  style={activeLink === 'Calculate Payment' ? { ...linkstyles.link1, ...linkstyles.activeLink } : linkstyles.link1}
  onClick={handlePaymentCalc}>
  Calculate payment
</Link>


<Link href="#" underline='hover' rel="noopener"
  style={activeLink === 'Calculate Penalty on cancellation' ? { ...linkstyles.link2, ...linkstyles.activeLink } : linkstyles.link2}
  onClick={handlePenaltyCalc}>
  Calculate penalty on cancellation
</Link>



</Box>


{calcContent === "Payment Content" && <Box className="carousel" sx={{
          display: 'flex', flexDirection: "row", alignItems: "center",  width:'calc(100%)',
          marginRight:{md:'14%',sm:'5%'},         
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width:{md:'20%',sm:'27%'}  ,
            height: '334px', alignItems: 'center', gap: '6%', 
          }}>
           < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'41.5%',sm:'49%'}
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}>Payment schedule</Typography> <HelpIcon style={{ fontSize: "12px",color: "#878787" }} /> </Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}>Remaining principle</Typography><HelpIcon style={{ fontSize: "12px",  color: "#878787" }} /></Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',marginTop:'-2.3px'
            }}> Interest paid</Typography> <HelpIcon style={{ fontSize: "12px",color: "#878787" }} /></Box>
                < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:0,sm:'-1px'}
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}> Principle paid</Typography><HelpIcon style={{ fontSize: "12px", color: "#878787" }} /></Box>

          </Box>
          <Box sx={{width:{md:'calc(75%)',sm:'calc(78%)'}, display: 'flex', flexDirection: 'row', alignItems: 'center',
          marginLeft:{md:'7%',sm:'1.5%'} 
           ,justifyContent:'center',gap:{md:'2%',sm:'1.5%'} ,marginTop:'-7%'
          }}>

        {dataList.slice(Math.abs(4-currentSlideIndex),currentSlideIndex)

          .map((slide, index) => (
            <Box key={index} className="slide" sx={{width:'calc(75%)'}}>
              <Payment dataList={dataList} index={index} mortgageName={slide.name}
               isSelected={isCheckedCheckboxSendQuotes.includes(slide.name)}
               onChange={() => handleCheckboxChangeSendQuote(slide.name)}
              />
            </Box>
          ))}

</Box>
      </Box>}
    
  

        {calcContent === "Penalty Content" && <Box className="carousel" sx={{
          display: 'flex', flexDirection: "row", alignItems: "center",  width:'calc(100%)',
          marginRight:{md:'14%',sm:'5%'},     
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width:{md:'20%',sm:'27%'}  ,
            height: '334px', alignItems: 'center', gap: '5%', 
          }}>
           < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'43.5%',sm:'49%'}
          }}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131", marginTop: '12px',marginRight: 'auto',
            }}>Cancellation </Typography> <HelpIcon sx={{ fontSize: "12px", color: "#878787",marginTop: '10px'}} /> </Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',marginTop: '-2.5px',
            }}>Remaining principle</Typography><HelpIcon sx={{ fontSize: "12px", color: "#878787" }} /></Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',marginTop: '-2px',
            }}> Calculated penalty</Typography> <HelpIcon sx={{ fontSize: "12px", color: "#878787" }} /></Box>

          </Box>
          <Box sx={{width:{md:'calc(75%)',sm:'calc(78%)'}, display: 'flex', flexDirection: 'row', alignItems: 'center',
          marginLeft:{md:'7%',sm:'1.5%'} 
           ,justifyContent:'center',gap:{md:'2%',sm:'1.5%'} ,marginTop:'-7%'
          }}>
        {dataList.slice(Math.abs(4-currentSlideIndex),currentSlideIndex)
          .map((slide, index) => (
            <Box key={index} className="slide" sx={{width:'calc(75%)'}}>
              <Penalty dataList={dataList} index={index} mortgageName={slide.name}
               isSelected={isCheckedCheckboxSendQuotes.includes(slide.name)}
               onChange={() => handleCheckboxChangeSendQuote(slide.name)}
              />
            </Box>
          ))}
</Box>
      </Box>}  
    

        </Box>

        <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    Height: '80%',  
    position: 'fixed',
    left: 0,
    bottom: 0,
   width: 'calc(100% )',   
 
 boxShadow:' 0px -4px 4px rgba(217, 217, 217, 0.25)',
   backgroundColor:'white',
   zIndex:'3'
  }}
>
        <Box sx={{display: 'flex', flexDirection: "row", marginTop:{md:'2.5%',sm:'3%'} , alignItems: 'center',width: 'calc(100%-34%)' 
        ,marginLeft:{md:'15%',sm:'5%'},marginRight:{md:'15%',sm:'5%'},gap:{md:'2%',sm:'1%'},justifyContent: 'center' ,
        }}>
  
  <Button variant="contained"  onMouseEnter={handleMouseEnterSendQuote} 
        onMouseLeave={handleMouseLeaveSendQuote} onClick={handlePopUp} disabled={!isAnyCheckboxChecked}  style={{
            color: "white", textTransform: "none", padding: "10px 16px 10px 16px ",
            background: !isHoveredSendQuote && !isAnyCheckboxChecked  ? '#939393' : isHoveredSendQuote   ? '#074554' : '#1F6373',
             border:!isAnyCheckboxChecked ?"1px solid #939393" : "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal", 
             lineHeight: "21px",
            fontWeight: "500", fontSize: "14px", width: "348px", borderRadius: "4px", marginBottom: '25px'
          }} startIcon={<img src={sendicon} alt="" />}>
           Send quotes
          </Button>
          <Button variant="contained" onMouseEnter={handleMouseEnterSendToMail} 
        onMouseLeave={handleMouseLeaveSendToMail}  style={{
          color:isHoveredSendToMail?"white": "#1F6373",textTransform: "none", padding: "10px 16px 10px 16px ", marginLeft: "14px",
            background:isHoveredSendToMail?"#1F6373 ":"#D2E0E3", border: "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal",
             lineHeight: "21px",
            fontWeight: "500", fontSize: "14px", width: "180px", borderRadius: "4px", marginBottom: '25px'
          }}
            startIcon={<MailOutlineIcon  style={{  color:isHoveredSendToMail?"white": "#1F6373", }}  />}>
            Send to email
          </Button>
          <Button variant="contained" onMouseEnter={handleMouseEnterDownloadResult} 
        onMouseLeave={handleMouseLeaveDownloadResult}  style={{
          color:isHoveredDownloadResult?"white": "#1F6373", textTransform: "none", padding: "10px 16px 10px 16px ", marginLeft: "14px",
            background:isHoveredDownloadResult?"#1F6373 ":"#D2E0E3", border: "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal",
             lineHeight: "21px",
            fontWeight: "500", fontSize: "14px", width: "204px", borderRadius: "4px", marginBottom: '25px'
          }}  startIcon={<GetAppIcon style={{  color:isHoveredDownloadResult?"white": "#1F6373", }} />}>
            Download result
          </Button>
          </Box>   
  </Box>
  {showPopup && (
  <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '1075px',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                
                  width: '436px',
                  height: '158px',
                  backgroundColor: '#fff',                
                  border: '1px solid #CFC9C9',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                  boxSizing: 'border-box',
                  zIndex: '1'
                }}
              >
                <IconButton onClick={handleCancel} style={{ color: '#939393', position: 'absolute', width: '20px', height: '20px', left: '400px',
                 top: '10px' }} >
                  <HighlightOffIcon ></HighlightOffIcon>
                </IconButton>
                
                <img src={Tick} alt="" style={{ marginTop: "10px"}} />
              
                <Typography style={{ fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', 
                textAlign: "center",marginTop:'15px' }}>Your quote has been sent successfully</Typography>
              
              </Box>
            </Box>
            )}
       
       </Box>
          
          </Grid>
    );
}
export default SendQuotes;