//import React, { useLayoutEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Button, Grid, Typography } from "@mui/material";
import logo from './images/logo.png'
import MenuIcon from '@mui/icons-material/Menu';

import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';




import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ImgFrame from './images/HomeBlue.png';
import dataicon from './images/icon-park-outline_database-forbid.svg';
//import downicon from './images/material-symbols_download.png';
import HelpIcon from '@mui/icons-material/Help';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Link1 from "@mui/material/Link";
import { useState ,useEffect } from "react";

import DivComponents from './DivComponentUserMobile';
import Payment from './PaymentUserMobile';
import Penalty from './PenaltyComponent';
import CheckboxPopUp from './CheckboxPopUp';
import GetAppIcon from '@mui/icons-material/GetApp';

import SignIn from './SignInSignUp'
import axios from 'axios';
import { Link } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../App.css';
import Footer from './Footer';
import HeaderMobile from './HeaderLandingMobile';
import { API_URL } from "./constants";
const dbLink = process.env.REACT_APP_API_URL;


function MortgageUserMobile() {

//   const menus = [
//     "Calculator",
//     "Quotations",
//     "Products",
//     "About us",
//     "Contact us"
//   ];

const [currentPage, setCurrentPage] = useState('Canada Mortgage');
  const menus = [
    { label: "Universal Calculator", link: "/" },
    { label: "Canada Mortgage", link: "/canada-mortgage" },  
    { label: "About us", link: "/about-us" },
    { label: "Contact us", link: "/contact-us" }
  ];
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };



  const linkstyles = {
    link1: {
      textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "16px", fontWeight: "400",
      color: " #313131", zIndex: 1
    },
    link2: {
      marginLeft: "5%", textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "16px", fontWeight: "400",
      color: " #313131"

    },
    activeLink: {
      textDecoration: 'underline', fontWeight: "500"
    },
  };
  const [activeLink, setActiveLink] = useState('Calculate Payment');

  const handleLinkClick = (link) => {
    setActiveLink(link);

  };






  const [calcContent, setcalcContent] = useState("Payment Content");

  const handlePaymentCalc = () => {
    setcalcContent("Payment Content");
    handleLinkClick('Calculate Payment');
  };

  const handlePenaltyCalc = () => {
    setcalcContent("Penalty Content");
    handleLinkClick('Calculate Penalty on cancellation');
  };






  const [isSelected, setIsSelected] = useState(false);
  const [downBorderAmount, setdownBorderAmount] = useState(false);
  const [downBorderPercentage, setdownBorderPercentage] = useState(false);
  const [askingPriceValue, setAskingPriceValue] = useState(1000000);
  const [mortgageamountValue, setMortgageamountValue] = useState("800000");
  const [termValue, setTermValue] = useState(5);
  const [rateValue, setRateValue] = useState(3);
  const [periodValue, setPeriodValue] = useState(25);
  const [frequencyValue, setFrequencyValue] = useState('M');
  const [downPerValue, setDownPerValue] = useState(20);
  const [downAmountValue, setDownAmountValue] = useState("200000");
  const [insuranceValue, setInsuranceValue] = useState(0);
  const [sheduledPayment, setSheduledPayment] = useState(3785.96);
  const [resultTotal, setResultTotal] = useState({ TotalAmountPaid: 1431.60, TotalInterestPaid: 1407.60, TotalPrinciplePaid: 2839.20 });
  //const [result2, setResult2] = useState(25);

  const [dataList, setDataList] = useState([
    {
      id: 0,
      name: "Option #1",
      askingprice: askingPriceValue,
      DownPaymentPercentage: downPerValue,
      DownPaymentAmount: downAmountValue,
      cmhc: insuranceValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      isSelected: false,
      downBorderAmount:false,
      downBorderPercentage:false,
      SheduledResult: sheduledPayment,
      lowerResults: resultTotal
    },
    {
      id: 1,
      name: "Option #2",
      askingprice: askingPriceValue,
      DownPaymentPercentage: downPerValue,
      DownPaymentAmount: downAmountValue,
      cmhc: insuranceValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      isSelected: false,
      downBorderAmount:false,
      downBorderPercentage:false,
      SheduledResult: sheduledPayment,
      lowerResults: resultTotal
    },
    
  ])


  const [numberOfAddClick, setnumberOfAddClick] = useState(4);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(2);

  const handleAddSlide = async () => {

    setisDeleteButtonEnabled(true);
    if (dataList.length !== 7) {
      const dwnamnt = askingPriceValue * (downPerValue / 100);
      const mrval = (askingPriceValue - dwnamnt) + insuranceValue;
      const Floatamount = parseFloat(mrval);
      const isFloat = Number.isFinite(Floatamount) && Floatamount % 1 !== 0; 
      const mortval =isFloat ? Floatamount.toFixed(2) : Floatamount.toString();
      let  monthPayment;
      const cmhcinsurance = await getThirdResult(mrval, downPerValue);
      const monthlyPayment = await getSheduledPayment(parseInt(askingPriceValue), (rateValue / 100), periodValue, frequencyValue, mrval);
      const data = await getSecondResult(parseInt(askingPriceValue), (rateValue / 100), periodValue, frequencyValue, monthlyPayment, termValue, mrval, monthlyPayment)
      if(monthlyPayment < 1){
        monthPayment = monthlyPayment.toFixed(4)
    }
    else{
        monthPayment = monthlyPayment.toFixed(2)   
    }
      const newData = {
        id: dataList.length, name: `Option #${numberOfAddClick + 1}`, askingprice: askingPriceValue,
        DownPaymentPercentage: downPerValue, DownPaymentAmount: dwnamnt, cmhc: cmhcinsurance, mortgageamount: mortval, term: termValue,
        rate: rateValue, period: periodValue, isSelected: false, downBorderAmount:false, downBorderPercentage:false, frequency: frequencyValue,
         SheduledResult:monthPayment, lowerResults: {
          TotalAmountPaid: data.TotalPrinciple,
          TotalInterestPaid: data.TotalInterest,
          TotalPrinciplePaid: data.TotalPayment
        }
      }

      // isDeleteConfirm===true?setCurrentSlideIndex(currentSlideIndex+2):setCurrentSlideIndex(currentSlideIndex+1);

      setnumberOfAddClick(numberOfAddClick + 1);

      setDataList([...dataList, newData]);
    }
    setCurrentSlideIndex(currentSlideIndex + 1);
  };



  const handlePrevSlide = () => {

    if (currentSlideIndex > 4)
      setCurrentSlideIndex(currentSlideIndex - 1)

  };

  const handleNextSlide = () => {
    if (currentSlideIndex < (dataList.length))
      setCurrentSlideIndex(currentSlideIndex + 1)

  }
  const [isDeleteButtonEnabled, setisDeleteButtonEnabled] = useState(false);





  const isdisableAddSlidebutton = (dataList[dataList.length]?.name) === (dataList[currentSlideIndex]?.name)
  const [CheckboxEnabled, setCheckboxEnabled] = useState(false);

  const [thebidding, setthebidding] = useState("bidding disable");
  const handleAddBiddingCheckbox = () => {
    setCheckboxEnabled(true);
    setthebidding("bidding enable");
    setisDeleteButtonEnabled(false);

  };

  const [showPopupforCheckbox, setShowPopupforCheckbox] = useState(false);

  const handleNextClick = () => {

    setShowPopupforCheckbox(true);



  };
  const [selectedCheckboxList, setCheckboxSelectedList] = useState([]);

  // const [isCheckedCheckboxNext, setisCheckedCheckboxNext] = useState([]);
  var isNextButtonEnabled = selectedCheckboxList.length > 0;
  const handleNextCheckboxChange = (isChecked, index) => {

    // setisNextButtonEnabled(isChecked);    

    //setisCheckedCheckboxNext(isChecked);
    //[...isChecked]=[temp,setIsChecked];

    //  if (isChecked) {
    //   setisCheckedCheckboxNext((prevChecked) => [...prevChecked, index]);
    // } else {
    //   setisCheckedCheckboxNext((prevChecked) =>
    //     prevChecked.filter((item) => item !== index)
    //   );
    // }




  };
  const IsChevorButton = (CheckboxEnabled && dataList.length > 4) || isDeleteButtonEnabled;




  // const [isChecked, setIsChecked] = useState(false);

  const [isHoveredAddMortgage, setIsHoveredAddMortgage] = useState(false);

  const handleMouseEnterAddMortgage = () => {
    setIsHoveredAddMortgage(true);
  };

  const handleMouseLeaveAddMortgage = () => {
    setIsHoveredAddMortgage(false);
  };

  // const [isHoveredTheBidding, setIsHoveredTheBidding] = useState(false);

  // const handleMouseEnterTheBidding = () => {
  //   setIsHoveredTheBidding(true);
  // };

  // const handleMouseLeaveTheBidding = () => {
  //   setIsHoveredTheBidding(false);
  // };
  // const [isHoveredSendToMail, setIsHoveredSendToMail] = useState(false);

  // const handleMouseEnterSendToMail = () => {
  //   setIsHoveredSendToMail(true);
  // };

  // const handleMouseLeaveSendToMail = () => {
  //   setIsHoveredSendToMail(false);
  // };

  // const [isHoveredDownloadResult, setIsHoveredDownloadResult] = useState(false);

  // const handleMouseEnterDownloadResult = () => {
  //   setIsHoveredDownloadResult(true);
  // };

  // const handleMouseLeaveDownloadResult = () => {
  //   setIsHoveredDownloadResult(false);
  // };
  // const [isHoveredNext, setIsHoveredNext] = useState(false);

  // const handleMouseEnterNext = () => {
  //   setIsHoveredNext(true);
  // };

  // const handleMouseLeaveNext = () => {
  //   setIsHoveredNext(false);
  // };

  // const [size, setSize] = useState([0, 0]);

  // function ViewPort() {
  //   var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  //   var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  //   var viewsize = w + "," + h;
  //   alert("Your View Port Size is:" + viewsize);
  // }
  // useLayoutEffect(() => {
  //   function updateSize() {
  //     setSize([window.outerWidth, window.outerWidth]);
  //     var x = window.matchMedia("(max-width: 700px)")
  //     console.log("x", x)
  //   }
  //   window.addEventListener('resize', updateSize);
  //   updateSize();
  //   return () => window.removeEventListener('resize', updateSize);
  // }, []);

  // console.log("size", size);

  const [isActiveAskingPrice, setIsActiveAskingPrice] = useState(false);

  const handleInputClickAskingPrice = () => {
    setIsActiveAskingPrice(true);
  };

  const handleInputBlurAskingPrice = () => {
    setIsActiveAskingPrice(false);
  };
  const [isActiveLocation, setIsActiveLocation] = useState(false);

  const handleInputClickLocation = () => {
    setIsActiveLocation(true);
  };

  const handleInputBlurLocation = () => {
    setIsActiveLocation(false);
  };
  const [showSignIn, setShowSignIn] = useState(false);

  const handleClickSignIn = () => {
    setShowSignIn(!showSignIn);
  };
  const [showTermText, setShowTermText] = useState(false);
  const handleTermClick = () => {
    setShowTermText(!showTermText)
    setTimeout(() => {
      setShowTermText(false);
    }, 5000);
    setShowAnnualInterestRateText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };

  const [showAnnualInterestRateText, setShowAnnualInterestRateText] = useState(false);
  const handleAnnualInterestRateClick = () => {
    setShowAnnualInterestRateText(!showAnnualInterestRateText);
    setTimeout(() => {
      setShowAnnualInterestRateText(false);
    }, 5000);
    
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };

  const [showAmortizationText, setShowAmortizationText] = useState(false);
  const handleAmortizationTextClick = () => {
    setShowAmortizationText(!showAmortizationText);
    setTimeout(() => {
      setShowAmortizationText(false);
    }, 5000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };

  const [showPaymentfreequencyText, setShowPaymentfreequencyText] = useState(false);
  const handlePaymentfreequencyTextClick = () => {
    setShowPaymentfreequencyText(!showPaymentfreequencyText);
    setTimeout(() => {
      setShowPaymentfreequencyText(false);
    }, 8000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };

  const [showPaymentSheduleText1, setShowPaymentSheduleText1] = useState(false);
  const handlePaymentSheduleTextClick1 = () => {
    setShowPaymentSheduleText1(!showPaymentSheduleText1);
    setTimeout(() => {
      setShowPaymentSheduleText1(false);
    }, 5000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };

  const [showPaymentSheduleText2, setShowPaymentSheduleText2] = useState(false);
  const handlePaymentSheduleTextClick2 = () => {
    setShowPaymentSheduleText2(!showPaymentSheduleText2);
    setTimeout(() => {
      setShowPaymentSheduleText2(false);
    }, 5000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };

  const [showAskingPriceText, setShowAskingPriceText] = useState(false);
  const handleAskingPriceTextClick = () => {
    setShowAskingPriceText(!showAskingPriceText);
    setTimeout(() => {
      setShowAskingPriceText(false);
    }, 5000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };

  

  const [showTotalPrinciplePaidText, setShowTotalPrinciplePaidText] = useState(false);
  const handleTotalPrinciplePaidTextClick = () => {
    setShowTotalPrinciplePaidText(!showTotalPrinciplePaidText);
    setTimeout(() => {
      setShowTotalPrinciplePaidText(false);
    }, 5000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };

  const [showTotalInterestPaidText, setShowTotalInterestPaidText] = useState(false);
  const handleTotalInterestPaidTextClick = () => {
    setShowTotalInterestPaidText(!showTotalInterestPaidText);
    setTimeout(() => {
      setShowTotalInterestPaidText(false);
    }, 6000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };

  const [showTotalAmountPaidText, setShowTotalAmountPaidText] = useState(false);
  const handleTotalAmountPaidTextClick = () => {
    setShowTotalAmountPaidText(!showTotalAmountPaidText);
    setTimeout(() => {
      setShowTotalAmountPaidText(false);
    }, 8000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };

  const [showMortgageAmountText, setShowMortgageAmountText] = useState(false);
  const handleMortgageAmountTextClick = () => {
    setShowMortgageAmountText(!showMortgageAmountText)
    setTimeout(() => {
      setShowMortgageAmountText(false);
    }, 3000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };

  const [showDownAmountText, setShowDownAmountText] = useState(false);
  const handleDownAmountTextClick = () => {
    setShowDownAmountText(!showDownAmountText)
    setTimeout(() => {
      setShowDownAmountText(false);
    }, 3000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };
  const [showDownPerText, setShowDownPerText] = useState(false);
  const handleDownPerTextClick = () => {
    setShowDownPerText(!showDownPerText)
    setTimeout(() => {
      setShowDownPerText(false);
    }, 3000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
  };
  const [showInsuranceText, setShowInsuranceText] = useState(false);
  const handleInsuranceTextClick = () => {
    setShowInsuranceText(!showInsuranceText)
    setTimeout(() => {
      setShowInsuranceText(false);
    }, 3000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowDownPerText(false);
  };


  //const [askingPriceValue, setAskingPriceValue] = useState('1000000');
  const [askingPriceVariableParent, setAskingPriceVariableParent] = useState(false);
  const handleChangeAskingPriceValue = (event) => {
    const newValue = event.target.value;
    setAskingPriceValue(newValue);
    // dataList.askingprice=newValue;
    setAskingPriceVariableParent(true);
  };

  const handleMouseEnterPaymentButton = (index) => {
    var tempdataList = [...dataList]
    tempdataList[index].isSelected = true;
    setDataList(tempdataList);
  };

  const handleMouseLeavePaymentButton = (index) => {
    var tempdataList = [...dataList]
    tempdataList[index].isSelected = false;
    setDataList(tempdataList);
  };
  const [showPopupforSummary, setShowPopupforSummary] = useState(false);
  const [showResultSummary, setShowResultSummary] = useState([]);
  const handleSummary = async (mname) => {
    const index = dataList.findIndex(x => x.name === mname);
    const downPerValue = dataList[index].DownPaymentPercentage;
    const dwnamnt = dataList[index].DownPaymentAmount;

    const askingValue = dataList[index].askingprice;

    const rate = (dataList[index].rate) / 100;
    const amortValue = dataList[index].period;
    const freqValue = dataList[index].frequency;
    const termsValue = dataList[index].term;
    const shedule = dataList[index].sheduledPayment;
    const mrval1 = dataList[index].askingprice - dwnamnt;
    // const cmhcinsurance = await getThirdResult(mrval1, downPerValue);
    const cmhcinsurance = dataList[index].cmhc;
    const mrval = dataList[index].mortgageamount;
    const monthlyPayment = await getSheduledPayment(askingValue, rate, amortValue, freqValue, mrval);
    const data = await getSecondResult(askingValue, rate, amortValue, freqValue, parseFloat(monthlyPayment), termsValue, mrval, parseFloat(monthlyPayment))
    setShowResultSummary(data.scheduleData);
    console.log("data.scheduleData/////", monthlyPayment)
    setShowPopupforSummary(true);
  };


  const handleCancel = () => {
    setShowPopupforSummary(false);


  };



  ////////////////////////backend/////////////////////////
  const [resultAsking, setResultAsking] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [result, setResult] = useState([3785.96, 3785.96, 3785.96, 3785.96, 3785.96, 3785.96, 3785.96]);
  const [result2, setResult2] = useState([{ Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
  { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
  { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
  { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }]);
  const [askingprice, setAskingPrice] = useState([1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000]);
  const [interestRate, setInterestRate] = useState([0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03]);
  const [amortznPeriod, setAmortznPeriod] = useState([25, 25, 25, 25, 25, 25, 25]);
  const [FreqParam, setFreqParam] = useState(['M', 'M', 'M', 'M', 'M', 'M']);
  const [TermVal, setTermVal] = useState([5, 5, 5, 5, 5, 5, 5]);
  const [InitialpaymentVal, setInitialpaymentVal] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [mortgageamountVal, setmortgageamountVal] = useState([800000, 800000, 800000, 800000, 800000, 800000, 800000]);
  const [downPaymentPercentageValue, setDownPaymentPercentageValue] = useState([20, 20, 20, 20, 20, 20, 20]);
  const [downPaymentAmountValue, setDownPaymentAmountValue] = useState([200000, 200000, 200000, 200000, 200000, 200000, 200000]);
  //const[insuranceValue,setInsuranceValue]=useState([0,0,0,0,0,0,0])
  // useEffect(() => {    
  //   console.log('result from parent:', result);
  // }, [result]); 

  const [mortgageAmountTextValue, setMortgageAmountTextValue] = useState([800000, 800000, 800000, 800000, 800000, 800000, 800000]);

  const [mortAmountValue, setMortAmountValue] = useState([800000, 800000, 800000, 800000, 800000, 800000, 800000]);

  const [mortgageAmountWithInsuranceValue, setMortgageAmountWithInsuranceValue] = useState([]);

  const [insuranceTextValue, setInsuranceTextValue] = useState([]);

  const handleChangeAskingprice = async (value, index) => {



    const AskingPriceValue = dataList[index].askingprice;

    const downPayment = dataList[index].DownPaymentPercentage;
    const MortgageAmount = (dataList[index].askingprice) - (dataList[index].askingprice * (dataList[index].DownPaymentPercentage / 100));
    var tempdataList1 = [...dataList]
    tempdataList1[index].mortgageamount = (dataList[index].askingprice) - (dataList[index].askingprice * (dataList[index].DownPaymentPercentage / 100)).toFixed(2);
    tempdataList1[index].DownPaymentAmount = value * (dataList[index].DownPaymentPercentage / 100);
    setDataList(tempdataList1);

    var res = await axios.post(`${API_URL}/calculate3`, { MortgageAmount, downPayment });

    var tempdataList = [...dataList]
    // tempdataList[index].mortgageamount=(value)-(value*(dataList[index].DownPaymentPercentage/100));
    tempdataList[index].cmhc = res.data.mortgageInsurance;
    // tempdataList[index].DownPaymentAmount=AskingPriceValue*(dataList[index].DownPaymentPercentage/100);
    setDataList(tempdataList);

    // const newArray=[...askingprice]
    // newArray[index]=AskingPriceValue;
    // setAskingPrice(newArray)
    // const newArray = askingprice.slice(); // Create a shallow copy of the array
    // newArray[index] = parseInt(event.target.value); // Modify the value at the specified index
    // setAskingPrice[index]=parseInt(value);
    const rate = (dataList[index].rate) / 100;
    const Amortization = dataList[index].period;
    const Frequency = dataList[index].frequency;
    const Term = dataList[index].term;
    const MortgageAmnt = dataList[index].mortgageamount;
    console.log("final price", dataList);
    try {
      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      // const monthlyPayment = response.data.MonthlyPayment
      // console.log("data",monthlyPayment);
      // const newArray = result.slice(); // Create a shallow copy of the array
      // newArray[index] = monthlyPayment; // Modify the value at the specified index
      // setResult(newArray);



      // Assuming monthlyPayment is an array received from the response
      const monthlyPayment = response.data.MonthlyPayment;
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}

      // Update the result array with the new values

      setResult((prevResult) => {
        const newArray = [...prevResult]; // Create a copy of the existing array
        newArray[index] = response.data.MonthlyPayment; // Modify the value at the specified index
        return newArray; // Set the updated array

      });




      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });


      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);

    } catch (error) {
      // Handle any error
    }
  };
  const getThirdResult = async (MortgageAmount, downPayment) => {
    // const response = await axios.post(`${API_URL}/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

    var res = await axios.post(`${API_URL}/calculate3`, { MortgageAmount, downPayment })

    const cmhcinsurance = res.data.mortgageInsurance;
    return cmhcinsurance;
  }


  const getSheduledPayment = async (AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt) => {
    const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });


    const monthlyPayment = response.data.MonthlyPayment;
    
    return monthlyPayment;
  }

  const getSecondResult = async (AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment) => {
    // const response = await axios.post(`${API_URL}/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

    const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment });

    return response2.data;
  }

  const handleChangeAskingpriceWithInsurance = async (value, index) => {

    const AskingPriceValue = value;

    const downPayment = dataList[index].DownPaymentPercentage;
    const MortgageAmount = (value) - (value * (dataList[index].DownPaymentPercentage / 100));
    const Floatamount = parseFloat(MortgageAmount);
    const isFloat = Number.isFinite(Floatamount) && Floatamount % 1 !== 0;  
   const mortgamnt =  isFloat ? Floatamount.toFixed(2) : Floatamount.toString(); 
    var tempdataList1 = [...dataList]
    tempdataList1[index].mortgageamount = mortgamnt;
    tempdataList1[index].DownPaymentAmount = value * (dataList[index].DownPaymentPercentage / 100);
    setDataList(tempdataList1);

    const cmhcinsurance = await getThirdResult(MortgageAmount, downPayment)

    var tempdataList = [...dataList]
    // tempdataList[index].mortgageamount=(value)-(value*(dataList[index].DownPaymentPercentage/100));
    tempdataList[index].cmhc = cmhcinsurance;
    // tempdataList[index].DownPaymentAmount=AskingPriceValue*(dataList[index].DownPaymentPercentage/100);
    setDataList(tempdataList);



    const rate = (dataList[index].rate) / 100;
    const Term = dataList[index].term;

    const Amortization = dataList[index].period;

    const Frequency = dataList[index].frequency;

    const MortgageAmnt = dataList[index].mortgageamount;
    const SheduledPayment = dataList[index].SheduledResult;

    try {
      //const response = await axios.post(`${API_URL}/calculate', { AskingPriceValue,rate,Amortization,Frequency,MortgageAmnt});

      const monthlyPayment = await getSheduledPayment(AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt)
      // const monthlyPayment = response.data.MonthlyPayment;
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}


      // setResult((prevResult) => {
      //   const newArray = [...prevResult]; // Create a copy of the existing array
      //   newArray[index] = monthlyPayment; // Modify the value at the specified index
      //   return newArray; // Set the updated array

      // });





      const data = await getSecondResult(AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment)

      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: data.TotalPayment,
          TotalInterestPaid: data.TotalInterest,
          TotalPrinciplePaid: data.TotalPrinciple,

        };
        return newDataList;
      });

      // const updatedResult2 = [...result2];
      // updatedResult2[index].Totalinterest =response2.data.TotalInterest;
      // updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
      // updatedResult2[index].Totalpayment =response2.data.TotalPayment;
      // setResult2(updatedResult2);

    } catch (error) {
      // Handle any error
    }
  };





  const handleOnchangeAskingPrice = async (value, index) => {


    const rate = (dataList[index].rate) / 100;
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const Frequency = dataList[index].frequency;
    const downPayment = ((value * 100) / AskingPriceValue).toFixed(1);
    const MortgageAmount = parseInt(AskingPriceValue) - parseInt(value);
    var res = await axios.post(`${API_URL}/calculate3`, { MortgageAmount, downPayment });

    var tempdataList = [...dataList]
    tempdataList[index].cmhc = res.data.mortgageInsurance;;
    setDataList(tempdataList);


    const MortgageAmnt = dataList[index].mortgageamount;

    const Term = dataList[index].term;
    console.log("$$$$", dataList[index].mortgageamount)
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod, FreqParam);
    try {
      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment;
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });

      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };

  const handleChangeInterest = async (event, index) => {
    const value = event.target.value;
    let rate = (value) / 100;
    const newArray = [...interestRate]
    newArray[index] = rate;
    setInterestRate(newArray);
    // setInterestRate[index]=rate;
    var tempdataList = [...dataList]
    tempdataList[index].rate = event.target.value;
    setDataList(tempdataList);
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const Frequency = dataList[index].frequency;

    const MortgageAmnt = dataList[index].mortgageamount;

    const Term = dataList[index].term;


    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod, FreqParam);
    try {
      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);

      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangePeriod = async (event, index) => {

    const Amortization = parseInt(event.target.value);
    const newArray = [...amortznPeriod]
    newArray[index] = Amortization;
    setAmortznPeriod(newArray);
    var tempdataList = [...dataList]
    tempdataList[index].period = parseInt(event.target.value);
    setDataList(tempdataList);

    setAmortznPeriod[index] = amortznPeriod;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate) / 100;
    const Frequency = dataList[index].frequency;
    const Term = dataList[index].term;

    const MortgageAmnt = dataList[index].mortgageamount;
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangeFreequency = async (event, index) => {

    const Frequency = event.target.value;
    // setFreqParam[index]=FreqParam;
    // const newArray = [...FreqParam]
    // newArray[index] = Frequency;
    // setFreqParam(newArray);
    var tempdataList = [...dataList]
    tempdataList[index].frequency = event.target.value;
    setDataList(tempdataList);


    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate) / 100;
    const Term = dataList[index].term;

    const MortgageAmnt = dataList[index].mortgageamount;
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = response.data.MonthlyPayment.toFixed(2);
        return newDataList;
      });
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangeTerm = async (event, index) => {

    const Term = parseInt(event.target.value);

    const newArray = [...TermVal]
    newArray[index] = Term;
    setTermVal(newArray)
    var tempdataList = [...dataList]
    tempdataList[index].term = parseInt(event.target.value);
    setDataList(tempdataList);

    // setTermVal[index]=parseInt(event.target.value);
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate) / 100;
    const Frequency = dataList[index].frequency;
    const MortgageAmnt = dataList[index].mortgageamount;
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  // const handleChangeInitialPayment = async(event,index) => {

  //   console.log("handleChangeInitialPayment ",event.target.value,index,askingprice)

  //   const Initialpayment = parseInt(event.target.value);

  //   const newArray=[...InitialpaymentVal]
  //   newArray[index]=Initialpayment;
  //   setInitialpaymentVal(newArray)

  //   // console.log("init......",Initialpayment)
  //   // setInitialpaymentVal[index]=Initialpayment;
  //  const Amortization =amortznPeriod[index];
  //   const AskingPriceValue =askingprice[index];
  //   const rate=interestRate[index];
  //   const Frequency=FreqParam[index];
  //  const Term=TermVal[index];

  //  const MortgageAmnt=(askingprice[index])-(event.target.value);
  //   console.log("interestRate,initialPayment,amortznPeriod",interestRate,askingprice,amortznPeriod);
  //   try {
  //     const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue,rate,Amortization,Frequency,MortgageAmnt});

  //     // Handle the response
  //     const monthlyPayment = response.data.MonthlyPayment
  //     const newArray = result.slice(); // Create a shallow copy of the array
  //     newArray[index] = monthlyPayment; // Modify the value at the specified index
  //     setResult(newArray);
  //     const response2 = await axios.post(`${API_URL}/calculate2', { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt });
  //     console.log("second result",response2);
  //     const updatedResult2 = [...result2];
  //     updatedResult2[index].Totalinterest =response2.data.TotalInterest;
  //     updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
  //     updatedResult2[index].Totalpayment =response2.data.TotalPayment;
  //     setResult2(updatedResult2);
  //     // Perform further actions with the response data
  //   } catch (error) {
  //     // Handle any error
  //   }
  // };
  // const handleChangeMortgageAmount = async(index) => {
  //   const MortgageAmnt = parseInt(mortgageAmountTextValue); 

  //   setmortgageamountVal[index]=MortgageAmnt;

  //   const Initialpayment=InitialpaymentVal[index];
  //  const Amortization =amortznPeriod[index];
  //   const AskingPriceValue =askingprice[index];
  //   const rate=interestRate[index];
  //   const Frequency=FreqParam[index];
  //  const Term=TermVal[index];

  //   console.log("interestRate,initialPayment,amortznPeriod",interestRate,askingprice,amortznPeriod);
  //   try {
  //     const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue,rate,Amortization,Frequency,MortgageAmnt});

  //     // Handle the response
  //     const monthlyPayment= response.data.MonthlyPayment;
  //     setMortAmountValue[index] = response.data.MonthlyPayment;
  //     // console.log("ghghghgh:index",monthlyPayment,index)
  //     const newArray = result.slice(); // Create a shallow copy of the array
  //      newArray[index] = monthlyPayment; 
  //      // Modify the value at the specified index
  //      setResult(newArray);

  //     const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt });
  //     console.log("second result",response2);
  //     const updatedResult2 = [...result2];
  //     updatedResult2[index].Totalinterest =response2.data.TotalInterest;
  //     updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
  //     updatedResult2[index].Totalpayment =response2.data.TotalPayment;
  //     setResult2(updatedResult2);
  //     // Perform further actions with the response data
  //   } catch (error) {
  //     // Handle any error
  //   }
  // };


  const handleChangeDownPaymentPercentageParent = async (value, index) => {
    const Per = (value);
    let DownPaymentPercentage = (Per) / 100;
    const newArray = [...downPaymentPercentageValue]
    newArray[index] = DownPaymentPercentage;
    //setInterestRate(newArray)
    // setInterestRate[index]=rate;
    // const Amortization =amortznPeriod[index]; 
    const AskingPriceValue = dataList[index].askingprice;
    // const Frequency=FreqParam[index];
    // const Initialpayment=InitialpaymentVal[index];
    // const rate=interestRate[index];

    const MortgageAmount = parseInt(AskingPriceValue) - (AskingPriceValue * (value / 100));
    // console.log("MortgageAmnt",parseInt(AskingPriceValue),parseInt(AskingPriceValue*(event.target.value.toFixed(1))))
    const downPayment = (value)
    // const Term = TermVal[index];
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod, FreqParam);
    try {
      const response1 = await axios.post(`${API_URL}/calculate3`, { MortgageAmount, downPayment });

      // Handle the response
      const mortgageInsurance = parseInt(response1.data.mortgageInsurance);
      const mortamnt = MortgageAmount + mortgageInsurance
      const Floatamount = parseFloat(mortamnt);
      const isFloat = Number.isFinite(Floatamount) && Floatamount % 1 !== 0;  
     const mortgamnt =  isFloat ? Floatamount.toFixed(2) : Floatamount.toString(); 
      var tempdataList1 = [...dataList]
      tempdataList1[index].cmhc = mortgageInsurance;
      tempdataList1[index].mortgageamount = mortgamnt;
      setDataList(tempdataList1);
      const Term = dataList[index].term;
      const Amortization = dataList[index].period;

      const rate = (dataList[index].rate) / 100;
      const Frequency = dataList[index].frequency;
      const MortgageAmnt = dataList[index].mortgageamount;
      console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);

      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangeDownPaymentAmountParent = async (value, index) => {
    const amnt = parseInt(value);
    //  let DownPaymentAmount=(amnt);
    //  const newArray=[...downPaymentAmountValue]
    //  newArray[index]=DownPaymentAmount;
    var tempdataList = [...dataList]
    tempdataList[index].DownPaymentAmount = parseInt(value);
    setDataList(tempdataList);

    //setInterestRate(newArray)
    // setInterestRate[index]=rate;
    // const Amortization =amortznPeriod[index]; 
    const AskingPriceValue = dataList[index].askingprice;
    // const Frequency=FreqParam[index];
    //const Initialpayment=InitialpaymentVal[index];
    //  const rate=interestRate[index];
    const downPayment = ((value * 100) / AskingPriceValue).toFixed(1);
    const MortgageAmount = parseInt(AskingPriceValue) - parseInt(value);
    console.log("nnnn@@@", (value));
    //const Term = TermVal[index];
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod, FreqParam);
    try {
      const response1 = await axios.post(`${API_URL}/calculate3`, { MortgageAmount, downPayment });

      // Handle the response
      const mortgageInsurance = parseInt(response1.data.mortgageInsurance);
      const mortamnt = MortgageAmount + mortgageInsurance
      const Floatamount = parseFloat(mortamnt);
      const isFloat = Number.isFinite(Floatamount) && Floatamount % 1 !== 0;  
     const mortgamnt =  isFloat ? Floatamount.toFixed(2) : Floatamount.toString(); 
      var tempdataList1 = [...dataList]
      tempdataList1[index].cmhc = mortgageInsurance;
      tempdataList1[index].mortgageamount = mortgamnt;
      setDataList(tempdataList1);

      const Term = dataList[index].term;
      const Amortization = dataList[index].period;

      const rate = (dataList[index].rate) / 100;
      const Frequency = dataList[index].frequency;
      const MortgageAmnt = dataList[index].mortgageamount;
      console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);

      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);







    } catch (error) {
      // Handle any error
    }
  };








useEffect(() => {

  
  console.log('datalist changed:', dataList); 
}, [dataList]);
  
//console.log("result from parent",result)

// useEffect(() => {
//   myfunction(askingprice)
// }, [askingprice]);

// useEffect(() => { 
// myTermFunction(TermVal)
// }, [TermVal]);

// useEffect(() => {
// myFunctionFreq(FreqParam)
// }, [FreqParam]);


// const myfunction = (arrayp)=>{
// console.log("array:",arrayp)

// }
// const myTermFunction = (arrayTerm)=>{
//   console.log("array:",arrayTerm)
  
//   }

//   const myFunctionFreq = (arrayFreq)=>{
//     console.log("array:",arrayFreq)
    
//     }
  


const triangleStyle = {
  position: 'absolute',
  top: '-10.5px',
  width: '10px',
  height: '20px',
  borderTop: '20px solid transparent',
  borderRight: '20px solid transparent',
  borderBottom: '20px solid #0C276C',
  transform: 'rotate(135deg)',

};




  return (
    <Grid container sx={{ width:  '100%', height: '100%',margin:0,padding:0 }}>
   <HeaderMobile currentPage={currentPage}></HeaderMobile>

    {/* <AppBar position="static" sx={{ bgcolor: "#0C276C", height: "56" }}>


<Toolbar disableGutters>
  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center', alignItems: 'center', paddingLeft: '18px',
    width:'30%' ,marginLeft:'2.5%'         }}>
  <img src={logo} alt=""  style={{ 

        height: '100%',
        width: '90%',
     
      }}
    />
    <IconButton
      edge="start"
      color="inherit"
      aria-label="menu"
      onClick={handleMenuToggle}

    >
      <MenuIcon />
    </IconButton>
  </Box>
  <Box
              sx={{
                display: showMenu ? "block" : "none", position: "absolute", top: "100%", width: "100vw", zIndex: 5,
                bgcolor: "#0C276C", justifyContent: 'flex-start', borderRadius: ' 0px 0px 8px 8px', paddingBottom: '20px'
              }}
            >
              {menus.map((menu) => (
                <Button onClick={handleMenuToggle}
                key={menu.label}
                component={Link}
                to={menu.link}
                  sx={{
                    display: "block", height: '40px',  color:  'white', textTransform: "capitalize", fontFamily: "poppins",
                    fontSize: "14px", lineHeight: "21px", fontWeight: "400", textAlign: 'left', borderRadius: '0px',
                    '&:hover': { background: "#CFDAF4", width: '100%',color:'#0C276C' }
                  }}
                >
                  {menu.label}
                </Button>
              ))}
            </Box>
  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '12px', marginLeft: 'auto', marginRight: '18px' }}>
    <Button
      variant="contained" onClick={handleClickSignIn}
      sx={{
        bgcolor: "#EB5648", color: "#fff", borderRadius: "16px", gap: "10px",
        alignContent: "center", padding: "4px 10px", textTransform: "capitalize", fontFamily: "poppins", fontSize: "14px",
        lineHeight: "21px", fontWeight: "400", width: "88px", height: "28px", '&:hover': { bgcolor: "#fff",color:'#0C276C' }, ml: "auto"
      }}
    >
      Sign in
    </Button> 
  
  </Box>
  {showSignIn && <SignIn />}





</Toolbar>


</AppBar>*/}

<Box  sx={{ height:'100%' , width:'calc(100%-32px)', alignItems:'center' , justifyContent:'center', display:'flex', flexDirection:'column',
paddingLeft:"16px",        paddingRight:"16px",paddingTop:"15px",gap:'15px'}}>
     
        <Box sx={{ width:'100%', alignItems:'center' , justifyContent:'center', display:'flex', flexDirection:'row',gap:'15px'}}>
         
          <Box sx={{ width:'25%', alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row',}}>
            <img src={ImgFrame} alt="" style={{ width: '100%', height: '100%' }} />
            </Box>
            <Box sx={{ width:'75%', alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'column',}}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "12px", lineHeight: "24px",
              fontWeight: 400, width: '100%'

            }}>
               Buying a home in Canada is an exciting and significant step towards creating a stable and prosperous future. At ratewaves , 
            we understand the importance of making well-informed decisions, and our Canada Mortgage Calculator is here to guide you on your
             journey.
            </Typography>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "12px", lineHeight: "24px",
              fontWeight: 400, width: '100%'

            }}>
            We've tailored this calculator to meet the unique needs of homebuyers across the country.
             Whether you're in the bustling city of Toronto, the scenic landscapes of Vancouver, or anywhere in between, our calculator 
             provides accurate and localized results for a range of Canadian mortgage options.
            </Typography>
          </Box>
         
        </Box>

        <Box  sx={{ width:'100%', alignItems:'center' , justifyContent:'center', display:'flex', flexDirection:'row',borderTop:'2px solid #E2E2E2'}}>
        </Box>

        <Box  sx={{ width:'100%', alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'column'
       ,gap:'10px'}} >
          <Box sx={{ width:"100%", alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'column',
        
          }} >
            <Typography style={{
              fontFamily: "poppins", fontSize: "16px", lineHeight: "24px", fontWeight: "500", color: "#313131", display: 'flex',
              alignItems: 'center'
            }}>Purchase price<ArrowForwardIcon sx={{ marginLeft: "5px", color: "#313131", fontSize: "16px", lineHeight: "24px"}} />
            </Typography>

          </Box>
          <Box sx={{width:'100%', alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'column'
         
          }} >

          <Box sx={{width:"100%",alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'column'}} >

            <Paper
              component="form" sx={{
                height: "40px", border: `1px solid ${isActiveAskingPrice ? '#0C276C' : '#CFC9C9'}`,
                borderRadius: "4px", boxShadow: isActiveAskingPrice ? '2px 0px 4px rgba(0, 0, 0, 0.25)' : 'none', display: 'flex', 
                alignItems: 'center',width:'100%',
              }}  >

              <InputBase  type="number"  onChange={(event) =>{  handleChangeAskingPriceValue(event,dataList.index)}} value={askingPriceValue}
                onBlur={handleInputBlurAskingPrice}
                sx={{ ml: 1, flex: 1, fontFamily: "Roboto", fontSize: "12px", lineHeight: "14px", fontWeight: "400",
                
              }}
                placeholder="Asking Price"
                inputProps={{ 'aria-label': 'Asking Price' ,sx: { color:'#626262'}, }} 
                
              />
            </Paper>

          </Box>
          {/* <Box sx={{width:'calc(100%)', alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'column',marginTop:'15px'
        }}   >
            <Paper
              component="form"
              sx={{
                display: 'flex', alignItems: 'center', height: '40px', borderRadius: '8px',width:'100%',
                border: `1px solid ${isActiveLocation ? '#0C276C' : '#CFC9C9'}`, boxShadow: isActiveLocation ? '2px 0px 4px rgba(0, 0, 0, 0.25)' : 'none',
              }}
            >
              <InputBase onClick={handleInputClickLocation}
                onBlur={handleInputBlurLocation}
                sx={{
                  ml: 1, flex: 1, fontFamily: 'Roboto', fontSize: '12px', lineHeight: '14px', fontWeight: '400', 
                }}
                placeholder="Location" inputProps={{ 'aria-label': 'Location',style: { color:'#626262'}, }}
              />
              <IconButton type="button" sx={{ color: '#626262',width:'15px',height:'15px',paddingRight:'5%' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>


          </Box> */}
          </Box>
        </Box>



        <Box sx={{
            display: 'flex', flexDirection: 'row' ,width:'100%' ,  alignItems: 'center',         }}>
            
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '600', color: '#313131', marginRight: 'auto',
            }}>Input parameters</Typography> </Box>


        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',width:'100%'  ,gap:'5px'  
       }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', minWidth:'100px'  ,width:'35%',
            alignItems: 'center', gap:'4%' ,height: "470px", 
          }}>
              <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',marginBottom:'-10px',
             alignItems: 'center'
          }}>
           <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Mortgage options</Typography></Box>
            <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative'
          }}>
             
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Purchase price</Typography> <HelpIcon onClick={handleAskingPriceTextClick}  sx={{
              fontSize: '10px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showAskingPriceText && (  <Box
            sx={{
              position: 'absolute',top:30, right: -170,borderRadius:'8px',zIndex: 9999,
                background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
               <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>This shows the total amount to be paid to seller/builder</Typography></Box>)}
             </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Down payment (%)</Typography>  <HelpIcon onClick={handleDownPerTextClick}  sx={{
              fontSize: '10px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showDownPerText && (  <Box
            sx={{
              position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
                 <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Portion of  purchase price arranged from own fund , not from mortgage loan</Typography></Box>)}
             </Box>
             <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Down payment</Typography>  <HelpIcon onClick={handleDownAmountTextClick}  sx={{
              fontSize: '10px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showDownAmountText && (  <Box
            sx={{
              position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
               <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Portion of  purchase price arranged from own fund , not from mortgage loan</Typography></Box>)}
             </Box>
             <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>CMHC insurance</Typography>  <HelpIcon onClick={handleInsuranceTextClick}  sx={{
              fontSize: '10px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showInsuranceText && (  <Box
            sx={{
              position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
                 <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Required for homebuyers in Canada who have a down payment of less than 20% of the purchase price of the home.
             It is designed to protect lenders in case the borrower defaults on their mortgage payments</Typography></Box>)}
             </Box>
             <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}> Mortgage </Typography>  <HelpIcon onClick={handleMortgageAmountTextClick}  sx={{
              fontSize: '10px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showMortgageAmountText && (  <Box
            sx={{
              position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >  
           <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Required Mortgage Amount</Typography></Box>)}
             </Box>
{/* <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Term</Typography> <HelpIcon onClick={handleTermClick}  sx={{
              fontSize: '10px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showTermText && (  <Box
            sx={{
              position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
                background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
                 <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>The number of years will be engaged with proposed mortgage</Typography></Box>)}
             </Box> */}
  <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}> Amortization</Typography><HelpIcon onClick={handleAmortizationTextClick}  sx={{
               fontSize: '10px', color: '#878787',            cursor:'pointer' }} />
             {showAmortizationText && (  <Box
            sx={{
              position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          > 
               <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
          <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>The number of years & months over which you will repay this loan</Typography></Box>)}
            
            </Box>        
            
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px', 
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Interest </Typography> <HelpIcon onClick={handleAnnualInterestRateClick}  sx={{
              fontSize: '10px',cursor:'pointer',
              color: '#878787'
            }} /> 
             {showAnnualInterestRateText && (  <Box
            sx={{
              position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
                background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
                 <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Annual interest rate for this mortgage</Typography></Box>)}
            </Box>
{/* <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}> Amortization</Typography><HelpIcon onClick={handleAmortizationTextClick}  sx={{
               fontSize: '10px', color: '#878787',            cursor:'pointer' }} />
             {showAmortizationText && (  <Box
            sx={{
              position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          > 
               <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
          <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>The number of years & months over which you will repay this loan</Typography></Box>)}
            
            </Box> */}
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131',marginRight: 'auto',
            }}> Payment frequency</Typography><HelpIcon onClick={handlePaymentfreequencyTextClick}  sx={{
              fontSize: '10px', cursor:'pointer',
              color: '#878787'
            }} />
            {showPaymentfreequencyText && (  <Box
            sx={{
              position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          > 
             <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
          <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Monthly, weekly, biweekly(Every two weeks), semi monthly(24 x per year), Accelerated BiWeekly(Reducing Amortization Period
             by making extra payment, half  of monthly payment on every two weeks.), Accelerated Weekly(Another accelerated option,
              one fourth of monthly payment on every weeks.)</Typography></Box>)}
            </Box>
            <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Term</Typography> <HelpIcon onClick={handleTermClick}  sx={{
              fontSize: '10px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showTermText && (  <Box
            sx={{
              position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
                background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
                 <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '18px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>The number of years will be engaged with proposed mortgage</Typography></Box>)}
             </Box>
             
          </Box>
         
<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center' ,gap:"4.5%"
           ,justifyContent:'center',width:'100%'
          }}>
         {dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex).map((data, index) => (
            <Box key={index} className="slide"  sx={{width:'100%'}} >
              <DivComponents dataList={dataList} setDataList={setDataList}
                index={index} parentIndex={index} CheckboxEnabled={CheckboxEnabled} setCurrentSlideIndex={setCurrentSlideIndex}
                currentSlideIndex={currentSlideIndex} mortgageName={data.name}
                isDeleteButtonEnabled={isDeleteButtonEnabled} selectedCheckboxList={selectedCheckboxList}
                setCheckboxSelectedList={setCheckboxSelectedList} askingPriceValue={askingPriceValue} 
              

                insuranceTextValue={insuranceTextValue} setInsuranceTextValue={setInsuranceTextValue} mortgageAmountWithInsuranceValue={mortgageAmountWithInsuranceValue} 
                setMortgageAmountWithInsuranceValue={setMortgageAmountWithInsuranceValue} handleOnchangeAskingPrice={handleOnchangeAskingPrice}
                handleChangeAskingpriceWithInsurance={handleChangeAskingpriceWithInsurance}
                 handleChangeTerm={handleChangeTerm} 
                handleChangeInterest={handleChangeInterest} handleChangePeriod={handleChangePeriod} 
                handleChangeAskingprice={handleChangeAskingprice} handleChangeFreequency={handleChangeFreequency} 
                mortgageAmountTextValue={mortgageAmountTextValue} setMortgageAmountTextValue={setMortgageAmountTextValue}
                askingPriceVariableParent={askingPriceVariableParent}  setAskingPriceVariableParent={setAskingPriceVariableParent} 
                handleChangeDownPaymentPercentageParent={handleChangeDownPaymentPercentageParent} handleChangeDownPaymentAmountParent={handleChangeDownPaymentAmountParent}
                downBorderAmount={downBorderAmount} setdownBorderAmount={setdownBorderAmount} downBorderPercentage={downBorderPercentage}
                setdownBorderPercentage={setdownBorderPercentage}
              />
            </Box>
          ))}
</Box>


       
          
        </Box>  



        <Box sx={{ display: 'flex', flexDirection: "row", alignItems: 'center',width:'100%',gap:'5px'
        
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'row', width:'30%',
             alignItems: 'center',minWidth:'100px',position: 'relative',height: '28px',
          }}>
           <Typography style={{
           fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
            color: " #313131",marginRight: 'auto',
          }}> Scheduled Payment</Typography><HelpIcon onClick={handlePaymentSheduleTextClick1}  sx={{
            fontSize: '10px', cursor:'pointer',
            color: '#878787'
          }} />
          {showPaymentSheduleText1 && (  <Box
          sx={{
            position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
            background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
          }}        >
             <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
           <Typography sx={{
            fontFamily: 'poppins', fontSize: '12px',
            lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',
          }}>Required payment  on each scheduled payment date</Typography></Box>)}
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center' ,gap:"5%",
           justifyContent:'center',width:'80%'
          }}>
          {dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex).map((value, index) => (
  <Box key={index} sx={{
    display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', border:dataList[index].isSelected  ?"1px solid #0C276C" : "1px solid #CFC9C9", 
    alignItems: 'center',borderRadius:'4px',height:"28px"
  }}>
    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
      color: " #313131",width:'100%',textAlign:'center'
    }}>{value.SheduledResult}</Typography>
  </Box>
))}

</Box>          
          {/* <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131",
          }}> Result</Typography></Box>
           <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'13%',sm:'17%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131"
          }}> Result</Typography></Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'12%',sm:'17%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131",
          }}> Result</Typography></Box>
            <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'13.5%',sm:'15%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131"
          }}>Result</Typography></Box> */}

        </Box>

        {!CheckboxEnabled && <Box sx={{ display:'flex' , flexDirection: 'row', justifyContent: 'right' ,width:'100%'
         }}>
            <Button onClick={handleAddSlide} disabled={dataList.length === 7} variant="contained" style={{
             color: "white", height: '30px',
              borderRadius: "12px", background: dataList.length === 7
                ? '#939393' : '#0C276C',
              border: dataList.length === 7 ? "1px solid #939393" : "1px solid #0C276C", fontFamily: "Roboto",
              textTransform: "none", fontStyle: "normal", fontWeight: "500", fontSize: "12px",'&:hover': { bgcolor: "#0B1E51",color:'white', border:'1px solid #0B1E51'} 
            }} 
              startIcon={
                <div
                sx={{
                  color: 'white',
                  height: '12px',
                  width: '12px',
                  '&:hover': {
                    color: '#0C276C', // Change the icon color on hover
                  },
                }}
              >
              <AddCircleOutlineIcon style={{  fontSize: "12px" }} /></div>}>
              Add more options
            </Button>
            
          </Box>}
         
          {IsChevorButton &&  <Box sx={{ display: 'flex', flexDirection: "row", width:'100%',justifyContent:'right',gap:'10px'
       }}>

          {/* <Link href="#" underline='hover'
            style={activeLink === 'Calculate Payment' ? { ...linkstyles.link1, ...linkstyles.activeLink } : linkstyles.link1}
            onClick={handlePaymentCalc}>
            Calculate payment
          </Link>


          <Link href="#" underline='hover' rel="noopener"
            style={activeLink === 'Calculate Penalty on cancellation' ? { ...linkstyles.link2, ...linkstyles.activeLink } : linkstyles.link2}
            onClick={handlePenaltyCalc}>
            Calculate penalty on cancellation
          </Link> */}


         <ChevronLeftIcon onClick={handlePrevSlide} disabled={currentSlideIndex === 4} sx={{
            background: "#CFDAF4", cursor: currentSlideIndex === 4?'default':'pointer',
            color: currentSlideIndex === 4 ? "#CCCCCC" : "#0C276C", boxShadow: "2px 0px 4px 0px #00000040 ", borderRadius: "50%",
            width: "28px", height: '28px'
          }} ></ChevronLeftIcon>



         <ChevronRightIcon onClick={isdisableAddSlidebutton ? null : handleNextSlide} sx={{
            background: "#CFDAF4", cursor: isdisableAddSlidebutton?'default':'pointer',
            boxShadow: "2px 0px 4px 0px #00000040", color: isdisableAddSlidebutton ? "#CCCCCC" : "#0C276C", borderRadius: "50%",
            width: "28px", height: '28px'
          }} ></ChevronRightIcon>

        </Box>}

         <Box className="carousel" sx={{
          display: 'flex', flexDirection: "row", alignItems: "center",  width:'100%',gap:'5px'
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width:'17.7%'  , height: '240px',
           minWidth:"100px", alignItems: 'center', gap: '7%',
          }}>
              < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',paddingTop:'2px'
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}>Mortgage options</Typography>   </Box>
           < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}>Scheduled Payment</Typography> <HelpIcon onClick={handlePaymentSheduleTextClick2} sx={{
              fontSize: '10px', cursor:'pointer',
              color: '#878787'
            }} />
            {showPaymentSheduleText2 && (  <Box
            sx={{
              position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          > 
             <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
          <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Required payment  on each scheduled payment date</Typography></Box>)}
             </Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}> Total principal</Typography><HelpIcon onClick={handleTotalPrinciplePaidTextClick} sx={{
              fontSize: '10px', cursor:'pointer',
              color: '#878787'
            }} />
            {showTotalPrinciplePaidText && (  <Box
            sx={{
              position: 'absolute',top:25, right: -170,borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
                 <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>The total amount of principal payment made during the Term and Amortization period respectively</Typography></Box>)}</Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
              color: " #313131",marginRight: 'auto'
            }}>Total interest </Typography> <HelpIcon onClick={handleTotalInterestPaidTextClick}  sx={{
              fontSize: '10px', cursor:'pointer',
              color: '#878787'
            }} />
            {showTotalInterestPaidText && (  <Box
            sx={{
              position: 'absolute', top:25, right: -170,borderRadius:'8px',zIndex: 9999,
                background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '200px', border:'1px solid #0C276C',
            }}          >
                 <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Total of all interest paid during the Term  period respectively assuming that the conditions of your loan
            </Typography></Box>)}</Box>
            < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',position: 'relative',height: '28px',
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "12px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}>Total amount</Typography><HelpIcon onClick={handleTotalAmountPaidTextClick}  sx={{
              fontSize: '10px', cursor:'pointer',
              color: '#878787'
            }} />
            {showTotalAmountPaidText && (  <Box
            sx={{
              position: 'absolute', top:25, right: -235,borderRadius:'8px',zIndex: 9999,
              background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px', width: '265px', border:'1px solid #0C276C',
            }}          > 
               <Box style={triangleStyle} sx={{ left: '20px',border:'1px solid #0C276C',  }}></Box>
          <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: 'white', marginRight: 'auto',
            }}>Total of all payments made during the Term and Amortization period respectively, assuming that the conditions of your loan
             (e.g. interest rate, amortization period, term, etc.) will not change during these periods.</Typography></Box>)}</Box>
              

          </Box>
          <Box sx={{width:'calc(100%)', display: 'flex', flexDirection: 'row', alignItems: 'center'
           ,justifyContent:'center',gap:'4.5%' 
          }}>

{dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex)

.map((slide, index) => (
  <Box key={index} className="slide" sx={{width:'calc(100%)'}}  >
    <Payment dataList={dataList} index={index} mortgageName={slide.name} onChange={() => handleNextCheckboxChange(slide.name)}
      isCheckedCheckboxNext={selectedCheckboxList.includes(slide.name)} 

    />
  </Box>
))}

       
</Box>

        </Box>

        {/* {calcContent === "Penalty Content" && <Box className="carousel" sx={{
          display: 'flex', flexDirection: "row", alignItems: "center",  width:'calc(100%-32px)',
          marginRight:"16px",       marginLeft:"16px"
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width:'17.5%'  ,
            height: '334px', alignItems: 'center', gap: '5%', 
          }}>
           < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:'43.5%'
          }}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131", marginTop: '12px',marginRight: 'auto',
            }}>Cancellation </Typography> <HelpIcon sx={{ fontSize: "12px", color: "#878787",marginTop: '10px'}} /> </Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',marginTop: '-2px',
            }}>Remaining principle</Typography><HelpIcon sx={{ fontSize: "12px", color: "#878787" }} /></Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',marginTop: '-2px',
            }}> Calculated penalty</Typography> <HelpIcon sx={{ fontSize: "12px", color: "#878787" }} /></Box>

          </Box>
          <Box sx={{width:'calc(75%)', display: 'flex', flexDirection: 'row', alignItems: 'center',marginLeft:'1.9%' 
           ,justifyContent:'center',gap:'1.5%' ,marginTop:'-7%'
          }}>
          {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex)
            .map((slide, index) => (
              <Box key={index} className="slide" sx={{width:'calc(75%)'}}>
                <Penalty dataList={dataList} index={index} mortgageName={slide.name} onChange={() => handleNextCheckboxChange(slide.name)}
                  isCheckedCheckboxNext={selectedCheckboxList.includes(slide.name)} />
              </Box>
            ))}
</Box>

        </Box>} */}

  
        
        {/* {thebidding === "bidding disable" &&<div> <Box sx={{display: 'flex', flexDirection: "row", marginTop:'25px' , alignItems: 'center',width:'calc(100%-32px)' 
        ,marginLeft:"16px",marginRight:"16px",justifyContent: 'center' , 
        }}>


          <Button variant="contained" onMouseEnter={handleMouseEnterTheBidding}
            onMouseLeave={handleMouseLeaveTheBidding} onClick={handleAddBiddingCheckbox} style={{
              color: "white", textTransform: "none",
              background: isHoveredTheBidding ? "#074554" : "#0C276C", border: "1px solid #0C276C", fontFamily: "Poppins",
              fontStyle: "normal", lineHeight: "21px", height: '36px',
              fontWeight: "500", fontSize: "12px", width:"100%", borderRadius: "4px",
            }} startIcon={<img src={dataicon} alt="" />} >
            Start the bidding
          </Button>
          </Box>
        <Box sx={{display: 'flex', flexDirection: "row",  marginTop:"15px", alignItems: 'center',
        width:'calc(100%-32px)',justifyContent:'center' ,marginBottom:'25px'
        ,marginLeft:"16px",marginRight:"16px"}}>
          <Button variant="contained" onMouseEnter={handleMouseEnterSendToMail}
            onMouseLeave={handleMouseLeaveSendToMail} style={{
              color: isHoveredSendToMail ? "white" : "#0C276C", textTransform: "none",
              background: isHoveredSendToMail ? "#0C276C " : "#CFDAF4", border: "1px solid #0C276C", fontFamily: "Poppins", fontStyle: "normal", lineHeight: "21px",
              fontWeight: "500", fontSize: "12px", width:"47%" , borderRadius: "4px", height: '36px'
            }}
            startIcon={<MailOutlineIcon style={{ color: isHoveredSendToMail ? "white" : "#0C276C", }} />}>
            Send to email
          </Button>
          <Button variant="contained" onMouseEnter={handleMouseEnterDownloadResult}
            onMouseLeave={handleMouseLeaveDownloadResult} style={{
              color: isHoveredDownloadResult ? "white" : "#0C276C", textTransform: "none", marginLeft: "10px", height: '36px',
              background: isHoveredDownloadResult ? "#0C276C " : "#CFDAF4", border: "1px solid #0C276C", fontFamily: "Poppins",
              fontStyle: "normal", lineHeight: "21px", fontWeight: "500", fontSize: "12px", width: "53%", borderRadius: "4px",
            }} startIcon={<GetAppIcon style={{ color: isHoveredDownloadResult ? "white" : "#0C276C", }} />}>
            Download result
          </Button>
  </Box>
  </div> }
        {thebidding === "bidding enable" && <Box sx={{display: 'flex', flexDirection: "row",  marginTop:"5px", alignItems: 'center',
        width:'calc(100%-32px)',justifyContent:'center' 
        ,marginLeft:"16px",marginRight:"16px"}}>



          <Button variant="contained" onClick={handleNextClick} disabled={!isNextButtonEnabled} style={{
            color: "white", textTransform: "none",
            background: isHoveredNext && isNextButtonEnabled
              ? '#074554' : !isNextButtonEnabled ? '#939393' : '#0C276C', border: !isNextButtonEnabled ? "1px solid #939393" : "1px solid #0C276C",
            fontFamily: "Poppins", fontStyle: "normal",
            lineHeight: "21px", fontWeight: "500", fontSize: "12px", width: "348px", borderRadius: "4px",height:'36px',
          }} onMouseEnter={handleMouseEnterNext}
            onMouseLeave={handleMouseLeaveNext} >
            Next<ArrowForwardIcon style={{ marginLeft: "5px", color: "white", fontSize: "16px" }} />
          </Button>
         



        </Box>} */}
          <Box sx={{ display: 'flex', flexDirection: "row", alignItems: 'center',width:'100%' 
        
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'row', width:'30%',
             alignItems: 'center',minWidth:'100px',maxWidth:'100px'
          }}>        
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center' ,gap:"5%",
           justifyContent:'center',width:'80%'
          }}>
 {dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex)

.map((slide, index) => (
          <Button id={index} variant="contained"  onClick={function () { handleSummary(slide.name); }}
          sx={{
            color: "white", textTransform: "none", height:'36px' ,padding: '5px' ,
            background: "#EB5648", border: "1px solid #EB5648", fontFamily: "Poppins",
            fontStyle: "normal", lineHeight: "18px", fontWeight: "400", fontSize: "12px", width: "100%", borderRadius: "8px", '&:hover': { bgcolor: "#fff", color: '#EB5648' }
          }}
            onMouseEnter={() => handleMouseEnterPaymentButton(index)}
            onMouseLeave={() => handleMouseLeavePaymentButton(index)}
            >
            Payment Schedule
          </Button>
           ))}

        </Box>
        {showPopupforSummary && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '1550px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '2'
            }}
          >
            <Box
              sx={{
                position: 'absolute',

                width: '80%',
                height: '80%',
                overflowY: 'auto',
                backgroundColor: '#fff',
                padding: "25px",
                border: '1px solid #CFC9C9',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                boxSizing: 'border-box',                zIndex: '3'
              }}
            >
              <IconButton onClick={handleCancel} style={{
                color: '#939393', position: 'absolute', width: '20px', height: '20px',
                left: '92%', top: '8px'
              }} >
                <HighlightOffIcon ></HighlightOffIcon>
              </IconButton>
              
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, marginTop:'10px' }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#0C276C', }}>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '500', color: '#ffffff', textAlign: 'center' }}>Period</TableCell>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '500', color: '#ffffff', textAlign: 'center' }}>Principal Payment</TableCell>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '500', color: '#ffffff', textAlign: 'center' }}>Interest Payment</TableCell>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '500', color: '#ffffff', textAlign: 'center' }}>Total Payment</TableCell>
                      <TableCell sx={{ fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '500', color: '#ffffff', textAlign: 'center' }}>Ending Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {

                      showResultSummary.map((row, index) => (
                        <TableRow
                          key={index}
                         
                          sx={{
                            '&:nth-of-type(odd)': {
                              backgroundColor: '#F3F3F3',
                            },
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                      
                          }}
                        >
                         
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center',   padding:'5px !important'
                          }}>{row.frequency} {row.month}</TableCell>
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center',  padding:'5px !important'
                          }}>{row.principlePayment}</TableCell>
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center',  padding:'5px !important'
                          }}>{row.interestPayment}</TableCell>
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center',  padding:'5px !important'
                          }}>{row.totalPayment}</TableCell>
                          <TableCell sx={{
                            fontFamily: 'poppins', fontSize: '12px', lineHeight: '21px', fontWeight: '400', color: '#313131',
                            textAlign: 'center',  padding:'5px !important'
                          }}>{row.EndingBalance}</TableCell>
                        </TableRow>
                      ))}

                  </TableBody>
                </Table>
              </TableContainer></Box></Box>)}

              </Box>
     {showPopupforCheckbox && (<CheckboxPopUp ></CheckboxPopUp>)}
      </Box>
      <Footer></Footer>
      </Grid>
  );

}

export default MortgageUserMobile;
