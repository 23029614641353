
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import { Button, Grid, Typography } from "@mui/material";
import logo from './images/logo.png'
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import SignIn from './SignInSignUp'
import '../App.css';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Footer from './Footer';
import Header from './HeaderLanding';
import HeaderMobile from './HeaderLandingMobile';

function AboutUs() {

  const [currentPage, setCurrentPage] = useState('About us');
  const menus = [
    { label: "Universal Calcualtor", link: "/" },
    { label: "Canada Mortgage ", link: "/canada-mortgage " },   
    { label: "About us", link: "/about-us" },
    { label: "Contact us", link: "/contact-us" }
  ];
  const [showSignIn, setShowSignIn] = useState(false);

  const handleClickSignIn = () => {
    setShowSignIn(!showSignIn);
  };
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  return (
    <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>
      <Box sx={{ height: '100% ', width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0, 
      padding: 0,display:{md: 'flex', sm: 'none',xs:'none' } }}>
<Header currentPage={currentPage}></Header>
        {/* <AppBar position="static" sx={{ bgcolor: "#0C276C", height: "72px", width: '100%' }}>
          <Box sx={{ display: "flex", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: "72px" }}>
            <Box sx={{ display: "flex", width: { md: '10%', sm: '12%' }, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <img src={logo} alt="" style={{ height: '85%', width: '90%', }} />
            </Box>
            <Box sx={{ display: "flex", width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left', paddingLeft: { md: "5%", sm: '0%' }, }}>
              {menus.map((menu) => (
                <Button
                key={menu.label}
                component={Link}
                to={menu.link}
                  sx={{
                     color: menu.label === currentPage ? '#939393' : 'white', paddingRight: { md: "7%", sm: '3%' }, textTransform: "capitalize", fontFamily: "poppins",
                    fontSize: "14px", lineHeight: "21px", fontWeight: "400", '&:hover': { color: "#939393", bgcolor: '#0C276C' }
                  }}>
                  {menu.label}
                </Button>
              ))}
            </Box>
            <Box sx={{ display: "flex", width: { md: '10%', sm: '17%' }, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
              <Button variant="contained" onClick={handleClickSignIn}
                sx={{
                  bgcolor: "#EB5648", color: "#fff", borderRadius: "16px", padding: " 4px, 10px, 4px, 10px", textTransform: "capitalize",
                  fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", width: "90%", height: "36px",
                  '&:hover': { bgcolor: "#fff", color: '#0C276C' }
                }} >
                Sign in
              </Button>
            </Box>

            {showSignIn && <SignIn />}

          </Box>
        </AppBar> */}
        </Box>
        <Box sx={{ display:{md: 'none', sm: 'flex',xs:'flex' }, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
          <HeaderMobile currentPage={currentPage}></HeaderMobile>
        {/* <AppBar position="static" sx={{ bgcolor: "#0C276C", height: "56" }}>


          <Toolbar disableGutters>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center', alignItems: 'center', paddingLeft: '18px',
              width:'30%' ,marginLeft:'2.5%'         }}>
            <img src={logo} alt=""  style={{ 

                  height: '100%',
                  width: '90%',
               
                }}
              />
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuToggle}

              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: showMenu ? "block" : "none", position: "absolute", top: "100%", width: "100vw", zIndex: 5,
                bgcolor: "#0C276C", justifyContent: 'flex-start', borderRadius: ' 0px 0px 8px 8px', paddingBottom: '20px'
              }}
            >
              {menus.map((menu) => (
                <Button onClick={handleMenuToggle}
                key={menu.label}
                component={Link}
                to={menu.link}
                  sx={{
                    display: "block", height: '40px', color: "white", textTransform: "capitalize", fontFamily: "poppins",
                    fontSize: "14px", lineHeight: "21px", fontWeight: "400", textAlign: 'left', borderRadius: '0px',
                    '&:hover': { background: "#CFDAF4", width: '100%',color:'#0C276C' }
                  }}
                >
                  {menu.label}
                </Button>
              ))}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '12px', marginLeft: 'auto', marginRight: '18px' }}>
              <Button
                variant="contained" onClick={handleClickSignIn}
                sx={{
                  bgcolor: "#EB5648", color: "#fff", borderRadius: "16px", gap: "10px",
                  alignContent: "center", padding: "4px 10px", textTransform: "capitalize", fontFamily: "poppins", fontSize: "14px",
                  lineHeight: "21px", fontWeight: "400", width: "88px", height: "28px", '&:hover': { bgcolor: "#fff",color:'#0C276C' }, ml: "auto"
                }}
              >
                Sign in
              </Button>
            
            </Box> 
            {showSignIn && <SignIn />}





          </Toolbar>


        </AppBar> */}
      </Box>
        <Box sx={{
        width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)',xs:'calc(100%-10%)' },  display: 'flex', flexDirection: 'row',height:'100%',
        marginLeft: { md: '16%', sm: '5%' , xs:'5%'}, marginRight: { md: '16%', sm: '5%' , xs:'5%'}, marginTop: { md: '10px', sm: '10px',xs:'25px' },
      
      }}>
  <Box sx={{
            display: 'flex', flexDirection: 'column', width:'100%', height:{ md: '100%', sm: '100%' , xs:'100%' },background:"#F9F9F9 ",paddingTop:'25px ',
             alignItems: 'center'
          }}>
             
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '16px',paddingLeft:'25px',paddingTop:{ md: '10px', sm: '10px' , xs:'0px'},
              lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
            }}>About us</Typography>
              <Box sx={{
            display: 'flex', flexDirection: 'column', width:'100%', height:{ md: '100%', sm: '100%' , xs:'100%' },background:"#F9F9F9 ",padding:'10px 25px 25px 25px',
             alignItems: 'center'
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '16px',paddingTop:'5px',
              lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Ratewaves is being proud while helping you to choose the right mortgage for you. Using our user-friendly interface,
             you can input essential details such as the property price, down payment, interest rate, and loan term to instantly discover
              your estimated monthly payments and total interest costs. Our calculator accounts for factors specific to the Canadian housing
               market, so you can trust the accuracy of the results.
           </Typography>
           <Typography sx={{
              fontFamily: 'poppins', fontSize: '16px',paddingTop:'5px',
              lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>
          But our commitment to your homeownership success doesn't end there. Our Canada Mortgage Calculator goes beyond the basics to
           help you explore different financial scenarios. Discover how making extra payments can save you money in the long run and analyze
            the impact of varying interest rates on your mortgage.
            </Typography>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '16px',paddingTop:'5px',
              lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>
           As your trusted partners, we are dedicated to supporting you throughout the process. Our team of experts is well-versed in the
            Canadian real estate landscape and mortgage market, ready to provide personalized guidance and answer any questions you may have
             along the way.
            </Typography>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '16px',paddingTop:'5px',
              lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>
        Whether you're a first-time homebuyer eager to find your perfect nest or a seasoned homeowner considering refinancing,
         our Canada Mortgage Calculator is here to empower you with knowledge. We believe that when you're equipped with the right information,
          you can confidently achieve your homeownership goals and build a solid foundation for your future.
            </Typography>
            </Box>
             </Box>




        </Box>
<Footer></Footer>
        </Grid>
  );

}

export default AboutUs;