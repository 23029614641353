import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Box, InputLabel, Stack, Typography } from '@mui/material';
import { useState , useEffect  } from 'react';
import DeleteIcon from './images/Component 4.png';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';
import axios from 'axios';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Tooltip from '@mui/material/Tooltip';
import '../App.css';
function debounce(func, delay) {
 
  let timerId;

  return function (...args) {
   
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

function Timer({ duration }) {
  const [seconds, setSeconds] = useState(duration);

  useEffect(() => {
    if (seconds > 0) {
      const timerInterval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1); // Decrement the seconds
      }, 1000); // Update the interval to 1 second

      return () => {
        clearInterval(timerInterval); // Clear the interval when the component unmounts or when seconds reach 0
      };
    }
  }, [seconds, duration]); // Add duration to the dependency array

  return (
    <span style={{ fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400' }}>
      {seconds > 0 ? `${seconds}S` : ''}
    </span>
  );
}
function formatValue(value) {
  const parsedValue = parseFloat(value);
  if (!isNaN(parsedValue)) {
    if (Number.isInteger(parsedValue)) {
      return parsedValue.toString(); // No fractional part, return as integer
    } else {
    //  const roundedValue = Math.round(parsedValue * 100) / 100; // Round to two decimal places
      return parsedValue.toFixed(2); // Format as string with two decimal places
    }
  } else {
    return 'Invalid input';
  }
}

function DivcomponenetUser(props) {

  const {  dataList, setDataList, mortgageName, CheckboxEnabled, isDeleteButtonEnabled,askingPriceValue,handleChangeTerm,handleChangeInitialPayment,
    currentSlideIndex, setCurrentSlideIndex, setCheckboxSelectedList, selectedCheckboxList, isSelected,setIsSelected,handleChangeFreequency,setTermVal,TermVal,
    handleChangeInterest,handleChangePeriod,handleChangeAskingprice, result,setResult, FreqParam,setFreqParam,amortznPeriod,setAmortznPeriod,setAskingPrice, askingprice,
    handleChangeMortgageAmount,mortgageAmountTextValue,setMortgageAmountTextValue,askingPriceVariableParent,setAskingPriceVariableParent,
    handleChangeDownPaymentPercentageParent,handleChangeDownPaymentAmountParent,insuranceTextValue, setInsuranceTextValue,mortgageAmountWithInsuranceValue,
     setMortgageAmountWithInsuranceValue,handleOnchangeAskingPrice,handleChangeAskingpriceWithInsurance ,downBorderAmount, setdownBorderAmount,
     downBorderPercentage, setdownBorderPercentage} = props;

     const index=dataList.findIndex(x => x.name ===mortgageName);
     const [showpopupTimerAmount, setShowPopupTimerAmount] = useState(false);
     const [showpopupTimerPercentage, setShowPopupTimerPercentage] = useState(false);
     const waitingDuration = 1; // in seconds
     const [showTimer, setShowTimer] = useState(false);
     const [popupTimer, setPopupTimer] = useState(null);
    //  const [amortizationMonthly, setAmortizationMonthly] = useState([]);
    //  const [termMonthly, setTermMonthly] = useState([]);
    
      // const [showPopuptermamort, setShowPopuptermamort] = useState(false);


     const handleAmountValueChange = (event, index) => {
      // Clear the previous popup timer, if any
      clearTimeout(popupTimer);
  
      // Show the popup
      setShowPopupTimerAmount(true);
  
      // Set a new timer to hide the popup after 5 seconds
      const newPopupTimer = setTimeout(() => {
        setShowPopupTimerAmount(false);
        // Call the handleChangeDownPaymentAmount function after the timer expires
        handleChangeDownPaymentAmount(event, index);
      }, 1000);
  
      // Store the timer ID
      setPopupTimer(newPopupTimer);
    };
    const handlePercentageValueChange = (event, index) => {
      // Clear the previous popup timer, if any
      clearTimeout(popupTimer);
  
      // Show the popup
      setShowPopupTimerPercentage(true);
  
      // Set a new timer to hide the popup after 5 seconds
      const newPopupTimer = setTimeout(() => {
        setShowPopupTimerPercentage(false);
        // Call the handleChangeDownPaymentAmount function after the timer expires
        handleChangeDownPaymentPercentage(event, index);
      }, 1000);
  
      // Store the timer ID
      setPopupTimer(newPopupTimer);
    };
   
    const [selectedOptionVariable, setSelectedOptionVariable] = useState(); //AmortizationPeriod Used this
    const [selectedOptionMonths, setSelectedOptionMonths] = useState( );
    const [selectedOptionMonthly, setSelectedOptionMonthly] = useState();
    const [showPopuptermamort, setShowPopuptermamort] = useState(false);
   // const [showPopupDownPer20, setShowPopupDownPer20] = useState(false);
    const [showPopupDownPer5, setShowPopupDownPer5] = useState(false);
    const [showPopupDownPer, setShowPopupDownPer] = useState(false);
    const [tooltipTitle, setTooltipTitle] = useState();
  
    const [tooltipTitlesMonth, setTooltipTitlesMonth] = useState(Array(dataList.length).fill(''));
    const [tooltipTitlestermMonth, setTooltipTitlestermMonth] = useState(Array(dataList.length).fill(''));
    const initialshowErrorBorder = Array(dataList.length).fill(false);
    const [showErrorBorder, setShowErrorBorder] = useState(initialshowErrorBorder);
    const initialshowErrorBorderterm = Array(dataList.length).fill(false);
    const [showErrorBorderterm, setShowErrorBorderterm] = useState(initialshowErrorBorderterm);
  const IsDeleteButton = dataList.length > 4 && isDeleteButtonEnabled;


  const handleCheckboxClickAddMortgage = (mortgageName) => {

    const temp = [...selectedCheckboxList];
    if (temp.includes(mortgageName)) {
      temp.splice(temp.indexOf(mortgageName), 1);
    }
    else {
      temp.push(mortgageName);
    }
    setCheckboxSelectedList(temp);

  };


  const [showPopup, setShowPopup] = useState(false);


  const handleDeleteClick = (mortgageName) => {
    setShowPopup(true);
  };

  const handleDeleteConfirm = (name) => {
    const temp = dataList.filter(dataList => dataList.name !== mortgageName);
    setDataList(temp);
    setShowPopup(false);
    dataList.length = (dataList.length - 1);
    

    if (currentSlideIndex === 4) {
      setCurrentSlideIndex(currentSlideIndex - 0);
    } else {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
    
   
  };
  console.log(currentSlideIndex)

  const handleCancel = () => {
    setShowPopup(false);
   
  };

  // const handleCancelDownPer20 = () => {
  //   setShowPopupDownPer20(false);
  //   dataList[index].DownPaymentPercentage =20;   
  //   dataList[index].DownPaymentAmount= minamnt20;
    
   
  // };
  const handleCancelTerm = () => {
    setShowPopuptermamort(false);
  };
  // const handleCancelDownPer5 = () => {
  //   setShowPopupDownPer5(false);
  //   dataList[index].DownPaymentPercentage=5;
  //   dataList[index].DownPaymentAmount=minamnt5;
  
    
  // };
  // const handleCancelDownPer = () => {
  //   setShowPopupDownPer(false);
  //   dataList[index].DownPaymentPercentage=minper;
  //   dataList[index].DownPaymentAmount=minamnt;
   
  // };
  const handleOptionChangeAmortization = (event,index) => {
    const AmortYear = parseInt(event.target.value)
    const AmortMonth = dataList[index].amortizationMonth
    var tempdataList=[...dataList];
    tempdataList[index].period=parseInt(event.target.value)  ;
    setDataList(tempdataList);
    setSelectedOptionVariable(event.target.value);
    var tempdataList=[...dataList];
    tempdataList[index].totalAmortization = AmortYear + (AmortMonth/12)  ;
    setDataList(tempdataList); 
   if (event.target.value !== '' && (dataList[index].totalAmortization < dataList[index].totalTerm )) {
    // setShowPopuptermamort(true);
    const newTooltipTitles = [...tooltipTitlesMonth];     
     newTooltipTitles[index] = 'The amortization period must be equal to or greater than the term';    
     setTooltipTitlesMonth(newTooltipTitles);    
     setShowErrorBorder((prevShowErrorBorder) => {
       const newShowErrorBorder = [...prevShowErrorBorder];
       newShowErrorBorder[index] = true;
       return newShowErrorBorder;
     });
   } 
  else if (event.target.value > 35 || event.target.value <= 0) {
     const newTooltipTitles = [...tooltipTitlesMonth];     
     newTooltipTitles[index] = 'Please enter a value between 1 and 35 ';    
     setTooltipTitlesMonth(newTooltipTitles);    
     setShowErrorBorder((prevShowErrorBorder) => {
       const newShowErrorBorder = [...prevShowErrorBorder];
       newShowErrorBorder[index] = true;
       return newShowErrorBorder;
     });
   } else {
     const newTooltipTitles = [...tooltipTitlesMonth];
     newTooltipTitles[index] = '';
     setTooltipTitlesMonth(newTooltipTitles);
     setShowErrorBorder((prevShowErrorBorder) => {
       const newShowErrorBorder = [...prevShowErrorBorder];
       newShowErrorBorder[index] = false;
       return newShowErrorBorder;
     });
     const newTooltipTitles1 = [...tooltipTitlesMonth];
    newTooltipTitles1[index] = '';
    setTooltipTitlestermMonth(newTooltipTitles1);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = false;
      return newShowErrorBorder; });
 
   }
    handleChangePeriod((AmortYear + (AmortMonth/12)),index)
  };

  const handleOptionChangeAmortizationMonth = (event,index) => {
    var tempdataList=[...dataList];
   tempdataList[index].amortizationMonth=parseInt(event.target.value)  ;
   setDataList(tempdataList);  
   const AmortYear = dataList[index].period
   const AmortMonth = event.target.value
   var tempdataList=[...dataList];
   tempdataList[index].totalAmortization=AmortYear + (AmortMonth/12)  ;
   setDataList(tempdataList); 
   if (event.target.value !== '' && (dataList[index].totalAmortization < dataList[index].totalTerm) ) {
    // setShowPopuptermamort(true);
    const newTooltipTitles = [...tooltipTitlesMonth];     
     newTooltipTitles[index] = 'The amortization period must be equal to or greater than the term';    
     setTooltipTitlesMonth(newTooltipTitles);    
     setShowErrorBorder((prevShowErrorBorder) => {
       const newShowErrorBorder = [...prevShowErrorBorder];
       newShowErrorBorder[index] = true;
       return newShowErrorBorder;
     });
   } 
   else {
    const newTooltipTitles = [...tooltipTitlesMonth];
    newTooltipTitles[index] = '';
    setTooltipTitlesMonth(newTooltipTitles);
    setShowErrorBorder((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = false;
      return newShowErrorBorder;
    });
    const newTooltipTitles1 = [...tooltipTitlesMonth];
    newTooltipTitles1[index] = '';
    setTooltipTitlestermMonth(newTooltipTitles1);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = false;
      return newShowErrorBorder; });
  }
   handleChangePeriod((AmortYear + (AmortMonth/12)),index)
 };

 const handleOptionChangeTermMonth = (event,index) => {
  
  var tempdataList=[...dataList];
  tempdataList[index].termMonth=parseInt(event.target.value)  ;
  setDataList(tempdataList);  
  const TermYear = dataList[index].term
  const TermMonth = event.target.value
  var tempdataList=[...dataList]
    tempdataList[index].totalTerm=TermYear + (TermMonth/12)  ;
    setDataList(tempdataList);
  if (dataList[index].totalAmortization < dataList[index].totalTerm) {
    // Show tooltip for amortization condition
    const newTooltipTitles = [...tooltipTitlesMonth];
    newTooltipTitles[index] = 'The amortization period must be equal to or greater than the term';
    setTooltipTitlestermMonth(newTooltipTitles);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = true;
      return newShowErrorBorder;
    });
  }
  else {
   
    const newTooltipTitles = [...tooltipTitlesMonth];
    newTooltipTitles[index] = '';
    setTooltipTitlestermMonth(newTooltipTitles);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = false;
      return newShowErrorBorder;
    });}
  handleChangeTerm((TermYear + (TermMonth/12)),index)
 
};

  const handleOptionChangePaymentFreequency = (event,index) => { 
   
    var tempdataList=[...dataList]
    tempdataList[index].frequency=(event.target.value)  ;
    setDataList(tempdataList);
    setSelectedOptionMonthly(event.target.value);
    
    setFreqParam(event.target.value);
   
    
  };
  const handleOptionChangeTerm = (event,index) => {
    const TermYear = parseInt(event.target.value)
    const TermMonth = dataList[index].termMonth
    var tempdataList=[...dataList]
    tempdataList[index].term=parseInt(event.target.value)  ;
    setDataList(tempdataList);
   // setSelectedOptionMonths(event.target.value);
   // setTermVal(event.target.value);
   var tempdataList=[...dataList]
    tempdataList[index].totalTerm=TermYear + (TermMonth/12)  ;
    setDataList(tempdataList);
   if (event.target.value !== '' && (dataList[index].totalAmortization < dataList[index].totalTerm)) {
    // Show tooltip for amortization condition
    const newTooltipTitles = [...tooltipTitlesMonth];
    newTooltipTitles[index] = 'The amortization period must be equal to or greater than the term';
    setTooltipTitlestermMonth(newTooltipTitles);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = true;
      return newShowErrorBorder;
    });
  } else if (event.target.value > 35 || event.target.value <= 0) {
    // Show tooltip for value range condition
    const newTooltipTitles = [...tooltipTitlesMonth];
    newTooltipTitles[index] = 'Please enter a value between 1 and 35';
    setTooltipTitlestermMonth(newTooltipTitles);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = true;
      return newShowErrorBorder;
    });
  } else {
    // Clear tooltips and errors if conditions are met
    const newTooltipTitles = [...tooltipTitlesMonth];
    newTooltipTitles[index] = '';
    setTooltipTitlestermMonth(newTooltipTitles);
    setShowErrorBorderterm((prevShowErrorBorder) => {
      const newShowErrorBorder = [...prevShowErrorBorder];
      newShowErrorBorder[index] = false;
      return newShowErrorBorder;
    });
  }
    handleChangeTerm((TermYear + (TermMonth/12)),index)
   
  };


const [downPaymentPerTextValue, setDownPaymentPerTextValue] = useState(); 
const [downPaymentAmountTextValue, setDownPaymentAmountTextValue] = useState(); 

 const [askingPriceTextValue, setAskingPriceTextValue] = useState([]);
 const [mortValValue, setMortValValue] = useState(800000);
 const [interestrateValue, setInterestrateValue] = useState(3);
 const [minamnt, setMinamnt] = useState();
 const [minamnt5, setMinamnt5] = useState();
 const [minamnt20, setMinamnt20] = useState();
 const [minper, setMinper] = useState();

 
 useEffect(() => {
  var tempdataList=[...dataList]
  tempdataList[index].askingprice=askingPriceValue  ; 
  tempdataList[index].DownPaymentAmount=askingPriceValue*(dataList.DownPaymentPercentage/100)
  setDataList(tempdataList); 
  (handleChangeAskingPriceParentText)(askingPriceValue,index)


}, [askingPriceValue]);
  

const handleChangeAskingPriceParentText =(askingPriceValue,index) => { 
  dataList[index].downBorderPercentage=(false);
    dataList[index].downBorderAmount=(false);
  var tempdataList=[...dataList]
  tempdataList[index].askingprice=parseInt(askingPriceValue)  ;   
  setDataList(tempdataList);
  let AmountValue1 =  dataList[index].DownPaymentAmount;
  const percentageValue =dataList[index].DownPaymentPercentage;
  if (askingPriceValue>= 1000000) {
     var percentage20=percentageValue;       
    if  (percentageValue < 20  ){
      const dwnamnt20=(dataList[index].askingprice*(20/100));
      setMinamnt20(dwnamnt20);
      AmountValue1=(dataList[index].askingprice*(20/100)).toFixed(2); 
        dataList[index].downBorderPercentage = true;
        dataList[index].downBorderAmount = true;
        setTooltipTitle(" The minimum down payment is 20% or " + dwnamnt20)
    //  setShowPopupDownPer20(true);
      handleChangeDownPaymentPercentageParent(20, index);
    
    } else {
     // setShowPopupDownPer20(false);
      setDownPaymentPerTextValue((percentageValue));
      var amount20=(dataList[index].askingprice*(percentage20/100));
      document.getElementById("Amount_"+index ).value=(amount20);
      handleChangeDownPaymentPercentageParent(percentageValue, index);
    }
  } 
  if (dataList[index].askingprice < 500000) {
    var percentage5=percentageValue;
    if  (percentageValue < 5 ){
      let dwnamnt5=(dataList[index].askingprice*(percentage5/100));
      setMinamnt5(dwnamnt5)       
      AmountValue1=(dataList[index].askingprice*(5/100)).toFixed(2)    
      dataList[index].downBorderPercentage = true;
      dataList[index].downBorderAmount = true;
       setTooltipTitle(" The minimum down payment is 5% or " + dwnamnt5)
     
      handleChangeDownPaymentPercentageParent(5, index);
      
    } else {
      setShowPopupDownPer5(false);
      setDownPaymentPerTextValue((percentageValue));
      var amount5=(dataList[index].askingprice*(percentage5/100));
      document.getElementById("Amount_"+index ).value=(amount5);
      handleChangeDownPaymentPercentageParent(percentageValue, index);
    }
  } 
  if ((dataList[index].askingprice > 500000 && dataList[index].askingprice < 1000000)) {
    var percentage=percentageValue;
    if  (percentageValue < (((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100){
      setMinper(((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2));
      var x=((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)         
      let mnamt=(dataList[index].askingprice * x)/100
      setMinamnt(mnamt);
      var DwnPer=((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)        
      var dwnamt=(500000*.05) + ((dataList[index].askingprice -500000)*(10/100))
       AmountValue1=(500000*.05) + ((dataList[index].askingprice -500000)*(10/100)).toFixed(2)
   dataList[index].downBorderPercentage = true;
   dataList[index].downBorderAmount = true;
    setTooltipTitle(" The minimum down payment is " + DwnPer + " or " + dwnamt)
     
      handleChangeDownPaymentPercentageParent((((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)), index);
    } else {
      setShowPopupDownPer(false);
      setDownPaymentPerTextValue(percentageValue);
      var amount=(dataList[index].askingprice*(percentageValue/100));
      document.getElementById("Amount_"+index ).value=(amount);
      handleChangeDownPaymentPercentageParent(percentageValue, index);
    }
  } 
  var mortamount=((dataList[index].askingprice)-(dataList[index].askingprice*(percentage/100)));   
  // console.log("mortamount",mortamount)
  // console.log("askingPriceTextValue",parseInt(dataList[index].askingprice*(percentage/100)))
  // console.log("downPaymentAmountTextValue",parseInt(downPaymentAmountTextValue))
  var mortgagewithhdmc= mortamount + insuranceTextValue;
  var tempdataList=[...dataList]
  tempdataList[index].mortgageamount=(dataList[index].askingprice - (dataList[index].askingprice*(percentageValue/100)))  + dataList[index].cmhc  ;   
  tempdataList[index].DownPaymentAmount = document.getElementById("Amount_"+index ).value;
tempdataList[index].DownPaymentPercentage =percentageValue;
  setDataList(tempdataList);
  console.log("mortgagewithhdmc.....",dataList)
   setMortValValue(mortgagewithhdmc);
   setMortgageAmountTextValue(mortValValue)

 }


  const handleChangeAskingPriceText =(event,index) => { 
    dataList[index].downBorderPercentage=(false);
    dataList[index].downBorderAmount=(false);
    var tempdataList=[...dataList]
    tempdataList[index].askingprice=event.target.value  ;   
    setDataList(tempdataList);
    let AmountValue1 =  dataList[index].DownPaymentAmount;
    const percentageValue = dataList[index].DownPaymentPercentage;
    if (event.target.value >= 1000000) {
       let percentage20=percentageValue;       
      if  (percentageValue < 20  ){
        let dwnamnt20=(dataList[index].askingprice*(20/100));        
        setMinamnt20(dwnamnt20);
        AmountValue1=(dataList[index].askingprice*(20/100)).toFixed(2); 
        dataList[index].downBorderPercentage = true;
        dataList[index].downBorderAmount = true;
        setTooltipTitle(" The minimum down payment is 20% or " + dwnamnt20)
        handleChangeDownPaymentPercentageParent(20, index);
      
      } else {
        dataList[index].downBorderPercentage = false;      
        setDownPaymentPerTextValue((percentageValue));
        var amount20=(dataList[index].askingprice*(percentage20/100));
        dataList[index].DownPaymentAmount=formatValue(amount20);
        handleChangeDownPaymentPercentageParent(percentageValue, index);
      }
    } 
    if (event.target.value <= 500000) {
      var percentage5=percentageValue;
      if  (percentageValue < 5 ){
        let dwnamnt5=(dataList[index].askingprice*(5/100));
        setMinamnt5(dwnamnt5)  
        AmountValue1=(dataList[index].askingprice*(5/100)).toFixed(2)    
        dataList[index].downBorderPercentage = true;
        dataList[index].downBorderAmount = true;
         setTooltipTitle(" The minimum down payment is 5% or " + dwnamnt5)
        handleChangeDownPaymentPercentageParent(5, index);
        
      } else {
       dataList[index].downBorderPercentage = false;
        setDownPaymentPerTextValue((percentageValue));
        var amount5=(dataList[index].askingprice*(percentage5/100));
        dataList[index].DownPaymentAmount=formatValue(amount5);
        handleChangeDownPaymentPercentageParent(percentageValue, index);
      }
    } 
    if ((dataList[index].askingprice > 500000 && dataList[index].askingprice < 1000000)) {
      var percentage=percentageValue;
      if  (percentageValue < (((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100){
        setMinper(((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2));
        var x=((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)         
        let mnamt=(dataList[index].askingprice * x)/100
        setMinamnt(mnamt);
          var DwnPer=((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)        
          var dwnamt=(500000*.05) + ((dataList[index].askingprice -500000)*(10/100))
           AmountValue1=(500000*.05) + ((dataList[index].askingprice -500000)*(10/100)).toFixed(2)
       dataList[index].downBorderPercentage = true;
       dataList[index].downBorderAmount = true;
        setTooltipTitle(" The minimum down payment is " + DwnPer + " or " + dwnamt)
        handleChangeDownPaymentPercentageParent((((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)), index);
      } else {
         dataList[index].downBorderPercentage = false;
        setDownPaymentPerTextValue(percentageValue);
        let amount=(dataList[index].askingprice*(percentageValue/100)).toFixed(2);
        dataList[index].DownPaymentAmount=formatValue(amount);
        handleChangeDownPaymentPercentageParent(percentageValue, index);
      }
    }
    var mortamount=((dataList[index].askingprice)-(dataList[index].askingprice*(percentage/100)));
    var mortgagewithhdmc= mortamount + insuranceTextValue;
    var tempdataList1=[...dataList]
    tempdataList1[index].mortgageamount=formatValue((dataList[index].askingprice - (dataList[index].askingprice*(percentageValue/100)))  + dataList[index].cmhc)  ;   
    tempdataList1[index].DownPaymentAmount =formatValue(AmountValue1);     
 tempdataList1[index].DownPaymentPercentage =formatValue(percentageValue);
    setDataList(tempdataList1);
    console.log("mortgagewithhdmc.....",dataList)
     setMortValValue(mortgagewithhdmc);
     setMortgageAmountTextValue(mortValValue)

   }


   const handleChangeInterstRate =(event,index) => { 
  
    var tempdataList=[...dataList]
    tempdataList[index].rate= event.target.value ;    
    setDataList(tempdataList);
    // var tempdataList=[...dataList];
    // tempdataList[index].rateMonth=(event.target.value / 12).toFixed(4) ;
    // setDataList(tempdataList);  
    handleChangeInterest(dataList[index].rate, index)
   }
   const handleChangeInterstRateMonth =(event,index) => { 
   var tempdataList=[...dataList];
   tempdataList[index].rateMonth=event.target.value ;
   setDataList(tempdataList);  
   
    const annual = event.target.value * 12
    var tempdataList=[...dataList]
    tempdataList[index].rate=parseFloat(annual)  ;    
    setDataList(tempdataList);
    handleChangeInterest(dataList[index].rate, index)
   } 
     
   
   const handleChangeInitialPaymentText =(event,index) => {  
    
   // setInitialPaymentTextValue(event.target.value);
  //   var mortamount=(parseInt(askingPriceTextValue)-parseInt(event.target.value));
   
  //  setMortValValue(mortamount);
   setMortgageAmountTextValue(mortValValue);
 

  }
  const handleChangeInterestText =(event,index) => { 
     
    setInterestrateValue(event.target.value);
   
  
    } 
 
   
    


    var percent='';
    const ref= useRef()
    var dwnamount='';
    const refamnt= useRef()

    const handleChangeDownPaymentPercentage = (event, index) => {      
      dataList[index].downBorderPercentage=(false);
    
      let percentageValue =(event.target.value);
      let AmountValue
      
        const newValue = parseInt(event.target.value); // Convert input value to integer
    
        if (newValue >=  100) {  
          dataList[index].DownPaymentPercentage = newValue     
          dataList[index].downBorderPercentage = true  ;
          setTooltipTitle("Please enter a value less than 100");
        }
     else{
      if (dataList[index].askingprice >= 1000000) {
         var percentage20=percentageValue;       
        if  (percentageValue < 20  ){         
          const dwnamnt20=dataList[index].askingprice*(20/100);
          AmountValue=dataList[index].askingprice*(20/100).toFixed(2);
          setMinamnt20(dwnamnt20);         
          dataList[index].downBorderPercentage = true  ;    
          
         
          setTooltipTitle(" The minimum down payment percentage is 20% ");
          handleChangeDownPaymentPercentageParent(20, index);
        
        
        } else {
          dataList[index].downBorderPercentage=(false);
          setDownPaymentPerTextValue((percentageValue));
          var amount20=dataList[index].askingprice*(percentage20/100);             
         dataList[index].DownPaymentAmount=formatValue(amount20)
         // document.getElementById("Amount_"+index ).value=(amount20).toFixed(2);
          handleChangeDownPaymentPercentageParent(percentageValue, index);
        }
      } 
      if (dataList[index].askingprice <=500000) {
        var percentage5=percentageValue;
        if  (percentageValue < 5 || percentageValue === 5){
          var downamnt5=(dataList[index].askingprice*(5/100));
          setMinamnt5(downamnt5)
         // AmountValue=(dataList[index].askingprice*(5/100)).toFixed(2)
          dataList[index].downBorderPercentage = true  ; 
          setTooltipTitle(" The minimum down payment is 5% ")
          handleChangeDownPaymentPercentageParent(5, index);
          
        } else {
          dataList[index].downBorderPercentage=(false);
          setDownPaymentPerTextValue((percentageValue));
          var amount5=(dataList[index].askingprice*(percentage5/100));        
         dataList[index].DownPaymentAmount=formatValue(amount5);
          handleChangeDownPaymentPercentageParent(percentageValue, index);
        }
      } 
      if ((dataList[index].askingprice > 500000 && dataList[index].askingprice < 1000000)) {
        var percentage=percentageValue;
        if  (percentageValue < ((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)){
          setMinper(((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2));
          var DwnPer=(((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100)).toFixed(2)
         // var x=((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(1)         
         // let mnamt=(dataList[index].askingprice*x)/100
       
           AmountValue=((500000*.05) + ((dataList[index].askingprice -500000)*(10/100))).toFixed(2)
          var dwnamt=(500000*.05) + ((dataList[index].askingprice -500000)*(10/100))
          setMinamnt(dwnamt);
          dataList[index].downBorderPercentage = true  ; 
          setTooltipTitle(" The minimum down payment is " + DwnPer  )
         
          handleChangeDownPaymentPercentageParent((((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(1)), index);
        } else {
          dataList[index].downBorderPercentage=(false);
          setDownPaymentPerTextValue(percentageValue);
          var amount=(dataList[index].askingprice*(percentageValue/100)).toFixed(2);           
         dataList[index].DownPaymentAmount=formatValue(amount);
          handleChangeDownPaymentPercentageParent(percentageValue, index);
        }
      } 
      var mortamount=((dataList[index].askingprice)-(dataList[index].askingprice*(percentage/100)));  
     
      var mortgagewithhdmc= mortamount + insuranceTextValue;
    
      var tempdataList=[...dataList]
      tempdataList[index].mortgageamount=formatValue((dataList[index].askingprice - (dataList[index].askingprice*(percentageValue/100)))  + dataList[index].cmhc ) ;   
      //tempdataList[index].downBorderPercentage=true  ; 
     
    //  tempdataList[index].DownPaymentAmount = formatValue(AmountValue);
   tempdataList[index].DownPaymentPercentage =(percentageValue);
      setDataList(tempdataList);
      console.log("mortgagewithhdmc.....",dataList)
       setMortValValue(mortgagewithhdmc);
       setMortgageAmountTextValue(mortValValue)
    }

       //setShowTimer(false);
      }



  const handleChangeDownPaymentAmount =(event,index) => { 
    setdownBorderAmount(false)
    let downAmountValue=event.target.value;
    let downPercentValue
    const newValue = parseInt(event.target.value); // Convert input value to integer
    
    if (newValue >=  dataList[index].askingprice) {  
      dataList[index].DownPaymentAmount = newValue     
      dataList[index].downBorderAmount = true  ;
      setTooltipTitle("Please enter a value less than the Purchase price");
    }
 else{


 if (dataList[index].askingprice >= 1000000) {     
      if  (downAmountValue < (dataList[index].askingprice*20)/100){          
        let mnamt20=(dataList[index].askingprice*20)/100;
        setMinamnt20(mnamt20)       
        dataList[index].downBorderAmount = true  ; 
        downPercentValue=dataList[index].DownPaymentPercentage
        setTooltipTitle(" The minimum down payment is  " + mnamt20)
        handleChangeDownPaymentAmountParent(200000, index);
       
      } else {
        dataList[index].downBorderAmount = false  ; 
        setDownPaymentAmountTextValue(downAmountValue);
        var val20=((downAmountValue*100)/dataList[index].askingprice);
        dataList[index].DownPaymentPercentage=(val20.toFixed(2));
        downPercentValue=val20.toFixed(2);
        handleChangeDownPaymentAmountParent(downAmountValue, index);
        
      }
    } 
    if (dataList[index].askingprice <= 500000) {     
      //var downAmount5=(downAmountValue*100)/dataList[index].askingprice;
      if  (downAmountValue < (dataList[index].askingprice*5)/100 ){
        let mnamt5=(dataList[index].askingprice*5)/100;
        setMinamnt5(mnamt5)
        downPercentValue=dataList[index].DownPaymentPercentage
        dataList[index].downBorderAmount = true  ; 
        setTooltipTitle(" The minimum down payment is  " + mnamt5)
        handleChangeDownPaymentAmountParent(25000, index);
       
      } else {
        dataList[index].downBorderAmount = false  ; 
        setDownPaymentAmountTextValue(downAmountValue);
        var downAmountValue5=(downAmountValue*100)/dataList[index].askingprice;     
        dataList[index].DownPaymentPercentage=(downAmountValue5).toFixed(2);
        downPercentValue=downAmountValue5.toFixed(2);
        handleChangeDownPaymentAmountParent(downAmountValue, index);
      }
    } 
    if ((dataList[index].askingprice >500000 && dataList[index].askingprice < 1000000)) {
      var downAmount=downAmountValue;
      if  ((downAmountValue < (500000*.05) + ((dataList[index].askingprice -500000)*(10/100)) )){
        var Dwnamnt=(500000*.05) + ((dataList[index].askingprice -500000)*(10/100))
        setMinamnt(Dwnamnt );  
        var DwnPer= ((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2) ;       
        setMinper(((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2))
      //  downPercentValue=DwnPer
      downPercentValue=dataList[index].DownPaymentPercentage
        dataList[index].downBorderAmount = true  ; 
        setTooltipTitle(" The minimum down payment is "  + Dwnamnt)
        handleChangeDownPaymentAmountParent((500000*.05) + ((dataList[index].askingprice -500000)*(10/100)) , index);
      } else {
        dataList[index].downBorderAmount = false  ; 
        setDownPaymentAmountTextValue(downAmountValue);
        var val=((downAmount*100)/dataList[index].askingprice);
        dataList[index].DownPaymentPercentage=(val.toFixed(2)); 
        handleChangeDownPaymentAmountParent(downAmountValue, index);
      }
    }  
   setDownPaymentAmountTextValue(downAmountValue);
   let mortamount=((dataList[index].askingprice)-(downAmountValue));  
   let mortgagewithhdmc= mortamount + insuranceTextValue;  
   var tempdataList=[...dataList]
   tempdataList[index].mortgageamount=formatValue(mortgagewithhdmc)  ; 
   tempdataList[index].DownPaymentAmount =(downAmountValue);
   tempdataList[index].DownPaymentPercentage =formatValue(downPercentValue);;
  setDataList(tempdataList);
  setMortValValue(mortgagewithhdmc);
  setMortgageAmountTextValue(mortValValue)
  }

  }
 
   const handleChangeInsuranceAmount =(event,index) => { 
     
    setInsuranceTextValue(event.target.value);
   
  
    } 

    // useEffect(() => {
    //   document.getElementById("Percent_"+index ).value = parseInt(dataList[index].DownPaymentPercentage);
    //  // document.getElementById("Amount_"+index ).value =dataList[index].DownPaymentAmount ;
     
     
        
    //   },[dataList[index].DownPaymentAmount,dataList[index].DownPaymentPercentage] );

    useEffect(() => {      
   
      handleChangeAskingpriceWithInsurance(dataList[index].askingprice,index);     
        
      }, [dataList[index].askingprice]);
  
   



const handleMouseEnter = (index) => {
  var tempdataList=[...dataList]
  tempdataList[index].isSelected=true  ;    
  setDataList(tempdataList);
};

const handleMouseLeave = (index) => {
  var tempdataList=[...dataList]
  tempdataList[index].isSelected=false  ;    
  setDataList(tempdataList); 
};

const renderDisplayValue = (value) => {
  switch (value) {
    case 'M':
      return 'Monthly';
    case 'S':
      return 'Semi-monthly';
    case 'W':
      return 'Weekly';
    case 'B':
      return 'Bi-weekly';
    case 'A':
      return 'Acc* weekly';
    case 'Z':
      return 'Acc* Bi-weekly';
    default:
      return value;
  }
};

const [isFocused, setIsFocused] = useState(false);
const [isFocusedTerm, setIsFocusedTerm] = useState(false);
const handleFocus = () => {
  // Set isFocused to true when the TextField is focused
  setIsFocused(true);
};

const handleBlur = () => {
  // Set isFocused to false when the TextField loses focus
  setIsFocused(false);
};
const handleFocusTerm = () => {
  // Set isFocused to true when the TextField is focused
  setIsFocusedTerm(true);
};

const handleBlurTerm = () => {
  // Set isFocused to false when the TextField loses focus
  setIsFocusedTerm(false);
};


  return (


    <Box key={mortgageName} sx={{
      display: 'flex', flexDirection: "column", border:dataList[index].isSelected || selectedCheckboxList.includes(mortgageName)
       ?"1px solid #0C276C !important" : "1px solid #CFC9C9", width:'98.5%',padding:'10%',
      height:{ md: "460px", sm: "460px",xs:'550px' } ,  alignItems: "center", borderRadius: "8px", gap: '4%', justifyContent: 'center',
      background: dataList[index].isSelected || selectedCheckboxList.includes(mortgageName) ? "#F9F9F9" : "white",
    }} 
     onMouseEnter={() => handleMouseEnter(index)}
    onMouseLeave={() => handleMouseLeave(index)}
    >

{IsDeleteButton &&<Box sx={{ display: 'flex', flexDirection: "row",position: "relative",
     width:'100%',height:'0px',justifyContent:'right',alignItems:'right',
    right:0 ,marginTop:'-25px',marginRight:'-60px' }}> 
      <IconButton onClick={() => handleDeleteClick(mortgageName)} aria-label="delete" size="small">
        <img src={DeleteIcon} alt="delete" sx={{ }} />
      </IconButton></Box>}





      <Typography sx={{
        color: "#626262", textAlign: "center", padding: { md: "0px 10px 0px 10px", sm:"0px 0px 0px 0px",xs:"0px 0px 0px 0px" } ,
         fontFamily: "poppins", fontSize:  { md: '14px', sm: '14px',xs:'12px' }, lineHeight: "21px",marginTop:'-7px',
         fontWeight: "600", color: '#0C276C' ,
      }}>{mortgageName}
      </Typography>
     
      
      <TextField key={index}   value={isNaN(dataList[index].askingprice) ? '' : String(dataList[index].askingprice)}  onChange={(event) =>{ 
      handleChangeAskingPriceText(event,index);  handleChangeAskingpriceWithInsurance(event.target.value,index)}}
       variant="outlined" sx={{
        width: "100%", fontFamily: "poppins",
      fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", '& input': {
         padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
        },
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262", fontFamily: "poppins",
        fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", },type:'number',
          '& label': {
            display: 'flex', alignItems: 'center',
            justifyContent: 'center'
          }
        }} /> 

     
     
 {dataList[index].downBorderPercentage ? (
    
    <Tooltip
     classes={{ tooltip: "custom-tooltip" }}
      title={tooltipTitle} 
      placement="top-start"
      arrow
      disableHoverListener = {!dataList[index].downBorderPercentage}
    >
   <TextField   variant="outlined" placeholder="20"  value={(dataList[index].DownPaymentPercentage)}
   onChange={(event) =>{ (handleChangeDownPaymentPercentage)(event,index)}}
    // onChange={(event) =>{ (handleAmountValueChange)(event,index)}}
      sx={{
      width: "100%", fontFamily: "poppins",
    fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", '& input': {
       padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
      },
    }}
      InputProps={{
        sx: { height: '28px', color: " #626262" , fontFamily: "poppins",border: dataList[index].downBorderPercentage ? '1px solid red !important' : '',
    fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",},type:'number',
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }, 
                 
      }}
      
      />
        </Tooltip>
    ) : (
      <TextField   variant="outlined" placeholder="20"   value={(dataList[index].DownPaymentPercentage)}
   onChange={(event) =>{ (handleChangeDownPaymentPercentage)(event,index)}}
    // onChange={(event) =>{ (handleAmountValueChange)(event,index)}}
      sx={{
      width: "100%", fontFamily: "poppins",
    fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", '& input': {
       padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
      },
    }}
      InputProps={{
        sx: { height: '28px', color: " #626262" , fontFamily: "poppins",border: dataList[index].downBorderPercentage ? '1px solid red !important' : ' ',
        borderRadius: dataList[index].downBorderPercentage ? '4px !important' : '1px solid #CFC9C9 ',
    fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",},type:'number',
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }, 
                       
      }}
         
      /> )
      }

    {dataList[index].downBorderAmount ? (
    
      <Tooltip
       classes={{ tooltip: "custom-tooltip" }}
        title={tooltipTitle} 
        placement="top-start"
        arrow
        disableHoverListener = {!dataList[index].downBorderAmount}
      >
      <TextField id={"Amount_"+index }  placeholder="200000"  ref={refamnt}    value={formatValue(dataList[index].DownPaymentAmount)}
        onChange={(event) =>{ handleChangeDownPaymentAmount(event,index)}}
      // onChange={(event) =>{ (handleAmountValueChange)(event,index)}}
        sx={{
        width: "100%", fontFamily: "poppins",
      fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", '& input': {
         padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
        },
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262" , fontFamily: "poppins",border: dataList[index].downBorderAmount ? '1px solid red !important' : '',

      fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",},type:'number',
          '& label': {
            display: 'flex', alignItems: 'center',
            justifyContent: 'center'
          }, 
                   
        }}
        
        />
          </Tooltip>
      ) : (
        <TextField id={"Amount_"+index }  placeholder="200000"  ref={refamnt}    value={formatValue(dataList[index].DownPaymentAmount)}
        onChange={(event) =>{ handleChangeDownPaymentAmount(event,index)}}
      // onChange={(event) =>{ (handleAmountValueChange)(event,index)}}
        sx={{
        width: "100%", fontFamily: "poppins",
      fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", '& input': {
         padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
        },
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262" , fontFamily: "poppins",border: dataList[index].downBorderAmount ? '1px solid red !important' : ' ',
          borderRadius: dataList[index].downBorderAmount ? '4px !important' : '1px solid #CFC9C9 ',
      fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",},type:'number',
          '& label': {
            display: 'flex', alignItems: 'center',
            justifyContent: 'center'
          }, 
                         
        }}
           
        /> )
        }

<TextField  variant="outlined" 
       value={isNaN(dataList[index].cmhc) ? '' : String(dataList[index].cmhc)}
       
       sx={{
        width:'100%', fontFamily: "poppins",fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",
        border: showErrorBorder[index] ? '1px solid red' : '',borderRadius:'4px',pointerEvents:'none', '& input': {
         padding:{ md: '12px', sm: '6px',xs:'8px' }, // Override padding for the input element
        },
       
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262" ,fontFamily: "poppins",
          fontSize: "12px ", lineHeight: "18px", fontWeight: "400", },type:"number",'& label': {
            display: 'flex', alignItems: 'center', justifyContent: 'center',
             border : showErrorBorder[index] ? '1px solid red !important' : '',
          }
        }}
           /> 
         {/* <TextField id={index}  
        sx={{
          width: "100%", fontFamily: "poppins",fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",border:'1px solid #CFC9C9 ',
          height: '28px', color: " #626262",borderRadius:'4px', display: 'flex', alignItems: 'center',  justifyContent: 'left',padding: "0px 10px 0px 10px",
        }} > 
            {isNaN(dataList[index].cmhc) ? '' : formatValue(dataList[index].cmhc)}
        </TextField> */}
      {/* <TextField     
      sx={{
        width: "100%", fontFamily: "poppins",fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",border:'1px solid #CFC9C9 ',
        height: '28px', color: " #626262",borderRadius:'4px', display: 'flex', alignItems: 'center',  justifyContent: 'left',padding: "0px 10px 0px 10px",
      }} >
         {isNaN(dataList[index].mortgageamount) ? '' : String(dataList[index].mortgageamount)} 
       
        </TextField> */}
        <TextField  variant="outlined" 
       value={isNaN(dataList[index].mortgageamount) ? '' : String(dataList[index].mortgageamount)}
       
       sx={{
        width:'100%', fontFamily: "poppins",fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",
        border: showErrorBorder[index] ? '1px solid red' : '',borderRadius:'4px',pointerEvents:'none', '& input': {
         padding:{ md: '12px', sm: '6px',xs:'8px' }, // Override padding for the input element
        },
       
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262" ,fontFamily: "poppins",
          fontSize: "12px ", lineHeight: "18px", fontWeight: "400", },type:"number",'& label': {
            display: 'flex', alignItems: 'center', justifyContent: 'center',
             border : showErrorBorder[index] ? '1px solid red !important' : '',
          }
        }}/>
      
         <Box sx={{ display: 'flex',gap:'5px',maxWidth:'100% !important',flexDirection:{ md: 'row', sm: 'row',xs:'column' }  }}>  
 
        <Tooltip
       classes={{ tooltip: "custom-tooltip" }}
        title={tooltipTitlesMonth[index]}
        placement="top-start"
        arrow
        disableHoverListener = {!dataList[index].period}
      >
       <TextField  placeholder={isFocused ? '' : '25 Y'} variant="outlined" 
       value={isNaN(dataList[index].period) ? '' : String(dataList[index].period)}
       
       sx={{
        width:{ md :'calc(50% - 3px)', sm: 'calc(50% - 3px)',xs:'100%' }, fontFamily: "poppins",fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",
        border: showErrorBorder[index] ? '1px solid red' : '',borderRadius:'4px', '& input': {
         padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
        },
        '& ::placeholder': {
          color: '#626262' ,opacity:1
        }
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262" ,fontFamily: "poppins",
          fontSize: "12px ", lineHeight: "18px", fontWeight: "400", },type:"number",'& label': {
            display: 'flex', alignItems: 'center', justifyContent: 'center',
             border : showErrorBorder[index] ? '1px solid red !important' : '',
          }
        }}onChange={(event) =>{ handleOptionChangeAmortization(event,index);
         }}  onFocus={handleFocus}
         onBlur={handleBlur}   /> 
          </Tooltip>
      <Select value={dataList[index].amortizationMonth} onChange={(event) =>{ handleOptionChangeAmortizationMonth(event,index); }}  
        sx={{
          width:{ md  :'calc(50% + 1px)', sm:  'calc(50% + 1px)',xs:'100%' },height: '28px', fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', 
          color: '#626262', '& .MuiSelect-select': {
           padding:{ md: '12px', sm: '6px',xs:'8px' }// Set padding to 0 for the select input
          }, // Override padding for the input element
          '& .MuiSelect-icon': {
            right: 0,// Set padding to 0 for the select input
          }, 

        }}
        displayEmpty
        MenuProps={{
          
          PaperProps: {
            style: {
              onMouseLeave: () => setSelectedOptionVariable(''), background: " #F8F8F8", borderRadius: '8px',marginTop:'5px', maxHeight: '250px',
               overflowY: 'auto',
              
            },
            
          },
        }}
      >
        {/* <MenuItem  style={{ display: 'none' }}>  Months  </MenuItem> */}
        <MenuItem value={0} sx={{
          backgroundColor: selectedOptionVariable === 0 ? '#0C276C !important' : 'transparent',
          color: selectedOptionVariable === 0 ? '#ffffff' : '#626262', fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px',
          fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',
        minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(0)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          0 M  </MenuItem> 
        <MenuItem value={1} sx={{
          backgroundColor: selectedOptionVariable === 1 ? '#0C276C !important' : 'transparent',
          color: selectedOptionVariable === 1 ? '#ffffff' : '#626262', fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px',
          fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(1)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          1 M  </MenuItem>
        <MenuItem value={2} sx={{
          backgroundColor: selectedOptionVariable === 2 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 2 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', 
          marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(2)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          2 M </MenuItem>
        <MenuItem value={3} sx={{
          backgroundColor: selectedOptionVariable === 3 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 3 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(3)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         3 M  </MenuItem>
         <MenuItem value={4} sx={{
          backgroundColor: selectedOptionVariable === 4 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 4 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(4)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          4 M </MenuItem>
        <MenuItem value={5} sx={{
          backgroundColor: selectedOptionVariable === 5 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 5 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(5)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         5 M  </MenuItem>
         <MenuItem value={6} sx={{
          backgroundColor: selectedOptionVariable === 6 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 6 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(6)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          6 M </MenuItem>
        <MenuItem value={7} sx={{
          backgroundColor: selectedOptionVariable === 7 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 7 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(7)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         7 M  </MenuItem>
         <MenuItem value={8} sx={{
          backgroundColor: selectedOptionVariable === 8 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 8 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(8)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          8 M </MenuItem>
        <MenuItem value={9} sx={{
          backgroundColor: selectedOptionVariable === 9 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 9 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(9)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         9 M  </MenuItem>
         <MenuItem value={10} sx={{
          backgroundColor: selectedOptionVariable === 10 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 10 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(10)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          10 M </MenuItem>
        <MenuItem value={11} sx={{
          backgroundColor: selectedOptionVariable === 15 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 15 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(15)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         11 M  </MenuItem>
         <MenuItem value={12} sx={{
          backgroundColor: selectedOptionVariable === 20 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 20 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(20)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          12 M </MenuItem>
       
      </Select>
     </Box>

      {/* <TextField id="outlined-basic" value={isNaN(dataList[index].rate) ? '' : String(dataList[index].rate)} placeholder="3" variant="outlined" sx={{
        width: "100%", fontFamily: "poppins",fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262", fontFamily: "poppins",
      fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", }, '& label': {
            display: 'flex', alignItems: 'center', justifyContent: 'center', '& input': {
             padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
            },
          }
        }}onChange={(event) =>{ handleChangeInterest(event, index);}} /> */}
       
     <TextField   variant="outlined" value={isNaN(dataList[index].rate) ? '' : String(dataList[index].rate)}
      sx={{
       width: '100%', fontFamily: "poppins",  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px",
        fontWeight: "400", '& input': {
        padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
       },
     }}
       InputProps={{
         sx: { height: '28px', color: " #626262" ,fontFamily: "poppins",
         fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", },type:"number",'& label': {
           display: 'flex', alignItems: 'center', justifyContent: 'center'
         }
       }}onChange={(event) => handleChangeInterstRate(event, index)} />   

{/* <TextField  variant="outlined" value={dataList[index].rateMonth}
      sx={{
       width:'100%', fontFamily: "poppins",  fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px",
        fontWeight: "400", '& input': {
        padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
       },
     }}
       InputProps={{
         sx: { height: '28px', color: " #626262" ,fontFamily: "poppins",
         fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", },type:"number",'& label': {
           display: 'flex', alignItems: 'center', justifyContent: 'center'
         }
       }}onChange={(event) => handleChangeInterstRateMonth(event, index)} />    */}
       
  
      <Select value={dataList[index].frequency} 
      onChange={(event) =>{ handleOptionChangePaymentFreequency(event, index);handleChangeFreequency(event,index)}}
      renderValue={(selected) => renderDisplayValue(selected)}
        sx={{
          width: '100%', height: '28px', fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px',
           fontWeight: '400', color: '#626262', '& .MuiSelect-select': {
           padding:{ md: '12px', sm: '6px',xs:'8px' }// Set padding to 0 for the select input
          }, // Override padding for the input element
          '& .MuiSelect-icon': {
            right: 0,// Set padding to 0 for the select input
          }, 
        }}
        displayEmpty MenuProps={{
          PaperProps: {
            style: {
              onMouseLeave: () => setSelectedOptionMonthly(''), background: " #F8F8F8 ", borderRadius: '8px',marginTop:'5px',
            },
          },
        }}
      >
         <MenuItem  sx={{ display: 'none' }}>Monthly </MenuItem>   
        <MenuItem value={"M"}  sx={{
          backgroundColor: selectedOptionMonthly === "M" ? '#0C276C !important' : 'transparent', color: selectedOptionMonthly ==="M" ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionMonthly('M')}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Monthly</MenuItem>
        <MenuItem value={"S"} sx={{
          backgroundColor: selectedOptionMonthly === "S" ? '#0C276C !important' : 'transparent', color: selectedOptionMonthly === "S" ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionMonthly("S")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Semi-monthly</MenuItem>
           <MenuItem value={"W"} sx={{
          backgroundColor: selectedOptionMonthly === "W" ? '#0C276C !important' : 'transparent', color: selectedOptionMonthly === "W" ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionMonthly("W")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Weekly</MenuItem>
           <MenuItem value={"B"} sx={{
          backgroundColor: selectedOptionMonthly === "B" ? '#0C276C !important' : 'transparent', color: selectedOptionMonthly === "B" ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionMonthly("B")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Bi-weekly</MenuItem>
           <MenuItem value={"A"} sx={{
          backgroundColor: selectedOptionMonthly === "A" ? '#0C276C !important' : 'transparent', color: selectedOptionMonthly === "A" ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionMonthly("A")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Accelerated weekly</MenuItem>
           <MenuItem value={"Z"} sx={{
          backgroundColor: selectedOptionMonthly === "Z" ? '#0C276C !important' : 'transparent', color: selectedOptionMonthly === "Z" ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionMonthly("Z")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Accelerated Bi-weekly</MenuItem>

      </Select>

      <Box sx={{display:'flex', flexDirection:{ md: 'row', sm: 'row',xs:'column' },width:'100%',gap:'5px'}}>
      <Tooltip
       classes={{ tooltip: "custom-tooltip" }}
        title={tooltipTitlestermMonth[index]}
        placement="top-start"
        arrow
        disableHoverListener = {!dataList[index].term}
      >
      <TextField  placeholder={isFocusedTerm ? '' : '5 Y'} variant="outlined" value={isNaN(dataList[index].term) ? '' : String(dataList[index].term)}
       sx={{
        width:{ md:"100%", sm:"100%",xs:'100%' } , fontFamily: "poppins",fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400",
        border: showErrorBorderterm[index] ? '1px solid red' : '',borderRadius:'4px',height: '28px', '& input': {
         padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
        },
        '& ::placeholder': {
          color: '#626262' ,opacity:1
        }
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262" ,fontFamily: "poppins",
          fontSize: "12px ", lineHeight: "18px", fontWeight: "400", },type:"number",'& label': {
            display: 'flex', alignItems: 'center', justifyContent: 'center', 
            border: showErrorBorderterm[index] ? '1px solid red' : '',borderRadius:'4px'
           
          }
        }}onChange={(event) =>{ handleOptionChangeTerm(event,index);}} 
        onFocus={handleFocusTerm}
        onBlur={handleBlurTerm}/>   
        </Tooltip>
       
        <Select value={isNaN(dataList[index].termMonth) ? '' : String(dataList[index].termMonth)} onChange={(event) =>{ handleOptionChangeTermMonth(event, index); }}  
        sx={{
          width: { md:'calc(50% + 1px)', sm:'calc(50% + 1px)',xs:'100%' },height: '28px', fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' },
           lineHeight: '18px', fontWeight: '400', 
          color: '#626262', '& .MuiSelect-select': {
           padding:{ md: '12px', sm: '6px',xs:'8px' }// Set padding to 0 for the select input
          }, // Override padding for the input element
          '& .MuiSelect-icon': {
            right: 0,// Set padding to 0 for the select input
          }, 
        
        }}
        displayEmpty       
        MenuProps={{          
          PaperProps: {
            sx: {
              onMouseLeave: () => setSelectedOptionVariable(''),
               background: " #F8F8F8", borderRadius: '8px',marginTop:'5px', maxHeight: '250px',
               overflowY: 'auto',
              
            },
            
          },
        }}
      >
        {/* <MenuItem value={0} sx={{ display: 'none',  }}>  0 M </MenuItem> */}
       <MenuItem value={0} sx={{
          backgroundColor: selectedOptionVariable === 0 ? '#0C276C !important' : 'transparent',
          color: selectedOptionVariable === 0 ? '#ffffff' : '#626262', fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px',
          fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' },minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(0)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          0 M  </MenuItem> 
        <MenuItem value={1} sx={{
          backgroundColor: selectedOptionVariable === 1 ? '#0C276C !important' : 'transparent',
          color: selectedOptionVariable === 1 ? '#ffffff' : '#626262', fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px',
          fontWeight: '400', borderRadius: '4px', marginLeft: '5px',   marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' },minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(1)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          1 M  </MenuItem>
        <MenuItem value={2} sx={{
          backgroundColor: selectedOptionVariable === 2 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 2 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(2)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          2 M </MenuItem>
        <MenuItem value={3} sx={{
          backgroundColor: selectedOptionVariable === 3 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 3 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(3)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         3 M  </MenuItem>
         <MenuItem value={4} sx={{
          backgroundColor: selectedOptionVariable === 4 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 4 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(4)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          4 M </MenuItem>
        <MenuItem value={5} sx={{
          backgroundColor: selectedOptionVariable === 5 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 5 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(5)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         5 M  </MenuItem>
         <MenuItem value={6} sx={{
          backgroundColor: selectedOptionVariable === 6 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 6 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(6)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          6 M </MenuItem>
        <MenuItem value={7} sx={{
          backgroundColor: selectedOptionVariable === 7 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 7 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(7)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         7 M  </MenuItem>
         <MenuItem value={8} sx={{
          backgroundColor: selectedOptionVariable === 8 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 8 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(8)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          8 M </MenuItem>
        <MenuItem value={9} sx={{
          backgroundColor: selectedOptionVariable === 9 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 9 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(9)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         9 M  </MenuItem>
         <MenuItem value={10} sx={{
          backgroundColor: selectedOptionVariable === 10 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 10 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(10)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          10 M </MenuItem>
        <MenuItem value={11} sx={{
          backgroundColor: selectedOptionVariable === 11 ? '#0C276C !important' : 'transparent', color: selectedOptionVariable === 11 ? '#ffffff' : '#626262',
          fontFamily: 'poppins',fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',  marginRight: '5px',minHeight:{ md: '28px', sm: '28px',xs:'18px' } ,
        }}
          onMouseEnter={() => setSelectedOptionVariable(11)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         11 M  </MenuItem>
        
       
      </Select>
      </Box>

     




      {showPopup && (

        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '2'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
            
              width: '378px',
              height: '202px',
              backgroundColor: '#fff',

              border: '1px solid #CFC9C9',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '3'
            }}
          >
            <IconButton onClick={handleCancel} style={{
              color: '#939393', position: 'absolute', width: '20px', height: '20px',
              left: '347px', top: '12px'
            }} >
              <HighlightOffIcon ></HighlightOffIcon>
            </IconButton>

            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
              textAlign: "center"
            }}>Are you sure you want to delete this Mortgage option?</Typography>
            <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
              <button size='small' onClick={handleCancel} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins', fontStyle: 'normal',
                fontWeight: '400', fontSize: '14px', lineHeight: '21px', marginRight: '24px', textAlign: 'center', color: '#313131',
                background: '#F8F8F8', border: '1px solid #CFC9C9', borderRadius: '4px',cursor:'pointer'
              }} >Cancel</button>
              <button size='small' onClick={handleDeleteConfirm} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C !important', border: '1px solid #0C276C !important', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C !important';
              }} >Delete </button>
            </Box>
          </Box>
        </Box>
      )}



{showPopuptermamort && (

<Box
  sx={{
    position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2'
  }}
>
  <Box
    sx={{
      position: 'absolute',
    
      width: '378px',
      height: '202px',
      backgroundColor: '#fff',

      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3'
    }}
  >
    <IconButton onClick={handleCancelTerm} style={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left: '347px', top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>

    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
      textAlign: "center"
    }}> The amortization period must be equal to or greater than the term</Typography>
   <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
   <button size='small' onClick={handleCancelTerm} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C !important', border: '1px solid #0C276C !important', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C !important';
              }} >Cancel </button>
            
            </Box>
   
  </Box>
</Box>
)}
{/* {showPopupDownPer20 && (

<Box
  sx={{
    position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2'
  }}
>
  <Box
    sx={{
      position: 'absolute',
    
      width: '378px',
      height: '202px',
      backgroundColor: '#fff',

      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3'
    }}
  >
    <IconButton onClick={handleCancelDownPer20} style={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left: '347px', top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>
    <Typography style={{
      fontFamily: "poppins", fontSize: "16px", lineHeight: "21px", fontWeight: "500", color: '#313131', width: "290px",
      textAlign: "center"
    }}> Mortgage option #{index + 1}</Typography>
    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
      textAlign: "center",paddingTop:'15px'
    }}> The minimum down payment is 20% or {minamnt20}</Typography>
   <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
   <button size='small' onClick={handleCancelDownPer20} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C !important', border: '1px solid #0C276C !important', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C !important';
              }} >Cancel </button>
            
            </Box>
   
  </Box>
</Box>
)} */}

{/* {showPopupDownPer5 && (

<Box
  sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2'
  }}
>
  <Box
    sx={{
      position: 'absolute',
    
      width: '378px',
      height: '202px',
      backgroundColor: '#fff',

      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3'
    }}
  >
    <IconButton onClick={handleCancelDownPer5} style={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left: '347px', top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>

    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
      textAlign: "center"
    }}> The minimum down payment is 5% or {minamnt5}</Typography>
   <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
   <button size='small' onClick={handleCancelDownPer5} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C !important', border: '1px solid #0C276C !important', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C !important';
              }} >Cancel </button>
            
            </Box>
   
  </Box>
</Box>
)} */}
{/* {showPopupDownPer && (

<Box
  sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2'
  }}
>
  <Box
    sx={{
      position: 'absolute',
    
      width: '378px',
      height: '202px',
      backgroundColor: '#fff',

      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3'
    }}
  >
    <IconButton onClick={handleCancelDownPer} style={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left: '347px', top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>

    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
      textAlign: "center"
    }}> The minimum down payment is greater than {minper} or {minamnt} </Typography>
   <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
   <button size='small' onClick={handleCancelDownPer} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C !important', border: '1px solid #0C276C !important', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C !important';
              }} >Cancel </button>
            
            </Box>
   
  </Box>
</Box>
)} */}
    </Box>


  );

}

export default DivcomponenetUser;