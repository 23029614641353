import { AppBar, Avatar, Box, Button, IconButton, Modal, OutlinedInput, Typography } from '@mui/material';
import React, { useState } from 'react'
import Rating from '@mui/material/Rating';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import star from './images/star.png';
import Joan from './images/Joan.png';
import Frank from './images/frank.png';
// import PrimaryButton from './customButton';
function RateandcommentMob() {
    const [openModal, setOpenmodal]=useState(true);
    const [value, setValue] = useState(5);
    const handleOpenmodal = () => setOpenmodal(true);
    const handleClosemodal = () => setOpenmodal(false);
    const [isHoveredSubmit, setIsHoveredSubmit] = useState(false);

    const handleMouseEnterSubmit = () => {
        setIsHoveredSubmit(true);
    };
  
    const handleMouseLeaveSubmit= () => {
        setIsHoveredSubmit(false);
    };

  return (

    <Box>
      {openModal && (
    
    // <Box
    //       sx={{
    //         position: 'absolute',
    //         top: 0,
    //         left: 0,
    //         width: '100%',
    //         height: '2700px',
    //         backgroundColor: 'rgba(0, 0, 0, 0.6)',
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         zIndex: '2'
    //       }}
    //     >
    //   <Box
    //       sx={{
    //         position: 'absolute',
    //         width:'calc(100%-24px)',
    //         marginLeft:'12px',
    //         marginRight:'12px',
    //         height:'940px',
    //         //maxHeight:'700px',
    //         marginTop:'75%',
    //         bgcolor: '#FFFF',
    //         borderRadius:'8px',
    //         BoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    //         alignItems:'center',
    //         justifyContent:'center',
    //         alignContent:'center',
           
    //       }}>
     
    <Box
          sx={{
           
            position: 'absolute',
    top: '0',
    left: '0',
  
            width: '100%',
            height: '2800px',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '2'
          }}
        >
      <Box
          sx={{
            position: 'absolute',
            width:'calc(100%-24px)',
            top: '25%',
            marginLeft:'12px',
            marginRight:'12px',
            height:'780px',
            //maxHeight:'700px',
         
            bgcolor: '#FFFF',
            borderRadius:'8px',
            BoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            alignItems:'center',
            justifyContent:'center',
            alignContent:'center',
             overflowY: 'auto',
          }}>
<Box sx={{display:'flex',flexDirection:'column',width:'calc(100%)',height:'100%',gap:'10px',}}>
  
<Box sx={{width:'calc(100%)',height:'56px',backgroundColor:'#1F6373',}}>
<Box sx={{display:'flex',flexDirection:'row',width:'100%',height:'65px'}}>
<Box sx={{display:'flex',flexDirection:'column',width:'15%',height:'56px',alignItems:'center',justifyContent:'center'}}>
<Avatar sx={{ color: '#313131', backgroundColor: '#CFC9C9', width: '32px', height: '32px', fontSize: '20px'}}>A</Avatar>
            </Box> 
            <Box sx={{display:'flex',flexDirection:'column',width:'28%',height:'56px',alignItems:'center',justifyContent:'center'}}>
            <Typography sx={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 400 }}>
                Agent code
              </Typography>
              <Typography sx={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500 }}>
                6601
              </Typography>
            </Box> 
            <Box sx={{display:'flex',flexDirection:'row',width:'57%',height:'56px',alignItems:'right',justifyContent:'right',paddingRight:'5%'}}>
            <IconButton onClick={handleClosemodal} >
              < CancelOutlinedIcon sx={{ color: '#FFFFFF', width: '24px', height: '24px', }} />
            </IconButton>
            </Box>
    </Box>   
</Box>
<Box sx={{display:'flex',flexDirection:'row',width:'calc(100%-24px)',height:'126px',background:'#F9F9F9',
marginLeft:'12px',marginRight:'12px',borderRadius:'8px',padding:'8px'}}>
    <Box sx={{display:'flex',flexDirection:'column',width:'28%',alignItems:'left',height:'126px',justifyContent:'center',gap:'5px'}}>
<Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 ,textAlign:'left'}}>Excellent</Typography>
  <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400,textAlign:'left'}}>Good</Typography>
 <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 ,textAlign:'left' }}>Average</Typography>
   <Typography sx={{color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 ,textAlign:'left'}}>Below average</Typography>
 <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 ,textAlign:'left'}}>Poor</Typography>
 </Box>
<Box sx={{display:'flex',flexDirection:'column',width:'57%',alignItems:'left',height:'126px',justifyContent:'center',gap:'20px'}}>
<Box sx={{width:'100%',height:'4px',borderRadius:'4px',backgroundColor:'#76DE24'}}></Box>
<Box sx={{width:'80%',height:'4px',borderRadius:'4px',backgroundColor:'#C8E2B3'}}></Box>
<Box sx={{width:'60%',height:'4px',borderRadius:'4px',backgroundColor:'#F9D075'}}></Box>
<Box sx={{width:'40%',height:'4px',borderRadius:'4px',backgroundColor:'#F68D11'}}></Box>
<Box sx={{width:'20%',height:'4px',borderRadius:'4px',backgroundColor:'#CE3932'}}></Box>
 </Box>
 <Box sx={{display:'flex',flexDirection:'row',width:'15%',alignItems:'center',height:'126px',justifyContent:'center',gap:'2px'}}>
 <Typography sx={{ color: '#1F6373', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500,lineHeight:'21px'}}>4.5</Typography>
 <img src={star} alt='' sx={{width:'12px',height:'12px'}} />
 </Box>
</Box>
<Box sx={{display:'flex',flexDirection:'column',width:'calc(100%-24px)',height:'170px',
marginLeft:'12px',marginRight:'12px',gap:'10px'}}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%',}}>
 <Box sx={{ alignItems: 'center',justifyContent:'left',display:'flex',flexDirection:'row',width:'40%',height:'32px',gap:'10px' }}>
                <img alt='' src={Joan} sx={{height: '32px', width: '32px', }} />
                <Typography sx={{ color: '#000000', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Joan Perkins</Typography>
              </Box>
              <Box sx={{ alignItems: 'center',justifyContent:'right',display:'flex',flexDirection:'row',width:'60%',height:'32px',marginRight:'3%', }}>
              <Typography sx={{ color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>1 Day ago</Typography>
              </Box>
              </Box>
               <Box sx={{ alignItems: 'center',justifyContent:'left',display:'flex',flexDirection:'row',width:'100%',height:'32px' ,}}>
              <Rating name="read-only" value={5} readOnly size='small'/>
                <Typography sx={{ color: '#000000', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400,margin:'3px' }}>5.0</Typography>
              </Box> 
              <Box sx={{ alignItems: 'center',justifyContent:'left',display:'flex',flexDirection:'row',width:'100%',height:'88px' }}>
              <Typography sx={{color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
            Lorem ipsum dolor sit amet,consectetuer adipiscing elitAncommodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculusmus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
            </Typography> 
              </Box> 
              <Box>
              
               
               
               
            </Box>        
</Box>
<Box sx={{width:'calc(100%-24px)',height:'2px',borderRadius:'4px',backgroundColor:'#F9F9F9',marginLeft:'12px',marginRight:'12px',
marginTop:'5px',marginBottom:'5px'}}></Box>
<Box sx={{display:'flex',flexDirection:'column',width:'calc(100%-24px)',height:'170px',
marginLeft:'12px',marginRight:'12px',gap:'10px'}}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%',}}>
 <Box sx={{ alignItems: 'center',justifyContent:'left',display:'flex',flexDirection:'row',width:'40%',height:'32px',gap:'10px' }}>
                <img alt='' src={Frank} sx={{height: '32px', width: '32px', }} />
                <Typography sx={{ color: '#000000', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>  Frank Garratt  </Typography>
              </Box>
              <Box sx={{ alignItems: 'center',justifyContent:'right',display:'flex',flexDirection:'row',width:'60%',height:'32px',marginRight:'3%', }}>
              <Typography sx={{ color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>3 Days ago</Typography>
              </Box>
              </Box>
               <Box sx={{ alignItems: 'center',justifyContent:'left',display:'flex',flexDirection:'row',width:'100%',height:'32px' ,}}>
              <Rating name="read-only" value={5} readOnly size='small'/>
                <Typography sx={{ color: '#000000', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400,margin:'3px' }}>4.0</Typography>
              </Box> 
              <Box sx={{ alignItems: 'center',justifyContent:'left',display:'flex',flexDirection:'row',width:'100%',height:'22px' }}>
              <Typography sx={{color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
              Lorem ipsum dolor sit amet, consectetuer adipisci</Typography> 
              </Box> 
              <Box>
              
               
               
                
            </Box>        
</Box>
<Box sx={{width:'calc(100%-24px)',height:'2px',borderRadius:'4px',backgroundColor:'#F9F9F9',marginLeft:'12px',marginRight:'12px'
,marginTop:'-55px'
}}></Box>
<Box sx={{display:'flex',flexDirection:'row',width:'calc(100%-24px)'
,marginLeft:'12px',marginRight:'12px',marginTop:'5px',justifyContent:'right',alignItems:'center',gap:'5px'}}>
     <Typography sx={{ color: '#1F6373', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400}}>See more</Typography>
 <ArrowDropDownIcon sx={{color: '#1F6373'}}/>
           

   </Box>
   <Box sx={{display:'flex',flexDirection:'column',width:'calc(100%-24px)',marginLeft:'12px',marginRight:'12px',
marginBottom:'5px',justifyContent:'center',alignItems:'center',gap:'5px'}}>
    <Typography sx={{color: '#313131', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500,}}>Rate your agent</Typography>
           <div align='center' >
            <Rating name="simple-controlled" value={value}
                onChange={(event, newValue) => { setValue(newValue);}}/>
            </div>
           

   </Box>
   <Box sx={{display:'flex',flexDirection:'column',width:'calc(100%-24px)',marginLeft:'12px',marginRight:'12px',
marginBottom:'5px',justifyContent:'left',alignItems:'left',gap:'5px'}}>
   
<Typography sx={{color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 500}}>Write your comments here.</Typography>
<OutlinedInput type="text" sx={{width:'100%', boxShadow: ' 0px 1px 2px rgba(16, 24, 40, 0.05);',height:'34px', border: '1px solid #D2D2D2',
  fontFamily: 'Poppins', color: '#313131',background:'#D2D2D2',borderRadius:'8px','&:hover': { background: "#F9F9F9",}}}/> 
           

   </Box>
   <Box sx={{display:'flex',flexDirection:'row',width:'calc(100%-24px)',
marginLeft:'12px',justifyContent:'right',alignItems:'right',marginRight:'12px',}}>
   
   <Button onClick={handleClosemodal} variant="contained" onMouseEnter={handleMouseEnterSubmit}
            onMouseLeave={handleMouseLeaveSubmit}  style={{
              color: "white", textTransform: "none",
              background: isHoveredSubmit ? "#074554" : "#1F6373", border: "1px solid #1F6373", fontFamily: "Poppins",
              fontStyle: "normal", lineHeight: "21px", height: '28px',
              fontWeight: "500", fontSize: "12px", width: "72px", borderRadius: "4px",
            }} >
           Submit
          </Button>
           

   </Box>

    </Box>



          {/* <Box sx={{width:'100%',height:'90px',backgroundColor:'#1F6373',display:{lg:'block',xs:'none'}}}>
            <div style={{ display: 'flex', flexDirection:'row-reverse',paddingTop:'12px',paddingRight:'12px' }}>
            <IconButton sx={{ padding: '0px' }} onClick={handleClosemodal} >
              < CancelOutlinedIcon sx={{ color: '#FFFFFF', width: '24px', height: '24px' }} />
            </IconButton>
            </div>
            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'10px', paddingLeft:'30px',paddingBottom:'28px'}}>
              <Box sx={{paddingleft:'30px'}}>
              <Avatar sx={{ color: '#313131', backgroundColor: '#CFC9C9', width: '34px', height: '34px', fontSize: '20px'}}>A</Avatar>
              </Box>
              <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>
              <Typography sx={{ width:'90px',color: 'white', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 ,textAlign:'left'}}>
                Agent code
              </Typography>
              <Typography sx={{ width:'90px',color: 'white', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400,textAlign:'left' }}>
                6601
              </Typography>
              </div>
              
            </Box>
            </Box>
            <Box sx={{width:'100%',height:'56px',backgroundColor:'#1F6373',display:{lg:'none',xs:'flex'}}}>
            <Box sx={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',marginRight:'12px'}}>
            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'10px', marginLeft:'12px'}}>
              <Box sx={{}}>
              <Avatar sx={{ color: '#313131', backgroundColor: '#CFC9C9', width: '34px', height: '34px', fontSize: '20px'}}>A</Avatar>
              </Box>
              <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>
              <Typography sx={{ width:'90px',color: 'white', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 ,textAlign:'left'}}>
                Agent code
              </Typography>
              <Typography sx={{ width:'90px',color: 'white', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400,textAlign:'left' }}>
                6601
              </Typography>
              </div>
            </Box>
            <IconButton sx={{ padding: '0px' }} onClick={handleClosemodal} >
              < CancelOutlinedIcon sx={{ color: '#FFFFFF', width: '20px', height: '20px' }} />
            </IconButton>
            </Box>
          </Box>
            <Box sx={{marginLeft:{lg:'28px',md:'28px',xs:'12px'},marginRight:{lg:'28px',md:'28px',xs:'12px'},paddingTop:'28px',paddingBottom:'28px',overflowY:'auto',maxHeight:'550px'}}>
              <Box sx={{width:'100%',height:'126px',backgroundColor:'#F9F9F9',borderRadius:'8px',alignItems:'center',paddingLeft:'10px',display:'flex',flexDirection:'row',gap:'2px'}}>
                <Box sx={{width:{lg:'110px',md:'110px',sm:'110px',xs:'40%'},height:'106px',display:'flex',flexDirection:'column',alignItems:'center',gap:'4px'}}>
                  <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 ,textAlign:'left'}}>Excellent</Typography>
                  <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400,textAlign:'left'}}>Good</Typography>
                  <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 ,textAlign:'left' }}>Average</Typography>
                  <Typography sx={{color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 ,textAlign:'left'}}>Below average</Typography>
                  <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 ,textAlign:'left'}}>Poor</Typography>
                </Box>
                <Box sx={{width:'40%',height:'106px',display:'flex',flexDirection:'column',alignItems:'center',gap:'4px'}}>
                <Box sx={{width:'calc(100%-24px)',height:'18px',display:'flex',alignItems:'center',gap:'4px'}}>
                  <Box sx={{width:'calc(100%-24px)',height:'4px',borderRadius:'4px',backgroundColor:'#76DE24'}}></Box>
                </Box>
                <Box sx={{width:'calc(100%-24px)',height:'18px',display:'flex',alignItems:'center',gap:'4px'}}>
                  <Box sx={{width:{lg:'234px',md:'234px',sm:'234px',xs:'75%'},height:'4px',borderRadius:'4px',backgroundColor:'#C8E2B3'}}></Box>
                </Box>
                <Box sx={{width:'calc(100%-24px)',height:'18px',display:'flex',alignItems:'center',gap:'4px'}}>
                  <Box sx={{width:{lg:'144px',md:'144px',sm:'144px',xs:'50%'},height:'4px',borderRadius:'4px',backgroundColor:'#F9D075'}}></Box>
                </Box>
                <Box sx={{width:'calc(100%-24px)',height:'18px',display:'flex',alignItems:'center',gap:'4px'}}>
                  <Box sx={{width:{lg:'88px',md:'88px',sm:'88px',xs:'40%'},height:'4px',borderRadius:'4px',backgroundColor:'#F68D11'}}></Box>
                </Box>
                <Box sx={{width:'calc(100%-24px)',height:'18px',display:'flex',alignItems:'center',gap:'4px'}}>  
                  <Box sx={{width:{lg:'38px',md:'38px',sm:'38px',xs:'30%'},height:'4px',borderRadius:'4px',backgroundColor:'#CE3932'}}></Box>
                </Box>
                </Box>
                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Typography sx={{ color: '#1F6373', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500,marginTop:'12px',marginLeft:{lg:'8px',md:'8px',sm:'0px',xs:'4px'} }}>4.5</Typography>
                <Box sx={{width:'24px',height:'24px'}}><img src={star} style={{marginTop:'11px',marginLeft:'4px',width:'14px',height:'14px'}} /></Box>
                </Box>
              </Box>
              <Box sx={{width:'calc(100%-24px)',height:'36px',display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'32px'}}>
              <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'8px'}}>  
              <Box sx={{ height: '32px', width: '32px', borderRadius: '32px', alignItems: 'center' }}>
                <img src={Joan} />
              </Box>
                <Box>
                <Typography sx={{ color: '#000000', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Joan Perkins</Typography>
                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Rating name="read-only" value={5} readOnly size='small'/>
                <Typography sx={{ color: '#000000', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>5.0</Typography>
                </Box>
              </Box>
              </Box>
              <Typography sx={{ color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>1 Day ago</Typography>
            </Box>
            <Box sx={{borderBottom:'2px solid #F9F9F9',paddingBottom:'22px',width:'calc(100%-24px)'}}>
            <Typography sx={{textAlign:'justify',color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400,marginTop:'12px',paddingLeft:'40px' }}>
            Lorem ipsum dolor sit amet,consectetuer adipiscing elitAncommodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculusmus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
            </Typography> 
            </Box>
            <Box sx={{width:{lg:'492px',md:'492px',xs:'100%'},height:'36px',display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'22px'}}>
              <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'8px'}}>  
              <Box sx={{ height: '32px', width: '32px', borderRadius: '32px', alignItems: 'center' }}>
                <img src={Frank} />
              </Box>
                <Box>
                <Typography sx={{ color: '#000000', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Frank Garratt</Typography>
                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Rating name="read-only" value={5} readOnly size='small'/>
                <Typography sx={{ color: '#000000', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>5.0</Typography>
                </Box>
              </Box>
              </Box>
              <Typography sx={{ color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>1 Day ago</Typography>
            </Box>
            <Box sx={{borderBottom:'2px solid #F9F9F9',paddingBottom:'22px',width:'calc(100%-24px)'}}>
            <Typography sx={{ textAlign:'justify',color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400,marginTop:'22px',paddingLeft:'40px' }}>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elitAncommodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculusmus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
            </Typography> 
            </Box>
            <div  align="right" style={{display:'flex',flexDirection:'row-reverse',alignItems:'center',gap:'6px'}}>
            <ArrowDropDownIcon sx={{color: '#1F6373',width:'16px',height:'14px'}}/>
            <Typography sx={{ color: '#1F6373', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 500}}>See more</Typography>
            </div>
            <Typography sx={{color: '#313131', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500,textAlign:'center',marginTop:'6px'}}>Rate your agent</Typography>
           <div align='center' style={{marginTop:'10px'}}>
            <Rating name="simple-controlled" value={value}
                onChange={(event, newValue) => { setValue(newValue);}}/>
            </div>
<Typography sx={{color: '#626262', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500,marginTop:'32px'}}>Write your comments here.</Typography>
<OutlinedInput type="text" sx={{width:{lg:'492px',md:'492px',xs:'100%'} , borderRadius: '4px',height:{lg:'54px',xs:'32px'}, BoxSizing: 'border-Box',  fontFamily: 'Poppins', color: '#313131',backgroundColor:'#F9F9F9',borderRadius:'8px'}}/> 
        <div align='right' style={{marginTop:'8px'}}>
            <PrimaryButton sx={{width: '82px', fontSize: '14px',borderRadius: '4px'}}>Submit</PrimaryButton> 
            <Button variant="contained" onMouseEnter={handleMouseEnterSubmit}
            onMouseLeave={handleMouseLeaveSubmit}  style={{
              color: "white", textTransform: "none",
              background: isHoveredSubmit ? "#074554" : "#1F6373", border: "1px solid #1F6373", fontFamily: "Poppins",
              fontStyle: "normal", lineHeight: "21px", height: '28px',marginTop:'10px',
              fontWeight: "500", fontSize: "12px", width: "72px", borderRadius: "4px",
            }} >
           Submit
          </Button>
                </div>
            </Box> */}
        </Box>
        </Box>
        )}

        </Box>
   
  )
}
export default RateandcommentMob;