//import React, { useLayoutEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AppBar from "@mui/material/AppBar";
import { Button, Grid, Typography } from "@mui/material";
import logo from './images/logo.png'
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ImgFrame from './images/HomeBlue.png';
import dataicon from './images/icon-park-outline_database-forbid.svg';
import HelpIcon from '@mui/icons-material/Help';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Link from "@mui/material/Link";
import { useState, useEffect } from "react";

import DivComponents from './DivComponent';
import Payment from './PaymentComponent';
import Penalty from './PenaltyComponent';
import CheckboxPopUp from './CheckboxPopUp';
import GetAppIcon from '@mui/icons-material/GetApp';
import SignIn from './SignInSignUp'
import axios from 'axios';






function AddMortgage() {
  const menus = [
    "Calculator",
    "Quotations",
    "Products",
    "About us",
    "Contact us"
  ];
  const linkstyles = {
    link1: {
    textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "16px", fontWeight: "400",
      color: " #313131", zIndex: 1
    },
    link2: {
      marginLeft: "5%", textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "16px", fontWeight: "400",
      color: " #313131"

    },
    activeLink: {
      textDecoration: 'underline', fontWeight: "500"
    },
  };
  const [activeLink, setActiveLink] = useState('Calculate Payment');

  const handleLinkClick = (link) => {
    setActiveLink(link);

  };
  const [calcContent, setcalcContent] = useState("Payment Content");
  const handlePaymentCalc = () => {
    setcalcContent("Payment Content");
    handleLinkClick('Calculate Payment');
  };
  const handlePenaltyCalc = () => {
    setcalcContent("Penalty Content");
    handleLinkClick('Calculate Penalty on cancellation');
  };






  const [askingPriceValue, setAskingPriceValue] = useState(10000);
  const [initialPaymentValue, setInitialPaymentValue] = useState(0);
  const [mortgageamountValue, setMortgageamountValue] = useState(10000);
  const [termValue, setTermValue] = useState(5);
  const [rateValue, setRateValue] = useState(3);
  const [periodValue, setPeriodValue] = useState(25);
  const [frequencyValue, setFrequencyValue] = useState('M');  
  const [insurance,setInsurance]=useState(0);
  const [sheduledPayment, setSheduledPayment] = useState(47.32);
  const [resultTotal, setResultTotal] = useState({ TotalAmountPaid:1431.60 ,TotalInterestPaid :1407.60 , TotalPrinciplePaid: 2839.20 });

  const [dataList, setDataList] = useState([
    {
      id: 0,
      name: "Option #1",
      askingprice: askingPriceValue,
      initialPayment: initialPaymentValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      SheduledResult:sheduledPayment,
      lowerResults:resultTotal
    },
    {
      id: 1,
      name: "Option #2",
      askingprice: askingPriceValue,
      initialPayment: initialPaymentValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      SheduledResult:sheduledPayment,
      lowerResults:resultTotal
    },
    {
      id: 2,
      name: "Option #3",
      askingprice: askingPriceValue,
      initialPayment: initialPaymentValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      SheduledResult:sheduledPayment,
      lowerResults:resultTotal
    },
    {
      id: 3,
      name: "Option #4",
      askingprice: askingPriceValue,
      initialPayment: initialPaymentValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      SheduledResult:sheduledPayment,
      lowerResults:resultTotal
    },
  ])

  
  const [numberOfAddClick, setnumberOfAddClick] = useState(4);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(4);

  const handleAddSlide = () => {
console.log("datalength",dataList.length )
    setisDeleteButtonEnabled(true);
    if (dataList.length !== 7) {
      const newData = {
        id: dataList.length , name: `Option #${numberOfAddClick + 1}`, askingprice: askingPriceValue,
        initialPayment: initialPaymentValue, mortgageamount: mortgageamountValue, term: termValue, rate: rateValue, period: periodValue,
         frequency: frequencyValue,SheduledResult:sheduledPayment, lowerResults:resultTotal
      }

      // isDeleteConfirm===true?setCurrentSlideIndex(currentSlideIndex+2):setCurrentSlideIndex(currentSlideIndex+1);

      setnumberOfAddClick(numberOfAddClick + 1);

      // setIsDeleteConfirm(false);
      setDataList([...dataList, newData]);
    }
    setCurrentSlideIndex(currentSlideIndex + 1);
  };




  const handlePrevSlide = () => {

    if (currentSlideIndex > 4)
      setCurrentSlideIndex(currentSlideIndex - 1)

  };

  const handleNextSlide = () => {
    if (currentSlideIndex < 7)
      setCurrentSlideIndex(currentSlideIndex + 1)

  }
  const [isDeleteButtonEnabled, setisDeleteButtonEnabled] = useState(false);
  const isdisableAddSlidebutton = (dataList[dataList.length]?.name) === (dataList[currentSlideIndex]?.name)
  const [CheckboxEnabled, setCheckboxEnabled] = useState(false);
  const [thebidding, setthebidding] = useState("bidding disable");
  const handleAddBiddingCheckbox = () => {
    setCheckboxEnabled(true);
    setthebidding("bidding enable");
    setisDeleteButtonEnabled(false);
  };
  const [showPopupforCheckbox, setShowPopupforCheckbox] = useState(false);
  const handleNextClick = () => {
    setShowPopupforCheckbox(true);
  };
  const [selectedCheckboxList, setCheckboxSelectedList] = useState([]);

 // const [isCheckedCheckboxNext, setisCheckedCheckboxNext] = useState([]);
  var isNextButtonEnabled = selectedCheckboxList.length > 0;
  const handleNextCheckboxChange = (isChecked, index) => {

    // setisNextButtonEnabled(isChecked);    

    //setisCheckedCheckboxNext(isChecked);
    //[...isChecked]=[temp,setIsChecked];

    //  if (isChecked) {
    //   setisCheckedCheckboxNext((prevChecked) => [...prevChecked, index]);
    // } else {
    //   setisCheckedCheckboxNext((prevChecked) =>
    //     prevChecked.filter((item) => item !== index)
    //   );
    // }




  };
  const IsChevorButton = (CheckboxEnabled && dataList.length > 4) || isDeleteButtonEnabled;




  // const [isChecked, setIsChecked] = useState(false);

  const [isHoveredAddMortgage, setIsHoveredAddMortgage] = useState(false);

  const handleMouseEnterAddMortgage = () => {
    setIsHoveredAddMortgage(true);
  };

  const handleMouseLeaveAddMortgage = () => {
    setIsHoveredAddMortgage(false);
  };

  const [isHoveredTheBidding, setIsHoveredTheBidding] = useState(false);

  const handleMouseEnterTheBidding = () => {
    setIsHoveredTheBidding(true);
  };

  const handleMouseLeaveTheBidding = () => {
    setIsHoveredTheBidding(false);
  };
  const [isHoveredSendToMail, setIsHoveredSendToMail] = useState(false);

  const handleMouseEnterSendToMail = () => {
    setIsHoveredSendToMail(true);
  };

  const handleMouseLeaveSendToMail = () => {
    setIsHoveredSendToMail(false);
  };

  const [isHoveredDownloadResult, setIsHoveredDownloadResult] = useState(false);

  const handleMouseEnterDownloadResult = () => {
    setIsHoveredDownloadResult(true);
  };

  const handleMouseLeaveDownloadResult = () => {
    setIsHoveredDownloadResult(false);
  };
  const [isHoveredNext, setIsHoveredNext] = useState(false);

  const handleMouseEnterNext = () => {
    setIsHoveredNext(true);
  };

  const handleMouseLeaveNext = () => {
    setIsHoveredNext(false);
  };

  // const [size, setSize] = useState([0, 0]);

  // function ViewPort() {
  //   var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  //   var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  //   var viewsize = w + "," + h;
  //   alert("Your View Port Size is:" + viewsize);
  // }
  // useLayoutEffect(() => {
  //   function updateSize() {
  //     setSize([window.outerWidth, window.outerWidth]);
  //     var x = window.matchMedia("(max-width: 700px)")
  //     console.log("x", x)
  //   }
  //   window.addEventListener('resize', updateSize);
  //   updateSize();
  //   return () => window.removeEventListener('resize', updateSize);
  // }, []);

  // console.log("size", size);

  const [isActiveAskingPrice, setIsActiveAskingPrice] = useState(false);

  const handleInputClickAskingPrice = () => {
    setIsActiveAskingPrice(true);
  };

  const handleInputBlurAskingPrice = () => {
    setIsActiveAskingPrice(false);
  };
  const [isActiveLocation, setIsActiveLocation] = useState(false);

  const handleInputClickLocation = () => {
    setIsActiveLocation(true);
  };

  const handleInputBlurLocation = () => {
    setIsActiveLocation(false);
  };
  const [showSignIn, setShowSignIn] = useState(false);

  const handleClickSignIn = () => {
    setShowSignIn(!showSignIn);
  };
  const [showTermText, setShowTermText] = useState(false);
  const handleTermClick = () => {
    setShowTermText(true)
  };
  const handleTermClickLeave = () => {
    setShowTermText(false)
  };
  const [showAnnualInterestRateText, setShowAnnualInterestRateText] = useState(false);
  const handleAnnualInterestRateClick = () => {
    setShowAnnualInterestRateText(true)
  };
  const handleAnnualInterestRateClickLeave = () => {
    setShowAnnualInterestRateText(false)
  };
  const [showAmortizationText, setShowAmortizationText] = useState(false);
  const handleAmortizationTextClick = () => {
    setShowAmortizationText(true)
  };
  const handleAmortizationTextClickLeave = () => {
    setShowAmortizationText(false)
  };
  const [showPaymentfreequencyText, setShowPaymentfreequencyText] = useState(false);
  const handlePaymentfreequencyTextClick = () => {
    setShowPaymentfreequencyText(true)
  };
  const handlePaymentfreequencyTextClickLeave = () => {
    setShowPaymentfreequencyText(false)
  };
  const [showPaymentSheduleText1, setShowPaymentSheduleText1] = useState(false);
  const handlePaymentSheduleTextClick1 = () => {
    setShowPaymentSheduleText1(true)
  };
  const handlePaymentSheduleTextClick1Leave = () => {
    setShowPaymentSheduleText1(false)
  };
  const [showPaymentSheduleText2, setShowPaymentSheduleText2] = useState(false);
  const handlePaymentSheduleTextClick2 = () => {
    setShowPaymentSheduleText2(true)
  };
  const handlePaymentSheduleTextClick2Leave = () => {
    setShowPaymentSheduleText2(false)
  };
  const [showAskingPriceText, setShowAskingPriceText] = useState(false);
  const handleAskingPriceTextClick = () => {
    setShowAskingPriceText(true)
  };
  const handleAskingPriceTextClickLeave = () => {
    setShowAskingPriceText(false)
  };
  const [showInitialpaymentText, setShowInitialpaymentText] = useState(false);
  const handleInitialPaymentTextClick = () => {
    setShowInitialpaymentText(true)
  };
  const handleInitialPaymentTextClickLeave = () => {
    setShowInitialpaymentText(false)
  };
  const [showTotalPrinciplePaidText, setShowTotalPrinciplePaidText] = useState(false);
  const handleTotalPrinciplePaidTextClick = () => {
    setShowTotalPrinciplePaidText(true)
  };
  const handleTotalPrinciplePaidTextClickLeave = () => {
    setShowTotalPrinciplePaidText(false)
  };
  const [showTotalInterestPaidText, setShowTotalInterestPaidText] = useState(false);
  const handleTotalInterestPaidTextClick = () => {
    setShowTotalInterestPaidText(true)
  };
  const handleTotalInterestPaidTextClickLeave = () => {
    setShowTotalInterestPaidText(false)
  };
  const [showTotalAmountPaidText, setShowTotalAmountPaidText] = useState(false);
  const handleTotalAmountPaidTextClick = () => {
    setShowTotalAmountPaidText(true)
  };
  const handleTotalAmountPaidTextClickLeave = () => {
    setShowTotalAmountPaidText(false)
  };
  const [showMortgageAmountText, setShowMortgageAmountText] = useState(false);
  const handleMortgageAmountTextClick = () => {
    setShowMortgageAmountText(true)
  };
  const handleMortgageAmountTextClickLeave = () => {
    setShowMortgageAmountText(false)
  };
  const [askingPriceVariableParent, setAskingPriceVariableParent] = useState(false);
  const handleChangeAskingPriceValue = (event) => {
    const newValue = event.target.value;
    setAskingPriceValue(newValue);
    setAskingPriceVariableParent(true);
    var tempdataList=[...dataList]
   tempdataList= tempdataList.map(e=>({...e,askingprice:parseInt(newValue)}));   
   setDataList(tempdataList);

  };
  ////////////////////////backend/////////////////////////
  const [resultAsking, setResultAsking] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [result, setResult] = useState([47.32, 47.32, 47.32, 47.32, 47.32, 47.32, 47.32]);
  const [result2, setResult2] = useState([{ Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
  { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
  { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
  { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }]);
  const [askingprice, setAskingPrice] = useState([10000, 10000, 10000, 10000, 10000, 10000, 10000]);
  const [interestRate, setInterestRate] = useState([0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03]);
  const [amortznPeriod, setAmortznPeriod] = useState([25, 25, 25, 25, 25, 25, 25]);
  const [FreqParam, setFreqParam] = useState(['M', 'M', 'M', 'M', 'M', 'M']);
  const [TermVal, setTermVal] = useState([5, 5, 5, 5, 5, 5, 5]);
  const [InitialpaymentVal, setInitialpaymentVal] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [mortgageamountVal, setmortgageamountVal] = useState([10000, 10000, 10000, 10000, 10000, 10000, 10000]);
  // useEffect(() => {    
  //   console.log('result from parent:', result);
  // }, [result]);

const [mortgageAmountTextValue, setMortgageAmountTextValue] = useState([10000,10000,10000,10000,10000,10000,10000]);

const [mortAmountValue, setMortAmountValue] = useState([10000,10000,10000,10000,10000,10000,10000]);

const setFinalPrice =async (value,index) => {
 
  setAskingPrice((prevResult) => {
    const newArray = [...prevResult]; // Create a copy of the existing array
    newArray[index] = parseInt(value); // Modify the value at the specified index
    return newArray; // Set the updated array
   
  });
setmortgageamountVal((prevResult) => {
  const newArraym = [...prevResult]; // Create a copy of the existing array
  newArraym[index] = parseInt(value); // Modify the value at the specified index
  return newArraym; // Set the updated array
 
});

    const AskingPriceValue = parseInt(value);
    // const newArray=[...askingprice]
    // newArray[index]=AskingPriceValue;
    // setAskingPrice(newArray)
    // const newArray = askingprice.slice(); // Create a shallow copy of the array
    // newArray[index] = parseInt(event.target.value); // Modify the value at the specified index
    // setAskingPrice[index]=parseInt(value);
    const rate = (dataList[index].rate)/100;
//const inietalpayval =dataList[index].initialPayment
    const Amortization = dataList[index].period;
    const Frequency = dataList[index].frequency;
    const Term = dataList[index].term;
    const MortgageAmnt =  parseInt(value) - dataList[index].initialPayment;
    var tempdataList=[...dataList]
    tempdataList[index].mortgageamount= parseInt(value) - dataList[index].initialPayment;
    setDataList(tempdataList);
    console.log("asking price text value!!!!",dataList);
    try {
      const response = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

    // Handle the response
    // const monthlyPayment = response.data.MonthlyPayment
    // console.log("data",monthlyPayment);
    // const newArray = result.slice(); // Create a shallow copy of the array
    // newArray[index] = monthlyPayment; // Modify the value at the specified index
    // setResult(newArray);
    
   
  
    // Assuming monthlyPayment is an array received from the response
    const monthlyPayment = response.data.MonthlyPayment;
    
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].SheduledResult = monthlyPayment;
      return newDataList;
    });
    // Update the result array with the new values
  console.log("<<<>>>>>>",dataList)
      setResult((prevResult) => {
        const newArray = [...prevResult]; // Create a copy of the existing array
        newArray[index] = response.data.MonthlyPayment; // Modify the value at the specified index
        return newArray; // Set the updated array
       
      });
     
   
   const SheduledPayment= dataList[index].SheduledResult;

    const response2 = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate2', { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt,SheduledPayment });
    console.log("second result",response2);
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].lowerResults = {
        TotalAmountPaid: response2.data.TotalPrinciple,
        TotalInterestPaid: response2.data.TotalInterest,
        TotalPrinciplePaid: response2.data.TotalPayment,
        
      };
      return newDataList;
    });

console.log("result2",dataList)
    const updatedResult2 = [...result2];
    updatedResult2[index].Totalinterest =response2.data.TotalInterest;
    updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
    updatedResult2[index].Totalpayment =response2.data.TotalPayment;
    setResult2(updatedResult2);

  } catch (error) {
    // Handle any error
  }
};

 
const handleChangeAskingprice =async (event,index) => {
  setFinalPrice(event.target.value,index);
//   console.log("asking price changed to",event.target.value);
//   console.log("Index value is ",index)
//   console.log("interestRate,askingprice,amortznPeriod,FreqParam",interestRate,askingprice,amortznPeriod,FreqParam);
//   const AskingPriceValue = parseInt(event.target.value);

//  // const newArray = askingprice.slice(); // Create a shallow copy of the array
// // newArray[index] = parseInt(event.target.value); // Modify the value at the specified index
//   setAskingPrice[index]=AskingPriceValue;
//   const rate=parseFloat(interestRate[index]);

    //   const Amortization = parseInt(amortznPeriod[index]);  
    //   const Frequency=FreqParam[index];
    //   const Term = parseInt(TermVal[index]);
    //   const MortgageAmnt=parseInt(mortgageamountVal[index]);
    //   try {
    //     const response = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate', { AskingPriceValue,rate,Amortization,Frequency,MortgageAmnt});

//     // Handle the response
//     const monthlyPayment = response.data.MonthlyPayment
//     console.log("data",monthlyPayment);
//     const newArray = result.slice(); // Create a shallow copy of the array
//     newArray[index] = monthlyPayment; // Modify the value at the specified index
//     setResult(newArray);
//     const response2 = await axios.post('http://localhost:5000/calculate2', { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term });
//     console.log("second result",response2);
//     const updatedResult2 = [...result2];
//     updatedResult2[index].Totalinterest =response2.data.TotalInterest;
//     updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
//     updatedResult2[index].Totalpayment =response2.data.TotalPayment;
//     setResult2(updatedResult2);

    //   } catch (error) {
    //     // Handle any error
    //   }
  };
  const handleChangeInterest = async (event, index) => {
    const value = parseFloat(event.target.value);
    let rate = (value) / 100;
    var tempdataList=[...dataList]
    tempdataList[index].rate=parseFloat((event.target.value))  ;
    setDataList(tempdataList);
    // setInterestRate[index]=rate;
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const Frequency = dataList[index].frequency;
    const Initialpayment = dataList[index].initialPayment;
    const MortgageAmnt = dataList[index].mortgageamount;

  const Term = TermVal[index];
  console.log("interestRate,initialPayment,amortznPeriod",interestRate,askingprice,amortznPeriod,FreqParam);
  try {
    const response = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate', { AskingPriceValue,rate,Amortization,Frequency,MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment;
        return newDataList;
      });
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);

      const SheduledPayment= dataList[index].SheduledResult;
      const response2 = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate2', { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt,SheduledPayment });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPrinciple,
        TotalInterestPaid: response2.data.TotalInterest,
        TotalPrinciplePaid: response2.data.TotalPayment,
          
        };
        return newDataList;
      });


      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangePeriod = async (event, index) => {

    const Amortization = parseInt(event.target.value);
    var tempdataList=[...dataList]
    tempdataList[index].period=parseInt(event.target.value)  ;
    setDataList(tempdataList);


   // setAmortznPeriod[index] = amortznPeriod;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate)/100;
    const Frequency = dataList[index].frequency;
    const Term = dataList[index].term;
    const Initialpayment = dataList[index].initialPayment;
    const MortgageAmnt = dataList[index].mortgageamount;
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment;
        return newDataList;
      });
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const SheduledPayment= dataList[index].SheduledResult;
      const response2 = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate2', { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt,SheduledPayment });
      console.log("second result", response2);

      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPrinciple,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPayment,
         
        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangeFreequency = async (event, index) => {

    const Frequency = event.target.value;
    // setFreqParam[index]=FreqParam;
    var tempdataList=[...dataList]
    tempdataList[index].frequency=event.target.value  ;
    setDataList(tempdataList);


    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate)/100;
    const Term = dataList[index].term;
    const Initialpayment = dataList[index].initialPayment;
    const MortgageAmnt = dataList[index].mortgageamount;
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

    // Handle the response
    const monthlyPayment = response.data.MonthlyPayment
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].SheduledResult = monthlyPayment;
      return newDataList;
    });
    const newArray = result.slice(); // Create a shallow copy of the array
    newArray[index] = monthlyPayment; // Modify the value at the specified index
    setResult(newArray);

    const SheduledPayment= dataList[index].SheduledResult;
    const response2 = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate2', { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt,SheduledPayment });
    console.log("second result",response2);
    const updatedResult2 = [...result2];

    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].lowerResults = {
        TotalAmountPaid: response2.data.TotalPrinciple,
        TotalInterestPaid: response2.data.TotalInterest,
        TotalPrinciplePaid: response2.data.TotalPayment,
       
      };
      return newDataList;
    });
    updatedResult2[index].Totalinterest =response2.data.TotalInterest;
    updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
    updatedResult2[index].Totalpayment =response2.data.TotalPayment;
    setResult2(updatedResult2);
    // Perform further actions with the response data
  } catch (error) {
    // Handle any error
  }
};
const handleChangeTerm = async(event,index) => {

  const Term = parseInt(event.target.value);

    var tempdataList=[...dataList]
    tempdataList[index].term=parseInt(event.target.value)  ;
    setDataList(tempdataList);

    // setTermVal[index]=parseInt(event.target.value);
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate)/100;
    const Frequency = dataList[index].frequency;
    const Initialpayment = dataList[index].initialPayment;
    const MortgageAmnt = dataList[index].mortgageamount;
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

    // Handle the response
    const monthlyPayment = response.data.MonthlyPayment
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].SheduledResult = monthlyPayment;
      return newDataList;
    });
    const newArray = result.slice(); // Create a shallow copy of the array
    newArray[index] = monthlyPayment; // Modify the value at the specified index
    setResult(newArray);
    
    const SheduledPayment= dataList[index].SheduledResult;
    const response2 = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate2', { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt,SheduledPayment });
    console.log("second result",response2);
    const updatedResult2 = [...result2];
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].lowerResults = {
        TotalAmountPaid: response2.data.TotalPrinciple,
        TotalInterestPaid: response2.data.TotalInterest,
        TotalPrinciplePaid: response2.data.TotalPayment,
       
      };
      return newDataList;
    });
    updatedResult2[index].Totalinterest =response2.data.TotalInterest;
    updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
    updatedResult2[index].Totalpayment =response2.data.TotalPayment;
    setResult2(updatedResult2);
    // Perform further actions with the response data
  } catch (error) {
    // Handle any error
  }
};
const handleChangeInitialPayment = async(event,index) => {
 
  console.log("handleChangeInitialPayment ",event.target.value,index,askingprice)

  const Initialpayment = parseInt(event.target.value);

    var tempdataList=[...dataList]
    tempdataList[index].initialPayment=parseInt(event.target.value)  ;
    setDataList(tempdataList);

    // console.log("init......",Initialpayment)
    // setInitialpaymentVal[index]=Initialpayment;
    const Amortization = dataList[index].period;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate)/100;
    const Frequency = dataList[index].frequency;
    const Term = dataList[index].term;
    const MortgageAmnt = (dataList[index].askingprice) - (event.target.value);
    
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

    // Handle the response
    const monthlyPayment = response.data.MonthlyPayment
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].SheduledResult = monthlyPayment;
      return newDataList;
    });
    const newArray = result.slice(); // Create a shallow copy of the array
    newArray[index] = monthlyPayment; // Modify the value at the specified index
    setResult(newArray);
    const SheduledPayment= dataList[index].SheduledResult;
    const response2 = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate2', { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt,SheduledPayment });
    console.log("second result",response2);
    const updatedResult2 = [...result2];
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].lowerResults = {
        TotalAmountPaid: response2.data.TotalPrinciple,
        TotalInterestPaid: response2.data.TotalInterest,
        TotalPrinciplePaid: response2.data.TotalPayment,
       
      };
      return newDataList;
    });
    updatedResult2[index].Totalinterest =response2.data.TotalInterest;
    updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
    updatedResult2[index].Totalpayment =response2.data.TotalPayment;
    setResult2(updatedResult2);
    // Perform further actions with the response data
  } catch (error) {
    // Handle any error
  }
};
const handleChangeMortgageAmount = async(index) => {
  const MortgageAmnt = parseInt(mortgageAmountTextValue); 

  setmortgageamountVal[index]=MortgageAmnt;
 
  const Initialpayment=InitialpaymentVal[index];
 const Amortization =amortznPeriod[index];
  const AskingPriceValue =askingprice[index];
  const rate=interestRate[index];
  const Frequency=FreqParam[index];
 const Term=TermVal[index];

  console.log("interestRate,initialPayment,amortznPeriod",interestRate,askingprice,amortznPeriod);
  try {
    const response = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate', { AskingPriceValue,rate,Amortization,Frequency,MortgageAmnt});

    // Handle the response
    const monthlyPayment= response.data.MonthlyPayment;
    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].SheduledResult = monthlyPayment;
      return newDataList;
    });
    setMortAmountValue[index] = response.data.MonthlyPayment;
    // console.log("ghghghgh:index",monthlyPayment,index)
    const newArray = result.slice(); // Create a shallow copy of the array
     newArray[index] = monthlyPayment; 
     // Modify the value at the specified index
     setResult(newArray);
     const SheduledPayment= dataList[index].SheduledResult;
    const response2 = await axios.post('http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:5000/calculate2', { AskingPriceValue,rate,Amortization,Frequency,monthlyPayment,Term,MortgageAmnt,SheduledPayment });
    console.log("second result",response2);

    setDataList(prevDataList => {
      const newDataList = [...prevDataList];
      newDataList[index].lowerResults = {
        TotalAmountPaid: response2.data.TotalPrinciple,
        TotalInterestPaid: response2.data.TotalInterest,
        TotalPrinciplePaid: response2.data.TotalPayment,
       
      };
      return newDataList;
    });
    const updatedResult2 = [...result2];
    updatedResult2[index].Totalinterest =response2.data.TotalInterest;
    updatedResult2[index].Totalprinciple =response2.data.TotalPrinciple;
    updatedResult2[index].Totalpayment =response2.data.TotalPayment;
    setResult2(updatedResult2);
    // Perform further actions with the response data
  } catch (error) {
    // Handle any error
  }
};

useEffect(() => {

  console.log('asking price has changed:', askingprice);  
  console.log('interest rate changed:', interestRate);  
  console.log('amrtzn period has changed:', amortznPeriod);  
  console.log('result has changed:', result); 
  console.log('result 2 has changed:', result2); 
  console.log('freuency has changed:', FreqParam); 
  console.log('Term has changed:', TermVal); 
}, [askingprice,interestRate,amortznPeriod,FreqParam,TermVal,mortgageamountVal,dataList]);
  
console.log("result from parent",result)

useEffect(() => {
  myfunction(askingprice)
}, [askingprice]);

useEffect(() => { 
myTermFunction(TermVal)
}, [TermVal]);

useEffect(() => {
myFunctionFreq(FreqParam)
}, [FreqParam]);


const myfunction = (arrayp)=>{
console.log("array:",arrayp)

}
const myTermFunction = (arrayTerm)=>{
  console.log("array:",arrayTerm)
  
  }

  const myFunctionFreq = (arrayFreq)=>{
    console.log("array:",arrayFreq)
    
    }
  






  return (
    <Grid container sx={{ width:  '100%', height: '100%',margin:0,padding:0 }}>
    <Box  sx={{ height:{md:'1150px',sm:'1125px'} , width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400",margin:0,padding:0 }}>

      <AppBar position="static" sx={{ bgcolor: "#0C276C", height: "72px", width: '100%' }}>
      <Box sx={{display: "flex" ,width:'100%',flexDirection:'row',justifyContent:'center',alignItems:'center',height: "72px"}}> 
       
          <Box sx={{display: "flex" ,width:{md:'10%',sm:'12%'},flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <img src={logo} alt=""  style={{              
               
               height: '85%',
               width: '90%',
              
             }} />
             
           
</Box>
            <Box sx={{display: "flex",width:'80%',flexDirection:'row',justifyContent:'left',alignItems:'left',paddingLeft:{md: "5%",sm:'3%'}, }}>
              {menus.map((menu) => (
                <Button
                  key={menu}

                  sx={{
                    color: "white",  paddingRight:{md: "7%",sm:'3%'}, textTransform: "capitalize", fontFamily: "poppins",
                    fontSize: "14px", lineHeight: "21px", fontWeight: "400", '&:hover': { color: "#939393",bgcolor:'#0C276C' }
                  }}
                >
                  {menu}
                </Button>
              ))}</Box>
  <Box sx={{display: "flex" ,width:{md:'10%',sm:'17%'},justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
              <Button variant="contained" onClick={handleClickSignIn}
                sx={{
                  bgcolor: "#EB5648", color: "#fff", borderRadius: "16px", 
                  padding: " 4px, 10px, 4px, 10px", textTransform: "capitalize", fontFamily: "poppins", fontSize: "14px",
                  lineHeight: "21px", fontWeight: "400", width: "90%", height: "36px", '&:hover': { bgcolor: "#fff",color:'#0C276C' }
                }} >Sign in</Button></Box>
            
            {showSignIn && <SignIn />}
          
      
        </Box>
      </AppBar> 


     
        <Box sx={{ width:'calc(100%-30%)', alignItems:'center' , justifyContent:'center', display:'flex', flexDirection:'row',marginLeft:{md:'10%',sm:'5%'},
        marginRight:{md:'14%',sm:'5%'},marginTop:{md:'2.5%',sm:'5%'}}}>
         
          <Box sx={{ width:{md:'15%',sm:'25%'}, alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row',}}>
            <img src={ImgFrame} alt="" />
            </Box>
            <Box sx={{ width:{md:'75%',sm:'100%'}, alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row', marginRight:{md:'10%',sm:'0%'}}}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "12px", lineHeight: "24px",
              fontWeight: 400, width: '100%'

            }}>
              Whether you are applying for a new mortgage or thinking about switching lenders, This comparison calculator can help you explore
              and pick the mortgage option that’s right for you. Use this Mortgage comparison tool to compare mortgage platfrom top lenders and
              find the one that best suits your needs.
            </Typography>
          </Box>
         
        </Box>

        <Box  sx={{ width:'calc(100%-30%)', alignItems:'center' , justifyContent:'center', display:'flex', flexDirection:'row',marginLeft:{md:'10%',sm:'5%'},
        marginRight:{md:'22%',sm:'6%'},marginTop:{md:'1%',sm:'3%'},borderTop:'2px solid #E2E2E2'}}>
        </Box>

        <Box  sx={{ width:'calc(100%-30%)', alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row'
        ,marginLeft:{md:'10%',sm:'5%'},        marginRight:{md:'18.3%',sm:'6%'},        marginTop:{md:'2%',sm:'3%'}}} >
          <Box sx={{ width:{md:'calc(27.3%)',sm:'37.5%'}, alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row'
        
          }} >
            <Typography style={{
              fontFamily: "poppins", fontSize: "16px", lineHeight: "24px", fontWeight: "500", color: "#313131", display: 'flex',
              alignItems: 'center'
            }}>Purchase price<ArrowForwardIcon sx={{ marginLeft: "5px", color: "#313131", fontSize: "16px", lineHeight: "24px" }} />
            </Typography>

          </Box>
          <Box sx={{width:{md:'94%',sm:'100%'},alignItems:'center' , justifyContent:'center', display:'flex', flexDirection:'row',gap:{md:'4%',sm:'4%'},
          marginRight:{md:'5%',sm:'0%'}
          }} >

          <Box sx={{width:{md:'48%',sm:'65%'},alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row'}} >

            <Paper
              component="form" sx={{
                height: "40px", border: `1px solid ${isActiveAskingPrice ? '#0C276C' : '#CFC9C9'}`,
                borderRadius: "4px", boxShadow: isActiveAskingPrice ? '2px 0px 4px rgba(0, 0, 0, 0.25)' : 'none', display: 'flex', 
                alignItems: 'center',width:'100%',
              }}  >

              <InputBase  onChange={(event) =>{  handleChangeAskingPriceValue(event,dataList.index)}} value={askingPriceValue}
                onBlur={handleInputBlurAskingPrice}
                sx={{ ml: 1, flex: 1, fontFamily: "Roboto", fontSize: "12px", lineHeight: "14px", fontWeight: "400",
                
              }}
                placeholder="Asking Price"
                inputProps={{ 'aria-label': 'Asking Price' ,sx: { color:'#626262'} }} 
              />
            </Paper>

          </Box>
          <Box sx={{width:{md:'48%',sm:'65%'},alignItems:'left' , justifyContent:'left', display:'flex', flexDirection:'row'}}   >
            <Paper
              component="form"
              sx={{
                display: 'flex', alignItems: 'center', height: '40px', borderRadius: '8px',width:'100%',
                border: `1px solid ${isActiveLocation ? '#0C276C' : '#CFC9C9'}`, boxShadow: isActiveLocation ? '2px 0px 4px rgba(0, 0, 0, 0.25)' : 'none',
              }}
            >
              <InputBase onClick={handleInputClickLocation}
                onBlur={handleInputBlurLocation}
                sx={{
                  ml: 1, flex: 1, fontFamily: 'Roboto', fontSize: '12px', lineHeight: '14px', fontWeight: '400', 
                }}
                placeholder="Location" inputProps={{ 'aria-label': 'Location',style: { color:'#626262'}, }}
              />
              <IconButton type="button" sx={{ color: '#626262',width:'15px',height:'15px',paddingRight:'5%' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>


          </Box>
          </Box>
        </Box>






        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',width:'calc(100%-20%)' ,marginLeft:{md:'10%',sm:'5%'},
        marginRight:{md:'10%',sm:'5%'} , marginTop:{md:'35px',sm:'35px'} ,gap:{md:'2%',sm:'0'},
       }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width:{md:'20%',sm:'27%'}  ,
            alignItems: 'center', gap:{md:'5%',sm:'25px'} ,height: "400px",  
          }}>
           
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center', marginTop: { md: '12px', sm: '12px' },
            }}>
              {/* <Typography sx={{
              fontFamily: 'poppins', fontSize: '16px',
              lineHeight: '24px', fontWeight: '600', color: '#313131'
             }}>Input parameters</Typography> */}
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '16px',
              lineHeight: '24px', fontWeight: '600', color: '#313131', marginRight: 'auto',
            }}>Input parameters</Typography> </Box>
            <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'3px',sm:'0px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Purchase price</Typography> <HelpIcon onMouseEnter={handleAskingPriceTextClick} onMouseLeave={handleAskingPriceTextClickLeave} sx={{
              fontSize: '12px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showAskingPriceText && (  <Box
            sx={{
              position: 'absolute',
              top:{md:'53%',sm:'34%'} ,
              left: {md:'23%',sm:'27%'},
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>This shows the total amount to be paid to seller/builder</Typography></Box>)}
             </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'7px',sm:'0px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Initial payment</Typography>  <HelpIcon onMouseEnter={handleInitialPaymentTextClick} onMouseLeave={handleInitialPaymentTextClickLeave} sx={{
              fontSize: '12px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showInitialpaymentText && (  <Box
            sx={{
              position: 'absolute',
              top:{md:'60%',sm:'34%'} ,
              left: {md:'23%',sm:'27%'},
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Portion of  purchase price arranged from own fund , not from mortgage loan</Typography></Box>)}
             </Box>
             <Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'7.5px',sm:'2px'},
          }}>
             <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}> Mortgage amount</Typography>  <HelpIcon onMouseEnter={handleMortgageAmountTextClick} onMouseLeave={handleMortgageAmountTextClickLeave} sx={{
              fontSize: '12px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showMortgageAmountText && (  <Box
            sx={{
              position: 'absolute',
              top:{md:'67%',sm:'34%'} ,
              left: {md:'23%',sm:'27%'},
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Required Mortgage Amount</Typography></Box>)}
             </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'4.5px',sm:'2px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Term</Typography> <HelpIcon onMouseEnter={handleTermClick} onMouseLeave={handleTermClickLeave} sx={{
              fontSize: '12px',cursor: 'pointer',
              color: '#878787'
            }} />
             {showTermText && (  <Box
            sx={{
              position: 'absolute',
              top:{md:'72.5%',sm:'41%'} ,
              left: {md:'23%',sm:'27%'},
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>The number of years will be engaged with proposed mortgage</Typography></Box>)}
             </Box>
          
            
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'8px',sm:'1px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px', 
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Interest rate (%)</Typography> <HelpIcon onMouseEnter={handleAnnualInterestRateClick} onMouseLeave={handleAnnualInterestRateClickLeave} sx={{
              fontSize: '12px',cursor:'pointer',
              color: '#878787'
            }} /> 
             {showAnnualInterestRateText && (  <Box
            sx={{
              position: 'absolute',
              top:{md:'79%',sm:'45%'} ,
              left: {md:'23%',sm:'27%'},
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Annual interest rate for this mortgage</Typography></Box>)}
            </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'6px',sm:'3px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}> Amortization period</Typography><HelpIcon onMouseEnter={handleAmortizationTextClick} onMouseLeave={handleAmortizationTextClickLeave} sx={{
               fontSize: '12px', color: '#878787',            cursor:'pointer' }} />
             {showAmortizationText && (  <Box
            sx={{
              position: 'absolute',
              top: {md:'85.5%',sm:'48.5%'} ,
              left:  {md:'23%',sm:'27%'},
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>The number of years & months over which you will repay this loan</Typography></Box>)}
            
            </Box>
<Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'7px',sm:'1px'},
          }}>
            <Typography sx={{
              fontFamily: 'poppins', fontSize: '14px',
              lineHeight: '21px', fontWeight: '400', color: '#313131',marginRight: 'auto',
            }}> Payment frequency</Typography><HelpIcon onMouseEnter={handlePaymentfreequencyTextClick} onMouseLeave={handlePaymentfreequencyTextClickLeave} sx={{
              fontSize: '12px', cursor:'pointer',
              color: '#878787'
            }} />
            {showPaymentfreequencyText && (  <Box
            sx={{
              position: 'absolute',
              top: {md:'92%',sm:'52.5%'},
              left:  {md:'23%',sm:'27%'},
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Monthly, weekly, biweekly(Every two weeks), semi monthly(24 x per year)</Typography></Box>)}
            </Box>
             
          </Box>
       
<Box sx={{width:{md:'calc(77%)',sm:'calc(78%)'}, display: 'flex', flexDirection: 'row', alignItems: 'center',marginLeft:{md:'0',sm:'1.5%'} 
           ,justifyContent:'center',gap:{md:'4.2%',sm:'1.5%'},
          }}>
          {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex).map((data, index) => (
            <Box key={index} className="slide"  sx={{width:'calc(75%)'}} >
              <DivComponents dataList={dataList} setDataList={setDataList}
                index={index} parentIndex={index} CheckboxEnabled={CheckboxEnabled} setCurrentSlideIndex={setCurrentSlideIndex}
                currentSlideIndex={currentSlideIndex} mortgageName={data.name}
                isDeleteButtonEnabled={isDeleteButtonEnabled} selectedCheckboxList={selectedCheckboxList}
                setCheckboxSelectedList={setCheckboxSelectedList} askingPriceValue={askingPriceValue} 
                FreqParam={FreqParam} setFreqParam={setFreqParam} amortznPeriod={amortznPeriod} setAmortznPeriod={setAmortznPeriod}
                TermVal={TermVal} setTermVal={setTermVal} askingprice={askingprice} setAskingPrice={setAskingPrice}
          
                result={result} setResult={setResult}  handleChangeTerm={handleChangeTerm} 
                handleChangeInterest={handleChangeInterest} handleChangePeriod={handleChangePeriod} handleChangeMortgageAmount={handleChangeMortgageAmount}
                handleChangeAskingprice={handleChangeAskingprice} handleChangeFreequency={handleChangeFreequency} handleChangeInitialPayment={handleChangeInitialPayment}
                mortgageAmountTextValue={mortgageAmountTextValue} setMortgageAmountTextValue={setMortgageAmountTextValue}
                askingPriceVariableParent={askingPriceVariableParent}  setAskingPriceVariableParent={setAskingPriceVariableParent} setFinalPrice={setFinalPrice}
              />
            </Box>
          ))}
</Box>


          {!CheckboxEnabled && <Box sx={{ display: { md: 'flex', sm: 'none' }, flexDirection: 'row', justifyContent: 'center',
           width: { md: '15%', sm: '0' } }}>
            <Button onClick={handleAddSlide} disabled={dataList.length === 7} variant="contained" style={{
              color: "white", height: '30px',width:'100%',
              marginTop: "120px", borderRadius: "12px", background: isHoveredAddMortgage && dataList.length === 7
                ? '#939393' : isHoveredAddMortgage ? '#074554' : '#0C276C',
              border: dataList.length === 7 ? "1px solid #939393" : "1px solid #0C276C", fontFamily: "Roboto",
              textTransform: "none", fontStyle: "normal", fontWeight: "500", fontSize: "12px",
            }} onMouseEnter={handleMouseEnterAddMortgage}
              onMouseLeave={handleMouseLeaveAddMortgage}
              startIcon={<AddCircleOutlineIcon style={{ color: "white", fontSize: "12px" }} />}>
              Add mortgage
            </Button>
            
          </Box>}
          {CheckboxEnabled && <Box sx={{ display:{md:'flex',sm:'none'} , flexDirection: 'row', justifyContent: 'center' ,
          width:{md:'13.5%',sm:'0'}}}>
            
          </Box>}
          
        </Box>  



        <Box sx={{ display: 'flex', flexDirection: "row", marginTop:{md:'2%',sm:'3.5%'} , alignItems: 'center',width:'calc(100%-30%)',
        marginLeft:{md:'10%',sm:'5%'}, marginRight:{md:'10%',sm:'5%'}   
        
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'17.1%',sm:'27%'},
             alignItems: 'center'
          }}>
           <Typography style={{
           fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
            color: " #313131",marginRight: 'auto',
          }}> Scheduled Payment</Typography><HelpIcon onMouseEnter={handlePaymentSheduleTextClick1} onMouseLeave={handlePaymentSheduleTextClick1Leave} sx={{
            fontSize: '12px', cursor:'pointer',
            color: '#878787'
          }} />
          {showPaymentSheduleText1 && (  <Box
          sx={{
            position: 'absolute',
            top: {md:'103.5%',sm:'52.5%'},
            left:  {md:'23%',sm:'27%'},
            zIndex: 9999,
            background: '#F9F9F9',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '7px',
          }}
        > <Typography sx={{
            fontFamily: 'poppins', fontSize: '12px',
            lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
          }}>Required payment  on each scheduled payment date</Typography></Box>)}
          </Box>
         
          {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex).map((value, index) => (
  <Box key={index} sx={{
    display: 'flex', flexDirection: 'row', width: { md: '17.5%', sm: '17%' }, justifyContent: 'center',
    alignItems: 'center',
  }}>
    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
      color: " #313131",
    }}>{value.SheduledResult}</Typography>
  </Box>
))}

            
          {/* <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131",
          }}> Result</Typography></Box>
           <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'13%',sm:'17%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131"
          }}> Result</Typography></Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'12%',sm:'17%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131",
          }}> Result</Typography></Box>
            <Box sx={{
            display: 'flex', flexDirection: 'row', width:{md:'13.5%',sm:'15%'},justifyContent:'center',
             alignItems: 'center',
          }}>
          <Typography style={{
            fontFamily: "poppins", fontSize: "14px", fontWeight: "600",
            color: " #313131"
          }}>Result</Typography></Box> */}

        </Box>

        {!CheckboxEnabled && <Box sx={{ display:{md:'none',sm:'flex'} , flexDirection: 'row', justifyContent: 'right' ,width:{md:'0',sm:'94%'}}}>
            <Button onClick={handleAddSlide} disabled={dataList.length === 7} variant="contained" style={{
                marginTop: "18px",color: "white", height: '30px',
              borderRadius: "12px", background: isHoveredAddMortgage && dataList.length === 7
                ? '#939393' : isHoveredAddMortgage ? '#074554' : '#0C276C',
              border: dataList.length === 7 ? "1px solid #939393" : "1px solid #0C276C", fontFamily: "Roboto",
              textTransform: "none", fontStyle: "normal", fontWeight: "500", fontSize: "12px",
            }} onMouseEnter={handleMouseEnterAddMortgage}
              onMouseLeave={handleMouseLeaveAddMortgage}
              startIcon={<AddCircleOutlineIcon style={{ color: "white", fontSize: "12px" }} />}>
              Add mortgage
            </Button>
            
          </Box>}
         
        <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '15px',width:'calc(100%-30%)',marginRight:{md:'24%',sm:'6%'},
        marginLeft:{md:'12%',sm:'5%'} ,justifyContent:'right',marginBottom:{md:'35px',sm:'30px'}
       }}>

          {/* <Link href="#" underline='hover'
            style={activeLink === 'Calculate Payment' ? { ...linkstyles.link1, ...linkstyles.activeLink } : linkstyles.link1}
            onClick={handlePaymentCalc}>
            Calculate payment
          </Link>


          <Link href="#" underline='hover' rel="noopener"
            style={activeLink === 'Calculate Penalty on cancellation' ? { ...linkstyles.link2, ...linkstyles.activeLink } : linkstyles.link2}
            onClick={handlePenaltyCalc}>
            Calculate penalty on cancellation
          </Link> */}


          {IsChevorButton && <ChevronLeftIcon onClick={handlePrevSlide} disabled={currentSlideIndex === 4} sx={{
            background: "#CFDAF4", marginLeft:{md:'35%',sm:'12%'} , cursor: currentSlideIndex === 4?'default':'pointer',
            color: currentSlideIndex === 4 ? "#CCCCCC" : "#0C276C", boxShadow: "2px 0px 4px 0px #00000040 ", borderRadius: "50%",
            width: "36px", height: '36px'
          }} ></ChevronLeftIcon>}



          {IsChevorButton && <ChevronRightIcon onClick={isdisableAddSlidebutton ? null : handleNextSlide} sx={{
            background: "#CFDAF4", marginLeft:{md: "20px",sm:'15px'} , cursor: isdisableAddSlidebutton?'default':'pointer',
            boxShadow: "2px 0px 4px 0px #00000040", color: isdisableAddSlidebutton ? "#CCCCCC" : "#0C276C", borderRadius: "50%",
            width: "36px", height: '36px'
          }} ></ChevronRightIcon>}

        </Box>

        {calcContent === "Payment Content" && <Box className="carousel" sx={{
          display: 'flex', flexDirection: "row", alignItems: "center",  width:'calc(100%-30%)',marginTop:{md:'-1.5%',sm:'-5%'},
          marginRight:{md:'13.8%',sm:'5%'},marginLeft:{md:'10%',sm:'5%'},
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width:{md:'26%',sm:'27%'}  ,
             alignItems: 'center', gap: '10%', height: "218px",
          }}>
           < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'30%',sm:'48%'}
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}>Scheduled Payment</Typography> <HelpIcon onMouseEnter={handlePaymentSheduleTextClick2} onMouseLeave={handlePaymentSheduleTextClick2Leave} sx={{
              fontSize: '12px', cursor:'pointer',
              color: '#878787'
            }} />
            {showPaymentSheduleText2 && (  <Box
            sx={{
              position: 'absolute',
              top: {md:'119.5%',sm:'52.5%'},
              left:  {md:'23%',sm:'27%'},
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Required payment  on each scheduled payment date</Typography></Box>)}
             </Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'-5px',sm:'-1px'}
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}>Total amount paid</Typography><HelpIcon onMouseEnter={handleTotalAmountPaidTextClick} onMouseLeave={handleTotalAmountPaidTextClickLeave} sx={{
              fontSize: '12px', cursor:'pointer',
              color: '#878787'
            }} />
            {showTotalAmountPaidText && (  <Box
            sx={{
              position: 'absolute',
              top: {md:'125%',sm:'52.5%'},
              left:  {md:'23%',sm:'27%'},
              width:'40%',
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Total of all payments made during the Term and Amortization period respectively, assuming that the conditions of your loan
             (e.g. interest rate, amortization period, term, etc.) will not change during these periods.</Typography></Box>)}</Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',marginTop:'-2.3px'
            }}>Total interest paid </Typography> <HelpIcon onMouseEnter={handleTotalInterestPaidTextClick} onMouseLeave={handleTotalInterestPaidTextClickLeave} sx={{
              fontSize: '12px', cursor:'pointer',
              color: '#878787'
            }} />
            {showTotalInterestPaidText && (  <Box
            sx={{
              position: 'absolute',
              top: {md:'130%',sm:'52.5%'},
              left:  {md:'23%',sm:'27%'},
              width:'40%',
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>Total of all interest paid during the Term  period respectively assuming that the conditions of your loan
            </Typography></Box>)}</Box>
                < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'-4px',sm:'-1px'}
          }}>
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',
            }}> Total principle Paid</Typography><HelpIcon onMouseEnter={handleTotalPrinciplePaidTextClick} onMouseLeave={handleTotalPrinciplePaidTextClickLeave} sx={{
              fontSize: '12px', cursor:'pointer',
              color: '#878787'
            }} />
            {showTotalPrinciplePaidText && (  <Box
            sx={{
              position: 'absolute',
              top: {md:'135.5%',sm:'52.5%'},
              left:  {md:'23%',sm:'27%'},
              width:'40%',
              zIndex: 9999,
              background: '#F9F9F9',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '7px',
            }}
          > <Typography sx={{
              fontFamily: 'poppins', fontSize: '12px',
              lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
            }}>The total amount of principal payment made during the Term and Amortization period respectively</Typography></Box>)}</Box>

          </Box>
          <Box sx={{width:{md:'calc(100%)',sm:'calc(78%)'}, display: 'flex', flexDirection: 'row', alignItems: 'center',marginLeft:{md:'2.5%',sm:'1.5%'} 
           ,justifyContent:'center',gap:{md:'4.2%',sm:'1.5%'} ,
          }}>

{dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex)

.map((slide, index) => (
  <Box key={index} className="slide"  sx={{width:'calc(75%)'}} >
    <Payment dataList={dataList} index={index} mortgageName={slide.name} onChange={() => handleNextCheckboxChange(slide.name)}
      isCheckedCheckboxNext={selectedCheckboxList.includes(slide.name)} result2={result2} result={result}

    />
  </Box>
))}

          {/* {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex)

            .map((slide, index) => (
              <Box key={index} className="slide"  sx={{width:'calc(75%)'}} >
                <Payment dataList={dataList} index={index} mortgageName={slide.name} onChange={() => handleNextCheckboxChange(slide.name)}
                  isCheckedCheckboxNext={selectedCheckboxList.includes(slide.name)}  result2={result2} result={result}
                />
              </Box>
            ))} */}
</Box>
<Box sx={{ display:{md:'flex',sm:'none'} , flexDirection: 'row', justifyContent: 'center' ,width:{md:'15.5%',sm:'0'}}}> </Box>
        </Box>}

        {calcContent === "Penalty Content" && <Box className="carousel" sx={{
          display: 'flex', flexDirection: "row", alignItems: "center",  width:'calc(100%-30%)',
          marginRight:{md:'14%',sm:'5%'},       marginLeft:{md:'12%',sm:'5%'}
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', width:{md:'17.5%',sm:'27%'}  ,
            height: '334px', alignItems: 'center', gap: '5%', 
          }}>
           < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center',marginTop:{md:'43.5%',sm:'49.5%'}
          }}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131", marginTop: '12px',marginRight: 'auto',
            }}>Cancellation </Typography> <HelpIcon sx={{ fontSize: "12px", color: "#878787",marginTop: '10px'}} /> </Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',marginTop: '-2px',
            }}>Remaining principle</Typography><HelpIcon sx={{ fontSize: "12px", color: "#878787" }} /></Box>
             < Box sx={{
            display: 'flex', flexDirection: 'row', width:'100%',
             alignItems: 'center'
          }}>
            <Typography sx={{
              fontFamily: "poppins", fontSize: "14px", fontWeight: "400",
              color: " #313131",marginRight: 'auto',marginTop: '-2px',
            }}> Calculated penalty</Typography> <HelpIcon sx={{ fontSize: "12px", color: "#878787" }} /></Box>

          </Box>
          <Box sx={{width:{md:'calc(75%)',sm:'calc(78%)'}, display: 'flex', flexDirection: 'row', alignItems: 'center',marginLeft:{md:'1.9%',sm:'1.5%'} 
           ,justifyContent:'center',gap:{md:'1.5%',sm:'1.5%'} ,marginTop:'-7%'
          }}>
          {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex)
            .map((slide, index) => (
              <Box key={index} className="slide" sx={{width:'calc(75%)'}}>
                <Penalty dataList={dataList} index={index} mortgageName={slide.name} onChange={() => handleNextCheckboxChange(slide.name)}
                  isCheckedCheckboxNext={selectedCheckboxList.includes(slide.name)} />
              </Box>
            ))}
</Box>
<Box sx={{ display:{md:'flex',sm:'none'} , flexDirection: 'row', justifyContent: 'center' ,width:{md:'15.7%',sm:'0'}}}> </Box>
        </Box>}

        <Box
  sx={{
    display: 'flex', flexDirection: "row", 
    paddingTop:{md:'3.5%',sm:'-5%'} , 
    alignItems: 'center',
 justifyContent: 'center' ,
    position: 'fixed',
    left: 0,
    bottom: 0,
   width: 'calc(100% )', 
   height:{md:'10%' ,sm:'17%'} ,
   gap:{md:'2%',sm:'1%'},
 boxShadow:' 0px -4px 4px rgba(217, 217, 217, 0.25)',
   backgroundColor:'white',
   zIndex:'3'
  }}
>
        
        {thebidding === "bidding disable" && <Box sx={{display: 'flex', flexDirection: "row", marginTop:{md:'-3.5%',sm:'-3%'} , alignItems: 'center',width:'calc(100%)' 
        ,marginLeft:{md:'12%',sm:'5%'},marginRight:{md:'25%',sm:'5%'},justifyContent: 'center' , gap:{md:'1%',sm:'1%'},paddingTop:{md:'0%',sm:'3%'}
        }}>


          <Button variant="contained" onMouseEnter={handleMouseEnterTheBidding}
            onMouseLeave={handleMouseLeaveTheBidding} onClick={handleAddBiddingCheckbox} style={{
              color: "white", textTransform: "none",
              background: isHoveredTheBidding ? "#074554" : "#0C276C", border: "1px solid #0C276C", fontFamily: "Poppins",
              fontStyle: "normal", lineHeight: "21px", height: '36px',
              fontWeight: "500", fontSize: "14px", width:"35%", borderRadius: "4px",
            }} startIcon={<img src={dataicon} alt="" />} >
            Start the bidding
          </Button>
          <Button variant="contained" onMouseEnter={handleMouseEnterSendToMail}
            onMouseLeave={handleMouseLeaveSendToMail} style={{
              color: isHoveredSendToMail ? "white" : "#0C276C", textTransform: "none", marginLeft: "14px",
              background: isHoveredSendToMail ? "#0C276C " : "#CFDAF4", border: "1px solid #0C276C", fontFamily: "Poppins", fontStyle: "normal", lineHeight: "21px",
              fontWeight: "500", fontSize: "14px", width:{md:"170px",sm:'190px'} , borderRadius: "4px", height: '36px'
            }}
            startIcon={<MailOutlineIcon style={{ color: isHoveredSendToMail ? "white" : "#0C276C", }} />}>
            Send to email
          </Button>
          <Button variant="contained" onMouseEnter={handleMouseEnterDownloadResult}
            onMouseLeave={handleMouseLeaveDownloadResult} style={{
              color: isHoveredDownloadResult ? "white" : "#0C276C", textTransform: "none", marginLeft: "14px", height: '36px',
              background: isHoveredDownloadResult ? "#0C276C " : "#CFDAF4", border: "1px solid #0C276C", fontFamily: "Poppins",
              fontStyle: "normal", lineHeight: "21px", fontWeight: "500", fontSize: "14px", width: "204px", borderRadius: "4px",
            }} startIcon={<GetAppIcon style={{ color: isHoveredDownloadResult ? "white" : "#0C276C", }} />}>
            Download result
          </Button>

        </Box>}
        {thebidding === "bidding enable" && <Box sx={{display: 'flex', flexDirection: "row",  marginTop:{md:'-3.5%',sm:'-5%'}, alignItems: 'center',
        width:'calc(100%-30%)',justifyContent:'center' 
        ,marginLeft:{md:'12%',sm:'5%'},marginRight:{md:'15%',sm:'5%'}}}>



          <Button variant="contained" onClick={handleNextClick} disabled={!isNextButtonEnabled} style={{
            color: "white", textTransform: "none",
            background: isHoveredNext && isNextButtonEnabled
              ? '#074554' : !isNextButtonEnabled ? '#939393' : '#0C276C', border: !isNextButtonEnabled ? "1px solid #939393" : "1px solid #0C276C",
            fontFamily: "Poppins", fontStyle: "normal",
            lineHeight: "21px", fontWeight: "500", fontSize: "14px", width: "348px", borderRadius: "4px",height:'36px',
          }} onMouseEnter={handleMouseEnterNext}
            onMouseLeave={handleMouseLeaveNext} >
            Next<ArrowForwardIcon style={{ marginLeft: "5px", color: "white", fontSize: "16px" }} />
          </Button>
         



          </Box>}

        </Box>
        {showPopupforCheckbox && (<CheckboxPopUp ></CheckboxPopUp>)}
      </Box>
    </Grid>
  );

}

export default AddMortgage;