import Box from '@mui/material/Box';
import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import '../App.css';
import Header from './HeaderLanding'
import HeaderMobile from './HeaderLandingMobile';
import Footer from './Footer';

function PrivacyPolicy() {

    const [currentPage, setCurrentPage] = useState('');
    return (
        <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>
            <Box sx={{
                height: '100% ', width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'flex', sm: 'flex', xs: 'none' }
            }}>
                <Header currentPage={currentPage}></Header>
            </Box>
            <Box sx={{
                height: '100% ', width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'none', sm: 'none', xs: 'flex' }
            }}>
                <HeaderMobile currentPage={currentPage}></HeaderMobile>
            </Box>
            <Box sx={{
                width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)', xs: 'calc(100%-10%)' }, display: 'flex', flexDirection: 'row', height: '100%',
                paddingLeft: { md: '16%', sm: '5%', xs: '5%' }, paddingRight: { md: '16%', sm: '5%', xs: '5%' }, paddingTop: { md: '50px', sm: '50px', xs: '25px' },
                paddingBottom: { md: '25px', sm: '25px', xs: '5%' },
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', width: '100%', height: '100%',
                    background: "#F9F9F9 ", padding: { md: '50px ', sm: '50px ', xs: '25px ' }, alignItems: 'center'
                }}>

                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: { md: '26px', sm: '26px', xs: '20px' }, paddingTop: { md: '25px', sm: '20px', xs: '0px' },
                        lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                    }}>
                        Privacy Policy
                    </Typography>
                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: { md: '25px', sm: '20px', xs: '0px' },
                        lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                    }}>
                        Last Updated: August 15, 2023
                    </Typography>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', height: '100%',
                        padding: '10px 0px 25px 0px', alignItems: 'center', gap: '10px'
                    }}>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>
                            This statement has been developed to comply with Canada’s Personal Information Protection and Electronic Documents Act (PIPEDA). PIPEDA sets out rules for the collection, use and disclosure of a member’s or volunteer’s personal information, as well as safeguarding that information in the course of its activity as defined in the legislation.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            What Is “Personal Information”

                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>
                            Under PIPEDA, “Personal Information” means any information that is identifiable to an individual, including name, address, telephone number, Social Insurance Number, and date of birth. It also includes, but is not limited to, other information relating to identity, such as, nationality, gender, marital status, financial information and credit history.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            Purposes For Personal Information
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>
                            Ratewaves  collects only that personal information required to maintain contact with an individual who expressed his interest to reach out us..
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            Consent
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>
                            At the time of completing a contact form,  consent of the person will be sought for the collection, use, retention and disclosure of their personal information.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>
                            An applicant may choose not to provide some or all of the personal information requested.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            Accuracy
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves   ensure that all personal information in active files is accurate, current and complete. When a person notifies the Ratewaves   that their  personal information requires correction or updating, the necessary changes will be made. Information contained in closed files is not updated.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            Limiting Use, Retention & Disclosure
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves uses and retains personal information for only those purposes to which the person has consented.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            Safeguards
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves utilizes a number of physical, organizational and technological measures to safeguard personal information from unauthorized access or inadvertent disclosure including but not limited to:
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '10px', paddingLeft: '30px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            Physical
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px', paddingLeft: '31px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Active files are stored in computers accessible by Ratewaves executive and directors.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '10px', paddingLeft: '30px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            Organizational
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px', paddingLeft: '31px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The Ratewaves executive and directors sign confidentiality agreements binding them to safeguarding the confidentiality of personal information to which they have access.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '10px', paddingLeft: '30px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            Technological
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px', paddingLeft: '31px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Personal information is retained on Ratewaves computers which are password protected.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '10px', paddingLeft: '30px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            Electronic Transmission of Information
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px', paddingLeft: '31px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Notwithstanding the technological safeguards, internet transmissions are susceptible to possible loss, misrouting, interception and misuse. For this reason, as part of the application or agreement that people sign consenting to their personal information being collected, used, retained, and disclosed, the Ratewaves will assume that it has the consent to communicate via the internet unless notified to the contrary.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            Individual Access
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>An individual who wishes to review or verify what personal information is held by the Ratewaves , may do so by making a request, in writing to the Privacy Officer  PrivacyOfficer@Ratewaves.com. Upon verification of the individual’s identity, the Privacy Officer will provide a written report within 60 days.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            Investigating Complaints
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Any concern or issue about the Ratewaves’s  personal information handling practises may be made, in writing, to the Privacy . Upon verification of the person’s identity, the Privacy Officer   will act promptly to investigate the complaint and provide a written report to the person.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If the individual is dissatisfied with the report provided by the President, or feels that the corrective action taken by the Ratewaves is insufficient, the person may direct a complaint to the Federal Privacy Commissioner.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                            Amendments To Our Privacy Policy
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The Ratewaves  Privacy Statement is in effect August 15, 2023. The Statement may be updated to reflect amendments in applicable laws. Any changes to this Statement will be posted on the Ratewaves  website, and will apply to personal information collected from the date of the posting.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                           Contact Information
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If you have any questions regarding the Ratewaves  Privacy Privacy Statement, or you wish to make an access to personal information request, please contact the Privacy Officer PrivacyOfficer@Ratewaves.com.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>Selling of Personal Information:
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves will not involve on following 
                        </Typography>
                        <ul style={{  listStyleType: 'disc', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>selling Personal Information to third parties 
                        </Typography>
                        </li>
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>publishing Personal Information with the intended purpose of charging individuals for its removal 
                        </Typography>
                        </li>
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>collecting, using, or sharing Personal Information that is otherwise unlawful 
                        </Typography>
                        </li>
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>profiling or categorizing clients in a way that leads to unfair, unethical, or discriminatory treatment contrary to human rights law.
                        </Typography>
                        </li>
                        </ul>


                    </Box>
                </Box>
            </Box>
            <Footer></Footer>
        </Grid>
    );

}

export default PrivacyPolicy;