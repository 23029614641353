import { InputLabel, Box } from '@mui/material';
//import { useState } from 'react';

function PenaltyComponent(props) {

    const {mortgageName,isSelected,isCheckedCheckboxNext } = props;

    return (
        <Box sx={{
            display: 'flex', flexDirection: "column", border:isSelected ||isCheckedCheckboxNext? "1px solid #0C276C":"1px solid #CFC9C9",  width: "90%", height: "172px",
            alignItems: "left", borderRadius: "8px", gap: '10%', justifyContent: 'left', 
            background: isSelected ||isCheckedCheckboxNext? "#F9F9F9" : "white" 
        }}>
            
                <InputLabel style={{
                    color: "#313131", textAlign: "center", fontFamily: "poppins", fontSize: "14px",
                    fontWeight: "400", lineHeight: "21px",paddingTop:'12px  '
                }}>
                    {mortgageName}
                </InputLabel>
                <InputLabel style={{ color: "#626262",  fontFamily: "poppins", fontSize: "12px", lineHeight: "18px", fontWeight: "400",
                paddingLeft:'12px' }}>0
                </InputLabel>
                <InputLabel style={{ color: "#626262",  fontFamily: "poppins", fontSize: "12px", lineHeight: "18px", fontWeight: "400",
                paddingLeft:'12px' }}>0
                </InputLabel>
                <InputLabel style={{ color: "#626262", fontFamily: "poppins", fontSize: "12px", lineHeight: "18px", fontWeight: "400",
                paddingLeft:'12px' }}>0
                </InputLabel>
           </Box>
    );
}
export default PenaltyComponent;