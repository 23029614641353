import { Box } from '@mui/material'
import React from 'react'
import UsersNotification from '../components/UsersNotification'
import UserNotificationMobile from '../components/UserNotificationMobile'


function UserNotification() {
  return (
    <div>
     

      <Box sx={{display:{md:'block',xs:'none',sm:'block'}}}>

       <UsersNotification>        
       </UsersNotification>
      </Box>

      <Box sx={{display:{md:'none',xs:'block',sm:'none'}}}>

<UserNotificationMobile>
</UserNotificationMobile>

</Box>
    </div>
  )
}

export default UserNotification
