import { Box, Button, CheckBox, FormControlLabel, FormLabel, IconButton, InputAdornment, Modal, OutlinedInput, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Signin from './SignIn';
import CustomOutlined from './customOutlined';
import PrimaryButton from './customButton';
function QuotesMod() {
    const [openModal, setOpenmodal]=useState(true);
    const handleOpenmodal = () => setOpenmodal(true);
    const handleClosemodal = () => setOpenmodal(false);
    const [showModal,setShowModal] = useState(false);
    const [values, setValues] = useState({
      currentPassword: '',
      newPassword1: '',
      newPassword2: '',
      });
    function showModalHandler(){
      setShowModal(!showModal);
      }
    const [showPasswordValues,setShowPasswordValues]=useState({
      currentPassword: false,
      newPassword1: false,
      newPassword2: false,
    })
    const [error, setError] = useState();
    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
    const handleClickShowPassword = (prop)=>() => {
      setShowPasswordValues({
        ...showPasswordValues,
        [prop]: !showPasswordValues[prop],
      });
    };
      const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
   return (
    <div style={{overflowY:'auto'}} >
        <Modal
        open = {openModal}
        //onClose={handleClosemodal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
     <Box
          sx={{
            position: 'absolute',
            width: '676px',
            height:'504px',
            left:'400px',
            top:'100px',
            bgcolor: '#FFFF',
            paddingBottom:'12px',
            borderRadius:'8px',
            BoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            alignItems:'center',
            }}>
          <div style={{ display: 'flex', flexDirection:'row-reverse',paddingTop:'12px',paddingRight:'12px',gap:'242px' }}>
            <IconButton sx={{ padding: '0px' }} onClick={handleClosemodal} >
              < CancelOutlinedIcon sx={{ color: '#606060', width: '24px', height: '24px' }} />
            </IconButton>
           <Typography sx={{fontSize:'16px',fontWeight:500,color:'#454545',fontFamily:'Poppins'}}>Get the quotes</Typography>
            </div> 
             <div style={{ display: 'flex', flexDirection:'column',paddingLeft:'56px',marginTop:'48px',paddingRight:'56px',gap:'16px'}}>
                <div style={{height:'40px',display: 'flex', flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
              <Typography sx={{textAlign:'left',fontSize:'14px',color:'#313131',fontFamily:'Poppins',fontWeight:400}}>Enter annual house hold income</Typography>
              <CustomOutlined type='password'
                        sx={{width:'240px',backgroundColor:'#FFFFF',fontFamily:'Poppins'}}/> 
                </div>
                <div style={{height:'40px', display: 'flex', flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
              <Typography sx={{textAlign:'left',fontSize:'14px',color:'#313131',fontFamily:'Poppins',fontWeight:400}}>Monthly Line of credit payment</Typography>
              <CustomOutlined type='text' placeholder='Enter amount'
                    sx={{width:'240px',backgroundColor:'#FFFFF',fontFamily:'Poppins'}}/> 
                </div>
                <div style={{height:'40px', display: 'flex', flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
              <Typography sx={{textAlign:'left',fontSize:'14px',color:'#313131',fontFamily:'Poppins',fontWeight:400}}>Monthly Car loan payment</Typography>
              <CustomOutlined type='text' placeholder='Enter amount'
                    sx={{width:'240px',backgroundColor:'#FFFFF',fontFamily:'Poppins'}}/> 
                </div>  
                <div style={{height:'40px', display: 'flex', flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
              <Typography sx={{textAlign:'left',fontSize:'14px',color:'#313131',fontFamily:'Poppins',fontWeight:400}}>Monthly Mortgages</Typography>
              <CustomOutlined type='text' placeholder='Enter amount'
                    sx={{width:'240px',backgroundColor:'#FFFFF',fontFamily:'Poppins'}}/> 
                </div>  
                <div style={{height:'40px', display: 'flex', flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
              <Typography sx={{textAlign:'left',fontSize:'14px',color:'#313131',fontFamily:'Poppins',fontWeight:400}}>Monthly Property Tax</Typography>
              <CustomOutlined type='text' placeholder='Enter amount'
                    sx={{width:'240px',backgroundColor:'#FFFFF',fontFamily:'Poppins'}}/> 
                </div> 
                <div style={{height:'40px', display: 'flex', flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
              <Typography sx={{textAlign:'left',fontSize:'14px',color:'#313131',fontFamily:'Poppins',fontWeight:400}}>Monthly Coondo Fees</Typography>
              <CustomOutlined type='text' placeholder='Enter amount'
                    sx={{width:'240px',backgroundColor:'#FFFFF',fontFamily:'Poppins'}}/> 
                </div>   
               </div>     
              <div align="center" style={{marginTop:'48px'}}>
              <PrimaryButton sx={{width:'224px',fontSize:'14px',fontWeight:500}}>Get the quotes</PrimaryButton>
              </div>
            </Box>
        </Modal>
    </div>
  )
}
export default QuotesMod;