import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react'
import '../App.css';
import Signin from './SignIn';
function HeaderMod() {
  const [showModal,setShowModal] = useState(false);
  const [showModal1,setShowModal1] = useState(false);
  function showModalHandler1(){
    setShowModal1(!showModal1);
}
 function showModalHandler(){
    setShowModal(!showModal);
}
  return (
    <div className="header">
        <div className='logopart'>
            <Box sx={{width:'6.25rem',height:'2.25rem',backgroundColor:'white'}}></Box>
            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'68px'}}>
            <Typography sx={{fontWeight:400,fontSize:{lg:14,md:12,sm:12,xs:12},color:'#FFFFFF',fontFamily:'Poppins'}}>Calculator</Typography>
           <Typography sx={{fontWeight:400,fontSize:{lg:14,md:12,sm:12,xs:12},color:'#939393',fontFamily:'Poppins'}}>Quotations</Typography>
          <Typography sx={{fontWeight:400,fontSize:{lg:14,md:12,sm:12,xs:12},color:'#FFFFFF',fontFamily:'Poppins'}}>Products</Typography>
          <Typography sx={{fontWeight:400,fontSize:{lg:14,md:12,sm:12,xs:12},color:'#FFFFFF',fontFamily:'Poppins'}}>About us</Typography>
          <Typography sx={{fontWeight:400,fontSize:{lg:14,md:12,sm:12,xs:12},color:'#FFFFFF',fontFamily:'Poppins'}}>Contact us</Typography>
          </Box>
        </div>
        <div style={{display:'flex',flexDirection:'row',gap:'2.25rem',alignItems:'center'}}>
            <div style={{display:'flex',flexDirection:'row',gap:'1rem',alignItems:'center'}}>
              <Button sx={{width:{lg:'7.125rem',xs:88},height:'2.25rem',backgroundColor:'white',color:'#212427',textTransform:'none',borderRadius:'1rem',fontSize:{lg:14,md:12,sm:12,xs:12},fontWeight:400,
              fontFamily:'Poppins',
                '&:hover': {
                  backgroundColor:'#1F6373',
                  color: '#A4FCC5',
                  border: '1px solid #A4FCC5',
                },
                '&:active': {
                  backgroundColor: '#1F6373',
                  color:'white',
                  border: '1px solid white'
                },
              }} onClick={showModalHandler1}>Sign in</Button>
              </div>
            {showModal1 ? <Signin />: null }
        </div>
    </div>
  );
}
export default HeaderMod;