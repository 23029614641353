import React from 'react';
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Button, Typography,Grid } from "@mui/material";
import Crop169Icon from "@mui/icons-material/Crop169";
import notification from "./images/Notification (4).png";
import Messages from "./images/Messages.png";
import Discussion from "./images/Discussion.png";
import Profile from "./images/image 22.png";
import { InputLabel } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from "react";
import NotificationDetails from './AgentNotificationDetailMobile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AgentNotificatinMenu from './AgentNotificationMenu';
import HeaderAgentScreenMobile from './HeaderAgentScreenMobile';
function AgentNotificationMobile(){


    const menus = [
        "Calculator",
        "Quotations",
        "Products",
        "About us",
        "Contact us"
      ];
      const [isOpenDrop, setIsOpenDrop] = useState(false);

      const handleMenuToggleDrop = () => {
        setIsOpenDrop(!isOpenDrop);
      };
    
      const handleMenuItemClickDrop = (value) => {
      
        setIsOpenDrop(false);
      }

      const [isOpenSortBy, setIsOpenSortBy] = useState(false);

      const handleMenuToggleSortBy = () => {
        setIsOpenSortBy(!isOpenSortBy);
      };
    
      const handleMenuItemClickSortBy = (value) => {
      
        setIsOpenSortBy(false);
      }

      const code=6611;
      const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const [showNotificationMenu, setShowNotificationMenu] = useState(false);

  const handleClickNotificationImage = () => {
    
    setShowNotificationMenu(!showNotificationMenu);
  };   
      return(
        <Grid container sx={{ width: '100%', height: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
            <HeaderAgentScreenMobile></HeaderAgentScreenMobile>
      
      </Box>
      </Box>
          <Box sx={{display:'flex',marginLeft:'12px',marginRight:'12px',flexDirection:'column',width: 'calc(100%-24px)',marginTop:'60px'}}>
        <InputLabel style={{  fontFamily: "poppins", fontSize: "14px", fontWeight: "500",lineHeight:'21px',color:"#313131",marginTop:'10px',
  }}>Notifications</InputLabel>
  
<AppBar position="static" sx={{ bgcolor: "#1F6373", height: "44px", borderRadius:'8px 8px 0px 0px',marginTop:'10px',paddingLeft:'20px',
justifyContent:'center', width: 'calc(100vw - 24px)',
 }}>
   <Box sx={{display:'flex',flexDirection:'row',alignItems:'right',justifyContent:'right',alignContent:'right',paddingRight: '5% ',}}>
   

 
 <div className="dropdownSortBy" >
        <div className="dropdown__toggleSortBy" onClick={handleMenuToggleSortBy}>
        <Box sx={{display:'flex',flexDirection:'row',alignItems:'right',justifyContent:'right',alignContent:'right'}}>
        <InputLabel style={{  fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'18px',color:"white",
  }}>Sort by</InputLabel> 
        <ExpandMoreIcon sx={{fontSize:'18px',paddingLeft:'5px'}}></ExpandMoreIcon></Box>
        </div>
        {isOpenSortBy && (
 <Box className="dropdown__menuDrop" sx={{
  position: 'absolute', right: '10px', fontFamily: 'poppins', fontSize: '12px', fontWeight: '400',
  lineHeight: '20px', width: '134px', background: '#F8F8F8', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
   zIndex: 5,
  color: '#2F2F2F', padding: '4px'
}}>
  <style>
    {`
.dropdown__menuDrop .MuiMenuItem-root:hover {
background-color: #1F6373;
color: white;
}
`}
  </style>
  <MenuItem onClick={() => handleMenuItemClickSortBy("By date")} value="option1" style={{
    fontFamily: 'poppins', fontSize: '12px',
    fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
  }}>
    
    By date
  </MenuItem>
  <MenuItem onClick={() => handleMenuItemClickSortBy("By time")} value="option2" style={{
    fontFamily: 'poppins', fontSize: '12px',
    fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
  }}>
    
    By time
  </MenuItem>
</Box>


       
        )}
      </div>
      </Box>
     
</AppBar>   
</Box>

          
          <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center',marginLeft:'12px',marginRight:'12px',
     width: 'calc(100vw - 24px)',marginBottom:'30px', }}>


 
      {[...Array(10)].map((_, index) => (
        <React.Fragment key={index} ><NotificationDetails code={code+index} ></NotificationDetails></React.Fragment>
      ))} 
  
    </Box> 




          
          </Grid>
    );
}
export default AgentNotificationMobile;