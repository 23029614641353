import React from 'react';
import Button from '@mui/material/Button';
function SecondaryButton(props)
{
    const {sx,...rest}=props;
    return(
        <Button {...rest}
        sx={{border:'1px solid #1F6373',width:'100%',height:'36px',borderRadius:'8px', fontSize:'16px',fontFamily:'Poppins',fontWeight:500,textTransform:'none',color:'#1F6373',backgroundColor:'#D2E0E3',
               '&:hover': {
                backgroundColor:'#1F6373',
                color: 'white',
                border: '1px solid #1F6373',
              },
              '&:active': {
                backgroundColor: '#1F6373',
                color:'white',
              },...sx
            }} />);
}
export default SecondaryButton;
