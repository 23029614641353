import React from "react";
import UserA from './images/Frame 5346.png';
import { Typography } from '@mui/material';


import { useState } from "react";


import Box from '@mui/material/Box';
import DropdownMenu from "./DropDownMenu";

function UserDetails() {
   const [isHovered, setIsHovered] = useState(false);

   const handleMouseEnter = () => {
      setIsHovered(true);
   };

   const handleMouseLeave = () => {
      setIsHovered(false);
   };

   return (
      <Box sx={{
         display: "flex", direction: "coloumn", backgroundColor: isHovered ? '#F9F9F9' : 'white',
         height: isHovered ? '90px' : '76px', paddingTop: isHovered ? '10px' : '0px', marginTop: isHovered ? '4px' : '0px',
         marginBottom: isHovered ? '4px' : '0px', gap: { md: '4.5%', sm: '2%' },
         borderBottom: '1px solid #D2D2D2', paddingLeft: '8px', alignContent: "center"
      }} onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}>
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: { md: '.5%', sm: '-1%' } }}>
            <img src={UserA} alt="" style={{ marginTop: "15px", marginBottom: "20px", height:'46px' }} />
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: { md: '1.5%', sm: '1%' } }}>
            <Typography sx={{
               fontFamily: "poppins", fontSize: { md: "14px", sm: "12px" }, fontWeight: "400", lineHeight: '21px', color: "#313131",

            }}>$ 3M</Typography>
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Typography sx={{
               fontFamily: "poppins", fontSize: { md: "14px", sm: "12px" }, fontWeight: "400", lineHeight: '21px', color: "#313131",

            }}>$ 25K</Typography>
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: { md: '-1%', sm: '0%' } }}>
            <Typography sx={{
               fontFamily: "poppins", fontSize: { md: "14px", sm: "12px" }, fontWeight: "400", lineHeight: '21px', color: "#313131",

            }}>$ 25K</Typography>
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: { md: '1%', sm: '2%' } }}>
            <Typography sx={{
               fontFamily: "poppins", fontSize: { md: "14px", sm: "12px" }, fontWeight: "400", lineHeight: '21px', color: "#313131",

            }}>$ 25K</Typography>
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: { md: '-1%', sm: '1.2%' } }}>
            <Typography sx={{
               fontFamily: "poppins", fontSize: { md: "14px", sm: "12px" }, fontWeight: "400", lineHeight: '21px', color: "#313131",

            }}>$ 25K</Typography>
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: { md: '1.5%', sm: '1%' } }}>
            <Typography sx={{
               fontFamily: "poppins", fontSize: { md: "14px", sm: "12px" }, fontWeight: "400", lineHeight: '21px', color: "#313131",

            }}>$ 25K</Typography>
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft:{ md: '-1%', sm: '-.3%' }  }}>
            <Typography sx={{
               fontFamily: "poppins", fontSize: { md: "14px", sm: "12px" }, fontWeight: "400", lineHeight: '21px', color: "#313131",

            }}>$ 25K</Typography>
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft:{ md: '-1%', sm: '.3%' }  }}>
            <Typography sx={{
               fontFamily: "poppins", fontSize: { md: "14px", sm: "12px" }, fontWeight: "400", lineHeight: '21px', color: "#313131",

            }}>$ 25K</Typography>
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: '-.5%' }}>
            <Typography sx={{
               fontFamily: "poppins", fontSize: { md: "14px", sm: "12px" }, fontWeight: "400", lineHeight: '21px', color: "#313131",

            }}>$ 25K</Typography>
         </Box>
         {/*<div>

 <Button variant="contained"  style={{
          color: '#313131' , textTransform: "none", padding: "10px 10px 10px 10px ",
          marginTop: "26px", background: "#D5FBFF",boxShadow:'none',
          filter: 'blur(0.5px)', fontFamily: "Poppins", fontStyle: "normal",
          lineHeight: "21px", fontWeight: "500", fontSize: "14px", width: "94px",height:'28px', borderRadius: "24px", marginLeft:'65px'
        }}  >
         New
        </Button>
</div>

<div>
         <InputLabel style={{  fontFamily: "poppins", fontSize: "10px", fontWeight: "400",lineHeight:'15px',color:"#212427",
         marginTop:'33px',marginLeft:'50px'
         }}>12/03/23 : 12 PM</InputLabel> 
      </div> */}
         <div >
            <DropdownMenu />
         </div>


         <div>







         </div>
      </Box>

   );
}
export default UserDetails;