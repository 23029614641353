import React from 'react';

import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Box, InputLabel, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import DeleteIcon from './images/Component 4.png';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';
import '../App.css';
import Tooltip from '@mui/material/Tooltip';

function DivcomponenetMobile(props) {
  const {  initialPaymentValue,setInitialPaymentValue,dataList, setDataList, mortgageName, CheckboxEnabled, isDeleteButtonEnabled,askingPriceValue,handleChangeTerm,handleChangeInitialPayment,
    currentSlideIndex, setCurrentSlideIndex, setCheckboxSelectedList, selectedCheckboxList, isSelected,handleChangeFreequency,setTermVal,TermVal,
    handleChangeInterest,handleChangePeriod,handleChangeAskingprice, result,setResult, FreqParam,setFreqParam,amortznPeriod,setAmortznPeriod,setAskingPrice, askingprice,
    handleChangeMortgageAmount,mortgageAmountTextValue,setMortgageAmountTextValue,askingPriceVariableParent,setAskingPriceVariableParent,setFinalPrice,
    downBorderAmount, setdownBorderAmount,setTooltipTitle,tooltipTitle } = props;
const index=dataList.findIndex(x => x.name ===mortgageName);
    const [selectedOptionVariable, setSelectedOptionVariable] = useState(); //AmortizationPeriod Used this
    const [selectedOptionMonths, setSelectedOptionMonths] = useState( );
    const [selectedOptionMonthly, setSelectedOptionMonthly] = useState();
    const [showPopuptermamort, setShowPopuptermamort] = useState(false);
  const IsDeleteButton = dataList.length > 2 && isDeleteButtonEnabled;


  const handleCheckboxClickAddMortgage = (mortgageName) => {

    const temp = [...selectedCheckboxList];
    if (temp.includes(mortgageName)) {
      temp.splice(temp.indexOf(mortgageName), 1);
    }
    else {
      temp.push(mortgageName);
    }
    setCheckboxSelectedList(temp);

  };
  

  const [showPopup, setShowPopup] = useState(false);


  const handleDeleteClick = (mortgageName) => {
    setShowPopup(true);
  };

  const handleDeleteConfirm = (name) => {
    const temp = dataList.filter(dataList => dataList.name !== mortgageName);
    setDataList(temp);
    setShowPopup(false);
    dataList.length = (dataList.length - 1);
    

    if (currentSlideIndex === 4) {
      setCurrentSlideIndex(currentSlideIndex - 0);
    } else {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
    
   
  };
  console.log(currentSlideIndex)

  const handleCancel = () => {
    setShowPopup(false);
  };

  const handleCancelTerm = () => {
    setShowPopuptermamort(false);
   
    var tempdataList=[...dataList];
     tempdataList[index].period=35;
     setDataList(tempdataList);
  };
 
  const handleOptionChangeAmortization = (event,index) => {
     var tempdataList=[...dataList];
    tempdataList[index].period=parseInt(event.target.value)  ;
    setDataList(tempdataList);
    setSelectedOptionVariable(event.target.value);
   // setAmortznPeriod(event.target.value);
    if (event.target.value !== '' && event.target.value < dataList[index].term ) {
      setShowPopuptermamort(true);
    } 
    
  };

  const handleOptionChangePaymentFreequency = (event,index) => { 
    var tempdataList=[...dataList]
    tempdataList[index].frequency=(event.target.value)  ;
    setDataList(tempdataList);
    setSelectedOptionMonthly(event.target.value);
    
    //setFreqParam(event.target.value);
   
    
  };
  const handleOptionChangeTerm = (event,index) => {
    var tempdataList=[...dataList]
    tempdataList[index].term=parseInt(event.target.value)  ;
    setDataList(tempdataList);
   // setSelectedOptionMonths(event.target.value);
   // setTermVal(event.target.value);
   
    if (event.target.value !== '' && dataList[index].period < event.target.value ) {
      setShowPopuptermamort(true);
    
    } 
   
  };

  const [isTooltipOpenAmount, setTooltipOpenAmount] = useState(false);

  const handleTooltipOpenAmount = () => {
    setTooltipOpenAmount(true);
  };

  const handleTooltipCloseAmount = () => {
    setTooltipOpenAmount(false);
  };
const [initialpaymentTextValue, setInitialPaymentTextValue] = useState([]); 
 const [askingPriceTextValue, setAskingPriceTextValue] = useState([]);
 const [askingPriceObjValue, setAskingPriceObjValue] = useState({});
 const [mortValValue, setMortValValue] = useState(10000);
 

 useEffect(() => {
  //setAskingPriceTextValue(askingPriceValue);
  var tempdataList=[...dataList]
  tempdataList[index].askingprice=askingPriceValue  ;
  tempdataList[index].mortgageamount=parseInt(tempdataList[index].askingprice-dataList[index].initialPayment);
  setDataList(tempdataList);

 
}, [askingPriceValue]);
  


  const handleChangeAskingPriceText =(event,index) => { 
  
    var tempdataList=[...dataList]
    tempdataList[index].askingprice=parseInt(event.target.value)  ;    
    setDataList(tempdataList);
    


   }


     
     
   const handleChangeInitialPaymentText =(event,index) => {  
    setdownBorderAmount(false)
  

  const newValue = parseInt(event.target.value);
  const tempdataList = [...dataList];

  if (newValue >= tempdataList[index].askingprice) {
    tempdataList[index].initialPayment=event.target.value  ;  
    tempdataList[index].downBorderAmount = true;
    setTooltipTitle("Please enter a value less than the Purchase price");
  } else {
    tempdataList[index].downBorderAmount = false; // Reset the flag
    tempdataList[index].initialPayment=parseInt(event.target.value)  ;   
    tempdataList[index].mortgageamount=parseInt(tempdataList[index].askingprice)-parseInt(tempdataList[index].initialPayment);
    setDataList(tempdataList);
  }
    
  

  }
  useEffect(() => {
    handleChangeAskingprice(dataList[index].askingprice, index)
  }, [dataList[index].askingprice]
  );
  const handleMouseEnter = (index) => {
    var tempdataList=[...dataList]
    tempdataList[index].isSelected=true  ;    
    setDataList(tempdataList);
  };

  const handleMouseLeave = (index) => {
    var tempdataList=[...dataList]
    tempdataList[index].isSelected=false  ;    
    setDataList(tempdataList); 
  };
  
  return (


    <Box key={mortgageName} style={{
      display: 'flex', flexDirection: "column", border:dataList[index].isSelected ?"1px solid #0C276C" : "1px solid #CFC9C9", 
       width:  '98%',
      height: "300px",  alignItems: "center", borderRadius: "8px", gap: '14px', justifyContent: 'center',
      background: dataList[index].isSelected ||selectedCheckboxList.includes(mortgageName) ? "#F9F9F9" : "white",padding:'0px 7px 0px 7px'
    }} >

      {IsDeleteButton && <IconButton  onClick={() => handleDeleteClick(mortgageName)} aria-label="delete" size="small"
      style={{marginLeft:'107%',}}>
        <img src={DeleteIcon} alt="" style={{ position: "relative", marginTop: "-45px",
        width: '18px', height: '18px',  }} />
      </IconButton>}



      {CheckboxEnabled && <Checkbox checked={selectedCheckboxList.includes(mortgageName)} onChange={() => handleCheckboxClickAddMortgage(mortgageName)} 
      style={{
        accentColor: "#0C276C", color: selectedCheckboxList.includes(mortgageName) === true ? "#0C276C" : "#CFC9C9",
        position: "relative", marginTop: "-30px", marginLeft: '99%', zIndex: 1, background: selectedCheckboxList.includes(mortgageName) === true ? "#F9F9F9" : "white",
        borderRadius: '0', width: '15px', height: '15px'
      }} />
      }




      <Typography style={{
        color: "#626262", textAlign: "center",  fontFamily: "poppins", fontSize: "12px", lineHeight: "18px",
        fontWeight: "400", marginTop: IsDeleteButton ? "-35px" : "-10px",
      }}>{mortgageName}
      </Typography>
      <TextField  value={isNaN(dataList[index].askingprice) ? '' : String(dataList[index].askingprice)}  
      onChange={(event) =>{ handleChangeAskingprice(event.target.value, index);  handleChangeAskingPriceText(event,index); }} variant="outlined" sx={{
    '& input': {
      width: "100%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    },
  }}
      
        InputProps={{
          sx: { height: '22px', color: " #626262" },
          '& label': {
            display: 'flex', alignItems: 'center',
            justifyContent: 'center'
          }
        }} />
        
{dataList[index].downBorderAmount ? (
    
    <Tooltip
     classes={{ tooltip: "custom-tooltip" }}
      title={tooltipTitle} 
      placement="top-start"
      arrow
      open={isTooltipOpenAmount}
      onClose={handleTooltipCloseAmount}
      disableHoverListener // Disable the hover listener
    >
            <TextField placeholder="0" variant="outlined" value={isNaN(dataList[index].initialPayment) ? '' : String(dataList[index].initialPayment)}  
      onChange={(event) =>{ handleChangeInitialPayment(event, index);handleChangeInitialPaymentText(event, index)}}
      onFocus={handleTooltipOpenAmount} // Use onFocus for touch-based devices
      onBlur={handleTooltipCloseAmount} // Use onBlur to close the tooltip
      sx={{
        width: "100%", fontFamily: "poppins",
        fontSize: "12px", lineHeight: "18px", fontWeight: "400",
      }}
        InputProps={{
          sx: { height: '22px', color: " #626262", fontFamily: "poppins",border: dataList[index].downBorderAmount ? '1px solid red !important' : '',
          fontSize: "12px", lineHeight: "18px", fontWeight: "400", },type:'number',
          '& label': {
            display: 'flex', alignItems: 'center',
            justifyContent: 'center'
          },
       
        }}/>  
          </Tooltip>
        ) : (
        <TextField  placeholder="0" variant="outlined" value={isNaN(dataList[index].initialPayment) ? '' : String(dataList[index].initialPayment)}  
        onChange={(event) =>{ handleChangeInitialPayment(event, index);handleChangeInitialPaymentText(event, index)}} 
        onFocus={handleTooltipOpenAmount} // Use onFocus for touch-based devices
        onBlur={handleTooltipCloseAmount} // Use onBlur to close the tooltip
        sx={{
          width: "100%", fontFamily: "poppins",
          fontSize: "12px", lineHeight: "18px", fontWeight: "400",
        }}
          InputProps={{
            sx: { height: '22px', color: " #626262", fontFamily: "poppins",border: dataList[index].downBorderAmount ? '1px solid red !important' : '',
            fontSize: "12px", lineHeight: "18px", fontWeight: "400", },type:'number',
            '& label': {
              display: 'flex', alignItems: 'center',
              justifyContent: 'center'
            },
          }} />)
        }
          <TextField id={props.parentIndex} placeholder="0" variant="outlined" value={isNaN(dataList[index].mortgageamount) ? '' : String(dataList[index].mortgageamount)}  
     sx={{
    '& input': {
      width: "100%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    },
  }}
      
        InputProps={{
          sx: { height: '22px', color: " #626262",":read-only":true  },
          '& label': {
            display: 'flex', alignItems: 'center',
            justifyContent: 'center'
          }
        }} />
      <style>
        {`
          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 1px solid #0C276C !important;
            box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
           
          }
        `}
      </style>

      {/* <Select value={selectedOptionVariable} onChange={handleOptionChangeVariable}
        style={{
          width: "100%", height: '22px', fontFamily: 'poppins', fontSize: '10px', lineHeight: '18px', fontWeight: '400', color: '#626262',

        }}
        displayEmpty
        MenuProps={{
          PaperProps: {
            style: {
              onMouseLeave: () => setSelectedOptionVariable(''), background: " #F8F8F8", borderRadius: '8px',marginTop:'5px', 
            },
          },
         
        }}
      >
        <MenuItem value="" style={{ display: 'none' }}>   Variable   </MenuItem>
        <MenuItem value={1} style={{
          backgroundColor: selectedOptionVariable === 1 ? '#1F6373' : 'transparent',
          color: selectedOptionVariable === 1 ? '#ffffff' : '#626262', fontFamily: 'poppins', fontSize: '10px', lineHeight: '18px',
          fontWeight: '400', borderRadius: '4px', marginLeft: '5px', marginRight: '5px', minHeight:'6px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(1)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          Variable  </MenuItem>
        <MenuItem value={2} style={{
          backgroundColor: selectedOptionVariable === 2 ? '#1F6373' : 'transparent', color: selectedOptionVariable === 2 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '10px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',minHeight:'6px',
           marginRight: '5px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(2)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          Fixed </MenuItem>
        <MenuItem value={3} style={{
          backgroundColor: selectedOptionVariable === 3 ? '#1F6373' : 'transparent', color: selectedOptionVariable === 3 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '10px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'6px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(3)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          Open  </MenuItem>
      </Select> */}


<Select size='1' value={dataList[index].period} onChange={(event) =>{ handleOptionChangeAmortization(event,index); handleChangePeriod(event,index)}}
        style={{
          width: "100%", height: '22px', fontFamily: 'poppins', fontSize: '10px', lineHeight: '18px', fontWeight: '400', color: '#626262',

        }}
        displayEmpty
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '250px',
              overflowY: 'auto',
              onMouseLeave: () => setSelectedOptionVariable(''), background: " #F8F8F8", borderRadius: '8px',marginTop:'5px',
            },
          },
         
        }}
      >
         <MenuItem  style={{ display: 'none' }}>  25 Years   </MenuItem>
        <MenuItem value={1} sx={{
          backgroundColor: selectedOptionVariable === 1 ? '#0C276C !important' : 'transparent',
          color: selectedOptionVariable === 1 ? '#ffffff' : '#626262', fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px',
          fontWeight: '400', borderRadius: '4px', marginLeft: '5px', marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(1)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          1 Year  </MenuItem>
        <MenuItem value={2} style={{
          backgroundColor: selectedOptionVariable === 2 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 2 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(2)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          2 Years </MenuItem>
        <MenuItem value={3} style={{
          backgroundColor: selectedOptionVariable === 3 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 3 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(3)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         3 Years  </MenuItem>
         <MenuItem value={4} style={{
          backgroundColor: selectedOptionVariable === 4 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 4 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(4)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          4 Years </MenuItem>
        <MenuItem value={5} style={{
          backgroundColor: selectedOptionVariable === 5 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 5 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(5)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         5 Years  </MenuItem>
         <MenuItem value={6} style={{
          backgroundColor: selectedOptionVariable === 6 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 6 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(6)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          6 Years </MenuItem>
        <MenuItem value={7} style={{
          backgroundColor: selectedOptionVariable === 7 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 7 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(7)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         7 Years  </MenuItem>
         <MenuItem value={8} style={{
          backgroundColor: selectedOptionVariable === 8 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 8 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(8)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          8 Years </MenuItem>
        <MenuItem value={9} style={{
          backgroundColor: selectedOptionVariable === 9 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 9 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(9)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         9 Years  </MenuItem>
         <MenuItem value={10} style={{
          backgroundColor: selectedOptionVariable === 10 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 10 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(10)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          10 Years </MenuItem>
        <MenuItem value={15} style={{
          backgroundColor: selectedOptionVariable === 15 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 15 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(15)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         15 Years  </MenuItem>
         <MenuItem value={20} style={{
          backgroundColor: selectedOptionVariable === 20 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 20 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(20)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          20 Years </MenuItem>
        <MenuItem value={25} style={{
          backgroundColor: selectedOptionVariable === 25 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 25 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(25)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         25 Years  </MenuItem>
         <MenuItem value={30} style={{
          backgroundColor: selectedOptionVariable === 30 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 30 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(30)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          30 Years </MenuItem>
        <MenuItem value={35} style={{
          backgroundColor: selectedOptionVariable === 35 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 35 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(35)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         35 Years  </MenuItem>
      </Select>


      <TextField id="outlined-basic" placeholder="3" variant="outlined" value={isNaN(dataList[index].rate) ? '' : String(dataList[index].rate)} 
       sx={{
    '& input': {
      width: "100%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    },
  }}
        InputProps={{
          sx: { height: '22px', color: " #626262" }, '& label': {
            display: 'flex', alignItems: 'center', justifyContent: 'center'
          }
        }}onChange={(event) => handleChangeInterest(event, index)}  />

      {/* <TextField id="outlined-basic" placeholder="0" variant="outlined" sx={{
    '& input': {
      width: "100%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    },
  }}
        InputProps={{
          sx: { height: '22px', color: " #626262" }, '& label': {
            display: 'flex', alignItems: 'center', justifyContent: 'center'
          }
        }} /> */}


      <Select size='1' value={dataList[index].frequency} onChange={(event) =>{ handleOptionChangePaymentFreequency(event, index);handleChangeFreequency(event,index)}}
        style={{
          width: "100%", height: '22px', fontFamily: 'poppins', fontSize: '10px', lineHeight: '18px', fontWeight: '400', color: '#626262',

        }}
        displayEmpty MenuProps={{
          PaperProps: {
            style: {
              onMouseLeave: () => setSelectedOptionMonthly(''), background: " #F8F8F8 ", borderRadius: '8px',marginTop:'5px'
            },
          },
        }}
      >
        <MenuItem  style={{ display: 'none' }}>Monthly </MenuItem>   
        <MenuItem value={"M"}  style={{
          backgroundColor: selectedOptionMonthly === "M" ? '#0C276C' : 'transparent', color: selectedOptionMonthly ==="M" ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonthly('M')}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Monthly</MenuItem>
        <MenuItem value={"S"} style={{
          backgroundColor: selectedOptionMonthly === "S" ? '#0C276C' : 'transparent', color: selectedOptionMonthly === "S" ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonthly("S")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Semi-monthly</MenuItem>
           <MenuItem value={"W"} style={{
          backgroundColor: selectedOptionMonthly === "W" ? '#0C276C' : 'transparent', color: selectedOptionMonthly === "W" ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonthly("W")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Weekly</MenuItem>
           <MenuItem value={"B"} style={{
          backgroundColor: selectedOptionMonthly === "B" ? '#0C276C' : 'transparent', color: selectedOptionMonthly === "B" ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonthly("B")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Bi-weekly</MenuItem>
           <MenuItem value={"A"} style={{
          backgroundColor: selectedOptionMonthly === "A" ? '#0C276C' : 'transparent', color: selectedOptionMonthly === "A" ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonthly("A")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Accelerated weekly</MenuItem>
           <MenuItem value={"Z"} style={{
          backgroundColor: selectedOptionMonthly === "Z" ? '#0C276C' : 'transparent', color: selectedOptionMonthly === "Z" ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonthly("Z")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Accelerated Bi-weekly</MenuItem>

      </Select>
      <Select value={dataList[index].term} onChange={(event) =>{ handleOptionChangeTerm(event,index); handleChangeTerm(event,index)}} 
        style={{
          width: "100%", height: '22px', fontFamily: 'poppins', fontSize: '10px', lineHeight: '18px', fontWeight: '400', color: '#626262'
        }}
        displayEmpty MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '250px',
              overflowY: 'auto',
              onMouseLeave: () => setSelectedOptionMonths(''),
              background: " #F8F8F8 ", borderRadius: '8px',marginTop:'5px',
            },
          },
        }}
      >
         <MenuItem  style={{ display: 'none' }}>    5 Years    </MenuItem>
        <MenuItem value={1} style={{
          backgroundColor: selectedOptionMonths === 1 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 1 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '10px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(1)}
          onMouseLeave={() => setSelectedOptionMonths('')}
        >1 Year</MenuItem>
        <MenuItem value={2} style={{
          backgroundColor: selectedOptionMonths === 2 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 2 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(2)}
          onMouseLeave={() => setSelectedOptionMonths('')}>2 Years</MenuItem>

        <MenuItem value={3} style={{
          backgroundColor: selectedOptionMonths === 3 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 3 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(3)}
          onMouseLeave={() => setSelectedOptionMonths('')}>3 Years</MenuItem>
        <MenuItem value={4} style={{
          backgroundColor: selectedOptionMonths === 4 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 4 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(4)}
          onMouseLeave={() => setSelectedOptionMonths('')}>4 Years</MenuItem>
        <MenuItem value={5} style={{
          backgroundColor: selectedOptionMonths === 5 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 5 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(5)}
          onMouseLeave={() => setSelectedOptionMonths('')}>5 Years</MenuItem>
        <MenuItem value={6} style={{
          backgroundColor: selectedOptionMonths === 6 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 6 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(6)}
          onMouseLeave={() => setSelectedOptionMonths('')}>6 Years</MenuItem>
        <MenuItem value={7} style={{
          backgroundColor: selectedOptionMonths === 7 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 7 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(7)}
          onMouseLeave={() => setSelectedOptionMonths('')}>7 Years</MenuItem>
        <MenuItem value={8} style={{
          backgroundColor: selectedOptionMonths === 8 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 8 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(8)}
          onMouseLeave={() => setSelectedOptionMonths('')}>8 Years</MenuItem>
        <MenuItem value={9} style={{
          backgroundColor: selectedOptionMonths === 9 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 9 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(9)}
          onMouseLeave={() => setSelectedOptionMonths('')}>9 Years</MenuItem>
        <MenuItem value={10} style={{
          backgroundColor: selectedOptionMonths === 10 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 10 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(10)}
          onMouseLeave={() => setSelectedOptionMonths('')}>10 Years</MenuItem>
        <MenuItem value={15} style={{
          backgroundColor: selectedOptionMonths === 15 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 15 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(15)}
          onMouseLeave={() => setSelectedOptionMonths('')}>15 Years</MenuItem>
        <MenuItem value={20} style={{
          backgroundColor: selectedOptionMonths === 20 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 20 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(20)}
          onMouseLeave={() => setSelectedOptionMonths('')}>20 Years</MenuItem>
           <MenuItem value={25} style={{
          backgroundColor: selectedOptionMonths === 25 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 25 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(25)}
          onMouseLeave={() => setSelectedOptionMonths('')}>25 Years</MenuItem>
            <MenuItem value={30} style={{
          backgroundColor: selectedOptionMonths === 30 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 30 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(30)}
          onMouseLeave={() => setSelectedOptionMonths('')}>30 Years</MenuItem>
            <MenuItem value={35} style={{
          backgroundColor: selectedOptionMonths === 35 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 35 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight: '10px',
        }}
          onMouseEnter={() => setSelectedOptionMonths(35)}
          onMouseLeave={() => setSelectedOptionMonths('')}>35 Years</MenuItem>
      </Select>
      {showPopup && (

        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '2'
          }}
        >
          <Box
            sx={{
              position: 'absolute',            
              width: '300px',
              top:'20%',
              height: '180px',
              backgroundColor: '#fff',

              border: '1px solid #CFC9C9',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '3'
            }}
          >
            <IconButton onClick={handleCancel} style={{
              color: '#939393', position: 'absolute', width: '20px', height: '20px',
              left: '273px', top: '10px'
            }} >
              <HighlightOffIcon ></HighlightOffIcon>
            </IconButton>

            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
              textAlign: "center", marginTop: '15px'
            }}>Are you sure you want to delete this Mortgage option?</Typography>
            <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '25px' }}>
              <button size='small' onClick={handleCancel} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins', fontStyle: 'normal',
                fontWeight: '400', fontSize: '14px', lineHeight: '21px', marginRight: '24px', textAlign: 'center', color: '#313131',
                background: '#F8F8F8', border: '1px solid #CFC9C9', borderRadius: '4px',cursor:'pointer'
              }} >Cancel</button>
              <button size='small' onClick={handleDeleteConfirm} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C', border: '1px solid #0C276C', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C';
              }} >Delete </button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>


  );

}

export default DivcomponenetMobile;