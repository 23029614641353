import React from 'react';
import TextField from '@mui/material/TextField';
import { Box, InputLabel, Stack, Typography } from '@mui/material';
import { Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { useState } from "react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import otp from './images/Group 3353.png';
import Tick from './images/Vector (6).png';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from '@mui/material/FormControl';
import Checkbox from "@mui/material/Checkbox";

function CheckboxPopUpMobile(props) {

  const [showPopupGetTheQuote, setShowPopupGetTheQuote] = useState(false);

  const handleCancel = () => {
    setShowPopupGetTheQuote(false);
  };
  const [showPopupforCheckbox, setshowPopupforCheckbox] = useState(false);


  const handlePopUpGetTheQuote = () => {
    setShowPopupGetTheQuote(true);




  };

  const [showPopup, setShowPopup] = useState(false);

  const handleCancelValidate = () => {
    setShowPopup(false);
  };



  const handlePopUp = () => {
    setShowPopup(true);
  };
  const handlePopupClose = () => {

    setshowPopupforCheckbox(true);
  };
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const handleCheckboxClick = (checkboxName) => {
    if (selectedCheckboxes.includes(checkboxName)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((name) => name !== checkboxName));
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, checkboxName]);
    }
  };

  const isCheckboxSelected = (checkboxName) => {
    return selectedCheckboxes.includes(checkboxName);
  };

  return (
    < Box>





      {(!showPopupGetTheQuote && !showPopupforCheckbox) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '1250px',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '30%',
              width: 'calc(95%)',
              height: '692px',
              backgroundColor: '#fff',
              border: '1px solid #CFC9C9',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '1'
            }}
          >
            <IconButton onClick={handlePopupClose} style={{
              color: '#939393', position: 'relative', width: '20px', height: '20px',
              marginLeft: '88%', marginTop: '-10%'
            }} >
              <HighlightOffIcon ></HighlightOffIcon>
            </IconButton>
            <Box sx={{
              display: 'flex', flexDirection: "column", alignItems: "center", borderRadius: "8px", background: ' #FFFFFF'
              , gap: '28px', marginLeft: '24px', marginRight: '24px', width: 'calc(100%-40px)'
            }}>
              <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '25px' }}>
                <Typography sx={{ color: "#454545", fontFamily: "poppins", fontSize: "16px", fontWeight: "500", lineHeight: "28px" }}>Get the quotes</Typography>

              </Box>
              <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'left', gap: '5px', alignItems: 'left' }}>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left' }}>

                  <Typography sx={{ color: "#313131", textAlign: "left", fontFamily: "poppins", fontSize: "14px", lineHeight: "20px", fontWeight: "500" }}>
                    Enter annual house hold income</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center' }}>
                  <TextField id="outlined-basic" type="password" placeholder="********" variant="outlined" size='small' style={{
                    width: '288px', height: '36px',
                    border: ' #CFC9C9', borderRadius: '8px', boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                  }} />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'left', gap: '5px', alignItems: 'left' }}>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left' }}>
                  <Typography sx={{ color: "#313131", textAlign: "left", fontFamily: "poppins", fontSize: "14px", lineHeight: "20px", fontWeight: "500", }}>
                    Line of credit payment</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center', lignItems: 'center' }}>
                  <TextField id="outlined-basic" type="password" placeholder="enter amount" variant="outlined" size='small' style={{
                    width: '288px', height: '36px',
                    border: ' #CFC9C9', borderRadius: '8px'
                  }} />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'left', gap: '5px', alignItems: 'left' }}>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left' }}>
                  <Typography sx={{ color: "#313131", textAlign: "left", fontFamily: "poppins", fontSize: "14px", lineHeight: "20px", fontWeight: "500" }}>
                    Car loan payment</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left' }}>
                  <TextField id="outlined-basic" type="password" placeholder="enter amount" variant="outlined" size='small'
                    style={{ width: '288px', height: '36px', border: ' #CFC9C9', borderRadius: '8px' }} />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'left', gap: '5px', alignItems: 'left', 
               width: 'calc(100%-20px)',overflowWrap: 'break-word' }}>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left' }}>
                  <Typography sx={{ color: "#313131",  textAlign: 'left',fontFamily: "poppins", fontSize: "14px", lineHeight: "20px", fontWeight: "400",
                  whiteSpace: 'normal', // Allow line breaks
                  wordWrap: 'break-word', // Wrap long words
                  maxWidth: '100%', // Limit width within the box
                  marginLeft: '14px', marginRight: '14px',
                 }}>
                  Do you have existing mortgage and will be 
continued  ever after purchasing new loan ?</Typography>
                </Box>
               
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left', marginLeft: '14px', marginRight: '14px', }}>
                 
                

               
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{
                            color: isCheckboxSelected('monthlyMortgages') ? '#1F6373' : '#CFC9C9', zIndex: 1, background: 'white', borderRadius: '0',
                          }}
                          checked={isCheckboxSelected('monthlyMortgages')}
                          onChange={() => handleCheckboxClick('monthlyMortgages')}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            color: '#313131', fontFamily: 'poppins', fontSize: '14px', lineHeight: '24px', fontWeight: '400',
                          }}
                        >
                          Monthly mortgages
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{
                            color: isCheckboxSelected('propertyTax') ? '#1F6373' : '#CFC9C9', zIndex: 1, background: 'white', borderRadius: '0',
                          }}
                          checked={isCheckboxSelected('propertyTax')}
                          onChange={() => handleCheckboxClick('propertyTax')}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            color: '#313131', fontFamily: 'poppins', fontSize: '14px', lineHeight: '24px', fontWeight: '400',
                          }}
                        >
                          Property tax
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{
                            color: isCheckboxSelected('condoFees') ? '#1F6373' : '#CFC9C9', zIndex: 1, background: 'white', borderRadius: '0',
                          }}
                          checked={isCheckboxSelected('condoFees')}
                          onChange={() => handleCheckboxClick('condoFees')}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            color: '#313131', fontFamily: 'poppins', fontSize: '14px', lineHeight: '24px', fontWeight: '400',
                          }}
                        >
                          Condo fees
                        </Typography>
                      }
                    />
                  </FormGroup>
                  </Box>
              </Box>



              <Box sx={{ display: "flex", justifyContent: "center", marginTop: '5px' }}>
                <Button variant="contained" onClick={handlePopUpGetTheQuote} style={{
                  color: "white", textTransform: "none",
                  background: "#1F6373", border: "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal",
                  lineHeight: "21px", fontWeight: "500", fontSize: "14px", width: "224px", height: '36px', borderRadius: "8px",
                }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.background = '#074554';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.background = '#1F6373';
                  }}  >
                  Get the quotes
                </Button>

              </Box>




            </Box>
          </Box>

        </Box>



      )}



      {(showPopupGetTheQuote && !showPopup) && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '',
              left: '',
              width: 'calc(92%)',
              height: '420px',
              backgroundColor: '#fff',

              border: '1px solid #CFC9C9',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '1'
            }}
          >

            <IconButton onClick={handleCancel} style={{
              color: '#939393', position: 'absolute', width: '20px', height: '20px', marginLeft: '90%',
              top: '15px'
            }} >
              <HighlightOffIcon ></HighlightOffIcon>
            </IconButton>

            <img src={otp} alt="" style={{ marginTop: '20px' }} />
            <Box sx={{ display: 'flex', flexDirection: "column", gap: '17px', marginTop: '10px', justifyContent: 'center', padding: '15px' }}>
              <Typography style={{
                fontFamily: "poppins", fontSize: "20px", lineHeight: "30px", fontWeight: "500", color: '#212427',
                textAlign: "center"
              }}>Enter your OTP</Typography>
              <Typography style={{
                fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "500", color: '#626262',
                textAlign: "center"
              }}>We sent a 6-digit OTP to your registered phone Number
                and registered email id</Typography>

              <Box sx={{ display: 'flex', flexDirection: "row", gap: '15px', justifyContent: 'center' }}>
                <TextField variant="outlined" InputProps={{ style: { height: '36px', width: '36px', }, }} />
                <TextField variant="outlined" InputProps={{ style: { height: '36px', width: '36px', }, }} />
                <TextField variant="outlined" InputProps={{ style: { height: '36px', width: '36px', }, }} />
                <TextField variant="outlined" InputProps={{ style: { height: '36px', width: '36px', }, }} />
                <TextField variant="outlined" InputProps={{ style: { height: '36px', width: '36px', }, }} />
                <TextField variant="outlined" InputProps={{ style: { height: '36px', width: '36px', }, }} />

              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: "column", gap: '20px', justifyContent: 'center', marginTop: '20px' }}>
              <button size='small' onClick={handlePopUp} style={{
                width: '288px', height: '36px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#1F6373', border: '1px solid #1F6373', borderRadius: '8px', cursor: 'pointer',
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.background = '#1F6373';
                }}  >Validate </button>
              <button size='small' style={{
                width: '288px', height: '36px', fontFamily: 'Poppins', fontStyle: 'normal',
                fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#313131',
                background: '#F8F8F8', border: '1px solid #CFC9C9', borderRadius: '8px', cursor: 'pointer',
              }} >Resend</button>
            </Box>
          </Box>
        </Box>
      )}

      {showPopup && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '1075px',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1'
          }}
        >
          <Box
            sx={{
              position: 'fixed',
              top: '40%',
              left: '',
              width: 'calc(92%)',
              height: '162px',
              backgroundColor: '#fff',

              border: '1px solid #CFC9C9',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '1'
            }}
          >
            <IconButton onClick={handleCancelValidate} style={{
              color: '#939393', position: 'absolute', width: '20px', height: '20px',
              left: '90%', top: '10px'
            }} >
              <HighlightOffIcon ></HighlightOffIcon>
            </IconButton>
            <img src={Tick} alt="" style={{ marginTop: "10px" }} />
            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131',
              textAlign: "center", marginTop: "15px"
            }}>You will get the quotation within 24 hours</Typography>

          </Box>
        </Box>
      )}
    </Box>

  );
}
export default CheckboxPopUpMobile;