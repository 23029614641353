import { Box, Button, IconButton, Modal, OutlinedInput, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import PrimaryButton from './customButton';
import SecondaryButton from './Secondarybutton';
function ModalsendtoemailMod() {
  const [alignment1, setAlignment1] = useState('disable');
  const [openModal, setOpenmodal]=useState(true);
  const [alignment2, setAlignment2] = useState('enable');
  const handleOpenmodal = () => setOpenmodal(true);
  const handleClosemodal = () => setOpenmodal(false);
  const handleChange1 = (event, newValue) => {
      setAlignment1(newValue);
  };
  const handleChange2 = (event, newValue) => {
    setAlignment2(newValue);
};
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 34,
  height: 20,
  borderRadius:16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'blue' : '#1F6373',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    BoxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 14,
    height: 14,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#1F6373',
    BoxSizing: 'border-Box',
  },
}));
  return (
    <div>
        <Modal
        open = {openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <Box
          sx={{
            position: 'absolute',
            width: '506px',
            height:'332px',
            left:400,
            top:150,
            bgcolor: '#FFFF',
            borderRadius:'8px',
            BoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            alignItems:'center',
            justifyContent:'center',
            alignContent:'center',
            }}>
            <div>
            <div style={{ display: 'flex', flexDirection:'row-reverse',paddingTop:'16px',paddingRight:'16px' }}>
            <IconButton sx={{ padding: '0px' }} onClick={handleClosemodal} >
              < CancelOutlinedIcon sx={{ color: '#606060', width: '24px', height: '24px' }} />
            </IconButton>
            </div>
            <div style={{paddingLeft:'28px',paddingRight:'28px',paddingBottom:'54px'}}>
            <div style={{height:'40px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',paddingTop:'18px'}}>
            <Typography sx={{fontSize:'14px',fontWeight:'400px',color:'#313131'}}><span>Do you want connnect to the agent?</span></Typography>
          <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
    </div>
    <div style={{height:'40px', display:'flex',flexDirection:'row',alignItems:'center',marginTop:'8px',justifyContent:'space-between'}}>
            <Typography sx={{textAlign:'center',fontSize:'14px',fontWeight:'400px',color:'#313131'}}><span>Do you want subscribe our news letter?</span></Typography>
          <AntSwitch  inputProps={{ 'aria-label': 'ant design' }} />
            </div>
            <OutlinedInput
                        type="text"
                        placeholder='Enter your email'
                        sx={{width:'450px',borderRadius:'8px',marginTop:'48px',height:'36px',border:'1px solid #606060',BoxSizing:'border-Box',fontSize:'16px',fontFamily:'Poppins',color:'#939393'}}/>
          <div style={{display:'flex',flexDirection:'row',paddingTop:'16px',gap:'16px'}}>
            <SecondaryButton sx={{width:'217px',backgroundColor:'white'}}>Cancel</SecondaryButton>
            <PrimaryButton sx={{width:'217px'}}>Send</PrimaryButton>
          </div>                   
            </div>
            </div>
            </Box>
            </Modal>
    </div>
  )
}
export default ModalsendtoemailMod