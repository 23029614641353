//import React, { useLayoutEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import AppBar from "@mui/material/AppBar";

import { Button, Grid, Typography } from "@mui/material";
import logo from './images/logo.png'

import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';



import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ImgFrame from './images/HomeBlue.png';
import dataicon from './images/icon-park-outline_database-forbid.svg';
//import downicon from './images/material-symbols_download.png';
import HelpIcon from '@mui/icons-material/Help';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Link1 from "@mui/material/Link";
import { useState, useEffect } from "react";

import DivComponents from './DivComponentUser';
import Payment from './PaymentUser';
import Penalty from './PenaltyComponent';
import CheckboxPopUp from './CheckboxPopUp';
import GetAppIcon from '@mui/icons-material/GetApp';

import SignIn from './SignInSignUp'
import axios from 'axios';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../App.css';
import Footer from './Footer';

import { API_URL } from "./constants";

import Header from './HeaderLanding';
import HeaderMobile from './HeaderLandingMobile';
import { Helmet } from 'react-helmet-async';


const dbLink = process.env.REACT_APP_API_URL;



function CanadaMortgage() {



  const [currentPage, setCurrentPage] = useState('Canada Mortgage');
  const menus = [
    { label: "Universal Calculator", link: "/" },
    { label: "Canada Mortgage", link: "/canada-mortgage" },   
    { label: "About us", link: "/about-us" },
    { label: "Contact us", link: "/contact-us" }
  ];



  const linkstyles = {
    link1: {
      textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "16px", fontWeight: "400",
      color: " #313131", zIndex: 1
    },
    link2: {
      marginLeft: "5%", textUnderlineOffset: "7px", paddingTop: "5px", fontFamily: "poppins", fontSize: "16px", fontWeight: "400",
      color: " #313131"

    },
    activeLink: {
      textDecoration: 'underline', fontWeight: "500"
    },
  };
  const [activeLink, setActiveLink] = useState('Calculate Payment');

  const handleLinkClick = (link) => {
    setActiveLink(link);

  };






  const [calcContent, setcalcContent] = useState("Payment Content");

  const handlePaymentCalc = () => {
    setcalcContent("Payment Content");
    handleLinkClick('Calculate Payment');
  };

  const handlePenaltyCalc = () => {
    setcalcContent("Penalty Content");
    handleLinkClick('Calculate Penalty on cancellation');
  };






  const [isSelected, setIsSelected] = useState(false);
  const [downBorderAmount, setdownBorderAmount] = useState(false);
  const [downBorderPercentage, setdownBorderPercentage] = useState(false);
  const [askingPriceValue, setAskingPriceValue] = useState(1000000);
  const [mortgageamountValue, setMortgageamountValue] = useState("800000");
  const [termValue, setTermValue] = useState();
  const [rateValue, setRateValue] = useState(3);
  const [periodValue, setPeriodValue] = useState();
  const [frequencyValue, setFrequencyValue] = useState('M');
  const [downPerValue, setDownPerValue] = useState(20);
  const [downAmountValue, setDownAmountValue] = useState("200000");
  const [insuranceValue, setInsuranceValue] = useState(0);
  const [sheduledPayment, setSheduledPayment] = useState(3785.96);
  const [periodTotal,setPeriodTotal] = useState(25);
  const [termTotal,setTermTotal] = useState(5);
  const [amortizationMonthly, setAmortizationMonthly] = useState(0); 
  const [termMonthly, setTermMonthly] = useState(0); 
  const [rateMonthly, setRateMonthly] = useState(0.2500);
  const [resultTotal, setResultTotal] = useState({ TotalAmountPaid: 1431.60, TotalInterestPaid: 1407.60, TotalPrinciplePaid: 2839.20 });
  //const [result2, setResult2] = useState(25);

  const [dataList, setDataList] = useState([
    {
      id: 0,
      name: "Option #1",
      askingprice: askingPriceValue,
      DownPaymentPercentage: downPerValue,
      DownPaymentAmount: downAmountValue,
      cmhc: insuranceValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      isSelected: false,
      downBorderAmount:false,
      downBorderPercentage:false,
      SheduledResult: sheduledPayment,
      lowerResults: resultTotal,
      totalAmortization: periodTotal,
      totalTerm: termTotal,
      termMonth: termMonthly,
      rateMonth: rateMonthly,
      amortizationMonth: amortizationMonthly,
    },
    {
      id: 1,
      name: "Option #2",
      askingprice: askingPriceValue,
      DownPaymentPercentage: downPerValue,
      DownPaymentAmount: downAmountValue,
      cmhc: insuranceValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      isSelected: false,
      downBorderAmount:false,
      downBorderPercentage:false,
      SheduledResult: sheduledPayment,
      lowerResults: resultTotal,
      totalAmortization: periodTotal,
      totalTerm: termTotal,
      termMonth: termMonthly,
      rateMonth: rateMonthly,
      amortizationMonth: amortizationMonthly,
    },
    {
      id: 2,
      name: "Option #3",
      askingprice: askingPriceValue,
      DownPaymentPercentage: downPerValue,
      DownPaymentAmount: downAmountValue,
      cmhc: insuranceValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      isSelected: false,
      downBorderAmount:false,
      downBorderPercentage:false,
      SheduledResult: sheduledPayment,
      lowerResults: resultTotal,
      totalAmortization: periodTotal,
      totalTerm: termTotal,
      termMonth: termMonthly,
      rateMonth: rateMonthly,
      amortizationMonth: amortizationMonthly,
    },
    {
      id: 3,
      name: "Option #4",
      askingprice: askingPriceValue,
      DownPaymentPercentage: downPerValue,
      DownPaymentAmount: downAmountValue,
      cmhc: insuranceValue,
      mortgageamount: mortgageamountValue,
      term: termValue,
      rate: rateValue,
      period: periodValue,
      frequency: frequencyValue,
      isChecked: false,
      isSelected: false,
      downBorderAmount:false,
      downBorderPercentage:false,
      SheduledResult: sheduledPayment,
      lowerResults: resultTotal,
      totalAmortization: periodTotal,
      totalTerm: termTotal,
      termMonth: termMonthly,
      rateMonth: rateMonthly,
      amortizationMonth: amortizationMonthly,
    },
  ])


  const [numberOfAddClick, setnumberOfAddClick] = useState(4);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(4);

  const handleAddSlide = async () => {

    setisDeleteButtonEnabled(true);
    if (dataList.length !== 7) {
      const dwnamnt = askingPriceValue * (downPerValue / 100);
      const mrval = (askingPriceValue - dwnamnt) + insuranceValue;
      const Floatamount = parseFloat(mrval);
      const isFloat = Number.isFinite(Floatamount) && Floatamount % 1 !== 0; 
      const mortval =isFloat ? Floatamount.toFixed(2) : Floatamount.toString();
      let  monthPayment;
      const cmhcinsurance = await getThirdResult(mrval, downPerValue);
      const monthlyPayment = await getSheduledPayment(parseInt(askingPriceValue), (rateValue / 100), periodTotal, frequencyValue, mrval);
      const data = await getSecondResult(parseInt(askingPriceValue), (rateValue / 100), periodTotal, frequencyValue, monthlyPayment, termTotal, mrval, monthlyPayment)
      if(monthlyPayment < 1){
        monthPayment = monthlyPayment.toFixed(4)
    }
    else{
        monthPayment = monthlyPayment.toFixed(2)   
    }
      const newData = {
        id: dataList.length, name: `Option #${numberOfAddClick + 1}`, askingprice: askingPriceValue,
        DownPaymentPercentage: downPerValue, DownPaymentAmount: dwnamnt, cmhc: cmhcinsurance, mortgageamount: mortval, term: termTotal,
        rate: rateValue, period: periodTotal, isSelected: false, downBorderAmount:false, downBorderPercentage:false,
         frequency: frequencyValue,  termMonth: termMonthly,  rateMonth: rateMonthly,  amortizationMonth: amortizationMonthly,
         SheduledResult:monthPayment, lowerResults: {
          TotalAmountPaid: data.TotalPrinciple,
          TotalInterestPaid: data.TotalInterest,
          TotalPrinciplePaid: data.TotalPayment
        }
      }

      // isDeleteConfirm===true?setCurrentSlideIndex(currentSlideIndex+2):setCurrentSlideIndex(currentSlideIndex+1);

      setnumberOfAddClick(numberOfAddClick + 1);

      setDataList([...dataList, newData]);
    }
    setCurrentSlideIndex(currentSlideIndex + 1);
  };



  const handlePrevSlide = () => {

    if (currentSlideIndex > 4)
      setCurrentSlideIndex(currentSlideIndex - 1)

  };

  const handleNextSlide = () => {
    if (currentSlideIndex < (dataList.length))
      setCurrentSlideIndex(currentSlideIndex + 1)

  }
  const [isDeleteButtonEnabled, setisDeleteButtonEnabled] = useState(false);





  const isdisableAddSlidebutton = (dataList[dataList.length]?.name) === (dataList[currentSlideIndex]?.name)
  const [CheckboxEnabled, setCheckboxEnabled] = useState(false);

  const [thebidding, setthebidding] = useState("bidding disable");
  const handleAddBiddingCheckbox = () => {
    setCheckboxEnabled(true);
    setthebidding("bidding enable");
    setisDeleteButtonEnabled(false);

  };

  const [showPopupforCheckbox, setShowPopupforCheckbox] = useState(false);

  const handleNextClick = () => {

    setShowPopupforCheckbox(true);



  };
  const [selectedCheckboxList, setCheckboxSelectedList] = useState([]);

  // const [isCheckedCheckboxNext, setisCheckedCheckboxNext] = useState([]);
  var isNextButtonEnabled = selectedCheckboxList.length > 0;
  const handleNextCheckboxChange = (isChecked, index) => {






  };
  const IsChevorButton = (CheckboxEnabled && dataList.length > 4) || isDeleteButtonEnabled;




  // const [isChecked, setIsChecked] = useState(false);

  const [isHoveredAddMortgage, setIsHoveredAddMortgage] = useState(false);

  const handleMouseEnterAddMortgage = () => {
    setIsHoveredAddMortgage(true);
  };

  const handleMouseLeaveAddMortgage = () => {
    setIsHoveredAddMortgage(false);
  };

  
  const [isActiveAskingPrice, setIsActiveAskingPrice] = useState(false);

  const handleInputClickAskingPrice = () => {
    setIsActiveAskingPrice(true);
  };

  const handleInputBlurAskingPrice = () => {
    setIsActiveAskingPrice(false);
  };
  const [isActiveLocation, setIsActiveLocation] = useState(false);

  const handleInputClickLocation = () => {
    setIsActiveLocation(true);
  };

  const handleInputBlurLocation = () => {
    setIsActiveLocation(false);
  };
  const [showSignIn, setShowSignIn] = useState(false);

  const handleClickSignIn = () => {
    setShowSignIn(!showSignIn);
  };
  const [showTermText, setShowTermText] = useState(false);
  const handleTermClick = () => {
    setShowTermText(!showTermText)
    setTimeout(() => {
      setShowTermText(false);
    }, 5000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };

  const [showAnnualInterestRateText, setShowAnnualInterestRateText] = useState(false);
  const handleAnnualInterestRateClick = () => {
    setShowAnnualInterestRateText(!showAnnualInterestRateText);
    setTimeout(() => {
     setShowAnnualInterestRateText(false);;
    }, 5000);
    setShowAnnualInterestRateMonthText(false)
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };

  const [showAnnualInterestRateMonthText, setShowAnnualInterestRateMonthText] = useState(false);
  const handleAnnualInterestRateMonthClick = () => {
    setShowAnnualInterestRateMonthText(!showAnnualInterestRateMonthText);
    setTimeout(() => {
      setShowAnnualInterestRateMonthText(false);
    }, 5000);
    setShowAnnualInterestRateText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };


  const [showAmortizationText, setShowAmortizationText] = useState(false);
  const handleAmortizationTextClick = () => {
    setShowAmortizationText(!showAmortizationText);
    setTimeout(() => {
      setShowAmortizationText(false);
    }, 5000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowTermText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };

  const [showPaymentfreequencyText, setShowPaymentfreequencyText] = useState(false);
  const handlePaymentfreequencyTextClick = () => {
    setShowPaymentfreequencyText(!showPaymentfreequencyText);
    setTimeout(() => {
      setShowPaymentfreequencyText(false);
    }, 8000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };

  const [showPaymentSheduleText1, setShowPaymentSheduleText1] = useState(false);
  const handlePaymentSheduleTextClick1 = () => {
    setShowPaymentSheduleText1(!showPaymentSheduleText1);
    setTimeout(() => {
      setShowPaymentSheduleText1(false);
    }, 5000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };

  const [showPaymentSheduleText2, setShowPaymentSheduleText2] = useState(false);
  const handlePaymentSheduleTextClick2 = () => {
    setShowPaymentSheduleText2(!showPaymentSheduleText2);
    setTimeout(() => {
      setShowPaymentSheduleText2(false);
    }, 5000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };

  const [showAskingPriceText, setShowAskingPriceText] = useState(false);
  const handleAskingPriceTextClick = () => {
    setShowAskingPriceText(!showAskingPriceText);
    setTimeout(() => {
      setShowAskingPriceText(false);
    }, 5000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };

  

  const [showTotalPrinciplePaidText, setShowTotalPrinciplePaidText] = useState(false);
  const handleTotalPrinciplePaidTextClick = () => {
    setShowTotalPrinciplePaidText(!showTotalPrinciplePaidText);
    setTimeout(() => {
      setShowTotalPrinciplePaidText(false);
    }, 5000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };

  const [showTotalInterestPaidText, setShowTotalInterestPaidText] = useState(false);
  const handleTotalInterestPaidTextClick = () => {
    setShowTotalInterestPaidText(!showTotalInterestPaidText);
    setTimeout(() => {
      setShowTotalInterestPaidText(false);
    }, 6000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };

  const [showTotalAmountPaidText, setShowTotalAmountPaidText] = useState(false);
  const handleTotalAmountPaidTextClick = () => {
    setShowTotalAmountPaidText(!showTotalAmountPaidText);
    setTimeout(() => {
      setShowTotalAmountPaidText(false);
    }, 8000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };

  const [showBalanceText, setShowBalanceText] = useState(false);
  const handleBalanceTextClick = () => {
    setShowBalanceText(!showBalanceText)
    setTimeout(() => {
      setShowBalanceText(false);
    }, 3000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
   setShowMortgageAmountText(false)
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
  };

  const [showMortgageAmountText, setShowMortgageAmountText] = useState(false);
  const handleMortgageAmountTextClick = () => {
    setShowMortgageAmountText(!showMortgageAmountText)
    setTimeout(() => {
      setShowMortgageAmountText(false);
    }, 3000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };

  const [showDownAmountText, setShowDownAmountText] = useState(false);
  const handleDownAmountTextClick = () => {
    setShowDownAmountText(!showDownAmountText)
    setTimeout(() => {
      setShowDownAmountText(false);
    }, 3000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowInsuranceText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };
  const [showDownPerText, setShowDownPerText] = useState(false);
  const handleDownPerTextClick = () => {
    setShowDownPerText(!showDownPerText)
    setTimeout(() => {
      setShowDownPerText(false);
    }, 3000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowInsuranceText(false);
    setShowBalanceText(false);
  };
  const [showInsuranceText, setShowInsuranceText] = useState(false);
  const handleInsuranceTextClick = () => {
    setShowInsuranceText(!showInsuranceText)
    setTimeout(() => {
      setShowInsuranceText(false);
    }, 3000);
   setShowAnnualInterestRateText(false);setShowAnnualInterestRateMonthText(false);
    setShowTermText(false);
    setShowAmortizationText(false);
    setShowPaymentfreequencyText(false);
    setShowPaymentSheduleText1(false);
    setShowPaymentSheduleText2(false);
    setShowAskingPriceText(false);   
    setShowTotalPrinciplePaidText(false);
    setShowTotalAmountPaidText(false);
    setShowTotalInterestPaidText(false);
    setShowMortgageAmountText(false);
    setShowDownAmountText(false);
    setShowDownPerText(false);
    setShowBalanceText(false);
  };


  //const [askingPriceValue, setAskingPriceValue] = useState('1000000');
  const [askingPriceVariableParent, setAskingPriceVariableParent] = useState(false);
  const handleChangeAskingPriceValue = (event) => {
    const newValue = event.target.value;
    setAskingPriceValue(newValue);
    // dataList.askingprice=newValue;
    setAskingPriceVariableParent(true);
  };

  const handleMouseEnterPaymentButton = (index) => {
    var tempdataList = [...dataList]
    tempdataList[index].isSelected = true;
    setDataList(tempdataList);
  };

  const handleMouseLeavePaymentButton = (index) => {
    var tempdataList = [...dataList]
    tempdataList[index].isSelected = false;
    setDataList(tempdataList);
  };
  const [showPopupforSummary, setShowPopupforSummary] = useState(false);
  const [showResultSummary, setShowResultSummary] = useState([]);
  const handleSummary = async (mname) => {
    const index = dataList.findIndex(x => x.name === mname);
    const downPerValue = dataList[index].DownPaymentPercentage;
    const dwnamnt = dataList[index].DownPaymentAmount;

    const askingValue = dataList[index].askingprice;

    const rate = (dataList[index].rate) / 100;
    const amortValue = dataList[index].totalAmortization;
    const freqValue = dataList[index].frequency;
    const termsValue =dataList[index].totalTerm;
    const shedule = dataList[index].sheduledPayment;
    const mrval1 = dataList[index].askingprice - dwnamnt;
    // const cmhcinsurance = await getThirdResult(mrval1, downPerValue);
    const cmhcinsurance = dataList[index].cmhc;
    const mrval = dataList[index].mortgageamount;
    const monthlyPayment = await getSheduledPayment(askingValue, rate, amortValue, freqValue, mrval);
    const data = await getSecondResult(askingValue, rate, amortValue, freqValue, parseFloat(monthlyPayment), termsValue, mrval, parseFloat(monthlyPayment))
    setShowResultSummary(data.scheduleData);
    console.log("data.scheduleData/////", monthlyPayment)
    setShowPopupforSummary(true);
  };


  const handleCancel = () => {
    setShowPopupforSummary(false);


  };



  ////////////////////////backend/////////////////////////
  const [resultAsking, setResultAsking] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [result, setResult] = useState([3785.96, 3785.96, 3785.96, 3785.96, 3785.96, 3785.96, 3785.96]);
  const [result2, setResult2] = useState([{ Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
  { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
  { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 },
  { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }, { Totalinterest: 1407.60, Totalprinciple: 1431.60, Totalpayment: 2839.20 }]);
  const [askingprice, setAskingPrice] = useState([1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000]);
  const [interestRate, setInterestRate] = useState([0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03]);
  const [amortznPeriod, setAmortznPeriod] = useState([25, 25, 25, 25, 25, 25, 25]);
  const [FreqParam, setFreqParam] = useState(['M', 'M', 'M', 'M', 'M', 'M']);
  const [TermVal, setTermVal] = useState([5, 5, 5, 5, 5, 5, 5]);
  const [InitialpaymentVal, setInitialpaymentVal] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [mortgageamountVal, setmortgageamountVal] = useState([800000, 800000, 800000, 800000, 800000, 800000, 800000]);
  const [downPaymentPercentageValue, setDownPaymentPercentageValue] = useState([20, 20, 20, 20, 20, 20, 20]);
  const [downPaymentAmountValue, setDownPaymentAmountValue] = useState([200000, 200000, 200000, 200000, 200000, 200000, 200000]);
 

  const [mortgageAmountTextValue, setMortgageAmountTextValue] = useState([800000, 800000, 800000, 800000, 800000, 800000, 800000]);

  const [mortAmountValue, setMortAmountValue] = useState([800000, 800000, 800000, 800000, 800000, 800000, 800000]);

  const [mortgageAmountWithInsuranceValue, setMortgageAmountWithInsuranceValue] = useState([]);

  const [insuranceTextValue, setInsuranceTextValue] = useState([]);

  const handleChangeAskingprice = async (value, index) => {



    const AskingPriceValue = dataList[index].askingprice;

    const downPayment = dataList[index].DownPaymentPercentage;
    const MortgageAmount = (dataList[index].askingprice) - (dataList[index].askingprice * (dataList[index].DownPaymentPercentage / 100));
    var tempdataList1 = [...dataList]
    tempdataList1[index].mortgageamount = (dataList[index].askingprice) - (dataList[index].askingprice * (dataList[index].DownPaymentPercentage / 100)).toFixed(2);
    tempdataList1[index].DownPaymentAmount = value * (dataList[index].DownPaymentPercentage / 100);
    setDataList(tempdataList1);

    var res = await axios.post(`${API_URL}/calculate3`, { MortgageAmount, downPayment });

    var tempdataList = [...dataList]
    // tempdataList[index].mortgageamount=(value)-(value*(dataList[index].DownPaymentPercentage/100));
    tempdataList[index].cmhc = res.data.mortgageInsurance;
    // tempdataList[index].DownPaymentAmount=AskingPriceValue*(dataList[index].DownPaymentPercentage/100);
    setDataList(tempdataList);

    
    const rate = (dataList[index].rate) / 100;
    const Amortization = dataList[index].totalAmortization;
    const Frequency = dataList[index].frequency;
    const Term =dataList[index].totalTerm;
    const MortgageAmnt = dataList[index].mortgageamount;
    console.log("final price", dataList);
    try {
      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

     


      // Assuming monthlyPayment is an array received from the response
      const monthlyPayment = response.data.MonthlyPayment;
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}

      // Update the result array with the new values

      setResult((prevResult) => {
        const newArray = [...prevResult]; // Create a copy of the existing array
        newArray[index] = response.data.MonthlyPayment; // Modify the value at the specified index
        return newArray; // Set the updated array

      });




      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });


      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);

    } catch (error) {
      // Handle any error
    }
  };
  const getThirdResult = async (MortgageAmount, downPayment) => {
    // const response = await axios.post(`${API_URL}/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

    var res = await axios.post(`${API_URL}/calculate3`, { MortgageAmount, downPayment })

    const cmhcinsurance = res.data.mortgageInsurance;
    return cmhcinsurance;
  }


  const getSheduledPayment = async (AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt) => {
    const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });


    const monthlyPayment = response.data.MonthlyPayment;
    
    return monthlyPayment;
  }

  const getSecondResult = async (AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment) => {
    // const response = await axios.post(`${API_URL}/calculate', { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

    const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment });

    return response2.data;
  }

  const handleChangeAskingpriceWithInsurance = async (value, index) => {

    const AskingPriceValue = value;

    const downPayment = dataList[index].DownPaymentPercentage;
    const MortgageAmount = (value) - (value * (dataList[index].DownPaymentPercentage / 100));
    const Floatamount = parseFloat(MortgageAmount);
    const isFloat = Number.isFinite(Floatamount) && Floatamount % 1 !== 0;  
   const mortgamnt =  isFloat ? Floatamount.toFixed(2) : Floatamount.toString(); 
    var tempdataList1 = [...dataList]
    tempdataList1[index].mortgageamount = mortgamnt;
    tempdataList1[index].DownPaymentAmount = value * (dataList[index].DownPaymentPercentage / 100);
    setDataList(tempdataList1);

    const cmhcinsurance = await getThirdResult(MortgageAmount, downPayment)

    var tempdataList = [...dataList]
    // tempdataList[index].mortgageamount=(value)-(value*(dataList[index].DownPaymentPercentage/100));
    tempdataList[index].cmhc = cmhcinsurance;
    // tempdataList[index].DownPaymentAmount=AskingPriceValue*(dataList[index].DownPaymentPercentage/100);
    setDataList(tempdataList);



    const rate = (dataList[index].rate) / 100;
    const Term =dataList[index].totalTerm;

    const Amortization = dataList[index].totalAmortization;

    const Frequency = dataList[index].frequency;

    const MortgageAmnt = dataList[index].mortgageamount;
    const SheduledPayment = dataList[index].SheduledResult;

    try {
      //const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue,rate,Amortization,Frequency,MortgageAmnt});

      const monthlyPayment = await getSheduledPayment(AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt)
      // const monthlyPayment = response.data.MonthlyPayment;
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}


     





      const data = await getSecondResult(AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt, SheduledPayment)

      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: data.TotalPayment,
          TotalInterestPaid: data.TotalInterest,
          TotalPrinciplePaid: data.TotalPrinciple,

        };
        return newDataList;
      });

    
    } catch (error) {
      // Handle any error
    }
  };





  const handleOnchangeAskingPrice = async (value, index) => {


    const rate = (dataList[index].rate) / 100;
    const Amortization = dataList[index].totalAmortization;
    const AskingPriceValue = dataList[index].askingprice;
    const Frequency = dataList[index].frequency;
    const downPayment = ((value * 100) / AskingPriceValue).toFixed(1);
    const MortgageAmount = parseInt(AskingPriceValue) - parseInt(value);
    var res = await axios.post(`${API_URL}/calculate3`, { MortgageAmount, downPayment });

    var tempdataList = [...dataList]
    tempdataList[index].cmhc = res.data.mortgageInsurance;;
    setDataList(tempdataList);


    const MortgageAmnt = dataList[index].mortgageamount;

    const Term =dataList[index].totalTerm;
    console.log("$$$$", dataList[index].mortgageamount)
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod, FreqParam);
    try {
      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment;
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });

      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };

  const handleChangeInterest = async (event, index) => {
    const value = event.target.value;
    let rate = (value) / 100;
    const newArray = [...interestRate]
    newArray[index] = rate;
    setInterestRate(newArray);
    // setInterestRate[index]=rate;
    var tempdataList = [...dataList]
    tempdataList[index].rate = event.target.value;
    setDataList(tempdataList);
    const Amortization = dataList[index].totalAmortization;
    const AskingPriceValue = dataList[index].askingprice;
    const Frequency = dataList[index].frequency;

    const MortgageAmnt = dataList[index].mortgageamount;

    const Term =dataList[index].totalTerm;


    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod, FreqParam);
    try {
      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);

      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangePeriod = async (value, index) => {

    const Amortization = parseFloat(value);
    const newArray = [...amortznPeriod]
    newArray[index] = Amortization;
    setAmortznPeriod(newArray);
    var tempdataList = [...dataList]
    tempdataList[index].totalAmortization = parseFloat(value);
    setDataList(tempdataList);

    setAmortznPeriod[index] = amortznPeriod;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate) / 100;
    const Frequency = dataList[index].frequency;
    const Term =dataList[index].totalTerm;

    const MortgageAmnt = dataList[index].mortgageamount;
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangeFreequency = async (event, index) => {

    const Frequency = event.target.value;
   
    var tempdataList = [...dataList]
    tempdataList[index].frequency = event.target.value;
    setDataList(tempdataList);


    const Amortization = dataList[index].totalAmortization;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate) / 100;
    const Term =dataList[index].totalTerm;

    const MortgageAmnt = dataList[index].mortgageamount;
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = response.data.MonthlyPayment.toFixed(2);
        return newDataList;
      });
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangeTerm = async (value, index) => {

    const Term = parseFloat(value);

    const newArray = [...TermVal]
    newArray[index] = Term;
    setTermVal(newArray)
    var tempdataList = [...dataList]
        tempdataList[index].totalTerm = parseFloat(value);
        setDataList(tempdataList);

    // setTermVal[index]=parseInt(event.target.value);
    const Amortization = dataList[index].totalAmortization;
    const AskingPriceValue = dataList[index].askingprice;
    const rate = (dataList[index].rate) / 100;
    const Frequency = dataList[index].frequency;
    const MortgageAmnt = dataList[index].mortgageamount;
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);
    try {
      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
      // Perform further actions with the response data
    } catch (error) {
      // Handle any error
    }
  };
 


  const handleChangeDownPaymentPercentageParent = async (value, index) => {
    const Per = (value);
    let DownPaymentPercentage = (Per) / 100;
    const newArray = [...downPaymentPercentageValue]
    newArray[index] = DownPaymentPercentage;
   
    const AskingPriceValue = dataList[index].askingprice;
    
    const MortgageAmount = parseInt(AskingPriceValue) - (AskingPriceValue * (value / 100));
    // console.log("MortgageAmnt",parseInt(AskingPriceValue),parseInt(AskingPriceValue*(event.target.value.toFixed(1))))
    const downPayment = (value)
    // const Term = TermVal[index];
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod, FreqParam);
    try {
      const response1 = await axios.post(`${API_URL}/calculate3`, { MortgageAmount, downPayment });

      // Handle the response
      const mortgageInsurance = parseInt(response1.data.mortgageInsurance);
      const mortamnt = MortgageAmount + mortgageInsurance
      const Floatamount = parseFloat(mortamnt);
      const isFloat = Number.isFinite(Floatamount) && Floatamount % 1 !== 0;  
     const mortgamnt =  isFloat ? Floatamount.toFixed(2) : Floatamount.toString(); 
      var tempdataList1 = [...dataList]
      tempdataList1[index].cmhc = mortgageInsurance;
      tempdataList1[index].mortgageamount = mortgamnt;
      setDataList(tempdataList1);
      const Term =dataList[index].totalTerm;
      const Amortization = dataList[index].totalAmortization;

      const rate = (dataList[index].rate) / 100;
      const Frequency = dataList[index].frequency;
      const MortgageAmnt = dataList[index].mortgageamount;
      console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);

      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);
    } catch (error) {
      // Handle any error
    }
  };
  const handleChangeDownPaymentAmountParent = async (value, index) => {
    const amnt = parseInt(value);
    //  let DownPaymentAmount=(amnt);
    //  const newArray=[...downPaymentAmountValue]
    //  newArray[index]=DownPaymentAmount;
    var tempdataList = [...dataList]
    tempdataList[index].DownPaymentAmount = parseInt(value);
    setDataList(tempdataList);

    //setInterestRate(newArray)
    // setInterestRate[index]=rate;
    // const Amortization =amortznPeriod[index]; 
    const AskingPriceValue = dataList[index].askingprice;
    // const Frequency=FreqParam[index];
    //const Initialpayment=InitialpaymentVal[index];
    //  const rate=interestRate[index];
    const downPayment = ((value * 100) / AskingPriceValue).toFixed(1);
    const MortgageAmount = parseInt(AskingPriceValue) - parseInt(value);
    console.log("nnnn@@@", (value));
    //const Term = TermVal[index];
    console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod, FreqParam);
    try {
      const response1 = await axios.post(`${API_URL}/calculate3`, { MortgageAmount, downPayment });

      // Handle the response
      const mortgageInsurance = parseInt(response1.data.mortgageInsurance);
      const mortamnt = MortgageAmount + mortgageInsurance
      const Floatamount = parseFloat(mortamnt);
      const isFloat = Number.isFinite(Floatamount) && Floatamount % 1 !== 0;  
     const mortgamnt =  isFloat ? Floatamount.toFixed(2) : Floatamount.toString(); 
      var tempdataList1 = [...dataList]
      tempdataList1[index].cmhc = mortgageInsurance;
      tempdataList1[index].mortgageamount = mortgamnt;
      setDataList(tempdataList1);

      const Term =dataList[index].totalTerm;
      const Amortization = dataList[index].totalAmortization;

      const rate = (dataList[index].rate) / 100;
      const Frequency = dataList[index].frequency;
      const MortgageAmnt = dataList[index].mortgageamount;
      console.log("interestRate,initialPayment,amortznPeriod", interestRate, askingprice, amortznPeriod);

      const response = await axios.post(`${API_URL}/calculate`, { AskingPriceValue, rate, Amortization, Frequency, MortgageAmnt });

      // Handle the response
      const monthlyPayment = response.data.MonthlyPayment
      if(monthlyPayment<1){
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(4);
        return newDataList;
        });}
    else{
        setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].SheduledResult = monthlyPayment.toFixed(2);
        return newDataList;
    });}
      const newArray = result.slice(); // Create a shallow copy of the array
      newArray[index] = monthlyPayment; // Modify the value at the specified index
      setResult(newArray);
      const response2 = await axios.post(`${API_URL}/calculate2`, { AskingPriceValue, rate, Amortization, Frequency, monthlyPayment, Term, MortgageAmnt });
      console.log("second result", response2);
      setDataList(prevDataList => {
        const newDataList = [...prevDataList];
        newDataList[index].lowerResults = {
          TotalAmountPaid: response2.data.TotalPayment,
          TotalInterestPaid: response2.data.TotalInterest,
          TotalPrinciplePaid: response2.data.TotalPrinciple,

        };
        return newDataList;
      });
      const updatedResult2 = [...result2];
      updatedResult2[index].Totalinterest = response2.data.TotalInterest;
      updatedResult2[index].Totalprinciple = response2.data.TotalPrinciple;
      updatedResult2[index].Totalpayment = response2.data.TotalPayment;
      setResult2(updatedResult2);







    } catch (error) {
      // Handle any error
    }
  };






  useEffect(() => {


    console.log('datalist changed:', dataList);
  }, [dataList]);

  console.log("result from parent", result)

  // useEffect(() => {
  //   myfunction(askingprice)
  // }, [askingprice]);

  // useEffect(() => { 
  // myTermFunction(TermVal)
  // }, [TermVal]);

  // useEffect(() => {
  // myFunctionFreq(FreqParam)
  // }, [FreqParam]);


  // const myfunction = (arrayp)=>{
  // console.log("array:",arrayp)

  // }
  // const myTermFunction = (arrayTerm)=>{
  //   console.log("array:",arrayTerm)

  //   }

  //   const myFunctionFreq = (arrayFreq)=>{
  //     console.log("array:",arrayFreq)

  //     }


  const triangleStyle = {
    position: 'absolute',
    top: '-10.5px',
    width: '10px',
    height: '20px',
    borderTop: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderBottom: '20px solid #0C276C',
    transform: 'rotate(135deg)',

  };





  return (
    <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>


      <Helmet>
                <title>Mortgage Calculator Canada |Compare Mortgage Rates Canada Instantly</title>
                <meta name="description" content="Mortgage Calculator Canada and Mortgage Rates Canada - RATEWAVE's tools help Canadians find the best mortgage terms and monthly payments!" />
                <link rel="canonical" href="https://ratewaves.com/canada-mortgage" /> 
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Mortgage Calculator Canada |Compare Mortgage Rates Canada Instantly",
                        "description": "Mortgage Calculator Canada and Mortgage Rates Canada - RATEWAVE's tools help Canadians find the best mortgage terms and monthly payments!",
                        "url": "https://ratewaves.com/canada-mortgage",
                        "publisher": {
                            "@type": "Organization",
                            "name": "Rate Waves",
                            "logo": {
                                "@type": "ImageObject",
                                "url": logo, // Use the imported logo path
                                "width": 250,
                                "height": 50,
                            },
                        },
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://ratewaves.com/canada-mortgage?q={search_term_string}",
                            "query-input": "required name=search_term_string",
                        },
                    })}
                </script>             
            </Helmet>
            <h1 style={{ position: 'absolute', left: '-9999px' }}>Mortgage Calculator Canada</h1>


    <Box sx={{
                height: '100% ', width: '100vw', fontFamily: "poppins",fontSize: { md: '14px', sm: '14px',xs:'12px' }, fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'flex', sm: 'none', xs: 'none' }
            }}>
                <Header currentPage={currentPage}></Header>

            </Box>
            <Box sx={{ display: { md: 'none', sm: 'flex', xs: 'flex' }, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
                <HeaderMobile currentPage={currentPage}></HeaderMobile>

            </Box>
            <Box sx={{
                 width: '100%',alignItems: 'center', justifyContent: 'center',minWidth:'100%',
                display: 'flex', flexDirection: 'column', paddingLeft: { md: '16%', sm: '5%' , xs:'3%'}, 
                paddingRight: { md: '16%', sm: '5%', xs:'3%' }, gap: { md: '30px', sm: '30px',xs:'20px' } ,              
            }}>
               <Box sx={{
          width:'100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row',gap: '2%',paddingTop:'30px'
        }}>

          <Box sx={{ width: { md: '15%', sm: '25%',xs:'30%' }, alignItems: 'left', justifyContent: 'left', display: 'flex', flexDirection: 'row', }}>
            <img src={ImgFrame} alt="imgframe" style={{ width: '100%', height: '100%' }} />
          </Box>

          <Box sx={{ width:  { md: '85%', sm: '75%',xs:'70%' }, alignItems: 'left', justifyContent: 'left', display: 'flex', flexDirection: 'column', }}>
            <Typography variant="h2" sx={{ fontFamily: "poppins", fontSize: "12px", lineHeight: "24px", fontWeight: 400, width: '100%', paddingTop: '7px' }}>
              Buying a home in Canada is an exciting and significant step towards creating a stable and prosperous future. At ratewaves ,
              we understand the importance of making well-informed decisions, and our Canada Mortgage Calculator is here to guide you on your
              journey.
            </Typography>
            <Typography variant="h2" sx={{ fontFamily: "poppins", fontSize: "12px", lineHeight: "24px", fontWeight: 400, width: '100%', paddingTop: '7px' }}>
              We've tailored this calculator to meet the unique needs of homebuyers across the country.
              Whether you're in the bustling city of Toronto, the scenic landscapes of Vancouver, or anywhere in between, our calculator
              provides accurate and localized results for a range of Canadian mortgage options.
            </Typography>
          </Box>

        </Box>
        <Box sx={{
          width:'100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row',
          borderTop: '2px solid #E2E2E2'
        }}>
        </Box>

        <Box sx={{ display: 'flex', flexDirection:{ md: 'row', sm: 'row', xs:'column' } ,minWidth:'100%', 
                alignItems: { md: 'center', sm: 'center', xs:'left' },  width: '100%', gap: { md: '2%', sm: '0',xs:'20px' }, }} >
                    <Box sx={{
                        display: 'flex', flexDirection: 'row', width: { md: "18%", sm: "23%", xs:'100%' }, 
                        minWidth: {md: "18%", sm: "23%", xs:'100%' }, alignItems: 'left', 
                        gap: { md: '5%', sm: '25px',xs: '20px' },
                    }} >
            <Typography variant="h3" sx={{
              fontFamily: "poppins", fontSize: { md: "16px", sm: "14px",xs: "14px"}, lineHeight: "24px", fontWeight: "500", 
              color: "#313131",              display: 'flex', alignItems: 'center'
            }}>
              Purchase price<ArrowForwardIcon sx={{ paddingLeft: "5px", color: "#313131", fontSize: "16px", lineHeight: "24px" }} />
            </Typography>
          </Box>

          <Box sx={{
                        minWidth: { md: 'calc(80%)', sm: 'calc(76.5%)', xs:'100%' }, display: 'flex', flexDirection: 'row', 
                        alignItems:{ md: 'center', sm: 'center', xs:'left' },  gap: { md: '4.2%', sm: '1.8%',xs: '0px' },
                        justifyContent: { md: 'center', sm: 'center', xs:'left' },
                        
                    }} >
                        <Box sx={{
                            width: { md: '49.5%', sm: '100%',xs:'100%'  }, alignItems: 'left', justifyContent: 'left',
                             display: 'flex', flexDirection: 'row'
                           
                        }} >
              <TextField className='askingtxt' value={askingPriceValue} variant="outlined"
                onChange={(event) => { handleChangeAskingPriceValue(event, dataList.index) }}
                sx={{ width: "100%", fontFamily: "poppins", fontSize: "12px", lineHeight: "18px", fontWeight: "400", }}
                InputProps={{
                  sx: { height: '38px', color: " #626262" },
                  type: 'number', min: 0, max: 9,
                  '& label': { display: 'flex', alignItems: 'left', justifyContent: 'left' }
                }}

              /> </Box>
            <Box sx={{
                            width: { md: '50%', sm: '100%' }, alignItems: 'left', justifyContent: 'left', display: 'flex',
                            flexDirection: 'row'
                        }} >
                        </Box>
          </Box>
        </Box>
        <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',
            }}>
             
              <Typography variant="h3" sx={{
                fontFamily: 'poppins', fontSize: '16px',
                lineHeight: '24px', fontWeight: '600', color: '#313131', marginRight: 'auto',
              }}>Input parameters</Typography> </Box>

<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%',
                 gap: { md: '2%', sm: '2.5%',xs:'1%' },minWidth:'100%' }}>

           <Box sx={{
                display: 'flex', flexDirection: 'column', width: { md: '18%', sm: '23%',xs:'38%' },
                 minWidth: { md: '18%', sm: '23%',xs:'38%' },
                alignItems: 'center', gap: { md: '3.5%', sm: '20px',xs:'20px' }, height:{ md: "460px", sm: "460px",xs:'550px' }
            }}>
            
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',paddingTop:{ md: '14px', sm: '12px',xs:'12px' }
            }}>
              <Typography variant="h3" sx={{
                fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' },
                lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>Mortgage options</Typography></Box>
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',
            }}>
              <Typography variant="h3" sx={{
                fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' },
                lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>Purchase price</Typography> <HelpIcon onClick={handleAskingPriceTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showAskingPriceText && (<Box
                sx={{
                  position: 'absolute', top: 31, right: -170, width: '200px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}          >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>This shows the total amount to be paid to seller/builder</Typography></Box>)}
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',paddingTop:{ md: '5px', sm: '0px',xs:'0px' }
            }}>
              <Typography variant="h3" sx={{
                fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' },
                lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>Down payment (%)</Typography>  <HelpIcon onClick={handleDownPerTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showDownPerText && (<Box
                sx={{
                  position: 'absolute',  top: 31, right: -170, width: '200px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}          >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}> Portion of  purchase price arranged from own fund , not from mortgage loan</Typography>
              </Box>)}
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',paddingTop:{ md: '8px', sm: '0px',xs:'0px' }
            }}>
              <Typography variant="h3" sx={{
                fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' },
                lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>Down payment</Typography>  <HelpIcon onClick={handleDownAmountTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showDownAmountText && (<Box
                sx={{
                  position: 'absolute',  top: 31, right: -170, width: '200px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}                          >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>  Portion of  purchase price arranged from own fund , not from mortgage loan</Typography>
              </Box>)}
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',paddingTop:{ md: '8px', sm: '0px',xs:'0px' }
            }}>
              <Typography variant="h3" sx={{
                fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' },
                lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>CMHC insurance</Typography>  <HelpIcon onClick={handleInsuranceTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showInsuranceText && (<Box
                sx={{
                  position: 'absolute', top: 31, right: -170, width: '200px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}          >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>Required for homebuyers in Canada who have a down payment of less than 20% of the purchase price of the home.
                 It is designed to protect lenders in case the borrower defaults on their mortgage payments</Typography>
              </Box>)}
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',paddingTop:{ md: '8px', sm: '0px',xs:'0px' }
            }}>
              <Typography variant="h3" sx={{
                fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' },
                lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}> Mortgage amount</Typography>  <HelpIcon onClick={handleMortgageAmountTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showMortgageAmountText && (<Box
                sx={{
                  position: 'absolute', top: 31, right: -170, width: '200px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>Required Mortgage Amount</Typography></Box>)}
            </Box>
          
  <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative', paddingTop: { md: '10px', sm: '0px',xs:'25px' }
            }}>
              <Typography variant="h3" sx={{
                fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' },
                lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}> Amortization period</Typography><HelpIcon onClick={handleAmortizationTextClick} sx={{
                fontSize: '12px', color: '#878787', cursor: 'pointer'
              }} />
              {showAmortizationText && (<Box
                sx={{
                  position: 'absolute', top: 31, right: -170, width: "200px",
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>The number of years & months over which you will repay this loan</Typography></Box>)}

            </Box>

            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',paddingTop: { md: '14px', sm: '0px',xs:'50px' }
            }}>
              <Typography variant="h3" sx={{
                fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' },
                lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}> Annual interest (%)</Typography> <HelpIcon onClick={handleAnnualInterestRateClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showAnnualInterestRateText && (<Box
                sx={{
                  position: 'absolute', top: 31, right: -170, width: '200px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>Annual interest rate for this mortgage</Typography></Box>)}
            </Box>

            {/* <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',paddingTop: { md: '15px', sm: '0px',xs:'23px' }
            }}>
              <Typography variant="h3" sx={{
                fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' },
                lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}> Monthly interest (%)</Typography> <HelpIcon onClick={handleAnnualInterestRateMonthClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showAnnualInterestRateMonthText && (<Box
                sx={{
                  position: 'absolute', top: 31, right: -170, width: '200px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>Monthly interest rate for this mortgage</Typography></Box>)}
            </Box> */}
          
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',paddingTop: { md: '18px', sm: '0px',xs:'20px' }
            }}>
              <Typography variant="h3" sx={{
                fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' },
                lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}> Payment frequency</Typography><HelpIcon onClick={handlePaymentfreequencyTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showPaymentfreequencyText && (<Box
                sx={{
                  position: 'absolute', top: 31, right: -170, width: '200px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>Monthly, weekly, biweekly(Every two weeks), semi monthly(24 x per year), Accelerated BiWeekly(Reducing Amortization Period
                  by making extra payment, half  of monthly payment on every two weeks.), Accelerated Weekly(Another accelerated option, one fourth of monthly payment on every weeks.)</Typography></Box>)}
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',paddingTop: { md: '20px', sm: '0px',xs:'42px' }
            }}>
              <Typography variant="h3" sx={{
                fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' },
                lineHeight: '21px', fontWeight: '400', color: '#313131', marginRight: 'auto',
              }}>Term</Typography> <HelpIcon onClick={handleTermClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showTermText && (<Box
                sx={{
                  position: 'absolute',  top: 31, right: -170, width: '200px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>The number of years will be engaged with proposed mortgage</Typography></Box>)}
            </Box>


          </Box>

          <Box sx={{
          minWidth: { md: '80%', sm: '73%' }, width: { md: '80%', sm: '73%' }, display: { md:'flex', sm: 'flex',xs:'none' }, flexDirection: 'row', 
          alignItems: 'center', justifyContent: 'center', gap: { md: '4.2%', sm: '1.7%' }
          }}>
            {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex).map((data, index) => (
              <Box key={index} className="slide" sx={{width: { md: '22%', sm: '25%' },minWidth:{ md: '22%', sm: '25%' }}} >
                <DivComponents dataList={dataList} setDataList={setDataList}
                  index={index} parentIndex={index} CheckboxEnabled={CheckboxEnabled} setCurrentSlideIndex={setCurrentSlideIndex}
                  currentSlideIndex={currentSlideIndex} mortgageName={data.name}
                  isDeleteButtonEnabled={isDeleteButtonEnabled} selectedCheckboxList={selectedCheckboxList}
                  setCheckboxSelectedList={setCheckboxSelectedList} askingPriceValue={askingPriceValue}
                  FreqParam={FreqParam} setFreqParam={setFreqParam} amortznPeriod={amortznPeriod} setAmortznPeriod={setAmortznPeriod}
                  TermVal={TermVal} setTermVal={setTermVal} askingprice={askingprice} setAskingPrice={setAskingPrice}
                  isSelected={isSelected} setIsSelected={setIsSelected}
                  insuranceTextValue={insuranceTextValue} setInsuranceTextValue={setInsuranceTextValue} mortgageAmountWithInsuranceValue={mortgageAmountWithInsuranceValue}
                  setMortgageAmountWithInsuranceValue={setMortgageAmountWithInsuranceValue} handleOnchangeAskingPrice={handleOnchangeAskingPrice}
                  handleChangeAskingpriceWithInsurance={handleChangeAskingpriceWithInsurance}
                  result={result} setResult={setResult} handleChangeTerm={handleChangeTerm}
                  handleChangeInterest={handleChangeInterest} handleChangePeriod={handleChangePeriod}
                  handleChangeAskingprice={handleChangeAskingprice} handleChangeFreequency={handleChangeFreequency}
                  mortgageAmountTextValue={mortgageAmountTextValue} setMortgageAmountTextValue={setMortgageAmountTextValue}
                  askingPriceVariableParent={askingPriceVariableParent} setAskingPriceVariableParent={setAskingPriceVariableParent}
                  handleChangeDownPaymentPercentageParent={handleChangeDownPaymentPercentageParent}
                   handleChangeDownPaymentAmountParent={handleChangeDownPaymentAmountParent}
                   downBorderAmount={downBorderAmount} setdownBorderAmount={setdownBorderAmount} downBorderPercentage={downBorderPercentage}
                   setdownBorderPercentage={setdownBorderPercentage}
                />
              </Box>
            ))}
          </Box>
          <Box sx={{
           width:'60%', minWidth:'60%', display: { md:'none', sm: 'none',xs:'flex' }, 
           flexDirection: 'row',alignItems: 'left', justifyContent: 'left', gap:'1.5%',
          }}>
            {dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex).map((data, index) => (
              <Box key={index} className="slide" sx={{width: '50%',minWidth: '50%'}} >
                <DivComponents dataList={dataList} setDataList={setDataList}
                  index={index} parentIndex={index} CheckboxEnabled={CheckboxEnabled} setCurrentSlideIndex={setCurrentSlideIndex}
                  currentSlideIndex={currentSlideIndex} mortgageName={data.name}
                  isDeleteButtonEnabled={isDeleteButtonEnabled} selectedCheckboxList={selectedCheckboxList}
                  setCheckboxSelectedList={setCheckboxSelectedList} askingPriceValue={askingPriceValue}
                  FreqParam={FreqParam} setFreqParam={setFreqParam} amortznPeriod={amortznPeriod} setAmortznPeriod={setAmortznPeriod}
                  TermVal={TermVal} setTermVal={setTermVal} askingprice={askingprice} setAskingPrice={setAskingPrice}
                  isSelected={isSelected} setIsSelected={setIsSelected}
                  insuranceTextValue={insuranceTextValue} setInsuranceTextValue={setInsuranceTextValue} mortgageAmountWithInsuranceValue={mortgageAmountWithInsuranceValue}
                  setMortgageAmountWithInsuranceValue={setMortgageAmountWithInsuranceValue} handleOnchangeAskingPrice={handleOnchangeAskingPrice}
                  handleChangeAskingpriceWithInsurance={handleChangeAskingpriceWithInsurance}
                  result={result} setResult={setResult} handleChangeTerm={handleChangeTerm}
                  handleChangeInterest={handleChangeInterest} handleChangePeriod={handleChangePeriod}
                  handleChangeAskingprice={handleChangeAskingprice} handleChangeFreequency={handleChangeFreequency}
                  mortgageAmountTextValue={mortgageAmountTextValue} setMortgageAmountTextValue={setMortgageAmountTextValue}
                  askingPriceVariableParent={askingPriceVariableParent} setAskingPriceVariableParent={setAskingPriceVariableParent}
                  handleChangeDownPaymentPercentageParent={handleChangeDownPaymentPercentageParent}
                   handleChangeDownPaymentAmountParent={handleChangeDownPaymentAmountParent}
                   downBorderAmount={downBorderAmount} setdownBorderAmount={setdownBorderAmount} downBorderPercentage={downBorderPercentage}
                   setdownBorderPercentage={setdownBorderPercentage}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', 
             gap: { md: '2%', sm: '2.2%',xs:'1%' } ,minWidth:'100%'  }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'row',  width: { md: '18%', sm: '23%',xs:'38%' },
                        minWidth: { md: '18%', sm: '23%',xs:'38%' },alignItems: 'center', position: 'relative',
                    }}>
            <Typography variant="h3" sx={{
              fontFamily: "poppins",fontSize: { md: '14px', sm: '14px',xs:'12px' }, fontWeight: "400",
              color: " #313131", marginRight: 'auto',
            }}> Scheduled Payment</Typography><HelpIcon onClick={handlePaymentSheduleTextClick1} sx={{
              fontSize: '12px', cursor: 'pointer',
              color: '#878787'
            }} />
            {showPaymentSheduleText1 && (<Box
              sx={{
                position: 'absolute', top: 31, right: -170, width: '200px',
                zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
              }}            >
              <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
              <Typography variant="h3" sx={{
                fontFamily: 'poppins', fontSize: '12px',
                lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
              }}>Required payment  on each scheduled payment date</Typography></Box>)}
          </Box>
          <Box sx={{
             minWidth: { md: '80%', sm: '73%' }, width: { md: '80%', sm: '73%' }, display: { md:'flex', sm: 'flex',xs:'none' }, flexDirection: 'row', 
             alignItems: 'center', justifyContent: 'center', gap: { md: '4.2%', sm: '1.7%' }
          }}>
            {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex).map((value, index) => (
              <Box key={index} sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'center', border: dataList[index].isSelected ? "1px solid #0C276C" : "1px solid #CFC9C9",
                alignItems: 'center',   width: { md: '22%', sm: '25%' },minWidth:{ md: '22%', sm: '25%' }, height: '35px', borderRadius: '8px', background: dataList[index].isSelected ? "#F9F9F9" : "white",
              }} onMouseEnter={() => handleMouseEnterPaymentButton(index)}
                onMouseLeave={() => handleMouseLeavePaymentButton(index)}>
                <Typography variant="h3" sx={{
                  fontFamily: "poppins",fontSize: { md: '14px', sm: '14px',xs:'12px' }, fontWeight: "600",
                  color: " #313131",
                }}>{value.SheduledResult}</Typography>
              </Box>
            ))}
          </Box>
          <Box sx={{
             width:'60%', minWidth:'60%', display: { md:'none', sm: 'none',xs:'flex' }, 
             flexDirection: 'row',alignItems: 'left', justifyContent: 'left', gap:'1.5%',
          }}>
            {dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex).map((value, index) => (
              <Box key={index} sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'center', border: dataList[index].isSelected ? "1px solid #0C276C" : "1px solid #CFC9C9",
                alignItems: 'center',   width: '50%',minWidth: '50%' , height: '35px', borderRadius: '8px', background: dataList[index].isSelected ? "#F9F9F9" : "white",
              }} onMouseEnter={() => handleMouseEnterPaymentButton(index)}
                onMouseLeave={() => handleMouseLeavePaymentButton(index)}>
                <Typography variant="h3" sx={{
                  fontFamily: "poppins",fontSize: { md: '14px', sm: '14px',xs:'12px' }, fontWeight: "600",
                  color: " #313131",
                }}>{value.SheduledResult}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box sx={{
          display: "flex", flexDirection: 'row', justifyContent: 'right', alignItems: 'right',
          width: '100%' ,minWidth:'100%',right:0 ,position:'relative'
        }}>
           <Button onClick={handleAddSlide} disabled={dataList.length === 7} variant="contained"
                        sx={{
                            color: "white", height: '30px', width: { md: '18%', sm: '30%', xs:'50%' },
                            minWidth:{ md: '18%', sm: '30%',xs:'50%' },  borderRadius: "12px",
                            background: dataList.length === 7 ? '#939393' : '#0C276C',
                            border: dataList.length === 7 ? "1px solid #939393" : "1px solid #0C276C", fontFamily: "Roboto",
                            textTransform: "none", fontStyle: "normal", fontWeight: "500", fontSize: "12px", '&:hover': { bgcolor: "#0B1E51", color: '#fff', border:'1px solid #0B1E51' }
                        }}
          startIcon={
            <div sx={{color: 'white', height: '12px', width: '12px', '&:hover': { color: '#0C276C',  }, }} >
                <AddCircleOutlineIcon sx={{ height: '12px', width: '12px', }} />
            </div>} >
            Add more options
          </Button>

        </Box>
        {IsChevorButton &&  <Box sx={{
          display: 'flex', flexDirection: "row", justifyContent: 'right',gap:'10px',width:'100%',minWidth:'100%',right:0 
        }}>
           <ChevronLeftIcon onClick={handlePrevSlide} disabled={currentSlideIndex === 4} sx={{
            background: "#CFDAF4", cursor: currentSlideIndex === 4 ? 'default' : 'pointer',
            color: currentSlideIndex === 4 ? "#CCCCCC" : "#0C276C", boxShadow: "2px 0px 4px 0px #00000040 ", borderRadius: "50%",
            width: "32px", height: '32px'
          }} ></ChevronLeftIcon>

         <ChevronRightIcon onClick={isdisableAddSlidebutton ? null : handleNextSlide} sx={{
            background: "#CFDAF4", cursor: isdisableAddSlidebutton ? 'default' : 'pointer',
            boxShadow: "2px 0px 4px 0px #00000040", color: isdisableAddSlidebutton ? "#CCCCCC" : "#0C276C", borderRadius: "50%",
            width: "32px", height: '32px'
          }} ></ChevronRightIcon>

        </Box>}
        <Box sx={{ display: 'flex', flexDirection: "row", alignItems: "center", width: '100%',minWidth:'100%',
                 gap: { md: '2%', sm: '2.5%', xs:'1%' } }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column',  alignItems: 'center', alignItems: 'center',
                        height: "300px",width: { md: '18%', sm: '23%',xs:'38%' }, minWidth: { md: '18%', sm: '23%',xs:'38%' },                       
                       gap: { md: '7%', sm: '17px',xs:'22px' },
                    }}>
            < Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center', paddingTop:'12px'
            }}>
              <Typography variant="h3" sx={{
                fontFamily: "poppins",fontSize: { md: '14px', sm: '14px',xs:'12px' }, fontWeight: "400",
                color: " #313131", marginRight: 'auto',
              }}>Mortgage options</Typography></Box>
            < Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',
            }}>
              <Typography variant="h3" sx={{
                fontFamily: "poppins",fontSize: { md: '14px', sm: '14px',xs:'12px' }, fontWeight: "400",
                color: " #313131", marginRight: 'auto',
              }}>Scheduled Payment</Typography> <HelpIcon onClick={handlePaymentSheduleTextClick2} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showPaymentSheduleText2 && (<Box
                sx={{
                  position: 'absolute',  top: 31, right: -170, width: '200px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#fff', marginRight: 'auto',
                }}>Required payment  on each scheduled payment date</Typography></Box>)}
            </Box>
            < Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',
            }}>
              <Typography variant="h3" sx={{
                fontFamily: "poppins",fontSize: { md: '14px', sm: '14px',xs:'12px' }, fontWeight: "400",
                color: " #313131", marginRight: 'auto',
              }}> Total Principal Paid</Typography><HelpIcon onClick={handleTotalPrinciplePaidTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showTotalPrinciplePaidText && (<Box
                sx={{
                  position: 'absolute', top: 31, right: -170, width: '200px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>The total amount of principal payment made during the Term and Amortization period respectively</Typography></Box>)}</Box>

            < Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',
            }}>
              <Typography variant="h3" sx={{
                fontFamily: "poppins",fontSize: { md: '14px', sm: '14px',xs:'12px' }, fontWeight: "400",
                color: " #313131", marginRight: 'auto'
              }}>Total interest paid </Typography> <HelpIcon onClick={handleTotalInterestPaidTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showTotalInterestPaidText && (<Box
                sx={{
                  position: 'absolute', top: 31, right: -170, width: '200px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>Total of all interest paid during the Term  period respectively assuming that the conditions of your loan
                </Typography></Box>)}</Box>
            < Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',
            }}>
              <Typography variant="h3" sx={{
                fontFamily: "poppins",fontSize: { md: '14px', sm: '14px',xs:'12px' }, fontWeight: "400",
                color: " #313131", marginRight: 'auto',
              }}>Total amount paid</Typography><HelpIcon onClick={handleTotalAmountPaidTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showTotalAmountPaidText && (<Box
                sx={{
                  position: 'absolute', top: 31, right: -320, width: '350px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>Total of all payments made during the Term and Amortization period respectively, assuming that the conditions of your loan
                  (e.g. interest rate, amortization period, term, etc.) will not change during these periods.</Typography></Box>)}</Box>

                  < Box sx={{
              display: 'flex', flexDirection: 'row', width: '100%',
              alignItems: 'center',height: '28px' ,position: 'relative',
            }}>
              <Typography variant="h3" sx={{
                fontFamily: "poppins",fontSize: { md: '14px', sm: '14px',xs:'12px' }, fontWeight: "400",
                color: " #313131", marginRight: 'auto',
              }}>Balance</Typography><HelpIcon onClick={handleBalanceTextClick} sx={{
                fontSize: '12px', cursor: 'pointer',
                color: '#878787'
              }} />
              {showBalanceText && (<Box
                sx={{
                  position: 'absolute', top: 31, right: -320, width: '350px',
                  zIndex: 9999, background: '#0C276C', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '7px',borderRadius:'8px'
                }}              >
                <Box style={triangleStyle} sx={{ left: { md: '20px', sm: '20px', xs: '10px ' } }}></Box>
                <Typography variant="h3" sx={{
                  fontFamily: 'poppins', fontSize: '12px',
                  lineHeight: '21px', fontWeight: '400', color: '#ffffff', marginRight: 'auto',
                }}>Balance</Typography></Box>)}</Box>

          </Box>

          
          <Box sx={{
           minWidth: { md: '80%', sm: '73%' }, width: { md: '80%', sm: '73%' }, display: { md:'flex', sm: 'flex',xs:'none' }, flexDirection: 'row', 
           alignItems: 'center', justifyContent: 'center', gap: { md: '4.2%', sm: '1.7%' }
          }}>

            {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex)

              .map((slide, index) => (
                <Box key={index} className="slide" sx={{ width: { md: '22%', sm: '25%' },minWidth:{ md: '22%', sm: '25%' } }} >
                  <Payment dataList={dataList} setDataList={setDataList} index={index} mortgageName={slide.name} onChange={() => handleNextCheckboxChange(slide.name)}
                    isCheckedCheckboxNext={selectedCheckboxList.includes(slide.name)} result2={result2} result={result}
                    isSelected={isSelected} setIsSelected={setIsSelected}
                  />
                </Box>
              ))}
          
          </Box>
          <Box sx={{
           width:'60%', minWidth:'60%', display: { md:'none', sm: 'none',xs:'flex' }, 
           flexDirection: 'row',alignItems: 'left', justifyContent: 'left', gap:'1.5%',
          }}>

            {dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex)

              .map((slide, index) => (
                <Box key={index} className="slide" sx={{ width: '50%',minWidth: '50%' }} >
                  <Payment dataList={dataList} setDataList={setDataList} index={index} mortgageName={slide.name} onChange={() => handleNextCheckboxChange(slide.name)}
                    isCheckedCheckboxNext={selectedCheckboxList.includes(slide.name)} result2={result2} result={result}
                    isSelected={isSelected} setIsSelected={setIsSelected}
                  />
                </Box>
              ))}
          
          </Box>

        </Box>
        <Box sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', width: '100%',minWidth:'100%',
                 gap: { md: '2%', sm: '2.5%', xs:'1%' }, paddingBottom: '30px' }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'row',  width: { md: '18%', sm: '23%',xs:'38%' },
                        minWidth: { md: '18%', sm: '23%',xs:'38%' },
                        alignItems: 'center'
                    }}> </Box>
                    <Box sx={{
                        minWidth: { md: '80%', sm: '73%' }, width: { md: '80%', sm: '73%' }, display: { md:'flex', sm: 'flex',xs:'none' }, flexDirection: 'row', 
                        alignItems: 'center', justifyContent: 'center', gap: { md: '4.2%', sm: '1.7%' }
                    }}>
                        {dataList.slice(Math.abs(4 - currentSlideIndex), currentSlideIndex).map((data, index) => (
                            <Box key={index} className="slide" sx={{width: { md: '22%', sm: '25%' },minWidth:{ md: '22%', sm: '25%' } }}>
                                <Button id={index} variant="contained" onClick={function () { handleSummary(data.name); }}
                                    sx={{
                                        color: "white", textTransform: "none", height: { md: '36px', sm: '36px' }, padding: { md: '5px', sm: '5px' },
                                        background: "#EB5648", border: "1px solid #EB5648", fontFamily: "Poppins", fontStyle: "normal", lineHeight: "18px",
                                        fontWeight: "400", fontSize: "12px", width: "100%", borderRadius: "8px",
                                        '&:hover': { bgcolor: "#D9210E", color: '#fff', border:'1px solid #D9210E' }
                                    }}
                                    onMouseEnter={() => handleMouseEnterPaymentButton(index)}
                                    onMouseLeave={() => handleMouseLeavePaymentButton(index)}  >
                                    Payment Schedule
                                </Button>
                            </Box>))}
                    </Box>
                    <Box sx={{
                       width:'60%', minWidth:'60%', display: { md:'none', sm: 'none',xs:'flex' }, 
                       flexDirection: 'row',alignItems: 'left', justifyContent: 'left', gap:'1.5%',
                    }}>
                        {dataList.slice(Math.abs(2 - currentSlideIndex), currentSlideIndex).map((data, index) => (
                            <Box key={index} className="slide" sx={{width: '50%',minWidth: '50%'  }}>
                                <Button id={index} variant="contained" onClick={function () { handleSummary(data.name); }}
                                    sx={{
                                        color: "white", textTransform: "none", height: { md: '36px', sm: '36px' }, padding: { md: '5px', sm: '5px' },
                                        background: "#EB5648", border: "1px solid #EB5648", fontFamily: "Poppins", fontStyle: "normal", lineHeight: "18px",
                                        fontWeight: "400", fontSize: "12px", width: "100%", borderRadius: "8px",
                                        '&:hover': { bgcolor: "#D9210E", color: '#fff', border:'1px solid #D9210E' }
                                    }}
                                    onMouseEnter={() => handleMouseEnterPaymentButton(index)}
                                    onMouseLeave={() => handleMouseLeavePaymentButton(index)}  >
                                    Payment Schedule
                                </Button>
                            </Box>))}
                    </Box>
                    {showPopupforSummary && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: '2'
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',

                                    width: '80%',
                                    height: '80%',
                                    overflowY: 'auto',
                                    backgroundColor: '#fff',
                                    padding: { md: "75px", sm: "50px" },
                                    border: '1px solid #CFC9C9',
                                    borderRadius: '12px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                    boxSizing: 'border-box',
                                    zIndex: '3'
                                }}
                            >
                                <IconButton onClick={handleCancel} sx={{
                                    color: '#939393', position: 'absolute', width: '20px', height: '20px',
                                    left: { md: '97%', sm: '95%' }, top: '14px'
                                }} >
                                    <HighlightOffIcon ></HighlightOffIcon>
                                </IconButton>

                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: { md: 650, sm: 800 } }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: '#0C276C', }}>
                                                <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#ffffff', textAlign: 'center' }}>Period</TableCell>
                                                <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#ffffff', textAlign: 'center' }}>Principal Payment</TableCell>
                                                <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#ffffff', textAlign: 'center' }}>Interest Payment</TableCell>
                                                <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#ffffff', textAlign: 'center' }}>Total Payment</TableCell>
                                                <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#ffffff', textAlign: 'center' }}>Ending Balance</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {

                                                showResultSummary.map((row, index) => (
                                                    <TableRow
                                                        key={index}

                                                        sx={{
                                                            '&:nth-of-type(odd)': {
                                                                backgroundColor: '#F3F3F3',
                                                            },
                                                            '&:last-child td, &:last-child th': {
                                                                border: 0,
                                                            },

                                                        }}
                                                    >

                                                        <TableCell sx={{
                                                            fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' }, lineHeight: '21px', fontWeight: '400', color: '#313131',
                                                            textAlign: 'center', padding: { md: '15px', sm: '10px' }
                                                        }}>{row.frequency} {row.month}</TableCell>
                                                        <TableCell sx={{
                                                            fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' }, lineHeight: '21px', fontWeight: '400', color: '#313131',
                                                            textAlign: 'center', padding: { md: '15px', sm: '10px' }
                                                        }}>{row.principlePayment}</TableCell>
                                                        <TableCell sx={{
                                                            fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' }, lineHeight: '21px', fontWeight: '400', color: '#313131',
                                                            textAlign: 'center', padding: { md: '15px', sm: '10px' }
                                                        }}>{row.interestPayment}</TableCell>
                                                        <TableCell sx={{
                                                            fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' }, lineHeight: '21px', fontWeight: '400', color: '#313131',
                                                            textAlign: 'center', padding: { md: '15px', sm: '10px' }
                                                        }}>{row.totalPayment}</TableCell>
                                                        <TableCell sx={{
                                                            fontFamily: 'poppins',fontSize: { md: '14px', sm: '14px',xs:'12px' }, lineHeight: '21px', fontWeight: '400', color: '#313131',
                                                            textAlign: 'center', padding: { md: '15px', sm: '10px' }
                                                        }}>{row.EndingBalance}</TableCell>
                                                    </TableRow>
                                                ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer></Box></Box>)} </Box>
              {showPopupforCheckbox && (<CheckboxPopUp ></CheckboxPopUp>)}






            
            </Box>
            <Footer></Footer>
            </Grid>
)
}
export default CanadaMortgage;