import React, { useState } from 'react'
import Header1 from './header1-modified'
import { Avatar, Box, Button, Card, CardActions, CardContent, Collapse, IconButton, InputBase, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Paper, Popper, Typography, styled } from '@mui/material'
import Line1 from './images/line.png';
import Line2 from './images/line1.png';
import John from './images/john1.png';
import SendIcon from '@mui/icons-material/Send';
import { KeyboardArrowDown } from '@mui/icons-material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Leftbox from './leftbox';
import Header1Mod from './header1-modified';
function DiscussionroomMob() {
    const [expanded, setExpanded] = React.useState(false);
    const [expanded1, setExpanded1] = React.useState(false);
    const [expanded2, setExpanded2] = React.useState(false);
    const [expanded3, setExpanded3] = React.useState(false);

    const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };
  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
  };
  const handleExpandClick3 = () => {
    setExpanded3(!expanded3);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
   };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{ overflow: 'hidden' }}>
        <Header1Mod />
        <Box sx={{ height: '970px', overflowY: 'auto', width: '100%', paddingLeft:{lg:'68px',xs:'10px'}, paddingRight:{lg:'68px',xs:'10px'}, paddingTop:{lg:'16px',xs:'5px'}, paddingBottom: '42px' }}>
                <Typography sx={{ fontWeight: 500, fontSize: {lg:'20px',xs:'14px'}, color: '#313131', fontFamily: 'Poppins' }}>Discussion room</Typography>
                <Box sx={{width: '100%', height:{lg:'60px',xs:'44px'}, marginTop: '4px', backgroundColor: '#1F6373',display:'flex',alignItems:'center', paddingLeft: {lg:'30px',xs:'12px'},borderRadius:'8px 8px 0px 0px'}}>
                   {/* <ArrowBackIcon sx={{ width: {lg:'24px',xs:14}, height:{lg:'24px',xs:'14px'},marginRight: '66px', color: 'white' }} /> */}
                </Box>
                <Box sx={{ width: '100%', height: '796px', display: 'flex', flexDirection: {lg:'row',md:'row',sm:'row',xs:'column-reverse'}, marginTop: '8px', gap: '10px', alignItems: 'center' }}>
                <Box sx={{ width: {lg:'834px',sm:'70%',xs:'94%'}, height: {lg:'796px',md:'796px',sm:'796px',xs:'68%'}, display: 'flex', flexDirection: 'column', backgroundColor: '#F8F8F8',paddingLeft:'12px',paddingRight:'12px' }}>
                    <Box sx={{width:{xs:'100%'},display:{lg:'flex',md:'flex',sm:'flex',xs:'none'},flexDirection:'row',alignItems:'center',alignContent:'center',justifyContent:'center',gap:'12px'}}>
                        <img src={Line1} width={'50%'}/>
                        <Typography sx={{fontWeight: 400, fontSize: '16px', color: '#939393', fontFamily: 'Poppins'}}>21/02/2013</Typography>
                        <img src={Line2} width={'50%'}/>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: '4px',marginTop:'10px' }}>
                                <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                                    <img src={John} />
                                </Box>
                                <div style={{ height: '38px', width: '50px', display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Hello</Typography>
                                </div>
                            </Box>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '4px', marginRight: '28px' }}>
                                <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400 }}>8:17 PM</Typography>
                            </div>
                            <Box sx={{display:'flex',flexDirection:'column',marginTop:{lg:'8px',md:'8px',sm:'8px',xs:'4px'}}}>
                                <Typography sx={{ color: '#E2A827', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500,marginLeft:'32px' }}>Agent #6622</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row',gap: '8px',marginTop:{lg:'8px',md:'8px',sm:'8px',xs:'4px'}}}>
                                <Avatar sx={{ color: 'white', backgroundColor: '#E2A827', width: '20px', height: '20px', fontSize: '12px' }}>A</Avatar>
                            <div style={{ height: '54px', width: '374px', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px',backgroundColor: 'white', borderRadius: '0px 8px 8px 8px', display: 'flex',alignItems:'center' }}>
                                <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                Hello! Thanks for reaching out us at Rate wave. did you find what you were looking for?
                                </Typography>
                            </div>
                            </Box>
                            <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '4px', fontWeight: 400, marginRight: '28px' }}>8:17 PM</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: '4px',marginTop:{lg:'8px',md:'8px',sm:'8px',xs:'4px'} }}>
                                <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                                    <img src={John} />
                                </Box>
                                <Box sx={{ height: '56px', width: {lg:'360px',md:'360px',sm:'360px',xs:'90%'}, paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                    Yeah, i am interested with your quotation of mortgage 4, can you explain about it?
                                    </Typography>
                                </Box>
                                </Box>
                                <div style={{ display: 'flex', flexDirection: 'row-reverse'}}>
                                <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400, marginRight: '28px',marginTop:'4px' }}> 8:17 PM</Typography>
                            </div>
                            <Box sx={{display:'flex',flexDirection:'row',gap:'8px',marginTop:{lg:'8px',md:'8px',sm:'8px',xs:'4px'}}}>
                            <Avatar sx={{ color: 'white', backgroundColor: '#E2A827', width: '20px', height: '20px', fontSize: '12px' }}>A</Avatar>
                                <div style={{ height: '38px', width: '94px',display:'flex', alignItems: 'center', paddingLeft: '10px',paddingRight:'10px', backgroundColor: 'white', borderRadius: '0px 8px 8px 8px' }}>
                                    <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Yeah, sure</Typography>
                                </div>
                            </Box>
                            <Box sx={{ height: '64px', width:{lg:'396px',md:'396px',sm:'396px',xs:'90%'}, alignItems: 'center', paddingLeft: '10px',paddingRight:'10px', marginLeft: '28px', marginTop: '8px', backgroundColor: 'white', borderRadius: '0px 8px 8px 8px', display: 'flex' }}>
                                <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                Quotation for mortgage 4 will help you get a comparatively law interest rate
                                </Typography>
                            </Box>
                            <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '4px', fontWeight: 400 }}>8:17 PM</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '4px',marginTop:'8px' }}>
                                <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                                    <img src={John} />
                                </Box>
                                <Box sx={{ height: '60px', width: {lg:'412px',md:'412px',sm:'412px', xs:'90%'}, paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                    okay, i will catch you later, now i am looking for a quotation with less amortization period, thanks for your corporation
                                    </Typography>
                                </Box>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row-reverse'}}>
                                <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400, marginRight: '28px',marginTop:'4px' }}>8:17 PM</Typography>
                            </div>
                            <Box sx={{display:'flex',flexDirection:'column',marginTop:{lg:'12px',md:'12px',xs:'4px'}}}>
                                <Typography sx={{ color: '#EB5648', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500,marginLeft:'32px' }}>Agent #6622</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row',gap: '8px',marginTop:{lg:'8px',md:'8px',sm:'8px',xs:'4px'}}}>
                                <Avatar sx={{ color: 'white', backgroundColor: '#EB5648', width: '20px', height: '20px', fontSize: '12px' }}>A</Avatar>
                            <Box sx={{ height: '64px', width: {lg:'392px',md:'392px',sm:'392px', xs:'90%'}, alignItems: 'center', paddingLeft: '10px', paddingRight: '10px',backgroundColor: 'white', borderRadius: '0px 8px 8px 8px', display: 'flex',alignItems:'center' }}>
                                <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                Hi.. i have a better quote with less amortization period, Do you want to know more about it?
                                </Typography>
                            </Box>
                            </Box>
                            <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '4px', fontWeight: 400, marginRight: '28px' }}>8:17 PM</Typography>
                            </Box>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '4px',marginTop:'12px' }}>
                                <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                                    <img src={John} />
                                </Box>
                                <Box sx={{ height: '56px', width: {lg:'412px',md:'412px',sm:'412px', xs:'90%'}, paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                    okay, i will catch you later, now i am looking for a quotation with less amortization period.
                                    </Typography>
                                </Box>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row-reverse'}}>
                                <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400, marginRight: '28px',marginTop:'4px' }}>8:17 PM</Typography>
                            </div>
                                <Paper
                                elevation={1}
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: {lg:'794px',md:'794px',sm:'90%',xs:'90%'}, height: '52px', borderRadius: '0.25rem', paddingRight: '12px', paddingLeft: '0.5rem', marginLeft:'8px', marginTop: '8px' }}
                            >
                                <InputBase
                                    placeholder="Type here"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <Button endIcon={<SendIcon sx={{
                                    color: 'white', width: '18px', height: '18px', '&:hover': {
                                        backgroundColor: '#D2E0E3',
                                        color: '#1F6373',
                                        border: '1px solid white',
                                    },
                                }} />} sx={{
                                    width: {lg:'40px',md:'40px',sm:'40px',xs:'30px'}, minWidth:{lg:'40px',md:'40px',sm:'40px',xs:'30px'}, height: {lg:'40px',md:'40px',sm:'40px',xs:'30px'}, borderRadius: '50%', backgroundColor: '#1F6373', "& .MuiButton-endIcon": { marginLeft: '0px' }, '&:hover': {
                                        backgroundColor: '#D2E0E3',
                                        color: '#1F6373',
                                        border: '1px solid #1F6373',
                                    },
                                }}></Button>
                            </Paper>
                </Box>
                <Box sx={{ width: '100%', height: {lg:'796px',md:'796px',sm:'796px',xs:'240px'},overflowY:{xs:'auto'}, display: 'flex', flexDirection: 'column', backgroundColor: '#F8F8F8',paddingLeft:'12px',paddingRight:'12px',paddingTop:'8px',paddingBottom:'10px' }}>
                    <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#313131', fontFamily: 'Poppins' }}>Selected Agents</Typography>
                    <div style={{display:'flex',flexDirection:'column',gap:'6px',marginTop:'8px'}}>
                     <List sx={{width:'100%'}}>
        <ListItemButton onClick={handleExpandClick} sx={{backgroundColor:'white',borderRadius:'8px',height:{lg:'70px',xs:'44px'},marginBottom:'6px'}}>
          <ListItemAvatar>
        <Avatar sx={{ color: '#313131', backgroundColor: '#DCDADA', width: {lg:'40px',xs:'34px'}, height: {lg:'40px',xs:'34px'} }}>A</Avatar>
        </ListItemAvatar>
        <ListItemText>
        <Typography sx={{ color: '#313131', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500,marginRight:{lg:'132px',sm:'0px',xs:'0px'}}}>Agent #6717</Typography>
        {/* </div> */}
        </ListItemText>
        {expanded ? <ExpandLess /> : <ExpandMore />}
       </ListItemButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit />
      <ListItemButton onClick={handleExpandClick1} sx={{backgroundColor:'white',borderRadius:'8px',height:{lg:'70px',xs:'44px'},marginTop:'6px'}}>
          <ListItemAvatar>
        <Avatar sx={{ color: '#313131', backgroundColor: '#DCDADA', width: {lg:'40px',xs:'34px'}, height: {lg:'40px',xs:'34px'} }}>A</Avatar>
        </ListItemAvatar>
        <ListItemText>
        <Typography sx={{ color: '#313131', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500,marginRight:{lg:'132px',sm:'0px',xs:'0px'}}}>Agent #6717</Typography>
        </ListItemText>
        {expanded1 ? <ExpandLess /> : <ExpandMore />}
         </ListItemButton>
      <Collapse in={expanded1} timeout="auto" unmountOnExit />
       <ListItemButton onClick={handleExpandClick2} sx={{backgroundColor:'white',borderRadius:'8px',height:{lg:'70px',xs:'44px'},marginTop:'6px'}}>
          <ListItemAvatar>
        <Avatar sx={{ color: '#313131', backgroundColor: '#DCDADA', width: {lg:'40px',xs:'34px'}, height: {lg:'40px',xs:'34px'} }}>A</Avatar>
        </ListItemAvatar>
        <ListItemText>
        <Typography sx={{ color: '#313131', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500,marginRight:{lg:'132px',sm:'0px',xs:'0px'}}}>Agent #6717</Typography>
        </ListItemText>
        {expanded2 ? <ExpandLess /> : <ExpandMore />}
       </ListItemButton>
      <Collapse in={expanded2} timeout="auto" unmountOnExit>
      <Box sx={{width:'100%',height:'420px',backgroundColor:'white',paddingLeft:'42px',paddingRight:'44px',paddingTop:'14px'}}>
        <Typography sx={{ textAlign:'center',color: '#313131', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500}}>
        Sent quotation for mortgage4
        </Typography>
        <div style={{display:'flex',flexDirection:'row',gap:'20px'}}>
           <div style={{marginTop:'20px'}}><Leftbox /></div>
              <Box sx={{
                              width: { lg: '80px', md: '158px', sm: '158px', xs: '104px' },height:'248px', display: 'flex', flexDirection: 'column',
                              backgroundColor: 'white',
                              gap: '26px', marginTop: '22px', borderRadius: '8px',
                              position: 'relative'}}>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>25000</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>25000</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Variable</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>2 Months</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>4.5</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>25 years</Typography>
                              </Box> 
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Monthly</Typography>
                              </Box>
                            </Box>
                        </div>
                        </Box>    
     </Collapse>             
        <ListItemButton onClick={handleExpandClick3} sx={{backgroundColor:'white',borderRadius:'8px',height:{lg:'70px',xs:'44px'},marginTop:'6px'}}>
          <ListItemAvatar>
        <Avatar sx={{ color: '#313131', backgroundColor: '#DCDADA', width: {lg:'40px',xs:'34px'}, height: {lg:'40px',xs:'34px'} }}>A</Avatar>
        </ListItemAvatar>
        <ListItemText>
        <Typography sx={{ color: '#313131', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500,marginRight:{lg:'132px',sm:'0px',xs:'0px'}}}>Agent #6717</Typography>
        </ListItemText>
        {expanded3 ? <ExpandLess /> : <ExpandMore />}
         </ListItemButton>
      <Collapse in={expanded3} timeout="auto" unmountOnExit />
       </List>
       </div>
     </Box>
    </Box>
    </Box>
    </div>
  )
}
export default DiscussionroomMob;