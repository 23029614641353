import { Box } from '@mui/material'
import React from 'react'
import MortgageUser from '../components/MortgageUser'
import MortgageUserMobile from '../components/MortgageUserMobile'


function MortgageUserDetails() {
  return (
    <div>
      <Box sx={{display:{md:'none', xs:'block', sm:'none'}}}>

        <MortgageUserMobile>
        </MortgageUserMobile>

      </Box>

      <Box sx={{display:{md:'block', xs:'none', sm:'block'}}}>

       <MortgageUser>        
       </MortgageUser>
      </Box>
    </div>
  )
}

export default MortgageUserDetails
