import React, { useState } from 'react'
import { Avatar, Box, Button, Collapse, InputAdornment, InputBase, List, ListItemButton, ListItemText, OutlinedInput, Paper, Typography } from '@mui/material'
import Line1 from './images/line.png';
import Line2 from './images/line1.png';
import Robert from './images/Robert.png';
import SendIcon from '@mui/icons-material/Send';
//import Header2 from './header2';
import Leftbox from './leftbox';
import HelpIcon from '@mui/icons-material/Help';
import Header2Mod from './header2-modified';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
function DiscussionMob() {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <div style={{ overflow: 'hidden' }}>
      <Header2Mod />
      <Box sx={{ height: '970px', overflowY: 'auto', width: '100%', paddingLeft:{lg:'68px',xs:'10px'}, paddingRight:{lg:'68px',xs:'10px'}, paddingTop:{lg:'16px',xs:'5px'}, paddingBottom: '42px' }}>
        <Typography sx={{ fontWeight: 500, fontSize: {lg:'20px',xs:'14px'}, color: '#313131', fontFamily: 'Poppins' }}>Discussion room</Typography>
        <Box sx={{width: '100%', height:{lg:'60px',xs:'44px'}, marginTop: '4px', backgroundColor: '#1F6373', borderRadius: '8px 8px 0px 0px'}}></Box>
        <Box sx={{ width: '100%',  display: 'flex', flexDirection:{lg:'row',md:'row',sm:'row',xs:'column-reverse'}, marginTop: {lg:'12px',xs:0}, gap: {lg:'10px',xs:0}, alignItems: 'center' }}>
          <Box sx={{ width: {lg:'834px',sm:'60%',xs:'94%'}, height: {lg:'796px',md:'796px',sm:'796px',xs:'68%'}, display: 'flex', flexDirection: 'column', backgroundColor: '#F8F8F8', paddingLeft: '12px', paddingRight: '12px',paddingBottom:'24px' }}>
          <Box sx={{width:{xs:'100%'},display:{lg:'flex',md:'flex',sm:'flex',xs:'none'},flexDirection:'row',alignItems:'center',alignContent:'center',justifyContent:'center',gap:'12px'}}>
              <img src={Line1} width={'50%'}/>
              <Typography sx={{fontWeight: 400, fontSize: '16px', color: '#939393', fontFamily: 'Poppins'}}>21/02/2013</Typography>
              <img src={Line2} width={'50%'}/>
          </Box>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '12px' }}>
              <Typography sx={{ color: '#1F6373', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500, marginLeft: '28px' }}>User #6622</Typography>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '8px' }}>
                <Avatar sx={{ color: '#000000', backgroundColor: '#D9D9D9', width: '20px', height: '20px', fontSize: '12px' }}>U</Avatar>
                <div style={{ height: '38px', width: '32px', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#DCDADA', borderRadius: '0px 8px 8px 8px', display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Hi</Typography>
                </div>
              </div>
              <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '4px', fontWeight: 400, marginRight: '28px' }}>8:17 PM</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '6px' }}>
              <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                <img src={Robert} />
              </Box>
              <div style={{ height: '38px', width: '32px', display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px' }}>
                <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Hi</Typography>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '8px', marginRight: '26px' }}>
              <Box sx={{ height: {lg:'54px',xs:'72px'}, width: {lg:'376px',md:'376px',xs:'252px'}, display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px' }}>
                <Typography sx={{ width:{lg:'356px',md:'356px',xs:'232px'},color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                  Hello! Thanks for reaching out us at Rate wave.Did you find what you were looking for?
                </Typography>
              </Box>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '4px', marginRight: '28px' }}>
              <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400 }}>8:17 PM</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '8px' }}>
                <Avatar sx={{ color: '#000000', backgroundColor: '#D9D9D9', width: '20px', height: '20px', fontSize: '12px' }}>U</Avatar>
                <Box sx={{ height: {lg:'54px',xs:'72px'}, width: {lg:'358px',md:'358px',xs:'302px'}, alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#DCDADA', borderRadius: '0px 8px 8px 8px', display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ width: {lg:'358px',md:'358px',xs:'234px'},color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                    Yeah, i am interested with your quotation of mortgage 4, can you explain about it?
                  </Typography>
                </Box>
              </div>
              <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '4px', fontWeight: 400, marginRight: '28px' }}>8:17 PM</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '4px', marginTop: '8px' }}>
              <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                <img src={Robert} />
              </Box>
              <Box sx={{ height: '72px', width: {lg:'408px',md:'408px',xs:'302px'}, paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px',paddingTop:'10px',flexDirection: 'column' }}>
                <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400, textAlign: 'left' }}>Yeah, sure!
                {/* </Typography>
                <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400, textAlign: 'left'}}> */}
                  our quotation for mortgage 4 will help you get a comparatively
                {/* </Typography>
                <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400, textAlign: 'left', width: '80%' }}> */}
                  law interest rate
                </Typography>
              </Box>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400, marginRight: '28px', marginTop: '4px' }}>8:17 PM</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '12px' }}>
              <Avatar sx={{ color: '#000000', backgroundColor: '#D9D9D9', width: '20px', height: '20px', fontSize: '12px' }}>U</Avatar>
              <Box sx={{ height: {lg:'74px',xs:'82px'}, width: {lg:'324px',md:'324px',xs:'80%'}, display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#DCDADA', borderRadius: '0px 8px 8px 8px' }}>
                <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                  okay, i will catch you later, now i am looking for a quotation with less amortization period, thanks for your corporation
                </Typography>
              </Box>
            </div>
            <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '4px', fontWeight: 400 }}>8:17 PM</Typography>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '4px', marginTop: '8px' }}>
              <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                <img src={Robert} />
              </Box>
              <div style={{ height: '38px', width: '54px', paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px' }}>
                <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                  okay
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400, marginRight: '28px', marginTop: '4px' }}>8:17 PM</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
              <Typography sx={{ color: '#EB5648', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500, marginLeft: '28px' }}>Agent #6622</Typography>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '8px' }}>
                <Avatar sx={{ color: '#EB5648', backgroundColor: '#D9D9D9', width: '20px', height: '20px', fontSize: '12px' }}>A</Avatar>
                <Box sx={{ height: {lg:'54px',xs:'72px'}, width: '350px', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', backgroundColor: 'white', borderRadius: '0px 8px 8px 8px', display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                    Hi..i have a better quote with less amortization period, Do you want to know more about it?
                  </Typography>
                </Box>
              </div>
              <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '4px', fontWeight: 400, marginRight: '28px' }}>8:17 PM</Typography>
            </div>
            <Paper
              elevation={0}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: {lg:'794px',md:'794px',xs:'90%'}, height: '50px', borderRadius: '0.25rem', paddingRight: '12px', paddingLeft: '0.5rem', marginLeft: '8px', marginTop: '8px' }}
            >
              <InputBase
                placeholder="Type here"
                inputProps={{ 'aria-label': 'search' }}
              />
              <Button endIcon={<SendIcon sx={{
                color: 'white', width: '18px', height: '18px', '&:hover': {
                  backgroundColor: '#D2E0E3',
                  color: '#1F6373',
                  border: '1px solid white',
                },
              }} />} sx={{
                width: {lg:'40px',md:'40px',sm:'34px',xs:'30px'}, minWidth:{lg:'40px',md:'40px',sm:'34px',xs:'30px'}, height: {lg:'40px',md:'40px',sm:'34px',xs:'30px'}, borderRadius: '50%', backgroundColor: '#1F6373', "& .MuiButton-endIcon": { marginLeft: '0px' }, '&:hover': {
                  backgroundColor: '#D2E0E3',
                  color: '#1F6373',
                  border: '1px solid #1F6373',
                },
              }}></Button>
            </Paper>
          </Box>
          <Box sx={{ width: '100%', height: {lg:'796px',md:'796px',sm:'796px',xs:'180px'},overflowY:{xs:'auto'}, display: 'flex', flexDirection: 'column', backgroundColor:{lg:'#F8F8F8',md:'#F8F8F8',sm:'#F8F8F8',xs:'white'},paddingLeft:'12px',paddingRight:'12px',paddingTop:'8px',paddingBottom:'10px' }}>
            <Typography sx={{ display:{lg:'block',xs:'none'},color: '#313131', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500 }}>
              User profile
            </Typography>
            <Box sx={{ width: '100%', height: {lg:'72px',xs:'20px'}, display: 'flex', flexDirection: 'raw', alignItems: 'center', backgroundColor: 'white', gap: '14px', marginTop: {lg:'8px',md:'8px',sm:'8px',xs:0}, paddingLeft: {lg:'24px',sm:'16px'}, borderRadius: '8px 8px 0px 0px',paddingTop:{lg:'16px',sm:'22px',xs:'8px'},paddingBottom:'12px' }}>
              <Avatar sx={{ color: '#313131', backgroundColor: '#939393', width: {lg:'40px',md:'40px',sm:'30px',xs:'20px'}, height: {lg:'40px',md:'40px',sm:'30px',xs:'20px'}, fontSize: {lg:'20px',sm:'16px',xs:'12px'} }}>U</Avatar>
              <Typography sx={{ color: '#313131', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500 }}>
                User #2118
              </Typography>
            </Box>
            <Box sx={{ width: '100%',height:'408px', display: {lg:'flex',md:'flex',sm:'flex',xs:'none'}, flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', gap: '16px',borderRadius: '0px 0px 8px 8px', paddingTop: '28px',paddingBottom:'26px' }}>
              <Typography sx={{ width: '366px', color: '#313131', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500,textAlign:'left',paddingLeft:'48px' }}>Selected mortgages</Typography>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
               <Box sx={{marginTop:'38px'}}>
                <Leftbox />
                </Box> 
                <Box sx={{
                  width: { lg: '80px', md: '158px', sm: '158px', xs: '104px' },display: 'flex', flexDirection: 'column',
                  backgroundColor: 'white',marginTop:'0px',
                  gap: '8px',  borderRadius: '8px',
                  position: 'relative'}}>
                <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography sx={{  fontSize: '14px', fontWeight: 400, color: '#626262',fontFamily: 'Poppins' }}>Mortgage 1</Typography>
                  </Box>
                  <Box sx={{width:'178px',display:'flex',flexDirection:'column',gap:'16px',alignItems:'center'}}>
                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>25000</Typography>
                  </Box>
                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>25000</Typography>
                  </Box>
                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Variable</Typography>
                  </Box>
                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>2 Months</Typography>
                  </Box>
                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>4.5</Typography>
                  </Box>
                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>25 years</Typography>
                  </Box>
                  <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Monthly</Typography>
                  </Box>
                  </Box>
                </Box>
               </div>
             </Box>
            <Box sx={{width:'100%', display:'flex',overflow:{xs:'auto'},flexDirection:{lg:'column',md:'column',sm:'column',xs:'row'}, gap:'8px',marginTop:'16px'}}>
              <Box sx={{display:'flex',flexDirection:'row',gap:'6px'}}>
              <Box sx={{width:'180px',height:{lg:'72px',md:'72px',sm:'72px',xs:'68px'},backgroundColor:{lg:'white',sm:'white',xs:'#F9F9F9'},display:'flex',flexDirection:'column', alignItems:'center',alignContent:'center',justifyContent:'center'}}>
              <Typography sx={{textAlign:'center', fontWeight: 400, fontSize: '12px', color: '#626262', fontFamily: 'Poppins'}}>Annual house hold income</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#313131', fontFamily: 'Poppins'}}>$2,50,000</Typography>
              </Box>
              <Box sx={{width:'180px',height:{lg:'72px',md:'72px',sm:'72px',xs:'68px'},backgroundColor:{lg:'white',sm:'white',xs:'#F9F9F9'},display:'flex',flexDirection:'column', alignItems:'center',alignContent:'center',justifyContent:'center'}}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#626262', fontFamily: 'Poppins'}}Monthly mortgages>Monthly mortgages</Typography>
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#313131', fontFamily: 'Poppins'}}>$2,50,000</Typography> 
              </Box>
              </Box>
              <Box sx={{display:'flex',flexDirection:'row',gap:'6px'}}>
              <Box sx={{width:'180px',height:{lg:'72px',md:'72px',sm:'72px',xs:'68px'},backgroundColor:{lg:'white',sm:'white',xs:'#F9F9F9'},display:'flex',flexDirection:'column', alignItems:'center',alignContent:'center',justifyContent:'center'}}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#626262', fontFamily: 'Poppins'}}>Car loan payment</Typography>
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#313131', fontFamily: 'Poppins'}}>$2,50,000</Typography>
              </Box>
              <Box sx={{width:'180px',height:{lg:'72px',md:'72px',sm:'72px',xs:'68px'},backgroundColor:{lg:'white',sm:'white',xs:'#F9F9F9'},display:'flex',flexDirection:'column', alignItems:'center',alignContent:'center',justifyContent:'center'}}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#626262', fontFamily: 'Poppins'}}>Monthly property tax</Typography>
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#313131', fontFamily: 'Poppins'}}>$2,50,000</Typography>
              </Box>
              </Box>
              <Box sx={{display:'flex',flexDirection:'row',gap:'6px'}}>
              <Box sx={{width:'180px',height:{lg:'72px',md:'72px',sm:'72px',xs:'68px'},backgroundColor:{lg:'white',sm:'white',xs:'#F9F9F9'},display:'flex',flexDirection:'column', alignItems:'center',alignContent:'center',justifyContent:'center'}}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#626262', fontFamily: 'Poppins'}}>Line of credit payment</Typography>
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#313131', fontFamily: 'Poppins'}}>$2,50,000</Typography>
              </Box>
              <Box sx={{width:'180px',height:{lg:'72px',md:'72px',sm:'72px',xs:'68px'},backgroundColor:{lg:'white',sm:'white',xs:'#F9F9F9'},display:'flex',flexDirection:'column', alignItems:'center',alignContent:'center',justifyContent:'center'}}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#626262', fontFamily: 'Poppins'}}>Monthly condo fees</Typography>
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#313131', fontFamily: 'Poppins'}}>$2,50,000</Typography>
            </Box>
              </Box>
            </Box>
            <Box sx={{display:{lg:'none',md:'none',sm:'none',xs:'flex'},backgroundColor:'#F9F9F9',marginTop:'8px',alignItems:'center'}}>
            <List sx={{width:'100%',height:'0px',zIndex:10}}>
        <ListItemButton onClick={handleExpandClick} sx={{backgroundColor:'#F9F9F9',borderRadius:'8px',height:{lg:'70px',xs:'44px'}}}>
        <ListItemText>
        <Typography sx={{ color: '#313131', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500,marginRight:{lg:'132px',sm:'0px',xs:'0px'}}}>Selected mortgages</Typography>
        {/* </div> */}
        </ListItemText>
        {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expanded} timeout="auto" unmountOnExit >
        <div style={{display:'flex',flexDirection:'row',gap:'20px',backgroundColor:'#F9F9F9',alignContent:'center',justifyContent:'center',zIndex:10}}>
           {/* <div style={{width:'178px',marginTop:'20px'}}><Leftbox /></div> */}
           <Box sx={{  display: 'flex', flexDirection: 'column', gap: '16px',marginTop:'22px' }}>
            <Box sx={{ width: { lg: '168px', md: 168, sm: 120, xs: 120 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Initial payment</Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
                  <Box sx={{ width: { lg: '168px', md: 168, sm: 120, xs: 120 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Monthly payment </Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
                  <Box sx={{ width: { lg: '168px', md: 168, sm: 120, xs: 120 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Type</Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
                  <Box sx={{ width: { lg: '168px', md: 168, sm: 120, xs: 120 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Term</Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
                  <Box sx={{ width: { lg: '168px', md: 168, sm: 120, xs: 120 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Interest rate (%) </Typography>
                    <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Interest (%) </Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
                  <Box sx={{ width: { lg: '168px', md: 168, sm: 120, xs: 120 }, height: '28px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Amortization period</Typography>
                    <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Amortization</Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
                  <Box sx={{ width: { lg: '168px', md: 168, sm: 120, xs: 120 }, height: '28px', alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                    <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment frequency</Typography>
                    <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment</Typography>
                    <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                  </Box>
    </Box>
              <Box sx={{
                              width: { lg: '80px', md: '158px', sm: '158px', xs: '104px' }, display: 'flex', flexDirection: 'column',
                              backgroundColor: '#F9F9F9',
                              gap: '16px', marginTop: '22px', borderRadius: '8px',
                              position: 'relative'}}>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>25000</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>25000</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Variable</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>2 Months</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>4.5</Typography>
                              </Box>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>25 years</Typography>
                              </Box> 
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Monthly</Typography>
                              </Box>
                            </Box>
                        </div>
                           
     </Collapse>             
        </List>
        </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
export default DiscussionMob