import { Box, Button, Checkbox, FormControlLabel, Grid, OutlinedInput, Typography, Collapse, } from "@mui/material";
import axios from "axios";
import { API_URL } from "./constants";
import React,  { useState, useEffect } from "react";
import '../App.css';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';
import Header from './HeaderLanding'
import HeaderMobile from './HeaderLandingMobile';
import Footer from './Footer';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Switch from '@mui/material/Switch';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

function AdminPage() {
    // const [builders, setBuilders] = useState([]);
    // const [approverNames, setApproverNames] = useState([]);
    // const [approverComments, setApproverComments] = useState([]);
    // const [requestStatuses, setRequestStatuses] = useState([]);
    const [errors, setErrors] = useState({});
    let builderEmail;   
    const adminname= localStorage.getItem('admin')
    const [submitClicked, setSubmitClicked] = useState(false);
  const [builders, setBuilders] = useState([]);
  const [approverNames, setApproverNames] = useState({});
  const [approverStatuses, setApproverStatuses] = useState({});
  const [requestStatuses, setRequestStatuses] = useState({});
  const [approverComments, setApproverComments] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
    async function fetchData() {
        try {
            const response = await axios.get(`${API_URL}/admin-builder-details`); 
            setBuilders(response.data);          
            const responseData = response.data;

            const initialApproverNames = {};
            const initialRequestStatuses = {};
            const initialApproverStatuses = {};
            const initialApproverComments = {};
        
            responseData.forEach((builder) => {
              initialApproverNames[`${builder.builder_id}-${builder.listing_agent_id}`] = builder.approver || '';
              initialRequestStatuses[`${builder.builder_id}-${builder.listing_agent_id}`] = builder.profile_status === 'ENABLED';
              initialApproverStatuses[`${builder.builder_id}-${builder.listing_agent_id}`] = builder.request_status;
              initialApproverComments[`${builder.builder_id}-${builder.listing_agent_id}`] = builder.approval_comment || '';
            });
        
            setApproverNames(initialApproverNames);
            setRequestStatuses(initialRequestStatuses);
            setApproverComments(initialApproverComments);
        
            // Set selectedOption after fetching data
            setSelectedOption(initialApproverStatuses);
            console.log("builderEmail",builderEmail)
           
        } 
        catch (error) {
            console.error('Error:', error.message);
        }
      }
    
      useEffect(() => {
        fetchData();
      }, []);
    

      
      const getStatus = (index) => {
        if (requestStatuses[index]) {
            return 'ENABLED'; 
        } else {  
           return 'DISABLED';
        }
      };
      const [expandedRow, setExpandedRow] = useState(null);

      const handleRowClick = (index) => {
        if (index === expandedRow) {
          setExpandedRow(null);
        } else {
          setExpandedRow(index);
        }
      };


    // const handlechangeApproverName = (event, index) => {
    //     setApproverNames((prevNames) => {
    //       const updatedNames = [...prevNames];
    //       updatedNames[index] = event.target.value;
    //       return updatedNames;
    //     });
    //   }; 
      
  //     const handlechangeApproverComment = (event, index) => {
  //       setApproverComments((prevComments) => {
  //         const updatedComments = [...prevComments];
  //         updatedComments[index] = event.target.value;
  //         return updatedComments;
  //       });
  //     };  

  //   const handleChangeProfileStatus = (index) => {
  //     return () => {
  //         console.log('Toggling index:', index);
  //         setRequestStatuses((prevStatuses) => {
  //             const updatedStatuses = [...prevStatuses];
  //             updatedStatuses[index] = !updatedStatuses[index];
  //             return updatedStatuses;
  //         });
  //     };
     
  // };
  
  const handlechangeApproverName = (event, builderId, listingAgentId) => {
    const updatedApproverNames = { ...approverNames };
    const key = `${builderId}-${listingAgentId}`;
    updatedApproverNames[key] = event.target.value;
    setApproverNames(updatedApproverNames);
    const errorsCopy = { ...errors };
    errorsCopy[key] = { ...errorsCopy[key], approverName: '' };
    setErrors(errorsCopy);
  };

  const handlechangeApproverComment = (event, builderId, listingAgentId) => {
    const updatedApproverComments = { ...approverComments };
    const key = `${builderId}-${listingAgentId}`;
    updatedApproverComments[key] = event.target.value;
    setApproverComments(updatedApproverComments);
    const errorsCopy = { ...errors };
    errorsCopy[key] = { ...errorsCopy[key], approverComment: '' };
    setErrors(errorsCopy);
  };

  const handleChangeProfileStatus = (builderId, listingAgentId) => () => {
    const key = `${builderId}-${listingAgentId}`;
    if (selectedOption[key] === 'REJECTED') {
      setRequestStatuses((prevStatuses) => {
        const updatedStatuses = { ...prevStatuses };
        const key = `${builderId}-${listingAgentId}`;
        updatedStatuses[key] = updatedStatuses[key];
        return updatedStatuses;
      });
  }
  else{
   
    setRequestStatuses((prevStatuses) => {
      const updatedStatuses = { ...prevStatuses };
      const key = `${builderId}-${listingAgentId}`;
      updatedStatuses[key] = !updatedStatuses[key];
      return updatedStatuses;
    });
  }
  };



  const handleApproval = (builderId, listingAgentId, approvalType) => () => {
   
    setApproverStatuses((prevStatuses) => {
      const updatedStatuses1 = { ...prevStatuses };
      const key = `${builderId}-${listingAgentId}`;
      if (approvalType === 'APPROVED') {
        updatedStatuses1[key] = 'APPROVED';  
        setRequestStatuses((prevStatuses) => {
          const updatedStatuses = { ...prevStatuses };
          const key = `${builderId}-${listingAgentId}`;
          updatedStatuses[key] = !updatedStatuses[key];
          return updatedStatuses;
        });
       
      } else if (approvalType === 'REJECTED') {      
     
        updatedStatuses1[key] = 'REJECTED';
      }
      return updatedStatuses1;
    }); 
    const key = `${builderId}-${listingAgentId}`;
    setSelectedOption((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [key]: approvalType,
    }));
    setSubmitClicked(true)
  };
  
  

  
    
      
  useEffect(() => {
    console.log('Updated Statuses:', requestStatuses);
}, [requestStatuses]);

      
 
      
    const handleSubmitButtonClick = async(builder_Id, listingAgent_Id, email_id) => {
      builderEmail = email_id;
      const builderid = builder_Id;
      const listingagentid = listingAgent_Id;
      const key = `${builderid}-${listingagentid}`;
      const errorsCopy = { ...errors };

     // const approverNameSave = approverNames[key];
     const approverNameSave = adminname
      const approverCommentSave = approverComments[key];
      const profileStatusSave = getStatus(key); // Assuming getStatus is a function that takes a unique key
      const requestStatusSave = selectedOption[key];
      let isValid = true;
      console.log("builderEmail",builderEmail)
                console.log("listingagentid",listingagentid)
                console.log("builderid",builderid)
                console.log("name",approverNames[key] )
                console.log("comment",approverComments[key])
                console.log("profilestatus",profileStatusSave)
                console.log("approverStatuses",selectedOption[key])
               
                // if (!approverNames[key]) {
                //   errorsCopy[key] = { ...errorsCopy[key], approverName: 'Please enter your name' };
                //   setErrors(errorsCopy);
                //   isValid = false;
                // }
                if (!approverComments[key]) {
                  errorsCopy[key] = { ...errorsCopy[key], approverComment: 'Please enter your comment' };
                  setErrors(errorsCopy);
                  isValid = false;
                }
            
               
                 else {
                  if (isValid) {
            try {

                const response = await axios.post(`${API_URL}/admin-personal-details`, {          
                  listingagentid: listingagentid,
                  builderid: builderid,
                });
                 // const listingagentid = response.data[0].listing_agent_id
                 // const builderid = response.data[0].builder_id
                  const builderlocation= response.data[0].builder_location
                  const builderName =  response.data[0].builder_name
                  const sponsorname =  response.data[0].sponsor_name
                  const sponsoremail= response.data[0].sponsor_email
                  const sponsorphonenumber= response.data[0].sponsor_phone_number                 
                  const registerdate = response.data[0].register_date
                  const requeststatus = response.data[0].request_status
                  const approver = response.data[0].approver
                  const approvaltime = response.data[0].approval_time
                  const approvercomment = response.data[0].approval_comment               
                  const profilestatus =  response.data[0].profile_status
                  const login_id=  response.data[0].login_id
                  console.log("details",response.data)
                  const response1 = await axios.post(`${API_URL}/insert-builderdetails-audit`, {                   
                    listingagentid:listingagentid,
                    builderid: builderid,                   
                    builderName: builderName, 
                    builderlocation:builderlocation,
                    sponsorname: sponsorname,      
                    sponsoremail: sponsoremail,
                    sponsorphonenumber: sponsorphonenumber,                  
                    registerdate:registerdate,               
                    requeststatus: requeststatus,
                    approver: approver,
                    approvaltime: approvaltime,
                    approvercomment: approvercomment,                   
                    profilestatus:profilestatus,
                    login_id: login_id,
                });
        
                console.log('Insert response:', response1.data); 
        
                const response3 = await axios.post(`${API_URL}/insert-approver-details`, {
                listingagentid: listingagentid,
                builderid: builderid,
                approverName: approverNameSave,
                approverComment: approverCommentSave,    
                requestStatus: requestStatusSave,  
                profileStatus:profileStatusSave,
            });
            console.log("builderEmail",builderEmail)
            if(profileStatusSave === 'ENABLED'){
              const email = builderEmail;
              console.log("enable working")
              const response = await axios.post(`${API_URL}/admin-enabled`, {        
                email: email,
               
            });
            }
            fetchData()
         setExpandedRow(null);
         setSubmitClicked(false)
          } 
        
            catch (error) {
                console.error('Error:', error.message);
            }
          
         }
           
           
     } };
       



    return (
        <Grid container sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
           
        }}>
             <Box sx={{
                height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'flex', sm: 'flex', xs: 'none' }
            }}>
                <Header ></Header>
            </Box>
            <Box sx={{
                height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'none', sm: 'none', xs: 'flex' }
            }}>
                <HeaderMobile ></HeaderMobile>
            </Box>
            <Typography sx={{
                        fontFamily: 'poppins', fontSize: { md: '38px', sm: '32px', xs: '16px' },paddingTop:'50px',
                       lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight:'auto',marginBottom:'25px',paddingLeft:'30px'
                    }}>
                       List of builders
                    </Typography>
                 
     
                    <TableContainer component={Paper}>
      <Table sx={{ minWidth: '100%' }} aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{ backgroundColor: 'White' }}>
            <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Builder name</TableCell>
            <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>User name</TableCell>
            <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Location</TableCell>
            <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Phone number</TableCell>
            <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Email id</TableCell>
            <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Date</TableCell>
            <TableCell sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131', textAlign: 'center' }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {builders.map((row, index) => (
            <React.Fragment key={index}>
                
              <TableRow 
                key={index}
                sx={{
                    backgroundColor: '#F3F3F3',
            
                   
                  }}
              >
                
                <TableCell
                  sx={{
                    fontFamily: 'poppins',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: '400',
                    color: '#313131',
                    textAlign: 'center',
                    padding: { md: '15px', sm: '10px' },
                  }}
                >
                  {row.builder_name}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'poppins',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: '400',
                    color: '#313131',
                    textAlign: 'center',
                    padding: { md: '15px', sm: '10px' },
                  }}
                >
                  {row.user_name}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'poppins',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: '400',
                    color: '#313131',
                    textAlign: 'center',
                    padding: { md: '15px', sm: '10px' },
                  }}
                >
                  {row.location}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'poppins',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: '400',
                    color: '#313131',
                    textAlign: 'center',
                    padding: { md: '15px', sm: '10px' },
                  }}
                >
                  {row.phone_number}
                </TableCell>               
                <TableCell
                  sx={{
                    fontFamily: 'poppins',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: '400',
                    color: '#313131',
                    textAlign: 'center',
                    padding: { md: '15px', sm: '10px' },
                  }}
                >
                  {row.email_id}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'poppins',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: '400',
                    color: '#313131',
                    textAlign: 'center',
                    padding: { md: '15px', sm: '10px' },
                  }}
                >
                  {row.register_date}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'poppins',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: '400',
                    color: '#313131',
                    textAlign: 'center',
                    padding: { md: '15px', sm: '10px' },
                  }}
                >
                  {row.request_status}
                </TableCell>
                <TableCell>
                <Button variant="outlined" sx={{textTransform:'none',border:'1px solid #626262',color:'#626262',background:'#fff',
                borderRadius:'8px',width:'120px'}}
  aria-label="expand row"
  size="small"
  onClick={() => handleRowClick(index)}
  endIcon={
    expandedRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
  }
>
  {expandedRow === index ? 'Less details' : 'More details'}
</Button>
                </TableCell>
              </TableRow>
              <TableRow sx={{width:'100%',height:'20px'}}></TableRow>
              {expandedRow === index && (

              


                 <TableRow>
                 <TableCell colSpan={7}>
                <Box sx={{display:'flex', width:'100%', flexDirection:'column', border:'1px solid #DCDADA',marginBottom:'20px',
            gap:'10px',padding:'20px'}}>
             <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '20px',paddingTop:'20px' }}>
              <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '20px' }}>
<Typography sx={{
  fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131',
}}>
  Builder Details
</Typography>
</Box>
<Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '25px' }}>
<Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
  <Typography sx={{
   fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',width:'15%', 
  }}>
    Builder name :
  </Typography>
  <Typography sx={{
    fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',
  }}>   {row.builder_name}
  </Typography>
</Box>
<Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
  <Typography sx={{
    fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',width:'15%', 
  }}>
    Builder location : 
  </Typography>
  <Typography sx={{
    fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',
  }}>  {row.builder_location}
  </Typography>
</Box>
<Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
  <Typography sx={{
   fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131', width:'15%', 
  }}>
    Sponsor name :
  </Typography>
  <Typography sx={{
    fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',
  }}>  {row.sponsor_name}
  </Typography>
</Box>
<Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
  <Typography sx={{
   fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',width:'15%', 
  }}>
    Sponsor email :
  </Typography>
  <Typography sx={{
    fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',
  }}>  {row.sponsor_email}
  </Typography>
</Box>
<Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
  <Typography sx={{
   fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',width:'15%', 
  }}>
    Sponsor number : 
  </Typography>
  <Typography sx={{
    fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',
  }}>  {row.sponsor_phone_number}
  </Typography>
</Box>
</Box>
             </Box>
                    
                        <Box  sx={{display:'flex', width:'100%', flexDirection:'column', gap:'20px',paddingTop:'20px'}}>
                        <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '600', color: '#313131',}}>
                        Approval Details 
                      </Typography>
                        </Box>                  
                        <Box  sx={{display:'flex', width:'100%', flexDirection:'column', gap:'10px'}}>
                        <Box sx={{display:'flex', width:'100%', flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <Typography sx={{width:'15%', fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',}}>
                        Approver name<span style={{ color: 'red' }}>*</span> :
                      </Typography>
                      <TextField onChange={(event) => handlechangeApproverName(event, row.builder_id, row.listing_agent_id)}
                   //  value={approverNames[`${row.builder_id}-${row.listing_agent_id}`] || ''}
                   value={adminname}
                       placeholder='John  abraham'  variant="outlined"   error={errors[`${row.builder_id}-${row.listing_agent_id}`]?.approverName !== undefined}
                       helperText={errors[`${row.builder_id}-${row.listing_agent_id}`]?.approverName}     
                       sx={{
                        width: "85%",
                        borderRadius: '8px',
                        backgroundColor: '#F9F9F9',
                        '& .MuiOutlinedInput-root': {
                          border: '1px solid #CFC9C9',
                          '&.Mui-error': {
                            border: 'none',
                            boxShadow: 'none',
                            '& fieldset': {
                              border: 'none',
                            },
                          },
                        },
                      }}
                      FormHelperTextProps={{
                        sx: {
                          fontFamily: "poppins",
                          width: '100%',
                          fontSize: "14px",
                          lineHeight: "18px",
                          fontWeight: "500",
                        },
                      }}
            InputProps={{sx: { height: '44px', color: " #626262",fontFamily: "poppins",width:'100%',
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500", pointerEvents: 'none',  },
                        }} />
                       
                      </Box>
                      <Box sx={{display:'flex', width:'100%', flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <Typography sx={{width:'15%', fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',}}>
                        Approval Status  :
                      </Typography>
                      <div style={{ width: '85%', paddingTop: '10px' }}>
                      <div key={[`${row.builder_id}-${row.listing_agent_id}`]}>
                      {selectedOption[`${row.builder_id}-${row.listing_agent_id}`] === 'APPROVED' && (
  <Button
  sx={{
    color: '#fff',
    background: '#76DE24',
    borderRadius: '8px',
    '&:hover': { bgcolor: "#76DE24", color: '#fff' },
  }}
  variant="contained"
  onClick={handleApproval(row.builder_id, row.listing_agent_id, 'APPROVED')}
  style={{ marginRight: '8px' }}
>
  <DoneIcon />
  Approved
</Button>
)}

{selectedOption[`${row.builder_id}-${row.listing_agent_id}`] === 'REJECTED' && (
  <Button
  sx={{
    color: '#fff',
    background: '#CE3932',
    borderRadius: '8px',
    '&:hover': { bgcolor: "#CE3932", color: '#fff' },
  }}
  variant="contained"
  color="secondary"
  onClick={handleApproval(row.builder_id, row.listing_agent_id, 'REJECTED')}
>
  <CloseIcon />
  Rejected
</Button>
)}

{selectedOption[`${row.builder_id}-${row.listing_agent_id}`] !== 'APPROVED' && selectedOption[`${row.builder_id}-${row.listing_agent_id}`] !== 'REJECTED' && (
  <div>
    <Button
        sx={{
          color: '#fff',
          background: '#76DE24',
          borderRadius: '8px',
          '&:hover': { bgcolor: "#76DE24", color: '#fff' },
        }}
        variant="contained"
        onClick={handleApproval(row.builder_id, row.listing_agent_id, 'APPROVED')}
        style={{ marginRight: '8px' }}
      >
        <DoneIcon />
        Approved
      </Button>

      <Button
        sx={{
          color: '#fff',
          background: '#CE3932',
          borderRadius: '8px',
          '&:hover': { bgcolor: "#CE3932", color: '#fff' },
        }}
        variant="contained"
        color="secondary"
        onClick={handleApproval(row.builder_id, row.listing_agent_id, 'REJECTED')}
      >
        <CloseIcon />
        Rejected
      </Button>
  </div>
)}



      
  </div>
</div>
 </Box>
                      <Box sx={{display:'flex', width:'100%', flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <Typography sx={{width:'15%', fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',}}>
                        profile Status :
                      </Typography>
                      <div style={{width:'85%'}}>
                      <label style={{ color: "#626262", fontFamily: "poppins", fontSize: "14px", lineHeight: "18px", fontWeight: "500" }}>
  {requestStatuses[`${row.builder_id}-${row.listing_agent_id}`] ? 'Enabled' : 'Disabled'}
</label>


 <Switch
    checked={requestStatuses[`${row.builder_id}-${row.listing_agent_id}`]}
    onChange={handleChangeProfileStatus(row.builder_id, row.listing_agent_id)}
    inputProps={{ 'aria-label': 'controlled' }}
  />

                        </div>
                      </Box>
                      <Box sx={{display:'flex', width:'100%', flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <Typography sx={{width:'15%', fontFamily: 'poppins', fontSize: '16px', lineHeight: '21px', fontWeight: '400', color: '#313131',}}>
                        Approver comment<span style={{ color: 'red' }}>*</span> :
                      </Typography>
                      <TextField rows={3} multiline onChange={(event) => handlechangeApproverComment(event, row.builder_id, row.listing_agent_id)}
                        value={approverComments[`${row.builder_id}-${row.listing_agent_id}`] || ''} variant="outlined"  
                        error={errors[`${row.builder_id}-${row.listing_agent_id}`]?.approverComment !== undefined}
                        helperText={errors[`${row.builder_id}-${row.listing_agent_id}`]?.approverComment}
                           
                        sx={{
                          width: "85%",
                          borderRadius: '8px',
                          backgroundColor: '#F9F9F9',
                          '& .MuiOutlinedInput-root': {
                            border: '1px solid #CFC9C9',
                            '&.Mui-error': {
                              border: 'none',
                              boxShadow: 'none',
                              '& fieldset': {
                                border: 'none',
                              },
                            },
                          },
                        }}
                        FormHelperTextProps={{
                          sx: {
                            fontFamily: "poppins",
                            width: '100%',
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontWeight: "500",
                          },
                        }}
            InputProps={{sx: { height: '80px', color: " #626262",fontFamily: "poppins",width:'100%',
                        fontSize: "14px", lineHeight: "18px", fontWeight: "500" },
                        '& label': {display: 'flex', alignItems: 'center',justifyContent: 'center'}}} />
                        
                      </Box>
                      
                        </Box>
                        <Box  sx={{display:'flex', width:'100%', flexDirection:'column', gap:'10px',justifyContent:'right',alignItems:'flex-end',
                         paddingTop:'20px'}}>
                        <Button variant="contained" disabled={!submitClicked} size="large" sx={{
                            width: '105px', height: '36px', color: 'white',borderRadius:'8px',
                            backgroundColor: '#0C276C', textTransform: 'capitalize', '&:hover': { bgcolor: "white", color: '#0C276C',border:'1px solid #0C276C ' }
                        }}
                        onClick={() => handleSubmitButtonClick(row.builder_id, row.listing_agent_id, row.email_id)}>Submit</Button>
                        </Box>
                  
                    </Box>
                    </TableCell>
                </TableRow>
              )}
              
            </React.Fragment>
            
          ))}
          
        </TableBody>
      </Table>
    </TableContainer>
              <Footer></Footer>
              <style>
        {`
          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 1px solid #0C276C !important;
            box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
          }
        `}
      </style>
        </Grid>
    )
}
export default AdminPage;