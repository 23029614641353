// import React from 'react';
// import TextField from '@mui/material/TextField';
// import { Box, InputLabel, Stack, Typography } from '@mui/material';
// import { Button } from "@mui/material";
// import IconButton from '@mui/material/IconButton';
// import { useState } from "react";
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// import { styled } from "@mui/system";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import React, { useState } from 'react';
import otp from './images/otpblue.png';
import Tick from './images/Vector (6).png';
import TextField from '@mui/material/TextField';
import { Box, InputLabel, IconButton, Typography, Button } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/system';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const OpenPickerIcon = styled(ArrowDropDownIcon)({});

// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';


function CheckboxPopUp  (props)  {    

    const [showPopupGetTheQuote, setShowPopupGetTheQuote] = useState(false);

    const handleCancel = () => {
      setShowPopupGetTheQuote(false);
    };
 const [showPopupforCheckbox , setshowPopupforCheckbox]    = useState(false);
  
    
    const handlePopUpGetTheQuote = () => {
      setShowPopupGetTheQuote(true);
      
     
       
     
    };

    const [showPopup, setShowPopup] = useState(false);

    const handleCancelValidate = () => {
      setShowPopup(false);
    };
  
   
  
    const handlePopUp = () => {
      setShowPopup(true);
    };
    const handlePopupClose = () => {
    
      setshowPopupforCheckbox(true);
    };


 

    return(

< Box>


{ (!showPopupGetTheQuote && !showPopupforCheckbox) && (
  <Box
  sx={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: {md:'1100px',sm:'1125px'} ,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '4'
  }}
>
  <Box
    sx={{
      position: 'absolute',
      
      width: {md:'45%',sm:'80%'},
      height: '560px',
      backgroundColor: '#fff',
    
      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '4'
    }}
  >
       <IconButton onClick={handlePopupClose} style={{ color: '#939393', position: 'relative', width: '20px', height: '20px', left: '45%', 
       }} >
                  <HighlightOffIcon ></HighlightOffIcon>
                </IconButton>
      <Box  sx={{display: 'flex', flexDirection:"column" ,
 alignItems:"center", borderRadius:"8px" ,background:' #FFFFFF', gap:'20px',}}>
  <Box sx={{display: 'flex', flexDirection:"row" }}>
  <InputLabel style={{ color:"#454545", fontFamily:"poppins" , fontSize:"16px", fontWeight:"500",lineHeight:"28px" }}>Get the quotes</InputLabel>

  </Box>
 
<Box sx={{display: 'flex', flexDirection:"row",justifyContent:'center',gap:'80px',alignItems: 'center' }}>

<InputLabel style={{ color:"#313131", textAlign:"left",fontFamily:"poppins" , fontSize:"14px",lineHeight:"21px" , fontWeight:"400" }}>
    Enter annual house hold income</InputLabel>
 <TextField id="outlined-basic" type="password" placeholder="********" variant="outlined" size='small' style={{  width: '240px', height: '36px',
 border: ' #CFC9C9',borderRadius: '8px'}}/>
</Box>

<Box sx={{display: 'flex', flexDirection:"row",gap:'93px',alignItems: 'center' }}>
<InputLabel style={{ color:"#313131", textAlign:"left",fontFamily:"poppins" , fontSize:"14px",lineHeight:"21px" , fontWeight:"400",paddingTop:'5px' }}>
Monthly line of credit payment</InputLabel>
 <TextField id="outlined-basic" type="password" placeholder="enter amount" variant="outlined" size='small' style={{  width: '240px', height: '36px',
 border: ' #CFC9C9',borderRadius: '8px' }}/>
</Box>

<Box sx={{display: 'flex', flexDirection:"row",gap:'50px',alignItems: 'center' }}>
<InputLabel style={{ color:"#313131", textAlign:"left",fontFamily:"poppins" , fontSize:"14px",lineHeight:"21px" , fontWeight:"400",paddingTop:'5px' }}>
Monthly car loan payment</InputLabel>
 <TextField id="outlined-basic" type="password" placeholder="enter amount" variant="outlined" size='small' style={{  width: '240px', height: '36px',
 border: ' #CFC9C9',borderRadius: '8px',marginLeft:'71px' }}/>
</Box>
        
<Box sx={{display: 'flex', flexDirection:"row",gap:'50px',alignItems: 'center' }}>
<InputLabel style={{ color:"#313131", textAlign:"left",fontFamily:"poppins" , fontSize:"14px",lineHeight:"21px" , fontWeight:"400",paddingTop:'5px' }}>
Monthly mortgages</InputLabel>
 <TextField id="outlined-basic" type="password" placeholder="enter amount" variant="outlined" size='small' style={{  width: '240px', height: '36px',
 border: ' #CFC9C9',borderRadius: '8px',marginLeft:'120px' }}/>
</Box>

<Box sx={{display: 'flex', flexDirection:"row",gap:'50px',alignItems: 'center' }}>
<InputLabel style={{ color:"#313131", textAlign:"left",fontFamily:"poppins" , fontSize:"14px",lineHeight:"21px" , fontWeight:"400",paddingTop:'5px' }}>
Monthly property tax</InputLabel>
 <TextField id="outlined-basic" type="password" placeholder="enter amount" variant="outlined" size='small' style={{  width: '240px', height: '36px',
 border: ' #CFC9C9',borderRadius: '8px',marginLeft:'113px' }}/>
</Box>

<Box sx={{display: 'flex', flexDirection:"row",gap:'50px',alignItems: 'center' }}>
<InputLabel style={{ color:"#313131", textAlign:"left",fontFamily:"poppins" , fontSize:"14px",lineHeight:"21px" , fontWeight:"400",paddingTop:'5px' }}>
Monthly condo fees</InputLabel>
 <TextField id="outlined-basic" type="password" placeholder="enter amount" variant="outlined" size='small' style={{  width: '240px', height: '36px',
 border: ' #CFC9C9',borderRadius: '8px',marginLeft:'120px' }}/>
</Box>
<Box sx={{display: 'flex', flexDirection:"row",gap:'180px',alignItems: 'center' }}>
<InputLabel style={{ color:"#313131", textAlign:"left",fontFamily:"poppins" , fontSize:"14px",lineHeight:"21px" , fontWeight:"400",
paddingTop:'5px',marginLeft:'5px' }}>
Select expiry date</InputLabel>
  {/* <TextField id="outlined-basic" type='date'  variant="outlined" size='small' sx={{  width: '240px', height: '36px',
 border: ' #CFC9C9',borderRadius: '8px',marginLeft:'120px', }}
  > </TextField>   */}
<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
       slotProps={{ textField: { size: 'small' } }}
        slots={{
          openPickerIcon: OpenPickerIcon
        }}
        sx={{ width:'43%' ,marginLeft:'5px'}} 
        format="DD/MM/YYYY" 
      />
    </LocalizationProvider>

     
      
      
</Box>
 <Box sx={{display:"flex",justifyContent:"center",marginTop:'25px'}}>     
<Button variant="contained" onClick={handlePopUpGetTheQuote} style={{
          color: "white", textTransform: "none", 
         background: "#0C276C", border: "1px solid #0C276C", fontFamily: "Poppins", fontStyle: "normal",
          lineHeight: "21px", fontWeight: "500", fontSize: "14px", width: "224px",height:'36px' ,borderRadius: "8px",
        }}
         onMouseEnter={(e) => {
          e.currentTarget.style.background = '#074554';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.background = '#0C276C';
        }}  >
        Get the quotes
        </Button> 
       
        </Box>  
       
       


</Box>     
</Box>  
   
    </Box>



)}



{(showPopupGetTheQuote && !showPopup) && (
  <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height:{md:'1100px',sm:'1125px'} ,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '4'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',                
                  width: '448px',
                  height: '438px',
                  backgroundColor: '#fff',
                
                  border: '1px solid #CFC9C9',
                  borderRadius: '8px',               
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                  boxSizing: 'border-box',
                  zIndex: '4'
                }}
              >
                
                <IconButton onClick={handleCancel} style={{ color: '#939393', position: 'absolute', width: '20px', height: '20px', left: '415px',
                 top: '15px' }} >
                  <HighlightOffIcon ></HighlightOffIcon>
                </IconButton>
               
                <img src={otp} alt="" style={{marginTop:'0px'}} />
                <Box sx={{display:'flex', flexDirection:"column",gap:'20px',marginTop:'20px',justifyContent:'center',padding:'15px'}}>
                <Typography style={{ fontFamily: "poppins", fontSize: "20px", lineHeight: "30px", fontWeight: "500", color: '#212427',
                 textAlign: "center" }}>Enter your OTP</Typography>
                <Typography style={{ fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "500", color: '#626262',
                textAlign: "center" }}>We sent a 6-digit OTP to your registered phone Number
                and registered email id</Typography>
               
                <Box sx={{ display: 'flex', flexDirection: "row",gap:'20px',justifyContent:'center' }}>
                <TextField  variant="outlined"  InputProps={{style: { height: '36px', width:'36px',  },  }}/>
                <TextField  variant="outlined"  InputProps={{style: { height: '36px', width:'36px',  },  }}/>
                <TextField  variant="outlined"  InputProps={{style: { height: '36px', width:'36px',  },  }}/>
                <TextField  variant="outlined"  InputProps={{style: { height: '36px', width:'36px',  },  }}/>
                <TextField  variant="outlined"  InputProps={{style: { height: '36px', width:'36px',  },  }}/>
                <TextField  variant="outlined"  InputProps={{style: { height: '36px', width:'36px',  },  }}/>
                 
                </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: "column",gap:'20px',justifyContent:'center',marginTop:'20px' }}>
                <button size='small' onClick={handlePopUp} style={{ width: '346px', height: '36px', fontFamily: 'Poppins',
                    fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                     background: '#0C276C', border: '1px solid #0C276C', borderRadius: '8px',cursor:'pointer',
                    }}onMouseEnter={(e) => {
                      e.currentTarget.style.background = '#074554';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.background = '#0C276C';
                    }}  >Validate </button>
                <button size='small' style={{ width: '346px', height: '36px', fontFamily: 'Poppins', fontStyle: 'normal',
                   fontWeight: '400', fontSize: '14px', lineHeight: '21px',  textAlign: 'center', color: '#0C276C', 
                   background: '#F8F8F8', border: '1px solid #CFC9C9', borderRadius: '8px',cursor:'pointer',  }} >Resend</button>
                  </Box> 
              </Box>
            </Box>
            )}  

            {showPopup && (
  <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: {md:'1100px',sm:'1125px'} ,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '4'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
               
                  width: '436px',
                  height: '158px',
                  backgroundColor: '#fff',
                 
                  border: '1px solid #CFC9C9',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                  boxSizing: 'border-box',
                  zIndex: '4'
                }}
              >
                <IconButton onClick={handleCancelValidate} style={{ color: '#939393', position: 'absolute', width: '20px', height: '20px', 
                left: '400px',  top: '10px' }} >
                  <HighlightOffIcon ></HighlightOffIcon>
                </IconButton>
                <img src={Tick} alt="" style={{ marginTop: "15px"}} />
                <Typography style={{ fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131',                 
                textAlign: "center",marginTop: "15px" }}>You will get the quotation within 24 hours</Typography>
               
              </Box>
            </Box>
            )}  
</Box>

    );
}
export default CheckboxPopUp;