import { Box, Button, CheckBox, FormControlLabel, FormLabel, IconButton, InputAdornment, Modal, OutlinedInput, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import otp from './images/otp.png';
import CustomOutlined from './customOutlined';
import PrimaryButton from './customButton';
import SecondaryButton from './Secondarybutton';
function EnterotpMod() {
    const [openModal, setOpenmodal]=useState(true);
    const handleOpenmodal = () => setOpenmodal(true);
    const handleClosemodal = () => setOpenmodal(false);
    const [showModal,setShowModal] = useState(false);
    const [values, setValues] = useState({
        currentPassword: '',
        newPassword1: '',
        newPassword2: '',
        });
      function showModalHandler(){
         setShowModal(!showModal);
       }
      const [showPasswordValues,setShowPasswordValues]=useState({
        currentPassword: false,
        newPassword1: false,
        newPassword2: false,
      })
      const [error, setError] = useState();
      const i = [1,2,3,4,5,6];
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    return (
      <div style={{overflowY:'auto'}} >
          <Modal
          open = {openModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <Box
            sx={{
              position: 'absolute',
              width: '448px',
              height:'438px',
              left:'459px',
             top:'100px',
              bgcolor: '#FFFF',
              paddingBottom:'36px',
              borderRadius:'8px',
              BoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              alignItems:'center',
              }}>
            <div style={{ display: 'flex', flexDirection:'row-reverse',paddingTop:'12px',paddingRight:'12px' }}>
              <IconButton sx={{ padding: '0px' }} onClick={handleClosemodal} >
                < CancelOutlinedIcon sx={{ color: '#606060', width: '24px', height: '24px' }} />
            </IconButton>
              </div>
               <div style={{ display: 'flex', flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center'}}>
                  <Box sx={{height:'64px',width:'54px',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                      <img src={otp} />
                  </Box>
                  <Typography sx={{fontSize:'18px',fontWeight:500,color:'#212427',fontFamily:'Poppins',marginTop:'21px'}}>Enter your OTP</Typography>
               </div>
               <div style={{ display: 'flex', flexDirection:'column',paddingLeft:'28px',paddingRight:'28px',paddingTop:'21px',
               paddingBottom:'36px',alignItems:'center',justifyContent:'center'}}>
                <Typography sx={{textAlign:'center',width:'392px',fontSize:'14px',color:'#626262',paddingBottom:'16px',fontFamily:'Poppins',fontWeight:500}}>We sent a 6-digit OTP to your registered phone Number and registered email id</Typography>
                <div style={{width:'346px',display:'flex',flexDirection:'row',gap:'26px',marginBottom:'48px'}}>
                { i.map(item => {
                     return(
                <CustomOutlined type="text" sx={{width:'36px'}}/>);
                    })} 
                </div> 
                <PrimaryButton sx={{width:'346px'}}>Validate</PrimaryButton>
                <div style={{marginTop:'16px'}}>
                <SecondaryButton sx={{width:'346px'}}>Resend</SecondaryButton>
              </div>
            </div>
         </Box>
        </Modal>
   </div>
  )
}
export default EnterotpMod