import { Avatar, Box, Button, InputAdornment, InputBase, OutlinedInput, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import Header1 from './header1-modified';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DoneIcon from '@mui/icons-material/Done';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Ellipse from './images/Ellipse.png';
import John from './images/john1.png';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header1Mod from './header1-modified';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
function PersonalchatuserMob() {
    const [i, setI] = useState([1, 2, 3, 4,5,6,7,8,9]);
    const [k, setK] = useState([1, 2, 3, 4,5]);
    const [j, setJ] = useState([1, 2]);
    const [selected, setSelected] = useState(false);
    function selectionHandler()
    {
        setSelected(true);
    }
    return (
        <div style={{ overflow: 'hidden' }}>
            <Header1Mod />
            <Box sx={{ height: '970px', overflowY: 'auto', width: '100%', paddingLeft:{lg:'68px',xs:'10px'}, paddingRight:{lg:'68px',xs:'10px'}, paddingTop:{lg:'16px',xs:'5px'}, paddingBottom: '42px' }}>
                <Typography sx={{ fontWeight: 500, fontSize: {lg:'20px',xs:'14px'}, color: '#313131', fontFamily: 'Poppins' }}>Messages</Typography>
                <Box sx={{width: '100%', height:{lg:'60px',xs:'44px'}, marginTop: '4px', backgroundColor: '#1F6373',display:'flex',alignItems:'center', paddingLeft: {lg:'30px',xs:'12px'},borderRadius:'8px 8px 0px 0px'}}>
                   {/* <ArrowBackIcon sx={{ width: {lg:'24px',xs:14}, height:{lg:'24px',xs:'14px'},marginRight: '66px', color: 'white' }} /> */}
                </Box>
                <Box sx={{display:{lg:'none',md:'none',sm:'none',xs:'block'}}}> 
                <Box sx={{  height: '796px', display: {lg:'none',md:'none',sm:'none',xs:'flex'}, flexDirection: 'column', gap: '12px', alignItems: 'center' }}>
                    <Box sx={{ width: {lg:'398px',xs:'100%'}, height: '796px', display: 'flex', flexDirection: 'column', backgroundColor: 'white'}}>
                       {!selected? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',backgroundColor: '#F9F9F9', alignItems: 'center',paddingTop:'12px' }}>
                            <OutlinedInput
                                type="text"
                                name="search"
                                placeholder='Search'
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>}
                                sx={{
                                    width: {lg:'325px',xs:'75%'}, borderRadius: '12px', fontSize: '16px', fontFamily: 'Poppins', color: '#939393',
                                    height: '34px', border: '1px solid #1F6373'
                                }}
                            />
                            <MoreVertIcon sx={{ color: '#1F6373', height: '30px' }} />
                        </div>:<Box sx={{ alignItems: 'center', display:'flex',flexDirection:'row'}}><Box sx={{width: '20%', height: '50px',backgroundColor:'#F9F9F9',paddingLeft:'14px', alignItems: 'center', display:'flex',flexDirection:'row'}}>
                        <Box sx={{width: '34px', height: '34px', alignItems: 'center', display:'flex',alignContent:'center',justifyContent:'center',borderRadius:'50%',border: '1px solid #1F6373'}}>
                        <SearchIcon sx={{color:'#939393',width: '14px', height: '14px'}}/></Box>
                        <MoreVertIcon sx={{ color: '#1F6373', height: '30px',width:'0.75em' }} /></Box>
                        <Box sx={{ width: {lg:'820px',xs:'100%'}, height: {lg:'78px',xs:'50px'}, display: 'flex', flexDirection: 'row', marginLeft:'4px',borderBottom:'1px solid #DCDADA',backgroundColor:'#F9F9F9', paddingLeft: {lg:'36px',xs:'12px'}, alignItems: 'center',borderRadius:{xs:'8px 8px 0px 0px'} }}>
                            <Avatar sx={{ color: '#313131', backgroundColor: '#D2D2D2', width: {lg:'40px',xs:'34px'}, height: {lg:'40px',xs:'34px'},fontSize:{xs:'12px'} ,marginRight: {lg:'28px',xs:'8px'} }}>A</Avatar>
                            <Typography sx={{width:'128px', color: '#313131', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500, marginRight: '12px' }}>Agent #6602</Typography>
                            <img src={Ellipse} />
                        </Box>
                            </Box>}
                        <Box sx={{display:'flex',flexDirection:'row'}}>
                        <Box sx={{width: '20%', height: '586px',backgroundColor: '#F9F9F9',paddingLeft: '16px'}}>
                        {i.map((item, index) => {  
                                return( 
                            <Box sx={{width: '20%',height:'66px', backgroundColor: '#F9F9F9',paddingLeft:'16px', alignItems: 'center',justifyContent:'center',alignContent:'center', display:'flex',flexDirection:'column','&:hover': {
                                      backgroundColor: '#FFFFFF',
                                },
                            }}  onClick={() => selectionHandler(index)}>
                                <Avatar sx={{ color: '#313131', backgroundColor: '#DCDADA', width: '34px', height: '34px' }}>A</Avatar>
                                <Typography sx={{color: '#313131', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 500}}>#660{item}</Typography>
                            </Box>
                                );
                        })}
                         </Box>
                         {!selected?(<Box sx={{width: '100%', height: '586px',backgroundColor:'white',display:'flex',flexDirection:'column', alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                           <Box sx={{width:'28px',height:'28px',borderRadius:'50%',backgroundColor:'#D2D2D2',display:'flex', alignItems:'center',alignContent:'center',justifyContent:'center'}}> <MessageOutlinedIcon sx={{color:'#1F6373',width:'10px',height:'10px'}} /></Box>
                           <Typography sx={{ color: '#000000', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400}}>Your messages</Typography>
                         </Box>):<Box sx={{width: '100%', height: '636px',backgroundColor:'white',display:'flex',flexDirection:'column', alignItems:'center'}}>
                                 
                        <Box sx={{width: '100%',height:'586px', paddingLeft: {lg:'40px',xs:'0px'}, paddingRight: {lg:'40px',xs:'0px'},backgroundColor:'#F9F9F9', paddingBottom: '4px', paddingTop: '8px',marginLeft:'6px'}}>
                            <Box sx={{width:'100%',paddingLeft:'8px',paddingRight:'8px'}}>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '8px' }}>
                                <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                                    <img src={John} />
                                </Box>
                                <Box sx={{ height: {lg:'38px',xs:'28px'}, width: {lg:'60px',xs:'38px'}, display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Hi... </Typography>
                                </Box>
                            </div>
                            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '8px', marginRight: '28px' }}>
                                <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400 }}>8:15 PM</Typography>
                            </Box>
                            <div style={{ display: 'flex', flexDirection: 'row',gap: '8px',marginTop: '8px'}}>
                                <Avatar sx={{ color: '#313131', backgroundColor: '#D9D9D9', width: '20px', height: '20px', fontSize: '12px' }}>A</Avatar>
                            <div style={{ height: '66px', width: '193px', alignItems: 'center',backgroundColor: 'white',paddingLeft:'10px',paddingRight:'10px', borderRadius: '0px 8px 8px 8px', display: 'flex' }}>
                                <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                Hello! Thanks for reaching out us . did you find what you were looking for?
                                </Typography>
                            </div>
                            </div>
                            <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '4px', fontWeight: 400, marginRight: '28px'}}>8:17 PM</Typography>
                                                      
                             <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '4px',marginTop: '8px' }}>
                                <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                                    <img src={John} />
                                </Box>
                            <div style={{ height: '54px', width: '72%', display: 'flex', alignItems: 'center', backgroundColor: '#1F6373',paddingLeft:'10px',paddingRight:'10px', borderRadius: '8px 0px 8px 8px' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                    I would like to move on with your quotation for mortgage 2
                                    </Typography>
                                </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                <div style={{ height: '34px',paddingLeft:'10px',paddingRight:'10px', width: '72%px', alignItems: 'center',marginTop: '8px', backgroundColor: '#1F6373',paddingLeft:'10px',paddingRight:'10px', borderRadius: '8px 0px 8px 8px', display: 'flex' ,marginRight:'26px'}}>
                                <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                    So, can you briefly explain about it?
                                </Typography>
                            </div>
                            </div>
                             <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '4px' }}>
                                <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400, marginRight: '28px' }}> 8:17 PM</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '12px', gap: '8px' }}> 
                                <Avatar sx={{ color: '#313131', backgroundColor: '#D9D9D9', width: '20px', height: '20px', fontSize: '12px' }}>A</Avatar>
                                <div style={{ height: '28px', width: '94px',display:'flex', alignItems: 'center', paddingLeft: '10px',paddingRight:'10px', backgroundColor: 'white', borderRadius: '0px 8px 8px 8px' }}>
                                    <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                        Yeah,sure
                                    </Typography>
                                </div>
                            </div>
                            <div style={{ height: '62px', width: '80%', alignItems: 'center', marginLeft: '28px',paddingLeft:'10px',paddingRight:'10px', marginTop: '8px', backgroundColor: 'white', borderRadius: '0px 8px 8px 8px', display: 'flex' }}>
                                <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                quotation for mortgage 4 will help you get a comparatively law interest rate
                                </Typography>
                            </div>
                            <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '4px', fontWeight: 400 }}>8:17 PM</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '8px', marginTop:'12px' }}>
                                <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                                    <img src={John} />
                                </Box>
                                <div style={{ height: '82px', width: '80%', display: 'flex',alignItems: 'center',paddingLeft:'10px',paddingRight:'10px', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                    okay,i will catch you later,now i am looking for a quotation with less amortization period,thanks for your corporation
                                    </Typography>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '4px', marginRight: '28px' }}>
                            <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '8px', fontWeight: 400 }}>8:17 PM</Typography>
                            </div>
                             </Box>                           
                              <Paper
                                elevation={0}
                                sx={{ display: 'flex',border:'1px solid #313131', alignItems: 'center', justifyContent: 'space-between', width: {lg:'680px',xs:'100%'}, height: {lg:'52px',xs:'40px'}, borderRadius: '0.25rem', paddingRight: '12px', paddingLeft: '0.5rem', marginLeft: {lg:'30px',xs:'0px'}, marginTop: '10px' }}>
                                <InputBase
                                    placeholder="Your message here"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <Button endIcon={<SendIcon sx={{
                                    color: 'white', width: '18px', height: '18px', '&:hover': {
                                        backgroundColor: '#D2E0E3',
                                        color: '#1F6373',
                                        border: '1px solid white',
                                    }, }} />} 
                                    sx={{width: {lg:'40px',xs:'30px'}, minWidth: {lg:'40px',xs:'30px'}, height:{lg:'40px',xs:'30px'}, borderRadius: '50%', backgroundColor: '#1F6373', "& .MuiButton-endIcon": { marginLeft: '0px' }, '&:hover': {
                                        backgroundColor: '#D2E0E3',
                                        color: '#1F6373',
                                        border: '1px solid #1F6373',
                                    },}}>
                                </Button>
                            </Paper>
                           </Box>
                           
                            </Box>}
                         </Box>
                    </Box>
                    </Box>
                    </Box>
                    {/* </Box> */}
                    <Box sx={{display:{lg:'block',md:'block',sm:'block',xs:'none'}}}>

                    <Box sx={{ width: '100%', height: '796px', display: 'flex', flexDirection: 'row', marginTop:{lg:'12px',md:'12px',sm:'20px'}, gap: {lg:'12px',md:'12px',sm:'10px'}, alignItems: 'center',backgroundColor:'white' }}>
                    <Box sx={{ width: '30%', height: '796px', display: 'flex', flexDirection: 'column', backgroundColor: '#F8F8F8', paddingTop: '18px', paddingLeft: '16px', paddingRight: '16px', gap: '36px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <OutlinedInput
                                type="text"
                                name="search"
                                placeholder='Search'
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>}
                                sx={{
                                    width: '325px', borderRadius: '12px', fontSize: '16px', fontFamily: 'Poppins', color: '#939393',
                                    height: '34px', border: '1px solid #1F6373'
                                }}
                            />
                            <MoreVertIcon sx={{ color: '#1F6373', height: '30px' }} />
                        </div>
                        <div>
                        {k.map((item, index) => {  
                                return( 
                            <Box sx={{width: '366px', height: '84px', alignItems: 'center', display: 'flex', justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px', '&:hover': {
                                      backgroundColor: '#FFFFFF',
                                },
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                    <Avatar sx={{ color: '#313131', backgroundColor: '#DCDADA', width: '34px', height: '34px' }}>A</Avatar>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography sx={{ color: '#313131', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500, textAlign: 'left' }}>Agent #6601</Typography>
                                        <Typography sx={{ color: '#626262', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500, textAlign: 'left' }}>You: Sure why not</Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
                                    <Typography sx={{ color: '#626262', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 500 }}>Today</Typography>
                                    <DoneIcon sx={{ color: '#76DE24', width: 20, height: 16 }} />
                                </div>
                            </Box>
                                );
                        })}
                         {j.map((item, index) => {  
                                return( 
                            <Box sx={{width: '366px', height: '84px', alignItems: 'center', display: 'flex', justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px', '&:hover': {
                                        backgroundColor: '#FFFFFF',
                                },
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                    <Avatar sx={{ color: '#313131', backgroundColor: '#DCDADA', width: '34px', height: '34px' }}>A</Avatar>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography sx={{ color: '#313131', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500, textAlign: 'left' }}>Agent #6601</Typography>
                                        <Typography sx={{ color: '#626262', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500, textAlign: 'left' }}>You: Sure why not</Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
                                    <Typography sx={{ color: '#626262', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 500 }}>Yesterday</Typography>
                                    <SyncAltIcon sx={{ color: '#1F6373', width: 16, height: 10 }} />
                                </div>
                            </Box>
                                );
                        })}
                            <Box sx={{
                                width: '366px', height: '84px', alignItems: 'center', display: 'flex', justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px', '&:hover': {
                                    backgroundColor: '#FFFFFF',
                                },
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                    <Avatar sx={{ color: '#313131', backgroundColor: '#DCDADA', width: '34px', height: '34px' }}>A</Avatar>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography sx={{ color: '#313131', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500, textAlign: 'left' }}>Agent #6601</Typography>
                                        <Typography sx={{ color: '#626262', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500, textAlign: 'left' }}>You: Sure why not</Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
                                    <Typography sx={{ color: '#626262', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 500 }}>29/03/23</Typography>
                                    <DoneIcon sx={{ color: '#76DE24', width: 20, height: 16 }} />
                                </div>
                            </Box>
                        </div>
                    </Box>
                    <Box sx={{ width: '70%', height: '796px', display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{ width: {lg:'100%',md:'100%',sm:'98%'}, height: '78px', display: 'flex', flexDirection: 'row', backgroundColor: '#1F6373', paddingLeft: '36px', alignItems: 'center' }}>
                            <Avatar sx={{ color: '#313131', backgroundColor: '#D2D2D2', width: '40px', height: '40px', marginRight: '28px' }}>A</Avatar>
                            <Typography sx={{ color: '#FFFFFF', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500, marginRight: '12px' }}>Agent #6602</Typography>
                            <img src={Ellipse} />
                        </Box>
                        <Box sx={{width: {lg:'100%',md:'100%',sm:'98%'},height: '796px', paddingLeft: '40px', paddingRight: '40px', paddingBottom: '28px', paddingTop: '14px', backgroundColor: '#F8F8F8' }}>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '8px' }}>
                                <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                                    <img src={John} />
                                </Box>
                                <div style={{ height: '38px', width: '60px', display: 'flex', alignItems: 'center', paddingLeft: '16px', paddingRight: '16px', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Hi... </Typography>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '8px', marginRight: '28px' }}>
                                <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400 }}>8:15 PM</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row',gap: '8px'}}>
                                <Avatar sx={{ color: '#313131', backgroundColor: '#D9D9D9', width: '20px', height: '20px', fontSize: '12px' }}>A</Avatar>
                            <div style={{ height: '54px', width: '314px', alignItems: 'center', paddingLeft: '16px', paddingRight: '16px',marginTop: '8px', backgroundColor: 'white', borderRadius: '0px 8px 8px 8px', display: 'flex' }}>
                                <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                Hello! Thanks for reaching out us . did you find what you were looking for?
                                </Typography>
                            </div>
                            </div>
                            <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '8px', fontWeight: 400, marginRight: '28px'}}>8:17 PM</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '8px' }}>
                                <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                                    <img src={John} />
                                </Box>
                            <div style={{ height: '38px', width: '390px', paddingLeft: '16px', paddingRight: '16px', display: 'flex', alignItems: 'center', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                    I would like to move on with your quotation for mortgage 2
                                    </Typography>
                                </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                <div style={{ height: '38px', width: '262px', alignItems: 'center', paddingLeft: '16px',paddingRight:'16px',marginTop: '8px', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px', display: 'flex' ,marginRight:'28px'}}>
                                <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                    So, can you briefly explain about it?
                                </Typography>
                            </div>
                            </div>
                             <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '8px' }}>
                                <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400, marginRight: '28px' }}> 8:17 PM</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px', gap: '8px' }}> 
                                <Avatar sx={{ color: '#313131', backgroundColor: '#D9D9D9', width: '20px', height: '20px', fontSize: '12px' }}>A</Avatar>
                                <div style={{ height: '38px', width: '94px',display:'flex', alignItems: 'center', paddingLeft: '16px',paddingRight:'16px', backgroundColor: 'white', borderRadius: '0px 8px 8px 8px' }}>
                                    <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                        Yeah,sure
                                    </Typography>
                                </div>
                            </div>
                            <div style={{ height: '54px', width: '388px', alignItems: 'center', paddingLeft: '16px',paddingRight:'10px', marginLeft: '28px', marginTop: '8px', backgroundColor: 'white', borderRadius: '0px 8px 8px 8px', display: 'flex' }}>
                                <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                quotation for mortgage 4 will help you get a comparatively law interest rate
                                </Typography>
                            </div>
                            <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '8px', fontWeight: 400 }}>8:17 PM</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '8px', marginTop:'22px' }}>
                                <Box sx={{ height: '20px', width: '20px', borderRadius: '20px', alignItems: 'center' }}>
                                    <img src={John} />
                                </Box>
                                <div style={{ height: '54px', width: '390px', display: 'flex', paddingLeft: '16px', paddingRight: '16px', alignItems: 'center', backgroundColor: '#1F6373', borderRadius: '8px 0px 8px 8px' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                    okay,i will catch you later,now i am looking for a quotation with less amortization period,thanks for your corporation
                                    </Typography>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '8px', marginRight: '28px' }}>
                            <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '8px', fontWeight: 400 }}>8:17 PM</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '22px', gap: '8px' }}> 
                                <Avatar sx={{ color: '#313131', backgroundColor: '#D9D9D9', width: '20px', height: '20px', fontSize: '12px' }}>A</Avatar>
                                <div style={{ height: '38px', width: '94px',display:'flex', alignItems: 'center', paddingLeft: '16px',paddingRight:'16px', backgroundColor: 'white', borderRadius: '0px 8px 8px 8px' }}>
                                    <Typography sx={{ color: '#313131', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>
                                        Yeah,sure
                                    </Typography>
                                </div>
                            </div>
                            <Typography sx={{ color: '#939393', fontSize: '10px', fontFamily: 'Poppins', marginLeft: '28px', marginTop: '8px', fontWeight: 400 }}>8:17 PM</Typography>
                              <Paper
                                elevation={0}
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '94%', height: '52px', borderRadius: '0.25rem', paddingRight: '12px', paddingLeft: '0.5rem', marginLeft: '30px', marginTop: '11px' }}>
                                <InputBase
                                    placeholder="Type here"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <Button endIcon={<SendIcon sx={{
                                    color: 'white', width: '18px', height: '18px', '&:hover': {
                                        backgroundColor: '#D2E0E3',
                                        color: '#1F6373',
                                        border: '1px solid white',
                                    }, }} />} 
                                    sx={{width: '40px', minWidth: '40px', height: '40px', borderRadius: '50%', backgroundColor: '#1F6373', "& .MuiButton-endIcon": { marginLeft: '0px' }, '&:hover': {
                                        backgroundColor: '#D2E0E3',
                                        color: '#1F6373',
                                        border: '1px solid #1F6373',
                                    },}}>
                                </Button>
                            </Paper>
                           </Box>
                   </Box>
                </Box>
                    </Box>
                    
                    </Box>
                    </div>
                    );
                    }

                    
export default PersonalchatuserMob;

