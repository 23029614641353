import { Box, InputLabel, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

function PaymentComponent(props) {

    const { dataList,setDataList,mortgageName, isSelected, isCheckedCheckboxNext,result2,result} = props;
    const index=dataList.findIndex(x => x.name ===mortgageName);

const handleMouseEnter = (index) => {
    var tempdataList=[...dataList]
    tempdataList[index].isSelected=true  ;    
    setDataList(tempdataList);
  };

  const handleMouseLeave = (index) => {
    var tempdataList=[...dataList]
    tempdataList[index].isSelected=false  ;    
    setDataList(tempdataList); 
  };


    return (


        <Box sx={{
            display: 'flex', flexDirection: "column", border:dataList[index].isSelected ||isCheckedCheckboxNext? "1px solid #0C276C":"1px solid #CFC9C9", 
             width: "98.5%", height: "300px",            alignItems: "center", borderRadius: "8px", gap: '8%', justifyContent: 'center', 
            background: dataList[index].isSelected ||isCheckedCheckboxNext? "#F9F9F9" : "white" ,padding:'10%'
        }}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={() => handleMouseLeave(index)}
        >
            <Typography sx={{
                color: "#313131",   fontFamily: "poppins", fontSize: { md: '14px', sm: '14px',xs:'12px' },marginTop:'-8px',
                fontWeight: "400", lineHeight: "21px",textAlign:'center', 
                padding:{ md: "0px 10px 0px 10px", sm:"0px 0px 0px 0px",xs:"0px 0px 0px 0px" },
            }}>{mortgageName}</Typography>

<TextField  variant="outlined" value={isNaN(dataList[index].SheduledResult) ? '' : String(dataList[index].SheduledResult)}  
    
    sx={{
      width: " 100%", fontFamily: "poppins",
      fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", '& input': {
        padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
      },
    }}
      InputProps={{
        sx: { height: '28px', color: " #626262",":read-only":true, pointerEvents: 'none', fontFamily: "poppins",
        fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />
    <style>
      {`
        .Mui-focused .MuiOutlinedInput-notchedOutline {
          border: 1px solid #0C276C !important;
          box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
        }
      `}
    </style>

    
<TextField  variant="outlined" value={isNaN(dataList[index].lowerResults.TotalAmountPaid) ? '' : String(dataList[index].lowerResults.TotalAmountPaid)}  
    
    sx={{
      width: " 100%", fontFamily: "poppins",
      fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", '& input': {
        padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
      },
    }}
      InputProps={{
        sx: { height: '28px', color: " #626262",":read-only":true,pointerEvents: 'none',fontFamily: "poppins",
        fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />

<TextField  variant="outlined" value={isNaN(dataList[index].lowerResults.TotalInterestPaid) ? '' : String(dataList[index].lowerResults.TotalInterestPaid)}  
    
    sx={{
      width: " 100%", fontFamily: "poppins",
      fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", '& input': {
        padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
      },
    }}
      InputProps={{
        sx: { height: '28px', color: " #626262",":read-only":true,pointerEvents: 'none',fontFamily: "poppins",
        fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />
      <TextField  variant="outlined" value={isNaN(dataList[index].lowerResults.TotalPrinciplePaid) ? '' : String(dataList[index].lowerResults.TotalPrinciplePaid)}  
    
    sx={{
      width: " 100%", fontFamily: "poppins",
      fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", '& input': {
        padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
      },
    }}
      InputProps={{
        sx: { height: '28px', color: " #626262",":read-only":true,pointerEvents: 'none',fontFamily: "poppins",
        fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />

<TextField  variant="outlined" value={(dataList[index].mortgageamount) - (dataList[index].SheduledResult)}  
    
    sx={{
      width: " 100%", fontFamily: "poppins",
      fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", '& input': {
        padding:{ md: '12px', sm: '6px',xs:'8px' } // Override padding for the input element
      },
    }}
      InputProps={{
        sx: { height: '28px', color: " #626262",":read-only":true,pointerEvents: 'none',fontFamily: "poppins",
        fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px", fontWeight: "400", },
        '& label': {
          display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }
      }} />    {/* <Typography sx={{
                color: "#626262",fontFamily: "poppins", fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px",
                fontWeight: "400",paddingLeft:{md:'30px',sm:'20px'}
            }}>{dataList[index].SheduledResult}</Typography> 
            <Typography sx={{
                color: "#626262",  fontFamily: "poppins", fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px",
                fontWeight: "400",paddingLeft:{md:'30px',sm:'20px'}
            }}>{dataList[index].lowerResults.TotalAmountPaid}</Typography>
            <Typography sx={{
                color: "#626262", fontFamily: "poppins", fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px",
                fontWeight: "400",paddingLeft:{md:'30px',sm:'20px'}
            }}>{dataList[index].lowerResults.TotalInterestPaid}</Typography>
            <Typography sx={{
                color: "#626262",  fontFamily: "poppins", fontSize: { md: '12px', sm: '12px',xs:'10px' }, lineHeight: "18px",
                fontWeight: "400",paddingLeft:{md:'30px',sm:'20px'}
            }}>{dataList[index].lowerResults.TotalPrinciplePaid}</Typography>*/}
        </Box>


    );
}
export default PaymentComponent;