import Box from '@mui/material/Box';
import {  Grid, Typography } from "@mui/material";
import { useState } from "react";
import '../App.css';
import Header from './HeaderLanding'
import HeaderMobile from './HeaderLandingMobile';
import Footer from './Footer';

function Disclaimer() {
    const [currentPage, setCurrentPage] = useState('');

    return (
        <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>
            <Box sx={{
                height: '100% ', width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'flex', sm: 'flex', xs: 'none' }
            }}>
                <Header currentPage={currentPage}></Header>
            </Box>
            <Box sx={{
                height: '100% ', width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'none', sm: 'none', xs: 'flex' }
            }}>
                <HeaderMobile currentPage={currentPage}></HeaderMobile>
            </Box>
            <Box sx={{
                width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)', xs: 'calc(100%-10%)' }, display: 'flex', flexDirection: 'row', height: '100%',
                paddingLeft: { md: '16%', sm: '5%', xs: '5%' }, paddingRight: { md: '16%', sm: '5%', xs: '5%' }, paddingTop: { md: '50px', sm: '50px', xs: '25px' },
                paddingBottom: { md: '25px', sm: '25px', xs: '5%' },
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', width: '100%', height: '100%',
                     background: "#F9F9F9 ", padding:{ md: '50px ', sm: '50px ', xs: '25px '} ,  alignItems: 'center'
                }}>

                    <Typography sx={{
                        fontFamily: 'poppins', fontSize:{ md: '26px', sm: '26px', xs: '20px' } , paddingTop: { md: '25px', sm: '20px', xs: '0px' },
                        lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                    }}>
                        Disclaimer
                    </Typography>
                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: { md: '25px', sm: '20px', xs: '0px' },
                        lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                    }}>
                        Last Updated: August 15, 2023
                    </Typography>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', height: '100%',
                        padding: '10px 0px 25px 0px', alignItems: 'center',gap:'10px'
                    }}>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px', 
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>
                            The information provided by this Ratewaves.ca website is for general informational purposes only and does not constitute
                            financial, legal, or professional advice. While we strive to keep the information accurate and up to date, we make no
                            representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability,
                            suitability, or availability with respect to the website or the information, products, services, or related graphics
                            contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your
                            own risk.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>
                            The use of this mortgage calculator is not a substitute for professional advice from a qualified financial
                            advisor, mortgage broker, or other relevant professionals. Users are encouraged to seek personalized advice
                            before making any financial decisions based on the results obtained from this mortgage calculator.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px', 
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>
                            In no event will we be liable for any loss or damage including without limitation, indirect or
                            consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits
                            arising out of or in connection with the use of this website and its mortgage calculator.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px', 
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>
                            Through this website, you can link to other websites that are not under the control of this mortgage
                            calculator website. We have no control over the nature, content, and availability of those sites.
                            The inclusion of any links does
                        </Typography>
                    </Box>
                </Box>
            </Box>
          <Footer></Footer>
        </Grid>
    );

}

export default Disclaimer;