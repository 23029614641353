import React, { useState } from 'react'
//import Header1 from './header1'
import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import HelpIcon from '@mui/icons-material/Help';
import Header2 from './header2-modified';
import { useTheme } from '@mui/material/styles';
import Leftbox from './leftbox';
import SecondaryButton from './Secondarybutton';
import Header2Mod from './header2-modified';
function UserprofileMob() {
  const [value, setValue] = useState(0);
const [value1, setValue1] = useState(0);
const [i, setI] = useState([2, 4, 6,7]);
const [j, setJ] = useState(5);
const handleChange = (event, newValue) => {
  setValue(newValue);
};
const newHandleChange = (event, newValue) => {
  setValue1(newValue);
};
const theme = useTheme();
const isMobile = theme.breakpoints.down('xs');
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '0px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
return (
  <div style={{ overflow: 'hidden' }}>
          <Header2Mod />
          <Box sx={{ height: '902px', overflowY: 'auto', width: '1366px', paddingLeft: {lg:'38px',sm:'30px',xs:'4%'}, paddingRight: {lg:'38px',sm:'30px',xs:'4%'}, paddingTop: '10px', paddingBottom: '64px' }}>
              <Typography sx={{ fontWeight: 500, fontSize: '20px', color: '#313131', fontFamily: 'Poppins',paddingLeft:'2px'}}>User profile</Typography>
              <Box sx={{
                 width: {lg:'1290px',md:'1290px',sm:'54%',xs:'26%'}, height:{lg:'60px',xs:'44px'}, marginTop: '2px', backgroundColor: '#1F6373',borderRadius:'8px 8px 0px 0px' ,paddingLeft:'30px',alignItems:'center',display:'flex'}}>
                  <Typography sx={{ fontWeight: 500, fontSize: '16px', color: 'white', fontFamily: 'Poppins'}}>User Id : 225231</Typography>
             </Box>
             <Box>
              <Box sx={{width:'1250px',paddingTop:'18px',marginLeft:{lg:'70px',md:'70px',sm:0,xs:0}}}>
              <Typography sx={{ fontWeight: 500, fontSize: '20px', color: '#313131', fontFamily: 'Poppins'}}>Selected mortgages</Typography>
              <Box sx={{display:'flex',flexDirection:{lg:'row',md:'column',sm:'column',xs:'column'},gap:{lg:'36px',md:'36px',sm:'12px',xs:'12px'},marginTop:'16px'}}>
                  <Box sx={{display:'flex',flexDirection:{lg:'column',md:'row',sm:'row',xs:'row'},width:{lg:'224px',md:'224px',sm:'56%',xs:'28%'},height:{lg:'666px',md:'666px',sm:'82px',xs:'82px'},gap:{lg:'18px',md:'18px',sm:'8px',xs:'8px'},overflowX:'auto',overflowY:'hidden',paddingTop:'12px',paddingBottom:'12px',borderRight:{lg:'1px solid #CFC9C9'}}}>
                      <Box sx={{minWidth:{lg:'200px',md:'200px',sm:'180px',xs:'180px'},height:{lg:'92px',md:'92px',sm:'82px',xs:'82px'},backgroundColor:'#F9F9F9',display:'flex',flexDirection:'column',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                          <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#626262', fontFamily: 'Poppins'}}>Annual house hold income</Typography>
                          <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#313131', fontFamily: 'Poppins'}}>$2,50,000</Typography>
                      </Box>
                      <Box sx={{minWidth:{lg:'200px',md:'200px',sm:'180px',xs:'180px'},height:{lg:'92px',md:'92px',sm:'82px',xs:'82px'},backgroundColor:'#F9F9F9',display:'flex',flexDirection:'column',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                          <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#626262', fontFamily: 'Poppins'}}>Line of credit payment</Typography>
                          <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#313131', fontFamily: 'Poppins'}}>$2,50,000</Typography>
                      </Box>
                      <Box sx={{minWidth:{lg:'200px',md:'200px',sm:'180px',xs:'180px'},height:{lg:'92px',md:'92px',sm:'82px',xs:'82px'},backgroundColor:'#F9F9F9',display:'flex',flexDirection:'column',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                      <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#626262', fontFamily: 'Poppins'}}>Car loan payment</Typography>
                          <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#313131', fontFamily: 'Poppins'}}>$2,50,000</Typography>
                      </Box>
                      <Box sx={{minWidth:{lg:'200px',md:'200px',sm:'180px',xs:'180px'},height:{lg:'92px',md:'92px',sm:'82px',xs:'82px'},backgroundColor:'#F9F9F9',display:'flex',flexDirection:'column',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                      <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#626262', fontFamily: 'Poppins'}}>Monthly mortgages</Typography>
                          <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#313131', fontFamily: 'Poppins'}}>$2,50,000</Typography>
                      </Box>
                      <Box sx={{minWidth:{lg:'200px',md:'200px',sm:'180px',xs:'180px'},height:{lg:'92px',md:'92px',sm:'82px',xs:'82px'},backgroundColor:'#F9F9F9',display:'flex',flexDirection:'column',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                      <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#626262', fontFamily: 'Poppins'}}>Monthly property tax</Typography>
                          <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#313131', fontFamily: 'Poppins'}}>$2,50,000</Typography>
                      </Box>
                      <Box sx={{minWidth:{lg:'200px',md:'200px',sm:'180px',xs:'180px'},height:{lg:'92px',md:'92px',sm:'82px',xs:'82px'},backgroundColor:'#F9F9F9',display:'flex',flexDirection:'column',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                      <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#626262', fontFamily: 'Poppins'}}>Monthly condo fees</Typography>
                          <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#313131', fontFamily: 'Poppins'}}>$2,50,000</Typography>
                      </Box>
                  </Box>
                  <Box sx={{ display:'flex',flexDirection:'column'}}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, fontSize: '14px', fontFamily: 'Poppins', color: '#313131', fontWeight: 600, marginTop: '15px' }}>Input parameters</Typography>
              <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}} >
                  <Box sx={{ height: '324px', display: 'flex', flexDirection: 'column',
                            paddingTop: {lg:0,md:0,sm:'18px',xs:'18px'}, paddingLeft: '12px', paddingRight: '12px', paddingBottom: '18px',
                             gap: {lg:'20px',md:'20px',sm:'16px',xs:'16px'}, marginTop: '12px', 
                            position: 'relative'}}>
                     <Typography sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, fontSize: '16px', fontFamily: 'Poppins', color: '#313131', fontWeight: 500 }}>Input parameters</Typography>
                     <Box sx={{width:{lg:'178px',sm:'178px'},display:'flex',flexDirection:'column',gap:'16px',marginTop:{}}}>
                     <Leftbox />
                    </Box>
                  </Box>
                 <Box sx={{ width: {lg:'692px',md:'692px',sm:'692px',xs:'300px'},height:'362px',overflowX:{lg:'hidden',md:'hidden',sm:'hidden',xs:'auto'},overflowY:'hidden', display: 'flex', gap: {lg:'20px',sm:'10px',xs:'10px'} }}>
                    {i.map((item, index) => {
                       if (isMobile && index > 1) {
                        return null; // Skip rendering this item
                      }
                        return (
                          <Box key={index} sx={{
                            width: { lg: '158px', md: '120px', sm: '120px', xs: '104px' },height:{sm:'320px',xs:'300px'}, display: 'flex', flexDirection: 'column',
                            paddingTop: {lg:'18px',md:'18px',sm:'22px',xs:'10px'}, paddingLeft: '12px', paddingRight: '12px', paddingBottom: '20px', backgroundColor: 'white',
                            border: '1px solid #CFC9C9', gap: '16px', marginTop: {lg:'12px',md:'12px',sm:'12px',xs:0}, borderRadius: '8px',
                            position: 'relative'
                          }}>
                         <Typography sx={{ textAlign: 'center', fontSize: {lg:'14px',xs:'12px'}, fontWeight: 400, color: '#626262',fontFamily: 'Poppins' }}>Mortgage {item}</Typography>
                          <Box sx={{width:'178px',display:'flex',flexDirection:'column',gap:'16px'}}>
                           <Box sx={{ width: { lg: '134px', md: 168, sm: 100, xs: 80 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',backgroundColor:'#F9F9F9'}}>
                            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins',backgroundColor:'#F9F9F9' }}>0</Typography>
                            </Box>
                            <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Variable</Typography>
                            </Box>
                            <Box sx={{ width: { lg: '134px', md: 168, sm: 100, xs: 80 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',backgroundColor:'#F9F9F9'}}>
                              <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins',backgroundColor:'#F9F9F9' }}>2 Months</Typography>
                            </Box>
                            <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>0</Typography>
                            </Box>
                            <Box sx={{ width: { lg: '134px', md: 168, sm: 100, xs: 80 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',backgroundColor:'#F9F9F9'}}>
                              <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins',backgroundColor:'#F9F9F9' }}>0</Typography>
                            </Box> 
                            <Box sx={{ width: { lg: '134px', md: 168, sm: 110, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Monthly</Typography>
                            </Box>
                            <Typography sx={{ fontSize: { lg: '14px', md: '14px', sm: '14px', xs: '12px' }, fontWeight: { lg: 500, xs: 500 }, color: '#313131', fontFamily: 'Poppins', marginTop: {lg:'12px',md:'12px',sm:'12px',xs:'0px'},marginBottom:'4px', marginLeft:{lg:'30px',sm:'20px'}}}>CAD({0})</Typography>
                            </Box>
                          </Box>
                        );
                      })}
                   </Box>
                    </Box>
             </div>
                   <Tabs value={value1} onChange={newHandleChange} aria-label="basic tabs example"
                      TabIndicatorProps={{ style: { background: '#313131' } }}
                      sx={{
                        " .MuiTabs-flexContainer": {height:{lg:'66px',md:'66px',sm:'66px',xs:'30px'},
                          alignItems: 'center',marginTop:{lg:'20px',md:'20px',sm:'20px',xs:'0px'},paddingLeft:'10px',gap:{lg:'18px',md:'18px',sm:'10px',xs:'8px'},
                          "& .Mui-selected": { color: '#313131', borderColor: '#313131', fontWeight: 500 }, "& .MuiTabs-indicator": { backgroundColor: '1px solid #313131' },
                        },
                        ".MuiTab-root": { paddingLeft: '0px' },
                      }}
                    >
                      <Tab label="Calculate payment" sx={{ textTransform: 'none',paddingBottom:0, fontFamily: 'Poppins',color:'#313131',fontWeight: 400 ,marginTop:'24px', fontSize: { lg: 16, xs: 12 } ,paddingLeft:0,paddingRight:0}} {...a11yProps(0)} />
                      <Tab label="Calculate penalty on cancellation" sx={{ textTransform: 'none',paddingBottom:0,color:'#313131', fontWeight: 400, fontFamily: 'Poppins',marginTop:'24px', fontSize: { lg: 16, xs: 12 } ,paddingLeft:0,paddingRight:0}} {...a11yProps(1)} />
                    </Tabs>
                  <TabPanel value={value1} index={0} sx={{
                  "& .MuiTabPanel-root": { padding: '0px' },
                  "& .MuiBox-root": { padding: '0px' },
                }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column',paddingLeft:'12px',paddingRight: {sm:'0px',xs:'12px'}, gap: '16px', marginTop: '64px', marginRight: {lg:'20px',sm:'30px'} }}>
                      <Box sx={{ width: { lg: '168px', md: 162, sm: 158, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Payment schedule</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                      <Box sx={{ width: { lg: '168px', md: 162, sm: 158, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Remaining principle</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                      <Box sx={{ width: { lg: '168px', md: 162, sm: 158, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Interest paid</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                      <Box sx={{ width: { lg: '168px', md: 162, sm: 158, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Principle paid</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                    </Box>
                      <Box sx={{width: {lg:'692px',md:'692px',sm:'692px',xs:'300px'},overflowX:{xs:'auto'},display:'flex',flexDirection:'row',gap:{lg:'20px',md:'20px',sm:'10px',xs:'10px'}}}>
                          {i.map((item, index) => {
                            if (isMobile && index > 1) {
                              return null; // Skip rendering this item
                            }
                            return (
                              <Box sx={{
                                width: {lg:'158px',md:'158px',sm:'120px',xs:'104px'}, height: '218px', display: 'flex', flexDirection: 'column',
                                paddingTop: '20px', paddingLeft: '12px', paddingRight: '12px', paddingBottom: '20px', backgroundColor: 'white',
                                border: '1px solid #CFC9C9', gap: '12px', borderRadius: '8px',marginTop:'12px'
                            }}>
                              <Box sx={{ width: { lg: '134px', md: 168}, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',alignContent:'center',justifyContent:'center'}}>
                                <Typography sx={{ textAlign: 'center', fontSize: {lg:'14px',xs:'12px'}, fontWeight: 400, color: '#626262',fontFamily:'Poppins' }}>Mortgage {item}</Typography>
                              </Box>
                              <Box sx={{width:'178px',display:'flex',flexDirection:'column',gap:'16px'}}>
                              <Box sx={{ width: { lg: '134px', md: 168, sm: 100, xs: 80 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',backgroundColor:'#F9F9F9'}}>
                                <Typography sx={{
                                  fontSize: '12px', color: '#313131',
                                  textAlign: 'left', lineHeight: '28px', fontFamily: 'Poppins'
                                }}>0</Typography>
                                </Box>
                                <Box sx={{ width: { lg: '134px', md: 168, sm: 100, xs: 80 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{
                                  fontSize: '12px', color: '#313131',
                                  textAlign: 'left', lineHeight: '28px', fontFamily: 'Poppins'
                                }}>0</Typography>
                                </Box>
                                <Box sx={{ width: { lg: '134px', md: 168, sm: 100, xs: 80 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',backgroundColor:'#F9F9F9'}}>
                                <Typography sx={{
                                  fontSize: '12px', color: '#313131',
                                  textAlign: 'left', lineHeight: '28px', fontFamily: 'Poppins'
                                }}>0</Typography>
                                </Box>
                                <Box sx={{ width: { lg: '134px', md: 168, sm: 100, xs: 80 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{
                                  fontSize: '12px', color: '#313131', lineHeight: '28px',
                                  textAlign: 'left', fontFamily: 'Poppins'
                                }}>0</Typography>
                                </Box>
                                </Box>
                                </Box>
                                );
                          })}
                        </Box>
                      </div>
                </TabPanel>
                <TabPanel value={value1} index={1}
                  x={{
                    "& .MuiTabPanel-root": { paddingLeft: '0px' },
                    "& .MuiBox-root": { padding: '0px' },
                  }}>

                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{  display: 'flex', flexDirection: 'column', marginTop: '64px', gap: '16px', marginRight: {lg:'16px',sm:'18px',xs:'0px'},paddingRight: {xs:'12px'},paddingLeft:'12px' }}>
                      <Box sx={{ width: { lg: '168px', md: 162, sm: 158, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Cancellation</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                      <Box sx={{ width: { lg: '168px', md: 162, sm: 158, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Penalty</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                      <Box sx={{ width: { lg: '168px', md: 162, sm: 158, xs: 110 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: { lg: '14px', md: 14, sm: 12, xs: 12 }, fontWeight: 400, color: '#313131', fontFamily: 'Poppins' }}>Calculated penalty</Typography>
                        <HelpIcon sx={{ width: '12px', height: '12px', color: '#878787' }} />
                      </Box>
                    </Box>
                      {/* <div style={{display:'flex',flexDirection:'row',gap:'20px'}}> */}
                      <Box sx={{width: {lg:'692px',md:'692px',sm:'692px',xs:'300px'},overflowX:{xs:'auto'},display:'flex',flexDirection:'row',gap:{lg:'20px',md:'20px',sm:'10px',xs:'10px'}}}>
                          {i.map((item, index) => {
                            if (isMobile && index > 1) {
                              return null; // Skip rendering this item
                            }
                            return (

                              <Box sx={{
                                  width: {lg:'158px',md:'158px',sm:'120px',xs:'104px'},height:'188px', display: 'flex', flexDirection: 'column',
                                  paddingTop: '20px', paddingLeft: '12px', paddingRight: '12px', paddingBottom: '20px', backgroundColor: 'white',
                                  border: '1px solid #CFC9C9', gap: '12px', borderRadius: '8px',marginTop:'12px'
                              }}>
                              <Box sx={{ width: { lg: '134px', md: 168}, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',alignContent:'center',justifyContent:'center'}}>
                                <Typography sx={{ textAlign: 'center', fontSize: {lg:'14px',xs:'12px'}, fontWeight: 400, color: '#626262', fontFamily: 'Poppins' }}>Mortgage {item}</Typography>
                                </Box>

                                <Box sx={{width:'178px',display:'flex',flexDirection:'column',gap:'16px'}}>
                                <Box sx={{ width: { lg: '134px', md: 168, sm: 100, xs: 80 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',backgroundColor:'#F9F9F9'}}>
                                <Typography sx={{
                                  fontSize: '12px', color: '#313131',
                                  textAlign: 'left', fontFamily: 'Poppins',marginBottom:'4px'
                                }}>0</Typography>
                                </Box>
                                <Box sx={{ width: { lg: '134px', md: 168, sm: 100, xs: 80 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography sx={{
                                  fontSize: '12px', color: '#313131',
                                  textAlign: 'left',  fontFamily: 'Poppins',marginBottom:'4px'
                                }}>0</Typography>
                                </Box>
                                <Box sx={{ width: { lg: '134px', md: 168, sm: 100, xs: 80 }, height: '28px', display: 'flex', flexDirection: 'row', alignItems: 'center',backgroundColor:'#F9F9F9'}}>
                                <Typography sx={{
                                  fontSize: '12px', color: '#313131',
                                  textAlign: 'left',   fontFamily: 'Poppins',marginBottom:'4px'
                                }}>0</Typography>
                                </Box>
                                </Box>
                              </Box>
                            );
                          })}
                          </Box>
                        {/* </div> */}
                      </div>
                  </TabPanel>
                  </Box>
              </Box>
              </Box>
             </Box>
          </Box>
          <Box sx={{width: '100%',height: '66px',paddingLeft:'1.875rem',paddingRight:'1.875rem',backgroundColor:'#FFFFFF',
            alignItems: 'center',alignContent:'center',justifyContent: 'center',display:'flex',flexDirection:'row',
            gap: '16px',
  /* border-top: 1px solid #E2E2E2; */
  BoxShadow:' 0px -4px 4px rgba(217, 217, 217, 0.25)'}}>
          <SecondaryButton sx={{
                width: '140px', fontSize: '12px',fontWeight:400,
                 "& .MuiButton-endIcon":{marginLeft:'0px',marginRight:'0px'},
                 "& .MuiButton-root": { paddingLeft: '8px', paddingRight: '8px', gap: '4px' },
              }} startIcon={<DoneIcon sx={{color:'#1F6373'}}/>}>Sent</SecondaryButton>
               <SecondaryButton sx={{width: '140px', fontSize: '12px',fontWeight:400,
                 "& .MuiButton-endIcon":{marginLeft:'0px',marginRight:'0px'},
                 "& .MuiButton-root": { paddingLeft: '8px', paddingRight: '8px', gap: '4px' },
              }} startIcon={<CloseIcon sx={{color:'#1F6373'}}/>}>Reject</SecondaryButton>
               <SecondaryButton sx={{width:'140px', fontSize:'12px',fontWeight:400,
                "& .MuiButton-endIcon":{marginLeft:'0px',marginRight:'0px'},
                 "& .MuiButton-root": { paddingLeft: '8px', paddingRight: '8px', gap: '4px' },
              }} startIcon={<SyncAltIcon sx={{color:'#1F6373'}}/>}>Negotiate</SecondaryButton>
          </Box>
  </div>
)
}
export default UserprofileMob;