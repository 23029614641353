
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import axios from 'axios';
import '../App.css';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CopyrightIcon from '@mui/icons-material/Copyright';

function Footer() {
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const menus = [
        { label: "Universal Calculator", link: "/" },
        { label: "Canada Mortgage", link: "/canada-mortgage " },
        { label: "Nehola", link: "/projects " },
        { label: "Blog", link: "/blog " },     
        { label: "About us", link: "/about-us" },
        { label: "Contact us", link: "/contact-us" }
    ];

    const menuColumn2 = [
        { label: "Disclaimer", link: "/disclaimer" },
        { label: "Privacy policy", link: "/privacy-policy " },
        { label: "Terms of use", link: "/terms-of-use" },
        
    ];

    const menuColumn3 = [
        { label: "Research", link: "/research" },
        { label: "Help", link: "/help" },
        { label: "FAQ", link: "/faq" },
        { label: "Blog", link: "/blog" },
    ];

    const menuColumn4 = [
        { label: "Facebook", link: "https://www.facebook.com/profile.php?id=61564941301494" },
        { label: "X", link: "https://x.com/RateWaves" },
        { label: "Youtube", link: "https://www.youtube.com/channel/UCZIrPVLmS-i6BHcoGvKbvIQ" },
        { label: "LinkedIn", link: "https://www.linkedin.com/company/ratewaves" },
        { label: "Instagram", link: "https://www.instagram.com/" },
        
    ];
   

    return (
        <Grid container sx={{ width: '100%', height: '100%', margin: 0,marginTop:'30px' }}>
             <Box  sx={{display:'flex',flexDirection:'column',width:'100%'
             ,background: '#504FA4', height: '100%', }}>
            <Box sx={{display: { md: 'flex', sm: 'flex', xs:'none' },flexDirection:'row', gap:{ md: '10%', sm: '10px' },
                     width: { md: 'calc(100%)', sm: 'calc(100%)' }, height: '100%',  paddingLeft: { md: '16%', sm: '5%' },
                      paddingRight: { md: '16%', sm: '5%' }, paddingTop:'36px',}}>
               <Box  sx={{display:'flex',flexDirection:'column',gap:'10px', justifyContent: 'flex-start', alignItems: 'flex-start'
               ,width:'100%', minWidth:'auto !important' ,}}>
               {menus.map((menu) => (
                            <Button
                                key={menu.label}
                                component={Link}
                                to={menu.link}
                                sx={{
                                    color: 'white',textTransform: "none", fontFamily: "poppins", fontSize: "16px", lineHeight: "30px",
                                    fontWeight: "400", minWidth:'auto !important' ,                                  
                                }}>
                                {menu.label}
                            </Button>
                        ))}
               </Box> 
               <Box  sx={{display:'flex',flexDirection:'column',gap:'10px',  justifyContent: 'flex-start', alignItems: 'flex-start'
               ,width:'100%', }}>
               {menuColumn2.map((menu) => (
                            <Button
                                key={menu.label}
                                component={Link}
                                to={menu.link}
                                sx={{
                                    color: 'white',textTransform: "none", fontFamily: "poppins", fontSize: "16px", lineHeight: "30px",
                                    fontWeight: "400",                                
                                }}>
                                {menu.label}
                            </Button>
                        ))}
               </Box> 
               {/* <Box  sx={{display:'flex',flexDirection:'column',gap:'10px',  justifyContent: 'flex-start', alignItems: 'flex-start',width:'55%' }}>
               {menuColumn3.map((menu) => (
                            <Button
                                key={menu.label}
                                component={Link}
                                to={menu.link}
                                sx={{
                                    color: 'white',textTransform: "none", fontFamily: "poppins", fontSize: "16px", lineHeight: "30px",
                                    fontWeight: "400",                                   
                                }}>
                                {menu.label}
                            </Button>
                        ))}
               </Box>  */}
               <Box  sx={{display:'flex',flexDirection:'column',gap:'10px',  justifyContent: 'flex-start', alignItems: 'flex-start',
               width:'100%',}}>
               {menuColumn4.map((menu) => (
                            <Button
                                key={menu.label}
                                component={Link}
                                to={menu.link}
                                target="_blank"
                                sx={{
                                    color: 'white',textTransform: "none", fontFamily: "poppins", fontSize: "16px", lineHeight: "30px",
                                    fontWeight: "400",minWidth:'auto'                                   
                                }}>
                                {menu.label}
                            </Button>
                        ))}
               </Box> 
               
            </Box>


            <Box sx={{display: { md: 'none', sm: 'none', xs:'flex' },flexDirection:'column', background: '#504FA4',gap:{ md: '10%', sm: '10px' },
                     width: '100%', height: '100%',  paddingLeft: '5%',
                      paddingRight: '5%', paddingTop:'36px', paddingBottom:'36px'}}>
               <Box  sx={{display:'flex',flexDirection:'column',gap:'10px', justifyContent: 'flex-start', alignItems: 'flex-start'
               ,width:'100%', }}>
               {menus.map((menu) => (
                            <Button
                                key={menu.label}
                                component={Link}
                                to={menu.link}
                                sx={{
                                    color: 'white',textTransform: "none", fontFamily: "poppins", fontSize: "16px", lineHeight: "30px",
                                    fontWeight: "400",                                   
                                }}>
                                {menu.label}
                            </Button>
                        ))}
               </Box> 
               <Box  sx={{display:'flex',flexDirection:'column',gap:'10px',  justifyContent: 'flex-start', alignItems: 'flex-start'
               ,width:'100%', }}>
               {menuColumn2.map((menu) => (
                            <Button
                                key={menu.label}
                                component={Link}
                                to={menu.link}
                                sx={{
                                    color: 'white',textTransform: "none", fontFamily: "poppins", fontSize: "16px", lineHeight: "30px",
                                    fontWeight: "400",                                
                                }}>
                                {menu.label}
                            </Button>
                        ))}
               </Box>               
               <Box  sx={{display:'flex',flexDirection:'column',gap:'10px',  justifyContent: 'flex-start', alignItems: 'flex-start',
               width:'100%', }}>
               {menuColumn4.map((menu) => (
                            <Button
                                key={menu.label}
                                component={Link}
                                to={menu.link}
                                target="_blank"
                                sx={{
                                    color: 'white',textTransform: "none", fontFamily: "poppins", fontSize: "16px", lineHeight: "30px",
                                    fontWeight: "400", minWidth:'auto'                                
                                }}>
                                {menu.label}
                            </Button>
                        ))}
               </Box> 
             
            </Box>
            <Box sx={{display:'flex',flexDirection:'row',gap:'5px', justifyContent: 'center', alignItems: 'center'
               ,width:'100%',padding:'25px' }}>
                <CopyrightIcon sx={{color:'white',fontSize: '9px',lineHeight: '18px', }}></CopyrightIcon>
                <Typography sx={{
              fontFamily: 'poppins', fontSize: '8px',  lineHeight: '18px', fontWeight: '400', color: '#fff', 
            }}>2023-2025</Typography>
 <Typography sx={{
              fontFamily: 'poppins', fontSize: '8px',  lineHeight: '18px', fontWeight: '400', color: '#fff', 
            }}>all rights reserved by</Typography>
          <Link
  to="https://www.varsha-info.com/"
  target="_blank" // Opens the link in a new tab
  rel="noopener noreferrer" // Recommended for security
  style={{
    textDecoration: 'none', // Remove default underline
    color: 'white', fontFamily: 'poppins', fontSize: '8px',  lineHeight: '18px', fontWeight: '400',
    transition: 'text-decoration 0.2s',  textUnderlineOffset: "5px",
  }}
  onMouseEnter={(e) => {
    e.target.style.textDecoration = 'underline'; // Add underline on hover
  }}
  onMouseLeave={(e) => {
    e.target.style.textDecoration = 'none'; // Remove underline on mouse leave
  }}
>
  www.varsha-info.com
</Link>







               </Box>
               </Box> 
        </Grid>
        );

    }
    

    export default Footer;