import React from 'react';
import TextField from '@mui/material/TextField';
import { Box, InputLabel, Stack, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { useState } from "react";
import UserA from './images/Group.png';
function AgentNotificatinMenu() {
    const UserNo = 6611;
    const [markAllfontWeight, setmarkAllfontWeight] = useState(false);

    const handleClickMarkAllRead = () => {    
    
      setmarkAllfontWeight(true);
    };
    const triangleStyle = {
        position: 'absolute',
        top: '-10px',
       
        width: '0',
        height: '0',
        borderTop: '20px solid transparent',
        borderRight: '20px solid transparent',
        borderBottom: '20px solid #f8f8f8',
        transform: 'rotate(135deg)', 
        
      };
    return (
        < Box>

            <Box
                sx={{
                    position: 'absolute',
                    top:{ md: '9.5%', sm: '6%' , xs:'95%'}, 
                    left:{ md: '60%', sm: '33%', xs:'5%' },  
                    width: { md: '360px', sm: '50%' ,xs:'90%' },
                    height: '590px',
                    backgroundColor: '#fff',
                    border: '1px solid #CFC9C9',
                    borderRadius: '8px 0px 8px 8px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                    boxSizing: 'border-box',
                    zIndex: '3',

                }}               
                  
            >
  <Box style={triangleStyle} sx={{ right:{ md: '20px', sm: '15px' , xs:'125px '}}}></Box>

                <Box sx={{
                    display: 'flex', flexDirection: "column",
                    alignItems: "center", borderRadius: "8px", background: ' #FFFFFF', marginLeft: '30px', marginRight: '30px'
                }}>

                    <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', width: '100%', 
                    marginBottom: '20px' ,marginTop:'10px'}}>
                        <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'left', width: '100%' }}>
                            <Typography sx={{
                                color: "#2F2F2F", fontFamily: "poppins", fontSize: "16px", fontWeight: "400", lineHeight: "18px",
                            }}>
                                Notifications</Typography></Box>
                        <Box  sx={{ display: 'flex', flexDirection: "row", justifyContent: 'right', alignItems: 'right', width: '100%' }}>
                        <span onClick={handleClickMarkAllRead}>
       
                            <Typography  style={{
                                color: "#2F2F2F", textAlign: "left", fontFamily: "poppins", fontSize: "12px", lineHeight: "18px",
                                fontWeight: "400", cursor: 'pointer'
                            }}>
                                Mark all as read</Typography>
                                </span>
                        </Box>
                    </Box>
                    <Box>



                        {[...Array(6)].map((data, index) => (
                            <React.Fragment key={index} > <Box sx={{
                                display: 'flex', justifyContent: 'left', alignContent: 'left', flexDirection: 'row', gap: '10px',paddingTop:'7px',
                                marginBottom: '10px','&:hover': { background: "#F9F9F9" }, paddingBottom:'7px'}} >
                                <Box sx={{ display: 'flex', justifyContent: 'left', alignContent: 'left' }}   >
                                    <img src={UserA} alt="" style={{  width: '16px', height: '16px', marginTop: '3px' }} />
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex', alignItems: 'left', justifyContent: 'left', flexDirection: 'column', textAlign: 'left'

                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'poppins', fontSize: '14px', fontWeight:markAllfontWeight? '500':'600', lineHeight: '18px',
                                             color:markAllfontWeight?  '#626262':'#313131',
                                        }}
                                    >
                                       User {UserNo + index} {' '}
                                        <Typography
                                        sx={{
                                            fontFamily: 'poppins', fontSize: '12px',fontWeight:markAllfontWeight? '400':'500' , lineHeight: '18px',
                                            color:markAllfontWeight?  '#626262':'#313131',display:'inline'
                                        }}
                                    >Requested to a quote. Click here to respond</Typography>
                                    </Typography>





                                    <Typography
                                        sx={{
                                            fontFamily: 'poppins', fontSize: '10px', fontWeight: '300', lineHeight: '18px', color: '#313131',
                                        }}
                                    >
                                       Nov 7,2022 at 9.15 AM
                                    </Typography>

                                </Box>


                            </Box>







                            </React.Fragment>
                        ))}

                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <Link href="http://ec2-3-145-140-63.us-east-2.compute.amazonaws.com:3000/agentsnotifications" underline="none" style={{
                            color: "#198CCD", textAlign: "left", fontFamily: "poppins", fontSize: "14px",
                            lineHeight: "18px", fontWeight: "400"
                        }}>
                            {' View all notifications'}
                        </Link>

                    </Box>



                </Box>

            </Box>

        </Box>






    );
}
export default AgentNotificatinMenu;