
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import { Button, Grid, Typography } from "@mui/material";
import logo from './images/logo.png'
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import SignIn from './SignInSignUp'
import '../App.css';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import mailid from './images/contact_mail.png';
import TextField from '@mui/material/TextField';
import Footer from './Footer';
import Header from './HeaderLanding';
import HeaderMobile from './HeaderLandingMobile';
import { API_URL } from "./constants";
import { Helmet } from 'react-helmet-async';
function ContactUs() {

    const [currentPage, setCurrentPage] = useState('Contact us');
    const menus = [
    { label: "Universal Calculator", link: "/" },
    { label: "Canada Mortgage ", link: "/canada-mortgage " },  
    { label: "About us", link: "/about-us" },
    { label: "Contact us", link: "/contact-us" }
  ];
    const [showSignIn, setShowSignIn] = useState(false);

    const handleClickSignIn = () => {
        setShowSignIn(!showSignIn);
    };
    const [showMenu, setShowMenu] = useState(false);

    const handleMenuToggle = () => {
        setShowMenu(!showMenu);
    };

    const [formData, setFormData] = useState({
        name: '',
        mail: '',       
        message: '',
    });
    const handleChangeName = (event) => {
        setFormData({ ...formData, name: event.target.value });
      };
    
    
      const handleChangeEmail = (event) => {
        setFormData({ ...formData, mail: event.target.value });
      };
    
      
      const handleChangeMessage = (event) => {
        setFormData({ ...formData, message: event.target.value });
      };
      
    const handleSubmit = () => {
      
        const name = formData.name;
        const mail = formData.mail;
        const message = formData.message;     
      
        const emailContent = `Name: ${name}\nEmail: ${mail}\nMessage: ${message}`;      
      
        fetch(`${API_URL}/contactUsMail`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
           name,mail,message
          }),
        })
          .then((response) => {
            // Handle the response, show success or error messages, etc.
            console.log('Email sent successfully');
          })
          .catch((error) => {
            console.error('Error sending email:', error);
          });
          setFormData({
            name: '',
            mail: '',
            message: '',
          });  
      };


    return (
        <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>
         <Helmet>
                <title>Mortgage Payment Calculator & Loan Calculator - Plan Your Finances Today</title>
                <meta name="description" content="Loan calculator and mortgage payment calculator by RATEWAVE - Get instant payment estimates and plan your financing with real-time calculations" />
                <link rel="canonical" href="https://ratewaves.com/contact-us" />               
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Mortgage Payment Calculator & Loan Calculator - Plan Your Finances Today",
                        "description": "Loan calculator and mortgage payment calculator by RATEWAVE - Get instant payment estimates and plan your financing with real-time calculations",
                        "url": "https://ratewaves.com/contact-us",
                        "publisher": {
                            "@type": "Organization",
                            "name": "Rate Waves",
                            "logo": {
                                "@type": "ImageObject",
                                "url": logo, // Dynamically links the logo image
                                "width": 250,
                                "height": 50,
                            },
                        },
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://ratewaves.com/contact-us?q={search_term_string}",
                            "query-input": "required name=search_term_string",
                        },
                    })}
                </script>
            </Helmet>
            <h1 style={{ position: 'absolute', left: '-9999px' }}>Loan calculator</h1>

            <Box sx={{
                height: '100% ', width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'flex', sm: 'none', xs: 'none' }
            }}>
        <Header currentPage={currentPage}></Header>
              
            </Box>
            <Box sx={{ display: { md: 'none', sm: 'flex', xs: 'flex' }, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
                <HeaderMobile currentPage={currentPage}></HeaderMobile>
               
            </Box>
            <Box sx={{
                width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)', xs: 'calc(100%-10%)' }, display: 'flex', 
                flexDirection: { md: 'row', sm: 'row', xs: 'column' }, height: '100%',
                paddingLeft: { md: '16%', sm: '5%', xs: '5%' }, paddingRight: { md: '16%', sm: '5%', xs: '5%' },
                 paddingTop: { md: '5%', sm: '5%', xs: '5%' },
               
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: { md: 'column', sm: 'column', xs: 'column' }, width: { md: '100%', sm: '100%', xs:'100%'} , 
                    height: '100%',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection:  { md: 'column', sm: 'column', xs: 'column' } , width:{ md: '100%', sm: '100%', xs:'calc(100%-10%)'} ,

                        height:'100% ',  padding:{ md:'20px', sm: '20px', xs: '15px' } ,
                        alignItems: 'center',justifyContent:'center'
                    }}>
                        <Typography variant="h2" sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>Contact us</Typography>

                        <Typography variant="h3" sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingTop:'10px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>We are appreciating reaching out to us. At Ratewaves, we are passionate about helping you achieve your
                         goals and dreams. Whether you have questions, need guidance, or are ready to embark on your homeownership
                          journey, our team is here to support you every step of the way.

                        </Typography>
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', marginTop:'10%',
                            justifyContent: 'left',  alignItems: 'left',
                        }}>    <Box sx={{
                            display: 'flex', flexDirection: 'row', width: '100%',
                            justifyContent: 'center',  alignItems: 'center',gap:'5px'
                        }}>
                            <img src={mailid} alt="mailicon" style={{textAlign:'center',height:'36px' , width:'36px', alignItems:'center' }} />
                            contact@ratewaves.com
                            </Box>
                         
                        </Box>
                    </Box>
                </Box>

               












               

            </Box>
<Footer></Footer>
        </Grid>
    );

}

export default ContactUs;