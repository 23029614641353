import React from "react";
import UserA from './images/Group.png';
import { Typography } from '@mui/material';


import { useState } from "react";


import Box from '@mui/material/Box';
import DropdownMenuNotification from "./UserNotificationDropDownMobile";

function UserNotificationDetails(props)

{
    const {code} = props;
   const [isHovered, setIsHovered] = useState(false);
   const [isDeleted, setIsDeleted] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
 
    return(
        <div>
       {! isDeleted && ( <Box sx={{display:"flex", flexDirection:"row", backgroundColor: isHovered ? '#F9F9F9' : 'white',
       height: '92px',  border:'1px solid #DCDADA',alignContent: "center", borderRadius:'12px',marginTop:'10px',width: 'calc(100vw - 24px)',
     }} 
        onMouseEnter={handleMouseEnter}
       onMouseLeave={handleMouseLeave}>
        <Box sx={{display:'flex',justifyContent:'center',alignContent:'center',paddingTop:'4%' }}>
       <img src={UserA} alt="img" style={{marginLeft:'10px',width:'16px',height:'16px'}} />
       </Box>
       <Box sx={{display:"flex", flexDirection:"column",alignItems:'left',justifyContent:'center',alignContent:'center',gap:'3px', marginLeft:'5%',
      width: 'calc(100vw - 24px)',
       }}>
       <Box sx={{display:'flex',alignItems:'left',justifyContent:'left',flexDirection:"row",textAlign:'left',width:'calc((100vw - 24px)-60%)' ,}}>
         <Typography sx={{  fontFamily: "poppins", fontSize: "12px", fontWeight: "600",lineHeight:'21px',color:"#313131",
       
         }}>Agent code {code}</Typography> 
         <Box sx={{display:'flex',alignItems:'left',justifyContent:'left',flexDirection:"row",width:'calc((100vw - 24px)-40%)', }}>
           <Typography sx={{  fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'21px',color:"#454545",
        marginLeft:'3px'
         }}> reacted to your quote</Typography> 
      </Box>
      </Box>
       <Box sx={{display:'inline',alignItems:'left',justifyContent:'left',flexDirection:"column", }}>
         <Typography  sx={{  fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'18px',color:"#939393",width:'95%',
         maxHeight:'32px',textOverflow:'ellipsis !important',overflow:'hidden',   display: '-webkit-box', WebkitLineClamp: 2,
         WebkitBoxOrient: 'vertical',
        
         }}>Lorem ipsum dolor sit amet, color emsite amet,eleifend odio. Aenean tell lorem</Typography> 
     
      </Box>
     <Box sx={{display:'flex',alignItems:'left',justifyContent:'left',flexDirection:"column", }}>
         <Typography sx={{  fontFamily: "poppins", fontSize: "10px", fontWeight: "400",lineHeight:'15px',color:"#CFC9C9",
        
         }}>10 mins ago</Typography> 
      </Box> 
      </Box>

   <Box >
      <DropdownMenuNotification isDeleted={isDeleted} setIsDeleted={setIsDeleted} />
   </Box> 
      <Box>
</Box> 
       </Box>) }</div>
       
    );
}
export default UserNotificationDetails;