import React from 'react';
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Button, Typography,Grid } from "@mui/material";
import Crop169Icon from "@mui/icons-material/Crop169";
import notification from "./images/Notification (4).png";
import Messages from "./images/Messages.png";
import Discussion from "./images/Discussion.png";
import Profile from "./images/Rectangle.png";
import { InputLabel } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from "react";
import NotificationDetails from './UserNotificationDetailsMobile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import UserNotificatinMenu from './UserNotificationMenu';
function HeaderUserScreenMobile(){


    const menus = [
        "Calculator",
        "Quotations",
        "Products",
        "About us",
        "Contact us"
      ];
      const [isOpenDrop, setIsOpenDrop] = useState(false);

      const handleMenuToggleDrop = () => {
        setIsOpenDrop(!isOpenDrop);
      };
    
      const handleMenuItemClickDrop = (value) => {
      
        setIsOpenDrop(false);
      }
      const [showMenu, setShowMenu] = useState(false);

      const handleMenuToggleMenu = () => {
        setShowMenu(!showMenu);
      };
    
      const [showNotificationMenu, setShowNotificationMenu] = useState(false);

      const handleClickNotificationImage = () => {
        
        setShowNotificationMenu(!showNotificationMenu);
      };
      
          
          return(
         
            <AppBar position="fixed" sx={{ bgcolor: "#1F6373", height: "56" }}>
    
    
              <Toolbar disableGutters>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center', alignItems: 'center', paddingLeft: '18px' }}>
                  <Crop169Icon
                    sx={{
    
                      height: '28px',
                      width: '44px',
                      backgroundColor: "#fff"
                    }}
                  />
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleMenuToggleMenu}
    
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: showMenu ? "block" : "none", position: "absolute", top: "100%", width: "100vw", zIndex: 1,
                    bgcolor: "#1F6373", justifyContent: 'flex-start', borderRadius: ' 0px 0px 8px 8px',paddingBottom:'20px'
                  }}
                >
                 {menus.map((menu) => (
                    <Button onClick={handleMenuToggleMenu}
                      key={menu}
                      sx={{
                        display: "block", height: '35px', color: "white", textTransform: "capitalize", fontFamily: "poppins",
                        fontSize: "14px", lineHeight: "21px", fontWeight: "400", textAlign: 'left', borderRadius: '0px',
                        '&:hover': { background: "#79A3A8", width: '100%' }
                      }}
                    >
                      {menu}
                    </Button>
                  ))} 
     
    
    
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '12px', marginLeft: 'auto', 
                }}>
                  <img src={Discussion} alt="img" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
                  <img src={Messages} alt="img" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
                
                  <img src={notification}  onClick={handleClickNotificationImage} alt="img" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
                  {showNotificationMenu && <UserNotificatinMenu/>}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', marginLeft: '7%', 
                marginRight: '3%' }}>
                  <img src={Profile} alt="img" style={{width:'24px',height:'24px'}} />
                  <InputLabel style={{
                    fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#fff",
                  }}>John Doe</InputLabel>
                  <Box className="dropdown">
                    <Box className="dropdown__toggle" onClick={handleMenuToggleDrop}>
                      <ExpandMoreIcon style={{ marginTop: '5px',marginLeft:'-5px' }} />
                    </Box>
                    {isOpenDrop && (
                      <Box className="dropdown__menu" sx={{
                        position: 'absolute', right: '5%', fontFamily: 'poppins', fontSize: '12px', fontWeight: '400',
                        lineHeight: '20px', width: '134px', background: '#F8F8F8', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                         zIndex: 1, color: '#2F2F2F', padding: '4px'
                      }}>
                        <style>
                          {`
              .dropdown__menu .MuiMenuItem-root:hover {
                background-color: #1F6373;
                color: white;
              }
            `}
                        </style>
                        <MenuItem onClick={() => handleMenuItemClickDrop("myprofile")} value="option1" style={{
                          fontFamily: 'poppins', fontSize: '12px',
                          fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
                        }}>
                          <PersonIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                          My profile
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuItemClickDrop("signout")} value="option2" style={{
                          fontFamily: 'poppins', fontSize: '12px',
                          fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
                        }}>
                          <LogoutIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                          Sign out
                        </MenuItem>
                      </Box>
                    )}
                  </Box>
    
    
    
                </Box>
    
    
              </Toolbar>
    
    
            </AppBar>
            );
}
export default HeaderUserScreenMobile;