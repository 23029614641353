import React from 'react';
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { Button, Grid } from "@mui/material";
import Crop169Icon from "@mui/icons-material/Crop169";
import notification from "./images/Notification (4).png";
import Messages from "./images/Messages.png";
import Discussion from "./images/Discussion.png";
import Profile from "./images/image 22.png";
import { InputLabel } from '@mui/material';

import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import sendicon from './images/Vector (5).png';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import UserDtls from './AgentLandingUserDetailsMobile';
import GetAppIcon from '@mui/icons-material/GetApp';
import AgentNotificatinMenu from './AgentNotificationMenu';

function AgentLandingMobile() {
  const menus = [
    "Calculator",
    "Quotations",
    "Products",
    "About us",
    "Contact us"
  ];
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggleDropDown = () => {
    setIsOpen(!isOpen);
    ;
  };

  const handleMenuItemClick = (value) => {

    setIsOpen(false);
  }
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const divToRender = <UserDtls></UserDtls>;
  const [isHoveredSendQuote, setIsHoveredSendQuote] = useState(false);

  const handleMouseEnterSendQuote = () => {
    setIsHoveredSendQuote(true);
  };

  const handleMouseLeaveSendQuote = () => {
    setIsHoveredSendQuote(false);
  };


  const [isHoveredSendToMail, setIsHoveredSendToMail] = useState(false);

  const handleMouseEnterSendToMail = () => {
    setIsHoveredSendToMail(true);
  };

  const handleMouseLeaveSendToMail = () => {
    setIsHoveredSendToMail(false);
  };
  const [isHoveredDownloadResult, setIsHoveredDownloadResult] = useState(false);

  const handleMouseEnterDownloadResult = () => {
    setIsHoveredDownloadResult(true);
  };

  const handleMouseLeaveDownloadResult = () => {
    setIsHoveredDownloadResult(false);
  };
  const [showNotificationMenu, setShowNotificationMenu] = useState(false);

  const handleClickNotificationImage = () => {
    
    setShowNotificationMenu(!showNotificationMenu);
  };
  


  return (

    <Grid container sx={{ width: '100%', height: '100%'}}>

      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
        <AppBar position="fixed" sx={{ bgcolor: "#1F6373", height: "56" }}>


          <Toolbar disableGutters>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center', alignItems: 'center', paddingLeft: '18px' }}>
              <Crop169Icon
                sx={{

                  height: '28px',
                  width: '44px',
                  backgroundColor: "#fff"
                }}
              />
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuToggleMenu}

              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: showMenu ? "block" : "none", position: "absolute", top: "100%", width: "100vw", zIndex: 1,
                bgcolor: "#1F6373", justifyContent: 'flex-start', borderRadius: ' 0px 0px 8px 8px',paddingBottom:'20px'
              }}
            >
              {menus.map((menu) => (
                <Button onClick={handleMenuToggleMenu}
                  key={menu}
                  sx={{
                    display: "block", height: '35px', color: "white", textTransform: "capitalize", fontFamily: "poppins",
                    fontSize: "14px", lineHeight: "21px", fontWeight: "400", textAlign: 'left', borderRadius: '0px',
                    '&:hover': { background: "#79A3A8", width: '100%' }
                  }}
                >
                  {menu}
                </Button>
              ))}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '12px', marginLeft: 'auto', 
            marginRight: '16px' }}>
              <img src={Discussion} alt="" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
              <img src={Messages} alt="" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
              <img src={notification}  onClick={handleClickNotificationImage} alt="" style={{ marginBottom: '3px',width:'26px',height:'28px' }} />
              {showNotificationMenu && <AgentNotificatinMenu />}
              <img src={Profile} alt="" style={{width:'24px',height:'24px'}} />
              <InputLabel style={{
                fontFamily: "poppins", fontSize: "12px", fontWeight: "400", lineHeight: '18px', color: "#fff",
              }}>Robert</InputLabel>
              <Box className="dropdown">
                <Box className="dropdown__toggle" onClick={handleMenuToggleDropDown}>
                  <ExpandMoreIcon style={{ marginTop: '5px' }} />
                </Box>
                {isOpen && (
                  <Box className="dropdown__menu" sx={{
                    position: 'absolute', right: '10px', fontFamily: 'poppins', fontSize: '12px', fontWeight: '400',
                    lineHeight: '20px', width: '134px', background: '#F8F8F8', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', zIndex: 1,
                    color: '#2F2F2F', padding: '4px'
                  }}>
                    <style>
                      {`
          .dropdown__menu .MuiMenuItem-root:hover {
            background-color: #1F6373;
            color: white;
          }
        `}
                    </style>
                    <MenuItem onClick={() => handleMenuItemClick("myprofile")} value="option1" style={{
                      fontFamily: 'poppins', fontSize: '12px',
                      fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
                    }}>
                      <PersonIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                      My profile
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick("signout")} value="option2" style={{
                      fontFamily: 'poppins', fontSize: '12px',
                      fontWeight: '400', lineHeight: '20px', minHeight: '10px', borderRadius: '4px'
                    }}>
                      <LogoutIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                      Sign out
                    </MenuItem>
                  </Box>
                )}
              </Box>



            </Box>


          </Toolbar>


        </AppBar>
      </Box>
       <Box
        sx={{
          width:  'calc(100% - 30px)',   display: 'flex', flexDirection: "row", justifyContent: 'center',  alignItems:'center',margin:'15px '
        }}

      >
        <AppBar position="relative" sx={{
          bgcolor: "#1F6373", height: "76px", width: 'calc(100% )', borderRadius: '8px 8px 0px 0px',
          justifyContent: 'center',marginTop:'60px'
        }}>

        </AppBar>
      </Box> 
      <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', marginLeft: '15px', marginRight: '15px',
     width: 'calc(100% - 30px)',marginBottom:'110px' }}>


  <Box>
      {[...Array(10)].map((_, index) => (
        <React.Fragment key={index}>{divToRender}</React.Fragment>
      ))}
    </Box>
    </Box> 
    <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    Height: '80%',
    gap:'7px',
    position: 'fixed',
    left: 0,
    bottom: 0,
   width: 'calc(100% )',
 
 boxShadow:' 0px -4px 4px rgba(217, 217, 217, 0.25)',
   backgroundColor:'white'
  }}
>
<Box
        sx={{
          width: '100%',   display: 'flex', flexDirection: "row", justifyContent: 'center',  alignItems:'center', marginTop:'10px',
         
        }}

      >
        
        <Button variant="contained"  onMouseEnter={handleMouseEnterSendQuote} 
        onMouseLeave={handleMouseLeaveSendQuote}  style={{
            color: "white", textTransform: "none",  padding: "10px 8px 10px 8px ",
            background:  isHoveredSendQuote   ? '#074554' : '#1F6373', border: "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal", 
            lineHeight: "21px", fontWeight: "500", fontSize: "14px", width: "100%", borderRadius: "4px",
          }} startIcon={<img src={sendicon} alt="" />}>
           Send quotes
          </Button>

      
      </Box> 
      <Box
        sx={{
          width: '100%',   display: 'flex', flexDirection: "row", justifyContent: 'center',  alignItems:'center',gap:'2%', 
         
        }}

      >
        <Box
        sx={{   width: '48%',   display: 'flex', flexDirection: "row", justifyContent: 'center',  alignItems:'center',     
        }}  >
        <Button variant="contained" onMouseEnter={handleMouseEnterSendToMail} 
        onMouseLeave={handleMouseLeaveSendToMail}  style={{
          color:isHoveredSendToMail?"white": "#1F6373",textTransform: "none",  padding: "10px 8px 10px 8px ",
            background:isHoveredSendToMail?"#1F6373 ":"#D2E0E3", border: "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal",
             lineHeight: "21px",minWidth:'142px',fontWeight: "500", fontSize: "14px", width: "100%", borderRadius: "4px", marginBottom: '15px'
          }}
            startIcon={<MailOutlineIcon  style={{  color:isHoveredSendToMail?"white": "#1F6373", }}  />}>
            Send to email
          </Button></Box>
          <Box
        sx={{   width: '50%',   display: 'flex', flexDirection: "row", justifyContent: 'center',  alignItems:'center',     
        }}  >
          <Button variant="contained" onMouseEnter={handleMouseEnterDownloadResult} 
        onMouseLeave={handleMouseLeaveDownloadResult}  style={{
          color:isHoveredDownloadResult?"white": "#1F6373", textTransform: "none", padding: "10px 8px 10px 8px ",
            background:isHoveredDownloadResult?"#1F6373 ":"#D2E0E3", border: "1px solid #1F6373", fontFamily: "Poppins", fontStyle: "normal",
             lineHeight: "21px",minWidth:'164px',
            fontWeight: "500", fontSize: "14px", width: "100%", borderRadius: "4px", marginBottom: '15px'
          }}  startIcon={<GetAppIcon style={{  color:isHoveredDownloadResult?"white": "#1F6373", }} />}>
            Download result
          </Button>
          </Box> 
      
      </Box> 
  
     


 
          
</Box>
      
    </Grid>

  );
}
export default AgentLandingMobile;