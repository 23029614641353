import { Box } from '@mui/material'
import React from 'react'
import AgentNotification from '../components/AgentNotification'
import AgentNotificationMobile from '../components/AgentNotificationMobile'


function AgentsNotification() {
  return (
    <div>
     

      <Box sx={{display:{md:'block',xs:'none',sm:'block'}}}>

       <AgentNotification>        
       </AgentNotification>
      </Box>

      <Box sx={{display:{md:'none',xs:'block',sm:'none'}}}>

<AgentNotificationMobile>
</AgentNotificationMobile>

</Box>
    </div>
  )
}

export default AgentsNotification
