import { Avatar, Button, Fade, Menu, MenuItem, MenuList, Tab, Tabs, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Box } from '@mui/system'
import React, { useState } from 'react'
import Footer from './footer-modified'

import Header1 from './header1-modified';
import RateandcommentMob from './rateandcommentmob';
import rmsg from './images/rmsg.png';
import star from './images/star.png';
import Header2 from './header2-modified';
import Header1Mod from './header1-modified';
// import Acceptmodal from './Acceptmodal';
import FooterMod from './footer-modified';
function Receivedqtn1() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [widtha, setWidtha] = useState(90);
  const [showModal,setShowModal] = useState(false);
  const [i, setI] = useState([1, 2, 3, 4, 5]);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function showModalHandler(){
    setShowModal(!showModal);
}


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ paddingTop: '8px' }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <div style={{ overflow: 'hidden' }}>
      <Header1Mod />
      <Box sx={{ height: 'calc(100vh - 70px)', overflowY: 'auto', width: '1366px', paddingLeft: {lg:'172px',sm:'30px'}, paddingRight: {lg:'172px',sm:'30px'},paddingTop:'10px' }}>
        {(value === 0) ?
          (<Typography sx={{ fontWeight: 500, fontSize: '20px', color: '#313131', fontFamily: 'Poppins' }}>Received quotations</Typography>) :
          (value === 1) ?
            (<Typography sx={{ fontWeight: 500, fontSize: '20px', color: '#313131', fontFamily: 'Poppins' }}>Accepted quotations</Typography>) :
            (value === 2) ? (<Typography sx={{ fontWeight: 700, fontSize: '20px', color: '#313131', fontFamily: 'Poppins' }}>Rejected quotations</Typography>) :
              <Typography sx={{ fontWeight: 500, fontSize: '20px', color: '#313131', fontFamily: 'Poppins' }}>Negotiated quotations</Typography>
        }
        <Box sx={{
          width:{lg:'1024px',md:'1024px',sm:'54%'}, height: '60px', color: 'white', display: 'flex', flexDirection: 'row',
          alignItems: 'center', justifyContent: 'space-between', marginTop: '10px', backgroundColor: '#1F6373',
          borderRadius: '8px 8px 0px 0px', paddingRight: '10px',paddingLeft:'10px',
        }}>
          <div>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
              TabIndicatorProps={{ style: { background: 'white' } }}
              sx={{
                color: 'white',
                " .MuiTabs-flexContainer": {
                  minHeight: 0, alignItems: 'center',gap:'20px',
                  "& .Mui-selected": { color: 'white', borderColor: 'white', fontWeight: 500 }, "& .MuiTabs-indicator": { backgroundColor: 'white' },
                },
              }}
            >
              <Tab label="All Quotations" sx={{ fontSize: '14px', textTransform: 'none', fontFamily: 'Poppins', color: 'white', fontWeight: 400 }} {...a11yProps(0)} />
              <Tab label="Accepted" sx={{ fontSize: '14px', textTransform: 'none', fontFamily: 'Poppins', color: 'white', fontWeight: 400 }} {...a11yProps(1)} />
              <Tab label="Rejected" sx={{ fontSize: '14px', textTransform: 'none', fontFamily: 'Poppins', color: 'white', fontWeight: 400 }} {...a11yProps(1)} />
              <Tab label="Negotiated" sx={{ fontSize: '14px', textTransform: 'none', fontFamily: 'Poppins', color: 'white', fontWeight: 400 }} {...a11yProps(1)} />
            </Tabs>
          </div>
          <div>
            <Button sx={{
              width: '100px', height: '36px', fontSize: '12px',
              textTransform: 'none', backgroundColor: '#1F6373', color: 'white', fontSize: '14px', alignItems: 'center', fontFamily: 'Poppins',
              fontWeight: 400, border: '1px solid #1F6373',
              // '&:hover': {
              //   backgroundColor: '#D2E0E3',
              //   color: '#1F6373',
              //   border: '1px solid #1F6373',
              // },

              '&:active': {

                backgroundColor: '#1F6373',
                color: 'white',
              },
            }}
            id="sort"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon sx={{
                color: 'white',
                '&:hover': {

                  color: '#1F6373',

                },
              }} />}
            >Sort by</Button>
            <Menu
        id="fade-menu1"
        MenuListProps={{
          'aria-labelledby': 'vie',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        
        sx={{"& .MuiMenu-paper": {backgroundColor:'#F8F8F8' },}}
      >
        <MenuList sx={{ width: '134px',height:'66px', bgcolor:'#F8F8F8',borderRadius:'8px',paddingLeft:'4px',paddingRight:'4px'}}>
        <MenuItem sx={{paddingTop:0,paddingBottom:0,marginBottom:'4px',borderRadius:'4px',display:'flex',alignItems:'center', height:'24px','&:hover': {backgroundColor:'#1F6373',color:'white'}}} onClick={handleClose}
              key={value}>
               <Typography sx={{fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>By date</Typography> 
              </MenuItem>
              <MenuItem sx={{paddingTop:0,paddingBottom:0, borderRadius:'4px',display:'flex',alignItems:'center',height:'24px','&:hover': {backgroundColor:'#1F6373',color:'white'}}}
              key={value}>
                <Typography sx={{fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>By time</Typography>
              </MenuItem>   
              
        </MenuList>
      </Menu>
          </div>

        </Box>
        <TabPanel value={value} index={0} sx={{
          "& .MuiTabPanel-root": { padding: '0px' },
          "& .MuiBox-root": { padding: '0px' },
        }}>
          {i.map((item, index) => {
            return (
          <Box sx={{
            width: {lg:'1024px',md:'1024px',sm:'54%'}, height: '124px', color: 'white', display: 'flex', flexDirection: 'row',
            alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white', border: '1px solid #dcdada',
            borderRadius: '12px', paddingLeft: '10px', paddingRight: '12px', marginBottom: '8px',gap:'10px',
            '&:hover': {
              backgroundColor: '#F9F9F9',
             },
          }}>
            
            <AgentBox />
            
            <MortgageBox />
            <div style={{ width: '38%', height: '96px', display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center', gap: '4px'}}>
              <div style={{ width: '288px', display: 'flex', flexDirection: 'row', gap: '23px', paddingLeft: '10px',paddingRight:'12px', alignItems: 'center', height: '38px',justifyContent:'space-between' }}>
                <Typography sx={{ color: '#212427', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Mortgage 1</Typography>
                <Typography sx={{ color: '#212427', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400 }}>Today</Typography>
              </div>
             
              <Buttons />
            </div>
            
          </Box>
            );
          })}

         
        </TabPanel>
        <TabPanel value={value} index={1} sx={{
          "& .MuiTabPanel-root": { padding: '0px' },
          "& .MuiBox-root": { padding: '0px' },
        }}>
          {i.map((item, index) => {
            return (
          <Box sx={{
            width:{lg:'1024px',md:'1024px',sm:'54%'}, height: '124px', color: 'white', display: 'flex', flexDirection: 'row',
            alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white', border: '1px solid #dcdada',
            borderRadius: '12px', paddingLeft: '10px', paddingRight: '12px', marginBottom: '8px',gap:'10px',
            '&:hover': {
              backgroundColor: '#F9F9F9',
             },
          }}>
            <AgentBox />
            <MortgageBox />
            <div style={{ width: '38%', height: '96px', display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center', gap: '4px'}}>
              <div style={{ width: '288px', display: 'flex', flexDirection: 'row', gap: '23px', paddingLeft: '10px',paddingRight:'12px', alignItems: 'center', height: '38px',justifyContent:'space-between' }}>
                <Typography sx={{ color: '#212427', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Mortgage 1</Typography>
                <Typography sx={{ color: '#212427', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400 }}>Today</Typography>
              </div>
              <StateButtons mode="Accepted"/>
              </div>
          </Box>
          );
        })}
        </TabPanel>
        <TabPanel value={value} index={2} sx={{
          "& .MuiTabPanel-root": { padding: '0px' },
          "& .MuiBox-root": { padding: '0px' },
        }}>
           {i.map((item, index) => {
            return (
          <Box sx={{
            width:{lg:'1024px',md:'1024px',sm:'54%'}, height: '124px', color: 'white', display: 'flex', flexDirection: 'row',
            alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white', border: '1px solid #dcdada',
            borderRadius: '12px', paddingLeft: {lg:'10px',sm:'8px'}, paddingRight: {lg:'12px',sm:'8px'}, marginBottom: '8px',gap:'10px',
            '&:hover': {
              backgroundColor: '#F9F9F9',
             },
          }}>
            <AgentBox />
            <MortgageBox />
            <div style={{ width: '38%', height: '96px', display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center', gap: '4px'}}>
              <div style={{ width: '288px', display: 'flex', flexDirection: 'row', gap: '23px', paddingLeft: '10px',paddingRight:'12px', alignItems: 'center', height: '38px',justifyContent:'space-between' }}>
                <Typography sx={{ color: '#212427', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Mortgage 1</Typography>
                <Typography sx={{ color: '#212427', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400 }}>Today</Typography>
              </div>
              <StateButtons mode="Rejected"/>
              </div>
          </Box>
          );
        })}
          
        </TabPanel>
        <TabPanel value={value} index={3} sx={{
          "& .MuiTabPanel-root": { padding: '0px' },
          "& .MuiBox-root": { padding: '0px' },
        }}>

{i.map((item, index) => {
            return (
          <Box sx={{
            width:{lg:'1024px',md:'1024px',sm:'54%'}, height: '124px', color: 'white', display: 'flex', flexDirection: 'row',
            alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white', border: '1px solid #dcdada',
            borderRadius: '12px', paddingLeft: '10px', paddingRight: '12px', marginBottom: '8px',gap:'10px',
            '&:hover': {
              backgroundColor: '#F9F9F9',
             },
          }}>
            <AgentBox />
            <MortgageBox />
            <div style={{ width: '38%', height: '96px', display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center', gap: '4px'}}>
              <div style={{ width: '288px', display: 'flex', flexDirection: 'row', gap: '23px', paddingLeft: '10px',paddingRight:'12px', alignItems: 'center', height: '38px',justifyContent:'space-between' }}>
                <Typography sx={{ color: '#212427', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Mortgage 1</Typography>
                <Typography sx={{ color: '#212427', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 400 }}>Today</Typography>
              </div>
              <StateButtons mode="Negotiated"/>
              </div>
          </Box>
          );
        })}
        </TabPanel>
        
      </Box>
      <FooterMod />
    </div>
  )
}
function Buttons() 
{
  const [selected,setSelected] =useState();
  
  const isAccept = selected=="Accepted";
  const isReject = selected=="Rejected";
  const isNegotiate = selected=="Negotiated";
 
  return(
    <div style={{ width: '288px', display: 'flex', flexDirection: 'row', gap: '6px', alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
      
       <Button endIcon = {isAccept?<DoneIcon />:null} sx={{
                  height: '32px', width: isAccept?'108px':'90px', fontSize: '12px', textTransform: 'none', border:isAccept?'1px solid #76DE24': '1px solid #212427', backgroundColor:isAccept?'#76DE24':'#FFFFFF', borderRadius: '8px', color:isAccept?'white':'#212427',
                  '&:hover': {
                    backgroundColor: '#76DE24',
                    color: 'white',

                  },fontFamily: 'Poppins',fontWeight:400,
                }} onClick={()=> setSelected("Accepted")} >{isAccept?'Accepted':'Accept'}</Button>
                
                <Button endIcon = {isReject? <CloseIcon sx={{ width: '16px', height: '16px' }}/>:null} sx={{
                  height: '32px', width: isReject?'108px':'90px', fontSize: '12px', textTransform: 'none', border:isReject?'1px solid #EB5648':'1px solid #212427', backgroundColor: isReject?'#EB5648':'#FFFFFF', borderRadius: '8px', color:isReject?'white':'#212427', '&:hover': {
                    backgroundColor: '#EB5648',
                    color: 'white',

                  },fontFamily: 'Poppins',fontWeight:400,
                }} onClick={()=> setSelected("Rejected")}>{isReject?'Rejected':'Reject'}</Button>
                <Button endIcon={isNegotiate?<SyncAltIcon sx={{ width: '12px', height: '12px' }} />:null} sx={{
                  height: '32px', width: isNegotiate?'108px':'90px', fontSize: '12px', textTransform: 'none', border: isNegotiate?'1px solid #1F6373':'1px solid #212427', backgroundColor:isNegotiate?'#1F6373':'#FFFFFF', borderRadius: '8px', color: isNegotiate?'white':'#212427', '&:hover': {
                    backgroundColor: '#1F6373',
                    color: 'white',

                  },fontFamily: 'Poppins',fontWeight:400,
                }} onClick={()=> setSelected("Negotiated")}>{isNegotiate?'Negotiated':'Negotiate'}</Button>
         
      
    </div>
  );
}

function StateButtons({mode}){
  return(
  
   <Box sx={{ width: {lg:'288px',md:'288px',sm:'260px'}, display: 'flex', flexDirection: 'row', gap: '6px', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
    <Button endIcon = {mode=="Accepted"?<DoneIcon />:null} sx={{
                  height: '32px', width: mode=="Accepted"?'108px':'90px', fontSize: '12px', textTransform: 'none', border:mode==='Accepted'?'1px solid #76DE24': '1px solid #212427', backgroundColor:mode=="Accepted"?'#76DE24':'#FFFFFF', borderRadius: '8px', color:mode=='Accepted'?'white':'#212427',
                  '&:hover': {
                    backgroundColor: '#76DE24',
                    color: 'white',

                  },fontFamily: 'Poppins',fontWeight:400,
                }}  >{mode=="Accepted"?'Accepted':'Accept'}</Button>
                <Button endIcon = {mode=='Rejected'? <CloseIcon sx={{ width: '16px', height: '16px' }}/>:null} sx={{
                  height: '32px', width: mode=='Rejected'?'108px':'90px', fontSize: '12px', textTransform: 'none', border:mode=='Rejected'?'1px solid #EB5648':'1px solid #212427', backgroundColor: mode=='Rejected'?'#EB5648':'#FFFFFF', borderRadius: '8px', color:mode=='Rejected'?'white':'#212427', '&:hover': {
                    backgroundColor: '#EB5648',
                    color: 'white',

                  },fontFamily: 'Poppins',fontWeight:400,
                }} >{mode=='Rejected'?'Rejected':'Reject'}</Button>
                <Button endIcon={mode=='Negotiated'?<SyncAltIcon sx={{ width: '12px', height: '12px' }} />:null} sx={{
                  height: '32px', width: mode=='Negotiated'?'108px':'90px', fontSize: '12px', textTransform: 'none', border: mode=='Negotiated'?'1px solid #1F6373':'1px solid #212427', backgroundColor:mode=='Negotiated'?'#1F6373':'#FFFFFF', borderRadius: '8px', color: mode=='Negotiated'?'white':'#212427', '&:hover': {
                    backgroundColor: '#1F6373',
                    color: 'white',

                  },fontFamily: 'Poppins',fontWeight:400,
                }} >{mode=='Negotiated'?'Negotiated':'Negotiate'}</Button>
          </Box>  
                 
   ) ;
  
}
function AgentBox()
{
  const [showModal,setShowModal] = useState(false);
  function showModalHandler(){
    setShowModal(!showModal);
}

  return(
    <div>
    <Box sx={{  width: '90px',height:'110px',backgroundColor:'#F9F9F9',borderRadius:'12px', display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center',paddingBottom:'6px',
            '&:hover': {
              border: '1px solid #1F6373',
              BoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }, }} onClick={showModalHandler}>
              <Avatar sx={{ color: '#313131', backgroundColor: '#CFC9C9', width: '34px', height: '34px', fontSize: '20px', fontFamily: 'Poppins', fontWeight: 400 ,marginBottom:'8px'}}>A</Avatar>
              <Typography sx={{ color: '#212427', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Agent code</Typography>
              <Typography sx={{ color: '#212427', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500 }}>6601</Typography>
              <Box sx={{width:'46px',height:'16px', display:'flex',flexDirection:'row',alignItems:'center'}} >
                <Box sx={{width:'12px',height:'10px'}}><img src={rmsg} /></Box>
                <Typography sx={{ color: '#1F6373', fontSize: '10px', fontFamily: 'Poppins', fontWeight: 500,marginTop:'12px',marginLeft:'8px' }}>4.5</Typography>
                <Box sx={{width:'12px',height:'16px'}}><img src={star} style={{marginLeft:'2px'}} /></Box>
              </Box>
            </Box>
            {showModal ? <RateandcommentMob />: null }
            </div>
  );
}
function MortgageBox()
{
  return(
    <Box sx={{ width: {lg:'600px',md:'600px',sm:'46%'},height:'82px', borderLeft: '1px solid #D2D2D2',borderRight:'1px solid #D2D2D2', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: {lg:'22px',md:'22px',sm:'0px'}, paddingLeft: {lg:'12px',sm:'4px'}, paddingRight: {lg:'12px',sm:'6px'} }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center', gap: '20px' }}>
                <Typography sx={{ color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Monthly payment</Typography>
                <Typography sx={{ color: '#212427', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>$25,000</Typography>
              </Box>
              <Box sx={{ display: {lg:'flex',md:'flex',sm:'none'}, flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center', gap: '20px' }}>
                <Typography sx={{ color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Interest rate(%)</Typography>
                <Typography sx={{ color: '#212427', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>4.5%</Typography>
              </Box>
              <Box sx={{ display: {lg:'none',md:'none',sm:'flex'}, flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center', gap: '20px' }}>
                <Typography sx={{ color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Amount</Typography>
                <Typography sx={{ color: '#212427', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>$425,0000</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center', gap: '20px' }}>
                <Typography sx={{ color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Type</Typography>
                <Typography sx={{ color: '#212427', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Variable</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center', gap: '20px' }}>
                <Typography sx={{ color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Amortization</Typography>
                <Typography sx={{ color: '#212427', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>25 Years</Typography>
              </Box>
              <Box sx={{ display: {lg:'flex',md:'flex',sm:'none'}, flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center', gap: '20px' }}>
                <Typography sx={{ color: '#626262', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>Term</Typography>
                <Typography sx={{ color: '#212427', fontSize: '12px', fontFamily: 'Poppins', fontWeight: 400 }}>2 Months</Typography>
              </Box>
            </Box>
  );
}
export default Receivedqtn1