import React, { useRef } from 'react';

import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Box, InputLabel, Stack, Typography } from '@mui/material';
import { useState , useEffect  } from 'react';
import DeleteIcon from './images/Component 4.png';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';
import axios from 'axios';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import '../App.css';
import Tooltip from '@mui/material/Tooltip';
function debounce(func, delay) {
 
  let timerId;

  return function (...args) {
   
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

function Timer({ duration }) {
  const [seconds, setSeconds] = useState(duration);

  useEffect(() => {
    if (seconds > 0) {
      const timerInterval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1); // Decrement the seconds
      }, 1000); // Update the interval to 1 second

      return () => {
        clearInterval(timerInterval); // Clear the interval when the component unmounts or when seconds reach 0
      };
    }
  }, [seconds, duration]); // Add duration to the dependency array

  return (
    <span style={{ fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400' }}>
      {seconds > 0 ? `${seconds}S` : ''}
    </span>
  );
}
function formatValue(value) {
  const parsedValue = parseFloat(value);
  if (!isNaN(parsedValue)) {
    if (Number.isInteger(parsedValue)) {
      return parsedValue.toString(); // No fractional part, return as integer
    } else {
    //  const roundedValue = Math.round(parsedValue * 100) / 100; // Round to two decimal places
      return parsedValue.toFixed(2); // Format as string with two decimal places
    }
  } else {
    return 'Invalid input';
  }
}

function DivcomponenetUserMobile(props) {

  const {  dataList, setDataList, mortgageName, CheckboxEnabled, isDeleteButtonEnabled,askingPriceValue,handleChangeTerm,handleChangeInitialPayment,
    currentSlideIndex, setCurrentSlideIndex, setCheckboxSelectedList, selectedCheckboxList, isSelected,setIsSelected,handleChangeFreequency,setTermVal,TermVal,
    handleChangeInterest,handleChangePeriod,handleChangeAskingprice, result,setResult, FreqParam,setFreqParam,amortznPeriod,setAmortznPeriod,setAskingPrice, askingprice,
    handleChangeMortgageAmount,mortgageAmountTextValue,setMortgageAmountTextValue,askingPriceVariableParent,setAskingPriceVariableParent,
    handleChangeDownPaymentPercentageParent,handleChangeDownPaymentAmountParent,insuranceTextValue, setInsuranceTextValue,mortgageAmountWithInsuranceValue,
     setMortgageAmountWithInsuranceValue,handleOnchangeAskingPrice,handleChangeAskingpriceWithInsurance ,downBorderAmount, setdownBorderAmount,
     downBorderPercentage, setdownBorderPercentage} = props;

     const index=dataList.findIndex(x => x.name ===mortgageName);
     const [showpopupTimerAmount, setShowPopupTimerAmount] = useState(false);
     const [showpopupTimerPercentage, setShowPopupTimerPercentage] = useState(false);
     const waitingDuration = 1; // in seconds
     const [showTimer, setShowTimer] = useState(false);
     const [popupTimer, setPopupTimer] = useState(null);

     const handleAmountValueChange = (event, index) => {
      // Clear the previous popup timer, if any
      clearTimeout(popupTimer);
  
      // Show the popup
      setShowPopupTimerAmount(true);
  
      // Set a new timer to hide the popup after 5 seconds
      const newPopupTimer = setTimeout(() => {
        setShowPopupTimerAmount(false);
        // Call the handleChangeDownPaymentAmount function after the timer expires
        handleChangeDownPaymentAmount(event, index);
      }, 1000);
  
      // Store the timer ID
      setPopupTimer(newPopupTimer);
    };
    const handlePercentageValueChange = (event, index) => {
      // Clear the previous popup timer, if any
      clearTimeout(popupTimer);
  
      // Show the popup
      setShowPopupTimerPercentage(true);
  
      // Set a new timer to hide the popup after 5 seconds
      const newPopupTimer = setTimeout(() => {
        setShowPopupTimerPercentage(false);
        // Call the handleChangeDownPaymentAmount function after the timer expires
        handleChangeDownPaymentPercentage(event, index);
      }, 1000);
  
      // Store the timer ID
      setPopupTimer(newPopupTimer);
    };
   
    const [isTooltipOpenPercentage, setTooltipOpenPercentage] = useState(false);

    const handleTooltipOpenPercentage = () => {
      setTooltipOpenPercentage(true);
    };
  
    const handleTooltipClosePercentage = () => {
      setTooltipOpenPercentage(false);
    };

    const [isTooltipOpenAmount, setTooltipOpenAmount] = useState(false);

    const handleTooltipOpenAmount = () => {
      setTooltipOpenAmount(true);
    };
  
    const handleTooltipCloseAmount = () => {
      setTooltipOpenAmount(false);
    };
    const [selectedOptionVariable, setSelectedOptionVariable] = useState(); //AmortizationPeriod Used this
    const [selectedOptionMonths, setSelectedOptionMonths] = useState( );
    const [selectedOptionMonthly, setSelectedOptionMonthly] = useState();
    const [showPopuptermamort, setShowPopuptermamort] = useState(false);
   // const [showPopupDownPer20, setShowPopupDownPer20] = useState(false);
    const [showPopupDownPer5, setShowPopupDownPer5] = useState(false);
    const [showPopupDownPer, setShowPopupDownPer] = useState(false);
    const [tooltipTitle, setTooltipTitle] = useState();

  const IsDeleteButton = dataList.length > 2 && isDeleteButtonEnabled;


  const handleCheckboxClickAddMortgage = (mortgageName) => {

    const temp = [...selectedCheckboxList];
    if (temp.includes(mortgageName)) {
      temp.splice(temp.indexOf(mortgageName), 1);
    }
    else {
      temp.push(mortgageName);
    }
    setCheckboxSelectedList(temp);

  };


  const [showPopup, setShowPopup] = useState(false);


  const handleDeleteClick = (mortgageName) => {
    setShowPopup(true);
  };

  const handleDeleteConfirm = (name) => {
    const temp = dataList.filter(dataList => dataList.name !== mortgageName);
    setDataList(temp);
    setShowPopup(false);
    dataList.length = (dataList.length - 1);
    

    if (currentSlideIndex === 4) {
      setCurrentSlideIndex(currentSlideIndex - 0);
    } else {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
    
   
  };
  console.log(currentSlideIndex)

  const handleCancel = () => {
    setShowPopup(false);
   
  };

  // const handleCancelDownPer20 = () => {
  //   setShowPopupDownPer20(false);
  //   dataList[index].DownPaymentPercentage =20;   
  //   dataList[index].DownPaymentAmount= minamnt20;
    
   
  // };
  const handleCancelTerm = () => {
    setShowPopuptermamort(false);
  };
  // const handleCancelDownPer5 = () => {
  //   setShowPopupDownPer5(false);
  //   dataList[index].DownPaymentPercentage=5;
  //   dataList[index].DownPaymentAmount=minamnt5;
  
    
  // };
  // const handleCancelDownPer = () => {
  //   setShowPopupDownPer(false);
  //   dataList[index].DownPaymentPercentage=minper;
  //   dataList[index].DownPaymentAmount=minamnt;
   
  // };
  // const handleOptionChangeAmortization = (event,index) => {
 
  //   var tempdataList=[...dataList];
  //   tempdataList[index].period=(event.target.value)  ;
  //   setDataList(tempdataList);
  //   setSelectedOptionVariable(event.target.value);
  //  // setAmortznPeriod(event.target.value);
  //   if (event.target.value !== '' && event.target.value <= TermVal[index] ) {
  //     setShowPopuptermamort(true);
  //   } 
    
  // };
  const handleOptionChangeAmortization = (event, index) => {
  // Check if dataList is defined and index is valid
  if (dataList && Array.isArray(dataList) && index >= 0 && index < dataList.length) {
    var tempdataList = [...dataList];
    tempdataList[index].period = event.target.value;
    setDataList(tempdataList);
    setSelectedOptionVariable(event.target.value);

    // Check if TermVal is defined and the value is valid
    if (event.target.value !== '' && TermVal && TermVal[index] && event.target.value <= TermVal[index]) {
      setShowPopuptermamort(true);
    }
  } else {
    // Handle invalid index or undefined dataList
    console.error('Invalid index or undefined dataList.');
  }
};


  const handleOptionChangePaymentFreequency = (event,index) => { 
   
    var tempdataList=[...dataList]
    tempdataList[index].frequency=(event.target.value)  ;
    setDataList(tempdataList);
    setSelectedOptionMonthly(event.target.value);
    
   // setFreqParam(event.target.value);
   
    
  };
  const handleOptionChangeTerm = (event,index) => {
 
    var tempdataList=[...dataList]
    tempdataList[index].term=parseInt(event.target.value)  ;
    setDataList(tempdataList);
   // setSelectedOptionMonths(event.target.value);
   // setTermVal(event.target.value);
   
    if (event.target.value !== '' && dataList[index].period <= event.target.value ) {
      setShowPopuptermamort(true);
    
    } 
   
  };


const [downPaymentPerTextValue, setDownPaymentPerTextValue] = useState(); 
const [downPaymentAmountTextValue, setDownPaymentAmountTextValue] = useState(); 

 const [askingPriceTextValue, setAskingPriceTextValue] = useState([]);
 const [mortValValue, setMortValValue] = useState(800000);
 const [interestrateValue, setInterestrateValue] = useState(3);
 const [minamnt, setMinamnt] = useState();
 const [minamnt5, setMinamnt5] = useState();
 const [minamnt20, setMinamnt20] = useState();
 const [minper, setMinper] = useState();

 
 useEffect(() => {
  var tempdataList=[...dataList]
  tempdataList[index].askingprice=askingPriceValue  ; 
  tempdataList[index].DownPaymentAmount=askingPriceValue*(dataList.DownPaymentPercentage/100)
  setDataList(tempdataList); 
 (handleChangeAskingPriceParentText)(askingPriceValue,index)


}, [askingPriceValue]);
  

const handleChangeAskingPriceParentText =(askingPriceValue,index) => { 
  dataList[index].downBorderPercentage=(false);
    dataList[index].downBorderAmount=(false);
  var tempdataList=[...dataList]
  tempdataList[index].askingprice=parseInt(askingPriceValue)  ;   
  setDataList(tempdataList);
  let AmountValue1 =  dataList[index].DownPaymentAmount;
  const percentageValue =dataList[index].DownPaymentPercentage;
  if (askingPriceValue>= 1000000) {
     var percentage20=percentageValue;       
    if  (percentageValue < 20  ){
      const dwnamnt20=(dataList[index].askingprice*(20/100));
      setMinamnt20(dwnamnt20);
      AmountValue1=(dataList[index].askingprice*(20/100)).toFixed(2); 
        dataList[index].downBorderPercentage = true;
        dataList[index].downBorderAmount = true;
        setTooltipTitle(" The minimum down payment is 20% or " + dwnamnt20)
    //  setShowPopupDownPer20(true);
      handleChangeDownPaymentPercentageParent(20, index);
    
    } else {
     // setShowPopupDownPer20(false);
      setDownPaymentPerTextValue((percentageValue));
      var amount20=(dataList[index].askingprice*(percentage20/100));
      document.getElementById("Amount_"+index ).value=(amount20);
      handleChangeDownPaymentPercentageParent(percentageValue, index);
    }
  } 
  if (dataList[index].askingprice < 500000) {
    var percentage5=percentageValue;
    if  (percentageValue < 5 ){
      let dwnamnt5=(dataList[index].askingprice*(percentage5/100));
      setMinamnt5(dwnamnt5)       
      AmountValue1=(dataList[index].askingprice*(5/100)).toFixed(2)    
      dataList[index].downBorderPercentage = true;
      dataList[index].downBorderAmount = true;
       setTooltipTitle(" The minimum down payment is 5% or " + dwnamnt5)
     
      handleChangeDownPaymentPercentageParent(5, index);
      
    } else {
      setShowPopupDownPer5(false);
      setDownPaymentPerTextValue((percentageValue));
      var amount5=(dataList[index].askingprice*(percentage5/100));
      document.getElementById("Amount_"+index ).value=(amount5);
      handleChangeDownPaymentPercentageParent(percentageValue, index);
    }
  } 
  if ((dataList[index].askingprice > 500000 && dataList[index].askingprice < 1000000)) {
    var percentage=percentageValue;
    if  (percentageValue < (((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100){
      setMinper(((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2));
      var x=((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)         
      let mnamt=(dataList[index].askingprice * x)/100
      setMinamnt(mnamt);
      var DwnPer=((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)        
      var dwnamt=(500000*.05) + ((dataList[index].askingprice -500000)*(10/100))
       AmountValue1=(500000*.05) + ((dataList[index].askingprice -500000)*(10/100)).toFixed(2)
   dataList[index].downBorderPercentage = true;
   dataList[index].downBorderAmount = true;
    setTooltipTitle(" The minimum down payment is " + DwnPer + " or " + dwnamt)
     
      handleChangeDownPaymentPercentageParent((((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)), index);
    } else {
      setShowPopupDownPer(false);
      setDownPaymentPerTextValue(percentageValue);
      var amount=(dataList[index].askingprice*(percentageValue/100));
      document.getElementById("Amount_"+index ).value=(amount);
      handleChangeDownPaymentPercentageParent(percentageValue, index);
    }
  } 
  var mortamount=((dataList[index].askingprice)-(dataList[index].askingprice*(percentage/100)));   
  // console.log("mortamount",mortamount)
  // console.log("askingPriceTextValue",parseInt(dataList[index].askingprice*(percentage/100)))
  // console.log("downPaymentAmountTextValue",parseInt(downPaymentAmountTextValue))
  var mortgagewithhdmc= mortamount + insuranceTextValue;
  var tempdataList=[...dataList]
  tempdataList[index].mortgageamount=(dataList[index].askingprice - (dataList[index].askingprice*(percentageValue/100)))  + dataList[index].cmhc  ;   
  tempdataList[index].DownPaymentAmount = document.getElementById("Amount_"+index ).value;
tempdataList[index].DownPaymentPercentage =percentageValue;
  setDataList(tempdataList);
  console.log("mortgagewithhdmc.....",dataList)
   setMortValValue(mortgagewithhdmc);
   setMortgageAmountTextValue(mortValValue)

 }


  const handleChangeAskingPriceText =(event,index) => { 
    dataList[index].downBorderPercentage=(false);
    dataList[index].downBorderAmount=(false);
    var tempdataList=[...dataList]
    tempdataList[index].askingprice=event.target.value  ;   
    setDataList(tempdataList);
    let AmountValue1 =  dataList[index].DownPaymentAmount;
    const percentageValue = dataList[index].DownPaymentPercentage;
    if (event.target.value >= 1000000) {
       let percentage20=percentageValue;       
      if  (percentageValue < 20  ){
        let dwnamnt20=(dataList[index].askingprice*(20/100));        
        setMinamnt20(dwnamnt20);
        AmountValue1=(dataList[index].askingprice*(20/100)).toFixed(2); 
        dataList[index].downBorderPercentage = true;
        dataList[index].downBorderAmount = true;
        setTooltipTitle(" The minimum down payment is 20% or " + dwnamnt20)
        handleChangeDownPaymentPercentageParent(20, index);
      
      } else {
        dataList[index].downBorderPercentage = false;      
        setDownPaymentPerTextValue((percentageValue));
        var amount20=(dataList[index].askingprice*(percentage20/100));
        dataList[index].DownPaymentAmount=formatValue(amount20);
        handleChangeDownPaymentPercentageParent(percentageValue, index);
      }
    } 
    if (event.target.value <= 500000) {
      var percentage5=percentageValue;
      if  (percentageValue < 5 ){
        let dwnamnt5=(dataList[index].askingprice*(5/100));
        setMinamnt5(dwnamnt5)  
        AmountValue1=(dataList[index].askingprice*(5/100)).toFixed(2)    
        dataList[index].downBorderPercentage = true;
        dataList[index].downBorderAmount = true;
         setTooltipTitle(" The minimum down payment is 5% or " + dwnamnt5)
        handleChangeDownPaymentPercentageParent(5, index);
        
      } else {
       dataList[index].downBorderPercentage = false;
        setDownPaymentPerTextValue((percentageValue));
        var amount5=(dataList[index].askingprice*(percentage5/100));
        dataList[index].DownPaymentAmount=formatValue(amount5);
        handleChangeDownPaymentPercentageParent(percentageValue, index);
      }
    } 
    if ((dataList[index].askingprice > 500000 && dataList[index].askingprice < 1000000)) {
      var percentage=percentageValue;
      if  (percentageValue < (((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100){
        setMinper(((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2));
        var x=((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)         
        let mnamt=(dataList[index].askingprice * x)/100
        setMinamnt(mnamt);
          var DwnPer=((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)        
          var dwnamt=(500000*.05) + ((dataList[index].askingprice -500000)*(10/100))
           AmountValue1=(500000*.05) + ((dataList[index].askingprice -500000)*(10/100)).toFixed(2)
       dataList[index].downBorderPercentage = true;
       dataList[index].downBorderAmount = true;
        setTooltipTitle(" The minimum down payment is " + DwnPer + " or " + dwnamt)
        handleChangeDownPaymentPercentageParent((((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)), index);
      } else {
         dataList[index].downBorderPercentage = false;
        setDownPaymentPerTextValue(percentageValue);
        let amount=(dataList[index].askingprice*(percentageValue/100)).toFixed(2);
        dataList[index].DownPaymentAmount=formatValue(amount);
        handleChangeDownPaymentPercentageParent(percentageValue, index);
      }
    }
    var mortamount=((dataList[index].askingprice)-(dataList[index].askingprice*(percentage/100)));
    var mortgagewithhdmc= mortamount + insuranceTextValue;
    var tempdataList1=[...dataList]
    tempdataList1[index].mortgageamount=formatValue((dataList[index].askingprice - (dataList[index].askingprice*(percentageValue/100)))  + dataList[index].cmhc)  ;   
    tempdataList1[index].DownPaymentAmount =formatValue(AmountValue1);     
 tempdataList1[index].DownPaymentPercentage =formatValue(percentageValue);
    setDataList(tempdataList1);
    console.log("mortgagewithhdmc.....",dataList)
     setMortValValue(mortgagewithhdmc);
     setMortgageAmountTextValue(mortValValue)

   }


 
     
   
   const handleChangeInitialPaymentText =(event,index) => {  
    
   // setInitialPaymentTextValue(event.target.value);
  //   var mortamount=(parseInt(askingPriceTextValue)-parseInt(event.target.value));
   
  //  setMortValValue(mortamount);
   setMortgageAmountTextValue(mortValValue);
 

  }
  const handleChangeInterestText =(event,index) => { 
     
    setInterestrateValue(event.target.value);
   
  
    } 
 
   
    


    var percent='';
    const ref= useRef()
    var dwnamount='';
    const refamnt= useRef()

    const handleChangeDownPaymentPercentage = (event, index) => {      
      dataList[index].downBorderPercentage=(false);
    
      let percentageValue =(event.target.value);
      let AmountValue
      
        const newValue = parseInt(event.target.value); // Convert input value to integer
    
        if (newValue >=  100) {  
          dataList[index].DownPaymentPercentage = newValue     
          dataList[index].downBorderPercentage = true  ;
          setTooltipTitle("Please enter a value less than 100");
        }
     else{
      if (dataList[index].askingprice >= 1000000) {
         var percentage20=percentageValue;       
        if  (percentageValue < 20  ){         
          const dwnamnt20=dataList[index].askingprice*(20/100);
          AmountValue=dataList[index].askingprice*(20/100).toFixed(2);
          setMinamnt20(dwnamnt20);         
          dataList[index].downBorderPercentage = true  ;    
          
         
          setTooltipTitle(" The minimum down payment percentage is 20% ");
          handleChangeDownPaymentPercentageParent(20, index);
        
        
        } else {
          dataList[index].downBorderPercentage=(false);
          setDownPaymentPerTextValue((percentageValue));
          var amount20=dataList[index].askingprice*(percentage20/100);             
         dataList[index].DownPaymentAmount=formatValue(amount20)
         // document.getElementById("Amount_"+index ).value=(amount20).toFixed(2);
          handleChangeDownPaymentPercentageParent(percentageValue, index);
        }
      } 
      if (dataList[index].askingprice <=500000) {
        var percentage5=percentageValue;
        if  (percentageValue < 5 || percentageValue === 5){
          var downamnt5=(dataList[index].askingprice*(5/100));
          setMinamnt5(downamnt5)
         // AmountValue=(dataList[index].askingprice*(5/100)).toFixed(2)
          dataList[index].downBorderPercentage = true  ; 
          setTooltipTitle(" The minimum down payment is 5% ")
          handleChangeDownPaymentPercentageParent(5, index);
          
        } else {
          dataList[index].downBorderPercentage=(false);
          setDownPaymentPerTextValue((percentageValue));
          var amount5=(dataList[index].askingprice*(percentage5/100));        
         dataList[index].DownPaymentAmount=formatValue(amount5);
          handleChangeDownPaymentPercentageParent(percentageValue, index);
        }
      } 
      if ((dataList[index].askingprice > 500000 && dataList[index].askingprice < 1000000)) {
        var percentage=percentageValue;
        if  (percentageValue < ((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2)){
          setMinper(((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2));
          var DwnPer=(((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100)).toFixed(2)
         // var x=((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(1)         
         // let mnamt=(dataList[index].askingprice*x)/100
       
           AmountValue=((500000*.05) + ((dataList[index].askingprice -500000)*(10/100))).toFixed(2)
          var dwnamt=(500000*.05) + ((dataList[index].askingprice -500000)*(10/100))
          setMinamnt(dwnamt);
          dataList[index].downBorderPercentage = true  ; 
          setTooltipTitle(" The minimum down payment is " + DwnPer  )
         
          handleChangeDownPaymentPercentageParent((((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(1)), index);
        } else {
          dataList[index].downBorderPercentage=(false);
          setDownPaymentPerTextValue(percentageValue);
          var amount=(dataList[index].askingprice*(percentageValue/100)).toFixed(2);           
         dataList[index].DownPaymentAmount=formatValue(amount);
          handleChangeDownPaymentPercentageParent(percentageValue, index);
        }
      } 
      var mortamount=((dataList[index].askingprice)-(dataList[index].askingprice*(percentage/100)));  
     
      var mortgagewithhdmc= mortamount + insuranceTextValue;
    
      var tempdataList=[...dataList]
      tempdataList[index].mortgageamount=formatValue((dataList[index].askingprice - (dataList[index].askingprice*(percentageValue/100)))  + dataList[index].cmhc ) ;   
      //tempdataList[index].downBorderPercentage=true  ; 
     
    //  tempdataList[index].DownPaymentAmount = formatValue(AmountValue);
   tempdataList[index].DownPaymentPercentage =(percentageValue);
      setDataList(tempdataList);
      console.log("mortgagewithhdmc.....",dataList)
       setMortValValue(mortgagewithhdmc);
       setMortgageAmountTextValue(mortValValue)
    }

       //setShowTimer(false);
      }



  const handleChangeDownPaymentAmount =(event,index) => { 
    setdownBorderAmount(false)
    let downAmountValue=event.target.value;
    let downPercentValue
    const newValue = parseInt(event.target.value); // Convert input value to integer
    
    if (newValue >=  dataList[index].askingprice) {  
      dataList[index].DownPaymentAmount = newValue     
      dataList[index].downBorderAmount = true  ;
      setTooltipTitle("Please enter a value less than the Purchase price");
    }
 else{


 if (dataList[index].askingprice >= 1000000) {     
      if  (downAmountValue < (dataList[index].askingprice*20)/100){          
        let mnamt20=(dataList[index].askingprice*20)/100;
        setMinamnt20(mnamt20)       
        dataList[index].downBorderAmount = true  ; 
        downPercentValue=dataList[index].DownPaymentPercentage
        setTooltipTitle(" The minimum down payment is  " + mnamt20)
        handleChangeDownPaymentAmountParent(200000, index);
       
      } else {
        dataList[index].downBorderAmount = false  ; 
        setDownPaymentAmountTextValue(downAmountValue);
        var val20=((downAmountValue*100)/dataList[index].askingprice);
        dataList[index].DownPaymentPercentage=(val20.toFixed(2));
        downPercentValue=val20.toFixed(2);
        handleChangeDownPaymentAmountParent(downAmountValue, index);
        
      }
    } 
    if (dataList[index].askingprice <= 500000) {     
      //var downAmount5=(downAmountValue*100)/dataList[index].askingprice;
      if  (downAmountValue < (dataList[index].askingprice*5)/100 ){
        let mnamt5=(dataList[index].askingprice*5)/100;
        setMinamnt5(mnamt5)
        downPercentValue=dataList[index].DownPaymentPercentage
        dataList[index].downBorderAmount = true  ; 
        setTooltipTitle(" The minimum down payment is  " + mnamt5)
        handleChangeDownPaymentAmountParent(25000, index);
       
      } else {
        dataList[index].downBorderAmount = false  ; 
        setDownPaymentAmountTextValue(downAmountValue);
        var downAmountValue5=(downAmountValue*100)/dataList[index].askingprice;     
        dataList[index].DownPaymentPercentage=(downAmountValue5).toFixed(2);
        downPercentValue=downAmountValue5.toFixed(2);
        handleChangeDownPaymentAmountParent(downAmountValue, index);
      }
    } 
    if ((dataList[index].askingprice >500000 && dataList[index].askingprice < 1000000)) {
      var downAmount=downAmountValue;
      if  ((downAmountValue < (500000*.05) + ((dataList[index].askingprice -500000)*(10/100)) )){
        var Dwnamnt=(500000*.05) + ((dataList[index].askingprice -500000)*(10/100))
        setMinamnt(Dwnamnt );  
        var DwnPer= ((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2) ;       
        setMinper(((((500000*.05) + (dataList[index].askingprice -500000)*(10/100))/dataList[index].askingprice)*100).toFixed(2))
      //  downPercentValue=DwnPer
      downPercentValue=dataList[index].DownPaymentPercentage
        dataList[index].downBorderAmount = true  ; 
        setTooltipTitle(" The minimum down payment is "  + Dwnamnt)
        handleChangeDownPaymentAmountParent((500000*.05) + ((dataList[index].askingprice -500000)*(10/100)) , index);
      } else {
        dataList[index].downBorderAmount = false  ; 
        setDownPaymentAmountTextValue(downAmountValue);
        var val=((downAmount*100)/dataList[index].askingprice);
        dataList[index].DownPaymentPercentage=(val.toFixed(2)); 
        handleChangeDownPaymentAmountParent(downAmountValue, index);
      }
    }  
   setDownPaymentAmountTextValue(downAmountValue);
   let mortamount=((dataList[index].askingprice)-(downAmountValue));  
   let mortgagewithhdmc= mortamount + insuranceTextValue;  
   var tempdataList=[...dataList]
   tempdataList[index].mortgageamount=formatValue(mortgagewithhdmc)  ; 
   tempdataList[index].DownPaymentAmount =(downAmountValue);
   tempdataList[index].DownPaymentPercentage =formatValue(downPercentValue);;
  setDataList(tempdataList);
  setMortValValue(mortgagewithhdmc);
  setMortgageAmountTextValue(mortValValue)
  }

  }


 
   const handleChangeInsuranceAmount =(event,index) => { 
     
    setInsuranceTextValue(event.target.value);
   
  
    } 

    // useEffect(() => {
    //   document.getElementById("Percent_"+index ).value = parseInt(dataList[index].DownPaymentPercentage);
    //  // document.getElementById("Amount_"+index ).value =dataList[index].DownPaymentAmount ;
     
     
        
    //   },[dataList[index].DownPaymentAmount,dataList[index].DownPaymentPercentage] );

    useEffect(() => {      
   
      handleChangeAskingpriceWithInsurance(dataList[index].askingprice,index);     
        
      }, [dataList[index].askingprice]);
  
   



const handleMouseEnter = (index) => {
  var tempdataList=[...dataList]
  tempdataList[index].isSelected=true  ;    
  setDataList(tempdataList);
};

const handleMouseLeave = (index) => {
  var tempdataList=[...dataList]
  tempdataList[index].isSelected=false  ;    
  setDataList(tempdataList); 
};

  return (


    <Box key={mortgageName} sx={{
      display: 'flex', flexDirection: "column",
       border:isSelected || selectedCheckboxList.includes(mortgageName) ?"1px solid #0C276C" : "1px solid #CFC9C9", width:'100%',
      height: "470px",  alignItems: "center", borderRadius: "8px", gap: '4%', justifyContent: 'center',
      background: isSelected || selectedCheckboxList.includes(mortgageName) ? "#F9F9F9" : "white",
    }}>

      {IsDeleteButton &&<Box sx={{ display: 'flex', flexDirection: "row",position: "relative", marginTop:"-25%" , 
      marginLeft:"97%"  }}> 
      <IconButton onClick={() => handleDeleteClick(mortgageName)} aria-label="delete" size="small">
        <img src={DeleteIcon} alt="delete" sx={{ }} />
      </IconButton></Box>}


      {CheckboxEnabled && <Box sx={{ display: 'flex', flexDirection: "row",position: "relative", marginTop:"-23%" , 
      marginLeft:"99%"  }}> 
      <Checkbox checked={selectedCheckboxList.includes(mortgageName)} onChange={() => handleCheckboxClickAddMortgage(mortgageName)}
       style={{
        accentColor: "#0C276C", color: selectedCheckboxList.includes(mortgageName) === true ? "#0C276C" : "#CFC9C9",
        zIndex: 1, background: selectedCheckboxList ? "#F9F9F9" : "white",
        borderRadius: '0', width: '19px', height: '19px'
      }} />
     </Box>}




      <Typography sx={{
        color: "#626262", textAlign: "center", padding: "0px 5px 0px 5px", fontFamily: "poppins", fontSize: "14px", lineHeight: "18px",
        fontWeight: "400", marginTop: IsDeleteButton ? "-25px" : "-15px",paddingTop: IsDeleteButton ?(CheckboxEnabled ? "1.5px" : "-27px") : "6px"
      }}>{mortgageName}
      </Typography>
     
      
      <TextField key={index}   value={isNaN(dataList[index].askingprice) ? '' : String(dataList[index].askingprice)}  onChange={(event) =>{ 
      handleChangeAskingPriceText(event,index);  handleChangeAskingpriceWithInsurance(event.target.value,index)}} variant="outlined" sx={{
        width: "90%", fontFamily: "poppins",
        fontSize: "12px", lineHeight: "18px", fontWeight: "400",
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262" , fontFamily: "poppins",
          fontSize: "12px", lineHeight: "18px", fontWeight: "400",},type:'number',
          '& label': {
            display: 'flex', alignItems: 'center',
            justifyContent: 'center'
          }
        }} /> 

      
       
 {dataList[index].downBorderPercentage ? (
    
    <Tooltip
     classes={{ tooltip: "custom-tooltip" }}
      title={tooltipTitle} 
      placement="top-start"
      arrow
      open={isTooltipOpenPercentage}
      onClose={handleTooltipClosePercentage}
      disableHoverListener // Disable the hover listener
    >
   <TextField   variant="outlined" placeholder="20"  value={dataList[index].DownPaymentPercentage}
   onChange={(event) =>{ (handleChangeDownPaymentPercentage)(event,index)}}
   onFocus={handleTooltipOpenPercentage} // Use onFocus for touch-based devices
   onBlur={handleTooltipClosePercentage} // Use onBlur to close the tooltip
    // onChange={(event) =>{ (handleAmountValueChange)(event,index)}}
    sx={{
      width: "90%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    }}
    InputProps={{
      sx: { height: '28px', color: " #626262", fontFamily: "poppins",border: dataList[index].downBorderPercentage ? '1px solid red !important' : ' ',
      fontSize: "12px", lineHeight: "18px", fontWeight: "400", },type:'number',
      inputProps: {
        min: "0", max: "100", 
      },
      '& label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
     
    }}
      />
        </Tooltip>
    ) : (
      <TextField   variant="outlined" placeholder="20"   value={dataList[index].DownPaymentPercentage}
   onChange={(event) =>{ (handleChangeDownPaymentPercentage)(event,index)}}
    // onChange={(event) =>{ (handleAmountValueChange)(event,index)}}
    onFocus={handleTooltipOpenPercentage} // Use onFocus for touch-based devices
    onBlur={handleTooltipClosePercentage} // Use onBlur to close the tooltip
    sx={{
      width: "90%", fontFamily: "poppins",
      fontSize: "12px", lineHeight: "18px", fontWeight: "400",
    }}
    InputProps={{
      sx: { height: '28px', color: " #626262", fontFamily: "poppins",border: dataList[index].downBorderPercentage ? '1px solid red !important' : ' ',
      fontSize: "12px", lineHeight: "18px", fontWeight: "400", },type:'number',
      inputProps: {
        min: "0", max: "100", 
      },
      '& label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
     
    }}
      /> )
      }    

{dataList[index].downBorderAmount ? (
    
    <Tooltip
     classes={{ tooltip: "custom-tooltip" }}
      title={tooltipTitle} 
      placement="top-start"
      arrow
      open={isTooltipOpenAmount}
      onClose={handleTooltipCloseAmount}
      disableHoverListener // Disable the hover listener
    >
  <TextField id={"Amount_"+index }  placeholder="200000"  ref={refamnt}    value={dataList[index].DownPaymentAmount}
        onChange={(event) =>{ handleChangeDownPaymentAmount(event,index)}}
        onFocus={handleTooltipOpenAmount} // Use onFocus for touch-based devices
        onBlur={handleTooltipCloseAmount} // Use onBlur to close the tooltip
       sx={{
        width: "90%", fontFamily: "poppins",
        fontSize: "12px", lineHeight: "18px", fontWeight: "400",
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262", fontFamily: "poppins",border: dataList[index].downBorderAmount ? '1px solid red !important' : '',
          fontSize: "12px", lineHeight: "18px", fontWeight: "400", },type:'number',
          '& label': {
            display: 'flex', alignItems: 'center',
            justifyContent: 'center'
          },
       
        }} />
        </Tooltip>
    ) : (
      <TextField id={"Amount_"+index }  placeholder="200000"  ref={refamnt}    value={dataList[index].DownPaymentAmount}
      onChange={(event) =>{ handleChangeDownPaymentAmount(event,index)}}
      onFocus={handleTooltipOpenAmount} // Use onFocus for touch-based devices
      onBlur={handleTooltipCloseAmount} // Use onBlur to close the tooltip
       sx={{
        width: "90%", fontFamily: "poppins",
        fontSize: "12px", lineHeight: "18px", fontWeight: "400",
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262", fontFamily: "poppins",border: dataList[index].downBorderAmount ? '1px solid red !important' : '',
          fontSize: "12px", lineHeight: "18px", fontWeight: "400", },type:'number',
          '& label': {
            display: 'flex', alignItems: 'center',
            justifyContent: 'center'
          },
       
        }} /> )
      }

         <Typography id={index}  
        sx={{
          width: "90%", fontFamily: "poppins",  fontSize: "12px", lineHeight: "18px", fontWeight: "400",border:'1px solid #CFC9C9 ',
          height: '28px', color: " #626262",borderRadius:'4px', display: 'flex', alignItems: 'center',  justifyContent: 'left',padding: "0px 5px 0px 5px",
        }} > 
            {isNaN(dataList[index].cmhc) ? '' : formatValue(dataList[index].cmhc)}
        </Typography>
      <Typography     
      sx={{
        width: "90%", fontFamily: "poppins",  fontSize: "12px", lineHeight: "18px", fontWeight: "400",border:'1px solid #CFC9C9 ',
        height: '28px', color: " #626262",borderRadius:'4px', display: 'flex', alignItems: 'center',  justifyContent: 'left',padding: "0px 5px 0px 5px",
      }} >
         {isNaN(dataList[index].mortgageamount) ? '' : formatValue(dataList[index].mortgageamount)} 
       
        </Typography>
     
      {/* <Select value={selectedOptionVariable} onChange={handleOptionChangeVariable}
        sx={{
          width: '80%', height: '28px', fontFamily: 'poppins', fontSize:{md: '12px',sm: '10px'}, lineHeight: '18px', fontWeight: '400', 
          color: '#626262',

        }}
        displayEmpty
        MenuProps={{
          
          PaperProps: {
            style: {
              onMouseLeave: () => setSelectedOptionVariable(''), background: " #F8F8F8", borderRadius: '8px',marginTop:'5px',
              
            },
            
          },
        }}
      >
        <MenuItem value="" style={{ display: 'none' }}>   Variable   </MenuItem>
        <MenuItem value={1} sx={{
          backgroundColor: selectedOptionVariable === 1 ? '#1F6373 !important' : 'transparent',
          color: selectedOptionVariable === 1 ? '#ffffff' : '#626262', fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px',
          fontWeight: '400', borderRadius: '4px', marginLeft: '5px', marginRight: '5px',
        }}
          onMouseEnter={() => setSelectedOptionVariable(1)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          Variable  </MenuItem>
        <MenuItem value={2} style={{
          backgroundColor: selectedOptionVariable === 2 ? '#1F6373' : 'transparent', color: selectedOptionVariable === 2 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', marginRight: '5px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(2)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          Fixed </MenuItem>
        <MenuItem value={3} style={{
          backgroundColor: selectedOptionVariable === 3 ? '#1F6373' : 'transparent', color: selectedOptionVariable === 3 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', marginRight: '5px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(3)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          Open  </MenuItem>
      </Select> */}


<Select value={dataList[index].period} onChange={(event) =>{ handleOptionChangeAmortization(event,index); handleChangePeriod(event,index)}}  
        sx={{
          width: '90%', height: '28px', fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', 
          color: '#626262',

        }}
        displayEmpty
        MenuProps={{
          
          PaperProps: {
            style: {
              onMouseLeave: () => setSelectedOptionVariable(''), background: " #F8F8F8", borderRadius: '8px',marginTop:'5px', maxHeight: '250px',
               overflowY: 'auto',
              
            },
            
          },
        }}
      >
        <MenuItem  style={{ display: 'none' }}>  25 Years   </MenuItem>
        <MenuItem value={1} sx={{
          backgroundColor: selectedOptionVariable === 1 ? '#0C276C !important' : 'transparent',
          color: selectedOptionVariable === 1 ? '#ffffff' : '#626262', fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px',
          fontWeight: '400', borderRadius: '4px', marginLeft: '5px', marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(1)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          1 Year  </MenuItem>
        <MenuItem value={2} style={{
          backgroundColor: selectedOptionVariable === 2 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 2 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(2)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          2 Years </MenuItem>
        <MenuItem value={3} style={{
          backgroundColor: selectedOptionVariable === 3 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 3 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(3)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         3 Years  </MenuItem>
         <MenuItem value={4} style={{
          backgroundColor: selectedOptionVariable === 4 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 4 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(4)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          4 Years </MenuItem>
        <MenuItem value={5} style={{
          backgroundColor: selectedOptionVariable === 5 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 5 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(5)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         5 Years  </MenuItem>
         <MenuItem value={6} style={{
          backgroundColor: selectedOptionVariable === 6 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 6 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(6)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          6 Years </MenuItem>
        <MenuItem value={7} style={{
          backgroundColor: selectedOptionVariable === 7 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 7 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(7)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         7 Years  </MenuItem>
         <MenuItem value={8} style={{
          backgroundColor: selectedOptionVariable === 8 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 8 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(8)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          8 Years </MenuItem>
        <MenuItem value={9} style={{
          backgroundColor: selectedOptionVariable === 9 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 9 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(9)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         9 Years  </MenuItem>
         <MenuItem value={10} style={{
          backgroundColor: selectedOptionVariable === 10 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 10 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(10)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          10 Years </MenuItem>
        <MenuItem value={15} style={{
          backgroundColor: selectedOptionVariable === 15 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 15 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(15)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         15 Years  </MenuItem>
         <MenuItem value={20} style={{
          backgroundColor: selectedOptionVariable === 20 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 20 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(20)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          20 Years </MenuItem>
        <MenuItem value={25} style={{
          backgroundColor: selectedOptionVariable === 25 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 25 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(25)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         25 Years  </MenuItem>
         <MenuItem value={30} style={{
          backgroundColor: selectedOptionVariable === 30 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 30 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(30)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
          30 Years </MenuItem>
        <MenuItem value={35} style={{
          backgroundColor: selectedOptionVariable === 35 ? '#0C276C' : 'transparent', color: selectedOptionVariable === 35 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionVariable(35)}
          onMouseLeave={() => setSelectedOptionVariable('')}
        >
         35 Years  </MenuItem>
      </Select>

      <TextField id="outlined-basic" value={isNaN(dataList[index].rate) ? '' : String(dataList[index].rate)} placeholder="3" variant="outlined" style={{
        width: "90%", fontFamily: "poppins", fontSize: "12px", lineHeight: "18px", fontWeight: "400",
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262",fontFamily: "poppins", fontSize: "12px", lineHeight: "18px", fontWeight: "400", }, '& label': {
            display: 'flex', alignItems: 'center', justifyContent: 'center'
          }
        }}onChange={(event) =>{ handleChangeInterest(event, index);}} />
      {/* <TextField id="outlined-basic" placeholder="0" variant="outlined" style={{
        width: "80%", fontFamily: "poppins", fontSize: "12px", lineHeight: "18px", fontWeight: "400",
      }}
        InputProps={{
          sx: { height: '28px', color: " #626262" }, '& label': {
            display: 'flex', alignItems: 'center', justifyContent: 'center'
          }
        }} /> */}
     
      <Select value={dataList[index].frequency} onChange={(event) =>{ handleOptionChangePaymentFreequency(event, index);handleChangeFreequency(event,index)}}
       sx={{
        width: "7.2rem",overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' ,  height: '28px', fontFamily: 'poppins', fontSize: "12px", lineHeight: '18px', fontWeight: '400', color: '#626262'
      }}
      displayEmpty MenuProps={{
        PaperProps: {
          style: {
            onMouseLeave: () => setSelectedOptionMonthly(''), background: " #F8F8F8 ", borderRadius: '8px',marginTop:'5px',
          },
        },
      }}
    >
         <MenuItem  style={{ display: 'none' }}>Monthly </MenuItem>   
        <MenuItem value={"M"}  style={{
          backgroundColor: selectedOptionMonthly === "M" ? '#0C276C' : 'transparent', color: selectedOptionMonthly ==="M" ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonthly('M')}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Monthly</MenuItem>
        <MenuItem value={"S"} style={{
          backgroundColor: selectedOptionMonthly === "S" ? '#0C276C' : 'transparent', color: selectedOptionMonthly === "S" ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonthly("S")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Semi-monthly</MenuItem>
           <MenuItem value={"W"} style={{
          backgroundColor: selectedOptionMonthly === "W" ? '#0C276C' : 'transparent', color: selectedOptionMonthly === "W" ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonthly("W")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Weekly</MenuItem>
           <MenuItem value={"B"} style={{
          backgroundColor: selectedOptionMonthly === "B" ? '#0C276C' : 'transparent', color: selectedOptionMonthly === "B" ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonthly("B")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Bi-weekly</MenuItem>
           <MenuItem value={"A"} style={{
          backgroundColor: selectedOptionMonthly === "A" ? '#0C276C' : 'transparent', color: selectedOptionMonthly === "A" ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonthly("A")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Accelerated weekly</MenuItem>
           <MenuItem value={"Z"} style={{
          backgroundColor: selectedOptionMonthly === "Z" ? '#0C276C' : 'transparent', color: selectedOptionMonthly === "Z" ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonthly("Z")}
          onMouseLeave={() => setSelectedOptionMonthly('')}>Accelerated Bi-weekly</MenuItem>

      </Select>
      <Select value={dataList[index].term} onChange={(event) =>{ handleOptionChangeTerm(event,index); handleChangeTerm(event,index)}} 
        sx={{
          width: '90%', height: '28px', fontFamily: 'poppins', fontSize:'12px', lineHeight: '18px', fontWeight: '400', 
          color: '#626262',
        }}
        displayEmpty MenuProps={{
          PaperProps: {
            style: {
               maxHeight: '250px',
               overflowY: 'auto',
              onMouseLeave: () => setSelectedOptionMonths(''),
              background: " #F8F8F8 ", borderRadius: '8px',marginTop:'5px',
            },
          },
        }}
      >
        <MenuItem  style={{ display: 'none' }}>    5 Years    </MenuItem>
        <MenuItem value={1} style={{
          backgroundColor: selectedOptionMonths === 1 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 1 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(1)}
          onMouseLeave={() => setSelectedOptionMonths('')}
        >1 Year</MenuItem>
        <MenuItem value={2} style={{
          backgroundColor: selectedOptionMonths === 2 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 2 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(2)}
          onMouseLeave={() => setSelectedOptionMonths('')}>2 Years</MenuItem>

        <MenuItem value={3} style={{
          backgroundColor: selectedOptionMonths === 3 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 3 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(3)}
          onMouseLeave={() => setSelectedOptionMonths('')}>3 Years</MenuItem>
        <MenuItem value={4} style={{
          backgroundColor: selectedOptionMonths === 4 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 4 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(4)}
          onMouseLeave={() => setSelectedOptionMonths('')}>4 Years</MenuItem>
        <MenuItem value={5} style={{
          backgroundColor: selectedOptionMonths === 5 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 5 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(5)}
          onMouseLeave={() => setSelectedOptionMonths('')}>5 Years</MenuItem>
        <MenuItem value={6} style={{
          backgroundColor: selectedOptionMonths === 6 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 6 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(6)}
          onMouseLeave={() => setSelectedOptionMonths('')}>6 Years</MenuItem>
        <MenuItem value={7} style={{
          backgroundColor: selectedOptionMonths === 7 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 7 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(7)}
          onMouseLeave={() => setSelectedOptionMonths('')}>7 Years</MenuItem>
        <MenuItem value={8} style={{
          backgroundColor: selectedOptionMonths === 8 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 8 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(8)}
          onMouseLeave={() => setSelectedOptionMonths('')}>8 Years</MenuItem>
        <MenuItem value={9} style={{
          backgroundColor: selectedOptionMonths === 9 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 9 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(9)}
          onMouseLeave={() => setSelectedOptionMonths('')}>9 Years</MenuItem>
        <MenuItem value={10} style={{
          backgroundColor: selectedOptionMonths === 10 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 10 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(10)}
          onMouseLeave={() => setSelectedOptionMonths('')}>10 Years</MenuItem>
        <MenuItem value={15} style={{
          backgroundColor: selectedOptionMonths === 15 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 15 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px', 
          marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(15)}
          onMouseLeave={() => setSelectedOptionMonths('')}>15 Years</MenuItem>
        <MenuItem value={20} style={{
          backgroundColor: selectedOptionMonths === 20 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 20 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(20)}
          onMouseLeave={() => setSelectedOptionMonths('')}>20 Years</MenuItem>
           <MenuItem value={25} style={{
          backgroundColor: selectedOptionMonths === 25 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 25 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(25)}
          onMouseLeave={() => setSelectedOptionMonths('')}>25 Years</MenuItem>
            <MenuItem value={30} style={{
          backgroundColor: selectedOptionMonths === 30 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 30 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(30)}
          onMouseLeave={() => setSelectedOptionMonths('')}>30 Years</MenuItem>
            <MenuItem value={35} style={{
          backgroundColor: selectedOptionMonths === 35 ? '#0C276C' : 'transparent', color: selectedOptionMonths === 35 ? '#ffffff' : '#626262',
          fontFamily: 'poppins', fontSize: '12px', lineHeight: '18px', fontWeight: '400', borderRadius: '4px', marginLeft: '5px',
           marginRight: '5px',minHeight:'10px'
        }}
          onMouseEnter={() => setSelectedOptionMonths(35)}
          onMouseLeave={() => setSelectedOptionMonths('')}>35 Years</MenuItem>
      </Select>
      {showPopup && (

        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '2'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
            
              width: '340px',
              height: '202px',
              backgroundColor: '#fff',

              border: '1px solid #CFC9C9',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '3'
            }}
          >
            <IconButton onClick={handleCancel} style={{
              color: '#939393', position: 'absolute', width: '20px', height: '20px',
              left: '92%', top: '12px'
            }} >
              <HighlightOffIcon ></HighlightOffIcon>
            </IconButton>

            <Typography style={{
              fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
              textAlign: "center"
            }}>Are you sure you want to delete this Mortgage option?</Typography>
            <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
              <button size='small' onClick={handleCancel} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins', fontStyle: 'normal',
                fontWeight: '400', fontSize: '14px', lineHeight: '21px', marginRight: '24px', textAlign: 'center', color: '#313131',
                background: '#F8F8F8', border: '1px solid #CFC9C9', borderRadius: '4px',cursor:'pointer'
              }} >Cancel</button>
              <button size='small' onClick={handleDeleteConfirm} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C', border: '1px solid #0C276C', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C';
              }} >Delete </button>
            </Box>
          </Box>
        </Box>
      )}



{showPopuptermamort && (

<Box
  sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2'
  }}
>
  <Box
    sx={{
      position: 'absolute',
    
      width: '340px',
      height: '202px',
      backgroundColor: '#fff',

      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3'
    }}
  >
    <IconButton onClick={handleCancelTerm} style={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left: '92%', top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>

    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
      textAlign: "center"
    }}> The amortization period must be equal to or greater than the term</Typography>
   <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
   <button size='small' onClick={handleCancelTerm} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C', border: '1px solid #0C276C', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C';
              }} >Cancel </button>
            
            </Box>
   
  </Box>
</Box>
)}
{/* {showPopupDownPer20 && (

<Box
  sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',  
    zIndex: '2'
  }}
>
  <Box
    sx={{
      position: 'absolute',
    
      width: '340px',
      height: '202px',
      backgroundColor: '#fff',

      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3'
    }}
  >
    <IconButton onClick={handleCancelDownPer20} style={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left: '92%', top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>

    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
      textAlign: "center"
    }}> The minimum down payment is 20% or {minamnt20} </Typography>
   <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
   <button size='small' onClick={handleCancelDownPer20} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C', border: '1px solid #0C276C', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C';
              }} >Cancel </button>
            
            </Box>
   
  </Box>
</Box>
)} */}

{/* {showPopupDownPer5 && (

<Box
  sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2'
  }}
>
  <Box
    sx={{
      position: 'absolute',
    
      width: '340px',
      height: '202px',
      backgroundColor: '#fff',

      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3'
    }}
  >
    <IconButton onClick={handleCancelDownPer5} style={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left: '92%', top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>

    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
      textAlign: "center"
    }}> The minimum down payment is 5% or {minamnt5}</Typography>
   <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
   <button size='small' onClick={handleCancelDownPer5} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C', border: '1px solid #0C276C', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C';
              }} >Cancel </button>
            
            </Box>
   
  </Box>
</Box>
)} */}
{/* {showPopupDownPer && (

<Box
  sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2'
  }}
>
  <Box
    sx={{
      position: 'absolute',
    
      width: '340px',
      height: '202px',
      backgroundColor: '#fff',

      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3'
    }}
  >
    <IconButton onClick={handleCancelDownPer} style={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left: '92%', top: '12px'
    }} >
      <HighlightOffIcon ></HighlightOffIcon>
    </IconButton>

    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
      textAlign: "center"
    }}> The minimum down payment is greater than {minper} or {minamnt} </Typography>
   <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
   <button size='small' onClick={handleCancelDownPer} style={{
                width: '77px', height: '32px', fontFamily: 'Poppins',
                fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
                background: '#0C276C', border: '1px solid #0C276C', borderRadius: '4px',cursor:'pointer'
              }} onMouseEnter={(e) => {
                e.currentTarget.style.background = '#074554';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = '#0C276C';
              }} >Cancel </button>
            
            </Box>
   
  </Box>
</Box>
)} */}
    </Box>


  );

}

export default DivcomponenetUserMobile;