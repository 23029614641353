import React, { useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box, MenuItem } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
const DropdownMenu = (props) => {

    const {setIsDeleted, isDeleted} = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (value) => {
    handleSelection(value);
    setIsOpen(false);
  }
  const [selectedIcon, setSelectedIcon] = useState(<CircleIcon style={{color:'#198CCD',width:'14px',height:'14px'}}/>);
  

  const handleSelection = (value) => {
    if (value === "Mark as Unread") {
        setSelectedIcon(<CircleIcon style={{color:'#198CCD',width:'14px',height:'14px'}}/>);
      
    } else if (value === "Mark as read") {
        setSelectedIcon(<DoneAllIcon style={{width:'14px',height:'14px'}} />);
     
    } else if (value === "Delete") {
        setIsDeleted(true);
      
  }}
 
  return (
  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: 'center', alignContent: 'center', height: '100%'}} >
   
 <Box className="dropdown__toggle" onClick={handleMenuToggle} sx={{paddingLeft:'40px',paddingRight:'50px'}} >
  {selectedIcon ? (    selectedIcon  ) : (    <CircleIcon style={{ cursor: 'pointer' }} />  )}
</Box>

    <Box className="dropdown" sx={{paddingRight:'20px'}}>
      <Box className="dropdown__toggle" onClick={handleMenuToggle}>
        <MoreVertOutlinedIcon style={{cursor:'pointer'}}/>
      </Box>
      {isOpen && (
        <Box className="dropdown__menu" handleSelection={handleSelection} style={{ position: 'absolute', right: '12% ',fontFamily: "poppins",
         fontSize: "12px",  fontWeight: "400",lineHeight:'18x',width:'164px',height:'96px',background:'#F8F8F8',borderRadius:'8px',
        boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',zIndex:1, minHeight: '20px',padding:'4px'}}>
           <style>
            {`
              .dropdown__menu .MuiMenuItem-root:hover {
                background-color: #1F6373;
                color: white !important;
              }
              
            `}
          </style>
          <MenuItem onClick={() => handleMenuItemClick("Mark as read")} value="option2" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
          fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'20px'}}><DoneAllIcon style={{width:'15px',height:'17px',
          marginRight:'10px'}}></DoneAllIcon>Mark as read</MenuItem>

          <MenuItem  onClick={() => handleMenuItemClick("Mark as Unread")} value="option1" style={{height:'24px',borderRadius:'4px', marginBottom: '7px',
          fontFamily: "poppins", fontSize: "12px",fontWeight: "400",lineHeight:'18px'}}><CircleIcon style={{width:'15px',height:'17px',
          marginRight:'10px',color:'#198CCD', }} />Mark as Unread</MenuItem>
         
          <MenuItem onClick={() => handleMenuItemClick("Delete")} value="option3" style={{height:'24px',borderRadius:'4px', marginBottom: '0px',
          fontFamily: "poppins", fontSize: "12px", fontWeight: "400",lineHeight:'18px'}}><DeleteIcon style={{width:'15px',height:'17px',
          marginRight:'10px'}}></DeleteIcon>Delete</MenuItem>
        </Box>
      )}
    </Box>
    </Box>
  );
};



export default DropdownMenu;
