import { Box } from '@mui/material'
import React from 'react'
import AddMortgage from '../components/MortgageAgent'
import AddMortgageMobile from '../components/MortgageAgentMobile'


function MortgageDetails() {
  return (
    <div>
      <Box sx={{display:{md:'none',xs:'block',sm:'none'}}}>

        <AddMortgageMobile>
        </AddMortgageMobile>

      </Box>

      <Box sx={{display:{md:'block',xs:'none',sm:'block'}}}>

       <AddMortgage>        
       </AddMortgage>
      </Box>
    </div>
  )
}

export default MortgageDetails
