import Box from '@mui/material/Box';
import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import '../App.css';
import Header from './HeaderLanding'
import HeaderMobile from './HeaderLandingMobile';
import Footer from './Footer';
import Image01 from './images/image01.png'
import Image02 from './images/image02.png'
import Image03 from './images/image03.png'
import Image04 from './images/image04.png'
import Image1 from './images/image1.png'
import Image2 from './images/image2.png'
import Image3 from './images/image3.png'
import { Link } from 'react-router-dom';
function Articles() {
    const [currentPage, setCurrentPage] = useState('Articles');
    const blogs = [
        {
            index:0,
            image: Image01,
            heading: 'Mortgage rates match highest level in 20 years',
            content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
            publishedDate: 'April 8,2023',
          },          
        { 
            index:1,
            image: Image02,
            heading: 'What is the role of a mortgage agent?',
            content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
            publishedDate: 'April 8,2023',
          },
          {
            index:2,
            image: Image03,
            heading: 'Steps to get the best interest rate on your mortgage',
            content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
            publishedDate: 'April 8,2023',
          },
          {
            index:3,
            image: Image04,
            heading: 'How a mortgage broker can help you',
            content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
            publishedDate: 'April 8,2023',
          },
        {
            index:4,
          image: Image1,
          heading: 'How much is a down payment on a house?',
          content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
          publishedDate: 'April 8,2023',
        },
        {
            index:5,
            image: Image2,
            heading: 'How to get the best mortgage rate',
            content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
            publishedDate: 'April 8,2023',
          },
          {
            index:6,
            image: Image3,
            heading: 'How to get the best mortgage rate',
            content: 'There are various types of credits cards available in Canada. The different credit card types are designed to fit the spend...',
            publishedDate: 'April 8,2023',
          },
      ];
      const articlesPerPage = 5;
      const totalPages = Math.ceil(blogs.length / articlesPerPage);
  
      const [currentPageNumber, setCurrentPageNumber] = useState(1);
  
      const startIndex = (currentPageNumber - 1) * articlesPerPage;
      const endIndex = Math.min(startIndex + articlesPerPage, blogs.length);
  
      const displayedBlogs = blogs.slice(startIndex, endIndex);
  
      const handlePageClick = (page) => {
          setCurrentPageNumber(page);
      };

    return (
        <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>
            <Box sx={{
                height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'flex', sm: 'flex', xs: 'none' }
            }}>
                <Header currentPage={currentPage}></Header>
            </Box>
            <Box sx={{
                height: '100% ', width: '100%', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'none', sm: 'none', xs: 'flex' }
            }}>
                <HeaderMobile ></HeaderMobile>
            </Box>
            <Box sx={{
        width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)',xs:'calc(100%-10%)' },  display: 'flex', flexDirection: 'row',height:'100%',
        marginLeft: { md: '16%', sm: '5%' , xs:'5%'}, marginRight: { md: '16%', sm: '5%' , xs:'5%'}, marginTop: { md: '50px', sm: '50px',xs:'25px' },
        marginBottom: { md: '0', sm: '0' , xs:'5%'},
      }}>
 
                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: { md: '26px', sm: '26px', xs: '20px' },paddingLeft:'20px',
                        lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto'
                    }}>
                       Articles
                    </Typography>
                    </Box>
                    <Box  sx={{
        width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)',xs:'calc(100%-10%)' },  display: 'flex', flexDirection: 'row',height:'100%',
        marginLeft: { md: '16%', sm: '5%' , xs:'5%'}, marginRight: { md: '16%', sm: '5%' , xs:'5%'}, marginTop: { md: '20px', sm: '20px',xs:'0px' },
        marginBottom: { md: '20px', sm: '20px' , xs:'0'},
      }}>
                     <div className="blog-grid"  >
                     {displayedBlogs.map((blog, index) => (
                            <Box key={index} sx={{width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)',xs:'calc(100%-10%)' },height:'100%',
                            padding: { md: '20px', sm: '10px' , xs:'5px'},display: 'flex', flexDirection: 'row',overflowX:'hidden'
                            ,borderBottom:'1px solid #CFC9C9',gap:{ md: '20px', sm: '10px' , xs:'5px'},}}  >
                                    
                                    <Box sx={{width:'20%',height:'100%',display: 'flex', flexDirection: 'column',}} >
                                    <img src={blog.image} alt={blog.heading}   />
                                    </Box>
                                    <Box sx={{width:{md:'80%' , sm:'80%', xs:'80%' },minWidth:{md:'80%' , sm:'80%', xs:'50%' },height:'100%',gap:'10px',padding:'5px',display: 'flex', flexDirection: 'column',}} >
                                    <Typography sx={{ fontFamily: 'poppins', fontSize:  '20px' ,
                                                    lineHeight: '24px', fontWeight: '600', color: '#313131',}} >{blog.heading}</Typography>
                                    <Typography sx={{ fontFamily: 'poppins', fontSize:  '16px' ,
                                                    lineHeight: '24px', fontWeight: '400', color: '#626262',}}>{blog.content}</Typography>
                                            <Box sx={{display: 'flex', flexDirection: 'row', width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)',xs:'calc(100%-10%)' },justifyContent: 'space-between', alignItems: 'center',  }} >
                                            <Link style={{ fontFamily: 'poppins', fontSize:  '16px' ,lineHeight: '24px', fontWeight: '400', color: '#4867AA',
                                                    textTransform:'none',  textDecoration: 'none', }}  to={{
                                                      pathname: '/steps-to-get',
                                                      search: `?blogTitle=${encodeURIComponent(blog.heading)}&blogImage=${encodeURIComponent(blog.image)}`,
                                                  }}>
                                            Read More
                                            </Link>
                                            <Typography sx={{fontFamily: 'poppins', fontSize:  '16px' ,
                                                            lineHeight: '24px', fontWeight: '400', color: '#626262',  }} className="published-date">Published: {blog.publishedDate}</Typography></Box>
                                            </Box>
                                     </Box>
                                     
                        ))}
                         </div> 
                        </Box>
                        
                     
                     <Box sx={{ display: 'flex', justifyContent: 'center',flexDirection:'row', alignItems:'center',width:'100%',gap:'10px',padding:'20px',paddingTop:'40px' }}>
                {/* Generate page number buttons */}
                {Array.from({ length: totalPages }, (_, index) => (
                    <Button
                        key={index + 1}
                        onClick={() => handlePageClick(index + 1)}
                        variant={currentPageNumber === index + 1 ? "contained" : "outlined"}
                       
                        sx={{width:'28px', height:'28px' ,borderRadius:'4px',padding:0, minWidth:0 ,backgroundColor:currentPageNumber === index + 1 ? '#0C276C': '#fff',
                         color:currentPageNumber === index + 1 ? '#ffffff': '#0C276C',border:'1px solid #0C276C '
                         ,'&:hover': { bgcolor: "#0C276C", color: '#fff' }  }}
                    >
                        {index + 1}
                    </Button>
                ))}</Box>
            <Footer></Footer>
        </Grid>
    );

}

export default Articles;