import Box from '@mui/material/Box';
import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import '../App.css';
import Header from './HeaderLanding'
import HeaderMobile from './HeaderLandingMobile';
import Footer from './Footer';

function TermsOfUse() {

    const [currentPage, setCurrentPage] = useState('');
    return (
        <Grid container sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>
            <Box sx={{
                height: '100% ', width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'flex', sm: 'flex', xs: 'none' }
            }}>
                <Header currentPage={currentPage}></Header>
            </Box>
            <Box sx={{
                height: '100% ', width: '100vw', fontFamily: "poppins", fontSize: "14px", fontWeight: "400", margin: 0,
                padding: 0, display: { md: 'none', sm: 'none', xs: 'flex' }
            }}>
                <HeaderMobile currentPage={currentPage}></HeaderMobile>
            </Box>
            <Box sx={{
                width: { md: 'calc(100%-32%)', sm: 'calc(100%-10%)', xs: 'calc(100%-10%)' }, display: 'flex', flexDirection: 'row', height: '100%',
                paddingLeft: { md: '16%', sm: '5%', xs: '5%' }, paddingRight: { md: '16%', sm: '5%', xs: '5%' }, paddingTop: { md: '50px', sm: '50px', xs: '25px' },
                paddingBottom: { md: '25px', sm: '25px', xs: '5%' },
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', width: '100%', height: '100%',
                    background: "#F9F9F9 ", padding: { md: '50px ', sm: '50px ', xs: '25px ' }, alignItems: 'center'
                }}>

                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: { md: '26px', sm: '26px', xs: '20px' }, paddingTop: { md: '25px', sm: '20px', xs: '0px' },
                        lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                    }}>
                       Terms of Use
                    </Typography>
                    <Typography sx={{
                        fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: { md: '25px', sm: '20px', xs: '0px' },
                        lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                    }}>
                        Last Updated: October 15, 2023
                    </Typography>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', height: '100%',
                        padding: '10px 0px 25px 0px', alignItems: 'center', gap: '10px'
                    }}>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>
                           This document  govern the user's access to and use of the website www.ratewaves.com & including its content encompasses various types of media, data, information, text, pictures, and materials that are provided on or through www.ratewaves.com.
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px', }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>"Us", "We", "Our":</span> These pronouns refer to Ratewaves, the entity that owns or operates the website or service.
                        </Typography>
                        </li>
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>"You", "User":</span> These terms refer to the individual person or legal entity (such as a company or organization) that is accessing, using, or interacting with the website or service provided by Ratewaves .
                        </Typography>
                        </li>
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>"Use" or "Using":</span> In the context of these terms, "use" or "using" refers to any of the actions mentioned above, such as browsing, installing, downloading, or accessing the service.
                        </Typography>
                        </li>                        
                        </ul>


                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                           Representation and Warranty:

                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Legal Age of Majority:</span> Users are required to represent and warrant that they have reached the legal age of majority in their jurisdiction. This ensures that users are legally capable of entering into binding agreements.
                        </Typography>
                        </li>
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Capacity to Enter into Binding Obligations:</span> Users must also have the capacity to enter into binding obligations. In other words, they must be able to commit to and uphold the terms outlined in the agreement.
                        </Typography>
                        </li>
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Accuracy of Information:</span> Users are required to ensure that all information provided to your company through Ratewaves is true, accurate, current, and complete. This emphasizes the importance of providing accurate and reliable information.
                        </Typography>
                        </li>                        
                        </ul>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                           Agreement to Terms:
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Binding Agreement:</span> By using Ratewaves in any manner or by clicking to accept the terms and conditions, users are agreeing to be bound by these terms of use.
                        </Typography>
                        </li>
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Compliance with Terms:</span> Users are expected to comply with the terms outlined in the agreement.
                        </Typography>
                        </li>
                                              
                        </ul>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                          Updates to Terms:
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Agreement to Updates: </span> Users agree to be bound by and comply with the terms of use even as they are updated from time to time in accordance with the section specified.
                        </Typography>
                        </li>                     
                                              
                        </ul>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                       Disagreement with Terms:
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Access and Use Restriction:  </span> If users do not agree to these terms of use, they are instructed not to access or use Ratewaves.
                        </Typography>
                        </li>                     
                                              
                        </ul>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                      Changes to Terms of Use:
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Right to Change:   </span>  The company (Ratewaves ) reserves the sole discretion to modify any aspect of the terms of use at any time.
                        </Typography>
                        </li>                     
                     
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Notification of Changes: </span> 
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Notice Method:   </span>  When changes are made, the company will notify users through methods such as placing a notice on the Ratewaves website, sending an email, or using other means required by applicable law.
                        </Typography>
                        </li>                     
                     
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>New Version: </span> A new version of the terms will be posted on the Ratewaves platform.
                        </Typography>
                        </li>       
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>"Last Updated" Date: </span> The "Last Updated" date at the top of the terms will be changed to reflect the date of the update.
                        </Typography>
                        </li>                    
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Consent to Updated Terms: </span> 
                        </Typography>
                        </li> 
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Consent Requirement:    </span> In some cases, users may need to provide consent to the updated terms in a specified manner before they can continue using Ratewaves.
                        </Typography>
                        </li>                     
                        </ul>   
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Non-Acceptance of Changes:  </span> 
                        </Typography>
                        </li> 
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>User's Option:  </span> If a user does not agree with the changes after receiving notice, they must stop using Ratewaves.
                        </Typography>
                        </li>                     
                        </ul>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Acceptance of Changes: </span> 
                        </Typography>
                        </li> 
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Continued Use:   </span> If a user continues to access or use Ratewaves after changes to the terms, it indicates their acceptance of the modified terms.
                        </Typography>
                        </li>                     
                        </ul>           
                        </ul>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                     Changes to Ratewaves:
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Right to Change Ratewaves:    </span> The company reserves the right to change or modify the Ratewaves platform at any time without providing prior notice.
                        </Typography>
                        </li>                     
                     
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Suspension of Access: </span> 
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Grounds for Suspension:    </span>  Access to or use of Ratewaves may be suspended under certain circumstances, including scheduled maintenance, violations of the terms of use, or emergency security concerns.
                        </Typography>
                        </li>                     
                     
                                         
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Third-Party Software Updates:</span> 
                        </Typography>
                        </li> 
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>User Responsibility:     </span> Users may need to update third-party software periodically to ensure compatibility with the Ratewaves platform.
                        </Typography>
                        </li>                     
                        </ul>   
                        </ul>   
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                    Electronic Communications:
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Consent to Electronic Communications:   </span> By using Ratewaves, sending electronic messages (such as emails or texts) to the company, or viewing the platform, users are deemed to be communicating with the company electronically. Users also consent to receiving communications from the company electronically.
                        </Typography>
                        </li>                     
                     
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Methods of Communication: </span> 
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Communication Channels:    </span> The company will communicate with users using methods such as email, text messages, or by posting notices on the Ratewaves platform.
                        </Typography>
                        </li>                     
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Legal Requirement Satisfaction:     </span>Users acknowledge that communications provided electronically fulfill any legal requirements for written communications.
                        </Typography>
                        </li>    
                                         
                        </ul> 
                     
                        </ul>   
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                   Text Message Rates and Charges:
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If users choose to receive text messages through Ratewaves, they may incur standard data and message rates. Any charges, fees, or costs associated with these text messages are the user's responsibility. Users are advised to consult their wireless carrier to determine the applicable rates and charges.
                        </Typography>
                        </li> 
                        </ul>   
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                   Service-Related Communications:
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users acknowledge that by creating an account to access Ratewaves, they agree to receive service-related emails and text messages from Ratewaves , which may include updates related to their accounts. Users can manage these communications through user features provided by Ratewaves.
                        </Typography>
                        </li>  
                        </ul>   
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                  Withdrawal of Consent:
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users have the right to withdraw their consent to receive communications electronically. However, withdrawing this consent (except for marketing communications) will require the user to uninstall, delete, and stop using Ratewaves. The withdrawal of consent does not affect the legal validity and enforceability of any obligations or prior electronic communications between the user and the company.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are encouraged to keep their contact information up to date to ensure uninterrupted receipt of communications.
                        </Typography>
                        </li>  
                        </ul>  
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                        User data & Privacy
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Transmission of Personal Information:    </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users acknowledge and agree that certain of their Personal Information will be transmitted electronically by Ratewaves .
                        </Typography>
                        </li>      
                        </ul>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>License to User Data:    </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users grant Ratewaves , its affiliates, service providers, licensees, successors, and assigns an irrevocable, worldwide, non-exclusive, royalty-free, transferable, and sublicensable license to access, collect, store, and use any data, information, records, or files loaded, transmitted, or entered into Ratewaves. This includes Personal Information.
                        </Typography>
                        </li>   
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This license is granted to develop and enhance Ratewaves, as well as to create Aggregated Data (data, information, or materials that do not identify specific individuals or companies). Ratewaves  and its affiliates are free to use and disclose Aggregated Data for any purpose, both during and after the term.
                        </Typography>
                        </li>    
                        </ul>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Privacy Policy Incorporation:    </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are directed to review the current privacy policy, which provides important information about how Personal Information is handled. The privacy policy is incorporated into and forms a part of these Terms of Use.
                        </Typography>
                        </li>   
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The term "Handle" is defined to encompass various actions related to processing Personal Information.
                        </Typography>
                        </li>    
                        </ul>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>User Representations and Warranties:   </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users represent and warrant that:
                        </Typography>
                        </li>  
                        </ul>   
                        <ul  style={{ listStyleType: 'disc',  paddingLeft: '80px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>User Data containing Personal Information will only include their own information or information for which necessary notices, disclosures, consents, and permissions have been obtained as required by applicable laws.
                        </Typography>
                        </li>   
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users own or control the appropriate rights to their User Data, including any third-party intellectual property rights.
                        </Typography>
                        </li>   
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>	Users will not submit, upload, or otherwise make available User Data that:
                        </Typography>
                        </li>  
                       
                        </ul>  
                        <ul  style={{ listStyleType: 'circle',  paddingLeft: '120px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>They do not have the necessary rights to use or grant the described license for.
                        </Typography>
                        </li>   
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Infringes, misappropriates, or violates any intellectual property or other rights of third parties.
                        </Typography>
                        </li>   
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Breaches or violates any applicable laws or the Terms of Use.
                        </Typography>
                        </li>                       
                        </ul>
                        </ul>  
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                       App Updates and Upgrades
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Issuing Updates and Upgrades:    </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves  reserves the right to release updated or upgraded versions of the Ratewaves platform from time to time.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The company may automatically electronically update or upgrade the version of Ratewaves that users are currently using on their browsers, subject to users' device settings.
                        </Typography>
                        </li>          
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Automatic Updates:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users consent to receive updates or upgrades to Ratewaves automatically without having to provide further consent each time an update or upgrade is issued.
                        </Typography>
                        </li>  
                       
                        </ul> 
                     
                       
                   
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Impact of Updates and Upgrades:    </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Updates or upgrades to Ratewaves (including new features) may lead to the following:
                        </Typography>
                        </li>  
                            
                        </ul> 
                       
                        <ul  style={{ listStyleType:'disc', paddingLeft: '80px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Automatic communication between users' devices and the company's servers to deliver new functionalities or record usage metrics.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Changes to user preferences or data stored on their devices.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Collection of personal information in accordance with the Privacy Policy
                        </Typography>
                        </li> 
                        </ul>
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>User Responsibility for Device Compatibility:  </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The company is not responsible if an update or upgrade affects how Ratewaves works due to a user's equipment or device not supporting the update or upgrade.
                        </Typography>
                        </li>  
                            
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Withdrawal of Consent:  </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users can withdraw their consent to receive automatic updates under certain conditions. The method to withdraw consent can be found in the manner described in the terms.
                        </Typography>
                        </li>  
                            
                        </ul> 
                       
                        </ul> 

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                      Ownership of and License to Ratewaves
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Ownership:    </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This section typically states that the Ratewaves platform, including its website, web application, mobile application, content, graphics, designs, logos, and any other elements, is owned by or licensed to the company (Ratewaves ).
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>It emphasizes that all rights, including intellectual property rights, associated with Ratewaves are vested in the company.
                        </Typography>
                        </li>          
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>License to Use:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are granted a limited, non-exclusive, non-transferable license to access and use the Ratewaves platform in accordance with the terms of use.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This license outlines the permissions granted to users, allowing them to use the platform for its intended purposes while adhering to the stipulated terms and conditions.
                        </Typography>
                        </li>  
                       
                        </ul> 
                     
                       
                   
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Restrictions: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This section may specify certain restrictions on the use of Ratewaves. These restrictions could include not modifying, copying, reproducing, distributing, or reverse engineering the platform or any of its components without explicit authorization from the company.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are often prohibited from using Ratewaves for any unlawful or unauthorized purposes.
                        </Typography>
                        </li> 
                            
                        </ul> 
                       
                     
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Termination of License: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The license granted to users to access and use Ratewaves can be terminated if they violate the terms of use or if the company decides to revoke access for any other reason.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Upon termination, users are typically required to cease using the platform and may be asked to delete any downloaded or copied materials.
                        </Typography>
                        </li>  
                            
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Intellectual Property Rights: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users acknowledge and agree that the company retains all rights, title, and interest in the intellectual property associated with Ratewaves.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This section reinforces the company's ownership of trademarks, copyrights, patents, and other intellectual property related to the platform.
                        </Typography>
                        </li>  
                             
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>User-Generated Content: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users may be reminded that any content they contribute to Ratewaves (user-generated content) is subject to the terms and conditions, and they may grant the company certain rights to use and distribute this content as outlined in the terms.
                        </Typography>
                        </li>
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Additional Terms and Conditions: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Some functionalities provided by Ratewaves  or its service providers may come with their own specific terms and conditions.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are informed that they may encounter these additional terms when using certain functionalities.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The additional terms and conditions are "incorporated herein by reference." This means that they become part of the overall agreement between the user and Ratewaves .
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are agreeing to comply with the additional terms and conditions that apply to specific functionalities.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>These additional terms must be followed by users alongside the main terms of use.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>In the case of a conflict or inconsistency between the main terms of use and the additional terms and conditions, the main terms of use will govern to the extent of the conflict.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The exception to this is if the conflicting term in the additional terms explicitly states that the conflicting term in the main terms of use doesn't apply.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are informed that if they don't accept or agree to the additional terms and conditions, they may not be able to access or use the specific functionalities that are subject to those terms.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This serves as a notice to users that they should consider whether they're willing to comply with these additional terms before using those functionalities.
                        </Typography>
                        </li>  
                             
                        </ul> 
                        </ul> 
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                   Your Responsibilities
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Prevent Unauthorized Access:    </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are required to take measures to prevent unauthorized access to or use of the Ratewaves platform. This emphasizes the importance of keeping their account and access credentials secure.
                        </Typography>
                        </li>  
                            
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Maintain User Data:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are responsible for maintaining, protecting, and making backups of their own User Data. This ensures the preservation and safeguarding of their data on the platform.
                        </Typography>
                        </li>                       
                       
                        </ul>  
                   
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Keep Contact Information Updated:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are expected to keep their contact information associated with their account accurate and current. This ensures that the company can communicate with users effectively.
                        </Typography>
                        </li>  
                        </ul>                        
                     
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Compliance with Laws and Regulations:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users must comply with all applicable laws and regulations, including those related to intellectual property, data privacy, and anti-spam practices.
                        </Typography>
                        </li>                         
                            
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Proper Account Usage: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users should not register for multiple accounts, register on behalf of others without permission, or create accounts on behalf of groups or entities without proper authorization.
                        </Typography>
                        </li>  
                       
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Respect User IDs:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users must not use another person's User ID without their permission. This is crucial for maintaining the integrity of individual accounts and identities.
                        </Typography>
                        </li>
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Export Control Compliance: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are prohibited from exporting, re-exporting, or transferring Ratewaves or any part thereof to countries, individuals, entities, or end-users subject to applicable export controls or sanctions.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users must ensure compliance with all trade restrictions, regulations, and laws, both foreign and domestic, regarding the use and distribution of the platform.
                        </Typography>
                        </li>  
                       
                             
                        </ul> 
                        </ul>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                   No Unlawful or Prohibited Use
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Unlawful Use:   </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are prohibited from using the platform for any unlawful purpose. This includes activities that violate local, national, or international laws, regulations, or statutes.
                        </Typography>
                        </li>  
                            
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Prohibited Activities:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are not allowed to engage in any activity that could harm, disrupt, or interfere with the proper functioning of the Ratewaves platform, its services, or its users.
                        </Typography>
                        </li>    
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This may include actions that introduce malware, attempt to gain unauthorized access, or engage in any form of hacking or cyberattacks.
                        </Typography>
                        </li>                        
                       
                        </ul>  
                   
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>No Harmful Content:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are forbidden from submitting, sharing, or transmitting any content that is harmful, offensive, defamatory, or violates the rights of others.
                        </Typography>
                        </li>  
                        </ul>                        
                     
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Respect for Intellectual Property:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users must respect the intellectual property rights of others. This includes refraining from using copyrighted material without proper authorization, and not engaging in any activity that infringes on the intellectual property rights of others.
                        </Typography>
                        </li>                         
                            
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Prohibited Use for Commercial Gain:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are not allowed to use the platform for commercial purposes without proper authorization. This prevents unauthorized use of the platform for personal financial gain.
                        </Typography>
                        </li>  
                       
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>No Unauthorized Access:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are prohibited from attempting to access or using accounts, systems, or data without proper authorization.
                        </Typography>
                        </li>
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>No Data Manipulation: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users must not engage in any activities that manipulate or alter data on the platform in a way that could compromise its integrity or the experience of other users.
                        </Typography>
                        </li>
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>No Interference with Others: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are not allowed to interfere with the use of the platform by other users, including actions that disrupt or inhibit their access or experience.
                        </Typography>
                        </li>
                        </ul> 
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Compliance with Laws:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are required to comply with all applicable laws and regulations while using the platform.
                        </Typography>
                        </li>
                        </ul> 
                        </ul>                        
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                  Communications Not Confidential
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>No Guarantee of Confidentiality:   </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The company explicitly states that it does not guarantee the confidentiality of any communications made by users through the Ratewaves platform.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This means that users should not expect their communications to remain private or confidential while using the platform.
                        </Typography>
                        </li>  
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>No Security Guarantee for Data Transmission:  </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The company also states that it does not guarantee the security of data that is transmitted over the internet or public networks in connection with users' use of the Ratewaves platform.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This implies that data transmitted between users and the platform may be susceptible to interception or unauthorized access.
                        </Typography>
                        </li>  
                        </ul>                           
                        </ul> 
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                 Feedback
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Non-Confidential Nature of Feedback:   </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are informed that any feedback they provide to the company will not be treated as confidential.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The company reserves the right to use, disclose, publish, exploit, and profit from any feedback without any obligation to compensate the user.
                        </Typography>
                        </li>  
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Grant of License to Feedback: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users grant the company, its affiliates, service providers, and their respective licensees, successors, and assigns, a comprehensive and perpetual license to use the provided feedback.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The license covers a wide range of rights, including distribution, modification, adaptation, translation, public performance, display, sale, and more, in any form, media, or technology.
                        </Typography>
                        </li>  
                        </ul>             
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Scope of License: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The granted license covers both existing and future forms of technology or media, regardless of whether the technology is currently known or developed in the future.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users allow the company to permit others to exercise the same rights under this license.
                        </Typography>
                        </li>  
                        </ul>    
                        </ul>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Exclusion of Separate Agreements:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The clause specifies that this agreement regarding feedback usage applies unless a separate agreement between the user and the company states otherwise.
                        </Typography>
                        </li>                        
                        </ul>    
                        </ul>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Waiver of Claims and Rights:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users waive any claims they might have related to the use of their feedback, including invasion of privacy, defamation, and right of publicity.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users also waive moral rights and author's rights in their feedback, even if the feedback is altered or used in a way not agreeable to them.
                        </Typography>
                        </li>  
                        </ul>    
                        </ul>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>In essence, this clause establishes that any feedback 
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                 Third Party Content, Websites or Services
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Third-Party Content, Websites, and Services:   </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Linking to Third-Party Content:</span> The company states that it does not review such third-party websites or services and is not responsible for them or any content that appears on them.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Trademarks: </span> Any trademarks associated with third-party content, websites, or services are the property of their respective owners.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>No Endorsement: </span> Ratewaves  does not endorse any third-party content, websites, services, or systems. It does not guarantee or warrant their quality, accuracy, reliability, or other attributes.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Use at Own Risk: </span> Users are informed that if they choose to access third-party content, websites, services, or systems, or access Ratewaves from such systems, they do so at their own risk.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Acceptance of Third-Party Terms: </span>Users may need to accept separate terms of use applicable to third-party content, websites, services, or systems. They are expected to comply with these terms.
                        </Typography>

                        </li>  
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Interactions with Organizations and Individuals: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>User Responsibility: </span> : Users' interactions with organizations and individuals found on or through Ratewaves, including transactions, payment, and delivery of goods and services, are solely their responsibility.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Ratewaves's Limited Liability: </span>The company is not responsible or liable for any loss or damage resulting from such interactions to the fullest extent permitted by applicable law.
                        </Typography>

                        </li>
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Facilitation of Connections:  </span>Users acknowledge that if they contact an organization or individual through Ratewaves, the company may provide the submitted information to the third party. The company might also receive compensation for facilitating this connection.
                        </Typography>

                        </li>  
                        </ul>             
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Specific Requirements from Third-Party Content Providers:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Some third-party content providers may have specific requirements for users to access their data. These requirements are detailed in another section (Section 23) related to the Virtual Office Website.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are expected to accept and conform to these requirements when using Ratewaves.
                        </Typography>

                        </li>  
                        </ul>    
                        </ul>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                Malicious Code and Security
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Risk of Downloading and Viewing Content:  </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The company emphasizes that when users download and view content from Ratewaves, they do so at their own risk.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This is a reminder to users that they should be cautious and aware of potential risks when interacting with online content.
                        </Typography>

                        </li>  
                   
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Compatibility and Virus Risks: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The company does not guarantee or warrant that Ratewaves will be compatible with users' computer systems or mobile devices.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Similarly, the company does not guarantee that Ratewaves or any links from Ratewaves will be free of viruses, worms, trojan horses, or other malicious code that could harm users' devices.
                        </Typography>

                        </li>                       
                        </ul>             
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>User's Responsibility for Protection:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are responsible for implementing safeguards to protect the security and integrity of their own computer systems and mobile devices.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This could include using antivirus software, firewalls, and other security measures to minimize risks.
                        </Typography>

                        </li>  
                        </ul>    
                        </ul>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Cost Responsibility:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are informed that they are responsible for the entire cost of any service, repairs, or connections needed for their computer systems or mobile devices due to their use of Ratewaves.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If any damage or issue arises from their use of the platform, the financial responsibility falls on the user.
                        </Typography>

                        </li>  
                        </ul>    
                        </ul>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
                Disclaimer on Jurisdictions has exceptional  provisions
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}> Exlusions Under Applicable Law:  </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Certain jurisdictions, including Quebec, may have laws that do not permit the exclusion or limitation of specific legal warranties, conditions, or representations.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If such laws apply to the user, some or all of the exclusions or limitations outlined in the terms of use (including the disclaimers that follow) may not apply, and the user may have additional rights.
                         </Typography>

                        </li>  
                   
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Minimum Permitted Warranty: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If the company is prohibited by applicable law from disclaiming certain implied warranties or conditions, the scope and duration of those warranties or conditions will be limited to the minimum extent allowed under that applicable law.
                        </Typography>

                        </li>  
                                          
                        </ul>             
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>"As Is" and "As Available" Nature:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The company emphasizes that Ratewaves and its offerings are provided "as is" and "as available."
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are informed that the platform may have faults and is not guaranteed to be error-free or operate without interruption.
                        </Typography>

                        </li>  
                        </ul>    
                        </ul>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Disclaimer of Various Warranties:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}> company disclaims all warranties, representations, and conditions of any kind regarding Ratewaves and its offerings, whether they are express, implied, statutory, or collateral.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The disclaimed warranties and conditions include but are not limited to merchantability, quality, durability, compatibility, security, reliability, completeness, accuracy, currency, timeliness, fitness for specific or general purposes, and non-infringement.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The company also disclaims any warranties or conditions arising from course of dealing, usage of trade, and any guarantee of error-free operation.
                        </Typography>

                        </li>  
                       
                        </ul>    
                        </ul>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Third-Party Communications and Content:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>	The company also disclaims all warranties, representations, and conditions of any kind with respect to third-party communications and any third-party websites or content accessed through Ratewaves.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This disclaimer applies to both direct and indirect access to third-party content via the platform.
                        </Typography>

                        </li>                          
                       
                        </ul>    
                        </ul>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
               Non-Reliance
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}> Limitations of Maps:  </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}> Incomplete, Accurate, or Updated Charts:   </span>
                       Users are told that any maps displayed on the platform may not be complete, accurate, or up-to-date.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Implication:   </span>Users should not solely rely on the maps for location or geographical accuracy.
                         </Typography>

                        </li>  
                   
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Service Provider Listings: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Non-Endorsement:  </span>
                        Users are informed that the inclusion of realtors, mortgage brokers, or other service providers on Ratewaves does not imply an endorsement by Ratewaves .
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>	Due Diligence:  </span>
                       The company clarifies that it has not conducted any due diligence or investigation regarding the services offered by these providers.
                        </Typography>

                        </li>                 
                        </ul>             
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Research Before Taking Decisions:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Information Reliability: </span>They are advised not to solely rely on the information contained on Ratewaves for making property purchase decisions.
                        </Typography>

                        </li>  
                       
                        </ul>    
                        </ul>
                        
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
               Limitation of Liability
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}> Scope of Liability Limitation:  </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The Ratewaves Parties, which include Ratewaves , its affiliates, officers, directors, employees, contractors, agents, licensors, licensees, service providers, and successors and assigns, will not be liable for any damages or losses incurred by users.
                        </Typography>

                        </li>  
                          
                   
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>	Types of Damages Excluded: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The Ratewaves Parties will not be liable for direct, indirect, incidental, consequential, special, exemplary, or punitive damages.
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This includes various forms of loss, such as lost profits, loss of use, loss of data, personal injury, fines, fees, penalties, or other liabilities.
                        </Typography>

                        </li>                 
                        </ul>             
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Legal Theories and Knowledge of Damages:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The exclusion of liability applies regardless of the legal theory under which a claim is made, whether it's based on warranty, contract, tort, negligence, strict liability, or any other legal theory.
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The exclusion applies even if the Ratewaves Parties were not advised or did not know of the possibility of such damages.
                        </Typography>

                        </li>  
                       
                        </ul>    
                        </ul>
                        
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Damages Related to Ratewaves:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The exclusion of liability covers damages resulting from or related to the use of Ratewaves or the inability to use Ratewaves, or these terms of use.
                        </Typography>

                        </li>  

                        </ul>    
                        </ul>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Exclusive Remedy:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If users are dissatisfied with any aspect of Ratewaves, their sole and exclusive remedy is to discontinue using the platform.
                        </Typography>

                        </li>  
                        

                       
                       
                        </ul>    
                        </ul>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Force Majeure and Acts Beyond Control:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The clause emphasizes that the Ratewaves Parties will not be held liable for delays or failures in performance resulting from acts of nature, forces, or causes beyond their reasonable control.
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>This includes a wide range of external factors such as internet failures, equipment failures, strikes, riots, government actions, acts of God, and more.


                        </Typography>

                        </li>  
                        

                       
                       
                        </ul>    
                        </ul>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
              Indemnification
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}> Indemnification Obligation: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are obligated to defend, indemnify, and hold harmless the Ratewaves Parties. This means that users will protect and compensate the Ratewaves Parties for any losses, costs, damages, or expenses resulting from certain situations.
                        </Typography>

                        </li>
                        </ul>  
                        </ul> 
                        <ul  style={{ listStyleType:'disc', paddingLeft: '80px', paddingTop:'5px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Claims arising from the user's User Data
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}> Breach of any provision of the terms of use or referenced documents.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}> Violation of laws or third-party rights, including intellectual property rights.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>  Introducing harmful programming routines (e.g., viruses, malware) into Ratewaves.
                        </Typography>
                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>  User's use of Ratewaves or Offerings, except where prohibited by law.
                        </Typography>
                        </li>  
                      
                        </ul>  
                        
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Costs Covered:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The indemnification includes costs such as legal and accounting fees, fines, penalties, and other relevant expenses.
                        </Typography>

                        </li>  

                        </ul>    
                        </ul>
                        
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Exclusive Defense and Control:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves  has the right to take over the exclusive defense and control of any matter that requires indemnification by the user.
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If Ratewaves  exercises this right, the user must cooperate fully with the company in asserting any available defenses.
                        </Typography>

                        </li>  


                        </ul>    
                        </ul>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Survival of Provisions:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The indemnification provisions outlined in this section will continue to be in effect even after the termination of the user's account, the terms of use, or their access to Ratewaves.
                        </Typography>

                        </li>  
                       
                        </ul>    
                        </ul>
                         <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
              Term and Termination
                        </Typography>
                        <ul style={{  listStyleType: 'square',paddingLeft:'30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}> 	Commencement and Duration: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The terms of use become effective when a user first starts using Ratewaves.
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The terms will remain in effect until they are terminated by either party.
                        </Typography>

                        </li> 
                   
                       
                        </ul> 
                        <ul style={{  listStyleType: 'square',  }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>	Termination by Ratewaves :</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves  has the right to terminate the terms of use at any time and with immediate effect.
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Notice of termination may be provided to the user through email or via the Ratewaves platform.
                        </Typography>

                        </li>                 
                        </ul>             
                        </ul> 
                        <ul style={{  listStyleType: 'square' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Termination by User:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users have the right to terminate the terms of use at any time and with immediate effect.
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>	To do so, users need to send an email requesting the deletion of their user account, cease using Ratewaves, and uninstall and delete the Ratewaves application.
                        </Typography>

                        </li>  
                       
                        </ul>    
                        </ul>
                        
                        <ul style={{  listStyleType: 'square',}}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Continued Use After Termination:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If a user continues to use any portion of Ratewaves that is publicly available after the terms of use have been terminated, these terms will still apply to that extent.


                        </Typography>

                        </li>  
                        </ul>  
                        </ul>    
                        </ul>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
             Geographic Restrictions
                        </Typography>
                        <ul style={{  listStyleType: 'square',paddingLeft:'30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}> Geographical Availability: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves  does not guarantee that Ratewaves is or will be available for use in all locations outside of Canada or within Canada.
                        </Typography>

                        </li>  
                                        
                       
                        </ul> 
                        <ul style={{  listStyleType: 'square'}}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>	Intended Usage Locations:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves is provided for use only by individuals located in Canada, excluding Quebec.
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The platform is intended to be used within these specific geographical areas.
                        </Typography>

                        </li>                 
                        </ul>             
                        </ul> 
                        <ul style={{  listStyleType: 'square',  }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Prohibition of Usage in Restricted Jurisdictions:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The clause emphasizes that Ratewaves is not intended for use in any jurisdiction where its use is not permitted.
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>	This means that individuals in jurisdictions where Ratewaves's usage is prohibited should not access the platform.
                        </Typography>

                        </li>  
                       
                        </ul>    
                        </ul>
                        
                        <ul style={{  listStyleType: 'square',  }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Risks of Accessing from Outside Canada:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If users access the site from outside Canada, they do so at their own risk.


                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>They are responsible for complying with the local laws and regulations of their jurisdiction.



                        </Typography>

                        </li>  
                        </ul>  
                        </ul>    
                        </ul> 
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
              General Provisions
                        </Typography>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}> Choice of Law:  </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The terms of use will be governed by the laws of the Province of British Columbia and the federal laws of Canada applicable therein.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Regardless of a user's domicile, residency, or physical location, these laws will apply to access and use of Ratewaves.
                         </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are only allowed to use Ratewaves in jurisdictions where its usage is lawful.
                         </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Disputes will be subject to the exclusive jurisdiction and venue of courts in Vancouver, British Columbia.
                         </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The United Nations Convention on Contracts for the International Sale of Goods will not apply.
                         </Typography>

                        </li>
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Entire Agreement: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The terms of use, along with referenced terms and conditions, constitute the entire agreement between the user and Ratewaves .
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>These terms supersede any prior communications or proposals, whether electronic, oral, or written, related to Ratewaves and Offerings.
                        </Typography>

                        </li>     
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Printed versions of these terms, as well as notices given in electronic form, are admissible in judicial or administrative proceedings.
                        </Typography>

                        </li>             
                        </ul>             
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Survival:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Certain sections of the terms of use will survive the expiration or termination of the agreement. These sections include those related to user data and privacy, ownership of Ratewaves, additional terms, communications not confidential, third-party content, malicious code and security, disclaimer, limitation of liability, indemnification, and general provisions.
                        </Typography>

                        </li>  
                       
                        </ul>    
                        </ul>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Waiver:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Failure to strictly enforce a provision of the terms of use doesn't constitute a waiver of that provision or right.
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Any waiver of a provision must be in writing, and waiving a provision in one instance doesn't prevent enforcing it in other instances.
                        </Typography>

                        </li>  
                       
                        </ul>    
                        </ul>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Severable:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If any provision in the terms of use is determined to be void, invalid, or unenforceable by a court, that provision will be severed while the rest of the terms remain in effect.
                        </Typography>

                        </li>  
                       
                        </ul>    
                        </ul>
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Assignment: </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users cannot assign the terms of use to a third party without Ratewaves ' prior written consent.
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Ratewaves can assign the terms or any rights under them to a third party without user consent.
                        </Typography>

                        </li>     
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Any attempt to assign in violation of this section is null and void.
                        </Typography>

                        </li>             
                        </ul>             
                        </ul> 
                          
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Dispute Resolution:</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are encouraged to contact Ratewaves  if they believe the terms of use haven't been adhered to.
                        </Typography>

                        </li>  
                        <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If a complaint is not adequately addressed, users can inform Ratewaves  for further investigation.


                        </Typography>

                        </li>  
                       
                        </ul>    
                        </ul>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
              Terms of Use on Web Page - New Home Listings page –“New Homes Launch”
                        </Typography>
                       
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Accuracy of Information  </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Web page “New Homes Launch” strives to provide accurate and up-to-date information regarding the properties listed on our platform. However, we do not guarantee the accuracy, completeness, or reliability of the information provided.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users are responsible for verifying the details of any property, including but not limited to price, location, condition, availability, and features, by directly contacting the property owner or authorized agent.
                         </Typography>

                         </li>  
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Listing Content </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Property listings on Web page “New Homes Launch pad” are submitted by property owners, agents, or other third parties. We do not endorse or verify the information or representations made in these listings.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Web page “New Homes Launch” shall not be held responsible for any misinformation, errors, omissions, or misrepresentations in property listings.
                         </Typography>

                         </li>  
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>User Conduct </span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users of Web page “New Homes Launch” are expected to conduct themselves in a respectful and lawful manner when using our platform. Any illegal, fraudulent, or abusive activities will not be tolerated.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Web page “New Homes Launch” reserves the right to remove, restrict, or block users and listings that violate our terms of service.
                         </Typography>

                         </li>  
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Liability</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Web page “New Homes Launch” shall not be liable for any direct or indirect damages, losses, or claims resulting from the use or inability to use our platform or the information provided on it.
                        </Typography>

                        </li>  
                        <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Users agree to release Web page “New Homes Launch” from any liability related to their interactions or transactions with property owners, agents, or other users on the site.
                         </Typography>

                         </li>  
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>External Links</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Web page “New Homes Launch” may contain links to external websites. We are not responsible for the content, accuracy, or safety of these external sites.
                        </Typography>

                        </li>  
                          
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Legal Advice</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>The information provided on Web page “New Homes Launch”  is not a substitute for professional legal, financial, or real estate advice. Users are advised to consult with appropriate experts before making any real estate decisions.
                        </Typography>

                        </li>  
                          
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Changes to Disclaimer</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>Web page “New Homes Launch”  reserves the right to modify, update, or change this disclaimer at any time. Users are encouraged to review this disclaimer periodically.
                        </Typography>

                        </li>  
                          
                        </ul>  
                        </ul> 
                        <ul style={{  listStyleType: 'square', paddingLeft: '30px' }}>
                            <li>
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}><span style={{ fontSize: '18px', fontWeight: '500' }}>Contact Information</span>
                        </Typography>
                        </li>  
                        <ul className="diamond-list" style={{ paddingLeft: '30px', paddingTop:'5px' }}>
                            <li>
                            <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>For any questions or concerns regarding this disclaimer, please contact Web page “New Homes Launch”.
                        </Typography>

                        </li>  
                          
                        </ul>  
                        </ul> 
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>By using Web page “New Homes Launch”, you agree to abide by this disclaimer. If you do not agree with any part of this disclaimer, please refrain from using our platform.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: { md: '20px', sm: '20px', xs: '18px' }, paddingTop: '20px',
                            lineHeight: '24px', fontWeight: '700', color: '#313131', marginRight: 'auto',
                        }}>
             Contact
                        </Typography>
                       
                        <Typography sx={{
                            fontFamily: 'poppins', fontSize: '16px',paddingLeft:'10px',paddingTop:'5px',
                            lineHeight: '28px', fontWeight: '400', color: '#313131', marginRight: 'auto',
                        }}>If you have any questions or comments regarding these Terms of Use, please contact us at legal@Ratewaves.ca.
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Footer></Footer>
        </Grid>
    );

}

export default TermsOfUse;