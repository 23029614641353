import { Box, Button, ButtonBase, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import '../App.css';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import msgno from './images/drum.png';
import Modalsendtoemail from './modalsendtoemailmob';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PrimaryButton from './customButton';
import SecondaryButton from './Secondarybutton';
function FooterMod() {
  const [accept, setAccept] = useState(false);
  const [showModal,setShowModal] = useState(false);
  const [newHeight, setNewHeight] =useState(0);
  const [showFooter, setShowFooter] = useState(false);
  function showModalHandler(){
    setShowModal(!showModal);
}
function hideDiv() {
  var div = document.getElementById("cookies");
  div.style.display = "none";
}
const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDiv(true);
    }, 3000); // 3000 milliseconds = 3 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='footer'>
      <div>
      <PrimaryButton startIcon={<Box sx={{width:'24px',height:'36px', alignItems:'center',justifyContent:'center',display:'flex',
    '&:hover': {
      Color: '#1F6373',
     },
    }}><img src={msgno} /> </Box>} 
    sx={{borderRadius:'4px'}}>Start the bidding</PrimaryButton>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:'0.75rem'}}>
                 <SecondaryButton startIcon={<MailOutlineIcon />} sx={{width:'156px',fontSize:'14px',borderRadius:'4px'}} onClick={showModalHandler}>Send to Email</SecondaryButton>
                <SecondaryButton startIcon={<FileDownloadOutlinedIcon />} sx={{width:'184px',borderRadius:'4px'}}>Download Result</SecondaryButton>
            </div>  
     {showDiv&&(<Box id="cookies"
    sx={{position:'absolute',display:'flex',flexDirection:{lg:'row',md:'row',sm:'column',xs:'column'}, width:'100%',height:{lg:'66px',md:'66px',sm:'100px',xs:'130px'},backgroundColor:'#D2D2D2',paddingLeft:'30px',paddingRight:'30px',alignItems:'center',justifyContent:{lg:'space-between',sm:'center',xs:'center'},alignContent:{sm:'center',xs:'center'},gap:{sm:'10px',xs:'10px'}}} 
     >
      <Typography sx={{fontSize:'10px',fontFamily:'Poppins',fontWeight:400}}>We use cookies and similar technologies to help personalize contend, tailor and measure ads, and provide a better experience. by clicking accept, you agree to this, as outlined in our cookie policy</Typography>
      <div style={{display:'flex',flexDirection:'row',gap:'8px',alignItems:'center'}}>
        <Button sx={{textTransform:'none',backgroundColor:'white',color:'black',width:'106px',height:'22px',fontSize:'10px',fontFamily:'Poppins',fontWeight:400}}>Preferences</Button>
        <PrimaryButton sx={{width:'106px',height:'22px',fontSize:'10px',borderRadius:'4px'}} onClick={hideDiv}>Accept</PrimaryButton>
      </div>
    </Box> )} 
    </div>
  );
}
export default FooterMod;
